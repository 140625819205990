import { AUTH__LOGIN, AUTH__INIT_USER, } from '../actions/auth';
import {
  PENDING, FULFILLED, REJECTED,
  LOGIN, ERROR, NO_USER,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';


const initialState = {
  loginData: {
    ...initRestData,
  },
  initUserData: {
    ...initRestData,
    isLoading: true,
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;
  switch (type) {
    /**
     * Init Auth
     */
    case `${AUTH__INIT_USER}__${LOGIN}`:
    case `${AUTH__INIT_USER}__${NO_USER}`:
    case `${AUTH__INIT_USER}__${FULFILLED}`: {
      return {
        ...state,
        initUserData: {
          isLoading: false,
          status: true,
          error: false,
        },
      };
    }

    case `${AUTH__INIT_USER}__${REJECTED}`: {
      return {
        ...state,
        initUserData: {
          isLoading: false,
          status: true,
          error: false,
          statusCode: payload.statusCode,
          messageCode: payload.messageCode,
          errorMessage: payload.errorMessage,
        },
      };
    }

    case `${AUTH__INIT_USER}__${ERROR}`: {
      return {
        ...state,
        initUserData: {
          isLoading: false,
          status: false,
          error: true,
          statusCode: payload.statusCode,
          messageCode: payload.messageCode,
          errorMessage: payload.errorMessage,
        },
      };
    }


    /**
     * Login Action
     */
    case `${AUTH__LOGIN}__${PENDING}`: {
      return {
        ...state,
        loginData: {
          ...state.loginData,
          ...(handlePending()),
        },
      };
    }

    case `${AUTH__LOGIN}__${FULFILLED}`: {
      return {
        ...state,
        loginData: {
          ...state.loginData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${AUTH__LOGIN}__${REJECTED}`: {
      return {
        ...state,
        loginData: {
          ...state.loginData,
          ...(handleRejected(payload)),
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
