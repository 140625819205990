export const COLS = [
  {
    id: 'id',
    width: 15,
    label: '#',
  },
  {
    id: 'name',
    width: 50,
    label: 'Název',
  },
  {
    id: 'actions',
    width: 35,
    label: 'Akce',
  },
];
