import React from 'react';
import { shape, string, } from 'prop-types';
import { Switch, Route, Redirect, } from 'react-router-dom';

import StandsLayout from './StandsLayout';
import ActionsPage from './ActionsPage';
import QueueConfigurationPage from './QueueConfigurationPage';
import NotFoundPage from '../../../templates/NotFound/NotFoundPage';
import BlankTemp from '../../../templates/Spaces/BlankTemp';


const QueueConfigLayout = ({ match, }) => (
  <BlankTemp>
    <Switch>
      <Redirect exact from={`${match.path}/stands`} to={`${match.path}/stands/main`} />
      <Route path={`${match.path}/stands`} component={StandsLayout} />
      <Route path={`${match.path}/actions`} component={ActionsPage} />
      <Route path={`${match.path}/configuration`} component={QueueConfigurationPage} />
      <Route component={NotFoundPage} />
    </Switch>
  </BlankTemp>
);


QueueConfigLayout.propTypes = {
  match: shape({
    path: string.isRequired,
  }).isRequired,
};


export default QueueConfigLayout;
