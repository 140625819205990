import { INPUT_TYPE, } from 'sofair-form-validation';

import {
  QUEUE_CONFIG__STAND_APP_CONFIGURATION_REFRESH,
  QUEUE_CONFIG__STAND_APP_CONFIGURATION_CHANGE_VALUE_DETAIL,
  QUEUE_CONFIG__STAND_APP_CONFIGURATION_VALIDATE_FORM_DETAIL,
  QUEUE_CONFIG__GET_STAND_APP_CONFIGURATION_DETAIL,
  QUEUE_CONFIG__CREATE_STAND_APP_CONFIGURATION_DETAIL,
  QUEUE_CONFIG__UPDATE_STAND_APP_CONFIGURATION_DETAIL,
  QUEUE_CONFIG__DELETE_STAND_APP_CONFIGURATION_DETAIL,
  QUEUE_CONFIG__TOGGLE_STAND_APP_CONFIGURATION_DELETE_MODAL,
} from '../actions/standAppConfigurationDetail';
import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';
import {
  mergeFormAndValidation, initInput, changeFormValue,
} from '../../../logic/forms/validation';


const initialState = {
  detailStandAppConfigurationData: {
    ...initRestData,
    isLoading: true,
  },
  createStandAppConfigurationData: {
    ...initRestData,
  },
  updateStandAppConfigurationData: {
    ...initRestData,
  },
  deleteStandAppConfigurationData: {
    ...initRestData,
  },
  standAppConfigurationDeleteModal: {
    id: null,
    isOpen: false,
  },
  detail: {
    id: null,
    isValid: true,
    invalidInputs: 0,
    form: {
      name: {
        ...initInput,
        name: 'name',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: true,
          min: 2,
          max: 255,
        },
      },
      createButton: {
        ...initInput,
        name: 'createButton',
        type: INPUT_TYPE.CHECKBOX,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
      planTab: {
        ...initInput,
        name: 'planTab',
        type: INPUT_TYPE.CHECKBOX,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
      queueTab: {
        ...initInput,
        name: 'queueTab',
        type: INPUT_TYPE.CHECKBOX,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
      standTab: {
        ...initInput,
        name: 'standTab',
        type: INPUT_TYPE.CHECKBOX,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
      delayTab: {
        ...initInput,
        name: 'delayTab',
        type: INPUT_TYPE.CHECKBOX,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
      visitorTab: {
        ...initInput,
        name: 'visitorTab',
        type: INPUT_TYPE.CHECKBOX,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
      isPlanTabMain: {
        ...initInput,
        name: 'isPlanTabMain',
        type: INPUT_TYPE.CHECKBOX,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
      isQueueTabMain: {
        ...initInput,
        name: 'isQueueTabMain',
        type: INPUT_TYPE.CHECKBOX,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
      isStandTabMain: {
        ...initInput,
        name: 'isStandTabMain',
        type: INPUT_TYPE.CHECKBOX,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
      isVisitorTabMain: {
        ...initInput,
        name: 'isVisitorTabMain',
        type: INPUT_TYPE.CHECKBOX,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
      isDelayTabMain: {
        ...initInput,
        name: 'isDelayTabMain',
        type: INPUT_TYPE.CHECKBOX,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
      planTabColumns: {
        ...initInput,
        name: 'planTabColumns',
        type: INPUT_TYPE.MULTISELECT,
      },
      queueTabColumns: {
        ...initInput,
        name: 'queueTabColumns',
        type: INPUT_TYPE.MULTISELECT,
      },
      standTabColumns: {
        ...initInput,
        name: 'standTabColumns',
        type: INPUT_TYPE.MULTISELECT,
      },
      delayTabColumns: {
        ...initInput,
        name: 'delayTabColumns',
        type: INPUT_TYPE.MULTISELECT,
      },
      planTabCallButton: {
        ...initInput,
        name: 'planTabCallButton',
        type: INPUT_TYPE.CHECKBOX,
      },
      standTabCallButton: {
        ...initInput,
        name: 'standTabCallButton',
        type: INPUT_TYPE.CHECKBOX,
      },
    },
    values: {
      name: '',
      createButton: false,
      planTab: false,
      queueTab: false,
      standTab: false,
      visitorTab: false,
      delayTab: false,
      isPlanTabMain: false,
      isQueueTabMain: false,
      isStandTabMain: false,
      isVisitorTabMain: false,
      isDelayTabMain: false,
      planTabColumns: [],
      queueTabColumns: [],
      standTabColumns: [],
      delayTabColumns: [],
      planTabCallButton: false,
      standTabCallButton: false,
    },
    oldValues: {},
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;

  switch (type) {
    /**
     * Refresh Data
     */
    case `${QUEUE_CONFIG__STAND_APP_CONFIGURATION_REFRESH}`: {
      return {
        ...state,
        createStandAppConfigurationData: {
          ...initialState.createStandAppConfigurationData,
        },
        updateStandAppConfigurationData: {
          ...initialState.updateStandAppConfigurationData,
        },
        detail: {
          ...initialState.detail,
        },
      };
    }


    /**
     * Change Form
     */
    case `${QUEUE_CONFIG__STAND_APP_CONFIGURATION_CHANGE_VALUE_DETAIL}`: {
      const newFormData = changeFormValue(
        state.detail.form, state.detail.values, state.detail.invalidInputs, payload
      );

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: newFormData.invalidInputs < 1,
          invalidInputs: newFormData.invalidInputs,
          form: newFormData.form,
          values: newFormData.values,
        },
      };
    }


    /**
     * Validate form
     */
    case `${QUEUE_CONFIG__STAND_APP_CONFIGURATION_VALIDATE_FORM_DETAIL}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload);

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }


    /**
     * GET Stand App Configuration Detail
     */
    case `${QUEUE_CONFIG__GET_STAND_APP_CONFIGURATION_DETAIL}__${PENDING}`: {
      return {
        ...state,
        detailStandAppConfigurationData: {
          ...initialState.detailStandAppConfigurationData,
          ...(handlePending()),
        },
        updateStandAppConfigurationData: {
          ...initialState.updateStandAppConfigurationData,
        },
        detail: {
          ...initialState.detail,
        },
      };
    }

    case `${QUEUE_CONFIG__GET_STAND_APP_CONFIGURATION_DETAIL}__${FULFILLED}`: {
      try {
        const newValues = {
          ...state.detail.values,
          name: payload.data.name,
          createButton: payload.data.createButton,
          planTab: payload.data.planTab,
          queueTab: payload.data.queueTab,
          standTab: payload.data.standTab,
          visitorTab: payload.data.visitorTab,
          delayTab: payload.data.delayTab,
          isPlanTabMain: payload.data.isPlanTabMain,
          isQueueTabMain: payload.data.isQueueTabMain,
          isStandTabMain: payload.data.isStandTabMain,
          isVisitorTabMain: payload.data.isVisitorTabMain,
          isDelayTabMain: payload.data.isDelayTabMain,
          planTabColumns: payload.data.planTabColumns,
          queueTabColumns: payload.data.queueTabColumns,
          standTabColumns: payload.data.standTabColumns,
          delayTabColumns: payload.data.delayTabColumns,
          planTabCallButton: payload.data.planTabCallButton,
          standTabCallButton: payload.data.standTabCallButton,
        };

        return {
          ...state,
          detailStandAppConfigurationData: {
            ...state.detailStandAppConfigurationData,
            ...(handleFulfilled(payload)),
          },
          detail: {
            ...state.detail,
            id: payload.data.id,
            values: newValues,
            oldValues: newValues,
          },
        };
      } catch (err) {
        return {
          ...state,
          detailStandAppConfigurationData: {
            ...state.detailStandAppConfigurationData,
            ...(handleRejected({
              statusCode: -1,
              messageInternal: err.message,
              message: {
                code: 'SOMETHING_HAPPEND',
                params: {},
              },
            })),
          },
        };
      }
    }

    case `${QUEUE_CONFIG__GET_STAND_APP_CONFIGURATION_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        detailStandAppConfigurationData: {
          ...state.detailStandAppConfigurationData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * CREATE Stands Detail
     */
    case `${QUEUE_CONFIG__CREATE_STAND_APP_CONFIGURATION_DETAIL}__${PENDING}`: {
      return {
        ...state,
        createStandAppConfigurationData: {
          ...state.createStandAppConfigurationData,
          ...(handlePending()),
        },
      };
    }

    case `${QUEUE_CONFIG__CREATE_STAND_APP_CONFIGURATION_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        createStandAppConfigurationData: {
          ...state.createStandAppConfigurationData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${QUEUE_CONFIG__CREATE_STAND_APP_CONFIGURATION_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data.data);

      return {
        ...state,
        createStandAppConfigurationData: {
          ...state.createStandAppConfigurationData,
          ...(handleRejected(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${QUEUE_CONFIG__CREATE_STAND_APP_CONFIGURATION_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        createStandAppConfigurationData: {
          ...state.createStandAppConfigurationData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * UPDATE Stands Detail
     */
    case `${QUEUE_CONFIG__UPDATE_STAND_APP_CONFIGURATION_DETAIL}__${PENDING}`: {
      return {
        ...state,
        updateStandAppConfigurationData: {
          ...state.updateStandAppConfigurationData,
          ...(handlePending()),
        },
      };
    }

    case `${QUEUE_CONFIG__UPDATE_STAND_APP_CONFIGURATION_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        updateStandAppConfigurationData: {
          ...state.updateStandAppConfigurationData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${QUEUE_CONFIG__UPDATE_STAND_APP_CONFIGURATION_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data);

      return {
        ...state,
        updateStandAppConfigurationData: {
          ...state.updateStandAppConfigurationData,
          ...(handleRejected(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${QUEUE_CONFIG__UPDATE_STAND_APP_CONFIGURATION_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        updateStandAppConfigurationData: {
          ...state.updateStandAppConfigurationData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * DELETE Stand App Configuration Detail
     */
    case `${QUEUE_CONFIG__DELETE_STAND_APP_CONFIGURATION_DETAIL}__${PENDING}`: {
      return {
        ...state,
        deleteStandAppConfigurationData: {
          ...state.deleteStandAppConfigurationData,
          ...(handlePending()),
        },
      };
    }

    case `${QUEUE_CONFIG__DELETE_STAND_APP_CONFIGURATION_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        deleteStandAppConfigurationData: {
          ...state.deleteStandAppConfigurationData,
          ...(handleFulfilled(payload)),
        },
        standAppConfigurationDeleteModal: {
          ...initialState.standAppConfigurationDeleteModal,
        },
      };
    }

    case `${QUEUE_CONFIG__DELETE_STAND_APP_CONFIGURATION_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        deleteStandAppConfigurationData: {
          ...state.deleteStandAppConfigurationData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
     * TOGGLE Kiosks configuration delete modal
     */
    case `${QUEUE_CONFIG__TOGGLE_STAND_APP_CONFIGURATION_DELETE_MODAL}`: {
      return {
        ...state,
        standAppConfigurationDeleteModal: {
          ...initialState.standAppConfigurationDeleteModal,
          isOpen: payload.isOpen,
          id: payload.id,
        },
        deleteStandAppConfigurationData: initialState.deleteStandAppConfigurationData,
      };
    }

    default:
      return state;
  }
};

export default reducer;
