import React, { Fragment, Component, } from 'react';
import {
  shape, func, arrayOf, object, string, bool,
} from 'prop-types';

import { withLang, } from '../../../../logic/languages/withLang';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Button from '../../../../atoms/Button/Button';
import Input from '../../../../atoms/Input/Input';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';
import InputSelectWrapper from '../../../../components/wrappers/InputSelectWrapper';
import InputTextArea from '../../../../atoms/InputTextArea/InputTextArea';
import CheckBox from '../../../../atoms/CheckBox/CheckBox';
import Trash from '../../../../styles/icons/Trash';

import { KIOSKS_CONFIGURATION_DETAIL_PARAMETERS, } from '../../../../globals';

import TicketPreview from './TicketPreview';

class TicketsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: '',
    };
  }

  toBase64 = (file) => (
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    })
  );

  onChangeFileUpload = async (formPart, e) => {
    const {
      handleOnChangeValue,
    } = this.props;
    try {
      const file = e.target.files[0];
      const base64 = await this.toBase64(file);
      this.setState({ logo: file, });
      handleOnChangeValue(formPart, base64);
    } catch (err) {
      handleOnChangeValue(formPart, '');
    }
  }

  clearValue = (type, formName) => {
    const { handleOnChangeValue, } = this.props;

    switch (type) {
      case 'text':
        handleOnChangeValue(formName, '');
        break;
      case 'select':
        handleOnChangeValue(formName, null);
        break;
      case 'multiselect':
        handleOnChangeValue(formName, []);
        break;
      case 'checkbox':
        handleOnChangeValue(formName, false);
        break;
      default:
        break;
    }
  }


  render() {
    const {
      // data
      form,
      values,
      numericalSeriesSharedData,
      transportationTypesData,
      // methods
      getNumericalSeriesShared,
      getTransportationTypesShared,
      handleOnChangeValue,
      langError,
    } = this.props;

    const {
      logo,
    } = this.state;
    return (
      <Fragment>
        <Row>
          <Col MD={14}>
            <Row formGroup>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
                Název
              </Col>
              <Col XS={17} SM={17} MD_offset={3} MD={17} LG_offset={0} LG={12} sidePadding>
                <Input
                  type="text"
                  value={values.name}
                  status={form.name.status}
                  onChange={(e) => handleOnChangeValue(form.name.name, e.target.value)}
                />
              </Col>
              <Col MD_offset={3} LG_offset={6} sidePadding>
                <InfoMsg
                  status={form.name.status}
                  msg={langError(form.name.statusMsg)}
                  showIcon
                />
              </Col>
            </Row>

            <Row formGroup>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
                Logo
              </Col>
              <Col XS={17} SM={17} MD_offset={3} MD={17} LG_offset={0} LG={12} sidePadding>
                <Input
                  type="file"
                  name="file"
                  value={logo.fileName}
                  status={form.logo.status}
                  onChange={(e) => this.onChangeFileUpload(form.logo.name, e)}
                />
              </Col>
              <Col XS={1} SM={1}>
                <Button
                  size="sm"
                  color="error"
                  onClick={() => this.clearValue(form.logo.type, form.logo.name)}
                >
                  <Trash />
                </Button>
              </Col>
              <Col MD_offset={3} LG_offset={6} sidePadding>
                <InfoMsg
                  status={form.logo.status}
                  msg={langError(form.logo.statusMsg)}
                  showIcon
                />
              </Col>
            </Row>

            <Row formGroup>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
                Header
              </Col>
              <Col XS={17} SM={17} MD_offset={3} MD={17} LG_offset={0} LG={12} sidePadding>
                <InputTextArea
                  rows={8}
                  value={values.header}
                  status={form.header.status}
                  onChange={(e) => handleOnChangeValue(form.header.name, e.target.value)}
                />
              </Col>
              <Col XS={1} SM={1}>
                <Button
                  size="sm"
                  color="error"
                  onClick={() => this.clearValue(form.header.type, form.header.name)}
                >
                  <Trash />
                </Button>
              </Col>
              <Col MD_offset={3} LG_offset={6} sidePadding>
                <InfoMsg
                  status={form.header.status}
                  msg={langError(form.header.statusMsg)}
                  showIcon
                />
              </Col>
            </Row>

            <Row formGroup>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
                Číselný kód
              </Col>
              <Col XS={17} SM={17} MD_offset={3} MD={17} LG_offset={0} LG={12} sidePadding>
                <InputSelectWrapper
                  value={values.numericalSeriesId}
                  status={form.numericalSeriesId.status}
                  options={numericalSeriesSharedData.data}
                  onChange={(value) => handleOnChangeValue(form.numericalSeriesId.name, value)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isLoading={numericalSeriesSharedData.isLoading}
                  error={numericalSeriesSharedData.error}
                  fetchData={getNumericalSeriesShared}
                />
              </Col>
              <Col XS={1} SM={1}>
                <Button
                  size="sm"
                  color="error"
                  onClick={() => this.clearValue(form.numericalSeriesId.type, form.numericalSeriesId.name)}
                >
                  <Trash />
                </Button>
              </Col>
              <Col MD_offset={3} LG_offset={6} sidePadding>
                <InfoMsg
                  status={form.numericalSeriesId.status}
                  msg={langError(form.numericalSeriesId.statusMsg)}
                  showIcon
                />
              </Col>
            </Row>

            <Row formGroup>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
                Zobrazované informace
              </Col>
              <Col XS={17} SM={17} MD_offset={3} MD={17} LG_offset={0} LG={12} sidePadding>
                <InputSelectWrapper
                  value={values.columns}
                  status={form.columns.status}
                  options={KIOSKS_CONFIGURATION_DETAIL_PARAMETERS}
                  isMulti
                  onChange={(value) => handleOnChangeValue(form.columns.name, value)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                />
              </Col>
              <Col XS={1} SM={1}>
                <Button
                  size="sm"
                  color="error"
                  onClick={() => this.clearValue(form.columns.type, form.columns.name)}
                >
                  <Trash />
                </Button>
              </Col>
              <Col MD_offset={3} LG_offset={6} sidePadding>
                <InfoMsg
                  status={form.columns.status}
                  msg={langError(form.columns.statusMsg)}
                  showIcon
                />
              </Col>
            </Row>

            <Row formGroup>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
                Footer
              </Col>
              <Col XS={17} SM={17} MD_offset={3} MD={17} LG_offset={0} LG={12} sidePadding>
                <InputTextArea
                  rows={8}
                  value={values.footer}
                  status={form.footer.status}
                  onChange={(e) => handleOnChangeValue(form.footer.name, e.target.value)}
                />
              </Col>
              <Col XS={1} SM={1}>
                <Button
                  size="sm"
                  color="error"
                  onClick={() => this.clearValue(form.footer.type, form.footer.name)}
                >
                  <Trash />
                </Button>
              </Col>
              <Col MD_offset={3} LG_offset={6} sidePadding>
                <InfoMsg
                  status={form.footer.status}
                  msg={langError(form.footer.statusMsg)}
                  showIcon
                />
              </Col>
            </Row>

            <Row formGroup>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
              <Col XS={17} SM={17} MD_offset={3} MD={17} LG_offset={0} LG={12} sidePadding>
                <CheckBox
                  id={form.barCode.name}
                  text="Zobrazit čárový kód?"
                  checked={values.barCode}
                  onChange={() => handleOnChangeValue(
                    form.barCode.name,
                    !values.barCode
                  )}
                />
              </Col>
              <Col XS={1} SM={1}>
                <Button
                  size="sm"
                  color="error"
                  onClick={() => this.clearValue(form.barCode.type, form.barCode.name)}
                >
                  <Trash />
                </Button>
              </Col>
              <Col MD_offset={3} LG_offset={6} sidePadding>
                <InfoMsg
                  status={form.barCode.status}
                  msg={langError(form.barCode.statusMsg)}
                  showIcon
                />
              </Col>
            </Row>

            <Row formGroup>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
                Použít u těchto typů přeprav
              </Col>
              <Col XS={17} SM={17} MD_offset={3} MD={17} LG_offset={0} LG={12} sidePadding>
                <InputSelectWrapper
                  value={values.transportationTypes}
                  status={form.transportationTypes.status}
                  options={transportationTypesData.data}
                  onChange={(value) => handleOnChangeValue(form.transportationTypes.name, value)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isLoading={transportationTypesData.isLoading}
                  error={transportationTypesData.error}
                  fetchData={getTransportationTypesShared}
                  isMulti
                />
              </Col>
              <Col XS={1} SM={1}>
                <Button
                  size="sm"
                  color="error"
                  onClick={() => this.clearValue(form.transportationTypes.type, form.transportationTypes.name)}
                >
                  <Trash />
                </Button>
              </Col>
              <Col MD_offset={3} LG_offset={6} sidePadding>
                <InfoMsg
                  status={form.transportationTypes.status}
                  msg={langError(form.transportationTypes.statusMsg)}
                  showIcon
                />
              </Col>
            </Row>

          </Col>
          <Col MD={10} SM={24}>
            <TicketPreview
              values={values}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

TicketsDetail.propTypes = {
  form: shape({
    name: shape({
      name: string.isRequired,
      type: string.isRequired,
      validation: object.isRequired,
      status: string.isRequired,
      info: string.isRequired,
      statusMsg: object.isRequired,
    }),
    logo: shape({
      name: string.isRequired,
      type: string.isRequired,
      validation: object.isRequired,
      status: string.isRequired,
      info: string.isRequired,
      statusMsg: object.isRequired,
    }),
  }).isRequired,
  values: shape({
    name: string,
    logo: string,
  }).isRequired,
  handleOnChangeValue: func.isRequired,
  numericalSeriesSharedData: object.isRequired,
  getNumericalSeriesShared: func.isRequired,
  getTransportationTypesShared: func.isRequired,
  transportationTypesData: object.isRequired,
  langError: func.isRequired,
};


export default withLang(TicketsDetail);
