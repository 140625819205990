import {
  SHARED__GET_SHARED_DATA,
  SHARED__GET_KIOSK_CONFIGURATIONS_SHOW_INFORMATION,
  SHARED__GET_KIOSK_CONFIGURATIONS_AUTH_PARAMS,
  SHARED__GET_KIOSK_CONFIGURATIONS,
  SHARED__GET_CONFIGURATION,
  SHARED__GET_PLATFORMS,
  SHARED__GET_TRANSPORTATION_TYPES,
  SHARED__GET_ACTIONS,
  SHARED__GET_STANDS,
  SHARED__GET_DEVICES,
  SHARED__GET_DISPLAYS,
  SHARED__GET_STAND_APP_CONFIGURATION,
  SHARED__GET_STAND_APP_CONFIGURATION_COLUMNS,
  SHARED__GET_DISPLAY_SUBTYPES,
  SHARED__GET_DISPLAY_CONFIGURATION,
  SHARED__GET_NUMERICAL_SERIES,
  SHARED__GET_CAMERAS,
  SHARED__GET_TICKETS,
} from '../actions/shared';
import { PENDING, FULFILLED, REJECTED, } from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';


const convertArrayToObject = (array, id = 'id') => {
  const newObject = {};

  for (let i = 0; i < array.length; i++) {
    newObject[array[i][id]] = {
      ...array[i],
    };
  }

  return newObject;
};


const parseSharedObject = (array, dataType) => {
  const newObject = {};
  const { id, name, translation, } = dataType;


  for (let i = 0; i < array.length; i++) {
    const item = array[i];

    newObject[item[id]] = {
      ...item,
      [name]: translation === null ? item[name] : item[translation][name],
    };
  }

  return newObject;
};

const parseObjectKeyValue = (object) => {
  const newObject = {};
  Object.keys(object).forEach((key) => {
    newObject[key] = { id: key, name: object[key], };
  });
  return newObject;
};

const initialState = {
  languagesData: {
    ...initRestData,
    data: {},
  },
  kioskConfigurationsShowInformationData: {
    ...initRestData,
    data: {},
  },
  kioskConfigurationsAuthParamsData: {
    ...initRestData,
    data: {},
  },
  kioskConfigurationsData: {
    ...initRestData,
    data: {},
  },
  configurationData: {
    ...initRestData,
    data: {},
  },
  platformsData: {
    ...initRestData,
    data: {},
  },
  transportationTypesData: {
    ...initRestData,
    data: {},
  },
  actionsData: {
    ...initRestData,
    data: {},
  },
  standsData: {
    ...initRestData,
    data: {},
  },
  devicesData: {
    ...initRestData,
    data: {},
  },
  displaysData: {
    ...initRestData,
    data: {},
  },
  displaySubtypesData: {
    ...initRestData,
    data: {},
  },
  displayConfigurationData: {
    ...initRestData,
    data: {},
  },
  standAppConfigurationData: {
    ...initRestData,
    data: {},
  },
  standAppConfigurationColumnsData: {
    ...initRestData,
    data: {},
  },
  formsData: {
    ...initRestData,
    data: {},
  },
  regexesData: {
    ...initRestData,
    data: {},
  },
  groupData: {
    ...initRestData,
    data: {},
  },
  optionsData: {
    ...initRestData,
    data: {},
  },
  btnActionsData: {
    ...initRestData,
    data: {},
  },
  numericalSeriesData: {
    ...initRestData,
    data: {},
  },
  camerasData: {
    ...initRestData,
    data: {},
  },
  ticketsData: {
    ...initRestData,
    data: {},
  },
};


const reducer = (state = initialState, action) => {
  const { type, payload, } = action;

  switch (type) {
    //
    // Common Reducer
    //
    case `${SHARED__GET_SHARED_DATA}__${PENDING}`: {
      return {
        ...state,
        [payload.dataType.type]: {
          ...state[payload.dataType.type],
          ...(handlePending()),
        },
      };
    }

    case `${SHARED__GET_SHARED_DATA}__${FULFILLED}`: {
      return {
        ...state,
        [payload.dataType.type]: {
          ...state[payload.dataType.type],
          ...(handleFulfilled(payload.response)),
          data: parseSharedObject(payload.response.data, payload.dataType),
        },
      };
    }

    case `${SHARED__GET_SHARED_DATA}__${REJECTED}`: {
      return {
        ...state,
        [payload.dataType.type]: {
          ...state[payload.dataType.type],
          ...(handleRejected(payload.response)),
        },
      };
    }

    /**
     * GET Kiosk configurations auth params shared
     */
    case `${SHARED__GET_KIOSK_CONFIGURATIONS_SHOW_INFORMATION}__${PENDING}`: {
      return {
        ...state,
        kioskConfigurationsShowInformationData: {
          ...state.kioskConfigurationsShowInformationData,
          ...(handlePending()),
        },
      };
    }

    case `${SHARED__GET_KIOSK_CONFIGURATIONS_SHOW_INFORMATION}__${FULFILLED}`: {
      return {
        ...state,
        kioskConfigurationsShowInformationData: {
          ...state.kioskConfigurationsShowInformationData,
          ...(handleFulfilled(payload)),
          data: convertArrayToObject(payload.data),
        },
      };
    }

    case `${SHARED__GET_KIOSK_CONFIGURATIONS_SHOW_INFORMATION}__${REJECTED}`: {
      return {
        ...state,
        kioskConfigurationsShowInformationData: {
          ...state.kioskConfigurationsShowInformationData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
     * GET Kiosk configurations auth params shared
     */
    case `${SHARED__GET_KIOSK_CONFIGURATIONS_AUTH_PARAMS}__${PENDING}`: {
      return {
        ...state,
        kioskConfigurationsAuthParamsData: {
          ...state.kioskConfigurationsAuthParamsData,
          ...(handlePending()),
        },
      };
    }

    case `${SHARED__GET_KIOSK_CONFIGURATIONS_AUTH_PARAMS}__${FULFILLED}`: {
      return {
        ...state,
        kioskConfigurationsAuthParamsData: {
          ...state.kioskConfigurationsAuthParamsData,
          ...(handleFulfilled(payload)),
          data: convertArrayToObject(payload.data),
        },
      };
    }

    case `${SHARED__GET_KIOSK_CONFIGURATIONS_AUTH_PARAMS}__${REJECTED}`: {
      return {
        ...state,
        kioskConfigurationsAuthParamsData: {
          ...state.kioskConfigurationsAuthParamsData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
     * GET Kiosk configurations shared
     */
    case `${SHARED__GET_KIOSK_CONFIGURATIONS}__${PENDING}`: {
      return {
        ...state,
        kioskConfigurationsData: {
          ...state.kioskConfigurationsData,
          ...(handlePending()),
        },
      };
    }

    case `${SHARED__GET_KIOSK_CONFIGURATIONS}__${FULFILLED}`: {
      return {
        ...state,
        kioskConfigurationsData: {
          ...state.kioskConfigurationsData,
          ...(handleFulfilled(payload)),
          data: convertArrayToObject(payload.data),
        },
      };
    }

    case `${SHARED__GET_KIOSK_CONFIGURATIONS}__${REJECTED}`: {
      return {
        ...state,
        kioskConfigurationsData: {
          ...state.kioskConfigurationsData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
     * GET Configuration shared
     */
    case `${SHARED__GET_CONFIGURATION}__${PENDING}`: {
      return {
        ...state,
        configurationData: {
          ...state.configurationData,
          ...(handlePending()),
        },
      };
    }

    case `${SHARED__GET_CONFIGURATION}__${FULFILLED}`: {
      return {
        ...state,
        configurationData: {
          ...state.configurationData,
          ...(handleFulfilled(payload)),
          data: convertArrayToObject(payload.data),
        },
      };
    }

    case `${SHARED__GET_CONFIGURATION}__${REJECTED}`: {
      return {
        ...state,
        configurationData: {
          ...state.configurationData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * GET Platform shared
     */
    case `${SHARED__GET_PLATFORMS}__${PENDING}`: {
      return {
        ...state,
        platformsData: {
          ...state.platformsData,
          ...(handlePending()),
        },
      };
    }

    case `${SHARED__GET_PLATFORMS}__${FULFILLED}`: {
      return {
        ...state,
        platformsData: {
          ...state.platformsData,
          ...(handleFulfilled(payload)),
          data: convertArrayToObject(payload.data.map((row) => ({
            ...row,
            name: row.name,
          }))),
        },
      };
    }

    case `${SHARED__GET_PLATFORMS}__${REJECTED}`: {
      return {
        ...state,
        platformsData: {
          ...state.platformsData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * GET Platform shared
     */
    case `${SHARED__GET_TRANSPORTATION_TYPES}__${PENDING}`: {
      return {
        ...state,
        transportationTypesData: {
          ...state.transportationTypesData,
          ...(handlePending()),
        },
      };
    }

    case `${SHARED__GET_TRANSPORTATION_TYPES}__${FULFILLED}`: {
      return {
        ...state,
        transportationTypesData: {
          ...state.transportationTypesData,
          ...(handleFulfilled(payload)),
          data: convertArrayToObject(
            payload.data.map(
              (row) => ({ id: row.id, name: row.name, })
            )
          ),
        },
      };
    }

    case `${SHARED__GET_TRANSPORTATION_TYPES}__${REJECTED}`: {
      return {
        ...state,
        transportationTypesData: {
          ...state.transportationTypesData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * GET Actions shared
     */
    case `${SHARED__GET_ACTIONS}__${PENDING}`: {
      return {
        ...state,
        actionsData: {
          ...state.actionsData,
          ...(handlePending()),
        },
      };
    }

    case `${SHARED__GET_ACTIONS}__${FULFILLED}`: {
      return {
        ...state,
        actionsData: {
          ...state.actionsData,
          ...(handleFulfilled(payload)),
          data: convertArrayToObject(payload.data.map((row) => ({
            ...row,
            name: row.actionTranslation.name,
          }))),
        },
      };
    }

    case `${SHARED__GET_ACTIONS}__${REJECTED}`: {
      return {
        ...state,
        actionsData: {
          ...state.actionsData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * GET Stand shared
     */
    case `${SHARED__GET_STANDS}__${PENDING}`: {
      return {
        ...state,
        standsData: {
          ...state.standsData,
          ...(handlePending()),
        },
      };
    }

    case `${SHARED__GET_STANDS}__${FULFILLED}`: {
      return {
        ...state,
        standsData: {
          ...state.standsData,
          ...(handleFulfilled(payload)),
          data: convertArrayToObject(payload.data.map((row) => ({
            ...row,
            name: row.standTranslation.name,
          }))),
        },
      };
    }

    case `${SHARED__GET_STANDS}__${REJECTED}`: {
      return {
        ...state,
        standsData: {
          ...state.standsData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
     * GET Devices shared
     */
    case `${SHARED__GET_DEVICES}__${PENDING}`: {
      return {
        ...state,
        devicesData: {
          ...state.devicesData,
          ...(handlePending()),
        },
      };
    }

    case `${SHARED__GET_DEVICES}__${FULFILLED}`: {
      return {
        ...state,
        devicesData: {
          ...state.devicesData,
          ...(handleFulfilled(payload)),
          data: convertArrayToObject(payload.data.filter(
            (item) => !Object.prototype.hasOwnProperty.call(item, 'synchronized') || item.synchronized === true
          )),
        },
      };
    }

    case `${SHARED__GET_DEVICES}__${REJECTED}`: {
      return {
        ...state,
        devicesData: {
          ...state.devicesData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * GET Dispalys shared
     */
    case `${SHARED__GET_DISPLAYS}__${PENDING}`: {
      return {
        ...state,
        displaysData: {
          ...state.displaysData,
          ...(handlePending()),
        },
      };
    }

    case `${SHARED__GET_DISPLAYS}__${FULFILLED}`: {
      return {
        ...state,
        displaysData: {
          ...state.displaysData,
          ...(handleFulfilled(payload)),
          data: convertArrayToObject(payload.data),
        },
      };
    }

    case `${SHARED__GET_DISPLAYS}__${REJECTED}`: {
      return {
        ...state,
        displaysData: {
          ...state.displaysData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
     * GET Stand App configuration shared
    */
    case `${SHARED__GET_STAND_APP_CONFIGURATION}__${PENDING}`: {
      return {
        ...state,
        standAppConfigurationData: {
          ...state.standAppConfigurationData,
          ...(handlePending()),
        },
      };
    }

    case `${SHARED__GET_STAND_APP_CONFIGURATION}__${FULFILLED}`: {
      return {
        ...state,
        standAppConfigurationData: {
          ...state.standAppConfigurationData,
          ...(handleFulfilled(payload)),
          data: convertArrayToObject(payload.data),
        },
      };
    }

    case `${SHARED__GET_STAND_APP_CONFIGURATION}__${REJECTED}`: {
      return {
        ...state,
        standAppConfigurationData: {
          ...state.standAppConfigurationData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
     * GET Stand App configuration Columns shared
    */
    case `${SHARED__GET_STAND_APP_CONFIGURATION_COLUMNS}__${PENDING}`: {
      return {
        ...state,
        standAppConfigurationColumnsData: {
          ...state.standAppConfigurationColumnsData,
          ...(handlePending()),
        },
      };
    }

    case `${SHARED__GET_STAND_APP_CONFIGURATION_COLUMNS}__${FULFILLED}`: {
      return {
        ...state,
        standAppConfigurationColumnsData: {
          ...state.standAppConfigurationColumnsData,
          ...(handleFulfilled(payload)),
          data: parseObjectKeyValue(payload.data),
        },
      };
    }

    case `${SHARED__GET_STAND_APP_CONFIGURATION_COLUMNS}__${REJECTED}`: {
      return {
        ...state,
        standAppConfigurationColumnsData: {
          ...state.standAppConfigurationColumnsData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
    * GET Display subtypes shared
   */
    case `${SHARED__GET_DISPLAY_SUBTYPES}__${PENDING}`: {
      return {
        ...state,
        displaySubtypesData: {
          ...state.displaySubtypesData,
          ...(handlePending()),
        },
      };
    }

    case `${SHARED__GET_DISPLAY_SUBTYPES}__${FULFILLED}`: {
      return {
        ...state,
        displaySubtypesData: {
          ...state.displaySubtypesData,
          ...(handleFulfilled(payload)),
          data: parseObjectKeyValue(payload.data),
        },
      };
    }

    case `${SHARED__GET_DISPLAY_SUBTYPES}__${REJECTED}`: {
      return {
        ...state,
        displaySubtypesData: {
          ...state.displaySubtypesData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
      * GET Display configuration shared
    */
    case `${SHARED__GET_DISPLAY_CONFIGURATION}__${PENDING}`: {
      return {
        ...state,
        displayConfigurationData: {
          ...state.displayConfigurationData,
          ...(handlePending()),
        },
      };
    }

    case `${SHARED__GET_DISPLAY_CONFIGURATION}__${FULFILLED}`: {
      return {
        ...state,
        displayConfigurationData: {
          ...state.displayConfigurationData,
          ...(handleFulfilled(payload)),
          data: convertArrayToObject(payload.data),
        },
      };
    }

    case `${SHARED__GET_DISPLAY_CONFIGURATION}__${REJECTED}`: {
      return {
        ...state,
        displayConfigurationData: {
          ...state.displayConfigurationData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
      * GET Numerical series dropdown shared
    */
    case `${SHARED__GET_NUMERICAL_SERIES}__${PENDING}`: {
      return {
        ...state,
        numericalSeriesData: {
          ...state.numericalSeriesData,
          ...(handlePending()),
        },
      };
    }

    case `${SHARED__GET_NUMERICAL_SERIES}__${FULFILLED}`: {
      return {
        ...state,
        numericalSeriesData: {
          ...state.numericalSeriesData,
          ...(handleFulfilled(payload)),
          data: convertArrayToObject(payload.data),
        },
      };
    }

    case `${SHARED__GET_NUMERICAL_SERIES}__${REJECTED}`: {
      return {
        ...state,
        numericalSeriesData: {
          ...state.numericalSeriesData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
      * GET Cameras shared
    */
    case `${SHARED__GET_CAMERAS}__${PENDING}`: {
      return {
        ...state,
        camerasData: {
          ...state.camerasData,
          ...(handlePending()),
        },
      };
    }

    case `${SHARED__GET_CAMERAS}__${FULFILLED}`: {
      return {
        ...state,
        camerasData: {
          ...state.camerasData,
          ...(handleFulfilled(payload)),
          data: convertArrayToObject(payload.data),
        },
      };
    }

    case `${SHARED__GET_CAMERAS}__${REJECTED}`: {
      return {
        ...state,
        camerasData: {
          ...state.camerasData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
      * GET Tickets shared
    */
    case `${SHARED__GET_TICKETS}__${PENDING}`: {
      return {
        ...state,
        ticketsData: {
          ...state.ticketsData,
          ...(handlePending()),
        },
      };
    }

    case `${SHARED__GET_TICKETS}__${FULFILLED}`: {
      return {
        ...state,
        ticketsData: {
          ...state.ticketsData,
          ...(handleFulfilled(payload)),
          data: convertArrayToObject(payload.data),
        },
      };
    }

    case `${SHARED__GET_TICKETS}__${REJECTED}`: {
      return {
        ...state,
        ticketsData: {
          ...state.ticketsData,
          ...(handleRejected(payload)),
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
