import React, { Component, } from 'react';
import { func, number, } from 'prop-types';

import StyledAddItem from './styles/StyledAddItem';
import Add from '../../../../styles/icons/Add';

class AddItem extends Component {
  handleAdd = () => {
    const {
      position,
      onAdd,
    } = this.props;

    onAdd(position);
  }


  render() {
    return (
      <StyledAddItem>
        <hr />
        <button
          type="button"
          onClick={this.handleAdd}
        >
          <Add />
        </button>
      </StyledAddItem>
    );
  }
}


AddItem.propTypes = {
  // data
  position: number.isRequired,
  // methods
  onAdd: func.isRequired,
};


export default AddItem;
