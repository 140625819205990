import React, { Component, } from 'react';
import ReactDOM from 'react-dom';
import {
  oneOfType, arrayOf, number, shape, node,
} from 'prop-types';


class DnDDragging extends Component {
  constructor(props) {
    super(props);

    this.state = {
      x: props.mouseDownPos.x,
      y: props.mouseDownPos.y,
    };
  }

  componentWillMount() {
    document.addEventListener('mousemove', this.handleMouseMove);
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.handleMouseMove);
  }

  handleMouseMove = (e) => {
    const { children, } = this.props;
    e.preventDefault();
    e.stopPropagation();

    if (children) {
      this.setState({
        x: e.pageX,
        y: e.pageY,
      });
    }
  }

  render() {
    const { x, y, } = this.state;
    const { children, } = this.props;

    return ReactDOM.createPortal((
      <div
        style={{
          position: 'absolute',
          top: `${y - 4}px`,
          left: `${x - 4}px`,
          pointerEvents: 'none',
          backgroundColor: 'inherit',
          zIndex: 900,
        }}
      >
        { children }
      </div>
    ),
    document.body);
  }
}


DnDDragging.propTypes = {
  mouseDownPos: shape({
    x: number.isRequired,
    y: number.isRequired,
  }).isRequired,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};


export default DnDDragging;
