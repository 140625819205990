import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION, FORM_SUCCESS,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';

const parseValues = (values) => ({
  name: values.name,
  code: values.code,
});


/**
 * Refresh data - on mount
 */
export const LANGUAGES__REFRESH = 'LANGUAGES__REFRESH';
export const refreshLanguagesDetailAction = () => ({
  type: `${LANGUAGES__REFRESH}`,
});


/**
 * Change Value
 */
export const LANGUAGES__CHANGE_VALUE_DETAIL = 'LANGUAGES__CHANGE_VALUE_DETAIL';
export const changeValueAction = (name, value, validationError) => ({
  type: `${LANGUAGES__CHANGE_VALUE_DETAIL}`,
  payload: {
    name,
    value,
    validationError,
  },
});


/**
 * Validate Form
 */
export const LANGUAGES__VALIDATE_FORM_DETAIL = 'LANGUAGES__VALIDATE_FORM_DETAIL';
export const validateFormAction = (error) => ({
  type: `${LANGUAGES__VALIDATE_FORM_DETAIL}`,
  payload: error,
});


/*
 * GET Languages Detail
 */
export const LANGUAGES__GET_LANGUAGES_DETAIL = 'LANGUAGES__GET_LANGUAGES_DETAIL';
export const getLanguagesDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${LANGUAGES__GET_LANGUAGES_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/settings/languages/detail?id=${id}`,
    });

    dispatch({
      type: `${LANGUAGES__GET_LANGUAGES_DETAIL}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${LANGUAGES__GET_LANGUAGES_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * CREATE Languages Detail
 */
export const LANGUAGES__CREATE_LANGUAGES_DETAIL = 'LANGUAGES__CREATE_LANGUAGES_DETAIL';
export const createLanguagesDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${LANGUAGES__CREATE_LANGUAGES_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/settings/languages`,
      data: {
        values: parseValues(item.values),
      },
    });

    dispatch({
      type: `${LANGUAGES__CREATE_LANGUAGES_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Jazyk vytvořen',
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${LANGUAGES__CREATE_LANGUAGES_DETAIL}__${REJECTED_VALIDATION}`,
        payload: parsedError,
      });
      return;
    }

    dispatch({
      type: `${LANGUAGES__CREATE_LANGUAGES_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * UPDATE Languages Detail
 */
export const LANGUAGES__UPDATE_LANGUAGES_DETAIL = 'LANGUAGES__UPDATE_LANGUAGES_DETAIL';
export const updateLanguagesDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${LANGUAGES__UPDATE_LANGUAGES_DETAIL}__${PENDING}`,
    });

    const values = parseValues(item.values);
    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/settings/languages`,
      data: {
        id: item.id,
        values,
      },
    });

    dispatch({
      type: `${LANGUAGES__UPDATE_LANGUAGES_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Jazyk upraven',
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${LANGUAGES__UPDATE_LANGUAGES_DETAIL}__${REJECTED_VALIDATION}`,
        payload: parsedError,
      });
      return;
    }

    dispatch({
      type: `${LANGUAGES__UPDATE_LANGUAGES_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};

/*
 * DELETE Languages Detail
 */
export const LANGUAGES__DELETE_LANGUAGES_DETAIL = 'LANGUAGES__DELETE_LANGUAGES_DETAIL';
export const deleteLanguagesDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${LANGUAGES__DELETE_LANGUAGES_DETAIL}__${PENDING}`,
      payload: {
        id,
      },
    });

    const response = await api({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/settings/languages?id=${id}`,
    });

    dispatch({
      type: `${LANGUAGES__DELETE_LANGUAGES_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        id,
        notif: {
          type: FORM_SUCCESS,
          title: 'Jazyk smazán',
        },
      },
    });
  } catch (err) {
    const parsedError = handleError(err);

    dispatch({
      type: `${LANGUAGES__DELETE_LANGUAGES_DETAIL}__${REJECTED}`,
      payload: {
        ...parsedError,
        id,
        message: parsedError.statusCode === 428 ? {
          code: 'CANT_DELETE',
          params: {},
        } : parsedError.message,
      },
    });
  }
};

/*
 * Toggle Languages delete Modal
 */
export const LANGUAGES__TOGGLE_DELETE_LANGUAGES_DELETE_MODAL = 'LANGUAGES__TOGGLE_DELETE_LANGUAGES_DELETE_MODAL';
export const toggleLanguageDeleteModalAction = (isOpen, id = null) => ({
  type: `${LANGUAGES__TOGGLE_DELETE_LANGUAGES_DELETE_MODAL}`,
  payload: {
    id,
    isOpen,
  },
});
