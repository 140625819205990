import React, { Component, } from 'react';
import {
  shape, bool, func, object,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  createPlatformsDetailAction,
  validateFormAction,
  changeValueAction,
  refreshPlatformsDetailAction,
} from '../actions/platformsDetail';
import {
  getTransportationTypesSharedAction,
} from '../../shared/actions/shared';
import {
  validateInput, validateWholeFormValidator,
} from '../../../logic/forms/validation';
import Modal from '../../../atoms/Modal/Modal';
import PlatformsDetail from '../components/PlatformsDetail/PlatformsDetail';
import PlatformsCreate from '../components/PlatformsCreate/PlatformsCreate';


class PlatformsCreateModal extends Component {
  componentDidMount() {
    const {
      refreshPlatformsDetail,
    } = this.props;

    refreshPlatformsDetail();
  }

  handleClose = () => {
    const { history, } = this.props;

    history.goBack();
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);

    changeValue(name, value, validationError);
  }

  handleCreate = () => {
    const {
      createPlatformsData,
      detail,
      validateForm,
      createPlatformsDetail,
    } = this.props;

    if (createPlatformsData.isLoading || !detail.isValid) return;

    const validation = validateWholeFormValidator(detail.form, detail.values);
    if (Object.keys(validation).length < 1) {
      createPlatformsDetail(detail, this.handleCreateSuccess);
    } else {
      validateForm(validation);
    }
  }

  handleCreateSuccess = () => {
    const { refreshParent, } = this.props;

    refreshParent();
    this.handleClose();
  }

  render() {
    const {
      createPlatformsData,
      transportationTypesSharedData,
      detail,
      getTransportationTypesShared,
    } = this.props;

    return (
      <Modal
        isOpen
        onClose={this.handleClose}
        closeOnEsc
        size="MD"
        title="Vytvoření rampy"
      >
        <PlatformsCreate
          // data
          createPlatformsData={createPlatformsData}
          isFormValid={detail.isValid}
          // methods
          handleClose={this.handleClose}
          handleCreate={this.handleCreate}
        >
          <PlatformsDetail
            // data
            values={detail.values}
            form={detail.form}
            transportationTypesSharedData={transportationTypesSharedData}
            // methods
            handleClose={this.handleClose}
            handleOnChangeValue={this.handleOnChangeValue}
            getTransportationTypesShared={getTransportationTypesShared}
          />
        </PlatformsCreate>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { platformsDetail, shared, } = state;
  return {
    createPlatformsData: platformsDetail.createPlatformsData,
    detail: platformsDetail.detail,
    // dropdowns
    transportationTypesSharedData: shared.transportationTypesData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createPlatformsDetail: bindActionCreators(createPlatformsDetailAction, dispatch),
  getTransportationTypesShared: bindActionCreators(getTransportationTypesSharedAction, dispatch),
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
  refreshPlatformsDetail: bindActionCreators(refreshPlatformsDetailAction, dispatch),
});


PlatformsCreateModal.propTypes = {
  createPlatformsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  transportationTypesSharedData: object.isRequired,
  detail: shape({
    isValid: bool.isRequired,
    form: object.isRequired,
    values: object.isRequired,
  }).isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
  getTransportationTypesShared: func.isRequired,
  createPlatformsDetail: func.isRequired,
  refreshPlatformsDetail: func.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
  refreshParent: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(PlatformsCreateModal);
