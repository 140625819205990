import { INPUT_TYPE, } from 'sofair-form-validation';

import {
  NUMERICAL_SERIES__CHANGE_VALUE_DETAIL,
  NUMERICAL_SERIES__GET_DETAIL,
  NUMERICAL_SERIES__CREATE_DETAIL,
  NUMERICAL_SERIES__REFRESH,
  NUMERICAL_SERIES__VALIDATE_FORM_DETAIL,
  NUMERICAL_SERIES__UPDATE_DETAIL,
} from '../actions/numericalSeriesDetail';
import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';
import {
  mergeFormAndValidation, initInput, changeFormValue,
} from '../../../logic/forms/validation';


const initialState = {
  detailNumericalSeriesData: {
    ...initRestData,
    isLoading: true,
  },
  createNumericalSeriesData: {
    ...initRestData,
  },
  updateNumericalSeriesData: {
    ...initRestData,
  },
  detail: {
    id: null,
    isValid: true,
    invalidInputs: 0,
    form: {
      name: {
        ...initInput,
        name: 'name',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: true,
          min: 2,
          max: 255,
        },
      },
      prefix: {
        ...initInput,
        name: 'prefix',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: false,
          min: 1,
          max: 10,
        },
      },
      postfix: {
        ...initInput,
        name: 'postfix',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: false,
          min: 1,
          max: 999,
        },
      },
      numbersCount: {
        ...initInput,
        name: 'numbersCount',
        type: INPUT_TYPE.NUMBER,
        validation: {
          ...initInput.validation,
          required: true,
          min: 1,
          max: 100000,
        },
      },
    },
    values: {
      name: '',
      prefix: '',
      postfix: '',
      numbersCount: '0',
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;

  switch (type) {
    /**
     * Refresh Data
     */
    case `${NUMERICAL_SERIES__REFRESH}`: {
      return {
        ...state,
        detail: {
          ...initialState.detail,
        },
      };
    }


    /**
     * Change Form
     */
    case `${NUMERICAL_SERIES__CHANGE_VALUE_DETAIL}`: {
      const newFormData = changeFormValue(
        state.detail.form, state.detail.values, state.detail.invalidInputs, payload
      );

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: newFormData.invalidInputs < 1,
          invalidInputs: newFormData.invalidInputs,
          form: newFormData.form,
          values: newFormData.values,
        },
      };
    }


    /**
     * Validate form
     */
    case `${NUMERICAL_SERIES__VALIDATE_FORM_DETAIL}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload);

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }


    /**
     * GET Detail
     */
    case `${NUMERICAL_SERIES__GET_DETAIL}__${PENDING}`: {
      return {
        ...state,
        detailNumericalSeriesData: {
          ...state.detailNumericalSeriesData,
          ...(handlePending()),
        },
        detail: {
          ...initialState.detail,
        },
      };
    }

    case `${NUMERICAL_SERIES__GET_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        detailNumericalSeriesData: {
          ...state.detailNumericalSeriesData,
          ...(handleFulfilled(payload)),
        },
        detail: {
          ...state.detail,
          id: payload.data.id,
          values: {
            ...state.detail.values,
            name: payload.data.name,
            prefix: payload.data.prefix,
            postfix: payload.data.postfix,
            numbersCount: payload.data.numbersCount.toString(),
          },
        },
      };
    }

    case `${NUMERICAL_SERIES__GET_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        detailNumericalSeriesData: {
          ...state.detailNumericalSeriesData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * CREATE Detail
     */
    case `${NUMERICAL_SERIES__CREATE_DETAIL}__${PENDING}`: {
      return {
        ...state,
        createNumericalSeriesData: {
          ...state.createNumericalSeriesData,
          ...(handlePending()),
        },
      };
    }

    case `${NUMERICAL_SERIES__CREATE_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        createNumericalSeriesData: {
          ...state.createNumericalSeriesData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${NUMERICAL_SERIES__CREATE_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data.data);

      return {
        ...state,
        createNumericalSeriesData: {
          ...state.createNumericalSeriesData,
          ...(handleFulfilled(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${NUMERICAL_SERIES__CREATE_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        createNumericalSeriesData: {
          ...state.createNumericalSeriesData,
          ...(handleRejected(payload)),
        },
      };
    }

    /*
    * UPDATE DETAIL
    */
    case `${NUMERICAL_SERIES__UPDATE_DETAIL}__${PENDING}`: {
      return {
        ...state,
        updateNumericalSeriesData: {
          ...state.updateNumericalSeriesData,
          ...(handlePending()),
        },
      };
    }

    case `${NUMERICAL_SERIES__UPDATE_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        updateNumericalSeriesData: {
          ...state.updateNumericalSeriesData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${NUMERICAL_SERIES__UPDATE_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data.data);

      return {
        ...state,
        updateNumericalSeriesData: {
          ...state.updateNumericalSeriesData,
          ...(handleFulfilled(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${NUMERICAL_SERIES__UPDATE_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        updateNumericalSeriesData: {
          ...state.updateNumericalSeriesData,
          ...(handleRejected(payload)),
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
