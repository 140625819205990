
import styled from 'styled-components';

const RowError = styled.div`
    color: ${(p) => p.theme.error.t800};
    font-weight: 400;
    padding-left: 9%;
`;

export default RowError;
