import {
  PENDING, FULFILLED, REJECTED, FORM_SUCCESS,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


const parseValues = (values) => ({
  transportationInPlanTabBeforeNowInMinutes: values.transportationInPlanTabBeforeNowInMinutes,
  transportationInPlanTabAfterNowInMinutes: values.transportationInPlanTabAfterNowInMinutes,
  transportationInDelayTabBeforePlanInMinutes: values.transportationInDelayTabBeforePlanInMinutes,
});


/**
 * Change Value
 */
export const QUEUE_CONFIG__CHANGE_VALUE_SETTINGS = 'QUEUE_CONFIG__CHANGE_VALUE_SETTINGS';
export const changeValueAction = (name, value, validationError) => ({
  type: `${QUEUE_CONFIG__CHANGE_VALUE_SETTINGS}`,
  payload: {
    name,
    value,
    validationError,
  },
});


/**
 * Validate Form
 */
export const QUEUE_CONFIG__VALIDATE_FORM_SETTINGS = 'QUEUE_CONFIG__VALIDATE_FORM_SETTINGS';
export const validateFormAction = (error) => ({
  type: `${QUEUE_CONFIG__VALIDATE_FORM_SETTINGS}`,
  payload: error,
});


/*
 * GET  Settings
 */
export const QUEUE_CONFIG__GET_SETTINGS = 'QUEUE_CONFIG__GET_SETTINGS';
export const getSettingsAction = () => async (dispatch) => {
  try {
    dispatch({
      type: `${QUEUE_CONFIG__GET_SETTINGS}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/queue_configuration`,
    });

    dispatch({
      type: `${QUEUE_CONFIG__GET_SETTINGS}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${QUEUE_CONFIG__GET_SETTINGS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * UPDATE  Settings
 */
export const QUEUE_CONFIG__UPDATE_SETTINGS = 'QUEUE_CONFIG__UPDATE_SETTINGS';
export const updateSettingsAction = (item) => async (dispatch) => {
  try {
    dispatch({
      type: `${QUEUE_CONFIG__UPDATE_SETTINGS}__${PENDING}`,
    });

    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/queue_configuration`,
      data: {
        values: parseValues(item.values),
      },
    });

    dispatch({
      type: `${QUEUE_CONFIG__UPDATE_SETTINGS}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Nastavení aktualizováno',
        },
      },
    });
  } catch (err) {
    const parsedError = handleError(err);

    dispatch({
      type: `${QUEUE_CONFIG__UPDATE_SETTINGS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};
