import React from 'react';
import MultiSelectList from '../../atoms/MultiSelectList/MultiSelectList';


const MultiSelectListWrapper = (props) => (
  <MultiSelectList
    {...props}
    loadingMessage="Načítám"
    errorMessage="Nepovedlo se načíst data"
    placeholder="Vyberte"
    getDataMessage="Refresh"
  />
);


export default MultiSelectListWrapper;
