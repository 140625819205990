import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION, FORM_SUCCESS,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


const parseValues = (values) => ({
  defaultLang: values.defaultLang.id,
  kioskDefaultLang: values.kioskDefaultLang.id,
  kioskLangs: values.kioskLangs.map((kioskLang) => (kioskLang.id)),
  centralAppDefaultLang: values.centralAppDefaultLang.id,
  centralAppLangs: values.centralAppLangs.map((centralAppLang) => (centralAppLang.id)),
  storekeeperAppDefaultLang: values.storekeeperAppDefaultLang.id,
  storekeeperAppLangs: values.storekeeperAppLangs.map(
    (storekeeperAppLang) => (storekeeperAppLang.id)
  ),
});


/**
 * Change Value
 */
export const LANGUAGES__CHANGE_VALUE_SETTINGS = 'LANGUAGES__CHANGE_VALUE_SETTINGS';
export const changeValueAction = (name, value, validationError) => ({
  type: `${LANGUAGES__CHANGE_VALUE_SETTINGS}`,
  payload: {
    name,
    value,
    validationError,
  },
});


/**
 * Validate Form
 */
export const LANGUAGES__VALIDATE_FORM_SETTINGS = 'LANGUAGES__VALIDATE_FORM_SETTINGS';
export const validateFormAction = (error) => ({
  type: `${LANGUAGES__VALIDATE_FORM_SETTINGS}`,
  payload: error,
});


/*
 * GET Languages Settings
 */
export const LANGUAGES__GET_LANGUAGES_SETTINGS = 'LANGUAGES__GET_LANGUAGES_SETTINGS';
export const getLanguagesSettingsAction = () => async (dispatch) => {
  try {
    dispatch({
      type: `${LANGUAGES__GET_LANGUAGES_SETTINGS}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/settings/system`,
    });

    dispatch({
      type: `${LANGUAGES__GET_LANGUAGES_SETTINGS}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${LANGUAGES__GET_LANGUAGES_SETTINGS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * UPDATE Languages Settings
 */
export const LANGUAGES__UPDATE_LANGUAGES_SETTINGS = 'LANGUAGES__UPDATE_LANGUAGES_SETTINGS';
export const updateLanguagesSettingsAction = (item) => async (dispatch) => {
  try {
    dispatch({
      type: `${LANGUAGES__UPDATE_LANGUAGES_SETTINGS}__${PENDING}`,
    });

    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/settings/system`,
      data: {
        values: parseValues(item.values),
      },
    });

    dispatch({
      type: `${LANGUAGES__UPDATE_LANGUAGES_SETTINGS}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Nastavení jazyků aktualizováno',
        },
      },
    });
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${LANGUAGES__UPDATE_LANGUAGES_SETTINGS}__${REJECTED_VALIDATION}`,
        payload: parsedError,
      });
      return;
    }

    dispatch({
      type: `${LANGUAGES__UPDATE_LANGUAGES_SETTINGS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};
