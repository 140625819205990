import {
  FORM_ERROR,
  FORM_DEFAULT,
  FORM_TYPES,
} from '../../globals';
import { validateInput, validateWholeForm, } from '../forms/validation';
import {
  inputCommonForm, inputCommonValues, fieldsetCommonProps, groupCommonProps,
} from './inputStructure';


const generateInitValueForInputType = (type) => {
  switch (type) {
    case FORM_TYPES.NUMBER:
    case FORM_TYPES.TEXT:
      return '';

    default:
      return null;
  }
};


export const validateInputAndChangeForm = (part, input, newValue) => {
  const validationErr = validateInput(part.form[input], newValue);
  const isValid = !Object.prototype.hasOwnProperty.call(validationErr, input);

  // set helpers
  const newStatus = isValid ? FORM_DEFAULT : FORM_ERROR;
  const newStatusMsg = isValid
    ? {
      code: '',
      params: {},
    }
    : validationErr[input];

  let newInvalidInputs = part.invalidInputs;
  if (newStatus !== part.form[input].status) {
    if (newStatus === FORM_ERROR) newInvalidInputs++;
    else newInvalidInputs--;
  }

  return {
    ...part,
    isValid: newInvalidInputs < 1,
    invalidInputs: newInvalidInputs,
    form: {
      ...part.form,
      [input]: {
        ...part.form[input],
        status: newStatus,
        statusMsg: newStatusMsg,
      },
    },
  };
};


export const validateTypeInput = (part) => {
  const validationRet = validateWholeForm({
    form: part.form,
    values: part.values,
  });

  return {
    ...part,
    isValid: validationRet.invalidInputs < 1,
    invalidInputs: validationRet.invalidInputs,
    form: {
      ...part.form,
      ...validationRet.form,
    },
  };
};


export const validatePart = (part) => {
  const { type, } = part;

  switch (type) {
    case FORM_TYPES.NUMBER:
    case FORM_TYPES.TEXT:
    case FORM_TYPES.CHECKBOX:
    case FORM_TYPES.RADIO:
    case FORM_TYPES.SELECT:
    case FORM_TYPES.MULTISELECT:
    case FORM_TYPES.TIME:
    case FORM_TYPES.DATE:
    case FORM_TYPES.TIMESTAMP:
      return validateTypeInput(part);

    default:
      return validateWholeForm(part);
  }
};


export const validateNames = (parts) => {
  const keys = Object.keys(parts);
  const addedNames = {};
  const sameNames = [];

  for (let i = 0; i < keys.length; i++) {
    const id = keys[i];

    switch (parts[id].type) {
      case FORM_TYPES.NUMBER:
      case FORM_TYPES.TEXT:
      case FORM_TYPES.CHECKBOX:
      case FORM_TYPES.RADIO:
      case FORM_TYPES.SELECT:
      case FORM_TYPES.MULTISELECT:
      case FORM_TYPES.TIME:
      case FORM_TYPES.DATE:
      case FORM_TYPES.TIMESTAMP: {
        if (Object.prototype.hasOwnProperty.call(addedNames, parts[id].values.name)) {
          sameNames.push(parts[id].values.name);
        }
        addedNames[parts[id].values.name] = parts[id].values.name;
        break;
      }

      default:
        break;
    }
  }

  return sameNames;
};


export const remapMapValue = (map, remapObject) => {
  if (map === null || map === undefined) {
    return null;
  }
  const newMap = {};
  const keys = Object.keys(map);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    newMap[remapObject[key]] = map[key];
  }

  return newMap;
};


export const removeFromObjImmutable = (keys, obj) => {
  const copy = { ...obj, };

  for (let i = 0; i < keys.length; i++) {
    delete copy[keys[i]];
  }
  return copy;
};


export const isDisabledSave = (part) => {
  switch (part.type) {
    case null:
      return true;

    case FORM_TYPES.GROUP: {
      return (
        part.values.group === null
        || part.inputsData.isLoading
        || part.inputsData.error
      );
    }

    default:
      return false;
  }
};


export const getPropsFromObj = (object, props) => {
  const ret = {};
  for (let i = 0; i < props.length; i++) {
    const key = props[i];
    ret[key] = object[key];
  }

  return ret;
};


export const addToRemoveFrom = ({
  state, removeFromId, addToId, nodeId, addToPos,
}) => ({
  ...state,
  structure: {
    ...state.structure,
    [removeFromId]: {
      ...state.structure[removeFromId],
      childs: state.structure[removeFromId].childs.filter((item) => item !== nodeId),
    },
    [addToId]: {
      ...state.structure[addToId],
      childs: [
        ...state.structure[addToId].childs.slice(0, addToPos),
        nodeId,
        ...state.structure[addToId].childs.slice(addToPos),
      ],
    },
    [nodeId]: {
      ...state.structure[nodeId],
      parent: addToId,
    },
  },
});


export const swapWithUpperChild = ({
  state, id, index,
}) => ({
  ...state,
  structure: {
    ...state.structure,
    [id]: {
      ...state.structure[id],
      childs: [
        ...state.structure[id].childs.slice(0, index - 1),
        state.structure[id].childs[index],
        state.structure[id].childs[index - 1],
        ...state.structure[id].childs.slice(index + 1),
      ],
    },
  },
});


//
// Add Part
//
export const addPartLogic = (id) => ({
  id,
  type: null,
  isValid: true,
  invalidInputs: 0,
});


//
// Get New Structure
//
export const makeNewStructure = ({
  id, parent, type = null, data = null, childs = [],
}) => ({
  id,
  parent,
  type,
  data,
  childs,
});


//
// Change Part Type
//
export const changePartTypeLogic = (id, newType, values = {}) => {
  const ret = {
    id,
    type: newType,
    isValid: true,
    invalidInputs: 0,
  };

  switch (newType) {
    case FORM_TYPES.NUMBER:
    case FORM_TYPES.TEXT:
    case FORM_TYPES.CHECKBOX:
    case FORM_TYPES.TIME:
    case FORM_TYPES.DATE:
    case FORM_TYPES.TIMESTAMP: {
      return {
        ...ret,
        form: {
          ...inputCommonForm,
          defaultValue: {
            ...inputCommonForm.defaultValue,
            type: newType,
          },
        },
        values: {
          ...inputCommonValues,
          defaultValue: generateInitValueForInputType(newType),
          ...values,
        },
      };
    }

    case FORM_TYPES.RADIO:
    case FORM_TYPES.SELECT:
    case FORM_TYPES.MULTISELECT: {
      return {
        ...ret,
        form: {
          ...inputCommonForm,
          defaultValue: {
            ...inputCommonForm.defaultValue,
            type: newType,
          },
          genericFormDialId: {
            ...inputCommonForm.genericFormDialId,
            validation: {
              ...inputCommonForm.genericFormDialId.validation,
              required: true,
            },
          },
        },
        values: {
          ...inputCommonValues,
          defaultValue: generateInitValueForInputType(newType),
          ...values,
        },
      };
    }

    case FORM_TYPES.FIELDSET: {
      return {
        ...ret,
        ...fieldsetCommonProps,
      };
    }

    case FORM_TYPES.GROUP: {
      return {
        ...ret,
        ...groupCommonProps,
      };
    }

    default:
      return ret;
  }
};
