import styled from 'styled-components';
import { FORM_STRUCTURE_FIELDSET, } from '../../../globals';


const styleFieldset = (type, theme) => `
  border-left: 2px solid ${theme.primary.t800};
`;

const styleType = (type, theme) => {
  switch (type) {
    case FORM_STRUCTURE_FIELDSET:
      return styleFieldset(type, theme);
    default:
      return '';
  }
};

const StyledChildrenRow = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  ${(p) => p.type && styleType(p.type, p.theme)}
`;

export default StyledChildrenRow;
