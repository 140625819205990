import React from 'react';
import styled from 'styled-components';
import {
  shape, string, object, func, bool,
} from 'prop-types';

import { withLang, } from '../../logic/languages/withLang';
import Error from '../../styles/icons/Error';
import ButtonLoader from '../../atoms/Button/ButtonLoader';


const StyledSegmentError = styled.div`
  height: 7rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.error.t800};
  background: ${(p) => p.theme.error.t50};

  .segment-error--icon {
    font-size: 1.5rem;
    margin-right: 4px;
  }

  .segment-error--text {
    margin-top: 0.25rem;
  }

  .segment-error--btn {
    text-align: center;
    margin-top: 5px;
  }

  /* FormGroup */
  ${(p) => p.formGroup && `
    margin-bottom: ${p.theme.formGroup.marginBottom}
  `}
`;


const SegmentError = ({
  message, refresh, formGroup, langError,
}) => (
  <StyledSegmentError formGroup={formGroup}>
    <div>
      <div>
        <span className="segment-error--icon">
          <Error />
        </span>
        <span className="segment-error--text">
          {langError(message)}
        </span>
      </div>
      <div className="segment-error--btn">
        <ButtonLoader
          onClick={refresh}
          size="sm"
          shape="outline"
          color="error"
        >
          Refresh
        </ButtonLoader>
      </div>
    </div>
  </StyledSegmentError>
);


SegmentError.propTypes = {
  message: shape({
    code: string.isRequired,
    params: object.isRequired,
  }),
  formGroup: bool,
  refresh: func.isRequired,
  langError: func.isRequired,
};

SegmentError.defaultProps = {
  message: {
    code: '',
    params: {},
  },
  formGroup: false,
};


export default withLang(SegmentError);
