import React, { Fragment, } from 'react';
import {
  shape, func, object, string, arrayOf, bool,
} from 'prop-types';

import { withLang, } from '../../../../logic/languages/withLang';
import { onlyNumbersInString, } from '../../../../logic/forms/utils';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Input from '../../../../atoms/Input/Input';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';
import InputSelectWrapper from '../../../../components/wrappers/InputSelectWrapper';


const StandsDetail = ({
  // data
  form,
  values,
  actionsSharedData,
  standAppConfigurationSharedData,
  // methods
  getActionsShared,
  getStandAppConfigurationShared,
  handleOnChangeValue,
  langError,
  disableOptionsForClearance,
}) => (
  <Fragment>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Název
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="text"
          value={values.name}
          status={form.name.status}
          onChange={(e) => handleOnChangeValue(form.name.name, e.target.value)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.name.status}
          msg={langError(form.name.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Akce
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <InputSelectWrapper
          value={values.actions}
          status={form.actions.status}
          options={actionsSharedData.data}
          onChange={(value) => handleOnChangeValue(form.actions.name, value)}
          isMulti
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          isLoading={actionsSharedData.isLoading}
          error={actionsSharedData.error}
          fetchData={getActionsShared}
          isOptionDisabled={disableOptionsForClearance}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.actions.status}
          msg={langError(form.actions.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Konfigurace
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <InputSelectWrapper
          value={values.standAppConfiguration}
          status={form.standAppConfiguration.status}
          options={standAppConfigurationSharedData.data}
          onChange={(value) => handleOnChangeValue(form.standAppConfiguration.name, value)}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          isLoading={standAppConfigurationSharedData.isLoading}
          error={standAppConfigurationSharedData.error}
          fetchData={getStandAppConfigurationShared}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.standAppConfiguration.status}
          msg={langError(form.standAppConfiguration.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Kapacita
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="number"
          value={values.capacity}
          status={form.capacity.status}
          onChange={
            (e) => handleOnChangeValue(form.capacity.name, onlyNumbersInString(e.target.value))
          }
          min="0"
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.capacity.status}
          msg={langError(form.capacity.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

  </Fragment>
);


StandsDetail.propTypes = {
  form: shape({
    name: shape({
      name: string.isRequired,
      type: string.isRequired,
      validation: object.isRequired,
      status: string.isRequired,
      info: string.isRequired,
      statusMsg: object.isRequired,
    }),
    actions: shape({
      name: string.isRequired,
      type: string.isRequired,
      validation: object.isRequired,
      status: string.isRequired,
      info: string.isRequired,
      statusMsg: object.isRequired,
    }),
    capacity: shape({
      name: string.isRequired,
      type: string.isRequired,
      validation: object.isRequired,
      status: string.isRequired,
      info: string.isRequired,
      statusMsg: object.isRequired,
    }),
  }).isRequired,
  values: shape({
    name: string,
    actions: arrayOf(object),
    devices: arrayOf(object),
    displays: arrayOf(object),
    capacity: string,
    reservedCapacity: string,
  }).isRequired,
  actionsSharedData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
    message: object.isRequired,
  }).isRequired,
  standAppConfigurationSharedData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
    message: object.isRequired,
  }).isRequired,
  getActionsShared: func.isRequired,
  getStandAppConfigurationShared: func.isRequired,
  handleOnChangeValue: func.isRequired,
  langError: func.isRequired,
  disableOptionsForClearance: func.isRequired,
};


export default withLang(StandsDetail);
