import { PENDING, FULFILLED, REJECTED, } from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


/*
 * GET Languages
 */
export const GENERIC_FORMS__GET_GENERIC_FORMS = 'GENERIC_FORMS__GET_GENERIC_FORMS';
export const getGenericFormsAction = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: `${GENERIC_FORMS__GET_GENERIC_FORMS}__${PENDING}`,
      payload: filter,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/generic_forms/filter`,
      data: filter,
    });

    dispatch({
      type: `${GENERIC_FORMS__GET_GENERIC_FORMS}__${FULFILLED}`,
      payload: response.data,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${GENERIC_FORMS__GET_GENERIC_FORMS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/**
 * Filter Changed
 */
export const GENERIC_FORMS__FILTER_CHANGED = 'GENERIC_FORMS__FILTER_CHANGED';

export const changeFilterAction = (filter) => ({
  type: `${GENERIC_FORMS__FILTER_CHANGED}`,
  payload: filter,
});
