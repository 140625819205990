import React, { Component, Fragment, } from 'react';
import {
  shape, func, object, string,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  refreshFormDetailAction,
  changeFormValueAction,
  getOptionsAction,
  editAction,
  getDetailAction,
} from '../actions/transportationEditFormsDetail';
import {
  toggleModalInputAction,
} from '../actions/transportationEditFormsModalInput';
import { validateInput, } from '../../../logic/forms/validation';
import Modal from '../../../atoms/Modal/Modal';
import TransportationEditFormsEdit from '../components/TransportationEditFormsEdit/TransportationEditFormsEdit';
import TransportationEditFormsDetail from '../components/TransportationEditFormsDetail/TransportationEditFormsDetail';
import InputModal from '../components/TransportationEditFormsDetail/InputModal';


class TransportationEditFormsEditModal extends Component {
  componentDidMount() {
    const {
      match,
      refreshFormDetail,
      getOptions,
      getDetail,
    } = this.props;

    getDetail(match.params.id);
    refreshFormDetail();
    getOptions();
  }

  handleClose = () => {
    const { history, } = this.props;

    history.goBack();
  }

  handleChangeValue = (name, value) => {
    const { detail, changeFormValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);
    changeFormValue(name, value, validationError);
  }


  handleEdit = () => {
    const { onEdit, } = this.props;

    onEdit({ onCompleted: this.handleEditSuccess, });
  }


  handleEditSuccess = () => {
    const { refreshParent, } = this.props;

    refreshParent();
    this.handleClose();
  }

  render() {
    const {
      // data
      editData,
      detailData,
      detail,
      optionsData,
      // methods
      toggleModalInput,
      getDetail,
    } = this.props;

    return (
      <Fragment>

        <InputModal />

        <Modal
          isOpen
          onClose={this.handleClose}
          closeOnEsc
          size="MD"
          title="Úprava formuláře"
        >
          <TransportationEditFormsEdit
            // data
            editData={editData}
            isFormValid={detail.isValid}
            detailData={detailData}
            // methods
            onClose={this.handleClose}
            onEdit={this.handleEdit}
            getDetail={getDetail}
          >
            <TransportationEditFormsDetail
              // data
              form={detail.form}
              values={detail.values}
              optionsData={optionsData}
              // methods
              onChangeValue={this.handleChangeValue}
              toggleModalInput={toggleModalInput}
            />
          </TransportationEditFormsEdit>
        </Modal>

      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { transportationEditFormsDetail, } = state;
  return {
    editData: transportationEditFormsDetail.editData,
    detailData: transportationEditFormsDetail.detailData,
    detail: transportationEditFormsDetail.detail,
    optionsData: transportationEditFormsDetail.optionsData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  refreshFormDetail: bindActionCreators(refreshFormDetailAction, dispatch),
  changeFormValue: bindActionCreators(changeFormValueAction, dispatch),
  getOptions: bindActionCreators(getOptionsAction, dispatch),
  toggleModalInput: bindActionCreators(toggleModalInputAction, dispatch),
  onEdit: bindActionCreators(editAction, dispatch),
  getDetail: bindActionCreators(getDetailAction, dispatch),
});


TransportationEditFormsEditModal.propTypes = {
  match: shape({
    params: shape({
      id: string.isRequired,
    }).isRequired,
  }).isRequired,
  detail: object.isRequired,
  detailData: object.isRequired,
  editData: object.isRequired,
  optionsData: object.isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
  refreshFormDetail: func.isRequired,
  refreshParent: func.isRequired,
  onEdit: func.isRequired,
  changeFormValue: func.isRequired,
  getOptions: func.isRequired,
  toggleModalInput: func.isRequired,
  getDetail: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(TransportationEditFormsEditModal);
