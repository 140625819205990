import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION, REJECTED_COLLISON, FORM_SUCCESS, FORM_ERROR,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


const parseValues = (values) => ({
  name: values.name,
  actions: values.actions.map((item) => item.id),
  capacity: values.capacity,
  standAppConfigurationId: values.standAppConfiguration === null ? null : values.standAppConfiguration.id,
});


/**
 * Refresh data - on mount
 */
export const QUEUE_CONFIG__STANDS_REFRESH = 'QUEUE_CONFIG__STANDS_REFRESH';
export const refreshStandsDetailAction = () => ({
  type: `${QUEUE_CONFIG__STANDS_REFRESH}`,
});


/**
 * Change Value
 */
export const QUEUE_CONFIG__STANDS_CHANGE_VALUE_DETAIL = 'QUEUE_CONFIG__STANDS_CHANGE_VALUE_DETAIL';
export const changeValueAction = (name, value, validationError) => ({
  type: `${QUEUE_CONFIG__STANDS_CHANGE_VALUE_DETAIL}`,
  payload: {
    name,
    value,
    validationError,
  },
});


/**
 * Validate Form
 */
export const QUEUE_CONFIG__STANDS_VALIDATE_FORM_DETAIL = 'QUEUE_CONFIG__STANDS_VALIDATE_FORM_DETAIL';
export const validateFormAction = (error) => ({
  type: `${QUEUE_CONFIG__STANDS_VALIDATE_FORM_DETAIL}`,
  payload: error,
});


/*
 * GET Stands Detail
 */
export const QUEUE_CONFIG__GET_STANDS_DETAIL = 'QUEUE_CONFIG__GET_STANDS_DETAIL';
export const getStandsDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${QUEUE_CONFIG__GET_STANDS_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/stands/detail?id=${id}`,
    });

    dispatch({
      type: `${QUEUE_CONFIG__GET_STANDS_DETAIL}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${QUEUE_CONFIG__GET_STANDS_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * CREATE Stands Detail
 */
export const QUEUE_CONFIG__CREATE_STANDS_DETAIL = 'QUEUE_CONFIG__CREATE_STANDS_DETAIL';
export const createStandsDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${QUEUE_CONFIG__CREATE_STANDS_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/stands`,
      data: {
        values: parseValues(item.values),
      },
    });

    dispatch({
      type: `${QUEUE_CONFIG__CREATE_STANDS_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Stanoviště vytvořeno',
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${QUEUE_CONFIG__CREATE_STANDS_DETAIL}__${REJECTED_VALIDATION}`,
        payload: parsedError,
      });
      return;
    }

    dispatch({
      type: `${QUEUE_CONFIG__CREATE_STANDS_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * UPDATE Stands Detail
 */
export const QUEUE_CONFIG__UPDATE_STANDS_DETAIL = 'QUEUE_CONFIG__UPDATE_STANDS_DETAIL';
export const updateStandsDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${QUEUE_CONFIG__UPDATE_STANDS_DETAIL}__${PENDING}`,
    });

    const values = parseValues(item.values);
    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/stands`,
      data: {
        id: item.id,
        values,
      },
    });

    dispatch({
      type: `${QUEUE_CONFIG__UPDATE_STANDS_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Stanoviště upraveno',
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${QUEUE_CONFIG__UPDATE_STANDS_DETAIL}__${REJECTED_VALIDATION}`,
        payload: {
          ...parsedError,
          message: {
            code: '',
            params: {},
          },
        },
      });
      return;
    }

    dispatch({
      type: `${QUEUE_CONFIG__UPDATE_STANDS_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * DELETE Stands Detail
 */
export const QUEUE_CONFIG__DELETE_STANDS_DETAIL = 'QUEUE_CONFIG__DELETE_STANDS_DETAIL';
export const deleteStandsDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${QUEUE_CONFIG__DELETE_STANDS_DETAIL}__${PENDING}`,
      payload: {
        id,
      },
    });

    const response = await api({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/stands?id=${id}`,
    });

    dispatch({
      type: `${QUEUE_CONFIG__DELETE_STANDS_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        id,
        notif: {
          type: FORM_SUCCESS,
          title: 'Stanoviště smazáno',
        },
      },
    });
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 428) {
      dispatch({
        type: `${QUEUE_CONFIG__DELETE_STANDS_DETAIL}__${REJECTED_COLLISON}`,
        payload: {
          ...parsedError,
          message: {
            code: 'CANT_DELETE',
            params: {},
          },
          id,
        },
      });
      return;
    }

    dispatch({
      type: `${QUEUE_CONFIG__DELETE_STANDS_DETAIL}__${REJECTED}`,
      payload: {
        ...parsedError,
        id,
        notif: {
          type: FORM_ERROR,
          title: 'Nepovedlo se smazat Stand',
          showMessage: true,
        },
      },
    });
  }
};


/*
 * Toggle Stands delete Modal
 */
export const QUEUE_CONFIG__TOGGLE_STANDS_DELETE_MODAL = 'QUEUE_CONFIG__TOGGLE_STANDS_DELETE_MODAL';
export const toggleStandsDeleteModalAction = (isOpen, id = null) => ({
  type: `${QUEUE_CONFIG__TOGGLE_STANDS_DELETE_MODAL}`,
  payload: {
    id,
    isOpen,
  },
});
