import React, { Component, } from 'react';
import {
  shape, bool, func, object,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  createActionsDetailAction,
  validateFormAction,
  changeValueAction,
  refreshActionsDetailAction,
} from '../actions/actionsDetail';
import { validateInput, validateWholeFormValidator, } from '../../../logic/forms/validation';
import Modal from '../../../atoms/Modal/Modal';
import ActionsDetail from '../components/ActionsDetail/ActionsDetail';
import ActionsCreate from '../components/ActionsCreate/ActionsCreate';


class ActionsCreateModal extends Component {
  componentDidMount() {
    const {
      refreshActionsDetail,
    } = this.props;

    refreshActionsDetail();
  }

  handleClose = () => {
    const { history, } = this.props;

    history.goBack();
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);

    changeValue(name, value, validationError);
  }

  handleCreate = () => {
    const {
      createActionsData,
      detail,
      validateForm,
      createActionsDetail,
    } = this.props;

    if (createActionsData.isLoading || !detail.isValid) return;

    const validation = validateWholeFormValidator(detail.form, detail.values);
    if (Object.keys(validation).length < 1) {
      createActionsDetail(detail, this.handleCreateSuccess);
    } else {
      validateForm(validation);
    }
  }

  handleCreateSuccess = () => {
    const { refreshParent, } = this.props;

    refreshParent();
    this.handleClose();
  }

  render() {
    const {
      createActionsData,
      detail,
    } = this.props;

    return (
      <Modal
        isOpen
        onClose={this.handleClose}
        closeOnEsc
        size="MD"
        title="Vytvoření akce"
      >
        <ActionsCreate
          // data
          createActionsData={createActionsData}
          isFormValid={detail.isValid}
          // methods
          handleClose={this.handleClose}
          handleCreate={this.handleCreate}
        >
          <ActionsDetail
            // data
            values={detail.values}
            form={detail.form}
            // methods
            handleClose={this.handleClose}
            handleOnChangeValue={this.handleOnChangeValue}
          />
        </ActionsCreate>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { actionsDetail, } = state;
  return {
    createActionsData: actionsDetail.createActionsData,
    detail: actionsDetail.detail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createActionsDetail: bindActionCreators(createActionsDetailAction, dispatch),
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
  refreshActionsDetail: bindActionCreators(refreshActionsDetailAction, dispatch),
});


ActionsCreateModal.propTypes = {
  createActionsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  detail: shape({
    isValid: bool.isRequired,
    form: object.isRequired,
    values: object.isRequired,
  }).isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
  createActionsDetail: func.isRequired,
  refreshActionsDetail: func.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
  refreshParent: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(ActionsCreateModal);
