export const PARAM_PREFIX = '#*@';
export const PARAM_POSTFIX = '@*#';


const initialState = {
  error: {
    // default
    UNKNOWN: 'Něco se nepovedlo',

    // common
    SOMETHING_HAPPEND: 'Něco se nepovedlo',

    // api
    CANNOT_CONNECT: 'Nelze se připojit',
    CANT_DELETE: 'Nelze smazat',

    // form
    FORM_INVALID: 'Chyby ve formuláři',
    FORM_SAME_NAMES: 'Názvy musí být unikátní',

    // inputs
    INPUT_TEXT_REQUIRED: 'Povinné',
    INPUT_TEXT_MIN: `Minimální délka: ${PARAM_PREFIX}min${PARAM_POSTFIX}`,
    INPUT_TEXT_MAX: `Maximální délka: ${PARAM_PREFIX}max${PARAM_POSTFIX}`,
    INPUT_NUMBER_REQUIRED: 'Povinné',
    INPUT_NUMBER_MIN: `Minimální délka: ${PARAM_PREFIX}min${PARAM_POSTFIX}`,
    INPUT_NUMBER_MAX: `Maximální délka: ${PARAM_PREFIX}max${PARAM_POSTFIX}`,
    INPUT_SELECT_REQUIRED: 'Povinné',
    INPUT_MULTISELECT_REQUIRED: 'Povinné',
    INPUT_MULTISELECT_MIN: `Minimální délka: ${PARAM_PREFIX}min${PARAM_POSTFIX}`,
    INPUT_MULTISELECT_MAX: `Maximální délka: ${PARAM_PREFIX}max${PARAM_POSTFIX}`,
    INPUT_CHECKBOX_REQUIRED: 'Povinné',
    INPUT_RADIO_REQUIRED: 'Povinné',

    // regexes
    REGEX_PROPERTY_NAME: 'Nevalidní název',

    // config-validations
    ACTION_NOT_EXIST: `Akce neexistuje: ${PARAM_PREFIX}action${PARAM_POSTFIX}`,
    ACTION_MUST_BE_FIRST: `Tato akce musí být první: ${PARAM_PREFIX}action${PARAM_POSTFIX}`,
    ACTION_MUST_BE_LAST: `Tato akce musí být poslední: ${PARAM_PREFIX}action${PARAM_POSTFIX}`,
    ACTION_IS_REQUIRED: `Akce s tímto typem: ${PARAM_PREFIX}action${PARAM_POSTFIX} je povinná`,
    LIMIT_EXCEEDED: `Tato akce je použita vícekrát: ${PARAM_PREFIX}action${PARAM_POSTFIX}`,
    ACTION_TYPE_MISSING: 'Něco se nepovedlo',
  },
};

const reducer = (state = initialState) => state;


export default reducer;
