import React from 'react';
import {
  oneOfType, arrayOf, node, string, shape, object, bool, func,
} from 'prop-types';

import Row from '../../atoms/Row/Row';
import Col from '../../atoms/Col/Col';
import InfoMsg from '../../atoms/InfoMsg/InfoMsg';
import { FORM_DEFAULT, } from '../../globals';
import { withLang, } from '../../logic/languages/withLang'


const FormRow = ({
  label, status, statusMsg, children, isLabel, langError,
}) => (
  <Row formGroup>
    <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel={isLabel} sidePadding SM_textAlign="left" LG_textAlign="right">
      {label}
    </Col>
    <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
      {children}
    </Col>
    <Col MD_offset={3} LG_offset={6} sidePadding>
      <InfoMsg
        status={status}
        msg={langError(statusMsg)}
        showIcon
      />
    </Col>
  </Row>
);


FormRow.propTypes = {
  label: string,
  status: string,
  isLabel: bool,
  statusMsg: shape({
    code: string,
    params: object,
  }),
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
  langError: func.isRequired,
};

FormRow.defaultProps = {
  label: '',
  isLabel: true,
  status: FORM_DEFAULT,
  statusMsg: {
    code: '',
    params: {},
  },
};


export default withLang(FormRow);
