import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION, REJECTED_COLLISON, FORM_SUCCESS, FORM_ERROR,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


const parseValues = (values) => ({
  name: values.name,
  createButton: values.createButton,
  planTab: values.planTab,
  queueTab: values.queueTab,
  standTab: values.standTab,
  visitorTab: values.visitorTab,
  delayTab: values.delayTab,
  isPlanTabMain: values.isPlanTabMain,
  isQueueTabMain: values.isQueueTabMain,
  isStandTabMain: values.isStandTabMain,
  isVisitorTabMain: values.isVisitorTabMain,
  isDelayTabMain: values.isDelayTabMain,
  standTabCallButton: values.standTabCallButton,
  planTabCallButton: values.planTabCallButton,
  planTabColumns: values.planTabColumns.map((item) => (
    item.id
  )),
  queueTabColumns: values.queueTabColumns.map((item) => (
    item.id
  )),
  standTabColumns: values.standTabColumns.map((item) => (
    item.id
  )),
  delayTabColumns: values.delayTabColumns.map((item) => (
    item.id
  )),
});

const mergeArray = (arrayToMerge, columns) => {
  const newArray = [];
  if (arrayToMerge !== null) {
    for (let i = 0; i < arrayToMerge.length; i++) {
      newArray.push({ id: arrayToMerge[i], name: columns[arrayToMerge[i]], });
    }
  }
  return newArray;
};

const mergeColumnsWithDetail = (values, columns) => {
  const newObject = { ...values, };
  newObject.planTabColumns = mergeArray(values.planTabColumns, columns);
  newObject.queueTabColumns = mergeArray(values.queueTabColumns, columns);
  newObject.standTabColumns = mergeArray(values.standTabColumns, columns);
  newObject.delayTabColumns = mergeArray(values.delayTabColumns, columns);
  return newObject;
};

/**
 * Refresh data - on mount
 */
export const QUEUE_CONFIG__STAND_APP_CONFIGURATION_REFRESH = 'QUEUE_CONFIG__STAND_APP_CONFIGURATION_REFRESH';
export const refreshStandAppConfigurationDetailAction = () => ({
  type: `${QUEUE_CONFIG__STAND_APP_CONFIGURATION_REFRESH}`,
});


/**
 * Change Value
 */
export const QUEUE_CONFIG__STAND_APP_CONFIGURATION_CHANGE_VALUE_DETAIL = 'QUEUE_CONFIG__STAND_APP_CONFIGURATION_CHANGE_VALUE_DETAIL';
export const changeValueAction = (name, value, validationError) => ({
  type: `${QUEUE_CONFIG__STAND_APP_CONFIGURATION_CHANGE_VALUE_DETAIL}`,
  payload: {
    name,
    value,
    validationError,
  },
});


/**
 * Validate Form
 */
export const QUEUE_CONFIG__STAND_APP_CONFIGURATION_VALIDATE_FORM_DETAIL = 'QUEUE_CONFIG__STAND_APP_CONFIGURATION_VALIDATE_FORM_DETAIL';
export const validateFormAction = (error) => ({
  type: `${QUEUE_CONFIG__STAND_APP_CONFIGURATION_VALIDATE_FORM_DETAIL}`,
  payload: error,
});


/*
 * GET Stand App Configuration Detail
 */
export const QUEUE_CONFIG__GET_STAND_APP_CONFIGURATION_DETAIL = 'QUEUE_CONFIG__GET_STAND_APP_CONFIGURATION_DETAIL';
export const getStandAppConfigurationDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${QUEUE_CONFIG__GET_STAND_APP_CONFIGURATION_DETAIL}__${PENDING}`,
    });

    const responseFromDetail = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/stands/configuration/detail?id=${id}`,
    });

    const responseFromColumns = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/stands/configuration/dashboardColumns`,
    });

    const merged = mergeColumnsWithDetail(responseFromDetail.data, responseFromColumns.data);
    dispatch({
      type: `${QUEUE_CONFIG__GET_STAND_APP_CONFIGURATION_DETAIL}__${FULFILLED}`,
      payload: {
        message: responseFromDetail.message,
        messageInternal: responseFromDetail.messageInternal,
        data: merged,
        statusCode: responseFromDetail.statusCode,
      },
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${QUEUE_CONFIG__GET_STAND_APP_CONFIGURATION_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * CREATE Stand App Configuration Detail
 */
export const QUEUE_CONFIG__CREATE_STAND_APP_CONFIGURATION_DETAIL = 'QUEUE_CONFIG__CREATE_STAND_APP_CONFIGURATION_DETAIL';
export const createStandAppConfigurationDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${QUEUE_CONFIG__CREATE_STAND_APP_CONFIGURATION_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/stands/configuration`,
      data: {
        values: parseValues(item.values),
      },
    });

    dispatch({
      type: `${QUEUE_CONFIG__CREATE_STAND_APP_CONFIGURATION_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Konfigurace pro aplikaci Stanoviště vytvořena',
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${QUEUE_CONFIG__CREATE_STAND_APP_CONFIGURATION_DETAIL}__${REJECTED_VALIDATION}`,
        payload: parsedError,
      });
      return;
    }

    dispatch({
      type: `${QUEUE_CONFIG__CREATE_STAND_APP_CONFIGURATION_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * UPDATE Stand App Configuration Detail
 */
export const QUEUE_CONFIG__UPDATE_STAND_APP_CONFIGURATION_DETAIL = 'QUEUE_CONFIG__UPDATE_STAND_APP_CONFIGURATION_DETAIL';
export const updateStandAppConfigurationDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${QUEUE_CONFIG__UPDATE_STAND_APP_CONFIGURATION_DETAIL}__${PENDING}`,
    });

    const values = parseValues(item.values);
    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/stands/configuration`,
      data: {
        id: item.id,
        values,
      },
    });

    dispatch({
      type: `${QUEUE_CONFIG__UPDATE_STAND_APP_CONFIGURATION_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Konfigurace pro aplikaci Stanoviště upravena',
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${QUEUE_CONFIG__UPDATE_STAND_APP_CONFIGURATION_DETAIL}__${REJECTED_VALIDATION}`,
        payload: {
          ...parsedError,
          message: {
            code: '',
            params: {},
          },
        },
      });
      return;
    }

    dispatch({
      type: `${QUEUE_CONFIG__UPDATE_STAND_APP_CONFIGURATION_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * DELETE Stand App Configuration Detail
 */
export const QUEUE_CONFIG__DELETE_STAND_APP_CONFIGURATION_DETAIL = 'QUEUE_CONFIG__DELETE_STAND_APP_CONFIGURATION_DETAIL';
export const deleteStandAppConfigurationDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${QUEUE_CONFIG__DELETE_STAND_APP_CONFIGURATION_DETAIL}__${PENDING}`,
      payload: {
        id,
      },
    });

    const response = await api({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/stands/configuration?id=${id}`,
    });

    dispatch({
      type: `${QUEUE_CONFIG__DELETE_STAND_APP_CONFIGURATION_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        id,
        notif: {
          type: FORM_SUCCESS,
          title: 'Konfigurace pro aplikaci Stanoviště smazána',
        },
      },
    });
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 428) {
      dispatch({
        type: `${QUEUE_CONFIG__DELETE_STAND_APP_CONFIGURATION_DETAIL}__${REJECTED_COLLISON}`,
        payload: {
          ...parsedError,
          message: {
            code: 'CANT_DELETE',
            params: {},
          },
          id,
        },
      });
      return;
    }

    dispatch({
      type: `${QUEUE_CONFIG__DELETE_STAND_APP_CONFIGURATION_DETAIL}__${REJECTED}`,
      payload: {
        ...parsedError,
        id,
        notif: {
          type: FORM_ERROR,
          title: 'Nepovedlo se smazat Konfiguraci',
          showMessage: true,
        },
      },
    });
  }
};


/*
 * Toggle Stands delete Modal
 */
export const QUEUE_CONFIG__TOGGLE_STAND_APP_CONFIGURATION_DELETE_MODAL = 'QUEUE_CONFIG__TOGGLE_STAND_APP_CONFIGURATION_DELETE_MODAL';
export const toggleStandAppConfigurationDeleteModalAction = (isOpen, id = null) => ({
  type: `${QUEUE_CONFIG__TOGGLE_STAND_APP_CONFIGURATION_DELETE_MODAL}`,
  payload: {
    id,
    isOpen,
  },
});
