import React, { Component, Fragment, } from 'react';
import {
  shape, number, func, object, string, arrayOf, bool,
} from 'prop-types';
import { Route, } from 'react-router-dom';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  deleteStandAppConfigurationDetailAction,
  toggleStandAppConfigurationDeleteModalAction,
} from '../actions/standAppConfigurationDetail';
import { getStandAppConfigurationAction, changeFilterAction, } from '../actions/standAppConfiguration';
import StandAppConfiguration from '../components/StandAppConfiguration/StandAppConfiguration';
import StandAppConfigurationCreateModal from './StandAppConfigurationCreateModal';
import StandAppConfigurationEditModal from './StandAppConfigurationEditModal';
import StandAppConfigurationDeleteModal from '../components/StandAppConfigurationDeleteModal/StandAppConfigurationDeleteModal';

class StandAppConfigurationPage extends Component {
  componentDidMount() {
    const { table, } = this.props;
    this.handleGetStandAppConfiguration(table.filter);
  }

  handleGetStandAppConfiguration = (newFilter) => {
    const { getStandAppConfiguration, table, } = this.props;

    getStandAppConfiguration(newFilter === undefined ? table.filter : newFilter);
  }

  handleEdit = (id) => {
    const { match, history, } = this.props;

    history.push(`${match.path}/edit/${id}`);
  }

  handleCreate = () => {
    const { match, history, } = this.props;

    history.push(`${match.path}/create`);
  }


  handleDelete = () => {
    const {
      deleteStandAppConfigurationData,
      deleteStandAppConfigurationDetail,
      standAppConfigurationDeleteModal,
    } = this.props;

    if (deleteStandAppConfigurationData.isLoading) return;

    deleteStandAppConfigurationDetail(standAppConfigurationDeleteModal.id);
  }

  handleToggle = () => {
    const { toggleStandAppConfigurationDeleteModal, } = this.props;
    toggleStandAppConfigurationDeleteModal(false);
  }

  render() {
    const {
      match,
      table,
      standAppConfigurationDeleteModal,
      standAppConfigurationData,
      changeFilter,
      deleteStandAppConfigurationData,
      toggleStandAppConfigurationDeleteModal,
    } = this.props;

    return (
      <Fragment>

        <StandAppConfiguration
          table={table}
          standAppConfigurationData={standAppConfigurationData}
          handleGetStandAppConfiguration={this.handleGetStandAppConfiguration}
          handleEdit={this.handleEdit}
          handleCreate={this.handleCreate}
          handleDelete={toggleStandAppConfigurationDeleteModal}
          changeFilter={changeFilter}
        />

        <Route
          exact
          path={`${match.path}/create`}
          render={(props) => <StandAppConfigurationCreateModal {...props} refreshParent={this.handleGetStandAppConfiguration} />} // eslint-disable-line
        />
        <Route
          exact
          path={`${match.path}/edit/:id`}
          render={(props) => <StandAppConfigurationEditModal {...props} refreshParent={this.handleGetStandAppConfiguration} />} // eslint-disable-line
        />

        <StandAppConfigurationDeleteModal
          modalData={standAppConfigurationDeleteModal}
          actionData={deleteStandAppConfigurationData}
          action={this.handleDelete}
          onClose={this.handleToggle}
        />

      </Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  const { standAppConfiguration, standAppConfigurationDetail, } = state;
  return {
    standAppConfigurationData: standAppConfiguration.standAppConfigurationData,
    table: standAppConfiguration.table,
    deleteStandAppConfigurationData: standAppConfigurationDetail.deleteStandAppConfigurationData,
    standAppConfigurationDeleteModal: standAppConfigurationDetail.standAppConfigurationDeleteModal,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStandAppConfiguration: bindActionCreators(getStandAppConfigurationAction, dispatch),
  changeFilter: bindActionCreators(changeFilterAction, dispatch),
  deleteStandAppConfigurationDetail: bindActionCreators(deleteStandAppConfigurationDetailAction, dispatch), // eslint-disable-line
  toggleStandAppConfigurationDeleteModal: bindActionCreators(toggleStandAppConfigurationDeleteModalAction, dispatch), // eslint-disable-line
});

StandAppConfigurationPage.propTypes = {
  table: shape({
    data: arrayOf(object).isRequired,
    count: number.isRequired,
    filter: object.isRequired,
  }).isRequired,
  standAppConfigurationData: object.isRequired,
  changeFilter: func.isRequired,
  toggleStandAppConfigurationDeleteModal: func.isRequired,
  match: shape({
    path: string.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  deleteStandAppConfigurationDetail: func.isRequired,
  deleteStandAppConfigurationData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  standAppConfigurationDeleteModal: shape({
    id: number,
    isOpen: bool.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(StandAppConfigurationPage);
