import React, { Component, } from 'react';
import {
  shape, bool, func, object,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  createStandsDetailAction,
  validateFormAction,
  changeValueAction,
  refreshStandsDetailAction,
} from '../actions/standsDetail';
import {
  getActionsSharedAction,
  getStandAppConfigurationSharedAction,
} from '../../shared/actions/shared';
import { validateInput, validateWholeFormValidator, } from '../../../logic/forms/validation';
import Modal from '../../../atoms/Modal/Modal';
import StandsDetail from '../components/StandsDetail/StandsDetail';
import StandsCreate from '../components/StandsCreate/StandsCreate';


class StandsCreateModal extends Component {
  componentDidMount() {
    const {
      refreshStandsDetail,
    } = this.props;

    refreshStandsDetail();
  }

  handleClose = () => {
    const { history, } = this.props;

    history.goBack();
  }

  disableOptionsForClearance = (option) => {
    const { detail: { values, }, } = this.props;
    // pokud není vybraná žádná hodnota => vše je viditelné
    if (values.actions.length < 1) {
      return false;
    }
    // pokud je prvni vybrana hodnota odbaveni, ostatní zesedivy
    if (values.actions[0].type === 'clearance') {
      return true;
    }
    // pokud je prvni vybrana hodnota jina nez odbaveni => odbaveni zesedivy
    if (option.type === 'clearance') {
      return true;
    }
    // aby nepicoval lint tak vracím false, i kdyz to sem nidky nemuze dojit
    return false;
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);

    changeValue(name, value, validationError);
  }

  handleCreate = () => {
    const {
      createStandsData,
      detail,
      validateForm,
      createStandsDetail,
    } = this.props;

    if (createStandsData.isLoading || !detail.isValid) return;

    const validation = validateWholeFormValidator(detail.form, detail.values);
    if (Object.keys(validation).length < 1) {
      createStandsDetail(detail, this.handleCreateSuccess);
    } else {
      validateForm(validation);
    }
  }

  handleCreateSuccess = () => {
    const { refreshParent, } = this.props;

    refreshParent();
    this.handleClose();
  }

  render() {
    const {
      createStandsData,
      detail,
      actionsSharedData,
      standAppConfigurationSharedData,
      getActionsShared,
      getStandAppConfigurationShared,
    } = this.props;

    return (
      <Modal
        isOpen
        onClose={this.handleClose}
        closeOnEsc
        size="MD"
        title="Vytvoření stanoviště"
      >
        <StandsCreate
          // data
          createStandsData={createStandsData}
          isFormValid={detail.isValid}
          // methods
          handleClose={this.handleClose}
          handleCreate={this.handleCreate}
        >
          <StandsDetail
            // data
            values={detail.values}
            form={detail.form}
            actionsSharedData={actionsSharedData}
            standAppConfigurationSharedData={standAppConfigurationSharedData}
            // methods
            handleClose={this.handleClose}
            handleOnChangeValue={this.handleOnChangeValue}
            getActionsShared={getActionsShared}
            getStandAppConfigurationShared={getStandAppConfigurationShared}
            disableOptionsForClearance={this.disableOptionsForClearance}
          />
        </StandsCreate>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { standsDetail, shared, } = state;
  return {
    createStandsData: standsDetail.createStandsData,
    detail: standsDetail.detail,
    // dropdowns
    actionsSharedData: shared.actionsData,
    standAppConfigurationSharedData: shared.standAppConfigurationData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createStandsDetail: bindActionCreators(createStandsDetailAction, dispatch), // eslint-disable-line
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
  refreshStandsDetail: bindActionCreators(refreshStandsDetailAction, dispatch),
  // dropdowns
  getActionsShared: bindActionCreators(getActionsSharedAction, dispatch),
  getStandAppConfigurationShared: bindActionCreators(getStandAppConfigurationSharedAction, dispatch), // eslint-disable-line
});


StandsCreateModal.propTypes = {
  createStandsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  detail: shape({
    isValid: bool.isRequired,
    form: object.isRequired,
    values: object.isRequired,
  }).isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
  actionsSharedData: object.isRequired,
  getActionsShared: func.isRequired,
  createStandsDetail: func.isRequired,
  refreshStandsDetail: func.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
  refreshParent: func.isRequired,
  standAppConfigurationSharedData: object.isRequired,
  getStandAppConfigurationShared: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(StandsCreateModal);
