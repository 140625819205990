import React from 'react';
import { shape, string, func, } from 'prop-types';


const LoginView = ({ loginForm, actions, }) => (
  <div>
    <h1>Prihlaseni</h1>

    <div>
      <label htmlFor="username">
        Jmeno
        <input
          type="text"
          name="username"
          id="username"
          value={loginForm.username.value}
          onChange={actions.handleChangeForm}
        />
      </label>
    </div>

    <div>
      <label htmlFor="password">
        Heslo
        <input
          type="password"
          name="password"
          id="password"
          value={loginForm.password.value}
          onChange={actions.handleChangeForm}
        />
      </label>
    </div>

    <button
      type="button"
      onClick={actions.handleLogin}
    >
      Prihlasit
    </button>
  </div>
);

LoginView.propTypes = {
  loginForm: shape({
    username: shape({
      value: string.isRequired,
      errorCode: string,
    }).isRequired,

    password: shape({
      value: string.isRequired,
      errorCode: string,
    }).isRequired,
  }).isRequired,

  actions: shape({
    handleChangeForm: func.isRequired,
    handleLogin: func.isRequired,
  }).isRequired,
};

export default LoginView;
