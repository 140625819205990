import React, { Component, } from 'react';
import {
  shape, bool, func, object,
} from 'prop-types';

import { FORM_TYPES, FORM_TYPES_CREATE_DD, } from '../../globals';
import Modal from '../../atoms/Modal/Modal';
import FormRow from './FormRow';
import InputSelectWrapper from '../wrappers/InputSelectWrapper';
import Row from '../../atoms/Row/Row';
import Col from '../../atoms/Col/Col';
import ButtonGrp from '../../atoms/ButtonGrp/ButtonGrp';
import Button from '../../atoms/Button/Button';
import TextPart from './parts/TextPart';
import FieldSetPart from './parts/FieldSetPart';
import GroupPart from './parts/GroupPart';
import NumberPart from './parts/NumberPart';
import SelectPart from './parts/SelectPart';
import CheckBoxPart from './parts/CheckBoxPart';
import MultiSelectPart from './parts/MultiSelectPart';
import DatePart from './parts/DatePart';
import TimePart from './parts/TimePart';
import TimestampPart from './parts/TimestampPart';
import RadioPart from './parts/RadioPart';


class PartModal extends Component {
  renderInputByType = () => {
    const {
      // data
      modal: {
        part,
      },
      commonData: {
        regexesData,
        optionsData,
        groupData,
      },
      // actions
      commonActions: {
        onGetRegexes,
        onGetOptions,
        onGetGroups,
        onGetGroupInputs,
      },
      partActions: {
        onChangeValue,
        onChangeValidationValue,
        onChangeTranslationValue,
      },
    } = this.props;

    switch (part.type) {
      case FORM_TYPES.TEXT:
        return (
          <TextPart
            part={part}
            regexesData={regexesData}
            onGetRegexes={onGetRegexes}
            onChangeValue={onChangeValue}
            onChangeValidationValue={onChangeValidationValue}
            onChangeTranslationValue={onChangeTranslationValue}
          />
        );

      case FORM_TYPES.NUMBER:
        return (
          <NumberPart
            part={part}
            onChangeValue={onChangeValue}
            onChangeValidationValue={onChangeValidationValue}
            onChangeTranslationValue={onChangeTranslationValue}
          />
        );

      case FORM_TYPES.CHECKBOX:
        return (
          <CheckBoxPart
            part={part}
            onChangeValue={onChangeValue}
            onChangeValidationValue={onChangeValidationValue}
            onChangeTranslationValue={onChangeTranslationValue}
          />
        );

      case FORM_TYPES.SELECT:
        return (
          <SelectPart
            part={part}
            optionsData={optionsData}
            onGetOptions={onGetOptions}
            onChangeValue={onChangeValue}
            onChangeValidationValue={onChangeValidationValue}
            onChangeTranslationValue={onChangeTranslationValue}
          />
        );

      case FORM_TYPES.MULTISELECT:
        return (
          <MultiSelectPart
            part={part}
            optionsData={optionsData}
            onGetOptions={onGetOptions}
            onChangeValue={onChangeValue}
            onChangeValidationValue={onChangeValidationValue}
            onChangeTranslationValue={onChangeTranslationValue}
          />
        );

      case FORM_TYPES.RADIO:
        return (
          <RadioPart
            part={part}
            optionsData={optionsData}
            onGetOptions={onGetOptions}
            onChangeValue={onChangeValue}
            onChangeValidationValue={onChangeValidationValue}
            onChangeTranslationValue={onChangeTranslationValue}
          />
        );

      case FORM_TYPES.FIELDSET:
        return (
          <FieldSetPart
            part={part}
            onChangeValue={onChangeValue}
          />
        );

      case FORM_TYPES.GROUP:
        return (
          <GroupPart
            part={part}
            groupData={groupData}
            onGetGroups={onGetGroups}
            onGetGroupInputs={onGetGroupInputs}
          />
        );

      case FORM_TYPES.TIME:
        return (
          <TimePart
            part={part}
            onChangeValue={onChangeValue}
            onChangeValidationValue={onChangeValidationValue}
            onChangeTranslationValue={onChangeTranslationValue}
          />
        );

      case FORM_TYPES.DATE:
        return (
          <DatePart
            part={part}
            onChangeValue={onChangeValue}
            onChangeValidationValue={onChangeValidationValue}
            onChangeTranslationValue={onChangeTranslationValue}
          />
        );

      case FORM_TYPES.TIMESTAMP:
        return (
          <TimestampPart
            part={part}
            onChangeValue={onChangeValue}
            onChangeValidationValue={onChangeValidationValue}
            onChangeTranslationValue={onChangeTranslationValue}
          />
        );

      default:
        return null;
    }
  }

  render() {
    const {
      // data
      modal: {
        isOpen,
        disabledSave,
        part,
        inGroup,
      },
      // actions
      partActions: {
        onClosePart,
        onSavePart,
        onChangePartType,
      },
    } = this.props;

    if (isOpen === false) return null;

    const { type, } = part;

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClosePart}
        closeOnEsc
        size="MD"
        title="Úprava Inputu"
      >
        <FormRow
          label="Typ"
        >
          <InputSelectWrapper
            options={FORM_TYPES_CREATE_DD}
            value={type === null ? null : FORM_TYPES_CREATE_DD[type]}
            isDisabled={inGroup}
            onChange={(newVal) => onChangePartType(newVal === null ? null : newVal.value)}
          />
        </FormRow>

        {this.renderInputByType()}

        <Row>
          <Col textAlign="right">
            <ButtonGrp>
              <Button
                onClick={onClosePart}
                shape="outline"
              >
                Zpět
              </Button>
              <Button
                onClick={onSavePart}
                disabled={disabledSave}
                color="success"
              >
                Uložit
              </Button>
            </ButtonGrp>
          </Col>
        </Row>
      </Modal>
    );
  }
}


PartModal.propTypes = {
  modal: shape({
    isOpen: bool.isRequired,
    disabledSave: bool.isRequired,
    part: object,
    inGroup: bool.isRequired,
  }).isRequired,
  commonData: shape({
    regexesData: object.isRequired,
    optionsData: object.isRequired,
    groupData: object.isRequired,
  }).isRequired,
  commonActions: shape({
    onGetRegexes: func.isRequired,
    onGetOptions: func.isRequired,
    onGetGroups: func.isRequired,
    onGetGroupInputs: func.isRequired,
  }).isRequired,
  partActions: shape({
    onClosePart: func.isRequired,
    onSavePart: func.isRequired,
    onChangePartType: func.isRequired,
    onChangeValue: func.isRequired,
    onChangeValidationValue: func.isRequired,
    onChangeTranslationValue: func.isRequired,
  }).isRequired,
};


export default PartModal;
