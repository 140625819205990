import React, { Component, } from 'react';
import {
  shape, bool, func, object,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  createTransportationTypesDetailAction,
  validateFormAction,
  changeValueAction,
  refreshTransTypeDetailAction,
} from '../actions/transportationTypesDetail';
import { getSharedDataAction, getTicketsSharedAction, } from '../../shared/actions/shared';
import { validateInput, } from '../../../logic/forms/validation';
import { validateTransTypesDetail, } from './utils';
import Modal from '../../../atoms/Modal/Modal';
import TransportationTypesDetail from '../components/TransportationTypesDetail/TransportationTypesDetail';
import TransportationTypesCreate from '../components/TransportationTypesCreate/TransportationTypesCreate';
import { SHARED_DATA_TYPES, } from '../../../globals';


class TransportationTypesCreateModal extends Component {
  componentDidMount() {
    const {
      refreshTransTypeDetail,
    } = this.props;

    refreshTransTypeDetail();
  }

  handleClose = () => {
    const { history, } = this.props;

    history.goBack();
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);

    changeValue(name, value, validationError);
  }

  handleCreate = async () => {
    const {
      createTransportationTypesData,
      detail,
      validateForm,
      createTransportationTypesDetail,
    } = this.props;

    if (createTransportationTypesData.isLoading || !detail.isValid) return;

    const validation = await validateTransTypesDetail(detail);
    if (Object.keys(validation).length < 1) {
      createTransportationTypesDetail(detail, this.handleCreateSuccess);
    } else {
      validateForm(validation);
    }
  }


  handleCreateSuccess = () => {
    const { refreshParent, } = this.props;

    refreshParent();
    this.handleClose();
  }

  render() {
    const {
      createTransportationTypesData,
      platformsSharedData,
      actionsSharedData,
      detail,
      getSharedData,
      ticketsSharedData,
      getTicketsShared,
    } = this.props;

    return (
      <Modal
        isOpen
        onClose={this.handleClose}
        closeOnEsc
        size="MD"
        title="Vytvoření typu přepravy"
      >
        <TransportationTypesCreate
          // data
          createTransportationTypesData={createTransportationTypesData}
          isFormValid={detail.isValid}
          // methods
          handleClose={this.handleClose}
          handleCreate={this.handleCreate}
        >
          <TransportationTypesDetail
            // data
            values={detail.values}
            form={detail.form}
            platformsSharedData={platformsSharedData}
            actionsSharedData={actionsSharedData}
            ticketsSharedData={ticketsSharedData}
            // methods
            handleClose={this.handleClose}
            handleOnChangeValue={this.handleOnChangeValue}
            getPlatformsShared={() => getSharedData(SHARED_DATA_TYPES.PLATFORMS)}
            getActionsShared={() => getSharedData(SHARED_DATA_TYPES.ACTIONS)}
            getTicketsShared={getTicketsShared}
          />
        </TransportationTypesCreate>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { transportationTypesDetail, shared, } = state;
  return {
    createTransportationTypesData: transportationTypesDetail.createTransportationTypesData,
    detail: transportationTypesDetail.detail,
    // dropdowns
    platformsSharedData: shared.platformsData,
    actionsSharedData: shared.actionsData,
    ticketsSharedData: shared.ticketsData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createTransportationTypesDetail: bindActionCreators(createTransportationTypesDetailAction, dispatch), // eslint-disable-line
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
  refreshTransTypeDetail: bindActionCreators(refreshTransTypeDetailAction, dispatch),
  getSharedData: bindActionCreators(getSharedDataAction, dispatch),
  getTicketsShared: bindActionCreators(getTicketsSharedAction, dispatch),
});


TransportationTypesCreateModal.propTypes = {
  createTransportationTypesData: object.isRequired,
  platformsSharedData: object.isRequired,
  actionsSharedData: object.isRequired,
  detail: shape({
    isValid: bool.isRequired,
    form: object.isRequired,
    values: object.isRequired,
  }).isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
  getSharedData: func.isRequired,
  createTransportationTypesDetail: func.isRequired,
  refreshTransTypeDetail: func.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
  refreshParent: func.isRequired,
  getTicketsShared: func.isRequired,
  ticketsSharedData: object.isRequired,

};

export default connect(mapStateToProps, mapDispatchToProps)(TransportationTypesCreateModal);
