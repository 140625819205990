import React, { Component, Fragment, } from 'react';
import {
  shape, func, object,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  refreshFormDetailAction,
  changeFormValueAction,
  getOptionsAction,
  createAction,
} from '../actions/transportationEditFormsDetail';
import {
  toggleModalInputAction,
} from '../actions/transportationEditFormsModalInput';
import { validateInput, } from '../../../logic/forms/validation';
import Modal from '../../../atoms/Modal/Modal';
import TransportationEditFormsCreate from '../components/TransportationEditFormsCreate/TransportationEditFormsCreate';
import TransportationEditFormsDetail from '../components/TransportationEditFormsDetail/TransportationEditFormsDetail';
import InputModal from '../components/TransportationEditFormsDetail/InputModal';


class TransportationEditFormsCreateModal extends Component {
  componentDidMount() {
    const {
      refreshFormDetail,
      getOptions,
    } = this.props;

    refreshFormDetail();
    getOptions();
  }

  handleClose = () => {
    const { history, } = this.props;

    history.goBack();
  }

  handleChangeValue = (name, value) => {
    const { detail, changeFormValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);
    changeFormValue(name, value, validationError);
  }


  handleCreate = () => {
    const { onCreate, } = this.props;

    onCreate({ onCompleted: this.handleCreateSuccess, });
  }


  handleCreateSuccess = () => {
    const { refreshParent, } = this.props;

    refreshParent();
    this.handleClose();
  }

  render() {
    const {
      // data
      createData,
      detail,
      optionsData,
      // methods
      toggleModalInput,
    } = this.props;

    return (
      <Fragment>

        <InputModal />

        <Modal
          isOpen
          onClose={this.handleClose}
          closeOnEsc
          size="MD"
          title="Vytvoření formuláře"
        >
          <TransportationEditFormsCreate
            // data
            createData={createData}
            isFormValid={detail.isValid}
            // methods
            onClose={this.handleClose}
            onCreate={this.handleCreate}
          >
            <TransportationEditFormsDetail
              // data
              form={detail.form}
              values={detail.values}
              optionsData={optionsData}
              // methods
              onChangeValue={this.handleChangeValue}
              toggleModalInput={toggleModalInput}
            />
          </TransportationEditFormsCreate>
        </Modal>

      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { transportationEditFormsDetail, } = state;
  return {
    createData: transportationEditFormsDetail.createData,
    detail: transportationEditFormsDetail.detail,
    optionsData: transportationEditFormsDetail.optionsData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  refreshFormDetail: bindActionCreators(refreshFormDetailAction, dispatch),
  changeFormValue: bindActionCreators(changeFormValueAction, dispatch),
  getOptions: bindActionCreators(getOptionsAction, dispatch),
  toggleModalInput: bindActionCreators(toggleModalInputAction, dispatch),
  onCreate: bindActionCreators(createAction, dispatch),
});


TransportationEditFormsCreateModal.propTypes = {
  detail: object.isRequired,
  createData: object.isRequired,
  optionsData: object.isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
  refreshFormDetail: func.isRequired,
  refreshParent: func.isRequired,
  onCreate: func.isRequired,
  changeFormValue: func.isRequired,
  getOptions: func.isRequired,
  toggleModalInput: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(TransportationEditFormsCreateModal);
