export const COLS = [
  {
    id: 'id',
    width: 10,
    label: '#',
  },
  {
    id: 'name',
    width: 25,
    label: 'Název',
  },
  {
    id: 'deviceCode',
    width: 25,
    label: 'Kód zařízení',
  },
  {
    id: 'synchronized',
    width: 10,
    label: 'Synchronizováno',
  },
  {
    id: 'online',
    width: 10,
    label: 'Dostupnost',
  },
  {
    id: 'actions',
    width: 20,
    label: 'Akce',
  },
];

export const onlineOptions = {
  yes: {
    value: 'yes',
    label: 'Online',
  },
  no: {
    value: 'no',
    label: 'Offline',
  },
  all: {
    value: 'all',
    label: 'Vše',
  },
};

export const synOptions = {
  yes: {
    value: 'yes',
    label: 'Ano',
  },
  no: {
    value: 'no',
    label: 'Ne',
  },
  all: {
    value: 'all',
    label: 'Vše',
  },
};
