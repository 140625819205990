import React, { Component, } from 'react';
import styled from 'styled-components';
import { func, string, bool, } from 'prop-types';


const StyledInputSwitch = styled.div`


  .input-switch--label {
    display: inline-block;
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
    cursor: pointer;
  }

  .input-switch--label-left {
    padding-right: 0.25rem;
    user-select: none;
  }

  .input-switch--label-right {
    padding-left: 0.25rem;
    user-select: none;
  }

  /* The switch - the box around the input-switch--slider */
  .input-switch--switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    top: 7px;
  }

  /* Hide default HTML checkbox */
  .input-switch--switch input {display:none;}

  /* The input-switch--slider */
  .input-switch--slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(p) => p.theme.grey.t200};
    -webkit-transition: .4s;
    transition: .4s;
  }

  .input-switch--slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: ${(p) => p.theme.primary.t200};
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .input-switch--slider {
    background-color: ${(p) => p.theme.grey.t100};
  }

  input:focus + .input-switch--slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .input-switch--slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded input-switch--sliders */
  .input-switch--slider.round {
    border-radius: 34px;
  }

  .input-switch--slider.round:before {
    border-radius: 50%;
  }
`;


class InputSwitch extends Component {
  toggle = (e) => {
    const {
      value,
      onChange,
      left,
      right,
      disabled,
    } = this.props;
    e.preventDefault();
    e.stopPropagation();

    if (disabled || !onChange) return;

    if (value === left) onChange(right);
    else onChange(left);
  }


  toggleLeft = () => {
    const {
      value,
      onChange,
      left,
      disabled,
    } = this.props;

    if (disabled || !onChange) return;

    if (value !== left) onChange(left);
  }


  toggleRight = () => {
    const {
      value,
      onChange,
      right,
      disabled,
    } = this.props;

    if (disabled || !onChange) return;

    if (value !== right) onChange(right);
  }


  render() {
    const {
      value,
      right,
      labelLeft,
      labelRight,
      disabled,
    } = this.props;

    return (
      <StyledInputSwitch disabled={disabled}>
        <span 
          className="input-switch--label input-switch--label-left"
          onClick={this.toggleLeft}
        >
          {labelLeft}
        </span>
        <label
          className="input-switch--switch"
          onClick={this.toggle}
        >
          <input
            type="checkbox"
            checked={value === right}
            onChange={this.handleChange}
          />
          <span className="input-switch--slider"></span>
        </label>
        <span 
          className="input-switch--label input-switch--label-right"
          onClick={this.toggleRight}
        >
          {labelRight}
        </span>
      </StyledInputSwitch>
    );
  }
}


InputSwitch.propTypes = {
  value: string.isRequired,
  left: string.isRequired,
  right: string.isRequired,
  labelLeft: string,
  labelRight: string,
  disabled: bool,
  onChange: func,
};

InputSwitch.defaultProps = {
  labelLeft: '-',
  labelRight: '-',
  disabled: false,
  onChange: undefined,
};


export default InputSwitch;