import React, { Component, } from 'react';
import {
  func, string, number, shape,
} from 'prop-types';

import StyledInputItem from './styles/StyledInputItem';
import Cross from '../../../../styles/icons/Cross';
import ArrowDown from '../../../../styles/icons/ArrowDown';
import ArrowUp from '../../../../styles/icons/ArrowUp';
import Pencil from '../../../../styles/icons/Pencil';


class InputItem extends Component {
  handleDelete = () => {
    const { position, onDelete, } = this.props;

    onDelete(position);
  }


  handleMoveUp = () => {
    const { position, onMoveUp, } = this.props;

    onMoveUp(position);
  }


  handleMoveDown = () => {
    const { position, onMoveDown, } = this.props;

    onMoveDown(position);
  }


  handleDetail = () => {
    const { position, onDetail, } = this.props;

    onDetail(position);
  }


  render() {
    const {
      // data
      data,
    } = this.props;

    return (
      <StyledInputItem>

        <div className="transportationEditForm--input-element">
          {data.inputTranslation ? data.inputTranslation.label : '-'}
          <button
            className="transportationEditForm--input-btn-edit"
            type="button"
            onClick={this.handleDetail}
          >
            <Pencil />
          </button>
        </div>

        <button
          type="button"
          onClick={this.handleMoveUp}
        >
          <ArrowUp />
        </button>
        <button
          type="button"
          onClick={this.handleMoveDown}
        >
          <ArrowDown />
        </button>
        <button
          type="button"
          onClick={this.handleDelete}
        >
          <Cross />
        </button>

      </StyledInputItem>
    );
  }
}


InputItem.propTypes = {
  // data
  position: number.isRequired,
  data: shape({
    inputTranslation: shape({
      label: string.isRequired,
    }),
  }).isRequired,
  // methods
  onDelete: func.isRequired,
  onMoveUp: func.isRequired,
  onMoveDown: func.isRequired,
  onDetail: func.isRequired,
};


export default InputItem;
