import React, { Component, Fragment, } from 'react';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';
import {
  shape, func, object, bool,
} from 'prop-types';

import { validateInput, validateWholeFormValidator, } from '../../../logic/forms/validation';
import {
  getSettingsAction,
  updateSettingsAction,
  changeValueAction,
  validateFormAction,
} from '../actions/queueConfiguration';

import QueueConfigurationForm from '../components/QueueConfigurationForm/QueueConfigurationForm';

class QueueConfigurationPage extends Component {
  componentDidMount() {
    this.handleGetDetail();
  }

  handleGetDetail = () => {
    const {
      getSettings,
    } = this.props;
    getSettings();
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);
    changeValue(name, value, validationError);
  }

  handleEdit = () => {
    const {
      updateSettings,
      detail,
      updateData,
      validateForm,
    } = this.props;

    if (updateData.isLoading || !detail.isValid) return;

    const validation = validateWholeFormValidator(detail.form, detail.values);
    if (Object.keys(validation).length < 1) {
      updateSettings(detail, this.handleEditSuccess);
    } else {
      validateForm(validation);
    }
  }

  render() {
    const {
      // data
      detail,
      settingsData,
      updateData,
      // methods
      validateForm,
    } = this.props;
    return (
      <Fragment>
        <QueueConfigurationForm
          form={detail.form}
          getData={settingsData}
          updateData={updateData}
          values={detail.values}
          onChangeValue={this.handleOnChangeValue}
          onEdit={this.handleEdit}
          isFormValid={validateForm}
          onGetDetail={this.handleGetDetail}
        />
      </Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  const { queueConfiguration, } = state;
  return {
    settingsData: queueConfiguration.getData,
    updateData: queueConfiguration.updateData,
    detail: queueConfiguration.detail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSettings: bindActionCreators(getSettingsAction, dispatch),
  updateSettings: bindActionCreators(updateSettingsAction, dispatch),
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
});

QueueConfigurationPage.propTypes = {
  settingsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  updateData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  detail: object.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
  getSettings: func.isRequired,
  updateSettings: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(QueueConfigurationPage);
