import {
  validateInputAndChangeForm,
  validatePart,
  validateNames,
} from './utils';


//
// Change Part Value
//
export const changePartValueLogic = (part, input, newValue) => ({
  ...(validateInputAndChangeForm(part, input, newValue)),
  values: {
    ...part.values,
    [input]: newValue,
  },
});


//
// Change Part Validation Value
//
export const changePartValidationValueLogic = (part, input, newValue) => ({
  ...(validateInputAndChangeForm(part, input, newValue)),
  values: {
    ...part.values,
    validation: {
      ...part.values.validation,
      [input]: newValue,
    },
  },
});


//
// Change Part Validation Value
//
export const changePartTranspaltionValueLogic = (part, input, newValue) => ({
  ...(validateInputAndChangeForm(part, input, newValue)),
  values: {
    ...part.values,
    translation: {
      ...part.values.translation,
      [input]: newValue,
    },
  },
});


//
// Validate Whole Create Gen Form
//
export const validateWholeEditableGenForm = (state) => {
  const { parts, } = state;
  const newParts = {};
  let newInvalidParts = 0;
  const newMsg = {
    code: '',
    params: '',
  };

  const keys = Object.keys(parts);
  for (let i = 0; i < keys.length; i++) {
    const id = keys[i];

    newParts[id] = validatePart(parts[id]);
    if (!newParts[id].isValid) {
      newInvalidParts++;
    }
  }
  if (newInvalidParts > 0) {
    newMsg.code = 'FORM_INVALID';
  }

  // if names are same
  //  - need valid names for this valdiation
  const newInvalidNames = newInvalidParts < 1
    ? validateNames(parts)
    : [];
  if (newInvalidNames.length > 0) {
    newMsg.code = 'FORM_SAME_NAMES';
    newMsg.params = {};
  }

  return {
    ...state,
    commonValidation: {
      isValid: newInvalidParts < 1,
      invalidParts: newInvalidParts,
    },
    finalValidation: {
      isValid: newInvalidNames.length < 1,
      invalidNames: newInvalidNames,
    },
    msg: newMsg,
    parts: newParts,
  };
};
