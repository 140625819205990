import React, { Component, Fragment, } from 'react';
import {
  shape, bool, func, string,
} from 'prop-types';

import { COLS, onlineOptions, synOptions, } from './utils';
import RTCol from '../../../../atoms/Table/RTCol';
import RTHeader from '../../../../atoms/Table/RTHeader';
import SortDefault from '../../../../styles/icons/SortDefault';
import SortAsc from '../../../../styles/icons/SortAsc';
import SortDesc from '../../../../styles/icons/SortDesc';
import Input from '../../../../atoms/Input/Input';
import InputSelectWrapper from '../../../../components/wrappers/InputSelectWrapper';


class ControllersTableHeader extends Component {
  renderSortIcon = (id) => {
    const { filter, controllersData, } = this.props;
    const className = `rt--col-icon ${controllersData.isLoading ? 'rt--col-icon-disabled' : ''}`;

    if (id !== filter.sortBy) {
      return (
        <span className={className}>
          <SortDefault />
        </span>
      );
    }

    if (filter.order === 'ASC') {
      return (
        <span className={className}>
          <SortAsc />
        </span>
      );
    }

    return (
      <span className={className}>
        <SortDesc />
      </span>
    );
  }

  render() {
    const {
      // data
      filter,
      controllersData,
      // methods
      onClickSort,
      onChangeParam,
    } = this.props;

    return (
      <Fragment>

        <RTHeader>
          <RTCol
            header
            clickable
            width={COLS[0].width}
            onClick={() => onClickSort(COLS[0].id)}
          >
            <span>{COLS[0].label}</span>
            {this.renderSortIcon(COLS[0].id)}
          </RTCol>

          <RTCol
            header
            clickable
            width={COLS[1].width}
            onClick={() => onClickSort(COLS[1].id)}
          >
            {COLS[1].label}
            {this.renderSortIcon(COLS[1].id)}
          </RTCol>

          <RTCol
            header
            clickable
            width={COLS[2].width}
            onClick={() => onClickSort(COLS[2].id)}
          >
            {COLS[2].label}
            {this.renderSortIcon(COLS[2].id)}
          </RTCol>

          <RTCol header width={COLS[3].width}>
            {COLS[3].label}
          </RTCol>

          <RTCol header width={COLS[4].width}>
            {COLS[4].label}
          </RTCol>

          <RTCol header width={COLS[5].width}>
            {COLS[5].label}
          </RTCol>
        </RTHeader>

        <RTHeader>
          <RTCol
            width={COLS[0].width}
          >
            <Input type="text" shape="line" placeholder="filter" name={COLS[0].id} value={filter.params.id} onChange={(e) => onChangeParam(e.target.name, e.target.value)} autoComplete="off" />
          </RTCol>

          <RTCol
            width={COLS[1].width}
          >
            <Input type="text" shape="line" placeholder="filter" name={COLS[1].id} value={filter.params.name} onChange={(e) => onChangeParam(e.target.name, e.target.value)} autoComplete="off" />
          </RTCol>

          <RTCol
            width={COLS[2].width}
          >
            <Input type="text" shape="line" placeholder="filter" name={COLS[2].id} value={filter.params.deviceCode} onChange={(e) => onChangeParam(e.target.name, e.target.value)} autoComplete="off" />
          </RTCol>

          <RTCol
            width={COLS[3].width}
          >
            <InputSelectWrapper
              shape="line"
              options={synOptions}
              value={synOptions[filter.params.synchronized]}
              onChange={(newValue) => onChangeParam(COLS[3].id, newValue.value)}
              isClearable={false}
              isLoading={controllersData.isLoading}
            />
          </RTCol>

          <RTCol
            width={COLS[4].width}
          >
            <InputSelectWrapper
              shape="line"
              options={onlineOptions}
              value={onlineOptions[filter.params.online]}
              onChange={(newValue) => onChangeParam(COLS[4].id, newValue.value)}
              isClearable={false}
              isLoading={controllersData.isLoading}
            />
          </RTCol>

          <RTCol width={COLS[5].width} />
        </RTHeader>
      </Fragment>
    );
  }
}


ControllersTableHeader.propTypes = {
  filter: shape({
    sortBy: string.isRequired,
    order: string.isRequired,
  }).isRequired,
  controllersData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  onClickSort: func.isRequired,
  onChangeParam: func.isRequired,
};


export default ControllersTableHeader;
