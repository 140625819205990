import styled from 'styled-components';

const NodeElement = styled.div`
  position: relative;
  font-size: 1rem;
  padding: 10px;

`;

export default NodeElement;
