import styled from 'styled-components';


const StyledMSLList = styled.div`
  display: flex;
  font-size: 1rem;
  -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */

  .msl--center-panel {
    width: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .msl--center-panel > button {
    margin: 4px 0;
  }
`;

export default StyledMSLList;
