import {
  QUEUE_CONFIG__GET_STAND_APP_CONFIGURATION,
  QUEUE_CONFIG__STAND_APP_CONFIGURATION_FILTER_CHANGED,
} from '../actions/standAppConfiguration';
import {
  QUEUE_CONFIG__DELETE_STAND_APP_CONFIGURATION_DETAIL,
} from '../actions/standAppConfigurationDetail';
import { PENDING, FULFILLED, REJECTED, } from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';


const initialState = {
  standAppConfigurationData: {
    ...initRestData,
    isLoading: true,
  },
  table: {
    data: [],
    count: 0,
    filter: {
      offset: 0,
      limit: 10,
      sortBy: 'id',
      order: 'ASC',
      params: {
        id: '',
        name: '',
      },
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;
  switch (type) {
    /**
     * GET All Stand App Configuration
     */
    case `${QUEUE_CONFIG__GET_STAND_APP_CONFIGURATION}__${PENDING}`: {
      return {
        ...state,
        standAppConfigurationData: {
          ...state.standAppConfigurationData,
          ...(handlePending()),
        },
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    case `${QUEUE_CONFIG__GET_STAND_APP_CONFIGURATION}__${FULFILLED}`: {
      return {
        ...state,
        standAppConfigurationData: {
          ...state.standAppConfigurationData,
          ...(handleFulfilled(payload)),
        },
        table: {
          data: payload.standAppConfiguration,
          count: payload.count,
          filter: payload.filter,
        },
      };
    }

    case `${QUEUE_CONFIG__GET_STAND_APP_CONFIGURATION}__${REJECTED}`: {
      return {
        ...state,
        standAppConfigurationData: {
          ...state.standAppConfigurationData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * DELETE Stand
     */
    case `${QUEUE_CONFIG__DELETE_STAND_APP_CONFIGURATION_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.filter((item) => item.id !== payload.id),
        },
      };
    }


    /**
     * Change Filter
     */
    case `${QUEUE_CONFIG__STAND_APP_CONFIGURATION_FILTER_CHANGED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
