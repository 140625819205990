import React, { Component, Fragment, } from 'react';
import {
  shape, number, func, object, string, arrayOf, bool,
} from 'prop-types';
import { Route, } from 'react-router-dom';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  toggleKiosksConfigurationDeleteModalAction,
  deleteKiosksConfigurationDetailAction,
} from '../actions/kiosksConfigurationDetail';
import { getKiosksConfigurationsAction, changeFilterAction, } from '../actions/kiosksConfigurations';
import KiosksConfigurations from '../components/KiosksConfigurations/KiosksConfigurations';
import KiosksConfigurationCreateModal from './KiosksConfigurationCreateModal';
import KiosksConfigurationEditModal from './KiosksConfigurationEditModal';
import KiosksConfigurationDeleteModal from '../components/KiosksConfigurationDeleteModal/KiosksConfigurationDeleteModal';


// TODO:
//  - kdyz vyplnim jednu hodnotu, tak to ma vlivna druhou
//    => napsat k tomu funkci pro zmenu se side efektama
class KioskConfigurationPage extends Component {
  componentDidMount() {
    const { table, } = this.props;
    this.handleGetKiosksConfigurations(table.filter);
  }

  handleGetKiosksConfigurations = (newFilter) => {
    const { getKiosksConfigurations, table, } = this.props;
    getKiosksConfigurations(newFilter === undefined ? table.filter : newFilter);
  }

  handleEdit = (id) => {
    const { match, history, } = this.props;
    history.push(`${match.path}/edit/${id}`);
  }

  handleCreate = () => {
    const { match, history, } = this.props;
    history.push(`${match.path}/create`);
  }

  handleDelete = () => {
    const {
      deleteKiosksConfigurationData,
      deleteKiosksConfigurationDetail,
      kiosksConfigurationDeleteModal,
    } = this.props;
    if (deleteKiosksConfigurationData.isLoading) return;
    deleteKiosksConfigurationDetail(kiosksConfigurationDeleteModal.id);
  }

  handleToggle = () => {
    const { toggleKiosksConfigurationDeleteModal, } = this.props;
    toggleKiosksConfigurationDeleteModal(false);
  }

  render() {
    const {
      match,
      table,
      kiosksConfigurationDeleteModal,
      deleteKiosksConfigurationData,
      kiosksConfigurationsData,
      changeFilter,
      toggleKiosksConfigurationDeleteModal,
    } = this.props;

    return (
      <Fragment>

        <KiosksConfigurations
          table={table}
          kiosksConfigurationsData={kiosksConfigurationsData}
          handleGetKiosksConfigurations={this.handleGetKiosksConfigurations}
          handleEdit={this.handleEdit}
          handleDelete={toggleKiosksConfigurationDeleteModal}
          handleCreate={this.handleCreate}
          changeFilter={changeFilter}
        />

        <Route
          exact
          path={`${match.path}/create`}
          render={(props) => (
            <KiosksConfigurationCreateModal
              {...props}
              refreshParent={this.handleGetKiosksConfigurations}
            />
          )}
        />
        <Route
          exact
          path={`${match.path}/edit/:id`}
          render={(props) => (
            <KiosksConfigurationEditModal
              {...props}
              refreshParent={this.handleGetKiosksConfigurations}
            />
          )}
        />

        <KiosksConfigurationDeleteModal
          modalData={kiosksConfigurationDeleteModal}
          actionData={deleteKiosksConfigurationData}
          action={this.handleDelete}
          onClose={this.handleToggle}
        />

      </Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  const { kiosksConfigurations, kiosksConfigurationDetail, } = state;
  return {
    kiosksConfigurationsData: kiosksConfigurations.kiosksConfigurationsData,
    table: kiosksConfigurations.table,
    kiosksConfigurationDeleteModal: kiosksConfigurationDetail.kiosksConfigurationDeleteModal,
    deleteKiosksConfigurationData: kiosksConfigurationDetail.deleteKiosksConfigurationData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getKiosksConfigurations: bindActionCreators(getKiosksConfigurationsAction, dispatch),
  toggleKiosksConfigurationDeleteModal: bindActionCreators(toggleKiosksConfigurationDeleteModalAction, dispatch), // eslint-disable-line
  changeFilter: bindActionCreators(changeFilterAction, dispatch),
  deleteKiosksConfigurationDetail: bindActionCreators(deleteKiosksConfigurationDetailAction, dispatch), // eslint-disable-line
});


KioskConfigurationPage.propTypes = {
  table: shape({
    data: arrayOf(object).isRequired,
    count: number.isRequired,
    filter: object.isRequired,
  }).isRequired,
  kiosksConfigurationsData: object.isRequired,
  changeFilter: func.isRequired,
  getKiosksConfigurations: func.isRequired,
  toggleKiosksConfigurationDeleteModal: func.isRequired,
  match: shape({
    path: string.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  deleteKiosksConfigurationDetail: func.isRequired,
  deleteKiosksConfigurationData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  kiosksConfigurationDeleteModal: shape({
    id: number,
    isOpen: bool.isRequired,
    collision: bool.isRequired,
  }).isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(KioskConfigurationPage);
