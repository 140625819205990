import React, { Component, Fragment, } from 'react';
import {
  shape, number, func, object, string, arrayOf, bool,
} from 'prop-types';
import { Route, } from 'react-router-dom';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  toggleActionsDeleteModalAction,
  deleteActionsDetailAction,
} from '../actions/actionsDetail';
import { getActionsAction, changeFilterAction, } from '../actions/actions';
import Actions from '../components/Actions/Actions';
import ActionsCreateModal from './ActionsCreateModal';
import ActionsEditModal from './ActionsEditModal';
import ActionsDeleteModal from '../components/ActionsDeleteModal/ActionsDeleteModal';


class ActionsPage extends Component {
  componentDidMount() {
    const { table, } = this.props;

    this.handleGetActions(table.filter);
  }

  handleGetActions = (newFilter) => {
    const { getActions, table, } = this.props;

    getActions(newFilter === undefined ? table.filter : newFilter);
  }

  handleEdit = (id) => {
    const { match, history, } = this.props;

    history.push(`${match.path}/edit/${id}`);
  }

  handleCreate = () => {
    const { match, history, } = this.props;

    history.push(`${match.path}/create`);
  }

  handleDelete = () => {
    const { deleteActionsData, deleteActionsDetail, actionsDeleteModal, } = this.props;

    if (deleteActionsData.isLoading) return;

    deleteActionsDetail(actionsDeleteModal.id);
  }

  handleToggle = () => {
    const { toggleActionsDeleteModal, } = this.props;

    toggleActionsDeleteModal(false);
  }


  render() {
    const {
      match,
      table,
      actionsDeleteModal,
      deleteActionsData,
      actionsData,
      changeFilter,
      toggleActionsDeleteModal,
    } = this.props;

    return (
      <Fragment>

        <Actions
          table={table}
          actionsData={actionsData}
          handleGetActions={this.handleGetActions}
          handleEdit={this.handleEdit}
          handleDelete={toggleActionsDeleteModal}
          handleCreate={this.handleCreate}
          changeFilter={changeFilter}
        />

        <Route
          exact
          path={`${match.path}/create`}
          render={
            (props) => <ActionsCreateModal {...props} refreshParent={this.handleGetActions} />
          }
        />
        <Route
          exact
          path={`${match.path}/edit/:id`}
          render={
            (props) => <ActionsEditModal {...props} refreshParent={this.handleGetActions} />
          }
        />

        <ActionsDeleteModal
          modalData={actionsDeleteModal}
          actionData={deleteActionsData}
          action={this.handleDelete}
          onClose={() => toggleActionsDeleteModal(false)}
        />

      </Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  const { actions, actionsDetail, } = state;
  return {
    actionsData: actions.actionsData,
    table: actions.table,
    actionsDeleteModal: actionsDetail.actionsDeleteModal,
    deleteActionsData: actionsDetail.deleteActionsData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getActions: bindActionCreators(getActionsAction, dispatch),
  toggleActionsDeleteModal: bindActionCreators(toggleActionsDeleteModalAction, dispatch), // eslint-disable-line
  changeFilter: bindActionCreators(changeFilterAction, dispatch),
  deleteActionsDetail: bindActionCreators(deleteActionsDetailAction, dispatch), // eslint-disable-line
});


ActionsPage.propTypes = {
  table: shape({
    data: arrayOf(object).isRequired,
    count: number.isRequired,
    filter: object.isRequired,
  }).isRequired,
  actionsData: object.isRequired,
  changeFilter: func.isRequired,
  getActions: func.isRequired,
  toggleActionsDeleteModal: func.isRequired,
  match: shape({
    path: string.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  deleteActionsDetail: func.isRequired,
  deleteActionsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  actionsDeleteModal: object.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(ActionsPage);
