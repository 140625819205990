import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION, FORM_SUCCESS, FORM_ERROR,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


const parseValues = (values) => ({
  name: values.name,
  kioskConfigurationId: values.kioskConfiguration.id,
  standId: values.standId.id,
  cameraIds: values.cameraIds.map((item) => (item.id)),
});


/**
 * Refresh data - on mount
 */
export const DEVICES__KIOSKS_REFRESH = 'DEVICES__KIOSKS_REFRESH';

export const refreshKiosksDetailAction = () => ({
  type: `${DEVICES__KIOSKS_REFRESH}`,
});


/**
 * Change Value
 */
export const DEVICES__KIOSKS_CHANGE_VALUE_DETAIL = 'DEVICES__KIOSKS_CHANGE_VALUE_DETAIL';

export const changeValueAction = (name, value, validationError) => ({
  type: `${DEVICES__KIOSKS_CHANGE_VALUE_DETAIL}`,
  payload: {
    name,
    value,
    validationError,
  },
});


/**
 * Validate Form
 */
export const DEVICES__KIOSKS_VALIDATE_FORM_DETAIL = 'DEVICES__KIOSKS_VALIDATE_FORM_DETAIL';

export const validateFormAction = (error) => ({
  type: `${DEVICES__KIOSKS_VALIDATE_FORM_DETAIL}`,
  payload: error,
});


/*
 * GET Kiosks Detail
 */
export const DEVICES__GET_KIOSKS_DETAIL = 'DEVICES__GET_KIOSKS_DETAIL';
export const getKiosksDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__GET_KIOSKS_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/devices/kiosk/detail?id=${id}`,
    });

    dispatch({
      type: `${DEVICES__GET_KIOSKS_DETAIL}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${DEVICES__GET_KIOSKS_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * UPDATE Kiosks Detail
 */
export const DEVICES__UPDATE_KIOSKS_DETAIL = 'DEVICES__UPDATE_KIOSKS_DETAIL';
export const updateKiosksDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__UPDATE_KIOSKS_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/devices/kiosk`,
      data: {
        id: item.id,
        values: parseValues(item.values),
      },
    });

    dispatch({
      type: `${DEVICES__UPDATE_KIOSKS_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Kiosek aktualizován',
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${DEVICES__UPDATE_KIOSKS_DETAIL}__${REJECTED_VALIDATION}`,
        payload: parsedError,
      });
      return;
    }

    dispatch({
      type: `${DEVICES__UPDATE_KIOSKS_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * DELETE Kiosks Detail
 */
export const DEVICES__DELETE_KIOSKS_DETAIL = 'DEVICES__DELETE_KIOSKS_DETAIL';
export const deleteKioskDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__DELETE_KIOSKS_DETAIL}__${PENDING}`,
      payload: {
        id,
      },
    });

    const response = await api({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/devices/kiosk?id=${id}`,
    });

    dispatch({
      type: `${DEVICES__DELETE_KIOSKS_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        id,
        notif: {
          type: FORM_SUCCESS,
          title: 'Kiosek odhlášen',
        },
      },
    });
  } catch (err) {
    const parsedError = handleError(err);

    dispatch({
      type: `${DEVICES__DELETE_KIOSKS_DETAIL}__${REJECTED}`,
      payload: {
        ...parsedError,
        id,
        notif: {
          type: FORM_ERROR,
          title: 'Nepovedlo se odhlásit kiosek',
          showMessage: true,
        },
      },
    });
  }
};
