import styled from 'styled-components';

import { FORM_WARNING, FORM_ERROR, FORM_SUCCESS, } from '../../../globals';


const StyledInfoMsg = styled.span`
  margin-top: .25rem;
  font-size: 0.8rem; 
  padding-left: 0.25rem;
  padding-right: 0.25rem; 

  /* Error */
  ${(p) => p.status && p.status === FORM_ERROR && `
    color: ${p.theme.error.t800};
  `}

  /* Warning */
  ${(p) => p.status && p.status === FORM_WARNING && `
    color: ${p.theme.warning.t800};
  `}

  /* Success */
  ${(p) => p.status && p.status === FORM_SUCCESS && `
    color: ${p.theme.success.t800};
  `}

  .info-msg--icon {
    padding-right: 0.25rem;
    font-size: 0.75rem;
    vertical-align: text-bottom;
  }
`;

export default StyledInfoMsg;
