import { AUTH__LOGIN__CHANGE_FORM, } from '../actions/login';

const initialState = {
  loginForm: {
    username: {
      value: '',
      errorCode: null,
    },
    password: {
      value: '',
      errorCode: null,
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;
  switch (type) {
    /**
     * Change Login Form
     */
    case AUTH__LOGIN__CHANGE_FORM: {
      return {
        ...state,
        loginForm: {
          ...state.loginForm,
          [payload.name]: {
            ...state.loginForm[payload.name],
            value: payload.value,
            errorCode: payload.errorCode,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
