import styled from 'styled-components';


const ButtonGrp = styled.div`
  button:not(:last-child) {
    margin-right: 0.5rem;
  }
`;


export default ButtonGrp;
