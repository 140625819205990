import React, { Component, Fragment, } from 'react';
import {
  shape, bool, func, oneOfType, arrayOf, node, object,
} from 'prop-types';

import { FORM_ERROR, FORM_SUCCESS, } from '../../../../globals';
import { withLang, } from '../../../../logic/languages/withLang';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import ButtonGrp from '../../../../atoms/ButtonGrp/ButtonGrp';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';
import SegmentLaoding from '../../../../components/SegmentLaoding/SegmentLaoding';
import SegmentError from '../../../../components/SegmentError/SegmentError';
import Pencil from '../../../../styles/icons/Pencil';


class TicketsEdit extends Component {
  renderChildren = () => {
    const { children, detailTicketsData, onGetDetail, } = this.props;

    if (detailTicketsData.isLoading) {
      return <SegmentLaoding formGroup />;
    }

    if (detailTicketsData.error) {
      return (
        <SegmentError
          formGroup
          message={detailTicketsData.message}
          refresh={onGetDetail}
        />
      );
    }

    return children;
  }

  render() {
    const {
      isFormValid,
      detailTicketsData,
      updateTicketsData,
      handleClose,
      handleEdit,
      langError,
    } = this.props;

    return (
      <Fragment>

        {this.renderChildren()}

        <Row>
          <Col textAlign="right">
            <InfoMsg
              status={updateTicketsData.error ? FORM_ERROR : FORM_SUCCESS}
              msg={langError(updateTicketsData.message)}
              showIcon
            />
          </Col>
          <Col textAlign="right">
            <ButtonGrp>

              <Button
                type="button"
                shape="outline"
                onClick={handleClose}
              >
                Zpět
              </Button>

              { detailTicketsData.status && (
                <ButtonLoader
                  type="button"
                  color="primary"
                  onClick={handleEdit}
                  disabled={!isFormValid}
                  isLoading={updateTicketsData.isLoading}
                >
                  <span className="btn--icon">
                    <Pencil />
                  </span>
                  Upravit
                </ButtonLoader>
              )}
            </ButtonGrp>
          </Col>
        </Row>

      </Fragment>
    );
  }
}


TicketsEdit.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
  isFormValid: bool.isRequired,
  detailTicketsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
    message: object.isRequired,
  }).isRequired,
  updateTicketsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
    message: object.isRequired,
  }).isRequired,
  handleClose: func.isRequired,
  handleEdit: func.isRequired,
  onGetDetail: func.isRequired,
  langError: func.isRequired,
};


export default withLang(TicketsEdit);
