import React, { Component, } from 'react';
import Select from 'react-select';
import {
  bool, func, object, string, oneOfType, arrayOf,
} from 'prop-types';
import { withTheme, } from 'styled-components';

import {
  FORM_DEFAULT, FORM_ERROR, FORM_WARNING, FORM_SUCCESS,
} from '../../globals';


class InputSelect extends Component {
  customStyles = {
    container: (base) => ({
      ...base,
      width: '100%',
    }),
    control: (base, state) => {
      const { theme, shape, } = this.props;

      const borderColor = this.getBorderColor(state.isFocused);
      const shapeStyle = this.getShapeStyle(shape, borderColor, state);

      return {
        ...base,
        borderColor,
        boxShadow: state.isFocused ? `0 0 0 1px ${borderColor}` : 'none',
        outline: 'none',
        boxSizing: 'border-box',
        transition: 'all 0.3s ease',
        borderStyle: theme.input.borderStyle,
        ...shapeStyle,

        '&:hover': {
          borderColor,
        },

        '&:disabled': {
          background: theme.grey.t100,
        },
      };
    },
    placeholder: () => {
      const { theme, } = this.props;

      return {
        color: theme.grey.t400,
        opacity: 1,
      };
    },
    clearIndicator: (base, state) => {
      const { theme, } = this.props;

      const color = state.isFocused ? theme.grey.t600 : theme.grey.t400;
      const colorHover = state.isFocused ? theme.error.t800 : theme.error.t600;

      return {
        ...base,
        color,
        cursor: 'pointer',

        '&:hover': {
          color: colorHover,
        },
      };
    },

    dropdownIndicator: (base, state) => {
      const { theme, } = this.props;

      const color = state.isFocused ? theme.grey.t600 : theme.grey.t400;
      const colorHover = state.isFocused ? theme.grey.t800 : theme.grey.t600;

      return {
        ...base,
        color,
        cursor: 'pointer',

        '&:hover': {
          color: colorHover,
        },
      };
    },

    indicatorSeparator: (base) => {
      const { theme, } = this.props;

      return {
        ...base,
        color: theme.grey.t400,
      };
    },

    menu: (base) => {
      const { theme, } = this.props;

      return {
        ...base,
        boxShadow: theme.common.shadow,
        zIndex: 5,
      };
    },

    option: (base, { isSelected, isFocused, }) => {
      const { theme, } = this.props;
      let style = {};

      if (isSelected) {
        style = {
          color: theme.white,
          background: theme.primary.t500,
        };
      } else if (isFocused) {
        style = {
          background: theme.primary.t100,
        };
      }

      return {
        ...base,
        ...style,

        ':active': {
          background: isSelected ? theme.primary.t400 : theme.primary.t200,
        },
      };
    },

    multiValueRemove: (base) => {
      const { theme, } = this.props;

      return {
        ...base,
        cursor: 'pointer',

        '&:hover': {
          color: theme.error.t900,
          background: theme.error.t100,
        },
      };
    },
  };

  state = {
    wasInitFetch: false,
  }

  handleFetch = () => {
    const { wasInitFetch, } = this.state;
    const { fetchData, isLoading, error, } = this.props;

    if (isLoading || (!error && wasInitFetch)) return;

    this.setState({
      wasInitFetch: true,
    });

    fetchData();
  }

  getBorderColor = (isFocused) => {
    const { theme, status, } = this.props;

    switch (status) {
      case FORM_ERROR: {
        return theme.error.t500;
      }
      case FORM_WARNING: {
        return theme.warning.t500;
      }
      case FORM_SUCCESS: {
        return theme.success.t500;
      }
      default: {
        if (isFocused) {
          return theme.primary.t500;
        }
        return theme.grey.t500;
      }
    }
  }

  getShapeStyle = (shape, borderColor, state) => {
    const { theme, } = this.props;

    if (shape === 'line') {
      return {
        borderRadius: 0,
        borderTopWidth: 0,
        borderBottomWidth: theme.input.borderWidthTopBottom,
        borderRightWidth: 0,
        borderLeftWidth: 0,
        boxShadow: state.isFocused ? `0 1px 0 0 ${borderColor}` : 'none',
      };
    }
    return {
      borderRadius: theme.input.borderRadius,
      borderTopWidth: theme.input.borderWidthTopBottom,
      borderBottomWidth: theme.input.borderWidthTopBottom,
      borderRightWidth: theme.input.borderWidthLeftRight,
      borderLeftWidth: theme.input.borderWidthLeftRight,
    };
  }

  parseOptions = () => {
    const { options, } = this.props;
    if (Array.isArray(options)) return options;

    return Object.keys(options).map((key) => ({
      ...options[key],
    }));
  }

  render() {
    const {
      // data
      value,
      isLoading,
      error,
      isMulti,
      isClearable,
      isSearchable,
      isDisabled,
      loadingMessage,
      noOptionsMessage,
      placeholder,
      errorMessage,
      // methods
      onChange,
      onBlur,
      getOptionLabel,
      getOptionValue,
      isOptionDisabled,
    } = this.props;

    const parsedOptions = this.parseOptions();

    let newPlaceholder = placeholder;
    let newNoOptionsMessage = noOptionsMessage;
    // if error and no data => error message
    if (!isLoading && error) {
      newPlaceholder = errorMessage;
      if (parsedOptions.length < 1) {
        newNoOptionsMessage = errorMessage;
      }
    }

    return (
      <Select
        // data
        styles={this.customStyles}
        value={value}
        options={parsedOptions}
        onMenuOpen={this.handleFetch}
        isMulti={isMulti}
        isClearable={isClearable}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        placeholder={newPlaceholder}
        // methods
        loadingMessage={() => loadingMessage}
        noOptionsMessage={() => newNoOptionsMessage}
        onChange={onChange}
        onBlur={onBlur}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isOptionDisabled={isOptionDisabled}
      />
    );
  }
}


InputSelect.propTypes = {
  // data
  value: oneOfType([ object, arrayOf(object), ]),
  options: oneOfType([ object, arrayOf(object), ]),
  isLoading: bool,
  error: bool,
  shape: string,
  isMulti: bool,
  status: string,
  isClearable: bool,
  isSearchable: bool,
  isDisabled: bool,
  loadingMessage: string,
  noOptionsMessage: string,
  placeholder: string,
  errorMessage: string,
  theme: object.isRequired,
  // methods
  onChange: func,
  fetchData: func,
  onBlur: func,
  getOptionLabel: func,
  getOptionValue: func,
  isOptionDisabled: func,
};


InputSelect.defaultProps = {
  // data
  value: null,
  options: {},
  isLoading: false,
  error: false,
  status: FORM_DEFAULT,
  shape: 'default',
  isMulti: false,
  isClearable: true,
  isSearchable: true,
  isDisabled: false,
  loadingMessage: 'Loading',
  noOptionsMessage: 'No options',
  placeholder: 'Select',
  errorMessage: 'Error',
  // methods
  onChange: () => {},
  fetchData: () => {},
  onBlur: () => {},
  getOptionLabel: (option) => option.label,
  getOptionValue: (option) => option.value,
  isOptionDisabled: () => {},
};


export default withTheme(InputSelect);
