import React, { Component, } from 'react';
import {
  shape, string, func,
} from 'prop-types';

import StyledNotif from './styles/StyledNotif';
import Success from '../../styles/icons/Success';
import Error from '../../styles/icons/Error';
import { FORM_SUCCESS, } from '../../globals';
import { withLang, } from '../languages/withLang';


class Notif extends Component {
  constructor(props) {
    super(props);

    this.killMyself = null;
  }

  componentDidMount() {
    this.killMyself = setTimeout(this.handleOnTimeout, 3000);
  }

  componentWillUnmount() {
    if (this.killMyself !== null) {
      clearTimeout(this.killMyself);
      this.killMyself = null;
    }
  }

  handleOnTimeout = () => {
    const { data, onTimeout, } = this.props;

    this.killMyself = null;
    onTimeout(data.id);
  }

  handleOnMouseEnter = () => {
    if (this.killMyself !== null) {
      clearTimeout(this.killMyself);
      this.killMyself = null;
    }
  }

  handleOnMouseLeave = () => {
    if (this.killMyself !== null) {
      clearTimeout(this.killMyself);
      this.killMyself = null;
    }
    this.killMyself = setTimeout(this.handleOnTimeout, 1000);
  }

  handleOnClick = () => {
    if (this.killMyself !== null) {
      clearTimeout(this.killMyself);
      this.killMyself = null;
    }
    this.handleOnTimeout();
  }

  render() {
    const { data, langError, } = this.props;

    const icon = data.type === FORM_SUCCESS
      ? <Success />
      : <Error />;

    return (
      <StyledNotif
        onMouseEnter={this.handleOnMouseEnter}
        onMouseLeave={this.handleOnMouseLeave}
        onClick={this.handleOnClick}
        type={data.type}
        className="noselect"
      >
        <div className="notif--icon">
          {icon}
        </div>
        <div>
          <div className="notif--title">
            {data.title}
          </div>
          <div className="notif--text">
            {data.showMessage ? langError(data.message) : data.text}
          </div>
        </div>
      </StyledNotif>
    );
  }
}


Notif.propTypes = {
  onTimeout: func.isRequired,
  data: shape({
    id: string.isRequired,
  }).isRequired,
  langError: func.isRequired,
};


export default withLang(Notif);
