import React, { Component, } from 'react';
import {
  func, object,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  createStandAppConfigurationDetailAction,
  validateFormAction,
  changeValueAction,
  refreshStandAppConfigurationDetailAction,
} from '../actions/standAppConfigurationDetail';
import {
  getStandAppConfigurationColumnsSharedAction,
} from '../../shared/actions/shared';
import { validateInput, validateWholeFormValidator, } from '../../../logic/forms/validation';
import Modal from '../../../atoms/Modal/Modal';
import StandAppConfigurationCreate from '../components/StandAppConfigurationCreate/StandAppConfigurationCreate';
import StandAppConfigurationDetail from '../components/StandAppConfigurationDetail/StandAppConfigurationDetail';

class StandAppConfigurationCreateModal extends Component {
  componentDidMount() {
    const {
      refreshStandAppConfigurationDetail,
    } = this.props;

    refreshStandAppConfigurationDetail();
  }

  handleClose = () => {
    const { history, } = this.props;

    history.goBack();
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);

    changeValue(name, value, validationError);
  }

  handleCreate = () => {
    const {
      createStandAppConfigurationData,
      detail,
      validateForm,
      createStandAppConfigurationDetail,
    } = this.props;

    if (createStandAppConfigurationData.isLoading || !detail.isValid) return;

    const validation = validateWholeFormValidator(detail.form, detail.values);
    if (Object.keys(validation).length < 1) {
      createStandAppConfigurationDetail(detail, this.handleCreateSuccess);
    } else {
      validateForm(validation);
    }
  }

  handleCreateSuccess = () => {
    const { refreshParent, } = this.props;

    refreshParent();
    this.handleClose();
  }

  render() {
    const {
      createStandAppConfigurationData,
      standAppConfigurationColumnsSharedData,
      getStandAppConfigurationColumnsShared,
      detail,
    } = this.props;

    return (
      <Modal
        isOpen
        onClose={this.handleClose}
        closeOnEsc
        size="LG"
        title="Vytvoření konfigurace pro aplikaci Stanoviště"
      >
        <StandAppConfigurationCreate
          // data
          createStandAppConfigurationData={createStandAppConfigurationData}
          isFormValid={detail.isValid}
          // methods
          handleClose={this.handleClose}
          handleCreate={this.handleCreate}
        >
          <StandAppConfigurationDetail
            // data
            values={detail.values}
            form={detail.form}
            // methods
            handleClose={this.handleClose}
            handleOnChangeValue={this.handleOnChangeValue}
            standAppConfigurationColumnsSharedData={standAppConfigurationColumnsSharedData}
            getStandAppConfigurationColumnsShared={getStandAppConfigurationColumnsShared}
          />
        </StandAppConfigurationCreate>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { standAppConfigurationDetail, shared, } = state;
  return {
    createStandAppConfigurationData: standAppConfigurationDetail.createStandAppConfigurationData,
    detail: standAppConfigurationDetail.detail,
    // dropdowns
    standAppConfigurationColumnsSharedData: shared.standAppConfigurationColumnsData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createStandAppConfigurationDetail: bindActionCreators(createStandAppConfigurationDetailAction, dispatch), // eslint-disable-line
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
  refreshStandAppConfigurationDetail: bindActionCreators(refreshStandAppConfigurationDetailAction, dispatch),  // eslint-disable-line
  getStandAppConfigurationColumnsShared: bindActionCreators(getStandAppConfigurationColumnsSharedAction, dispatch), // eslint-disable-line
});


StandAppConfigurationCreateModal.propTypes = {
  createStandAppConfigurationData: object.isRequired,
  refreshStandAppConfigurationDetail: func.isRequired,
  createStandAppConfigurationDetail: func.isRequired,
  standAppConfigurationColumnsSharedData: object.isRequired,
  getStandAppConfigurationColumnsShared: func.isRequired,
  history: object.isRequired,
  detail: object.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
  refreshParent: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(StandAppConfigurationCreateModal);
