import React, { Component, } from 'react';
import {
  shape, func, object, bool, arrayOf,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import { validateInput, } from '../../../../logic/forms/validation';
import {
  toggleModalInputAction,
  changeFormValueAction,
  confirmAction,
} from '../../actions/transportationEditFormsModalInput';
import Modal from '../../../../atoms/Modal/Modal';
import InputModalView from './InputModalView';


class InputModal extends Component {
  handleChangeValue = (name, value) => {
    const { inputForm, changeFormValue, } = this.props;
    const validationError = validateInput(inputForm.form[name], value);
    changeFormValue(name, value, validationError);
  }


  render() {
    const {
      modalData: {
        isOpen,
      },
      typeOptions,
      inputForm,
      // methods
      onToggle,
      confirm,
    } = this.props;

    if (!isOpen) return null;

    return (
      <Modal
        isOpen
        onClose={() => onToggle({})}
        closeOnEsc
        size="MD"
        title="Detail formuláře"
      >
        <InputModalView
          // data
          typeOptions={typeOptions}
          inputForm={inputForm}
          // methods
          onToggle={onToggle}
          onChangeValue={this.handleChangeValue}
          onConfirm={confirm}
        />
      </Modal>
    );
  }
}


const mapStateToProps = (state) => {
  const { transportationEditFormsModalInput, } = state;
  return {
    modalData: transportationEditFormsModalInput.modalData,
    typeOptions: transportationEditFormsModalInput.typeOptions,
    inputForm: transportationEditFormsModalInput.inputForm,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onToggle: bindActionCreators(toggleModalInputAction, dispatch),
  changeFormValue: bindActionCreators(changeFormValueAction, dispatch),
  confirm: bindActionCreators(confirmAction, dispatch),
});


InputModal.propTypes = {
  // data
  modalData: shape({
    isOpen: bool.isRequired,
  }).isRequired,
  typeOptions: arrayOf(object).isRequired,
  inputForm: shape({
    isValid: bool.isRequired,
    form: object.isRequired,
    values: object.isRequired,
  }).isRequired,
  // methods
  onToggle: func.isRequired,
  changeFormValue: func.isRequired,
  confirm: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(InputModal);
