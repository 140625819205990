import styled from 'styled-components';


const StyledItem = styled.li`
  padding: 0.25rem 1rem;

  &:focus {
    outline: none;
  }

  ${(p) => (p.isSelected) && `
    background: ${p.isFocused ? p.theme.primary.t100 : p.theme.grey.t300};
  `}
`;

export default StyledItem;
