import {
  GENERIC_FORMS__GET_GENERIC_FORMS_DETAIL,
  GENERIC_FORMS__REFRESH,
  GENERIC_FORMS__DELETE_GENERIC_FORMS_DETAIL,
  GENERIC_FORMS__TOGGLE_DELETE_GENERIC_FORMS_DELETE_MODAL,
  GENERIC_FORMS__CREATE_GEN_FORM,
  GENERIC_FORMS__UPDATE_GEN_FORM,
} from '../actions/genericFormsDetail';
import { EDITABLE_GEN_FORM__GET_GROUP_INPUTS, } from '../actions/editableGenForm';
import {
  PENDING, FULFILLED, REJECTED,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';


const initialState = {
  getData: {
    ...initRestData,
    isLoading: true,
  },
  createData: {
    ...initRestData,
  },
  updateData: {
    ...initRestData,
  },
  deleteData: {
    ...initRestData,
  },
  modalDelete: {
    id: null,
    isOpen: false,
  },
  groupInputs: {},
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;

  switch (type) {
    /**
     * Refresh Data
     */
    case `${GENERIC_FORMS__REFRESH}`: {
      return {
        ...initialState,
      };
    }


    /**
     * GET GenericForms Detail
     */
    case `${GENERIC_FORMS__GET_GENERIC_FORMS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        getData: {
          ...initialState.getData,
          ...(handlePending()),
        },
      };
    }

    case `${GENERIC_FORMS__GET_GENERIC_FORMS_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        getData: {
          ...state.getData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${GENERIC_FORMS__GET_GENERIC_FORMS_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        getData: {
          ...state.getData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * Create Gen Form
     */
    case `${GENERIC_FORMS__CREATE_GEN_FORM}__${PENDING}`: {
      return {
        ...state,
        createData: {
          ...state.createData,
          ...(handlePending()),
        },
      };
    }

    case `${GENERIC_FORMS__CREATE_GEN_FORM}__${FULFILLED}`: {
      return {
        ...state,
        createData: {
          ...state.createData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${GENERIC_FORMS__CREATE_GEN_FORM}__${REJECTED}`: {
      return {
        ...state,
        createData: {
          ...state.createData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * Update Gen Form
     */
    case `${GENERIC_FORMS__UPDATE_GEN_FORM}__${PENDING}`: {
      return {
        ...state,
        updateData: {
          ...state.updateData,
          ...(handlePending()),
        },
      };
    }

    case `${GENERIC_FORMS__UPDATE_GEN_FORM}__${FULFILLED}`: {
      return {
        ...state,
        updateData: {
          ...state.updateData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${GENERIC_FORMS__UPDATE_GEN_FORM}__${REJECTED}`: {
      return {
        ...state,
        updateData: {
          ...state.updateData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * DELETE Generic Form Detail
     */
    case `${GENERIC_FORMS__DELETE_GENERIC_FORMS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        deleteData: {
          ...state.deleteData,
          ...(handlePending()),
        },
      };
    }

    case `${GENERIC_FORMS__DELETE_GENERIC_FORMS_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        deleteData: {
          ...state.deleteData,
          ...(handleFulfilled(payload)),
        },
        modalDelete: {
          ...initialState.modalDelete,
        },
      };
    }

    case `${GENERIC_FORMS__DELETE_GENERIC_FORMS_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        deleteData: {
          ...state.deleteData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
     * TOGGLE GenericForms delete modal
     */
    case `${GENERIC_FORMS__TOGGLE_DELETE_GENERIC_FORMS_DELETE_MODAL}`: {
      return {
        ...state,
        modalDelete: {
          ...initialState.modalDelete,
          isOpen: payload.isOpen,
          id: payload.id,
        },
        deleteData: initialState.deleteData,
      };
    }


    /**
     * Add Group Input Data
     */
    case `${EDITABLE_GEN_FORM__GET_GROUP_INPUTS}__${FULFILLED}`: {
      const { groupValue, data, } = payload;

      if (groupValue === null) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        groupInputs: {
          ...state.groupInputs,
          [groupValue.id]: data,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
