import uuidv4 from 'uuid/v4';

import { FORM_ROOT, GEN_FORM_RECURSIVE_TYPES, FORM_TYPES, } from '../../globals';
import {
  removeFromObjImmutable,
  isDisabledSave,
  getPropsFromObj,
  addToRemoveFrom,
  swapWithUpperChild,
  addPartLogic,
  changePartTypeLogic,
  makeNewStructure,
} from './utils';
import {
  handlePending, handleFulfilled, handleRejected,
} from '../restApi/utils';
import { parseGroupInputsFromApi, parsefromApi, } from './parsefromApi';
import { inputFormRoot, } from './inputStructure';
import { addNodeToArray, addArrayToArray, } from '../utils/array';


const handleError = (state) => ({
  ...state,
  msg: {
    code: 'somethingHappened',
    params: {},
  },
});


//
// Move Part Up
//
export const movePartUp = (state, payload) => {
  const {
    id, position, parent, parentPosition,
  } = payload;
  const { structure, } = state;

  // 1 - First in parent
  if (position < 1) {
    // 1.1 - Parent is Root => return
    if (structure[id].parent === FORM_ROOT) {
      return {
        ...state,
      };
    }

    // 1.2 - can add before parent
    const grandParentId = structure[parent].parent;
    return addToRemoveFrom({
      state,
      removeFromId: parent,
      addToId: grandParentId,
      nodeId: id,
      addToPos: parentPosition,
    });
  }

  // 2 - has upper brother
  const upperBrotherId = structure[parent].childs[position - 1];

  // 2.1 - upper brother is iterable => go to his last child
  if (GEN_FORM_RECURSIVE_TYPES.includes(structure[upperBrotherId].type)) {
    return addToRemoveFrom({
      state,
      removeFromId: parent,
      addToId: upperBrotherId,
      nodeId: id,
      addToPos: structure[upperBrotherId].childs.length,
    });
  }

  // 2.2 - switch with upper brother
  return swapWithUpperChild({
    state,
    id: parent,
    index: position,
  });
};


//
// Move Part Down
//
export const movePartDown = (state, payload) => {
  const {
    id, position, parent, parentPosition,
  } = payload;
  const { structure, } = state;
  const parentLength = structure[parent].childs.length;

  // 1 - not last child
  if (position < parentLength - 1) {
    const lowerBrotherId = structure[parent].childs[position + 1];

    // 1.1 - go to lower brother childs, forst position
    if (GEN_FORM_RECURSIVE_TYPES.includes(structure[lowerBrotherId].type)) {
      return addToRemoveFrom({
        state,
        removeFromId: parent,
        addToId: lowerBrotherId,
        nodeId: id,
        addToPos: 0,
      });
    }

    // 1.2 - switch with lower brother
    return swapWithUpperChild({
      state,
      id: parent,
      index: position + 1,
    });
  }

  // 2 - i ma last childs

  // 2.1 - last in root -> return
  if (structure[id].parent === FORM_ROOT) {
    return {
      ...state,
    };
  }

  // 2.2 - can go down
  const grandParentId = structure[parent].parent;
  return addToRemoveFrom({
    state,
    removeFromId: parent,
    addToId: grandParentId,
    nodeId: id,
    addToPos: parentPosition + 1,
  });
};


//
// Craete Gen Form Initial State
//
export const initialState = {
  id: null,
  lang: null,
  commonValidation: {
    isValid: true,
    invalidParts: 0,
  },
  finalValidation: {
    isValid: true,
    msg: {
      code: '',
      params: '',
    },
  },
  msg: {
    code: '',
    params: {},
  },
  // tree
  structure: {
    [FORM_ROOT]: makeNewStructure({
      id: FORM_ROOT,
      type: FORM_ROOT,
      parent: null,
    }),
  },
  // data
  parts: {
    [FORM_ROOT]: inputFormRoot(),
  },
  // edit modal - copy data to edit
  modal: {
    isOpen: false,
    isNew: false,
    parent: null,
    position: null,
    disabledSave: false,
    inGroup: false,
    part: null,
    struct: null,
    subStructure: {},
    subParts: {},
  },
};


//
// Add Part
//
export const caseAddPart = (state, payload) => {
  try {
    const newId = uuidv4();
    const { position, parent, } = payload;

    return {
      ...state,
      modal: {
        ...initialState.modal,
        isOpen: true,
        isNew: true,
        parent,
        position,
        disabledSave: true,
        part: addPartLogic(newId),
        struct: makeNewStructure({ id: newId, parent, }),
      },
    };
  } catch (err) {
    return handleError(state, err);
  }
};


//
// Edit Part
//
export const caseEditPart = (state, payload) => {
  try {
    const { parts, structure, } = state;
    const { partId, position, } = payload;

    const parentId = structure[partId].parent;

    return {
      ...state,
      modal: {
        ...initialState.modal,
        isOpen: true,
        parent: parentId,
        position,
        inGroup: state.structure[parentId].type === FORM_TYPES.GROUP,
        part: parts[partId],
        struct: structure[partId],
        subStructure: getPropsFromObj(structure, structure[partId].childs),
        subParts: getPropsFromObj(parts, structure[partId].childs),
      },
    };
  } catch (err) {
    return handleError(state, err);
  }
};


//
// Close Part
//
export const caseClosePart = (state) => {
  try {
    return {
      ...state,
      modal: {
        ...initialState.modal,
      },
    };
  } catch (err) {
    return handleError(state, err);
  }
};


//
// Save Part
//
export const caseSavePart = (state) => {
  try {
    const {
      isNew,
      parent,
      position,
      part,
      struct,
      subStructure,
      subParts,
    } = state.modal;

    const oldChilds = [];

    // validation
    let newInvalidParts = state.commonValidation.invalidParts;
    const oldIsValid = isNew ? true : state.parts[part.id].isValid;
    if (oldIsValid !== part.isValid) {
      if (part.isValid) newInvalidParts--;
      else newInvalidParts++;
    }

    // copy old state
    const newState = {
      ...state,
      // setInvalidParts
      commonValidation: {
        isValid: newInvalidParts < 1,
        invalidParts: newInvalidParts,
      },
      finalValidation: initialState.finalValidation,
      msg: initialState.msg,
      // reset modal
      modal: {
        ...initialState.modal,
      },
      structure: {
        ...state.structure,
        // if new add id to parent
        [parent]: {
          ...state.structure[parent],
          childs: isNew
            ? addNodeToArray(state.structure[parent].childs, position, part.id)
            : state.structure[parent].childs,
        },
      },
    };

    // 1 - if not new - prepare old state
    if (!isNew) {
      const oldStruct = newState.structure[part.id];
      if (GEN_FORM_RECURSIVE_TYPES.includes(oldStruct.type)) {
        // 1.1 - old type is recursive
        //  - save possible childs for future replace
        oldChilds.push(...oldStruct.childs);
      } else {
        // 1.2 - old type isn't recursive
        //  - remove his possible childs -> they are saved in edit modal
        const idsToRemove = newState.structure[part.id].childs;

        for (let i = 0; i < idsToRemove.length; i++) {
          const item = idsToRemove[i];
          delete newState.parts[item];
          delete newState.structure[item];
        }
      }
    }


    // 2 - merge old state and edited part
    if (GEN_FORM_RECURSIVE_TYPES.includes(part.type)) {
      // 2.1 - new part is recursive
      return {
        ...newState,
        structure: {
          ...newState.structure,
          // copy struct from modal
          [part.id]: {
            ...struct,
            childs: oldChilds,
          },
        },
        parts: {
          ...newState.parts,
          // copy part from modal
          [part.id]: part,
        },
      };
    }

    // 2.2 - new part isn't iterable
    //  - add possible old childs behind him
    //  - add new possible childs
    return {
      ...newState,
      structure: {
        ...newState.structure,
        // add struct from modal
        [struct.id]: struct,
        // add substructures from modal
        ...subStructure,
        // addp possible oldChilds
        // if new add part id
        [parent]: {
          ...newState.structure[parent],
          childs: oldChilds.length > 0
            ? addArrayToArray(newState.structure[parent].childs, position + 1, oldChilds)
            : newState.structure[parent].childs,
        },
      },
      parts: {
        ...newState.parts,
        // copy part from modal
        [part.id]: part,
        // copy subparts from modal
        ...subParts,
      },
    };
  } catch (err) {
    return handleError(state, err);
  }
};


//
// Change Part Type
//
export const caseChangePartType = (state, payload) => {
  try {
    const { newType, } = payload;
    const { part: { id, }, parent, } = state.modal;

    const newPart = changePartTypeLogic(id, newType);

    return {
      ...state,
      modal: {
        ...state.modal,
        disabledSave: isDisabledSave(newPart),
        part: newPart,
        struct: makeNewStructure({ id, parent, type: newType, }),
        subStructure: {},
        subParts: {},
      },
    };
  } catch (err) {
    return handleError(state, err);
  }
};


//
// Change Value
//
export const caseChangeValue = (state, payload) => {
  try {
    const { newPart, } = payload;

    return {
      ...state,
      modal: {
        ...state.modal,
        disabledSave: isDisabledSave(newPart),
        part: newPart,
      },
    };
  } catch (err) {
    return handleError(state, err);
  }
};


//
// Change Root Part
//
export const caseChangeRootPart = (state, payload) => {
  try {
    const { newPart, } = payload;

    // validation
    let newInvalidParts = state.commonValidation.invalidParts;
    if (state.parts[FORM_ROOT].isValid !== newPart.isValid) {
      if (newPart.isValid) newInvalidParts--;
      else newInvalidParts++;
    }

    return {
      ...state,
      commonValidation: {
        isValid: newInvalidParts < 1,
        invalidParts: newInvalidParts,
      },
      finalValidation: initialState.finalValidation,
      msg: initialState.msg,
      parts: {
        ...state.parts,
        [FORM_ROOT]: newPart,
      },
    };
  } catch (err) {
    return handleError(state, err);
  }
};


//
// Remove Part
//
export const caseRemovePart = (state, payload) => {
  try {
    const { id, position, } = payload;
    const parentId = state.structure[id].parent;
    const partsToRemove = [];
    let newInvalidParts = state.commonValidation.invalidParts;
    let newParentChilds = state.structure[parentId].childs;
    const newStructure = { ...state.structure, };

    // 1 - set childs
    //   - make new parent childs
    partsToRemove.push(id);
    newParentChilds.splice(position, 1);

    if (GEN_FORM_RECURSIVE_TYPES.includes(state.structure[id].type)) {
      // 1.1 - iterable part
      //   - move his childs to parent
      //   - change children's parent
      const tmpChilds = state.structure[id].childs;
      if (tmpChilds.length > 0) {
        newParentChilds = addArrayToArray(newParentChilds, position, tmpChilds);

        for (let j = 0; j < tmpChilds.length; j++) {
          const tmpChildId = tmpChilds[j];
          newStructure[tmpChildId].parent = parentId;
        }
      }
    } else {
      // 1.2 - not iterable
      //   - remove this childs
      partsToRemove.push(...state.structure[id].childs);
    }

    // 2 - validation
    //   - check if are invalid
    for (let i = 0; i < partsToRemove.length; i++) {
      const tmpId = partsToRemove[i];
      if (!state.parts[tmpId].isValid) {
        newInvalidParts--;
      }
    }

    // 3 - merge it all
    return {
      ...state,
      // validation
      commonValidation: {
        isValid: newInvalidParts < 1,
        invalidParts: newInvalidParts,
      },
      finalValidation: initialState.finalValidation,
      msg: initialState.msg,
      // structure
      // - remove childs
      // - set parents childs
      structure: {
        ...(removeFromObjImmutable(partsToRemove, newStructure)),
        [parentId]: {
          ...newStructure[parentId],
          childs: newParentChilds,
        },
      },
      // parts
      // - remove parts
      parts: removeFromObjImmutable(partsToRemove, state.parts),
    };
  } catch (err) {
    return handleError(state, err);
  }
};


//
// Move Part
//
export const caseMovePart = (state, payload) => {
  try {
    if (payload.moveUp) {
      return movePartUp(state, payload);
    }
    return movePartDown(state, payload);
  } catch (err) {
    return handleError(state, err);
  }
};


//
// Validate Whole
//
export const caseValidateWhole = (state, payload) => {
  try {
    return payload.newState;
  } catch (err) {
    return handleError(state, err);
  }
};


//
// Get Gen Form Fulfilled
//
export const caseGetGenFormFulfilled = (state, payload) => {
  try {
    return {
      ...initialState,
      ...parsefromApi(payload.data),
    };
  } catch (err) {
    return handleError(state, err);
  }
};


//
// Change Group Pending
//
export const caseChangeGroupPending = (state, payload) => {
  try {
    const { groupValue, } = payload;
    const { modal, } = state;

    if (modal.part === null || modal.part.type !== FORM_TYPES.GROUP) return state;

    return {
      ...state,
      modal: {
        ...state.modal,
        disabledSave: true,
        part: {
          ...state.modal.part,
          values: {
            ...state.modal.part.values,
            group: groupValue,
          },
          inputsData: {
            ...state.modal.part.inputsData,
            ...(handlePending()),
          },
        },
      },
    };
  } catch (err) {
    return handleError(state, err);
  }
};


//
// Change Group Fulfilled
//
export const caseChangeGroupFulfilled = (state, payload) => {
  try {
    const {
      groupValue,
      data: {
        inputs,
      },
    } = payload;
    const { modal, } = state;

    if (modal.part === null || modal.part.type !== FORM_TYPES.GROUP) return state;

    const parsed = parseGroupInputsFromApi(inputs, state.modal.part.id);

    return {
      ...state,
      modal: {
        ...state.modal,
        disabledSave: groupValue === null,
        part: {
          ...state.modal.part,
          values: {
            ...state.modal.part.values,
            group: groupValue,
          },
          inputsData: {
            ...state.modal.part.inputsData,
            ...(handleFulfilled(payload)),
          },
        },
        struct: {
          ...state.modal.struct,
          data: groupValue,
          childs: parsed.childs,
        },
        subStructure: parsed.newStructure,
        subParts: parsed.newParts,
      },
    };
  } catch (err) {
    return handleError(state, err);
  }
};


//
// Change Group Rejected
//
export const caseChangeGroupRejected = (state, payload) => {
  try {
    const { modal, } = state;

    if (modal.part === null || modal.part.type !== FORM_TYPES.GROUP) return state;

    return {
      ...state,
      modal: {
        ...state.modal,
        disabledSave: true,
        part: {
          ...state.modal.part,
          inputsData: {
            ...state.modal.part.inputsData,
            ...(handleRejected(payload)),
          },
        },
      },
    };
  } catch (err) {
    return handleError(state, err);
  }
};
