import { INPUT_TYPE, } from 'sofair-form-validation';

import {
  CAMERAS_CHANGE_VALUE_DETAIL,
  CAMERAS_REFRESH,
  CAMERAS_VALIDATE_FORM_DETAIL,
  CAMERAS__CREATE_DETAIL,
  CAMERAS__GET_DETAIL,
  CAMERAS__UPDATE_DETAIL,
} from '../actions/camerasDetail';
import {
  PENDING, FULFILLED, REJECTED,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';
import {
  mergeFormAndValidation, initInput, changeFormValue,
} from '../../../logic/forms/validation';


const initialState = {
  detailCamerasData: {
    ...initRestData,
    isLoading: true,
  },
  createCamerasData: {
    ...initRestData,
  },
  updateCamerasData: {
    ...initRestData,
  },
  deleteCamerasData: {
    ...initRestData,
  },
  camerasDeleteModal: {
    id: null,
    isOpen: false,
  },
  detail: {
    id: null,
    isValid: true,
    invalidInputs: 0,
    form: {
      name: {
        ...initInput,
        name: 'name',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: true,
          min: 2,
          max: 255,
        },
      },
      ip: {
        ...initInput,
        name: 'ip',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: true,
          min: 7,
          max: 255,
        },
      },
      port: {
        ...initInput,
        name: 'port',
        type: INPUT_TYPE.NUMBER,
        validation: {
          ...initInput.validation,
          required: true,
          min: 1,
          max: 100000,
        },
      },
      username: {
        ...initInput,
        name: 'username',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: true,
          min: 3,
          max: 255,
        },
      },
      password: {
        ...initInput,
        name: 'password',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: true,
          min: 3,
          max: 255,
        },
      },
    },
    values: {
      name: '',
      ip: '',
      port: '0',
      username: '',
      password: '',
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;

  switch (type) {
    /**
     * Refresh Data
     */
    case `${CAMERAS_REFRESH}`: {
      return {
        ...state,
        createCamerasData: {
          ...initialState.createCamerasData,
        },
        updateCamerasData: {
          ...initialState.updateCamerasData,
        },
        detail: {
          ...initialState.detail,
        },
      };
    }


    /**
     * Change Form
     */
    case `${CAMERAS_CHANGE_VALUE_DETAIL}`: {
      const newFormData = changeFormValue(
        state.detail.form, state.detail.values, state.detail.invalidInputs, payload
      );

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: newFormData.invalidInputs < 1,
          invalidInputs: newFormData.invalidInputs,
          form: newFormData.form,
          values: newFormData.values,
        },
      };
    }


    /**
     * Validate form
     */
    case `${CAMERAS_VALIDATE_FORM_DETAIL}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload);

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }


    /**
     * GET Cameras Detail
     */
    case `${CAMERAS__GET_DETAIL}__${PENDING}`: {
      return {
        ...state,
        detailCamerasData: {
          ...initialState.detailCamerasData,
          ...(handlePending()),
        },
        updateCamerasData: {
          ...initialState.updateCamerasData,
        },
        detail: {
          ...initialState.detail,
        },
      };
    }

    case `${CAMERAS__GET_DETAIL}__${FULFILLED}`: {
      try {
        const newValues = {
          ...state.detail.values,
          name: payload.data.name,
          ip: payload.data.ip,
          port: payload.data.port.toString(),
          username: payload.data.username,
          password: payload.data.password,
        };

        return {
          ...state,
          detailCamerasData: {
            ...state.detailCamerasData,
            ...(handleFulfilled(payload)),
          },
          detail: {
            ...state.detail,
            id: payload.data.id,
            values: newValues,
          },
        };
      } catch (err) {
        return {
          ...state,
          detailCamerasData: {
            ...state.detailCamerasData,
            ...(handleRejected({
              statusCode: -1,
              messageInternal: err.message,
              message: {
                code: 'Nepovedlo se stáhnout detail',
                params: {},
              },
            })),
          },
        };
      }
    }

    case `${CAMERAS__GET_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        detailCamerasData: {
          ...state.detailCamerasData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * CREATE Cameras Detail
     */
    case `${CAMERAS__CREATE_DETAIL}__${PENDING}`: {
      return {
        ...state,
        createCamerasData: {
          ...state.createCamerasData,
          ...(handlePending()),
        },
      };
    }

    case `${CAMERAS__CREATE_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        createCamerasData: {
          ...state.createCamerasData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${CAMERAS__CREATE_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        createCamerasData: {
          ...state.createCamerasData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * UPDATE Cameras Detail
     */
    case `${CAMERAS__UPDATE_DETAIL}__${PENDING}`: {
      return {
        ...state,
        updateCamerasData: {
          ...state.updateCamerasData,
          ...(handlePending()),
        },
      };
    }

    case `${CAMERAS__UPDATE_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        updateCamerasData: {
          ...state.updateCamerasData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${CAMERAS__UPDATE_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        updateCamerasData: {
          ...state.updateCamerasData,
          ...(handleRejected(payload)),
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
