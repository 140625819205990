import React from 'react';
import { NavLink, } from 'react-router-dom';
import {
  string, arrayOf, node, bool, oneOfType,
} from 'prop-types';


const TabLink = ({
  exact = true, to, children,
}) => (
  <NavLink
    exact={exact}
    className="tabslink--item"
    to={to}
    activeClassName="tabslink--item-active"
  >
    {children}
  </NavLink>
);


TabLink.propTypes = {
  to: string.isRequired,
  exact: bool,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};

TabLink.defaultProps = {
  exact: true,
};


export default TabLink;
