import React, { Fragment, } from 'react';
import {
  shape, func, string, bool,
} from 'prop-types';

import { withLang, } from '../../../../logic/languages/withLang';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Input from '../../../../atoms/Input/Input';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';

const ControllersDetail = ({
  // data
  form,
  values,
  // methods
  handleOnChangeValue,
  langError,
}) => (
  <Fragment>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Název
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="text"
          value={values.name}
          status={form.name.status}
          onChange={(e) => handleOnChangeValue(form.name.name, e.target.value)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.name.status}
          msg={langError(form.name.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Šířka zobrazení
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="number"
          value={values.width}
          status={form.width.status}
          onChange={(e) => handleOnChangeValue(form.width.name, e.target.value)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.width.status}
          msg={langError(form.width.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Výška zobrazení
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="number"
          value={values.height}
          status={form.height.status}
          onChange={(e) => handleOnChangeValue(form.height.name, e.target.value)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.height.status}
          msg={langError(form.height.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Kód zařízení
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} formValue sidePadding>
        {values.deviceCode}
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Synchronizováno
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} formValue sidePadding>
        <span className={values.synchronized ? 'app--color-success' : 'app--color-error'}>
          {values.synchronized ? 'Ano' : 'ne'}
        </span>
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Dostupnost
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} formValue sidePadding>
        <span className={values.online ? 'app--color-success' : 'app--color-error'}>
          {values.online ? 'Online' : 'Offline'}
        </span>
      </Col>
    </Row>

  </Fragment>
);


ControllersDetail.propTypes = {
  form: shape({
    name: shape({
      name: string.isRequired,
    }),
    width: shape({
      name: string.isRequired,
    }),
    height: shape({
      name: string.isRequired,
    }),
  }).isRequired,
  values: shape({
    name: string,
    width: string,
    height: string,
    deviceCode: string,
    online: bool,
    synchronized: bool,
  }).isRequired,
  handleOnChangeValue: func.isRequired,
  langError: func.isRequired,
};


export default withLang(ControllersDetail);
