import React from 'react';
import { func, } from 'prop-types';

import StyledSidebar from './styles/StyledSidebar';
import SidebarLink from './SidebarLink';
import SideBarSubTitle from './SideBarSubTitle';
import SidebarHeader from './SidebarHeader';
import SidebarDivider from './SidebarDivider';
import TransportationTypeLogo from '../../styles/icons/TransportationTypeLogo';
import PlatformLogo from '../../styles/icons/PlatformLogo';
import ActionsLogo from '../../styles/icons/ActionsLogo';
import KioskLogo from '../../styles/icons/KioskLogo';
import DisplayLogo from '../../styles/icons/DisplayLogo';
import StandLogo from '../../styles/icons/StandLogo';
import DashboardLogo from '../../styles/icons/DashboardLogo';
import LanguageLogo from '../../styles/icons/Language';
import FormLogo from '../../styles/icons/Form';
import Storekeeper from '../../styles/icons/Storekeeper';
import CameraLogo from '../../styles/icons/Camera';
import QueueSettings from '../../styles/icons/QueueSettings';

const Sidebar = ({ onToggleSidebar, }) => (
  <StyledSidebar className="noselect">

    <SidebarHeader
      onToggleSidebar={onToggleSidebar}
    />

    <SidebarDivider />

    <br />

    <ul>
      <li>
        <SidebarLink to="/" exact>
          <span className="sidebar--item-icon"><DashboardLogo /></span>
          <span>Dashboard</span>
        </SidebarLink>
      </li>

      <SideBarSubTitle
        title="Obsluha"
      />

      <li>
        <SidebarLink to="/transportation-types">
          <span className="sidebar--item-icon"><TransportationTypeLogo /></span>
          <span>Typy přeprav</span>
        </SidebarLink>
      </li>

      <li>
        <SidebarLink to="/platforms">
          <span className="sidebar--item-icon"><PlatformLogo /></span>
          <span>Rampy</span>
        </SidebarLink>
      </li>
    </ul>

    <SideBarSubTitle
      title="Průchod frontou"
    />

    <ul>
      <li>
        <SidebarLink to="/queue-config/actions">
          <span className="sidebar--item-icon"><ActionsLogo /></span>
          <span>Akce</span>
        </SidebarLink>
      </li>

      <li>
        <SidebarLink to="/queue-config/stands">
          <span className="sidebar--item-icon"><StandLogo /></span>
          <span>Stanoviště</span>
        </SidebarLink>
      </li>

      <li>
        <SidebarLink to="/queue-config/configuration">
          <span className="sidebar--item-icon"><QueueSettings /></span>
          <span>Nastavení front</span>
        </SidebarLink>
      </li>
    </ul>

    <SideBarSubTitle
      title="Komponenty"
    />

    <ul>
      <li>
        <SidebarLink to="/devices/kiosks">
          <span className="sidebar--item-icon"><KioskLogo /></span>
          <span>Kiosky</span>
        </SidebarLink>
      </li>

      <li>
        <SidebarLink to="/devices/cameras">
          <span className="sidebar--item-icon"><CameraLogo /></span>
          <span>Kamery</span>
        </SidebarLink>
      </li>

      <li>
        <SidebarLink to="/devices/displays/controllers">
          <span className="sidebar--item-icon"><DisplayLogo /></span>
          <span>Displeje</span>
        </SidebarLink>
      </li>

      <li>
        <SidebarLink to="/devices/storekeeper">
          <span className="sidebar--item-icon"><Storekeeper /></span>
          <span>Aplikace skladník</span>
        </SidebarLink>
      </li>
    </ul>

    <SideBarSubTitle
      title="Nastavení"
    />

    <ul>
      <li>
        <SidebarLink to="/languages">
          <span className="sidebar--item-icon"><LanguageLogo /></span>
          <span>Jazyky</span>
        </SidebarLink>
      </li>

      <li>
        <SidebarLink to="/generic-forms">
          <span className="sidebar--item-icon"><FormLogo /></span>
          <span>Formuláře</span>
        </SidebarLink>
      </li>
    </ul>

  </StyledSidebar>
);


Sidebar.propTypes = {
  onToggleSidebar: func.isRequired,
};

export default Sidebar;
