import { INPUT_TYPE, } from 'sofair-form-validation';

import {
  TICKETS__CHANGE_VALUE_DETAIL,
  TICKETS__GET_DETAIL,
  TICKETS__CREATE_DETAIL,
  TICKETS_DETAIL__REFRESH,
  TICKETS__VALIDATE_FORM_DETAIL,
  TICKETS__UPDATE_DETAIL,
} from '../actions/ticketsDetail';
import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION, KIOSKS_CONFIGURATION_DETAIL_PARAMETERS,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';
import {
  mergeFormAndValidation, initInput, changeFormValue,
} from '../../../logic/forms/validation';

import { parseArrayFromApi, removeNullFromJsonArray, } from '../../../logic/utils/array';

const initialState = {
  detailTicketsData: {
    ...initRestData,
    isLoading: true,
  },
  createTicketsData: {
    ...initRestData,
  },
  updateTicketsData: {
    ...initRestData,
  },
  detail: {
    id: null,
    isValid: true,
    invalidInputs: 0,
    form: {
      name: {
        ...initInput,
        name: 'name',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: true,
          min: 2,
          max: 255,
        },
      },
      logo: {
        ...initInput,
        name: 'logo',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: false,
          min: 0,
          max: 10000000,
        },
      },
      header: {
        ...initInput,
        name: 'header',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: false,
          min: 0,
          max: 1000,
        },
      },
      footer: {
        ...initInput,
        name: 'footer',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: false,
          min: 0,
          max: 1000,
        },
      },
      columns: {
        ...initInput,
        name: 'columns',
        type: INPUT_TYPE.MULTISELECT,
        validation: {
          ...initInput.validation,
          required: false,
          min: 0,
          max: 100,
        },
      },
      barCode: {
        ...initInput,
        name: 'barCode',
        type: INPUT_TYPE.CHECKBOX,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
      numericalSeriesId: {
        ...initInput,
        name: 'numericalSeriesId',
        type: INPUT_TYPE.SELECT,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
      transportationTypes: {
        ...initInput,
        name: 'transportationTypes',
        type: INPUT_TYPE.MULTISELECT,
        validation: {
          ...initInput.validation,
          required: false,
          min: 0,
          max: 1000,
        },
      },
    },
    values: {
      name: '',
      logo: '',
      header: '',
      footer: '',
      columns: [],
      barCode: false,
      numericalSeriesId: null,
      transportationTypes: [],
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;

  switch (type) {
    /**
     * Refresh Data
     */
    case `${TICKETS_DETAIL__REFRESH}`: {
      return {
        ...state,
        detail: {
          ...initialState.detail,
        },
      };
    }


    /**
     * Change Form
     */
    case `${TICKETS__CHANGE_VALUE_DETAIL}`: {
      const newFormData = changeFormValue(
        state.detail.form, state.detail.values, state.detail.invalidInputs, payload
      );

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: newFormData.invalidInputs < 1,
          invalidInputs: newFormData.invalidInputs,
          form: newFormData.form,
          values: newFormData.values,
        },
      };
    }


    /**
     * Validate form
     */
    case `${TICKETS__VALIDATE_FORM_DETAIL}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload);

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }


    /**
     * GET Detail
     */
    case `${TICKETS__GET_DETAIL}__${PENDING}`: {
      return {
        ...state,
        detailTicketsData: {
          ...state.detailTicketsData,
          ...(handlePending()),
        },
        detail: {
          ...initialState.detail,
        },
      };
    }

    case `${TICKETS__GET_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        detailTicketsData: {
          ...state.detailTicketsData,
          ...(handleFulfilled(payload)),
        },
        detail: {
          ...state.detail,
          id: payload.data.id,
          values: {
            ...state.detail.values,
            name: payload.data.name,
            logo: payload.data.logo,
            header: payload.data.header,
            footer: payload.data.footer,
            columns: parseArrayFromApi(payload.data.columns).map(
              (item) => (KIOSKS_CONFIGURATION_DETAIL_PARAMETERS[item])
            ),
            barCode: payload.data.barCode === true,
            numericalSeriesId: payload.data.numericalSeries.id === null ? null : payload.data.numericalSeries,
            transportationTypes: payload.data.transportationTypes === null ? [] : payload.data.transportationTypes,
          },
        },
      };
    }

    case `${TICKETS__GET_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        detailTicketsData: {
          ...state.detailTicketsData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * CREATE Detail
     */
    case `${TICKETS__CREATE_DETAIL}__${PENDING}`: {
      return {
        ...state,
        createTicketsData: {
          ...state.createTicketsData,
          ...(handlePending()),
        },
      };
    }

    case `${TICKETS__CREATE_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        createTicketsData: {
          ...state.createTicketsData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${TICKETS__CREATE_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data.data);

      return {
        ...state,
        createTicketsData: {
          ...state.createTicketsData,
          ...(handleFulfilled(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${TICKETS__CREATE_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        createTicketsData: {
          ...state.createTicketsData,
          ...(handleRejected(payload)),
        },
      };
    }

    /*
    * UPDATE DETAIL
    */
    case `${TICKETS__UPDATE_DETAIL}__${PENDING}`: {
      return {
        ...state,
        updateTicketsData: {
          ...state.updateTicketsData,
          ...(handlePending()),
        },
      };
    }

    case `${TICKETS__UPDATE_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        updateTicketsData: {
          ...state.updateTicketsData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${TICKETS__UPDATE_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data.data);

      return {
        ...state,
        updateTicketsData: {
          ...state.updateTicketsData,
          ...(handleFulfilled(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${TICKETS__UPDATE_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        updateTicketsData: {
          ...state.updateTicketsData,
          ...(handleRejected(payload)),
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
