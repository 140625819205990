import styled from 'styled-components';

const StyledColorPickerWrapper = styled.div`
  position: absolute;
  z-index: 1000;

  ${(p) => p.position === 'top' && `
    top: -300px;
  `}
`;

export default StyledColorPickerWrapper;
