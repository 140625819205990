
import styled from 'styled-components';

const ActionButton = styled.button`
    font-size: 1rem;
    padding: 5px;
    background: none;
    border: none;
    color: ${(p) => p.theme.grey.t800};

 &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    color: ${(p) => p.theme.primary.t800};
  }

  &:disabled {
    cursor: default;
  }

`;

export default ActionButton;
