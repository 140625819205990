import React from 'react';
import styled from 'styled-components';
import {
  bool,
} from 'prop-types';

import Loader from '../../atoms/Loader/Loader';


const StyledSegmentLaoding = styled.div`
  height: 7rem;
  width: 100%;
  display: flex;
  background: ${(p) => p.theme.grey.t300};
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.white};
  font-size: 2rem;

  /* FormGroup */
  ${(p) => p.formGroup && `
    margin-bottom: ${p.theme.formGroup.marginBottom}
  `}
`;


const SegmentLaoding = ({ formGroup, }) => (
  <StyledSegmentLaoding formGroup={formGroup}>
    <Loader />
  </StyledSegmentLaoding>
);


SegmentLaoding.propTypes = {
  formGroup: bool,
};

SegmentLaoding.defaultProps = {
  formGroup: false,
};


export default SegmentLaoding;
