import {
  PLATFORMS__GET_PLATFORMS,
  PLATFORMS__FILTER_CHANGED,
} from '../actions/platforms';
import {
  PLATFORMS__RESTORE_PLATFORMS_DETAIL,
  PLATFORMS__DELETE_PLATFORMS_DETAIL,
  PLATFORMS__FORCE_DELETE_PLATFORMS_DETAIL,
} from '../actions/platformsDetail';
import {
  PENDING, FULFILLED, REJECTED, REJECTED_COLLISON,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';


const parseTableData = (data) => (
  data.map((item) => ({
    ...item,
    name: item.platformTranslation.name,
    ...initRestData,
  }))
);


const initialState = {
  platformsData: {
    ...initRestData,
    isLoading: true,
  },
  table: {
    data: [],
    count: 0,
    filter: {
      offset: 0,
      limit: 10,
      sortBy: 'id',
      order: 'ASC',
      params: {
        id: '',
        name: '',
        no: '',
        deleted: 'no',
      },
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;
  switch (type) {
    /**
     * GET Platforms
     */
    case `${PLATFORMS__GET_PLATFORMS}__${PENDING}`: {
      return {
        ...state,
        platformsData: {
          ...state.platformsData,
          ...(handlePending()),
        },
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    case `${PLATFORMS__GET_PLATFORMS}__${FULFILLED}`: {
      return {
        ...state,
        platformsData: {
          ...state.platformsData,
          ...(handleFulfilled(payload)),
        },
        table: {
          data: parseTableData(payload.data.platforms),
          count: payload.data.count,
          filter: payload.data.filter,
        },
      };
    }

    case `${PLATFORMS__GET_PLATFORMS}__${REJECTED}`: {
      return {
        ...state,
        platformsData: {
          ...state.platformsData,
          ...(handleRejected(payload)),
        },
        table: {
          ...state.table,
          data: [],
        },
      };
    }


    /**
     * DELETE, RESTORE Platforms Detail
     */
    case `${PLATFORMS__DELETE_PLATFORMS_DETAIL}__${PENDING}`:
    case `${PLATFORMS__RESTORE_PLATFORMS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.map((item) => {
            if (item.id === payload.id) {
              return {
                ...item,
                ...(handlePending()),
              };
            }
            return item;
          }),
        },
      };
    }

    case `${PLATFORMS__DELETE_PLATFORMS_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.map((item) => {
            if (item.id === payload.id) {
              const now = new Date();

              return {
                ...item,
                deletedAt: now.toUTCString(),
                ...initRestData,
              };
            }
            return item;
          }),
        },
      };
    }

    case `${PLATFORMS__RESTORE_PLATFORMS_DETAIL}__${FULFILLED}`: {
      const { id, } = payload;

      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.map((item) => {
            if (item.id === id) {
              return {
                ...payload.data,
                ...initRestData,
              };
            }
            return item;
          }),
        },
      };
    }

    case `${PLATFORMS__DELETE_PLATFORMS_DETAIL}__${REJECTED}`:
    case `${PLATFORMS__RESTORE_PLATFORMS_DETAIL}__${REJECTED}`: {
      const { id, } = payload;

      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                ...(handleRejected(payload)),
              };
            }
            return item;
          }),
        },
      };
    }

    case `${PLATFORMS__DELETE_PLATFORMS_DETAIL}__${REJECTED_COLLISON}`: {
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.map((item) => {
            if (item.id === payload.id) {
              return {
                ...item,
                ...(handleRejected(payload)),
              };
            }
            return item;
          }),
        },
      };
    }


    /**
     * FORCE DELETE Platfgorm Detail
     */
    case `${PLATFORMS__FORCE_DELETE_PLATFORMS_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.map((item) => {
            if (item.id === payload.id) {
              const now = new Date();
              return {
                ...item,
                deletedAt: now.toUTCString(),
                ...initRestData,
              };
            }
            return item;
          }),
        },
      };
    }


    /**
     * Change Filter
     */
    case `${PLATFORMS__FILTER_CHANGED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
