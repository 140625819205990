import { INPUT_TYPE, } from 'sofair-form-validation';
import {
  DEVICES__GET_DISPLAYS_CONFIGURATION_DETAIL,
  DEVICES__CREATE_DISPLAYS_CONFIGURATION_DETAIL,
  DEVICES__UPDATE_DISPLAYS_CONFIGURATION_DETAIL,
  DEVICES__DISPLAYS_CONFIGURATION_VALIDATE_FORM_DETAIL,
  DEVICES__DISPLAYS_CONFIGURATION_CHANGE_VALUE_DETAIL,
  DEVICES__DISPLAYS_CONFIGURATION_REFRESH,
  DEVICES__DISPLAYS_CONFIGURATION_ADD_COLUMN_DETAIL,
  DEVICES__DISPLAYS_CONFIGURATION_CHANGE_VALUE_IN_COLUMN_DETAIL,
  DEVICES__DISPLAYS_CONFIGURATION_REMOVE_COLUMN_DETAIL,
} from '../actions/displaysConfigurationDetail';
import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';
import {
  mergeFormAndValidation, changeFormValue, initInput,
} from '../../../logic/forms/validation';

import {
  removeNullFromJsonArray,
} from '../../../logic/utils/array';

const initialState = {
  detailDisplaysConfigurationData: {
    ...initRestData,
    isLoading: true,
  },
  createDisplaysConfigurationData: {
    ...initRestData,
  },
  updateDisplaysConfigurationData: {
    ...initRestData,
  },
  detail: {
    id: null,
    isValid: true,
    invalidInputs: 0,
    form: {
      name: {
        ...initInput,
        name: 'name',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: true,
          min: 2,
          max: 255,
        },
      },
      type: {
        ...initInput,
        name: 'type',
        type: INPUT_TYPE.SELECT,
        validation: {
          ...initInput.validation,
          required: true,
        },
      },
      fontSize: {
        ...initInput,
        name: 'fontSize',
        type: INPUT_TYPE.NUMBER,
        validation: {
          ...initInput.validation,
          required: true,
          min: 8,
          max: 1000,
        },
      },
      noRows: {
        ...initInput,
        name: 'noRows',
        type: INPUT_TYPE.NUMBER,
        validation: {
          ...initInput.validation,
          required: true,
          min: 1,
          max: 100,
        },
      },
      lineDivider: {
        ...initInput,
        name: 'lineDivider',
        type: INPUT_TYPE.CHECKBOX,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
      dividerHeight: {
        ...initInput,
        name: 'dividerHeight',
        type: INPUT_TYPE.NUMBER,
        validation: {
          ...initInput.validation,
          required: false,
          min: 0,
          max: 20,
        },
      },
      dividerColor: {
        ...initInput,
        name: 'dividerColor',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: false,
          min: 0,
          max: 20,
        },
      },
      hideTimeout: {
        ...initInput,
        name: 'hideTimeout',
        type: INPUT_TYPE.NUMBER,
        validation: {
          ...initInput.validation,
          required: true,
          min: 10,
          max: 10000,
        },
      },
      hideTransportationAfterTimeout: {
        ...initInput,
        name: 'hideTransportationAfterTimeout',
        type: INPUT_TYPE.CHECKBOX,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
      columns: {
        ...initInput,
        name: 'columns',
        type: INPUT_TYPE.MULTISELECT,
        validation: {
          ...initInput.validation,
          required: false,
          min: 0,
          max: 5,
        },
      },
    },
    values: {
      name: '',
      type: 'ramp',
      columns: [],
      noRows: 0,
      lineDivider: false,
      dividerHeight: 0,
      dividerColor: '',
      fontSize: 0,
      hideTransportationAfterTimeout: false,
      hideTimeout: 0,
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;

  switch (type) {
    /**
     * Refresh Data
     */
    case `${DEVICES__DISPLAYS_CONFIGURATION_REFRESH}`: {
      return {
        ...state,
        detail: {
          ...initialState.detail,
          values: {
            ...initialState.detail.values,
            columns: [],
          },
        },
      };
    }


    /**
     * Change form
     */
    case `${DEVICES__DISPLAYS_CONFIGURATION_CHANGE_VALUE_DETAIL}`: {
      const newFormData = changeFormValue(
        state.detail.form,
        state.detail.values,
        state.detail.invalidInputs,
        payload
      );

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: newFormData.invalidInputs < 1,
          invalidInputs: newFormData.invalidInputs,
          form: newFormData.form,
          values: newFormData.values,
        },
      };
    }

    /**
     * Add column
     */
    case `${DEVICES__DISPLAYS_CONFIGURATION_ADD_COLUMN_DETAIL}`: {
      const { columns, } = state.detail.values;

      columns.push({
        parameter: '',
        color: '',
        width: 0,
      });

      return {
        ...state,
        detail: {
          ...state.detail,
          values: {
            ...state.detail.values,
          },
        },
      };
    }

    /**
     * Change in Column
     */
    case `${DEVICES__DISPLAYS_CONFIGURATION_CHANGE_VALUE_IN_COLUMN_DETAIL}`: {
      const { columns, } = state.detail.values;
      columns[payload.index][payload.parameter] = payload.value;
      return {
        ...state,
        detail: {
          ...state.detail,
          values: {
            ...state.detail.values,
            columns,
          },
        },
      };
    }

    /**
     * Remove Column
     */
    case `${DEVICES__DISPLAYS_CONFIGURATION_REMOVE_COLUMN_DETAIL}`: {
      const { columns, } = state.detail.values;
      delete columns[payload.index];

      return {
        ...state,
        detail: {
          ...state.detail,
          values: {
            ...state.detail.values,
            columns,
          },
        },
      };
    }

    /**
     * Validate form
     */
    case `${DEVICES__DISPLAYS_CONFIGURATION_VALIDATE_FORM_DETAIL}`: {
      const validatedForm = mergeFormAndValidation(
        state.detail.form,
        payload,
      );

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
          values: {
            ...state.detail.values,
          },
        },
      };
    }


    /**
     * GET Display Configuration Detail
     */
    case `${DEVICES__GET_DISPLAYS_CONFIGURATION_DETAIL}__${PENDING}`: {
      return {
        ...state,
        detailDisplaysConfigurationData: {
          ...state.detailDisplaysConfigurationData,
          ...(handlePending()),
        },
        detail: {
          ...initialState.detail,
        },
      };
    }

    case `${DEVICES__GET_DISPLAYS_CONFIGURATION_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        detailDisplaysConfigurationData: {
          ...state.detailDisplaysConfigurationData,
          ...(handleFulfilled(payload)),
        },
        detail: {
          ...state.detail,
          id: payload.data.id,
          values: {
            ...state.detail.values,
            name: payload.data.name,
            type: payload.data.type,
            columns: removeNullFromJsonArray(payload.data.configuration.columns),
            noRows: payload.data.configuration.noRows,
            lineDivider: payload.data.configuration.lineDivider,
            dividerHeight: payload.data.configuration.dividerHeight,
            dividerColor: payload.data.configuration.dividerColor,
            fontSize: payload.data.configuration.fontSize,
            hideTransportationAfterTimeout:
              payload.data.configuration.hideTransportationAfterTimeout,
            hideTimeout: payload.data.configuration.hideTimeout,
          },
        },
      };
    }

    case `${DEVICES__GET_DISPLAYS_CONFIGURATION_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        detailDisplaysConfigurationData: {
          ...state.detailDisplaysConfigurationData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * CREATE Config Detail
     */
    case `${DEVICES__CREATE_DISPLAYS_CONFIGURATION_DETAIL}__${PENDING}`: {
      return {
        ...state,
        createDisplaysConfigurationData: {
          ...state.createDisplaysConfigurationData,
          ...(handlePending()),
        },
      };
    }

    case `${DEVICES__CREATE_DISPLAYS_CONFIGURATION_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        createDisplaysConfigurationData: {
          ...state.createDisplaysConfigurationData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${DEVICES__CREATE_DISPLAYS_CONFIGURATION_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        createDisplaysConfigurationData: {
          ...state.createDisplaysConfigurationData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * UPDATE Display Configuration Detail
     */
    case `${DEVICES__UPDATE_DISPLAYS_CONFIGURATION_DETAIL}__${PENDING}`: {
      return {
        ...state,
        updateDisplaysConfigurationData: {
          ...state.updateDisplaysConfigurationData,
          ...(handlePending()),
        },
      };
    }

    case `${DEVICES__UPDATE_DISPLAYS_CONFIGURATION_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        updateDisplaysConfigurationData: {
          ...state.updateDisplaysConfigurationData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${DEVICES__UPDATE_DISPLAYS_CONFIGURATION_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data.data);

      return {
        ...state,
        updateDisplaysConfigurationData: {
          ...state.updateDisplaysConfigurationData,
          ...(handleFulfilled(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${DEVICES__UPDATE_DISPLAYS_CONFIGURATION_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        updateDisplaysConfigurationData: {
          ...state.updateDisplaysConfigurationData,
          ...(handleRejected(payload)),
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
