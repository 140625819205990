import React, { Component, } from 'react';
import {
  shape, bool, func, object,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  createLanguagesDetailAction,
  validateFormAction,
  changeValueAction,
  refreshLanguagesDetailAction,
} from '../actions/languagesDetail';
import { validateInput, validateWholeFormValidator, } from '../../../logic/forms/validation';
import Modal from '../../../atoms/Modal/Modal';
import LanguagesDetail from '../components/LanguagesDetail/LanguagesDetail';
import LanguagesCreate from '../components/LanguagesCreate/LanguagesCreate';


class LanguagesCreateModal extends Component {
  componentDidMount() {
    const {
      refreshLanguagesDetail,
    } = this.props;

    refreshLanguagesDetail();
  }

  handleClose = () => {
    const { history, } = this.props;

    history.goBack();
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);

    changeValue(name, value, validationError);
  }

  handleCreate = async () => {
    const {
      createLanguagesData,
      detail,
      validateForm,
      createLanguagesDetail,
    } = this.props;

    if (createLanguagesData.isLoading || !detail.isValid) return;

    const validation = await validateWholeFormValidator(detail.form, detail.values);
    if (Object.keys(validation).length < 1) {
      createLanguagesDetail(detail, this.handleCreateSuccess);
    } else {
      validateForm(validation);
    }
  }


  handleCreateSuccess = () => {
    const { refreshParent, } = this.props;

    refreshParent();
    this.handleClose();
  }

  render() {
    const {
      createLanguagesData,
      detail,
    } = this.props;

    return (
      <Modal
        isOpen
        onClose={this.handleClose}
        closeOnEsc
        size="MD"
        title="Vytvoření jazyku"
      >
        <LanguagesCreate
          // data
          createLanguagesData={createLanguagesData}
          isFormValid={detail.isValid}
          // methods
          handleClose={this.handleClose}
          handleCreate={this.handleCreate}
        >
          <LanguagesDetail
            // data
            values={detail.values}
            form={detail.form}
            // methods
            handleClose={this.handleClose}
            handleOnChangeValue={this.handleOnChangeValue}
          />
        </LanguagesCreate>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { languagesDetail, } = state;
  return {
    createLanguagesData: languagesDetail.createLanguagesData,
    detail: languagesDetail.detail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createLanguagesDetail: bindActionCreators(createLanguagesDetailAction, dispatch), // eslint-disable-line
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
  refreshLanguagesDetail: bindActionCreators(refreshLanguagesDetailAction, dispatch),
});


LanguagesCreateModal.propTypes = {
  createLanguagesData: object.isRequired,
  detail: shape({
    isValid: bool.isRequired,
    form: object.isRequired,
    values: object.isRequired,
  }).isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
  createLanguagesDetail: func.isRequired,
  refreshLanguagesDetail: func.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
  refreshParent: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(LanguagesCreateModal);
