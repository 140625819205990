import { INPUT_TYPE, } from 'sofair-form-validation';

import {
  LANGUAGES__GET_LANGUAGES_DETAIL,
  LANGUAGES__CREATE_LANGUAGES_DETAIL,
  LANGUAGES__UPDATE_LANGUAGES_DETAIL,
  LANGUAGES__VALIDATE_FORM_DETAIL,
  LANGUAGES__CHANGE_VALUE_DETAIL,
  LANGUAGES__REFRESH,
  LANGUAGES__DELETE_LANGUAGES_DETAIL,
  LANGUAGES__TOGGLE_DELETE_LANGUAGES_DELETE_MODAL,
} from '../actions/languagesDetail';
import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';
import {
  mergeFormAndValidation, initInput, changeFormValue,
} from '../../../logic/forms/validation';

const initialState = {
  detailLanguagesData: {
    ...initRestData,
    isLoading: true,
  },
  createLanguagesData: {
    ...initRestData,
  },
  updateLanguagesData: {
    ...initRestData,
  },
  deleteLanguagesData: {
    ...initRestData,
  },
  languagesDeleteModal: {
    id: null,
    isOpen: false,
  },
  detail: {
    id: null,
    isValid: true,
    invalidInputs: 0,
    form: {
      name: {
        ...initInput,
        name: 'name',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: true,
          min: 5,
          max: 255,
        },
      },
      code: {
        ...initInput,
        name: 'code',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: true,
          min: 1,
          max: 10,
        },
      },
    },
    values: {
      name: '',
      code: '',
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;

  switch (type) {
    /**
     * Refresh Data
     */
    case `${LANGUAGES__REFRESH}`: {
      return {
        ...state,
        createLanguagesData: {
          ...initialState.createLanguagesData,
        },
        updateLanguagesData: {
          ...initialState.updateLanguagesData,
        },
        detail: {
          ...initialState.detail,
        },
      };
    }


    /**
     * Change Form
     */
    case `${LANGUAGES__CHANGE_VALUE_DETAIL}`: {
      const newFormData = changeFormValue(
        state.detail.form, state.detail.values, state.detail.invalidInputs, payload
      );

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: newFormData.invalidInputs < 1,
          invalidInputs: newFormData.invalidInputs,
          form: newFormData.form,
          values: newFormData.values,
        },
      };
    }


    /**
     * Validate form
     */
    case `${LANGUAGES__VALIDATE_FORM_DETAIL}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload);

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }


    /**
     * GET Languages Detail
     */
    case `${LANGUAGES__GET_LANGUAGES_DETAIL}__${PENDING}`: {
      return {
        ...state,
        detailLanguagesData: {
          ...initialState.detailLanguagesData,
          ...(handlePending()),
        },
        updateLanguagesData: {
          ...initialState.updateLanguagesData,
        },
        detail: {
          ...initialState.detail,
        },
      };
    }

    case `${LANGUAGES__GET_LANGUAGES_DETAIL}__${FULFILLED}`: {
      try {
        const newValues = {
          ...state.detail.values,
          name: payload.data.name,
          code: payload.data.code,
        };
        return {
          ...state,
          detailLanguagesData: {
            ...state.detailLanguagesData,
            ...(handleFulfilled(payload)),
          },
          detail: {
            ...state.detail,
            id: payload.data.id,
            values: {
              ...newValues,
            },
          },
        };
      } catch (err) {
        return {
          ...state,
          detailLanguagesData: {
            ...state.detailLanguagesData,
            ...(handleRejected({
              statusCode: -1,
              messageInternal: err.message,
              message: {
                code: 'SOMETHING_HAPPEND',
                params: {},
              },
            })),
          },
        };
      }
    }

    case `${LANGUAGES__GET_LANGUAGES_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        detailLanguagesData: {
          ...state.detailLanguagesData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * CREATE Languages Detail
     */
    case `${LANGUAGES__CREATE_LANGUAGES_DETAIL}__${PENDING}`: {
      return {
        ...state,
        createLanguagesData: {
          ...state.createLanguagesData,
          ...(handlePending()),
        },
      };
    }

    case `${LANGUAGES__CREATE_LANGUAGES_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        createLanguagesData: {
          ...state.createLanguagesData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${LANGUAGES__CREATE_LANGUAGES_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data);

      return {
        ...state,
        createLanguagesData: {
          ...state.createLanguagesData,
          ...(handleRejected(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${LANGUAGES__CREATE_LANGUAGES_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        createLanguagesData: {
          ...state.createLanguagesData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * UPDATE Languages Detail
     */
    case `${LANGUAGES__UPDATE_LANGUAGES_DETAIL}__${PENDING}`: {
      return {
        ...state,
        updateLanguagesData: {
          ...state.updateLanguagesData,
          ...(handlePending()),
        },
      };
    }

    case `${LANGUAGES__UPDATE_LANGUAGES_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        updateLanguagesData: {
          ...state.updateLanguagesData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${LANGUAGES__UPDATE_LANGUAGES_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data.data);

      return {
        ...state,
        updateLanguagesData: {
          ...state.updateLanguagesData,
          ...(handleRejected(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${LANGUAGES__UPDATE_LANGUAGES_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        updateLanguagesData: {
          ...state.updateLanguagesData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * DELETE Language Detail
     */
    case `${LANGUAGES__DELETE_LANGUAGES_DETAIL}__${PENDING}`: {
      return {
        ...state,
        deleteLanguagesData: {
          ...state.deleteLanguagesData,
          ...(handlePending()),
        },
      };
    }

    case `${LANGUAGES__DELETE_LANGUAGES_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        deleteLanguagesData: {
          ...state.deleteLanguagesData,
          ...(handleFulfilled(payload)),
        },
        languagesDeleteModal: {
          ...initialState.languagesDeleteModal,
        },
      };
    }

    case `${LANGUAGES__DELETE_LANGUAGES_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        deleteLanguagesData: {
          ...state.deleteLanguagesData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
     * TOGGLE Languages delete modal
     */
    case `${LANGUAGES__TOGGLE_DELETE_LANGUAGES_DELETE_MODAL}`: {
      return {
        ...state,
        languagesDeleteModal: {
          ...initialState.languagesDeleteModal,
          isOpen: payload.isOpen,
          id: payload.id,
        },
        deleteLanguagesData: initialState.deleteLanguagesData,
      };
    }

    default:
      return state;
  }
};

export default reducer;
