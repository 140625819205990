import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION, FORM_SUCCESS,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';

/**
 * Refresh data - on mount
 */
export const NUMERICAL_SERIES__REFRESH = 'NUMERICAL_SERIES__REFRESH';
export const refreshNumericalSeriesDetailAction = () => ({
  type: `${NUMERICAL_SERIES__REFRESH}`,
});


/**
 * Change Value
 */
export const NUMERICAL_SERIES__CHANGE_VALUE_DETAIL = 'NUMERICAL_SERIES__CHANGE_VALUE_DETAIL';
export const changeValueAction = (name, value, validationError) => ({
  type: `${NUMERICAL_SERIES__CHANGE_VALUE_DETAIL}`,
  payload: {
    name,
    value,
    validationError,
  },
});


/**
 * Validate Form
 */
export const NUMERICAL_SERIES__VALIDATE_FORM_DETAIL = 'NUMERICAL_SERIES__VALIDATE_FORM_DETAIL';
export const validateFormAction = (error) => ({
  type: `${NUMERICAL_SERIES__VALIDATE_FORM_DETAIL}`,
  payload: error,
});


/*
 * GET Detail
 */
export const NUMERICAL_SERIES__GET_DETAIL = 'NUMERICAL_SERIES__GET_DETAIL';
export const getNumericalSeriesDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${NUMERICAL_SERIES__GET_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/numerical_series?id=${id}`,
    });

    dispatch({
      type: `${NUMERICAL_SERIES__GET_DETAIL}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${NUMERICAL_SERIES__GET_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * CREATE Numerical Serie Detail
 */
export const NUMERICAL_SERIES__CREATE_DETAIL = 'NUMERICAL_SERIES__CREATE_DETAIL';
export const createNumericalSeriesDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${NUMERICAL_SERIES__CREATE_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/numerical_series`,
      data: {
        values: item.values,
      },
    });

    dispatch({
      type: `${NUMERICAL_SERIES__CREATE_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Numerická řada byla vytvořena',
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${NUMERICAL_SERIES__CREATE_DETAIL}__${REJECTED_VALIDATION}`,
        payload: parsedError,
      });
      return;
    }

    dispatch({
      type: `${NUMERICAL_SERIES__CREATE_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};

/*
 * Update Numerical Serie Detail
 */
export const NUMERICAL_SERIES__UPDATE_DETAIL = 'NUMERICAL_SERIES__UPDATE_DETAIL';
export const updateNumericalSeriesDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${NUMERICAL_SERIES__UPDATE_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/numerical_series`,
      data: {
        id: item.id,
        values: item.values,
      },
    });

    dispatch({
      type: `${NUMERICAL_SERIES__UPDATE_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Numerická řada byla upravena',
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${NUMERICAL_SERIES__UPDATE_DETAIL}__${REJECTED_VALIDATION}`,
        payload: parsedError,
      });
      return;
    }

    dispatch({
      type: `${NUMERICAL_SERIES__UPDATE_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};
