import styled from 'styled-components';


const StyledCheckBox = styled.div`
/* Customize the label (the checkbox--container) */
.checkbox--container {
  /* display: inline-block;*/
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox--container-disabled {
  cursor: default;
}

/* Hide the browser's default checkbox */
.checkbox--container input {
  position: absolute;
  opacity: 0;
  left: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkbox--checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  background-color: ${(p) => p.theme.grey.t200};
}

/* On mouse-over, add a grey background color */
.checkbox--container:hover input ~ .checkbox--checkmark {
  background-color: ${(p) => p.theme.grey.t200};
}

/* When the checkbox is checked, add a blue background */
.checkbox--container input:checked ~ .checkbox--checkmark {
  background-color: ${(p) => p.theme.primary.t700};
}

.checkbox--container input:disabled ~ .checkbox--checkmark {
  background-color: ${(p) => p.theme.grey.t400};
  cursor: default;
}

/* Create the checkbox--checkmark/indicator (hidden when not checked) */
.checkbox--checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkbox--checkmark when checked */
.checkbox--container input:checked ~ .checkbox--checkmark:after {
  display: block;
}

/* Style the checkbox--checkmark/indicator */
.checkbox--container .checkbox--checkmark:after {
  left: 7px;
  top: 4px;
  width: 4px;
  height: 8px;
  border: solid ${(p) => p.theme.white};
  box-sizing: content-box;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
`;


export default StyledCheckBox;
