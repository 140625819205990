import { PENDING, FULFILLED, REJECTED, } from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


/*
 * GET All Stand App Configuration
 */
export const QUEUE_CONFIG__GET_STAND_APP_CONFIGURATION = 'QUEUE_CONFIG__GET_STAND_APP_CONFIGURATION';
export const getStandAppConfigurationAction = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: `${QUEUE_CONFIG__GET_STAND_APP_CONFIGURATION}__${PENDING}`,
      payload: filter,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/stands/configuration/filter`,
      data: filter,
    });

    dispatch({
      type: `${QUEUE_CONFIG__GET_STAND_APP_CONFIGURATION}__${FULFILLED}`,
      payload: response.data,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${QUEUE_CONFIG__GET_STAND_APP_CONFIGURATION}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/**
 * Filter Changed
 */
export const QUEUE_CONFIG__STAND_APP_CONFIGURATION_FILTER_CHANGED = 'QUEUE_CONFIG__STAND_APP_CONFIGURATION_FILTER_CHANGED';

export const changeFilterAction = (filter) => ({
  type: `${QUEUE_CONFIG__STAND_APP_CONFIGURATION_FILTER_CHANGED}`,
  payload: filter,
});
