//
// Find key value
//
export const findKeyValuePairForValue = (obj, key) => {
  if (key === null || !Object.prototype.hasOwnProperty.call(obj, key)) {
    return null;
  }
  return obj[key];
};

export const parseObjectwithTranslation = (id, obj, name) => {
  if (id === null || obj === null) {
    return null;
  }
  return { id, name: obj[name].name, };
};
