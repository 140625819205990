export const deletedOptions = {
  yes: {
    value: 'yes',
    label: 'Vše',
  },
  no: {
    value: 'no',
    label: 'Aktivní',
  },
  only: {
    value: 'only',
    label: 'Smazané',
  },
};


export const COLS = [
  {
    id: 'id',
    width: 15,
    label: '#',
  },
  {
    id: 'name',
    width: 30,
    label: 'Název',
  },
  {
    id: 'deleted',
    width: 30,
    label: 'Stav',
  },
  {
    id: 'actions',
    width: 25,
    label: 'Akce',
  },
];
