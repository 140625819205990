import React from 'react';
import {
  arrayOf, func, shape, string,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';
import { TransitionGroup, CSSTransition, } from 'react-transition-group';

import { removeNotifAction, } from './actions';

import StyledNotifManager from './styles/StyledNotifManager';
import Notif from './Notif';


const NotifManager = ({ data, removeNotif, }) => (
  <StyledNotifManager>
    <TransitionGroup>
      {data.map((item) => (
        <CSSTransition
          classNames="notif--trans"
          timeout={350}
          key={item.id}
        >
          <Notif
            data={item}
            onTimeout={removeNotif}
          />
        </CSSTransition>
      ))}
    </TransitionGroup>
  </StyledNotifManager>
);


const mapStateToProps = (state) => {
  const { notifs, } = state;
  return {
    data: notifs.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  removeNotif: bindActionCreators(removeNotifAction, dispatch),
});


NotifManager.propTypes = {
  removeNotif: func.isRequired,
  data: arrayOf(shape({
    id: string.isRequired,
  })).isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(NotifManager);
