import React, { Component, Fragment, } from 'react';
import {
  shape, bool, arrayOf, func, string, number,
} from 'prop-types';

import RTRow from '../../../../atoms/Table/RTRow';
import RTColHeader from '../../../../atoms/Table/RTColHeader';
import RTCol from '../../../../atoms/Table/RTCol';
import RTBody from '../../../../atoms/Table/RTBody';
import Button from '../../../../atoms/Button/Button';
import ButtonGrp from '../../../../atoms/ButtonGrp/ButtonGrp';
import Detail from '../../../../styles/icons/Detail';
import Trash from '../../../../styles/icons/Trash';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Restore from '../../../../styles/icons/Restore';
import RTLoader from '../../../../atoms/Table/RTLoader';
import RTError from '../../../../atoms/Table/RTError';
import RTNoData from '../../../../atoms/Table/RTNoData';


class PlatformsTableRow extends Component {
  renderRows = () => {
    const {
      // data
      data,
      cols,
      platformsData,
      // methods
      handleGetPlatforms,
      handleDelete,
      handleEdit,
      handleRestore,
    } = this.props;

    const isLoading = platformsData.isLoading; // eslint-disable-line
    const noData = !isLoading && data.length < 1;

    if (!isLoading && platformsData.error) {
      return (
        <RTError
          data={platformsData}
          refresh={() => handleGetPlatforms()}
        />
      );
    }

    return (
      <Fragment>
        {isLoading && (
          <RTLoader />
        )}

        {noData && (
          <RTNoData />
        )}

        {data.map((item) => (
          <RTRow key={item.id}>

            <RTColHeader>{`${cols[0].label}:`}</RTColHeader>
            <RTCol width={cols[0].width}>{item.id}</RTCol>

            <RTColHeader>{`${cols[1].label}:`}</RTColHeader>
            <RTCol width={cols[1].width}>{item.name}</RTCol>

            <RTColHeader>{`${cols[2].label}:`}</RTColHeader>
            <RTCol width={cols[2].width}>{item.no}</RTCol>

            <RTColHeader>{`${cols[3].label}:`}</RTColHeader>
            <RTCol width={cols[3].width}>
              <span className={item.deletedAt === null ? 'app--color-success' : 'app--color-error'}>
                {item.deletedAt === null ? 'Aktivní' : 'Smazáno'}
              </span>
            </RTCol>

            <RTColHeader>{`${cols[4].label}:`}</RTColHeader>
            <RTCol width={cols[4].width}>
              {item.deletedAt === null && (
                <ButtonGrp>
                  <Button
                    shape="outline"
                    size="sm"
                    color="secondary"
                    onClick={() => handleEdit(item.id)}
                  >
                    <span className="rt--col-icon-first"><Detail /></span>
                    Detail
                  </Button>
                  <ButtonLoader
                    shape="outline"
                    size="sm"
                    color="error"
                    onClick={() => handleDelete(item)}
                    isLoading={item.isLoading}
                  >
                    <span className="rt--col-icon-first"><Trash /></span>
                    Smazat
                  </ButtonLoader>
                </ButtonGrp>
              )}
              {item.deletedAt !== null && (
                <div>
                  <ButtonLoader
                    shape="outline"
                    size="sm"
                    color="success"
                    onClick={() => handleRestore(item)}
                    isLoading={item.isLoading}
                  >
                    <span className="rt--col-icon-first"><Restore /></span>
                    Obnovit
                  </ButtonLoader>
                </div>
              )}
            </RTCol>
          </RTRow>
        ))}
      </Fragment>
    );
  }

  render() {
    return (
      <RTBody>
        {this.renderRows()}
      </RTBody>
    );
  }
}


PlatformsTableRow.propTypes = {
  data: arrayOf(shape({
    id: number.isRequired,
    name: string.isRequired,
    deletedAt: string,
    isLoading: bool.isRequired,
    error: bool.isRequired,
  })).isRequired,
  cols: arrayOf(shape({
    label: string.isRequired,
    width: number.isRequired,
  })).isRequired,
  platformsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  handleGetPlatforms: func.isRequired,
  handleRestore: func.isRequired,
  handleEdit: func.isRequired,
  handleDelete: func.isRequired,
};


export default PlatformsTableRow;
