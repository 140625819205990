import React, { Fragment, } from 'react';
import {
  shape, func, arrayOf, object, string, bool,
} from 'prop-types';

import { withLang, } from '../../../../logic/languages/withLang';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Input from '../../../../atoms/Input/Input';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';
import MultiSelectListWrapper from '../../../../components/wrappers/MultiSelectListWrapper';


const NumericalSeriesDetail = ({
  // data
  form,
  values,
  // methods
  handleOnChangeValue,
  langError,
}) => (
  <Fragment>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Název
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="text"
          value={values.name}
          status={form.name.status}
          onChange={(e) => handleOnChangeValue(form.name.name, e.target.value)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.name.status}
          msg={langError(form.name.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Prefix
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="text"
          value={values.prefix}
          status={form.prefix.status}
          onChange={(e) => handleOnChangeValue(form.prefix.name, e.target.value)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.prefix.status}
          msg={langError(form.prefix.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Postfix
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="text"
          value={values.postfix}
          status={form.postfix.status}
          onChange={(e) => handleOnChangeValue(form.postfix.name, e.target.value)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.postfix.status}
          msg={langError(form.postfix.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Počet číslic
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="number"
          value={values.numbersCount}
          status={form.numbersCount.status}
          onChange={(e) => handleOnChangeValue(form.numbersCount.name, e.target.value)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.numbersCount.status}
          msg={langError(form.numbersCount.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

  </Fragment>
);


NumericalSeriesDetail.propTypes = {
  form: shape({
    name: shape({
      name: string.isRequired,
      type: string.isRequired,
      validation: object.isRequired,
      status: string.isRequired,
      info: string.isRequired,
      statusMsg: object.isRequired,
    }),
    prefix: shape({
      name: string.isRequired,
      type: string.isRequired,
      validation: object.isRequired,
      status: string.isRequired,
      info: string.isRequired,
      statusMsg: object.isRequired,
    }),
    postfix: shape({
      name: string.isRequired,
      type: string.isRequired,
      validation: object.isRequired,
      status: string.isRequired,
      info: string.isRequired,
      statusMsg: object.isRequired,
    }),
    numbersCount: shape({
      name: string.isRequired,
      type: string.isRequired,
      validation: object.isRequired,
      status: string.isRequired,
      info: string.isRequired,
      statusMsg: object.isRequired,
    }),
  }).isRequired,
  values: shape({
    name: string,
    prefix: string,
    postfix: string,
    numbersCount: string,
  }).isRequired,
  handleOnChangeValue: func.isRequired,
  langError: func.isRequired,
};


export default withLang(NumericalSeriesDetail);
