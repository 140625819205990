import uuidv4 from 'uuid/v4';


/**
 * If something is removed return true
 * @param {Array} newArray
 * @param {Array} oldArray
 * @param {string} id object identificator
 */
export const isSomethingRemovedFromArray = (newArray, oldArray) => {
  const newObj = {};
  for (let i = 0; i < newArray.length; i++) {
    const key = newArray[i];
    newObj[key] = newArray[i];
  }

  for (let j = 0; j < oldArray.length; j++) {
    const key = oldArray[j];

    if (!Object.prototype.hasOwnProperty.call(newObj, key)) {
      return true;
    }
  }

  return false;
};


// Compare arrays elements id
// - newArray - array
// - oldArray - array
// - id - string
export const isArrayChanged = (newArray, oldArray) => {
  if (newArray.length !== oldArray.length) {
    return true;
  }

  for (let i = 0; i < newArray.length; i++) {
    if (newArray[i] !== oldArray[i]) {
      return true;
    }
  }
  return false;
};


// parse array items to object: id, payload
//  - array
export const parseArrayItemToPayloadAndUUID = (array) => array.map(
  (item) => ({
    id: uuidv4(),
    payload: item,
  })
);

// check array from server
//  - array
export const parseArrayFromApi = (array) => array.filter(
  (item) => item !== null || item !== undefined
);


export const returnSelectValueOrNull = (value, id = 'id') => (value === null ? null : value[id]);


/**
 * Add part to array immutable
 * @param {Array} array
 * @param {Number} position
 * @param {Any} node
 */
export const addNodeToArray = (array, position, node) => ([
  ...array.slice(0, position),
  node,
  ...array.slice(position),
]);


/**
 * Add array to array immutable
 * @param {Array} array
 * @param {Number} position
 * @param {Array} add
 */
export const addArrayToArray = (array, position, add) => ([
  ...array.slice(0, position),
  ...add,
  ...array.slice(position),
]);

export const removeNullFromJsonArray = (array) => {
  const newArray = [];
  for (let i = 0; i < array.length; i++) {
    if (array[i] !== null) {
      newArray.push(array[i]);
    }
  }
  return newArray;
};
