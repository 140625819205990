import React, { Fragment, } from 'react';
import {
  shape, number, bool, object, func, arrayOf,
} from 'prop-types';

// import Row from '../../../../atoms/Row/Row';
// import Col from '../../../../atoms/Col/Col';
import Heading from '../../../../atoms/Heading/Heading';
// import Button from '../../../../atoms/Button/Button';
import ParkingsTable from './ParkingsTable';


// TODO:
//  - handle Errors Delete, Restore
const Parkings = ({
  // data
  table,
  parkingsData,
  // methods
  handleGetParkings,
  handleRestore,
  handleEdit,
  handleDelete,
  // handleCreate,
  changeFilter,
}) => (
  <Fragment>

    <Heading h4>
      Parkoviště
    </Heading>

    {/*
    <Row formGroup>
      <Col>
        <Button
          type="button"
          onClick={handleCreate}
          color="success"
          shape="outline"
        >
          Vytvořit parkoviště
        </Button>
      </Col>
    </Row>
    */}

    <ParkingsTable
      data={table.data}
      table={table}
      filter={table.filter}
      count={table.count}
      parkingsData={parkingsData}
      handleGetParkings={handleGetParkings}
      handleRestore={handleRestore}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      changeFilter={changeFilter}
    />

  </Fragment>
);


Parkings.propTypes = {
  table: shape({
    data: arrayOf(object).isRequired,
    count: number.isRequired,
    filter: object.isRequired,
  }).isRequired,
  parkingsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  changeFilter: func.isRequired,
  handleGetParkings: func.isRequired,
  handleRestore: func.isRequired,
  handleEdit: func.isRequired,
  handleDelete: func.isRequired,
  // handleCreate: func.isRequired,
};


export default Parkings;
