import React, { Component, Fragment, } from 'react';
import {
  shape, bool, object, func, string, number, arrayOf,
} from 'prop-types';

import { COLS, } from './utils';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Pagination from '../../../../atoms/Pagination/Pagination';
import ControllersTableRow from './ControllersTableRow';
import ControllersTableHeader from './ControllersTableHeader';


class ControllersTable extends Component {
  constructor(props) {
    super(props);

    this.paramsTimer = null;
  }

  componentWillUnmount() {
    if (this.paramsTimer !== null) {
      clearTimeout(this.paramsTimer);
      this.paramsTimer = null;
    }
  }

  handleOnClickSort = (id) => {
    const {
      filter, controllersData,
    } = this.props;

    if (controllersData.isLoading) return;

    let newOrder = 'ASC';

    if (filter.sortBy === id && filter.order === 'ASC') {
      newOrder = 'DESC';
    }

    this.handleFilterChanged({
      ...filter,
      order: newOrder,
      sortBy: id,
    });
  }

  handleOnChangeParams = (name, value) => {
    const { changeFilter, filter, } = this.props;

    if (this.paramsTimer !== null) {
      clearTimeout(this.paramsTimer);
      this.paramsTimer = null;
    }

    this.paramsTimer = setTimeout(this.paramsTimerDone, 750);

    changeFilter({
      ...filter,
      params: {
        ...filter.params,
        [name]: value,
      },
    });
  }

  handleOnChangeOffset = (newOffset) => {
    const { filter, } = this.props;

    this.handleFilterChanged({
      ...filter,
      offset: newOffset,
    });
  }

  paramsTimerDone = () => {
    const { filter, onGetControllers, } = this.props;

    onGetControllers({
      ...filter,
      offset: 0,
    });

    clearTimeout(this.paramsTimer);
    this.paramsTimer = null;
  }

  handleFilterChanged = (newFilter) => {
    const {
      onGetControllers, changeFilter,
    } = this.props;

    if (this.paramsTimer === null) {
      onGetControllers({
        ...newFilter,
      });
    } else {
      changeFilter({
        ...newFilter,
      });
    }
  }

  render() {
    const {
      // data
      data,
      filter,
      controllersData,
      count,
      // methods
      onGetControllers,
      onEdit,
      onRegister,
      onDispEditor,
      onDelete,
    } = this.props;

    return (
      <Fragment>

        <Row formGroup>
          <Col>
            <ControllersTableHeader
              filter={filter}
              controllersData={controllersData}
              onClickSort={this.handleOnClickSort}
              onChangeParam={this.handleOnChangeParams}
            />
            <ControllersTableRow
              // data
              data={data}
              cols={COLS}
              controllersData={controllersData}
              // methods
              onGetControllers={onGetControllers}
              onEdit={onEdit}
              onRegister={onRegister}
              onDispEditor={onDispEditor}
              onDelete={onDelete}
            />
          </Col>
        </Row>

        <Pagination
          isLoading={controllersData.isLoading}
          offset={filter.offset}
          limit={filter.limit}
          count={count}
          action={this.handleOnChangeOffset}
        />
      </Fragment>
    );
  }
}


ControllersTable.propTypes = {
  data: arrayOf(object).isRequired,
  count: number.isRequired,
  filter: shape({
    sortBy: string.isRequired,
    order: string.isRequired,
  }).isRequired,
  controllersData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  changeFilter: func.isRequired,
  onGetControllers: func.isRequired,
  onEdit: func.isRequired,
  onDispEditor: func.isRequired,
  onRegister: func.isRequired,
  onDelete: func.isRequired,
};


export default ControllersTable;
