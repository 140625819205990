import React, { Component, Fragment, } from 'react';
import {
  shape, bool, func, object, string,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  getTransportationTypesDetailAction,
  updateTransportationTypesDetailAction,
  validateFormAction,
  changeValueAction,
  forceUpdateTransportationTypesDetailAction,
  toggleForceUpdateModalAction,
  changeValueForceUpdateModalAction,
} from '../actions/transportationTypesDetail';
import {
  getSharedDataAction,
  getTicketsSharedAction,
} from '../../shared/actions/shared';
import { validateInput, } from '../../../logic/forms/validation';
import { validateTransTypesDetail, } from './utils';
import TransportationTypesEdit from '../components/TransportationTypesEdit/TransportationTypesEdit';
import Modal from '../../../atoms/Modal/Modal';
import TransportationTypesDetail from '../components/TransportationTypesDetail/TransportationTypesDetail';
import TransportationTypesCollisionUpdate from '../components/TransportationTypesCollisionUpdate/TransportationTypesCollisionUpdate';
import { SHARED_DATA_TYPES, } from '../../../globals';

class TransportationTypesEditModal extends Component {
  componentDidMount() {
    this.handleGetDetail();
  }

  handleGetDetail = () => {
    const { match, getTransportationTypesDetail, } = this.props;

    getTransportationTypesDetail(match.params.id);
  }

  handleClose = () => {
    const { history, } = this.props;

    history.goBack();
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);

    changeValue(name, value, validationError);
  }

  handleEdit = async () => {
    const {
      updateTransportationTypesData,
      detail,
      validateForm,
      updateTransportationTypesDetail,
    } = this.props;

    if (updateTransportationTypesData.isLoading || !detail.isValid) return;

    const validation = await validateTransTypesDetail(detail);
    if (Object.keys(validation).length < 1) {
      updateTransportationTypesDetail(detail, this.handleEditSuccess);
    } else {
      validateForm(validation);
    }
  }

  handleForceEdit = async () => {
    const {
      forceUpdateTransportationTypesData,
      detail,
      forceUpdateModal,
      validateForm,
      forceUpdateTransportationTypesDetail,
    } = this.props;

    if (forceUpdateTransportationTypesData.isLoading || !detail.isValid) return;

    const validation = await validateTransTypesDetail(detail);
    if (Object.keys(validation).length < 1) {
      forceUpdateTransportationTypesDetail(detail, forceUpdateModal.values, this.handleEditSuccess);
    } else {
      validateForm(validation);
    }
  }

  handleEditSuccess = () => {
    const { refreshParent, } = this.props;

    refreshParent();
    this.handleClose();
  }

  render() {
    const {
      // data
      forceUpdateModal,
      forceUpdateTransportationTypesData,
      detailTransportationTypesData,
      updateTransportationTypesData,
      actionsSharedData,
      platformsSharedData,
      detail,
      ticketsSharedData,
      // methods
      getSharedData,
      toggleForceUpdateModal,
      changeValueForceUpdateModal,
      getTicketsShared,
    } = this.props;

    return (
      <Fragment>
        <Modal
          isOpen
          onClose={this.handleClose}
          closeOnEsc
          size="MD"
          title="Detail typu přepravy"
        >
          <TransportationTypesEdit
            // data
            transTypeDetailData={detailTransportationTypesData}
            updateTransportationTypesData={updateTransportationTypesData}
            isFormValid={detail.isValid}
            // methods
            handleClose={this.handleClose}
            handleEdit={this.handleEdit}
            onGetDetail={this.handleGetDetail}
          >
            <TransportationTypesDetail
              // data
              values={detail.values}
              form={detail.form}
              actionsSharedData={actionsSharedData}
              platformsSharedData={platformsSharedData}
              ticketsSharedData={ticketsSharedData}
              // methods
              handleClose={this.handleClose}
              handleOnChangeValue={this.handleOnChangeValue}
              getPlatformsShared={() => getSharedData(SHARED_DATA_TYPES.PLATFORMS)}
              getActionsShared={() => getSharedData(SHARED_DATA_TYPES.ACTIONS)}
              getTicketsShared={getTicketsShared}
            />
          </TransportationTypesEdit>
        </Modal>

        <TransportationTypesCollisionUpdate
          modalData={forceUpdateModal}
          actionData={forceUpdateTransportationTypesData}
          onClose={() => toggleForceUpdateModal(false)}
          action={this.handleForceEdit}
          onChangeValue={changeValueForceUpdateModal}
        />

      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { transportationTypesDetail, shared, } = state;
  return {
    detailTransportationTypesData: transportationTypesDetail.detailTransportationTypesData,
    updateTransportationTypesData: transportationTypesDetail.updateTransportationTypesData,
    detail: transportationTypesDetail.detail,
    forceUpdateModal: transportationTypesDetail.forceUpdateModal,
    forceUpdateTransportationTypesData: transportationTypesDetail.forceUpdateTransportationTypesData, // eslint-disable-line
    // dropdowns
    actionsSharedData: shared.actionsData,
    platformsSharedData: shared.platformsData,
    ticketsSharedData: shared.ticketsData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTransportationTypesDetail: bindActionCreators(getTransportationTypesDetailAction, dispatch), // eslint-disable-line
  updateTransportationTypesDetail: bindActionCreators(updateTransportationTypesDetailAction, dispatch), // eslint-disable-line
  getSharedData: bindActionCreators(getSharedDataAction, dispatch),
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
  forceUpdateTransportationTypesDetail: bindActionCreators(forceUpdateTransportationTypesDetailAction, dispatch), // eslint-disable-line
  toggleForceUpdateModal: bindActionCreators(toggleForceUpdateModalAction, dispatch),
  changeValueForceUpdateModal: bindActionCreators(changeValueForceUpdateModalAction, dispatch),
  getTicketsShared: bindActionCreators(getTicketsSharedAction, dispatch),
});


TransportationTypesEditModal.propTypes = {
  forceUpdateTransportationTypesData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  forceUpdateModal: shape({
    isOpen: bool.isRequired,
    reservations: object.isRequired,
    transports: object.isRequired,
  }).isRequired,
  detailTransportationTypesData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  updateTransportationTypesData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  actionsSharedData: object.isRequired,
  platformsSharedData: object.isRequired,
  detail: shape({
    isValid: bool.isRequired,
    form: object.isRequired,
    values: object.isRequired,
    oldValues: object.isRequired,
  }).isRequired,
  match: shape({
    params: shape({
      id: string.isRequired,
    }).isRequired,
  }).isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
  getTransportationTypesDetail: func.isRequired,
  toggleForceUpdateModal: func.isRequired,
  forceUpdateTransportationTypesDetail: func.isRequired,
  getSharedData: func.isRequired,
  refreshParent: func.isRequired,
  updateTransportationTypesDetail: func.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
  changeValueForceUpdateModal: func.isRequired,
  getTicketsShared: func.isRequired,
  ticketsSharedData: object.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(TransportationTypesEditModal);
