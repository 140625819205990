import React, { Component, Fragment, } from 'react';
import {
  shape, number, func, object, string, arrayOf, bool,
} from 'prop-types';
import { Route, } from 'react-router-dom';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  toggleStandsDeleteModalAction,
  deleteStandsDetailAction,
} from '../actions/standsDetail';
import { getStandsAction, changeFilterAction, } from '../actions/stands';
import Stands from '../components/Stands/Stands';
import StandsCreateModal from './StandsCreateModal';
import StandsEditModal from './StandsEditModal';
import StandsDeleteModal from '../components/StandsDeleteModal/StandsDeleteModal';


class StandsPage extends Component {
  componentDidMount() {
    const { table, } = this.props;

    this.handleGetStands(table.filter);
  }

  handleGetStands = (newFilter) => {
    const { getStands, table, } = this.props;

    getStands(newFilter === undefined ? table.filter : newFilter);
  }

  handleEdit = (id) => {
    const { match, history, } = this.props;

    history.push(`${match.path}/edit/${id}`);
  }

  handleCreate = () => {
    const { match, history, } = this.props;

    history.push(`${match.path}/create`);
  }

  handleDelete = () => {
    const { deleteStandsData, deleteStandsDetail, standsDeleteModal, } = this.props;

    if (deleteStandsData.isLoading) return;

    deleteStandsDetail(standsDeleteModal.id);
  }

  handleToggle = () => {
    const { toggleStandsDeleteModal, } = this.props;

    toggleStandsDeleteModal(false);
  }


  render() {
    const {
      match,
      table,
      standsDeleteModal,
      deleteStandsData,
      standsData,
      changeFilter,
      toggleStandsDeleteModal,
    } = this.props;

    return (
      <Fragment>

        <Stands
          table={table}
          standsData={standsData}
          handleGetStands={this.handleGetStands}
          handleEdit={this.handleEdit}
          handleDelete={toggleStandsDeleteModal}
          handleCreate={this.handleCreate}
          changeFilter={changeFilter}
        />

        <Route
          exact
          path={`${match.path}/create`}
          render={(props) => <StandsCreateModal {...props} refreshParent={this.handleGetStands} />}
        />
        <Route
          exact
          path={`${match.path}/edit/:id`}
          render={(props) => <StandsEditModal {...props} refreshParent={this.handleGetStands} />}
        />

        <StandsDeleteModal
          modalData={standsDeleteModal}
          actionData={deleteStandsData}
          action={this.handleDelete}
          onClose={() => toggleStandsDeleteModal(false)}
        />

      </Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  const { stands, standsDetail, } = state;
  return {
    standsData: stands.standsData,
    table: stands.table,
    standsDeleteModal: standsDetail.standsDeleteModal,
    deleteStandsData: standsDetail.deleteStandsData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStands: bindActionCreators(getStandsAction, dispatch),
  toggleStandsDeleteModal: bindActionCreators(toggleStandsDeleteModalAction, dispatch), // eslint-disable-line
  changeFilter: bindActionCreators(changeFilterAction, dispatch),
  deleteStandsDetail: bindActionCreators(deleteStandsDetailAction, dispatch), // eslint-disable-line
});


StandsPage.propTypes = {
  table: shape({
    data: arrayOf(object).isRequired,
    count: number.isRequired,
    filter: object.isRequired,
  }).isRequired,
  standsData: object.isRequired,
  changeFilter: func.isRequired,
  getStands: func.isRequired,
  toggleStandsDeleteModal: func.isRequired,
  match: shape({
    path: string.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  deleteStandsDetail: func.isRequired,
  deleteStandsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  standsDeleteModal: shape({
    id: number,
    isOpen: bool.isRequired,
    collision: bool.isRequired,
  }).isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(StandsPage);
