import styled from 'styled-components';


const StyledModalBody = styled.div`
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
`;

export default StyledModalBody;
