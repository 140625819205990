import React, { Fragment, } from 'react';
import {
  object, func,
} from 'prop-types';

import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import DisplaysConfigurationTable from './DisplaysConfigurationTable';
import Button from '../../../../atoms/Button/Button';


const DisplaysConfiguration = ({
  // data
  table,
  displaysConfigurationData,
  // methods
  handleGetDisplaysConfiguration,
  handleEdit,
  handleDelete,
  handleCreate,
  changeFilter,
}) => (
  <Fragment>

    <Row formGroup>
      <Col>
        <Button
          type="button"
          onClick={handleCreate}
          color="success"
          shape="outline"
        >
          Vytvořit konfiguraci displejů
        </Button>
      </Col>
    </Row>

    <DisplaysConfigurationTable
      data={table.data}
      table={table}
      filter={table.filter}
      count={table.count}
      displaysConfigurationData={displaysConfigurationData}
      handleGetDisplaysConfiguration={handleGetDisplaysConfiguration}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      changeFilter={changeFilter}
    />

  </Fragment>
);


DisplaysConfiguration.propTypes = {
  table: object.isRequired,
  displaysConfigurationData: object.isRequired,
  changeFilter: func.isRequired,
  handleGetDisplaysConfiguration: func.isRequired,
  handleEdit: func.isRequired,
  handleDelete: func.isRequired,
  handleCreate: func.isRequired,
};


export default DisplaysConfiguration;
