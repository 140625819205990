import React, { Component, Fragment, } from 'react';
import {
  shape, bool, func, object,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';
import LanguagesSettingsForm from '../components/LanguageSettingsForm/LanguageSettingsForm';
import {
  changeValueAction,
  validateFormAction,
  getLanguagesSettingsAction,
  updateLanguagesSettingsAction,
} from '../actions/languagesSettings';
import { validateInput, validateWholeFormValidator, } from '../../../logic/forms/validation';
import { getSharedDataAction, } from '../../shared/actions/shared';
import { SHARED_DATA_TYPES, } from '../../../globals';


class LanguagesSettingsPage extends Component {
  componentDidMount() {
    const {
      getLanguagesSettings,
    } = this.props;
    getLanguagesSettings();
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);
    changeValue(name, value, validationError);
  }

  handleEdit = () => {
    const {
      updateLanguagesSettings,
      detail,
      updateData,
      validateForm,
    } = this.props;

    if (updateData.isLoading || !detail.isValid) return;

    const validation = validateWholeFormValidator(detail.form, detail.values);
    if (Object.keys(validation).length < 1) {
      updateLanguagesSettings(detail, this.handleEditSuccess);
    } else {
      validateForm(validation);
    }
  }


  render() {
    const {
      // data
      detail,
      getData,
      updateData,
      languagesData,
      // methods
      validateForm,
      getSharedData,
    } = this.props;
    return (
      <Fragment>
        <LanguagesSettingsForm
          form={detail.form}
          getData={getData}
          updateData={updateData}
          values={detail.values}
          onChangeValue={this.handleOnChangeValue}
          onEdit={this.handleEdit}
          isFormValid={validateForm}
          languagesData={languagesData}
          getLanguagesShared={() => getSharedData(SHARED_DATA_TYPES.LANGUAGES)}
        />
      </Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  const { languagesSettings, shared, } = state;
  return {
    getData: languagesSettings.getData,
    updateData: languagesSettings.updateData,
    detail: languagesSettings.detail,
    // shared
    languagesData: shared.languagesData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
  getLanguagesSettings: bindActionCreators(getLanguagesSettingsAction, dispatch),
  updateLanguagesSettings: bindActionCreators(updateLanguagesSettingsAction, dispatch),
  getSharedData: bindActionCreators(getSharedDataAction, dispatch),
});


LanguagesSettingsPage.propTypes = {
  getData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  updateData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  languagesData: object.isRequired,
  detail: object.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
  getLanguagesSettings: func.isRequired,
  updateLanguagesSettings: func.isRequired,
  getSharedData: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(LanguagesSettingsPage);
