import React, { Component, Fragment, } from 'react';
import {
  shape, number, bool, func, object, string, arrayOf,
} from 'prop-types';
import { Route, } from 'react-router-dom';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  changeCamerasFilterAction,
  deleteCameraAction,
  getCamerasAction,
} from '../actions/cameras';

import Cameras from '../components/CamerasTable/Cameras';
import CamerasEditModal from './CamerasEditModal';
import CamerasCreateModal from './CamerasCreateModal';

class CamerasPage extends Component {
  componentDidMount() {
    const { table, } = this.props;

    this.handleGetCameras(table.filter);
  }

  handleGetCameras = (newFilter) => {
    const { getCameras, table, } = this.props;

    getCameras(newFilter === undefined ? table.filter : newFilter);
  }

  handleEdit = (id) => {
    const { match, history, } = this.props;

    history.push(`${match.path}/edit/${id}`);
  }

  handleCreate = () => {
    const { match, history, } = this.props;

    history.push(`${match.path}/create`);
  }

  handleDelete = (item) => {
    const { deleteCamera, } = this.props;

    if (!item.isLoading) {
      deleteCamera(item.id);
    }
  }


  render() {
    const {
      match,
      table,
      camerasData,
      changeCamerasFilter,
    } = this.props;

    return (
      <Fragment>
        <Cameras
          table={table}
          camerasData={camerasData}
          handleGetCameras={this.handleGetCameras}
          handleEdit={this.handleEdit}
          handleDelete={this.handleDelete}
          handleCreate={this.handleCreate}
          changeFilter={changeCamerasFilter}
        />

        <Route
          exact
          path={`${match.path}/create`}
          render={
            (props) => <CamerasCreateModal {...props} refreshParent={this.handleGetCameras} /> // eslint-disable-line
          }
        />
        <Route
          exact
          path={`${match.path}/edit/:id`}
          render={
            (props) => <CamerasEditModal {...props} refreshParent={this.handleGetCameras} /> // eslint-disable-line
          }
        />
      </Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  const { cameras, } = state;
  return {
    camerasData: cameras.camerasData,
    table: cameras.table,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeCamerasFilter: bindActionCreators(changeCamerasFilterAction, dispatch),
  deleteCamera: bindActionCreators(deleteCameraAction, dispatch),
  getCameras: bindActionCreators(getCamerasAction, dispatch),
});


CamerasPage.propTypes = {
  table: shape({
    data: arrayOf(object).isRequired,
    count: number.isRequired,
    filter: object.isRequired,
  }).isRequired,
  camerasData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  changeCamerasFilter: func.isRequired,
  match: shape({
    path: string.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  getCameras: func.isRequired,
  deleteCamera: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(CamerasPage);
