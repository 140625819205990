import {
  DEVICES__GET_DISPLAY_EDITOR, DEVICES__UPDATE_DISPLAYS_IN_EDITOR,
} from '../actions/displayEditor';
import {
  PENDING, FULFILLED, REJECTED,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';
import { parseStringFromApi, } from '../../../logic/utils/string';

const parseDisplays = (displays) => {
  return displays.map((display) => {
    return {
      ...display,
      platforms: display.platforms.map((platform) => {
        return { platform: { id: platform.id, name: platform.platformTranslation.name, }, position: platform.DisplayHasPlatform.position, };
      }),
    };
  });
}

const initialState = {
  detailDisplayEditorData: {
    ...initRestData,
    isLoading: true,
  },
  updateDisplayEditorData: {
    ...initRestData,
  },
  detail: {
    id: null,
    isValid: true,
    values: {
      name: '',
      width: 1920,
      height: 1080,
      displays: [],
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;

  switch (type) {
    /**
     * GET Display Editor Settings
     */
    case `${DEVICES__GET_DISPLAY_EDITOR}__${PENDING}`: {
      return {
        ...state,
        detailDisplayEditorData: {
          ...state.detailDisplayEditorData,
          ...(handlePending()),
        },
        detail: {
          ...initialState.detail,
        },
      };
    }

    case `${DEVICES__GET_DISPLAY_EDITOR}__${FULFILLED}`: {
      try {
        const newValues = {
          ...state.detail.values,
          name: parseStringFromApi(payload.data.name),
          width: payload.data.width.toString(),
          height: payload.data.height.toString(),
          displays: parseDisplays(payload.data.displays),
        };
        return {
          ...state,
          detailDisplayEditorData: {
            ...state.detailDisplayEditorData,
            ...(handleFulfilled(payload)),
          },
          detail: {
            ...state.detail,
            id: payload.data.id,
            values: newValues,
          },
        };
      } catch (err) {
        return {
          ...state,
          detailDisplayEditorData: {
            ...state.detailDisplayEditorData,
            ...(handleRejected({
              statusCode: -1,
              messageInternal: err.message,
              message: {
                code: 'SOMETHING_HAPPEND',
                params: {},
              },
            })),
          },
        };
      }
    }

    case `${DEVICES__GET_DISPLAY_EDITOR}__${REJECTED}`: {
      return {
        ...state,
        detailDisplayEditorData: {
          ...state.detailDisplayEditorData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
     * UPDATE Displays in Display Controller
     */
    case `${DEVICES__UPDATE_DISPLAYS_IN_EDITOR}__${PENDING}`: {
      return {
        ...state,
        updateDisplayEditorData: {
          ...state.updateDisplayEditorData,
          ...(handlePending()),
        },
      };
    }

    case `${DEVICES__UPDATE_DISPLAYS_IN_EDITOR}__${FULFILLED}`: {
      return {
        ...state,
        updateDisplayEditorData: {
          ...state.updateDisplayEditorData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${DEVICES__UPDATE_DISPLAYS_IN_EDITOR}__${REJECTED}`: {
      return {
        ...state,
        updateDisplayEditorData: {
          ...state.updateDisplayEditorData,
          ...(handleRejected(payload)),
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
