import React, { Component, } from 'react';
import {
  shape, func, object,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';


import {
  getDisplayEditorAction, updateDisplaysInEditorAction,
} from '../actions/displayEditor';

import {
  getDisplaySubtypesSharedAction,
  getDisplayConfigurationSharedAction,
  getPlatformsSharedAction,
  getStandsSharedAction,
} from '../../shared/actions/shared';

import Modal from '../../../atoms/Modal/Modal';
import DisplayEditor from '../components/DisplayEditor/DisplayEditor';
import SegmentLaoding from '../../../components/SegmentLaoding/SegmentLaoding';
import SegmentError from '../../../components/SegmentError/SegmentError';

class DispEditorModal extends Component {
  componentDidMount() {
    this.handleGetDetail();
  }

  handleGetDetail = () => {
    const { match, getDisplayEditorDetail, getDisplaySubtypesShared, } = this.props;

    getDisplaySubtypesShared();
    getDisplayEditorDetail(match.params.id);
  }

  handleClose = () => {
    const { history, } = this.props;

    history.goBack();
  }

  handleEditSuccess = () => {
    const { refreshParent, } = this.props;

    refreshParent();
    this.handleClose();
  }

  handleEdit = (displays, deleted) => {
    const {
      updateDisplayEditorDetail,
      detail,
    } = this.props;

    if (updateDisplayEditorDetail.isLoading) return;

    console.log(displays);

    updateDisplayEditorDetail(detail.id, { displays, deleted, }, this.handleEditSuccess);
  }

  renderContent = () => {
    const {
      // data
      detailDisplayEditorData,
      updateDisplayEditorData,
      displaySubtypesSharedData,
      displayConfigurationSharedData,
      platformsSharedData,
      standsSharedData,
      detail,
      // methods
      getDisplaySubtypesShared,
      getDisplayConfigurationShared,
      getPlatformsShared,
      getStandsShared,
    } = this.props;

    if (detailDisplayEditorData.isLoading) {
      return (<SegmentLaoding />); // todo loading
    }

    if (detailDisplayEditorData.error) {
      return (
        <SegmentError
          message={detailDisplayEditorData.message}
          // todo refresh funcki?
        />
      );
    }

    return (
      <DisplayEditor
        getDisplaySubtypesShared={getDisplaySubtypesShared}
        getDisplayConfigurationShared={getDisplayConfigurationShared}
        updateDisplayEditorData={updateDisplayEditorData}
        displaySubtypesSharedData={displaySubtypesSharedData}
        displayConfigurationSharedData={displayConfigurationSharedData}
        standsSharedData={standsSharedData}
        getStandsShared={getStandsShared}
        getPlatformsShared={getPlatformsShared}
        platformsSharedData={platformsSharedData}
        editorSettings={{ width: detail.values.width, height: detail.values.height, }}
        displays={detail.values.displays}
        handleEdit={this.handleEdit}
        onClose={this.handleClose}
      />
    );
  }

  render() {
    return (
      <Modal
        isOpen
        onClose={this.handleClose}
        closeOnEsc
        size="FHD"
        title="Konfigurace displejů"
      >
        {this.renderContent()}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { displayEditor, shared, } = state;
  return {
    detailDisplayEditorData: displayEditor.detailDisplayEditorData,
    updateDisplayEditorData: displayEditor.updateDisplayEditorData,
    detail: displayEditor.detail,
    displaySubtypesSharedData: shared.displaySubtypesData,
    displayConfigurationSharedData: shared.displayConfigurationData,
    platformsSharedData: shared.platformsData,
    standsSharedData: shared.standsData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDisplayEditorDetail: bindActionCreators(getDisplayEditorAction, dispatch), // eslint-disable-line
  updateDisplayEditorDetail: bindActionCreators(updateDisplaysInEditorAction, dispatch), // eslint-disable-line
  getDisplaySubtypesShared: bindActionCreators(getDisplaySubtypesSharedAction, dispatch),
  getDisplayConfigurationShared: bindActionCreators(getDisplayConfigurationSharedAction, dispatch),
  getPlatformsShared: bindActionCreators(getPlatformsSharedAction, dispatch),
  getStandsShared: bindActionCreators(getStandsSharedAction, dispatch),
});

DispEditorModal.propTypes = {
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
  detailDisplayEditorData: object.isRequired,
  refreshParent: func.isRequired,
  detail: object.isRequired,
  match: object.isRequired,
  getDisplayEditorDetail: func.isRequired,
  updateDisplayEditorDetail: func.isRequired,
  updateDisplayEditorData: object.isRequired,
  getDisplaySubtypesShared: func.isRequired,
  getDisplayConfigurationShared: func.isRequired,
  displaySubtypesSharedData: object.isRequired,
  displayConfigurationSharedData: object.isRequired,
  platformsSharedData: object.isRequired,
  getPlatformsShared: func.isRequired,
  standsSharedData: object.isRequired,
  getStandsShared: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(DispEditorModal);
