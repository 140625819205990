import React, { Fragment, } from 'react';
import {
  shape, func, arrayOf, object, string, bool,
} from 'prop-types';

import { withLang, } from '../../../../logic/languages/withLang';
import { onlyNumbersInString, } from '../../../../logic/forms/utils';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Input from '../../../../atoms/Input/Input';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';
import MultiSelectListWrapper from '../../../../components/wrappers/MultiSelectListWrapper';


const PlatformsDetail = ({
  // data
  form,
  values,
  transportationTypesSharedData,
  // methods
  handleOnChangeValue,
  getTransportationTypesShared,
  langError,
}) => (
  <Fragment>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Název
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="text"
          value={values.name}
          status={form.name.status}
          onChange={(e) => handleOnChangeValue(form.name.name, e.target.value)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.name.status}
          msg={langError(form.name.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Označení (pro Displeje)
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="text"
          value={values.no}
          status={form.no.status}
          onChange={(e) => handleOnChangeValue(form.no.name, e.target.value)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.no.status}
          msg={langError(form.no.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Kapacita
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="number"
          value={values.capacity}
          status={form.capacity.status}
          onChange={
            (e) => handleOnChangeValue(form.capacity.name, onlyNumbersInString(e.target.value))
          }
          min="0"
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.capacity.status}
          msg={langError(form.capacity.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Typy přeprav
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <MultiSelectListWrapper
          onChange={(value) => handleOnChangeValue(form.transportationTypes.name, value)}
          options={transportationTypesSharedData.data}
          value={values.transportationTypes}
          isLoading={transportationTypesSharedData.isLoading}
          error={transportationTypesSharedData.error}
          fetchData={getTransportationTypesShared}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
        />
        {console.log(transportationTypesSharedData)}
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.transportationTypes.status}
          msg={langError(form.transportationTypes.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

  </Fragment>
);


PlatformsDetail.propTypes = {
  form: shape({
    name: shape({
      name: string.isRequired,
      type: string.isRequired,
      validation: object.isRequired,
      status: string.isRequired,
      info: string.isRequired,
      statusMsg: object.isRequired,
    }),
    no: shape({
      name: string.isRequired,
      type: string.isRequired,
      validation: object.isRequired,
      status: string.isRequired,
      info: string.isRequired,
      statusMsg: object.isRequired,
    }),
    capacity: shape({
      name: string.isRequired,
      type: string.isRequired,
      validation: object.isRequired,
      status: string.isRequired,
      info: string.isRequired,
      statusMsg: object.isRequired,
    }),
    transportationTypes: shape({
      name: string.isRequired,
      type: string.isRequired,
      validation: object.isRequired,
      status: string.isRequired,
      info: string.isRequired,
      statusMsg: object.isRequired,
    }),
  }).isRequired,
  values: shape({
    name: string,
    capacity: string,
    no: string,
    transportationTypes: arrayOf(object),
  }).isRequired,
  transportationTypesSharedData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
    data: object.isRequired,
  }).isRequired,
  getTransportationTypesShared: func.isRequired,
  handleOnChangeValue: func.isRequired,
  langError: func.isRequired,
};


export default withLang(PlatformsDetail);
