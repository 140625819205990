import {
  TRANSPORTATION_EDIT_FORMS__FILTER_CHANGED,
  TRANSPORTATION_EDIT_FORMS__GET_FORMS,
} from '../actions/transportationEditForms';
import {
  TRANSPORTATION_EDIT_FORMS__DELETE_DETAIL,
} from '../actions/transportationEditFormsDetail';
import {
  PENDING, FULFILLED, REJECTED,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';


const initialState = {
  formsData: {
    ...initRestData,
    isLoading: true,
  },
  table: {
    data: [],
    count: 0,
    filter: {
      offset: 0,
      limit: 10,
      sortBy: 'id',
      order: 'ASC',
      params: {
        id: '',
        name: '',
      },
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;
  switch (type) {
    /**
     * GET LIST
     */
    case `${TRANSPORTATION_EDIT_FORMS__GET_FORMS}__${PENDING}`: {
      return {
        ...state,
        formsData: {
          ...state.formsData,
          ...(handlePending()),
        },
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    case `${TRANSPORTATION_EDIT_FORMS__GET_FORMS}__${FULFILLED}`: {
      return {
        ...state,
        formsData: {
          ...state.formsData,
          ...(handleFulfilled(payload)),
        },
        table: {
          data: payload.forms,
          count: payload.count,
          filter: payload.filter,
        },
      };
    }

    case `${TRANSPORTATION_EDIT_FORMS__GET_FORMS}__${REJECTED}`: {
      return {
        ...state,
        formsData: {
          ...state.formsData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * DELETE Detail
     */
    case `${TRANSPORTATION_EDIT_FORMS__DELETE_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.filter((item) => (
            item.id !== payload.id
          )),
        },
      };
    }

    /**
     * Change Filter
     */
    case `${TRANSPORTATION_EDIT_FORMS__FILTER_CHANGED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
