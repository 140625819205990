import { 
  PENDING, FULFILLED, REJECTED, FORM_ERROR, FORM_SUCCESS,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


/*
 * GET Display configuration
 */
export const DEVICES__GET_DISPLAYS_CONFIGURATION = 'DEVICES__GET_DISPLAYS_CONFIGURATION';
export const getDisplaysConfigurationAction = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__GET_DISPLAYS_CONFIGURATION}__${PENDING}`,
      payload: filter,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/devices/display/configuration/filter`,
      data: filter,
    });
    dispatch({
      type: `${DEVICES__GET_DISPLAYS_CONFIGURATION}__${FULFILLED}`,
      payload: response.data,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${DEVICES__GET_DISPLAYS_CONFIGURATION}__${REJECTED}`,
      payload: parsedError,
    });
  }
};

/*
 * DELETE Display
 */
export const DEVICES__DELETE_DISPLAYS_CONFIGURATION = 'DEVICES__DELETE_DISPLAYS_CONFIGURATION';
export const deleteDisplayConfigurationAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__DELETE_DISPLAYS_CONFIGURATION}__${PENDING}`,
      payload: {
        id,
      },
    });

    const response = await api({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/devices/display/configuration?id=${id}`,
    });

    dispatch({
      type: `${DEVICES__DELETE_DISPLAYS_CONFIGURATION}__${FULFILLED}`,
      payload: {
        ...response,
        id,
        notif: {
          type: FORM_SUCCESS,
          title: 'Konfigurace úspěšně smazána',
        },
      },
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${DEVICES__DELETE_DISPLAYS_CONFIGURATION}__${REJECTED}`,
      payload: {
        ...parsedError,
        id,
        notif: {
          type: FORM_ERROR,
          title: 'Nepovedlo se smazat konfiguraci',
          showMessage: true,
        },
      },
    });
  }
};


/**
 * Filter Changed
 */
export const DEVICES__GET_DISPLAYS_CONFIGURATION_FILTER_CHANGED = 'DEVICES__GET_DISPLAYS_CONFIGURATION_FILTER_CHANGED';

export const changeFilterAction = (filter) => ({
  type: `${DEVICES__GET_DISPLAYS_CONFIGURATION_FILTER_CHANGED}`,
  payload: filter,
});
