import {
  DEVICES__GET_STOREKEEPER_DEVICES,
  DEVICES__STOREKEEPER_FILTER_CHANGED,
} from '../actions/storekeeper';
import {
  DEVICES__DELETE_STOREKEEPER_DEVICE_DETAIL,
} from '../actions/storekeeperDetail';
import { PENDING, FULFILLED, REJECTED, } from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';


const parseTableData = (data) => (
  data.map((item) => ({
    ...item,
    ...initRestData,
  }))
);


const initialState = {
  storekeeperDevicesData: {
    ...initRestData,
    isLoading: true,
  },
  table: {
    data: [],
    count: 0,
    filter: {
      offset: 0,
      limit: 10,
      sortBy: 'id',
      order: 'ASC',
      params: {
        id: '',
        name: '',
        deviceCode: '',
        synchronized: 'all',
        online: 'all',
      },
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;
  switch (type) {
    /**
     * GET Storekeeper
     */
    case `${DEVICES__GET_STOREKEEPER_DEVICES}__${PENDING}`: {
      return {
        ...state,
        storekeeperDevicesData: {
          ...state.storekeeperDevicesData,
          ...(handlePending()),
        },
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    case `${DEVICES__GET_STOREKEEPER_DEVICES}__${FULFILLED}`: {
      return {
        ...state,
        storekeeperDevicesData: {
          ...state.storekeeperDevicesData,
          ...(handleFulfilled(payload)),
        },
        table: {
          data: parseTableData(payload.devices),
          count: payload.count,
          filter: payload.filter,
        },
      };
    }

    case `${DEVICES__GET_STOREKEEPER_DEVICES}__${REJECTED}`: {
      return {
        ...state,
        storekeeperDevicesData: {
          ...state.storekeeperDevicesData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * DELETE Storekeeper Detail
     */
    case `${DEVICES__DELETE_STOREKEEPER_DEVICE_DETAIL}__${PENDING}`: {
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.map((item) => {
            if (item.id === payload.id) {
              return {
                ...item,
                ...(handlePending()),
              };
            }
            return item;
          }),
        },
      };
    }

    case `${DEVICES__DELETE_STOREKEEPER_DEVICE_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.filter((item) => item.id !== payload.id),
        },
      };
    }

    case `${DEVICES__DELETE_STOREKEEPER_DEVICE_DETAIL}__${REJECTED}`: {
      const { id, } = payload;

      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                ...(handleRejected(payload)),
              };
            }
            return item;
          }),
        },
      };
    }

    /**
     * Change Filter
     */
    case `${DEVICES__STOREKEEPER_FILTER_CHANGED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
