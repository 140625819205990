import React, { Fragment, } from 'react';
import {
  func, object, string, shape, oneOfType, node, arrayOf,
} from 'prop-types';
import { FORM_ERROR, } from '../../globals';

import { withLang, } from '../../logic/languages/withLang';
import FormRow from './FormRow';
import Row from '../../atoms/Row/Row';
import Col from '../../atoms/Col/Col';
import Input from '../../atoms/Input/Input';
import InfoMsg from '../../atoms/InfoMsg/InfoMsg';


const EditableGenFormView = ({
  // data
  data,
  children,
  msg,
  // methods
  langError,
  onChangeValue,
}) => {
  const { values, form, } = data;
  return (
    <Fragment>
      <FormRow
        label="Název formuláře"
      >
        <Input
          type="text"
          value={values.title}
          onChange={(e) => onChangeValue(form.title.name, e.target.value)}
          status={form.title.status}
        />
      </FormRow>
      {children}
      <Row style={{ paddingTop: '1rem', }}>
        <Col textAlign="right">
          <InfoMsg
            status={FORM_ERROR}
            msg={langError(msg)}
            showIcon
          />
        </Col>
      </Row>
    </Fragment>
  );
};


EditableGenFormView.propTypes = {
  data: shape({
    id: string.isRequired,
    values: object.isRequired,
    form: object.isRequired,
  }).isRequired,
  msg: object.isRequired,
  onChangeValue: func.isRequired,
  langError: func.isRequired,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};


export default withLang(EditableGenFormView);
