import React from 'react';
import styled from 'styled-components';

import Loader from '../Loader/Loader';


const StyledRTLoader = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  background: #0000004f;
  color: ${(p) => p.theme.white};
  font-size: 2rem;
  z-index: 1;
  align-items: center;
  justify-content: center;
`;


const RTLoader = () => (
  <StyledRTLoader>
    <Loader />
  </StyledRTLoader>
);


export default RTLoader;
