import React from 'react';
import { NavLink, } from 'react-router-dom';
import {
  string, arrayOf, node, number, oneOfType, bool,
} from 'prop-types';


const SidebarLink = ({
  level, children, ...navlinkProps
}) => (
  <NavLink
    {...navlinkProps}
    className={`sidebar--item sidebar--item-level-${level}`}
    activeClassName="sidebar--item-active"
  >
    {children}
  </NavLink>
);


SidebarLink.propTypes = {
  exact: bool,
  to: string.isRequired,
  level: number,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};

SidebarLink.defaultProps = {
  level: 0,
  exact: false,
};


export default SidebarLink;
