import React, { Component, } from 'react';
import {
  object, arrayOf, func, bool, string,
} from 'prop-types';

import MSLView from './MSLView';
import { FORM_DEFAULT, } from '../../globals';


class MultiSelectList extends Component {
  state = {
    selectLeft: {},
    selectRight: {},
  }

  componentDidMount() {
    this.handleFetchData();
  }

  handleFetchData = () => {
    const { fetchData, } = this.props;

    fetchData();
  }

  parseOptions = () => {
    const {
      options, value, getOptionValue,
    } = this.props;

    // 1. convert value to object
    const leftValue = {};
    for (let j = 0; j < value.length; j++) {
      if (value[j] !== null && value[j] !== undefined) {
        leftValue[getOptionValue(value[j])] = value[j];
      }
    }

    // 2. right data
    const rightValue = {};
    const keys = Object.keys(options);
    for (let i = 0; i < keys.length; i++) {
      if (!Object.prototype.hasOwnProperty.call(leftValue, keys[i])) {
        rightValue[keys[i]] = options[keys[i]];
      }
    }

    return {
      leftValue,
      rightValue,
    };
  }

  handleSelect = (newSelected, box) => {
    this.setState({
      [box]: newSelected,
    });
  }

  handleAdd = (data) => {
    const {
      isLoading, error, onChange, value, options,
    } = this.props;

    if (isLoading || error) return;
    if (Object.keys(data).length < 1) return;

    this.setState({
      selectLeft: data,
      selectRight: {},
    });

    onChange([
      ...value,
      ...Object.keys(data).map((key) => options[data[key]]),
    ]);
  }

  handleRemove = (data) => {
    const {
      isLoading, error, onChange, value, getOptionValue,
    } = this.props;

    if (isLoading || error) return;
    if (Object.keys(data).length < 1) return;

    this.setState({
      selectLeft: {},
      selectRight: data,
    });

    onChange(value.filter(
      (item) => !Object.prototype.hasOwnProperty.call(data, getOptionValue(item))
    ));
  }

  render() {
    const {
      // data
      isLoading,
      error,
      status,
      errorMessage,
      getDataMessage,
      placeholder,
      // methods
      getOptionLabel,
      getOptionValue,
    } = this.props;
    const { selectLeft, selectRight, } = this.state;

    const parsedData = this.parseOptions();

    return (
      <MSLView
        leftValue={parsedData.leftValue}
        rightValue={parsedData.rightValue}
        selectLeft={selectLeft}
        selectRight={selectRight}
        status={status}
        isLoading={isLoading}
        error={error}
        errorMessage={errorMessage}
        getDataMessage={getDataMessage}
        placeholder={placeholder}
        // actions
        handleFetchData={this.handleFetchData}
        getOptionLabel={getOptionLabel} // todo
        getOptionValue={getOptionValue} // todo
        handleSelect={this.handleSelect}
        handleRemove={this.handleRemove}
        handleAdd={this.handleAdd}
      />
    );
  }
}


MultiSelectList.propTypes = {
  onChange: func.isRequired,
  options: object.isRequired,
  value: arrayOf(object).isRequired, // eslint-disable-line
  getOptionLabel: func,
  getOptionValue: func,
  status: string,
  isLoading: bool,
  error: bool,
  errorMessage: string,
  getDataMessage: string,
  placeholder: string,
  fetchData: func,
};


MultiSelectList.defaultProps = {
  getOptionLabel: (option) => option.label,
  getOptionValue: (option) => option.value,
  isLoading: false,
  error: false,
  errorMessage: 'Error',
  getDataMessage: 'Get data',
  placeholder: 'Select',
  fetchData: () => {},
  status: FORM_DEFAULT,
};


export default MultiSelectList;
