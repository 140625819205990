import React from 'react';


export const withDnD = (Context) => (Comp) => {
  const Receiver = ({ ...props }) => (
    <Comp
      {...props}
    />
  );

  const ConnectedComponent = (props) => (
    <Context.Consumer>
      {(dnd) => (
        <Receiver
          {...props}
          dnd={dnd}
        />
      )}
    </Context.Consumer>
  );

  return ConnectedComponent;
};
