import React, { Component, } from 'react';
import {
  shape, bool, func, object,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  createNumericalSeriesDetailAction,
  validateFormAction,
  changeValueAction,
  refreshNumericalSeriesDetailAction,
} from '../actions/numericalSeriesDetail';
import { validateInput, validateWholeFormValidator, } from '../../../logic/forms/validation';
import Modal from '../../../atoms/Modal/Modal';
import NumericalSeriesDetail from '../components/NumericalSeriesDetail/NumericalSeriesDetail';
import NumericalSeriesCreate from '../components/NumericalSeriesCreate/NumericalSeriesCreate';

class NumericalSeriesCreateModal extends Component {
  componentDidMount() {
    const {
      refreshNumericalSeriesDetail,
    } = this.props;

    refreshNumericalSeriesDetail();
  }

  handleClose = () => {
    const { history, } = this.props;

    history.goBack();
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);

    changeValue(name, value, validationError);
  }

  handleCreate = async () => {
    const {
      createNumericalSeriesData,
      detail,
      validateForm,
      createNumericalSeriesDetail,
    } = this.props;

    if (createNumericalSeriesData.isLoading || !detail.isValid) return;

    const validation = validateWholeFormValidator(
      detail.form,
      detail.values,
    );

    if (Object.keys(validation).length < 1) {
      createNumericalSeriesDetail(detail, this.handleCreateSuccess);
    } else {
      validateForm(validation);
    }
  }


  handleCreateSuccess = () => {
    const { refreshParent, } = this.props;

    refreshParent();
    this.handleClose();
  }

  render() {
    const {
      createNumericalSeriesData,
      detail,
    } = this.props;

    return (
      <Modal
        isOpen
        onClose={this.handleClose}
        closeOnEsc
        size="MD"
        title="Vytvoření číselné řady"
      >
        <NumericalSeriesCreate
          // data
          createNumericalSeriesData={createNumericalSeriesData}
          isFormValid={detail.isValid}
          // methods
          handleClose={this.handleClose}
          handleCreate={this.handleCreate}
        >
          <NumericalSeriesDetail
            // data
            values={detail.values}
            form={detail.form}
            // methods
            handleClose={this.handleClose}
            handleOnChangeValue={this.handleOnChangeValue}
          />
        </NumericalSeriesCreate>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { numericalSeriesDetail, } = state;
  return {
    createNumericalSeriesData: numericalSeriesDetail.createNumericalSeriesData,
    detail: numericalSeriesDetail.detail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createNumericalSeriesDetail: bindActionCreators(createNumericalSeriesDetailAction, dispatch), // eslint-disable-line
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
  refreshNumericalSeriesDetail: bindActionCreators(refreshNumericalSeriesDetailAction, dispatch),
});


NumericalSeriesCreateModal.propTypes = {
  createNumericalSeriesData: object.isRequired,
  detail: shape({
    isValid: bool.isRequired,
    form: object.isRequired,
    values: object.isRequired,
  }).isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
  createNumericalSeriesDetail: func.isRequired,
  refreshNumericalSeriesDetail: func.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
  refreshParent: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(NumericalSeriesCreateModal);
