import React from 'react';
import ReactDOM from 'react-dom';
import {
  oneOfType, arrayOf, node, bool, string, func,
} from 'prop-types';

import ModalContent from './ModalContent';


const Modal = ({ children, isOpen, ...props }) => {
  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal((
    <ModalContent
      {...props}
    >
      {children}
    </ModalContent>
  ),
  document.body);
};


Modal.propTypes = {
  disableClickOutside: bool,
  disablePadding: bool,
  closeOnEsc: bool,
  size: string,
  color: string,
  title: string,
  onClose: func.isRequired,
  isOpen: bool.isRequired,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};


Modal.defaultProps = {
  disableClickOutside: true,
  disablePadding: false,
  closeOnEsc: false,
  size: 'LG',
  color: 'primary',
  title: '',
};


export default Modal;
