import { PENDING, FULFILLED, REJECTED, } from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';

/*
 * GET List
 */
export const TRANSPORTATION_EDIT_FORMS__GET_FORMS = 'TRANSPORTATION_EDIT_FORMS__GET_FORMS';
export const getFormsAction = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: `${TRANSPORTATION_EDIT_FORMS__GET_FORMS}__${PENDING}`,
      payload: filter,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/transportation_edit_forms/filter`,
      data: filter,
    });

    dispatch({
      type: `${TRANSPORTATION_EDIT_FORMS__GET_FORMS}__${FULFILLED}`,
      payload: response.data,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${TRANSPORTATION_EDIT_FORMS__GET_FORMS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/**
 * Filter Changed
 */
export const TRANSPORTATION_EDIT_FORMS__FILTER_CHANGED = 'TRANSPORTATION_EDIT_FORMS__FILTER_CHANGED';

export const changeFilterAction = (filter) => ({
  type: `${TRANSPORTATION_EDIT_FORMS__FILTER_CHANGED}`,
  payload: filter,
});
