import styled from 'styled-components';


/**
 * shape
 */
const BTN_CONTAINER = 'container';
const BTN_OUTLINE = 'outline';
const BTN_TEXT = 'text';

/**
 * color
 */
const BTN_COMMOM = 'common';
const BTN_PRIMARY = 'primary';
const BTN_SECONDARY = 'secondary';
const BTN_TERTIARY = 'tertiary';
const BTN_ERROR = 'error';
const BTN_WARNING = 'warning';
const BTN_SUCCESS = 'success';

/**
 * size
 */
const BTN_SM = 'sm';
// const BTN_MD = 'md';
const BTN_LG = 'lg';
const BTN_XS = 'xs';


const renderBtnContainer = ({
  color, bg, disabledCol, disabledBg, hover, active,
}) => (`
  background: ${bg};
  color: ${color};

  &:disabled {
    background: ${disabledBg};
    color: ${disabledCol};
  }
  
  &:hover:not([disabled]), &:focus:not([disabled]) {
    background: ${hover};
    color: ${color};
  }

  &:active:not([disabled]) {
    background: ${active};
    color: ${color};
  }
`);


const renderBtnOutline = ({
  main, hover, active, disabled,
}) => (`
  background: transparent;
  color: ${main};
  border-color: ${main}; 

  &:disabled {
    background: transparent;
    color: ${disabled};
    border-color: ${disabled}; 
  }
  
  &:hover:not([disabled]), &:focus:not([disabled]) {
    background: ${hover};
  }

  &:active:not([disabled]) {
    background: ${active};
  }
`);


const renderBtnText = ({
  main, hover, active, disabled,
}) => (`
  background: transparent;
  color: ${main};

  &:disabled {
    background: transparent;
    color: ${disabled};
  }
  
  &:hover:not([disabled]), &:focus:not([disabled]) {
    background: ${hover};
  }

  &:active:not([disabled]) {
    background: ${active};
  }
`);


const Button = styled.button`
  min-width: 80px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  padding: 0.375em 0.75em;
  vertical-align: middle;
  cursor: pointer;

  font: inherit;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  user-select: none;
  

  border-width: 2px;
  border-style: solid;
  border-color: transparent;


  transition:all 0.3s ease;


  /* aktivni */
  &:focus {
    outline: none;
  }

  &:hover {
    
  }

  &:disabled {
    cursor: default;
  }

  
  /* klik */
  &:active {

  }

  .btn--icon {
    padding-right: 0.25rem;
  }

  
  /* No min-width */
  ${(p) => p.noMinWidth && `
    min-width: unset;
  `}

  /* Large */
  ${(p) => (p.size && p.size === BTN_LG) && `
    font-size: 1.25rem;
  `}

  /* Small */
  ${(p) => (p.size && p.size === BTN_SM) && `
    font-size: 0.85rem;
  `}

  /*  Extra Small */
   ${(p) => (p.size && p.size === BTN_XS) && `
    font-size: 0.75rem;
  `}

  /* Small */
  ${(p) => p.width && `
    min-width: unset;
    width: ${p.width};
  `}



${(p) => {
    if (p.color !== BTN_COMMOM) return '';

    switch (p.shape) {
      case BTN_CONTAINER: {
        return renderBtnContainer({
          color: p.theme.white,
          bg: p.theme.grey.t700,
          disabledCol: p.theme.grey.t500,
          disabledBg: p.theme.grey.t300,
          hover: p.theme.grey.t800,
          active: p.theme.grey.t400,
        });
      }
      case BTN_OUTLINE: {
        return renderBtnOutline({
          main: p.theme.grey.t700,
          hover: p.theme.grey.t200,
          active: p.theme.grey.t400,
          disabled: p.theme.grey.t500,
        });
      }
      case BTN_TEXT: {
        return renderBtnText({
          main: p.theme.grey.t700,
          hover: p.theme.grey.t200,
          active: p.theme.grey.t400,
          disabled: p.theme.grey.t500,
        });
      }
      default: {
        return '';
      }
    }
  }}


  ${(p) => {
    if (p.color !== BTN_PRIMARY) return '';

    switch (p.shape) {
      case BTN_CONTAINER: {
        return renderBtnContainer({
          color: p.theme.white,
          bg: p.theme.primary.t700,
          disabledCol: p.theme.grey.t500,
          disabledBg: p.theme.grey.t300,
          hover: p.theme.primary.t800,
          active: p.theme.primary.t200,
        });
      }
      case BTN_OUTLINE: {
        return renderBtnOutline({
          main: p.theme.primary.t700,
          hover: p.theme.primary.t50,
          active: p.theme.primary.t200,
          disabled: p.theme.grey.t500,
        });
      }
      case BTN_TEXT: {
        return renderBtnText({
          main: p.theme.primary.t700,
          hover: p.theme.primary.t50,
          active: p.theme.primary.t200,
          disabled: p.theme.grey.t500,
        });
      }
      default: {
        return '';
      }
    }
  }}


  ${(p) => {
    if (p.color !== BTN_SECONDARY) return '';

    switch (p.shape) {
      case BTN_CONTAINER: {
        return renderBtnContainer({
          color: p.theme.white,
          bg: p.theme.secondary.t700,
          disabledCol: p.theme.grey.t500,
          disabledBg: p.theme.grey.t300,
          hover: p.theme.secondary.t800,
          active: p.theme.secondary.t200,
        });
      }
      case BTN_OUTLINE: {
        return renderBtnOutline({
          main: p.theme.secondary.t700,
          hover: p.theme.secondary.t50,
          active: p.theme.secondary.t200,
          disabled: p.theme.grey.t500,
        });
      }
      case BTN_TEXT: {
        return renderBtnText({
          main: p.theme.secondary.t700,
          hover: p.theme.secondary.t50,
          active: p.theme.secondary.t200,
          disabled: p.theme.grey.t500,
        });
      }
      default: {
        return '';
      }
    }
  }}


  ${(p) => {
    if (p.color !== BTN_TERTIARY) return '';

    switch (p.shape) {
      case BTN_CONTAINER: {
        return renderBtnContainer({
          color: p.theme.white,
          bg: p.theme.tertiary.t700,
          disabledCol: p.theme.grey.t500,
          disabledBg: p.theme.grey.t300,
          hover: p.theme.tertiary.t800,
          active: p.theme.tertiary.t200,
        });
      }
      case BTN_OUTLINE: {
        return renderBtnOutline({
          main: p.theme.tertiary.t700,
          hover: p.theme.tertiary.t50,
          active: p.theme.tertiary.t200,
          disabled: p.theme.grey.t500,
        });
      }
      case BTN_TEXT: {
        return renderBtnText({
          main: p.theme.tertiary.t700,
          hover: p.theme.tertiary.t50,
          active: p.theme.tertiary.t200,
          disabled: p.theme.grey.t500,
        });
      }
      default: {
        return '';
      }
    }
  }}


  ${(p) => {
    if (p.color !== BTN_ERROR) return '';

    switch (p.shape) {
      case BTN_CONTAINER: {
        return renderBtnContainer({
          color: p.theme.white,
          bg: p.theme.error.t600,
          disabledCol: p.theme.grey.t500,
          disabledBg: p.theme.grey.t300,
          hover: p.theme.error.t800,
          active: p.theme.error.t200,
        });
      }
      case BTN_OUTLINE: {
        return renderBtnOutline({
          main: p.theme.error.t700,
          hover: p.theme.error.t50,
          active: p.theme.error.t200,
          disabled: p.theme.grey.t500,
        });
      }
      case BTN_TEXT: {
        return renderBtnText({
          main: p.theme.error.t700,
          hover: p.theme.error.t50,
          active: p.theme.error.t200,
          disabled: p.theme.grey.t500,
        });
      }
      default: {
        return '';
      }
    }
  }}


  ${(p) => {
    if (p.color !== BTN_WARNING) return '';

    switch (p.shape) {
      case BTN_CONTAINER: {
        return renderBtnContainer({
          color: p.theme.white,
          bg: p.theme.warning.t700,
          disabledCol: p.theme.grey.t500,
          disabledBg: p.theme.grey.t300,
          hover: p.theme.warning.t800,
          active: p.theme.warning.t200,
        });
      }
      case BTN_OUTLINE: {
        return renderBtnOutline({
          main: p.theme.warning.t700,
          hover: p.theme.warning.t50,
          active: p.theme.warning.t200,
          disabled: p.theme.grey.t500,
        });
      }
      case BTN_TEXT: {
        return renderBtnText({
          main: p.theme.warning.t700,
          hover: p.theme.warning.t50,
          active: p.theme.warning.t200,
          disabled: p.theme.grey.t500,
        });
      }
      default: {
        return '';
      }
    }
  }}


  
  ${(p) => {
    if (p.color !== BTN_SUCCESS) return '';

    switch (p.shape) {
      case BTN_CONTAINER: {
        return renderBtnContainer({
          color: p.theme.white,
          bg: p.theme.success.t700,
          disabledCol: p.theme.grey.t500,
          disabledBg: p.theme.grey.t300,
          hover: p.theme.success.t800,
          active: p.theme.success.t200,
        });
      }
      case BTN_OUTLINE: {
        return renderBtnOutline({
          main: p.theme.success.t700,
          hover: p.theme.success.t50,
          active: p.theme.success.t200,
          disabled: p.theme.grey.t500,
        });
      }
      case BTN_TEXT: {
        return renderBtnText({
          main: p.theme.success.t700,
          hover: p.theme.success.t50,
          active: p.theme.success.t200,
          disabled: p.theme.grey.t500,
        });
      }
      default: {
        return '';
      }
    }
  }}
`;


Button.defaultProps = {
  shape: BTN_CONTAINER,
  color: BTN_COMMOM,
};

export default Button;
