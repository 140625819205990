import { AUTH__LOGIN, AUTH__INIT_USER, } from '../actions/auth';
import {
  FULFILLED,
  LOGIN,
  REJECTED,
  ERROR,
} from '../../../globals';
import { AUTH__REFRESH, AUTH__API, } from '../../../logic/restApi/restApi';


const initialState = {
  isLoggedIn: false,
  authority: null,
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;
  switch (type) {
    /**
     * Login
     */
    case `${AUTH__REFRESH}__${FULFILLED}`:
    case `${AUTH__INIT_USER}__${LOGIN}`:
    case `${AUTH__LOGIN}__${FULFILLED}`: {
      return {
        ...state,
        isLoggedIn: true,
        authority: payload.authority,
      };
    }


    /**
     * Logout
     */
    case `${AUTH__API}__${ERROR}`:
    case `${AUTH__REFRESH}__${REJECTED}`:
    case `${AUTH__REFRESH}__${ERROR}`: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
