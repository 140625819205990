import styled from 'styled-components';


const StyledHeader = styled.div`
  background: ${(p) => p.theme.white};
  border-bottom: 1px solid ${(p) => p.theme.grey.t300};
  box-shadow: 0 0.05rem 0.2rem rgba(0,0,0,.05);

  ul {
    display: flex;
    padding-left: 0;
    list-style: none;
  }


  /*
   * Grid
   */
  .header--container {
    display: flex;
    align-items: center;
    height: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .header--container-right {
    margin-left: auto;
  }


  /*
   * Header Item Round
   */
  .header--item {
    text-align: center;
    cursor: pointer;
  }

  .header--item-round {
    line-height: 3rem;
    height: 3rem;
    width: 3rem;
    border-radius: 3rem;
    font-size: 1rem;
    color: ${(p) => p.theme.secondary.t700};
  }

  .header--item:hover, .header--item-focused {
    background: ${(p) => p.theme.secondary.t50}
  }
`;


export default StyledHeader;
