import React from 'react';

import StyledSvg from './styles/StyledSvg';


const Logo = () => (
  <StyledSvg viewBox="0 0 612 612">
    <path d="m286 302h-60c-24.8125 0-45 20.1875-45 45v120c0 24.8125 20.1875 45 45 45h60c24.8125 0 45-20.1875 45-45v-120c0-24.8125-20.1875-45-45-45zm-4.394531 145.605469-15 15c-5.859375 5.859375-15.351563 5.859375-21.210938 0l-15-15c-5.859375-5.859375-5.859375-15.351563 0-21.210938s15.351563-5.859375 21.210938 0l4.394531 4.394531 4.394531-4.394531c13.945313-13.945312 35.269531 7.152344 21.210938 21.210938zm0-60c-5.859375 5.859375-15.351563 5.859375-21.210938 0l-4.394531-4.394531-4.394531 4.394531c-5.859375 5.859375-15.351563 5.859375-21.210938 0s-5.859375-15.351563 0-21.210938l15-15c5.859375-5.859375 15.351563-5.859375 21.210938 0l15 15c5.859375 5.859375 5.859375 15.351563 0 21.210938zm0 0" fill="#FFFFFF" />
    <path d="m0 497c0 8.289062 6.710938 15 15 15h46c8.289062 0 15-6.710938 15-15v-406h-76zm0 0" fill="#FFFFFF" />
    <path d="m497 0h-482c-8.289062 0-15 6.710938-15 15v46h512v-46c0-8.289062-6.710938-15-15-15zm0 0" fill="#FFFFFF" />
    <path d="m106 231.199219h300v39.800781h-300zm0 0" fill="#FFFFFF" />
    <path d="m106 161.199219h300v39.796875h-300zm0 0" fill="#FFFFFF" />
    <path d="m106 91h300v40h-300zm0 0" fill="#FFFFFF" />
    <path d="m436 271v226c0 8.289062 6.710938 15 15 15h46c8.289062 0 15-6.710938 15-15v-406h-76zm0 0" fill="#FFFFFF" />
  </StyledSvg>
);

export default Logo;
