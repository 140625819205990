import React from 'react';
import {
  shape, bool, number,
} from 'prop-types';

import Row from '../../atoms/Row/Row';
import Col from '../../atoms/Col/Col';
import CollisionFragment from './CollisionFragment';


const CollisionReservation = ({ collision, ...fragProps }) => {
  if (!collision.conflict) return null;

  return (
    <CollisionFragment {...fragProps}>
      <Row>
        <Col sidePadding className="collision--title">
          Rezervace
        </Col>
        <Col sidePadding>
          <span className="collision--text">Rezervovaných přeprav:</span>
          <span className="collision--data">{collision.data}</span>
        </Col>
      </Row>
    </CollisionFragment>
  );
};


CollisionReservation.propTypes = {
  collision: shape({
    conflict: bool.isRequired,
    data: number.isRequired,
  }).isRequired,
};


export default CollisionReservation;
