import React, { Component, } from 'react';
import {
  shape, func, string, arrayOf, bool, number, object,
} from 'prop-types';

import { withDnD, } from '../../../../logic/dnd/withDnD';
import PlanContext from './PlanContext';
import DropZone from './DropZone';

import StyledPlanItem from './styles/StyledPlanItem';


const enableDropPos = (pos, root, last, dragged) => {
  if (root !== dragged.root) return false;
  if (pos.length !== dragged.pos.length) return false;

  let i = 0;
  for (i; i < pos.length - 1; i++) {
    if (pos[i] !== dragged.pos[i]) return false;
  }

  return last === dragged.pos[i];
};


class PlanValuetem extends Component {
  state = {
    dropTop: false,
    dropBottom: false,
  }

  handleOnMouseDown = (e) => {
    const {
      dnd, item, root, position,
    } = this.props;

    e.preventDefault();
    e.stopPropagation();

    if (dnd.isActive) return;

    dnd.onDragStart({
      e,
      id: item.id,
      root,
      pos: position,
      payload: item.payload,
    });
  }

  handleOnDragOverDropPos = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  handleOnDragOver = (e) => {
    const {
      dnd, item, root,
    } = this.props;

    e.preventDefault();
    e.stopPropagation();

    if (!dnd.isActive) return;

    const target = e.currentTarget;
    const viewportOffset = target.getBoundingClientRect();

    const elHeigth = target.offsetHeight;
    const topPos = e.clientY - viewportOffset.top;

    // TOP position
    if (topPos < (elHeigth / 2)) {
      this.setState({
        dropTop: true,
        dropBottom: false,
      });

    // BOTTOM position
    } else {
      this.setState({
        dropTop: false,
        dropBottom: true,
      });
    }

    dnd.onDragOver({ root, id: item.id, });
  }

  handleOnMouseUp = (e) => {
    const { dropTop, } = this.state;
    const { root, dnd, position, } = this.props;

    e.stopPropagation();

    if (!dnd.isActive) return;

    // TOP position
    if (dropTop) {
      const newPos = [ ...position, ];
      dnd.onDrop({ root, pos: newPos, });

    // BOTTOM position
    } else {
      const newPos = position.slice(0, position.length - 1);
      newPos.push(position[position.length - 1] + 1);
      dnd.onDrop({ root, pos: newPos, });
    }
  }

  render() {
    const { dropTop, dropBottom, } = this.state;
    const {
      item,
      dnd,
      root,
      position,
      draggingOverList,
    } = this.props;

    const isDraggingOver = item.id === dnd.over.id;
    const isLocked = root === dnd.item.root && dnd.item.id === item.id;

    const showDropTop = isDraggingOver
      && dropTop
      && !isLocked
      && !enableDropPos(position, root, position[position.length - 1] - 1, dnd.item);
    const showDropBottom = isDraggingOver
      && dropBottom
      && !isLocked
      && !enableDropPos(position, root, position[position.length - 1] + 1, dnd.item);

    return (
      <li // eslint-disable-line
        onMouseDown={this.handleOnMouseDown}
        onMouseUp={this.handleOnMouseUp}
        onMouseMove={this.handleOnDragOverDropPos}
      >
        { showDropTop && (
          <DropZone />
        )}

        <StyledPlanItem
          role="none"
          onMouseMove={this.handleOnDragOver}
          draggingOverList={draggingOverList}
          isLocked={isLocked}
        >
          {item.payload.name}
        </StyledPlanItem>

        { showDropBottom && (
          <DropZone />
        )}
      </li>
    );
  }
}


PlanValuetem.propTypes = {
  item: shape({
    id: string.isRequired,
    payload: object.isRequired,
  }).isRequired,
  root: string.isRequired,
  position: arrayOf(number).isRequired,
  draggingOverList: bool.isRequired,
  dnd: shape({
    onDragStart: func.isRequired,
    onDragOver: func.isRequired,
    onDrop: func.isRequired,
    isActive: bool.isRequired,
    item: shape({
      id: string,
    }).isRequired,
  }).isRequired,
};


export default withDnD(PlanContext)(PlanValuetem);
