import { INPUT_TYPE, } from 'sofair-form-validation';

import { FORM_DEFAULT, FORM_ROOT, } from '../../globals';
import { initRestData, } from '../restApi/utils';
import { addPartLogic, } from './utils';


//
// Form Root
//
export const inputFormRoot = () => ({
  ...addPartLogic(FORM_ROOT),
  type: FORM_ROOT,
  form: {
    title: {
      type: INPUT_TYPE.TEXT,
      name: 'title',
      validation: {
        required: true,
        min: 2,
        max: 255,
        regex: null,
      },
      status: FORM_DEFAULT,
      statusMsg: {},
    },
  },
  values: {
    title: '',
  },
});


//
// All common inputs
//
export const inputCommonForm = {
  name: {
    type: INPUT_TYPE.TEXT,
    name: 'name',
    validation: {
      required: true,
      min: 2,
      max: 255,
      regex: {
        name: 'propertyName',
        expression: /^[a-zA-Z][a-zA-Z0-9]*/g,
        code: 'REGEX_PROPERTY_NAME',
        params: '',
      },
    },
    status: FORM_DEFAULT,
    statusMsg: {},
  },
  action: {
    type: INPUT_TYPE.SELECT,
    name: 'action',
    validation: {
      required: false,
      min: null,
      max: null,
      regex: null,
    },
    status: FORM_DEFAULT,
    statusMsg: {},
  },
  defaultValue: {
    type: INPUT_TYPE.TEXT,
    name: 'defaultValue',
    validation: {
      required: false,
      min: null,
      max: null,
      regex: null,
    },
    status: FORM_DEFAULT,
    statusMsg: {},
  },
  disabled: {
    type: INPUT_TYPE.CHECKBOX,
    name: 'disabled',
    validation: {
      required: false,
      min: null,
      max: null,
      regex: null,
    },
    status: FORM_DEFAULT,
    statusMsg: {},
  },
  required: {
    type: INPUT_TYPE.CHECKBOX,
    name: 'required',
    validation: {
      required: false,
      min: null,
      max: null,
      regex: null,
    },
    status: FORM_DEFAULT,
    statusMsg: {},
  },
  min: {
    type: INPUT_TYPE.NUMBER,
    name: 'min',
    validation: {
      required: false,
      min: 0,
      max: null,
      regex: null,
    },
    status: FORM_DEFAULT,
    statusMsg: {},
  },
  max: {
    type: INPUT_TYPE.NUMBER,
    name: 'max',
    validation: {
      required: false,
      min: 0,
      max: null,
      regex: null,
    },
    status: FORM_DEFAULT,
    statusMsg: {},
  },
  regex: {
    type: INPUT_TYPE.SELECT,
    name: 'regex',
    validation: {
      required: false,
      min: null,
      max: null,
      regex: null,
    },
    status: FORM_DEFAULT,
    statusMsg: {},
  },
  label: {
    type: INPUT_TYPE.TEXT,
    name: 'label',
    validation: {
      required: false,
      min: null,
      max: null,
      regex: null,
    },
    status: FORM_DEFAULT,
    statusMsg: {},
  },
  placeholder: {
    type: INPUT_TYPE.TEXT,
    name: 'placeholder',
    validation: {
      required: false,
      min: null,
      max: null,
      regex: null,
    },
    status: FORM_DEFAULT,
    statusMsg: {},
  },
  help: {
    type: INPUT_TYPE.TEXT,
    name: 'help',
    validation: {
      required: false,
      min: null,
      max: null,
      regex: null,
    },
    status: FORM_DEFAULT,
    statusMsg: {},
  },
  genericFormDialId: {
    type: INPUT_TYPE.SELECT,
    name: 'genericFormDialId',
    validation: {
      required: false,
      min: null,
      max: null,
      regex: null,
    },
    status: FORM_DEFAULT,
    statusMsg: {},
  },
};


//
// Common values
//
export const inputCommonValues = {
  name: '',
  defaultValue: '',
  disabled: false,
  validation: {
    required: false,
    min: null,
    max: null,
    regex: null,
  },
  translation: {
    label: '',
    placeholder: '',
    help: '',
  },
  genericFormDialId: null,
  action: null,
  map: null,
};


//
// Fieldset
//
export const fieldsetCommonProps = {
  form: {
    title: {
      type: INPUT_TYPE.TEXT,
      name: 'title',
      validation: {
        required: true,
        min: 2,
        max: 255,
        regex: null,
      },
      status: FORM_DEFAULT,
      statusMsg: {},
    },
  },
  values: {
    title: '',
  },
};


//
// Group
//
export const groupCommonProps = {
  form: {
    group: {
      type: INPUT_TYPE.SELECT,
      name: 'group',
      validation: {
        required: true,
        min: null,
        max: null,
        regex: null,
      },
      status: FORM_DEFAULT,
      statusMsg: {},
    },
  },
  values: {
    group: null,
  },
  inputsData: initRestData,
};
