import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION, REJECTED_COLLISON, FORM_SUCCESS, FORM_ERROR,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';
import { isSomethingRemovedFromArray, isArrayChanged, } from '../../../logic/utils/array';


const parseValues = (values) => ({
  name: values.name,
  platforms: values.platforms.map((item) => item.id),
  plan: values.plan.map((item) => item.payload.id),
  ticketId: values.ticketId !== null ? values.ticketId.id : null,
});


/**
 * Refresh data - on mount
 */
export const TRANSPORTATION_TYPES__REFRESH = 'TRANSPORTATION_TYPES__REFRESH';
export const refreshTransTypeDetailAction = () => ({
  type: `${TRANSPORTATION_TYPES__REFRESH}`,
});


/**
 * Change Value
 */
export const TRANSPORTATION_TYPES__CHANGE_VALUE_DETAIL = 'TRANSPORTATION_TYPES__CHANGE_VALUE_DETAIL';
export const changeValueAction = (name, value, validationError) => ({
  type: `${TRANSPORTATION_TYPES__CHANGE_VALUE_DETAIL}`,
  payload: {
    name,
    value,
    validationError,
  },
});


/**
 * Validate Form
 */
export const TRANSPORTATION_TYPES__VALIDATE_FORM_DETAIL = 'TRANSPORTATION_TYPES__VALIDATE_FORM_DETAIL';
export const validateFormAction = (error) => ({
  type: `${TRANSPORTATION_TYPES__VALIDATE_FORM_DETAIL}`,
  payload: error,
});


/*
 * GET Transportation Types Detail
 */
export const TRANSPORTATION_TYPES__GET_TRANSPORTATION_TYPES_DETAIL = 'TRANSPORTATION_TYPES__GET_TRANSPORTATION_TYPES_DETAIL';
export const getTransportationTypesDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${TRANSPORTATION_TYPES__GET_TRANSPORTATION_TYPES_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/transportation_types/detail?id=${id}`,
    });

    dispatch({
      type: `${TRANSPORTATION_TYPES__GET_TRANSPORTATION_TYPES_DETAIL}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${TRANSPORTATION_TYPES__GET_TRANSPORTATION_TYPES_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * CREATE Transportation Types Detail
 */
export const TRANSPORTATION_TYPES__CREATE_TRANSPORTATION_TYPES_DETAIL = 'TRANSPORTATION_TYPES__CREATE_TRANSPORTATION_TYPES_DETAIL';
export const createTransportationTypesDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${TRANSPORTATION_TYPES__CREATE_TRANSPORTATION_TYPES_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/transportation_types`,
      data: {
        values: parseValues(item.values),
      },
    });

    dispatch({
      type: `${TRANSPORTATION_TYPES__CREATE_TRANSPORTATION_TYPES_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Typ přepravy vytvořen',
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${TRANSPORTATION_TYPES__CREATE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED_VALIDATION}`,
        payload: parsedError,
      });
      return;
    }

    dispatch({
      type: `${TRANSPORTATION_TYPES__CREATE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * UPDATE Transportation Types Detail
 */
export const TRANSPORTATION_TYPES__UPDATE_TRANSPORTATION_TYPES_DETAIL = 'TRANSPORTATION_TYPES__UPDATE_TRANSPORTATION_TYPES_DETAIL';
export const updateTransportationTypesDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${TRANSPORTATION_TYPES__UPDATE_TRANSPORTATION_TYPES_DETAIL}__${PENDING}`,
    });

    const values = parseValues(item.values);
    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/transportation_types`,
      data: {
        id: item.id,
        values,
        platformsChanged: isSomethingRemovedFromArray(
          values.platforms, item.oldValues.platforms.map((plat) => plat.id)
        ),
        planChanged: isArrayChanged(values.plan, item.oldValues.plan.map((plan) => plan.id)),
        force: false,
      },
    });

    dispatch({
      type: `${TRANSPORTATION_TYPES__UPDATE_TRANSPORTATION_TYPES_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Typ přepravy upraven',
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${TRANSPORTATION_TYPES__UPDATE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED_VALIDATION}`,
        payload: parsedError,
      });
      return;
    }

    if (parsedError.statusCode === 428) {
      dispatch({
        type: `${TRANSPORTATION_TYPES__UPDATE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED_COLLISON}`,
        payload: {
          ...parsedError,
          message: {
            code: '',
            params: {},
          },
        },
      });
      return;
    }

    dispatch({
      type: `${TRANSPORTATION_TYPES__UPDATE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * FORCE UPDATE Transportation Types Detail
 */
export const TRANSPORTATION_TYPES__FORCE_UPDATE_TRANSPORTATION_TYPES_DETAIL = 'TRANSPORTATION_TYPES__FORCE_UPDATE_TRANSPORTATION_TYPES_DETAIL';
export const forceUpdateTransportationTypesDetailAction = (
  item, modalValues, redirectBack
) => async (dispatch) => {
  try {
    dispatch({
      type: `${TRANSPORTATION_TYPES__FORCE_UPDATE_TRANSPORTATION_TYPES_DETAIL}__${PENDING}`,
    });

    const values = parseValues(item.values);
    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/transportation_types`,
      data: {
        id: item.id,
        values,
        platformsChanged: isSomethingRemovedFromArray(
          values.platforms, item.oldValues.platforms.map((plat) => plat.id)
        ),
        planChanged: isArrayChanged(values.plan, item.oldValues.plan.map((plan) => plan.id)),
        force: true,
        forcePlatforms: modalValues.applyPlat,
        forcePlanNow: modalValues.applyPlan,
      },
    });

    dispatch({
      type: `${TRANSPORTATION_TYPES__FORCE_UPDATE_TRANSPORTATION_TYPES_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Typ přepravy upraven',
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${TRANSPORTATION_TYPES__FORCE_UPDATE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED_VALIDATION}`,
        payload: parsedError,
      });
      return;
    }

    dispatch({
      type: `${TRANSPORTATION_TYPES__FORCE_UPDATE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * DELETE Transportation Types Detail
 */
export const TRANSPORTATION_TYPES__DELETE_TRANSPORTATION_TYPES_DETAIL = 'TRANSPORTATION_TYPES__DELETE_TRANSPORTATION_TYPES_DETAIL';
export const deleteTransportationTypesDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${TRANSPORTATION_TYPES__DELETE_TRANSPORTATION_TYPES_DETAIL}__${PENDING}`,
      payload: {
        id,
      },
    });

    const response = await api({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/transportation_types?id=${id}&force=${false}`,
    });

    dispatch({
      type: `${TRANSPORTATION_TYPES__DELETE_TRANSPORTATION_TYPES_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        id,
        notif: {
          type: FORM_SUCCESS,
          title: 'Trans. Typ smazán',
        },
      },
    });
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 428) {
      dispatch({
        type: `${TRANSPORTATION_TYPES__DELETE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED_COLLISON}`,
        payload: {
          ...parsedError,
          id,
          message: {
            code: 'isUsed',
            params: {},
          },
        },
      });
      return;
    }

    dispatch({
      type: `${TRANSPORTATION_TYPES__DELETE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED}`,
      payload: {
        ...parsedError,
        id,
        notif: {
          type: FORM_ERROR,
          title: 'Nepovedlo se smazat Trans. Typ',
          showMessage: true,
        },
      },
    });
  }
};


/*
 * FORCE DELETE Transportation Types Detail
 */
export const TRANSPORTATION_TYPES__FORCE_DELETE_TRANSPORTATION_TYPES_DETAIL = 'TRANSPORTATION_TYPES__FORCE_DELETE_TRANSPORTATION_TYPES_DETAIL';
export const forceDeleteTransportationTypesDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${TRANSPORTATION_TYPES__FORCE_DELETE_TRANSPORTATION_TYPES_DETAIL}__${PENDING}`,
      payload: {
        id,
      },
    });

    const response = await api({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/transportation_types?id=${id}&force=${true}`,
    });

    dispatch({
      type: `${TRANSPORTATION_TYPES__FORCE_DELETE_TRANSPORTATION_TYPES_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        id,
        notif: {
          type: FORM_SUCCESS,
          title: 'Trans. Typ smazán',
        },
      },
    });
  } catch (err) {
    const parsedError = handleError(err);

    dispatch({
      type: `${TRANSPORTATION_TYPES__FORCE_DELETE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED}`,
      payload: {
        ...parsedError,
        id,
      },
    });
  }
};


/*
 * RESTORE Transportation Types Detail
 */
export const TRANSPORTATION_TYPES__RESTORE_TRANSPORTATION_TYPES_DETAIL = 'TRANSPORTATION_TYPES__RESTORE_TRANSPORTATION_TYPES_DETAIL';
export const restoreTransportationTypesDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${TRANSPORTATION_TYPES__RESTORE_TRANSPORTATION_TYPES_DETAIL}__${PENDING}`,
      payload: {
        id,
      },
    });

    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/transportation_types/restore`,
      data: {
        id,
      },
    });

    dispatch({
      type: `${TRANSPORTATION_TYPES__RESTORE_TRANSPORTATION_TYPES_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        id,
        notif: {
          type: FORM_SUCCESS,
          title: 'Trans. Typ obnoven',
        },
      },
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${TRANSPORTATION_TYPES__RESTORE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED}`,
      payload: {
        ...parsedError,
        id,
        notif: {
          type: FORM_ERROR,
          title: 'Nepovedlo se obnovit Trans. Typ',
          showMessage: true,
        },
      },
    });
  }
};


/**
 * Toggle Modal Force Update Transportation Types
 */
export const TRANSPORTATION_TYPES__TOGGLE_FORCE_UPDATE_TRANSPORTATION_TYPES_MODAL = 'TRANSPORTATION_TYPES__TOGGLE_FORCE_UPDATE_TRANSPORTATION_TYPES_MODAL';
export const toggleForceUpdateModalAction = () => ({
  type: `${TRANSPORTATION_TYPES__TOGGLE_FORCE_UPDATE_TRANSPORTATION_TYPES_MODAL}`,
});


/**
 * Change Value Modal Force Update Transportation Types
 */
export const TRANSPORTATION_TYPES__CHANGE_VALUE_FORCE_UPDATE_TRANSPORTATION_TYPES_MODAL = 'TRANSPORTATION_TYPES__CHANGE_VALUE_FORCE_UPDATE_TRANSPORTATION_TYPES_MODAL';
export const changeValueForceUpdateModalAction = (name, value) => ({
  type: `${TRANSPORTATION_TYPES__CHANGE_VALUE_FORCE_UPDATE_TRANSPORTATION_TYPES_MODAL}`,
  payload: {
    name,
    value,
  },
});


/**
 * Toggle Modal Force Delete Transportation Types
 */
export const TRANSPORTATION_TYPES__TOGGLE_FORCE_DELETE_TRANSPORTATION_TYPES_MODAL = 'TRANSPORTATION_TYPES__TOGGLE_FORCE_DELETE_TRANSPORTATION_TYPES_MODAL';
export const toggleForceDeleteModalAction = () => ({
  type: `${TRANSPORTATION_TYPES__TOGGLE_FORCE_DELETE_TRANSPORTATION_TYPES_MODAL}`,
});
