import React, { Fragment, } from 'react';
import {
  shape, bool, func, oneOfType, arrayOf, node, object,
} from 'prop-types';

import { withLang, } from '../../../../logic/languages/withLang';
import { FORM_ERROR, FORM_SUCCESS, } from '../../../../globals';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import ButtonGrp from '../../../../atoms/ButtonGrp/ButtonGrp';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';
import Add from '../../../../styles/icons/Add';


const CamerasCreate = ({
  children,
  isFormValid,
  createCamerasData,
  handleClose,
  handleCreate,
  langError,
}) => (
  <Fragment>

    {children}

    <Row>
      <Col textAlign="right">
        <InfoMsg
          status={createCamerasData.error ? FORM_ERROR : FORM_SUCCESS}
          msg={langError(createCamerasData.message)}
          showIcon
        />
      </Col>
      <Col textAlign="right">
        <ButtonGrp>
          <Button
            type="button"
            shape="outline"
            onClick={handleClose}
          >
            Zpět
          </Button>

          <ButtonLoader
            type="button"
            color="primary"
            onClick={handleCreate}
            disabled={!isFormValid}
            isLoading={createCamerasData.isLoading}
          >
            <span className="btn--icon">
              <Add />
            </span>
            Přidat
          </ButtonLoader>
        </ButtonGrp>
      </Col>
    </Row>

  </Fragment>
);


CamerasCreate.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
  isFormValid: bool.isRequired,
  createCamerasData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
    message: object.isRequired,
  }).isRequired,
  handleClose: func.isRequired,
  handleCreate: func.isRequired,
  langError: func.isRequired,
};


export default withLang(CamerasCreate);
