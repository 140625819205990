import styled from 'styled-components';


const StyledModalPanel = styled.div`
  width: 100%;
  text-align: right;
  background: ${(p) => `linear-gradient(90deg,${p.theme.tertiary.t900} 0,${p.theme.secondary.t600} 80%)`};
  align-items: center;
  display: flex;
  height: 2.5rem;
  color: ${(p) => p.theme.white};

  .modal-content--title {
    font-size: 1.1rem;
    font-weight: bold;
    padding-left: ${(p) => p.theme.common.padding}
  }


  .modal-content--exit {
    margin-left: auto;
    background: transparent;
    border: none; 
    line-height: 1.25rem;
    color: ${(p) => p.theme.white};

    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0%;
    margin-right: 0.625rem;
    padding: 0;

    cursor: pointer;
  }

  .modal-content--exit:focus, .modal-content--exit:hover {
    outline: none;
    color: ${(p) => p.theme.secondary.t600};
    background: ${(p) => p.theme.white};
  }


  /*
   * Color
   */
  ${(p) => (p.color && p.color === 'error') && `
    background: ${`linear-gradient(90deg,${p.theme.error.t900} 0,${p.theme.error.t700} 80%)`};
  `}
`;

export default StyledModalPanel;
