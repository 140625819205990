import React, { Component, Fragment, } from 'react';
import {
  object, func, bool, string,
} from 'prop-types';
import { FocusWithin, } from 'react-focus-within';

import StyledMSLList from './styles/StyledMSLList';
import StyledItem from './styles/StyledItem';
import Loader from '../Loader/Loader';
import Button from '../Button/Button';


class MSBBoxView extends Component {
  renderItems = () => {
    const {
      data,
      selection,
      isLoading,
      isFocused,
      error,
      errorMessage,
      getDataMessage,
      mainList,
      placeholder,
      // actions
      handleFetchData,
      getOptionValue,
      getOptionLabel,
      clickHandler,
      onFocus,
      onBlur,
      onMouseDown,
    } = this.props;

    if (isLoading) {
      return (
        <div className="msl--info-container msl--loader">
          <Loader />
        </div>
      );
    }

    if (error) {
      return (
        <div className="msl--info-container msl--error">
          <div>
            <div>{errorMessage}</div>
            <Button
              shape="outline"
              color="error"
              size="sm"
              onClick={handleFetchData}
            >
              {getDataMessage}
            </Button>
          </div>
        </div>
      );
    }

    const keys = Object.keys(data);

    if (mainList && keys.length < 1) {
      return (
        <div className="msl--info-container msl--placeholder">
          <span>{placeholder}</span>
        </div>
      );
    }

    return (
      <Fragment>
        { keys.map((key) => {
          const id = getOptionValue(data[key]);
          const name = getOptionLabel(data[key]);
          const isSelected = Object.prototype.hasOwnProperty.call(selection, id);

          return (
            <StyledItem
              onFocus={onFocus}
              onBlur={onBlur}
              onMouseDown={onMouseDown}
              key={id}
              // focusable props
              tabIndex="-1"
              isSelected={isSelected}
              isFocused={isFocused}
              onClick={(e) => clickHandler(e, id)}
            >
              {name}
            </StyledItem>
          );
        })}
      </Fragment>
    );
  }

  render() {
    const {
      isFocused,
      status,
      mainList,
      // actions
      onFocus,
      onBlur,
      onMouseDown,
    } = this.props;

    return (
      <StyledMSLList
        mainList={mainList}
        isFocused={isFocused}
        status={status}
        // focusable props
        tabIndex="-1"
        onFocus={onFocus}
        onBlur={onBlur}
        onMouseDown={onMouseDown}
      >
        {this.renderItems()}
      </StyledMSLList>
    );
  }
}


MSBBoxView.propTypes = {
  data: object.isRequired,
  selection: object.isRequired,
  getOptionLabel: func.isRequired,
  getOptionValue: func.isRequired,
  clickHandler: func.isRequired,
  isLoading: bool.isRequired,
  error: bool.isRequired,
  errorMessage: string.isRequired,
  placeholder: string.isRequired,
  getDataMessage: string.isRequired,
  status: string.isRequired,
  mainList: bool.isRequired,
  isFocused: bool.isRequired,
  handleFetchData: func.isRequired,
  onFocus: func.isRequired,
  onBlur: func.isRequired,
  onMouseDown: func.isRequired,
};


const MSBBoxViewWithFocus = (props) => (
  <FocusWithin>
    {({ focusProps, isFocused, }) => (
      <MSBBoxView {...props} isFocused={isFocused} {...focusProps} />
    )}
  </FocusWithin>
);


export default MSBBoxViewWithFocus;
