import React from 'react';
import styled from 'styled-components';
import {
  bool, func, shape,
} from 'prop-types';

import { withLang, } from '../../logic/languages/withLang';
import Error from '../../styles/icons/Error';
import ButtonLoader from '../Button/ButtonLoader';


const StyledRTError = styled.div`
  height: 7rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.error.t800};
  background: ${(p) => p.theme.error.t50};

  .rt--error-icon {
    font-size: 1.5rem;
    margin-right: 4px;
  }

  .rt--error-text {
    margin-top: 0.25rem;
  }

  .rt--error-btn {
    text-align: center;
    margin-top: 5px;
  }
`;


const RTError = ({ data, refresh, langError, }) => (
  <StyledRTError>
    <div>
      <div>
        <span className="rt--error-icon">
          <Error />
        </span>
        <span className="rt--error-text">
          {langError(data.message)}
        </span>
      </div>
      <div className="rt--error-btn">
        <ButtonLoader
          onClick={refresh}
          isLoading={data.isLoading}
          size="sm"
          shape="outline"
          color="error"
        >
          Refresh
        </ButtonLoader>
      </div>
    </div>
  </StyledRTError>
);


RTError.propTypes = {
  data: shape({
    isLoading: bool.isRequired,
  }).isRequired,
  refresh: func.isRequired,
  langError: func.isRequired,
};


export default withLang(RTError);
