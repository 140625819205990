import React, { Component, } from 'react';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';
import {
  shape, object, func, bool, string,
} from 'prop-types';
import { Redirect, } from 'react-router-dom';

import LoginView from '../components/LoginView/LoginView';
import { changeLoginFormAction, } from '../actions/login';
import { loginAction, } from '../actions/auth';


class LoginPage extends Component {
  handleChangeForm = (e) => {
    const { changeLoginForm, } = this.props;
    changeLoginForm({
      name: e.target.name,
      value: e.target.value,
      errorCode: null,
    });
  }

  handleLogin = () => {
    const { loginForm, handleLoginAction, } = this.props;

    handleLoginAction({
      username: loginForm.username.value,
      password: loginForm.password.value,
    });
  }

  render() {
    const { isLoggedIn, loginForm, location, } = this.props;
    const { from, } = location.state || { from: { pathname: '/', }, };

    if (isLoggedIn) {
      return <Redirect to={from} />;
    }

    return (
      <LoginView
        loginForm={loginForm}
        actions={{
          handleChangeForm: this.handleChangeForm,
          handleLogin: this.handleLogin,
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { login, user, } = state;
  return {
    isLoggedIn: user.isLoggedIn,
    loginForm: login.loginForm,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLoginForm: bindActionCreators(changeLoginFormAction, dispatch),
  handleLoginAction: bindActionCreators(loginAction, dispatch),
});

LoginPage.propTypes = {
  isLoggedIn: bool.isRequired,
  loginForm: shape({
    username: shape({
      value: string.isRequired,
      errorCode: string,
    }).isRequired,
    password: shape({
      value: string.isRequired,
      errorCode: string,
    }).isRequired,
  }).isRequired,
  location: object, // eslint-disable-line react/forbid-prop-types
  changeLoginForm: func.isRequired,
  handleLoginAction: func.isRequired,
};

LoginPage.defaultProps = {
  location: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
