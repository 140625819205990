import React, { Component, Fragment, } from 'react';
import {
  shape, number, bool, func, object, string, arrayOf,
} from 'prop-types';
import { Route, } from 'react-router-dom';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  restorePlatformssDetailAction,
  deletePlatformsDetailAction,
  forceDeletePlatformsDetailAction,
  toggleForceDeleteModalAction,
} from '../actions/platformsDetail';
import {
  getPlatformsAction,
  changeFilterAction,
} from '../actions/platforms';
import Platforms from '../components/Platforms/Platforms';
import PlatformsCreateModal from './PlatformsCreateModal';
import PlatformsEditModal from './PlatformsEditModal';
import PlatformsDeleteCollisionModal from '../components/PlatformsDeleteCollisionModal/PlatformsDeleteCollisionModal';


class PlatformsPage extends Component {
  componentDidMount() {
    const { table, } = this.props;

    this.handleGetPlatforms(table.filter);
  }

  handleGetPlatforms = (newFilter) => {
    const { getPlatforms, table, } = this.props;

    getPlatforms(newFilter === undefined ? table.filter : newFilter);
  }

  handleEdit = (id) => {
    const { match, history, } = this.props;

    history.push(`${match.path}/edit/${id}`);
  }

  handleCreate = () => {
    const { match, history, } = this.props;

    history.push(`${match.path}/create`);
  }

  handleRestore = (item) => {
    const { restorePlatformsDetail, } = this.props;

    if (!item.isLoading) {
      restorePlatformsDetail(item.id);
    }
  }

  handleDelete = (item) => {
    const { deletePlatformsDetail, } = this.props;

    if (!item.isLoading) {
      deletePlatformsDetail(item.id);
    }
  }

  handleForceDelete = () => {
    const { forceDeletePlatformsDetail, forceDeleteModal, forceDeleteData, } = this.props;

    if (!forceDeleteData.isLoading) {
      forceDeletePlatformsDetail(forceDeleteModal.id);
    }
  }

  render() {
    const {
      match,
      table,
      platformsData,
      forceDeleteModal,
      forceDeleteData,
      changeFilter,
      toggleForceDeleteModal,
    } = this.props;

    return (
      <Fragment>

        <Platforms
          table={table}
          platformsData={platformsData}
          handleGetPlatforms={this.handleGetPlatforms}
          handleRestore={this.handleRestore}
          handleEdit={this.handleEdit}
          handleDelete={this.handleDelete}
          handleCreate={this.handleCreate}
          changeFilter={changeFilter}
        />

        <Route
          exact
          path={`${match.path}/create`}
          render={
            (props) => <PlatformsCreateModal {...props} refreshParent={this.handleGetPlatforms} />
          }
        />
        <Route
          exact
          path={`${match.path}/edit/:id`}
          render={
            (props) => <PlatformsEditModal {...props} refreshParent={this.handleGetPlatforms} />
          }
        />

        <PlatformsDeleteCollisionModal
          modalData={forceDeleteModal}
          actionData={forceDeleteData}
          onClose={() => toggleForceDeleteModal(false)}
          action={this.handleForceDelete}
        />

      </Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  const { platforms, platformsDetail, } = state;
  return {
    platformsData: platforms.platformsData,
    table: platforms.table,
    forceDeleteModal: platformsDetail.forceDeleteModal,
    forceDeleteData: platformsDetail.forceDeleteData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPlatforms: bindActionCreators(getPlatformsAction, dispatch),
  restorePlatformsDetail: bindActionCreators(restorePlatformssDetailAction, dispatch),
  deletePlatformsDetail: bindActionCreators(deletePlatformsDetailAction, dispatch),
  changeFilter: bindActionCreators(changeFilterAction, dispatch),
  forceDeletePlatformsDetail: bindActionCreators(forceDeletePlatformsDetailAction, dispatch),
  toggleForceDeleteModal: bindActionCreators(toggleForceDeleteModalAction, dispatch),
});


PlatformsPage.propTypes = {
  table: shape({
    data: arrayOf(object).isRequired,
    count: number.isRequired,
    filter: object.isRequired,
  }).isRequired,
  platformsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  match: shape({
    path: string.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  forceDeleteModal: shape({
    id: number,
    isOpen: bool.isRequired,
    reservations: object.isRequired,
    transports: object.isRequired,
  }).isRequired,
  forceDeleteData: object.isRequired,
  changeFilter: func.isRequired,
  getPlatforms: func.isRequired,
  restorePlatformsDetail: func.isRequired,
  deletePlatformsDetail: func.isRequired,
  forceDeletePlatformsDetail: func.isRequired,
  toggleForceDeleteModal: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(PlatformsPage);
