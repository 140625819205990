import React, { Component, Fragment, } from 'react';
import {
  shape, number, bool, func, object, string, arrayOf,
} from 'prop-types';
import { Route, } from 'react-router-dom';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  deleteControllerDetailAction,
} from '../actions/controllersDetail';
import { getControllersAction, changeControllersFilterAction, } from '../actions/controllers';
import Controllers from '../components/Controllers/Controllers';
import ControllersEditModal from './ControllersEditModal';
import ControllersRegisterModal from './ControllersRegisterModal';
import DispEditorModal from './DispEditorModal';

class ControllersPage extends Component {
  componentDidMount() {
    const { table, } = this.props;

    this.handleGetControllers(table.filter);
  }

  handleGetControllers = (newFilter) => {
    const { getControllers, table, } = this.props;

    getControllers(newFilter === undefined ? table.filter : newFilter);
  }

  handleEdit = (id) => {
    const { match, history, } = this.props;

    history.push(`${match.path}/edit/${id}`);
  }

  handleRegister = (id) => {
    const { match, history, } = this.props;

    history.push(`${match.path}/register/${id}`);
  }

  handleDelete = (item) => {
    const { deleteControllerDetail, } = this.props;

    if (!item.isLoading) {
      deleteControllerDetail(item.id);
    }
  }

  handleDispEditor = (id) => {
    const { match, history, } = this.props;

    history.push(`${match.path}/disp-editor/${id}`);
  }

  render() {
    const {
      match,
      table,
      controllersData,
      changeFilter,
    } = this.props;

    return (
      <Fragment>
        <Controllers
          table={table}
          controllersData={controllersData}
          onGetControllers={this.handleGetControllers}
          onEdit={this.handleEdit}
          onRegister={this.handleRegister}
          onDispEditor={this.handleDispEditor}
          onDelete={this.handleDelete}
          changeFilter={changeFilter}
        />
        <Route
          exact
          path={`${match.path}/edit/:id`}
          component={
            (props) => <ControllersEditModal {...props} refreshParent={this.handleGetControllers} />
          }
        />
        <Route
          exact
          path={`${match.path}/register/:id`}
          component={
            (props) => <ControllersRegisterModal {...props} refreshParent={this.handleGetControllers} />  // eslint-disable-line
          }
        />
        <Route
          exact
          path={`${match.path}/disp-editor/:id`}
          component={
            (props) => <DispEditorModal {...props} refreshParent={this.handleGetControllers} />
          }
        />
      </Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  const { controllers, } = state;
  return {
    controllersData: controllers.controllersData,
    table: controllers.table,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getControllers: bindActionCreators(getControllersAction, dispatch),
  changeFilter: bindActionCreators(changeControllersFilterAction, dispatch),
  deleteControllerDetail: bindActionCreators(deleteControllerDetailAction, dispatch),
});


ControllersPage.propTypes = {
  table: shape({
    data: arrayOf(object).isRequired,
    count: number.isRequired,
    filter: object.isRequired,
  }).isRequired,
  controllersData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  changeFilter: func.isRequired,
  getControllers: func.isRequired,
  match: shape({
    path: string.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  deleteControllerDetail: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(ControllersPage);
