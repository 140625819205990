import React, { Fragment, } from 'react';
import { Switch, Route, } from 'react-router-dom';

import Heading from '../../../atoms/Heading/Heading';
import TabsLinkPanel from '../../../atoms/TabsLink/TabsLinkPanel';
import TabLink from '../../../atoms/TabsLink/TabLink';
// import DisplaysPage from './DisplaysPage';
import DisplaysConfigurationPage from './DisplaysConfigurationPage';
import ControllersPage from './ControllersPage';

const DisplaysLayout = () => (
  <Fragment>
    <Heading h4>
      Displeje
    </Heading>

    <TabsLinkPanel>
      <TabLink
        exact={false}
        to="/devices/displays/controllers"
      >
        Disp. Kontroléry
      </TabLink>
     {/* <TabLink
        exact={false}
        to="/devices/displays/main"
      >
        Displeje
      </TabLink> */}
      <TabLink
        exact={false}
        to="/devices/displays/configuration"
      >
        Konfigurace displejů
      </TabLink>
    </TabsLinkPanel>

    <Switch>
      {/* <Route path="/devices/displays/main" component={DisplaysPage} /> */}
      <Route path="/devices/displays/controllers" component={ControllersPage} />
      <Route path="/devices/displays/configuration" component={DisplaysConfigurationPage} />
    </Switch>
  </Fragment>
);


export default DisplaysLayout;
