import {
  PENDING, FULFILLED, REJECTED, FORM_SUCCESS, FORM_ERROR,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';

const parseColumnsArray = (array) => {
  const newArray = [];
  for (let i = 0; i < array.length; i++) {
    if (array[i] !== null) {
      newArray.push(array[i]);
    }
  }
  return newArray;
};

/**
 * Refresh data - on mount
 */
export const DEVICES__DISPLAYS_CONFIGURATION_REFRESH = 'DEVICES__DISPLAYS_CONFIGURATION_REFRESH';

export const refreshDisplaysConfigurationDetailAction = () => ({
  type: `${DEVICES__DISPLAYS_CONFIGURATION_REFRESH}`,
});


/**
 * Change Value
 */
export const DEVICES__DISPLAYS_CONFIGURATION_CHANGE_VALUE_DETAIL = 'DEVICES__DISPLAYS_CONFIGURATION_CHANGE_VALUE_DETAIL';

export const changeValueAction = (name, value, validationError) => ({
  type: `${DEVICES__DISPLAYS_CONFIGURATION_CHANGE_VALUE_DETAIL}`,
  payload: {
    name,
    value,
    validationError,
  },
});

/**
 * Add column
 */
export const DEVICES__DISPLAYS_CONFIGURATION_ADD_COLUMN_DETAIL = 'DEVICES__DISPLAYS_CONFIGURATION_ADD_COLUMN_DETAIL';

export const addColumnAction = () => ({
  type: `${DEVICES__DISPLAYS_CONFIGURATION_ADD_COLUMN_DETAIL}`,
  payload: {},
});

/**
 * Change value in column
 */
export const DEVICES__DISPLAYS_CONFIGURATION_CHANGE_VALUE_IN_COLUMN_DETAIL = 'DEVICES__DISPLAYS_CONFIGURATION_CHANGE_VALUE_IN_COLUMN_DETAIL';

export const changeValueInColumnAction = (parameter, value, index) => ({
  type: `${DEVICES__DISPLAYS_CONFIGURATION_CHANGE_VALUE_IN_COLUMN_DETAIL}`,
  payload: {
    parameter,
    value,
    index,
  },
});

/**
 * Remove column
 */
export const DEVICES__DISPLAYS_CONFIGURATION_REMOVE_COLUMN_DETAIL = 'DEVICES__DISPLAYS_CONFIGURATION_REMOVE_COLUMN_DETAIL';

export const removeColumnAction = (index) => ({
  type: `${DEVICES__DISPLAYS_CONFIGURATION_REMOVE_COLUMN_DETAIL}`,
  payload: {
    index,
  },
});

/**
 * Validate Form
 */
export const DEVICES__DISPLAYS_CONFIGURATION_VALIDATE_FORM_DETAIL = 'DEVICES__DISPLAYS_CONFIGURATION_VALIDATE_FORM_DETAIL';

export const validateFormAction = (error) => ({
  type: `${DEVICES__DISPLAYS_CONFIGURATION_VALIDATE_FORM_DETAIL}`,
  payload: error,
});


/*
 * GET Display Configuration Detail
 */
export const DEVICES__GET_DISPLAYS_CONFIGURATION_DETAIL = 'DEVICES__GET_DISPLAYS_CONFIGURATION_DETAIL';
export const getDisplaysConfigurationDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__GET_DISPLAYS_CONFIGURATION_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/devices/display/configuration/detail?id=${id}`,
    });

    dispatch({
      type: `${DEVICES__GET_DISPLAYS_CONFIGURATION_DETAIL}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${DEVICES__GET_DISPLAYS_CONFIGURATION_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * CREATE Displays Configuration Detail
 */
export const DEVICES__CREATE_DISPLAYS_CONFIGURATION_DETAIL = 'DEVICES__CREATE_DISPLAYS_CONFIGURATION_DETAIL';
export const createDisplaysConfigurationDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__CREATE_DISPLAYS_CONFIGURATION_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/devices/display/configuration`,
      data: {
        values: {
          name: item.values.name,
          type: item.values.type,
        },
        configuration: {
          noRows: parseInt(item.values.noRows, 10),
          fontSize: parseInt(item.values.fontSize, 10),
          columns: parseColumnsArray(item.values.columns),
          lineDivider: item.values.lineDivider,
          dividerHeight: parseInt(item.values.dividerHeight, 10),
          dividerColor: item.values.dividerColor,
          hideTransportationAfterTimeout: item.values.hideTransportationAfterTimeout,
          hideTimeout: parseInt(item.values.hideTimeout, 10),
        },
      },
    });

    dispatch({
      type: `${DEVICES__CREATE_DISPLAYS_CONFIGURATION_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Konfigurace displejů vytvořena',
          showMessage: true,
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    dispatch({
      type: `${DEVICES__CREATE_DISPLAYS_CONFIGURATION_DETAIL}__${REJECTED}`,
      payload: {
        ...parsedError,
        notif: {
          type: FORM_ERROR,
          title: 'Konfigurace displejů nebyla vytvořena',
          showMessage: true,
        },
      },
    });
  }
};


/*
 * UPDATE Displays Configuration Detail
 */
export const DEVICES__UPDATE_DISPLAYS_CONFIGURATION_DETAIL = 'DEVICES__UPDATE_DISPLAYS_CONFIGURATION_DETAIL';
export const updateDisplaysConfigurationDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__UPDATE_DISPLAYS_CONFIGURATION_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/devices/display/configuration`,
      data: {
        id: item.id,
        values: {
          name: item.values.name,
          type: item.values.type,
        },
        configuration: {
          noRows: parseInt(item.values.noRows, 10),
          fontSize: parseInt(item.values.fontSize, 10),
          columns: parseColumnsArray(item.values.columns),
          lineDivider: item.values.lineDivider,
          dividerHeight: parseInt(item.values.dividerHeight, 10),
          dividerColor: item.values.dividerColor,
          hideTransportationAfterTimeout: item.values.hideTransportationAfterTimeout,
          hideTimeout: parseInt(item.values.hideTimeout, 10),
        },
      },
    });

    dispatch({
      type: `${DEVICES__UPDATE_DISPLAYS_CONFIGURATION_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Konfigurace displejů upravena',
          showMessage: true,
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    dispatch({
      type: `${DEVICES__UPDATE_DISPLAYS_CONFIGURATION_DETAIL}__${REJECTED}`,
      payload: {
        ...parsedError,
        notif: {
          type: FORM_ERROR,
          title: 'Nepovedlo se upravit konfiguraci',
          showMessage: true,
        },
      },
    });
  }
};


/*
 * DELETE Displays Configuration Detail
 */
export const DEVICES__DELETE_DISPLAYS_CONFIGURATION_DETAIL = 'DEVICES__DELETE_DISPLAYS_CONFIGURATION_DETAIL';
export const deleteDisplaysConfigurationDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__DELETE_DISPLAYS_CONFIGURATION_DETAIL}__${PENDING}`,
      payload: {
        id,
      },
    });

    const response = await api({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/devices/display/configuration?id=${id}`,
    });

    dispatch({
      type: `${DEVICES__DELETE_DISPLAYS_CONFIGURATION_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        id,
        notif: {
          type: FORM_SUCCESS,
          title: 'Konfigurace displeje smazána',
          showMessage: true,
        },
      },
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${DEVICES__DELETE_DISPLAYS_CONFIGURATION_DETAIL}__${REJECTED}`,
      payload: {
        ...parsedError,
        id,
        notif: {
          type: FORM_ERROR,
          title: 'Nepovedlo se smazat konfiguraci',
          showMessage: true,
        },
      },
    });
  }
};
