import React from 'react';
import { Route, } from 'react-router-dom';

import BlankTemp from '../../../templates/Spaces/BlankTemp';
import PlatformsPage from './PlatformsPage';


const PlatformsLayout = () => (
  <BlankTemp>
    <Route component={PlatformsPage} />
  </BlankTemp>
);


export default PlatformsLayout;
