import { PENDING, FULFILLED, REJECTED, } from '../../../globals';
import { api, handleError, makeFakeFulfilledData, } from '../../../logic/restApi/restApi';


/**
 * Add Part
 */
export const EDITABLE_GEN_FORM__ADD_PART = 'EDITABLE_GEN_FORM__ADD_PART';

export const addPartAction = (parent, position) => ({
  type: `${EDITABLE_GEN_FORM__ADD_PART}`,
  payload: {
    parent,
    position,
  },
});


/**
 * Edit Part
 */
export const EDITABLE_GEN_FORM__EDIT_PART = 'EDITABLE_GEN_FORM__EDIT_PART';

export const editPartAction = (partId, position) => ({
  type: `${EDITABLE_GEN_FORM__EDIT_PART}`,
  payload: {
    partId,
    position,
  },
});


/**
 * Close Part
 */
export const EDITABLE_GEN_FORM__CLOSE_PART = 'EDITABLE_GEN_FORM__CLOSE_PART';

export const closePartAction = () => ({
  type: `${EDITABLE_GEN_FORM__CLOSE_PART}`,
});


/**
 * Save Part
 */
export const EDITABLE_GEN_FORM__SAVE_PART = 'EDITABLE_GEN_FORM__SAVE_PART';

export const savePartAction = () => ({
  type: `${EDITABLE_GEN_FORM__SAVE_PART}`,
});


/**
 * Change Part Type
 */
export const EDITABLE_GEN_FORM__CHANGE_PART_TYPE = 'EDITABLE_GEN_FORM__CHANGE_PART_TYPE';

export const changePartTypeAction = (newType) => ({
  type: `${EDITABLE_GEN_FORM__CHANGE_PART_TYPE}`,
  payload: {
    newType,
  },
});


/**
 * Change Part
 */
export const EDITABLE_GEN_FORM__CHANGE_PART = 'EDITABLE_GEN_FORM__CHANGE_PART';

export const changePartAction = (newPart) => ({
  type: `${EDITABLE_GEN_FORM__CHANGE_PART}`,
  payload: {
    newPart,
  },
});


/**
 * Change Common info
 */
export const EDITABLE_GEN_FORM__CHANGE_ROOT_PART = 'EDITABLE_GEN_FORM__CHANGE_ROOT_PART';

export const changeRootPartAction = (newPart) => ({
  type: `${EDITABLE_GEN_FORM__CHANGE_ROOT_PART}`,
  payload: {
    newPart,
  },
});


/**
 * Remove Part
 */
export const EDITABLE_GEN_FORM__REMOVE_PART = 'EDITABLE_GEN_FORM__REMOVE_PART';

export const removePartAction = (id, position) => ({
  type: `${EDITABLE_GEN_FORM__REMOVE_PART}`,
  payload: {
    id,
    position,
  },
});


/**
 * Move Part
 */
export const EDITABLE_GEN_FORM__MOVE_PART = 'EDITABLE_GEN_FORM__MOVE_PART';

export const movePartAction = ({
  id, parent, parentPosition, position, moveUp,
}) => ({
  type: `${EDITABLE_GEN_FORM__MOVE_PART}`,
  payload: {
    id,
    parent,
    parentPosition,
    position,
    moveUp,
  },
});


/*
 * GET Group Inputs
 */
export const EDITABLE_GEN_FORM__GET_GROUP_INPUTS = 'EDITABLE_GEN_FORM__GET_GROUP_INPUTS';
export const getGroupInputsAction = (groupValue) => async (dispatch, getState) => {
  try {
    // check redux
    const cacheGroupInputs = getState().genericFormsDetail.groupInputs;
    if (
      groupValue === null
      || Object.prototype.hasOwnProperty.call(cacheGroupInputs, groupValue.id)
    ) {
      dispatch({
        type: `${EDITABLE_GEN_FORM__GET_GROUP_INPUTS}__${FULFILLED}`,
        payload: {
          ...makeFakeFulfilledData(groupValue === null ? [] : cacheGroupInputs[groupValue.id]),
          groupValue,
        },
      });
      return;
    }

    // not in redux, get data
    dispatch({
      type: `${EDITABLE_GEN_FORM__GET_GROUP_INPUTS}__${PENDING}`,
      payload: {
        groupValue,
      },
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/generic_forms/group?id=${groupValue.id}`,
    });

    dispatch({
      type: `${EDITABLE_GEN_FORM__GET_GROUP_INPUTS}__${FULFILLED}`,
      payload: {
        ...response,
        groupValue,
      },
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${EDITABLE_GEN_FORM__GET_GROUP_INPUTS}__${REJECTED}`,
      payload: {
        ...parsedError,
        groupValue,
      },
    });
  }
};
