import React, { Component, Fragment, } from 'react';
import {
  shape, number, bool, func, object, string, arrayOf,
} from 'prop-types';
import { Route, } from 'react-router-dom';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  deleteLanguagesDetailAction,
  toggleLanguageDeleteModalAction,
} from '../actions/languagesDetail';
import {
  getLanguagesAction,
  changeFilterAction,
} from '../actions/languages';
import TableView from '../components/LanguagesTable/TableView';
import LanguagesEditModal from './LanguagesEditModal';
import LanguagesCreateModal from './LanguagesCreateModal';
import LanguagesDeleteModal from '../components/LanguagesDeleteModal/LanguagesDeleteModal';


class LanguagesPage extends Component {
  componentDidMount() {
    const { table, } = this.props;

    this.handleGetLanguages(table.filter);
  }

  handleGetLanguages = (newFilter) => {
    const { getLanguages, table, } = this.props;

    getLanguages(newFilter === undefined ? table.filter : newFilter);
  }

  handleEdit = (id) => {
    const { match, history, } = this.props;

    history.push(`${match.path}/edit/${id}`);
  }

  handleCreate = () => {
    const { match, history, } = this.props;
    history.push(`${match.path}/create`);
  }

  handleDelete = () => {
    const {
      deleteLanguagesData,
      languagesDeleteModal,
      deleteLanguagesDetail,
    } = this.props;
    if (!deleteLanguagesData.isLoading) {
      deleteLanguagesDetail(languagesDeleteModal.id);
    }
  }

  handleToggle = () => {
    const { toggleLanguageDeleteModal, } = this.props;
    toggleLanguageDeleteModal(false);
  }

  render() {
    const {
      // data
      match,
      table,
      languagesData,
      deleteLanguagesData,
      languagesDeleteModal,
      // methods
      changeFilter,
      toggleLanguageDeleteModal,
    } = this.props;

    return (
      <Fragment>
        <TableView
          table={table}
          tableData={languagesData}
          onGetTableData={this.handleGetLanguages}
          onRestore={this.handleRestore}
          onEdit={this.handleEdit}
          onDelete={toggleLanguageDeleteModal}
          onCreate={this.handleCreate}
          onChangeFilter={changeFilter}
        />
        <Route
          exact
          path={`${match.path}/create`}
          render={
            (props) => <LanguagesCreateModal {...props} refreshParent={this.handleGetLanguages} /> // eslint-disable-line
          }
        />
        <Route
          exact
          path={`${match.path}/edit/:id`}
          render={
            (props) => <LanguagesEditModal {...props} refreshParent={this.handleGetLanguages} /> // eslint-disable-line
          }
        />

        <LanguagesDeleteModal
          modalData={languagesDeleteModal}
          actionData={deleteLanguagesData}
          action={this.handleDelete}
          onClose={this.handleToggle}
        />

      </Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  const { languages, languagesDetail, } = state;
  return {
    languagesData: languages.languagesData,
    table: languages.table,
    languagesDeleteModal: languagesDetail.languagesDeleteModal,
    deleteLanguagesData: languagesDetail.deleteLanguagesData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getLanguages: bindActionCreators(getLanguagesAction, dispatch),
  deleteLanguagesDetail: bindActionCreators(deleteLanguagesDetailAction, dispatch), // eslint-disable-line
  changeFilter: bindActionCreators(changeFilterAction, dispatch),
  toggleLanguageDeleteModal: bindActionCreators(toggleLanguageDeleteModalAction, dispatch), // eslint-disable-line
});


LanguagesPage.propTypes = {
  table: shape({
    data: arrayOf(object).isRequired,
    count: number.isRequired,
    filter: object.isRequired,
  }).isRequired,
  languagesData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  match: shape({
    path: string.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  changeFilter: func.isRequired,
  getLanguages: func.isRequired,
  deleteLanguagesDetail: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(LanguagesPage);
