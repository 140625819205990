import styled from 'styled-components';


const StyledPlanItem = styled.div`
  padding: 0.25rem 1rem;

  ${(p) => (!p.draggingOverList && !p.isLocked) && `
    &:hover {
      background: ${p.theme.primary.t100}
      cursor: grab;
    }
  `}

  ${(p) => p.isLocked && `
    background: ${p.theme.grey.t300}
  `}

  -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
`;

export default StyledPlanItem;
