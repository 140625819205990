import React, { Component, Fragment, } from 'react';
import { SketchPicker, } from 'react-color';
import { bool, string, func, } from 'prop-types';

import Input from '../Input/Input';
import StyledColorPickerWrapper from './styles/StyledColorPickerWrapper';

class InputColor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
    };
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleChangeComplete = (color) => {
    const {
      formName,
      onChange,
    } = this.props;
    onChange(formName, color.hex);
  };

  handleClick = (e) => {
    if (this.node.contains(e.target)) {
      this.setState({ showPicker: true, });
      return;
    }

    this.setState({ showPicker: false, });
  }

  showPicker = () => {
    const { showPicker, } = this.state;
    if (!showPicker) {
      this.setState({ showPicker: true, });
    }
  }

  render() {
    const {
      value,
      formName,
      disableAlpha,
      status,
      onChange,
    } = this.props;

    const { showPicker, } = this.state;

    return (
      <Fragment>
        <Input
          type="text"
          value={value}
          status={status}
          onChange={(e) => onChange(formName, e.target.value)}
          onClick={() => this.showPicker()}
        />
        <StyledColorPickerWrapper
          ref={(node) => { this.node = node; }}
          position="top"
        >
          { showPicker ? (
            <SketchPicker
              color={value}
              onChangeComplete={(color) => this.handleChangeComplete(color)}
              disableAlpha={disableAlpha}
            />
          ) : ''}
        </StyledColorPickerWrapper>
      </Fragment>
    );
  }
}

InputColor.propTypes = {
  value: string.isRequired,
  formName: string.isRequired,
  disableAlpha: bool.isRequired,
  status: string.isRequired,
  onChange: func.isRequired,
};

export default InputColor;
