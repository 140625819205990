import React, { Component, Fragment, } from 'react';
import {
  shape, bool, func, oneOfType, arrayOf, node, object,
} from 'prop-types';

import { withLang, } from '../../../../logic/languages/withLang';
import { FORM_ERROR, FORM_SUCCESS, } from '../../../../globals';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import ButtonGrp from '../../../../atoms/ButtonGrp/ButtonGrp';
import SegmentLaoding from '../../../../components/SegmentLaoding/SegmentLaoding';
import SegmentError from '../../../../components/SegmentError/SegmentError';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';

import Pencil from '../../../../styles/icons/Pencil';


class TransportationEditFormsEdit extends Component {
  renderChildren = () => {
    const { children, detailData, getDetail, } = this.props;

    if (detailData.isLoading) {
      return <SegmentLaoding formGroup />;
    }

    if (detailData.error) {
      return (
        <SegmentError
          formGroup
          message={detailData.message}
          refresh={getDetail}
        />
      );
    }

    return children;
  }

  render() {
    const {
      isFormValid,
      editData,
      onClose,
      onEdit,
      langError,
    } = this.props;

    return (
      <Fragment>

        {this.renderChildren()}

        <Row>
          <Col textAlign="right">
            <InfoMsg
              status={editData.error ? FORM_ERROR : FORM_SUCCESS}
              msg={langError(editData.message)}
              showIcon
            />
          </Col>
          <Col textAlign="right">
            <ButtonGrp>
              <Button
                type="button"
                shape="outline"
                onClick={onClose}
              >
                Zpět
              </Button>

              <ButtonLoader
                type="button"
                color="primary"
                onClick={onEdit}
                disabled={!isFormValid}
                isLoading={editData.isLoading}
              >
                <span className="btn--icon">
                  <Pencil />
                </span>
                Upravit
              </ButtonLoader>
            </ButtonGrp>
          </Col>
        </Row>

      </Fragment>
    );
  }
}


TransportationEditFormsEdit.propTypes = {
  detailData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
    message: object.isRequired,
  }).isRequired,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
  isFormValid: bool.isRequired,
  editData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
    message: object.isRequired,
  }).isRequired,
  onClose: func.isRequired,
  onEdit: func.isRequired,
  langError: func.isRequired,
  getDetail: func.isRequired,
};


export default withLang(TransportationEditFormsEdit);
