import React from 'react';

import LangContext from './LangContext';


export const withLang = (Comp) => {
  const ConnectedComponent = (props) => (
    <LangContext.Consumer>
      {(contexts) => (
        <Comp
          {...props}
          {...contexts}
        />
      )}
    </LangContext.Consumer>
  );

  return ConnectedComponent;
};
