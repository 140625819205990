import {
  PENDING, FULFILLED, REJECTED, FORM_SUCCESS,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


const parseValues = (values) => ({
  name: values.name,
  ip: values.ip,
  port: values.port,
  username: values.username,
  password: values.password,
});


/**
 * Refresh data - on mount
 */
export const CAMERAS_REFRESH = 'CAMERAS_REFRESH';
export const refreshCamerasDetailAction = () => ({
  type: `${CAMERAS_REFRESH}`,
});


/**
 * Change Value
 */
export const CAMERAS_CHANGE_VALUE_DETAIL = 'CAMERAS_CHANGE_VALUE_DETAIL';
export const changeValueAction = (name, value, validationError) => ({
  type: `${CAMERAS_CHANGE_VALUE_DETAIL}`,
  payload: {
    name,
    value,
    validationError,
  },
});


/**
 * Validate Form
 */
export const CAMERAS_VALIDATE_FORM_DETAIL = 'CAMERAS_VALIDATE_FORM_DETAIL';
export const validateFormAction = (error) => ({
  type: `${CAMERAS_VALIDATE_FORM_DETAIL}`,
  payload: error,
});


/*
 * GET Camera Detail
 */
export const CAMERAS__GET_DETAIL = 'CAMERAS__GET_DETAIL';
export const getCamerasDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${CAMERAS__GET_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/devices/cameras/detail?id=${id}`,
    });

    dispatch({
      type: `${CAMERAS__GET_DETAIL}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${CAMERAS__GET_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * CREATE Cameras Detail
 */
export const CAMERAS__CREATE_DETAIL = 'CAMERAS__CREATE_DETAIL';
export const createCamerasDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${CAMERAS__CREATE_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/devices/cameras`,
      data: {
        values: parseValues(item.values),
      },
    });

    dispatch({
      type: `${CAMERAS__CREATE_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Kamera přidána',
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    dispatch({
      type: `${CAMERAS__CREATE_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * UPDATE Cameras Detail
 */
export const CAMERAS__UPDATE_DETAIL = 'CAMERAS__UPDATE_DETAIL';
export const updateCamerasDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${CAMERAS__UPDATE_DETAIL}__${PENDING}`,
    });

    const values = parseValues(item.values);
    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/devices/cameras`,
      data: {
        id: item.id,
        values,
      },
    });

    dispatch({
      type: `${CAMERAS__UPDATE_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Kamera upravena',
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    dispatch({
      type: `${CAMERAS__UPDATE_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};
