import React, { Fragment, } from 'react';
import {
  shape, func, arrayOf, object, string, bool,
} from 'prop-types';

import { withLang, } from '../../../../logic/languages/withLang';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Input from '../../../../atoms/Input/Input';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';
import MultiSelectListWrapper from '../../../../components/wrappers/MultiSelectListWrapper';
import InputSelectWrapper from '../../../../components/wrappers/InputSelectWrapper';
import Plan from './Plan';


const TransportationTypesDetail = ({
  // data
  form,
  values,
  actionsSharedData,
  platformsSharedData,
  ticketsSharedData,
  // methods
  handleOnChangeValue,
  getActionsShared,
  getPlatformsShared,
  getTicketsShared,
  langError,
}) => (
  <Fragment>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Název
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="text"
          value={values.name}
          status={form.name.status}
          onChange={(e) => handleOnChangeValue(form.name.name, e.target.value)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.name.status}
          msg={langError(form.name.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Platformy
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <MultiSelectListWrapper
          onChange={(value) => handleOnChangeValue(form.platforms.name, value)}
          options={platformsSharedData.data}
          value={values.platforms}
          isLoading={platformsSharedData.isLoading}
          error={platformsSharedData.error}
          fetchData={getPlatformsShared}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.platforms.status}
          msg={langError(form.platforms.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Akce
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Plan
          value={values.plan}
          actionsSharedData={actionsSharedData}
          onChange={(newVal) => handleOnChangeValue(form.plan.name, newVal)}
          getActionsShared={getActionsShared}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.plan.status}
          msg={langError(form.plan.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Konfigurace lístku
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <InputSelectWrapper
          value={values.ticketId}
          status={form.ticketId.status}
          options={ticketsSharedData.data}
          onChange={(value) => handleOnChangeValue(form.ticketId.name, value)}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          isLoading={ticketsSharedData.isLoading}
          error={ticketsSharedData.error}
          fetchData={getTicketsShared}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.ticketId.status}
          msg={langError(form.ticketId.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

  </Fragment>
);


TransportationTypesDetail.propTypes = {
  form: shape({
    name: shape({
      name: string.isRequired,
      type: string.isRequired,
      validation: object.isRequired,
      status: string.isRequired,
      info: string.isRequired,
      statusMsg: object.isRequired,
    }),
    platforms: shape({
      name: string.isRequired,
      type: string.isRequired,
      validation: object.isRequired,
      status: string.isRequired,
      info: string.isRequired,
      statusMsg: object.isRequired,
    }),
    plan: shape({
      name: string.isRequired,
      type: string.isRequired,
      validation: object.isRequired,
      status: string.isRequired,
      info: string.isRequired,
      statusMsg: object.isRequired,
    }),
    ticketId: shape({
      name: string.isRequired,
      type: string.isRequired,
      validation: object.isRequired,
      status: string.isRequired,
      info: string.isRequired,
      statusMsg: object.isRequired,
    }),
  }).isRequired,
  values: shape({
    name: string,
    platforms: arrayOf(object),
    plan: arrayOf(object),
    ticketId: object,
  }).isRequired,
  platformsSharedData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
    data: object.isRequired,
  }).isRequired,
  actionsSharedData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
    data: object.isRequired,
  }).isRequired,
  handleOnChangeValue: func.isRequired,
  getActionsShared: func.isRequired,
  getPlatformsShared: func.isRequired,
  langError: func.isRequired,
};


export default withLang(TransportationTypesDetail);
