import React from 'react';
import {
  arrayOf, node, oneOfType, bool,
} from 'prop-types';


const HeaderItemRound = ({
  children, isFocused, ...props
}) => (
  <div
    className={`header--item header--item-round ${isFocused ? 'header--item-focused' : ''}`}
    {...props}
  >
    {children}
  </div>
);


HeaderItemRound.propTypes = {
  isFocused: bool,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};

HeaderItemRound.defaultProps = {
  isFocused: false,
};


export default HeaderItemRound;
