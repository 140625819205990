import styled from 'styled-components';

import { FORM_WARNING, FORM_ERROR, FORM_SUCCESS, } from '../../globals';


const getColor = (status, theme) => {
  switch (status) {
    case FORM_SUCCESS:
      return {
        color: theme.success.t500,
        focus: theme.success.t500,
      };

    case FORM_WARNING:
      return {
        color: theme.warning.t500,
        focus: theme.warning.t500,
      };

    case FORM_ERROR:
      return {
        color: theme.error.t500,
        focus: theme.error.t500,
      };

    default:
      return {
        color: theme.grey.t500,
        focus: theme.primary.t500,
      };
  }
};


const InputTextArea = styled.textarea`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: .375em .75em;
  line-height: 1.5;
  font-size: 1rem;
  background-clip: padding-box;
  overflow-y: scroll;
  resize: none;
  
  background: ${(p) => p.theme.white};;
  color: ${(p) => p.theme.black};
  border-radius: ${(p) => p.theme.input.borderRadius};
  border-color: ${(p) => p.theme.grey.t500};
  border-top-width: ${(p) => p.theme.input.borderWidthTopBottom};
  border-bottom-width: ${(p) => p.theme.input.borderWidthTopBottom};
  border-right-width: ${(p) => p.theme.input.borderWidthLeftRight};
  border-left-width: ${(p) => p.theme.input.borderWidthLeftRight};
  border-style: ${(p) => p.theme.input.borderStyle};

  &:focus {
    outline: unset;
  }
  &:disabled {
    background: ${(p) => p.theme.grey.t100};
  }

  /* Shape and color */
  ${(p) => {
    const colors = getColor(p.status, p.theme);

    if (p.shape === 'line') {
      return `
        border-color: ${colors.color};
        border-top: none;
        border-right: none;
        border-left: none;
        border-radius: 0;

        &:focus {
          border-color: ${colors.focus};
          box-shadow: 0 1px 0 0 ${colors.focus};
        }
      `;
    }
    return `
      border-color: ${colors.color};
      border-radius: ${p.theme.input.borderRadius};

      &:focus {
        border-color: ${colors.focus};
        box-shadow: 0 0 0 1px ${colors.focus};
      }
    `;
  }}

  /* Large */
  ${(p) => p.size && p.size === 'lg' && `
    font-size: 1.25rem
  `}

  /* Small */
  ${(p) => p.size && p.size === 'sm' && `
    font-size: 0.85rem
  `}
`;

export default InputTextArea;
