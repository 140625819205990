import styled from 'styled-components';


const StyledModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 10;
  transform: translateZ(0);
  background-color: rgba(0, 0, 0, 0.64);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;


export default StyledModalOverlay;
