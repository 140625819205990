import {
  PENDING, FULFILLED, REJECTED, FORM_SUCCESS,
} from '../../../globals';
import { api, handleError, makeFakeFulfilledData, } from '../../../logic/restApi/restApi';


export const TRANSPORTATION_TYPES__GET_TRANSPORTATION_EDIT_FORMS = 'TRANSPORTATION_TYPES__GET_TRANSPORTATION_EDIT_FORMS';
export const getTransportationEditFormDataAction = () => async (dispatch) => {
  try {
    dispatch({
      type: `${TRANSPORTATION_TYPES__GET_TRANSPORTATION_EDIT_FORMS}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/transportation_edit_forms/dropdown`,
    });

    dispatch({
      type: `${TRANSPORTATION_TYPES__GET_TRANSPORTATION_EDIT_FORMS}__${FULFILLED}`,
      payload: {
        ...response,
      },
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${TRANSPORTATION_TYPES__GET_TRANSPORTATION_EDIT_FORMS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/**
 * Change Mapped Form
 */
export const TRANSPORTATION_TYPES__CHANGE_MAPPED_FORM = 'TRANSPORTATION_TYPES__CHANGE_MAPPED_FORM';

export const changeMappedFormAction = ({ actionId, name, value, }) => ({
  type: `${TRANSPORTATION_TYPES__CHANGE_MAPPED_FORM}`,
  payload: {
    actionId,
    name,
    value,
  },
});


/*
 * Get Mapped Forms
 */
export const TRANSPORTATION_TYPES__GET_MAPPED_FORMS = 'TRANSPORTATION_TYPES__GET_MAPPED_FORMS';
export const getMappedFormsAction = (selected) => async (dispatch) => {
  try {
    if (selected === null) {
      dispatch({
        type: `${TRANSPORTATION_TYPES__GET_MAPPED_FORMS}__${FULFILLED}`,
        payload: {
          ...makeFakeFulfilledData(null),
          selected,
        },
      });
    }

    dispatch({
      type: `${TRANSPORTATION_TYPES__GET_MAPPED_FORMS}__${PENDING}`,
      payload: {
        selected,
      },
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/transportation_types/map_forms?id=${selected.id}`,
    });

    dispatch({
      type: `${TRANSPORTATION_TYPES__GET_MAPPED_FORMS}__${FULFILLED}`,
      payload: {
        ...response,
        selected,
      },
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${TRANSPORTATION_TYPES__GET_MAPPED_FORMS}__${REJECTED}`,
      payload: {
        ...parsedError,
        selected,
      },
    });
  }
};


/*
 * Update Mapped Forms
 */
export const TRANSPORTATION_TYPES__UPDATE_MAPPED_FORMS = 'TRANSPORTATION_TYPES__UPDATE_MAPPED_FORMS';
export const updateMappedFormsAction = () => async (dispatch, getState) => {
  try {
    const { selected, mapped, } = getState().mapForms;
    if (selected === null) return;

    dispatch({
      type: `${TRANSPORTATION_TYPES__UPDATE_MAPPED_FORMS}__${PENDING}`,
    });

    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/transportation_types/map_forms`,
      data: {
        values: mapped.map((item) => ({
          transportationTypeId: selected.id,
          actionId: item.action.id,
          formId: item.form === null ? null : item.form.id,
          transportationEditFormId: item.transportationEditForm
            ? item.transportationEditForm.id
            : null,
        })),
      },
    });

    dispatch({
      type: `${TRANSPORTATION_TYPES__UPDATE_MAPPED_FORMS}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Upraveno',
        },
      },
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${TRANSPORTATION_TYPES__UPDATE_MAPPED_FORMS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};
