import React, { Fragment, } from 'react';
import {
  object, func,
} from 'prop-types';

import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import CamerasTable from './CamerasTable';
import Heading from '../../../../atoms/Heading/Heading';
import Button from '../../../../atoms/Button/Button';


const Cameras = ({
  // data
  table,
  camerasData,
  // methods
  handleGetCameras,
  handleEdit,
  handleDelete,
  handleCreate,
  changeFilter,
}) => (
  <Fragment>

    <Heading h4>
      Kamery
    </Heading>

    <Row formGroup>
      <Col>
        <Button
          type="button"
          onClick={handleCreate}
          color="success"
          shape="outline"
        >
          Přidat kameru
        </Button>
      </Col>
    </Row>

    <CamerasTable
      data={table.data}
      table={table}
      filter={table.filter}
      count={table.count}
      camerasData={camerasData}
      handleGetCameras={handleGetCameras}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      changeFilter={changeFilter}
    />

  </Fragment>
);


Cameras.propTypes = {
  table: object.isRequired,
  camerasData: object.isRequired,
  changeFilter: func.isRequired,
  handleGetCameras: func.isRequired,
  handleEdit: func.isRequired,
  handleDelete: func.isRequired,
  handleCreate: func.isRequired,
};


export default Cameras;
