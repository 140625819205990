import styled from 'styled-components';


const TabsLinkPanel = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.black};
  padding: 0 0.2rem;
  margin-bottom: 1.5rem;

  .tabslink--item {
    margin: 0px 0.2rem -1px 0.2rem;
    min-width: 80px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    padding: 0.375em 0.75em;
    vertical-align: middle;
    cursor: pointer;

    font: inherit;
    text-decoration: none;
    color: inherit;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    transition:all 0.3s ease;

    background: ${(p) => p.theme.grey.t300};
    border-top: 1px solid ${(p) => p.theme.grey.t300};
    border-left: 1px solid ${(p) => p.theme.grey.t300};
    border-right: 1px solid ${(p) => p.theme.grey.t300};
    border-bottom: 1px solid ${(p) => p.theme.black};
  }

  .tabslink--item-active {
    background: ${(p) => p.theme.white};
    border-top: 1px solid ${(p) => p.theme.black};
    border-left: 1px solid ${(p) => p.theme.black};
    border-right: 1px solid ${(p) => p.theme.black};
    border-bottom: 1px solid ${(p) => p.theme.white};
  }
`;


export default TabsLinkPanel;
