import {
  TRANSPORTATION_TYPES__GET_MAPPED_FORMS,
  TRANSPORTATION_TYPES__CHANGE_MAPPED_FORM,
  TRANSPORTATION_TYPES__UPDATE_MAPPED_FORMS,
  TRANSPORTATION_TYPES__GET_TRANSPORTATION_EDIT_FORMS,
} from '../actions/mapForms';
import {
  PENDING, FULFILLED, REJECTED,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';


const initialState = {
  selected: null,
  mapped: [],
  mappedFormsData: {
    ...initRestData,
  },
  updateData: {
    ...initRestData,
  },
  transportationEditFormData: {
    ...initRestData,
    data: [],
  },
};


const reducer = (state = initialState, action) => {
  const { type, payload, } = action;
  switch (type) {
    /**
     * Change Mapped Form
     */
    case TRANSPORTATION_TYPES__CHANGE_MAPPED_FORM: {
      const { actionId, name, value, } = payload;
      return {
        ...state,
        mapped: state.mapped.map((item) => {
          if (item.action.id === actionId) {
            return {
              ...item,
              [name]: value,
            };
          }
          return item;
        }),
      };
    }


    /**
     * Get Mapped Forms
     */
    case `${TRANSPORTATION_TYPES__GET_MAPPED_FORMS}__${PENDING}`: {
      return {
        ...initialState,
        selected: payload.selected,
        mappedFormsData: {
          ...state.mappedFormsData,
          ...(handlePending()),
        },
      };
    }

    case `${TRANSPORTATION_TYPES__GET_MAPPED_FORMS}__${FULFILLED}`: {
      try {
        return {
          ...state,
          selected: payload.selected,
          mapped: payload.data.actionsHasForm,
          mappedFormsData: {
            ...state.mappedFormsData,
            ...(handleFulfilled(payload)),
          },
        };
      } catch (err) {
        return {
          ...state,
          selected: payload.selected,
          mappedFormsData: {
            ...state.mappedFormsData,
            ...(handleRejected({
              statusCode: -1,
              messageInternal: err.message,
              message: {
                code: 'SOMETHING_HAPPEND',
                params: {},
              },
            })),
          },
        };
      }
    }

    case `${TRANSPORTATION_TYPES__GET_MAPPED_FORMS}__${REJECTED}`: {
      return {
        ...state,
        updateData: {
          ...state.updateData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * Update Mapped Forms
     */
    case `${TRANSPORTATION_TYPES__UPDATE_MAPPED_FORMS}__${PENDING}`: {
      return {
        ...state,
        updateData: {
          ...state.updateData,
          ...(handlePending()),
        },
      };
    }

    case `${TRANSPORTATION_TYPES__UPDATE_MAPPED_FORMS}__${FULFILLED}`: {
      return {
        ...initialState,
        transportationEditFormData: {
          ...state.transportationEditFormData,
        },
      };
    }

    case `${TRANSPORTATION_TYPES__UPDATE_MAPPED_FORMS}__${REJECTED}`: {
      return {
        ...state,
        updateData: {
          ...state.updateData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * Get Transportation Edit Form
     */
    case `${TRANSPORTATION_TYPES__GET_TRANSPORTATION_EDIT_FORMS}__${PENDING}`: {
      return {
        ...state,
        transportationEditFormData: {
          ...state.transportationEditFormData,
          ...(handlePending()),
          isLoading: !state.transportationEditFormData.data,
        },
      };
    }

    case `${TRANSPORTATION_TYPES__GET_TRANSPORTATION_EDIT_FORMS}__${FULFILLED}`: {
      return {
        ...state,
        transportationEditFormData: {
          ...state.transportationEditFormData,
          ...(handleFulfilled(payload)),
          data: payload.data,
        },
      };
    }

    case `${TRANSPORTATION_TYPES__GET_TRANSPORTATION_EDIT_FORMS}__${REJECTED}`: {
      return {
        ...state,
        transportationEditFormData: {
          ...state.transportationEditFormData,
          ...(handleRejected(payload)),
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
