import styled from 'styled-components';


const Heading = styled.div`
  display: block;
  margin-bottom: 1rem;
  margin-top: 0;
  margin-left: 0; 
  margin-right: 0; 
  font-family: inherit;
  font-weight: bold;
  line-height: 1.2;
  color: inherit;

  ${(p) => p.h1 && `
    font-size: 2.5rem;
  `}
  ${(p) => p.h2 && `
    font-size: 2rem;
`}
  ${(p) => p.h3 && `
    font-size: 1.75rem;
  `}
  ${(p) => p.h4 && `
    font-size: 1.5rem;
  `}
  ${(p) => p.h5 && `
    font-size: 1.25rem;
  `}
  ${(p) => p.h6 && `
    font-size: 1rem;
  `}
`;

export default Heading;
