import React, { Component, Fragment, } from 'react';
import {
  shape, number, bool, func, object, string, arrayOf,
} from 'prop-types';
import { Route, } from 'react-router-dom';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  changeFilterAction,
  getTicketsAction,
  deleteTicketDetailAction,
} from '../actions/tickets';
import TableView from '../components/TicketsTable/TableView';
import TicketsEditModal from './TicketsEditModal';
import TicketsCreateModal from './TicketsCreateModal';

class TicketsPage extends Component {
  componentDidMount() {
    const { table, } = this.props;

    this.handleGetTickets(table.filter);
  }

  handleGetTickets = (newFilter) => {
    const { getTickets, table, } = this.props;

    getTickets(newFilter === undefined ? table.filter : newFilter);
  }

  handleEdit = (id) => {
    const { match, history, } = this.props;

    history.push(`${match.path}/edit/${id}`);
  }

  handleCreate = () => {
    const { match, history, } = this.props;

    history.push(`${match.path}/create`);
  }


  handleDelete = (item) => {
    const { deleteTicketDetail, } = this.props;

    if (!item.isLoading) {
      deleteTicketDetail(item.id);
    }
  }


  render() {
    const {
      table,
      ticketsData,
      changeFilter,
      match,
    } = this.props;

    return (
      <Fragment>
        <TableView
          table={table}
          tableData={ticketsData}
          onGetTableData={this.handleGetTickets}
          onEdit={this.handleEdit}
          onDelete={this.handleDelete}
          onCreate={this.handleCreate}
          onChangeFilter={changeFilter}
        />
        <Route
          exact
          path={`${match.path}/create`}
          render={
            (props) => <TicketsCreateModal {...props} refreshParent={this.handleGetTickets} /> // eslint-disable-line
          }
        />
        <Route
          exact
          path={`${match.path}/edit/:id`}
          render={
            (props) => <TicketsEditModal {...props} refreshParent={this.handleGetTickets} /> // eslint-disable-line
          }
        />
      </Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  const { tickets, } = state;
  return {
    ticketsData: tickets.ticketsData,
    table: tickets.table,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTickets: bindActionCreators(getTicketsAction, dispatch),
  changeFilter: bindActionCreators(changeFilterAction, dispatch),
  deleteTicketDetail: bindActionCreators(deleteTicketDetailAction, dispatch),
});


TicketsPage.propTypes = {
  table: shape({
    data: arrayOf(object).isRequired,
    count: number.isRequired,
    filter: object.isRequired,
  }).isRequired,
  ticketsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  match: shape({
    path: string.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  changeFilter: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(TicketsPage);
