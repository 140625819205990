import {
  PENDING, FULFILLED, REJECTED, FORM_SUCCESS,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';
import { validateWholeEditableGenForm, } from '../../../logic/editableGenForm/methods';
import { parseToApi, } from '../../../logic/editableGenForm/parseToApi';


/**
 * Refresh data - on mount
 */
export const GENERIC_FORMS__REFRESH = 'GENERIC_FORMS__REFRESH';
export const refreshGenericFormsDetailAction = () => ({
  type: `${GENERIC_FORMS__REFRESH}`,
});


/*
 * GET GenericForms Detail
 */
export const GENERIC_FORMS__GET_GENERIC_FORMS_DETAIL = 'GENERIC_FORMS__GET_GENERIC_FORMS_DETAIL';
export const getGenericFormsDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${GENERIC_FORMS__GET_GENERIC_FORMS_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/generic_forms?id=${id}`,
    });

    dispatch({
      type: `${GENERIC_FORMS__GET_GENERIC_FORMS_DETAIL}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${GENERIC_FORMS__GET_GENERIC_FORMS_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * CREATE Gen Form
 */
export const GENERIC_FORMS__VALIDATE_WHOLE_FORM = 'GENERIC_FORMS__VALIDATE_WHOLE_FORM';
export const GENERIC_FORMS__CREATE_GEN_FORM = 'GENERIC_FORMS__CREATE_GEN_FORM';
export const createAction = (onSuccess) => async (dispatch, getState) => {
  try {
    const oldState = getState().editableGenForm;
    const newState = validateWholeEditableGenForm(oldState);

    if (!newState.commonValidation.isValid || !newState.finalValidation.isValid) {
      dispatch({
        type: `${GENERIC_FORMS__VALIDATE_WHOLE_FORM}`,
        payload: {
          newState,
        },
      });
      return;
    }

    const parsedData = parseToApi(oldState);

    dispatch({
      type: `${GENERIC_FORMS__CREATE_GEN_FORM}__${PENDING}`,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/generic_forms`,
      data: parsedData,
    });

    dispatch({
      type: `${GENERIC_FORMS__CREATE_GEN_FORM}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Formulář vytvořen',
        },
      },
    });
    onSuccess();
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${GENERIC_FORMS__CREATE_GEN_FORM}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * UPDATE Gen Form
 */
export const GENERIC_FORMS__UPDATE_GEN_FORM = 'GENERIC_FORMS__UPDATE_GEN_FORM';
export const updateAction = (onSuccess) => async (dispatch, getState) => {
  try {
    const oldState = getState().editableGenForm;
    const newState = validateWholeEditableGenForm(oldState);

    if (!newState.commonValidation.isValid || !newState.finalValidation.isValid) {
      dispatch({
        type: `${GENERIC_FORMS__VALIDATE_WHOLE_FORM}`,
        payload: {
          newState,
        },
      });
      return;
    }

    const parsedData = parseToApi(oldState);

    dispatch({
      type: `${GENERIC_FORMS__UPDATE_GEN_FORM}__${PENDING}`,
    });

    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/generic_forms`,
      data: parsedData,
    });

    dispatch({
      type: `${GENERIC_FORMS__UPDATE_GEN_FORM}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Formulář upraven',
        },
      },
    });

    onSuccess();
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${GENERIC_FORMS__UPDATE_GEN_FORM}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * DELETE GenericForms Detail
 */
export const GENERIC_FORMS__DELETE_GENERIC_FORMS_DETAIL = 'GENERIC_FORMS__DELETE_GENERIC_FORMS_DETAIL';
export const deleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${GENERIC_FORMS__DELETE_GENERIC_FORMS_DETAIL}__${PENDING}`,
      payload: {
        id,
      },
    });

    const response = await api({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/generic_forms?id=${id}`,
    });

    dispatch({
      type: `${GENERIC_FORMS__DELETE_GENERIC_FORMS_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        id,
        notif: {
          type: FORM_SUCCESS,
          title: 'Formulář smazán',
        },
      },
    });
  } catch (err) {
    const parsedError = handleError(err);

    dispatch({
      type: `${GENERIC_FORMS__DELETE_GENERIC_FORMS_DETAIL}__${REJECTED}`,
      payload: {
        ...parsedError,
        id,
        message: parsedError.statusCode === 428 ? {
          code: 'CANT_DELETE',
          params: {},
        } : parsedError.message,
      },
    });
  }
};


/*
 * Toggle GenericForms delete Modal
 */
export const GENERIC_FORMS__TOGGLE_DELETE_GENERIC_FORMS_DELETE_MODAL = 'GENERIC_FORMS__TOGGLE_DELETE_GENERIC_FORMS_DELETE_MODAL';
export const toggleModalDeleteAction = (isOpen = false, id = null) => ({
  type: `${GENERIC_FORMS__TOGGLE_DELETE_GENERIC_FORMS_DELETE_MODAL}`,
  payload: {
    id,
    isOpen,
  },
});
