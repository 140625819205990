import React, { Component, Fragment, } from 'react';
import {
  shape, number, bool, func, object, string, arrayOf,
} from 'prop-types';
import { Route, } from 'react-router-dom';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  deleteStorekeeperDeviceDetailAction,
} from '../actions/storekeeperDetail';
import { getStorekeeperDevicesAction, changeStorekeeperFilterAction, } from '../actions/storekeeper';
import Storekeeper from '../components/Storekeeper/Storekeeper';
import StorekeeperEditModal from './StorekeeperEditModal';
import StorekeeperRegisterModal from './StorekeeperRegisterModal';

class StorekeeperPage extends Component {
  componentDidMount() {
    const { table, } = this.props;

    this.handleGetStorekeeperDevices(table.filter);
  }

  handleGetStorekeeperDevices = (newFilter) => {
    const { getStorekeeperDevices, table, } = this.props;

    getStorekeeperDevices(newFilter === undefined ? table.filter : newFilter);
  }

  handleEdit = (id) => {
    const { match, history, } = this.props;

    history.push(`${match.path}/edit/${id}`);
  }

  handleRegister = (id) => {
    const { match, history, } = this.props;

    history.push(`${match.path}/register/${id}`);
  }

  handleDelete = (item) => {
    const { deleteStorekeeperDeviceDetail, } = this.props;

    if (!item.isLoading) {
      deleteStorekeeperDeviceDetail(item.id);
    }
  }


  render() {
    const {
      match,
      table,
      storekeeperDevicesData,
      changeFilter,
    } = this.props;

    return (
      <Fragment>
        <Storekeeper
          table={table}
          storekeeperDevicesData={storekeeperDevicesData}
          onGetStorekeeperDevices={this.handleGetStorekeeperDevices}
          onEdit={this.handleEdit}
          onRegister={this.handleRegister}
          onDelete={this.handleDelete}
          changeFilter={changeFilter}
        />
        <Route
          exact
          path={`${match.path}/edit/:id`}
          component={
            (props) => (
              <StorekeeperEditModal
                {...props}
                refreshParent={this.handleGetStorekeeperDevices}
              />
            )
          }
        />
        <Route
          exact
          path={`${match.path}/register/:id`}
          component={
            (props) => (
              <StorekeeperRegisterModal
                {...props}
                refreshParent={this.handleGetStorekeeperDevices}
              />
            )
          }
        />
      </Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  const { storekeeper, } = state;
  return {
    storekeeperDevicesData: storekeeper.storekeeperDevicesData,
    table: storekeeper.table,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStorekeeperDevices: bindActionCreators(getStorekeeperDevicesAction, dispatch),
  changeFilter: bindActionCreators(changeStorekeeperFilterAction, dispatch),
  deleteStorekeeperDeviceDetail: bindActionCreators(deleteStorekeeperDeviceDetailAction, dispatch),
});


StorekeeperPage.propTypes = {
  table: shape({
    data: arrayOf(object).isRequired,
    count: number.isRequired,
    filter: object.isRequired,
  }).isRequired,
  storekeeperDevicesData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  changeFilter: func.isRequired,
  getStorekeeperDevices: func.isRequired,
  match: shape({
    path: string.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  deleteStorekeeperDetail: func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(StorekeeperPage);
