import styled from 'styled-components';

import media from '../../../styles/media';

const StyledAppTemplate = styled.div`
  display: flex;
  background: ${(p) => p.theme.grey.t200};
  height: 100%;

  .app-temp--sidebar-wrapper {
    background: ${(p) => `linear-gradient(180deg,${p.theme.tertiary.t900} 0,${p.theme.secondary.t600} 400px)`};
    color: ${(p) => p.theme.white};
    display: block;
  }

  ${() => media.XS`
    .app-temp--sidebar-wrapper {
      position: absolute;
      z-index: 2;
      min-height: 100vh;
    }
  `}

  ${() => media.SM`
    .app-temp--sidebar-wrapper {
      position: absolute;
      z-index: 2;
      min-height: 100vh;
    }
  `}
  
  ${() => media.LG`
    .app-temp--sidebar-wrapper {
      position: static;
    }
  `}


  .slide-sidebar-enter {
    margin-left: -220px;
  }

  .slide-sidebar-enter-active {
    margin-left: 0;
    transition: margin-left 350ms ease-in;
  }

  .slide-sidebar-exit {
    margin-left: 0;
  }

  .slide-sidebar-exit-active {
    margin-left: -220px;
    transition: margin-left 350ms ease-in;
  }

`;


export default StyledAppTemplate;
