import React, { Component, Fragment, } from 'react';

import barcode from './barcodeBase64';

class TicketPreview extends Component {
  renderTicketParts = (data) => (
    <div style={{ width: '100%', textAlign: 'center', }}>
      {data.logo === '' ? (
        ''
      ) : (
        <img src={data.logo} width="300px" height="auto" alt="logo" />
      )}
      <p>{data.header}</p>
      <p style={{ fontSize: '48px', fontWeight: 'bold', margin: '16px 0px', }}>{data.numericalSeriesId ? data.serialNumber : ''}</p>
      <ul style={{ listStyleType: 'none', padding: 0, }}>
        {data.columns}
      </ul>
      <p>{data.footer}</p>
      { data.barCode !== '' ? (
        <img src={data.barCode} width="200px" height="auto" alt="barcode" />
      ) : (
        ''
      )
      }
    </div>
  );


  renderTicket = () => {
    const { values, } = this.props;
    const columns = ( 
      <Fragment>
        {values.columns.map((item) => (
          <li key={item.id}>
            <span style={{ fontWeight: 'bold', }}>
              {item.name}
              :
            </span>
            <span style={{ marginLeft: '5px', }}>
              VALUE
            </span>
          </li>
        ))}
      </Fragment>
    );
    return (
      <Fragment>
        {this.renderTicketParts({
          logo: values.logo,
          header: values.header,
          numericalSeriesId: values.numericalSeriesId !== null,
          serialNumber: 123,
          columns,
          footer: values.footer,
          barCode: values.barCode ? barcode.barcode : '',
        })}
      </Fragment>
    );
  }

  render() {
    return (
      <div style={{ width: '100%', border: '1px solid black', textAlign: 'center', }}>
        {this.renderTicket()}
      </div>
    );
  }
}

export default TicketPreview;

/*${ticket.logo ? '<img src="{{logo}}" width="384px">' : ''}
            ${ticket.header ? '<p>{{header}}</p>' : ''}
            ${ticket.numericalSeriesId ? '<p>{{serialNumber}}</p>' : ''}
            ${ticketColumns}
            ${ticket.footer ? '<p>{{footer}}</p>' : ''}
            ${ticket.barCode ? '< */
