import { PENDING, FULFILLED, REJECTED, } from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


/*
 * GET Languages
 */
export const LANGUAGES__GET_LANGUAGES = 'LANGUAGES__GET_LANGUAGES';
export const getLanguagesAction = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: `${LANGUAGES__GET_LANGUAGES}__${PENDING}`,
      payload: filter,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/settings/languages/filter`,
      data: filter,
    });

    dispatch({
      type: `${LANGUAGES__GET_LANGUAGES}__${FULFILLED}`,
      payload: response.data,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${LANGUAGES__GET_LANGUAGES}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/**
 * Filter Changed
 */
export const LANGUAGES__FILTER_CHANGED = 'LANGUAGES__FILTER_CHANGED';

export const changeFilterAction = (filter) => ({
  type: `${LANGUAGES__FILTER_CHANGED}`,
  payload: filter,
});
