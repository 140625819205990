import React, { Component, } from 'react';
import uuidv4 from 'uuid/v4';
import {
  arrayOf, func, string, any,
} from 'prop-types';

import ModalContext from './ModalContext';


/**
 * Add Modal Manager props to Modal
 */
export const withModalManager = (Comp) => {
  class Receiver extends Component {
    static propTypes = {
      addModal: func.isRequired,
      removeModal: func.isRequired,
      modals: arrayOf(string).isRequired,
      compProps: any, // eslint-disable-line
    }

    state = {
      uuid: uuidv4(),
    }

    componentDidMount() {
      const { addModal, } = this.props;
      const { uuid, } = this.state;

      addModal(uuid);
    }

    componentWillUnmount() {
      const { removeModal, } = this.props;
      const { uuid, } = this.state;

      removeModal(uuid);
    }

    render() {
      const { modals, compProps, } = this.props;
      const { uuid, } = this.state;

      return (
        <Comp
          {...compProps}
          isTopModal={modals[modals.length - 1] === uuid}
        />
      );
    }
  }

  const ConnectedComponent = (props) => (
    <ModalContext.Consumer>
      {(providerProps) => (
        <Receiver
          compProps={props}
          {...providerProps}
        />
      )}
    </ModalContext.Consumer>
  );

  return ConnectedComponent;
};
