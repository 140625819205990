import { INPUT_TYPE, } from 'sofair-form-validation';

import {
  DEVICES__GET_KIOSKS_DETAIL,
  DEVICES__UPDATE_KIOSKS_DETAIL,
  DEVICES__KIOSKS_VALIDATE_FORM_DETAIL,
  DEVICES__KIOSKS_CHANGE_VALUE_DETAIL,
  DEVICES__KIOSKS_REFRESH,
} from '../actions/kiosksDetail';
import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';
import {
  mergeFormAndValidation, initInput, changeFormValue,
} from '../../../logic/forms/validation';
import { parseStringFromApi, } from '../../../logic/utils/string';
import { parseObjectwithTranslation, } from '../../../logic/utils/object';


const initialState = {
  detailKiosksData: {
    ...initRestData,
    isLoading: true,
  },
  updateKiosksData: {
    ...initRestData,
  },
  detail: {
    id: null,
    isValid: true,
    invalidInputs: 0,
    form: {
      name: {
        ...initInput,
        name: 'name',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: true,
          min: 2,
          max: 255,
        },
      },
      kioskConfiguration: {
        ...initInput,
        name: 'kioskConfiguration',
        type: INPUT_TYPE.SELECT,
        validation: {
          ...initInput.validation,
          required: true,
        },
      },
      standId: {
        ...initInput,
        name: 'standId',
        type: INPUT_TYPE.SELECT,
        validation: {
          ...initInput.validation,
          required: true,
        },
      },
      cameraIds: {
        ...initInput,
        name: 'cameraIds',
        type: INPUT_TYPE.MULTISELECT,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
    },
    values: {
      name: '',
      kioskConfiguration: null,
      standId: null,
      cameraIds: [],
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;

  switch (type) {
    /**
     * Refresh Data
     */
    case `${DEVICES__KIOSKS_REFRESH}`: {
      return {
        ...state,
        detail: {
          ...initialState.detail,
        },
      };
    }


    /**
     * Change Form
     */
    case `${DEVICES__KIOSKS_CHANGE_VALUE_DETAIL}`: {
      const newFormData = changeFormValue(
        state.detail.form, state.detail.values, state.detail.invalidInputs, payload
      );

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: newFormData.invalidInputs < 1,
          invalidInputs: newFormData.invalidInputs,
          form: newFormData.form,
          values: newFormData.values,
        },
      };
    }


    /**
     * Validate form
     */
    case `${DEVICES__KIOSKS_VALIDATE_FORM_DETAIL}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload);

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }


    /**
     * GET Kiosks Detail
     */
    case `${DEVICES__GET_KIOSKS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        detailKiosksData: {
          ...state.detailKiosksData,
          ...(handlePending()),
        },
        detail: {
          ...initialState.detail,
        },
      };
    }

    case `${DEVICES__GET_KIOSKS_DETAIL}__${FULFILLED}`: {
      try {
        const newValues = {
          ...state.detail.values,
          name: parseStringFromApi(payload.data.name),
          kioskConfiguration: payload.data.kioskConfiguration,
          standId: parseObjectwithTranslation(payload.data.standId, payload.data.stand, 'standTranslation'),
          deviceCode: parseStringFromApi(payload.data.deviceCode),
          online: payload.data.online,
          synchronized: payload.data.synchronized,
          cameraIds: payload.data.cameras,
        };
        return {
          ...state,
          detailKiosksData: {
            ...state.detailKiosksData,
            ...(handleFulfilled(payload)),
          },
          detail: {
            ...state.detail,
            id: payload.data.id,
            values: newValues,
          },
        };
      } catch (err) {
        return {
          ...state,
          detailKiosksData: {
            ...state.detailKiosksData,
            ...(handleRejected({
              statusCode: -1,
              messageInternal: err.message,
              message: {
                code: 'SOMETHING_HAPPEND',
                params: {},
              },
            })),
          },
        };
      }
    }

    case `${DEVICES__GET_KIOSKS_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        detailKiosksData: {
          ...state.detailKiosksData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * UPDATE Kiosks Detail
     */
    case `${DEVICES__UPDATE_KIOSKS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        updateKiosksData: {
          ...state.updateKiosksData,
          ...(handlePending()),
        },
      };
    }

    case `${DEVICES__UPDATE_KIOSKS_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        updateKiosksData: {
          ...state.updateKiosksData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${DEVICES__UPDATE_KIOSKS_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data.data);

      return {
        ...state,
        updateKiosksData: {
          ...state.updateKiosksData,
          ...(handleFulfilled(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${DEVICES__UPDATE_KIOSKS_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        updateKiosksData: {
          ...state.updateKiosksData,
          ...(handleRejected(payload)),
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
