import {
  TICKETS__DELETE_DETAIL,
  TICKETS__FILTER_CHANGED,
  TICKETS__GET_TICKETS,
} from '../actions/tickets';
import {
  PENDING, FULFILLED, REJECTED,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';

const initialState = {
  ticketsData: {
    ...initRestData,
    isLoading: true,
  },
  table: {
    data: [],
    count: 0,
    filter: {
      offset: 0,
      limit: 10,
      sortBy: 'id',
      order: 'ASC',
      params: {
        id: '',
        name: '',
      },
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;
  switch (type) {
    /**
     * GET Tickets
     */
    case `${TICKETS__GET_TICKETS}__${PENDING}`: {
      return {
        ...state,
        ticketsData: {
          ...state.ticketsData,
          ...(handlePending()),
        },
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    case `${TICKETS__GET_TICKETS}__${FULFILLED}`: {
      return {
        ...state,
        ticketsData: {
          ...state.ticketsData,
          ...(handleFulfilled(payload)),
        },
        table: {
          data: payload.tickets.map((item) => ({
            ...initRestData,
            ...item,
          })),
          count: payload.count,
          filter: payload.filter,
        },
      };
    }

    case `${TICKETS__GET_TICKETS}__${REJECTED}`: {
      return {
        ...state,
        ticketsData: {
          ...state.ticketsData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
     * DELETE NumericalSerie
     */
    case `${TICKETS__DELETE_DETAIL}__${PENDING}`: {
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.map((item) => {
            if (item.id === payload.id) {
              return {
                ...item,
                ...(handlePending()),
              };
            }
            return item;
          }),
        },
      };
    }

    case `${TICKETS__DELETE_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.filter((item) => item.id !== payload.id),
        },
      };
    }

    case `${TICKETS__DELETE_DETAIL}__${REJECTED}`: {
      const { id, } = payload;

      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                ...(handleRejected(payload)),
              };
            }
            return item;
          }),
        },
      };
    }


    /**
     * Change Filter
     */
    case `${TICKETS__FILTER_CHANGED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
