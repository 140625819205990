import React, { Component, } from 'react';
import {
  func, bool, shape, string, number, oneOfType, arrayOf, node, object,
} from 'prop-types';
import { withTheme, } from 'styled-components';
import NodeElement from '../styles/NodeElement';
import StyledNodeRow from '../styles/NodeRow';
import ActionButton from '../styles/ActionButton';
import Cross from '../../../styles/icons/Cross';
import ArrowDown from '../../../styles/icons/ArrowDown';
import ArrowUp from '../../../styles/icons/ArrowUp';
import Pencil from '../../../styles/icons/Pencil';
import StyledChildrenRow from '../styles/ChildrenPart';
import { FORM_STRUCTURE_GROUP, FORM_STRUCTURE_FIELDSET, FORM_TYPES_CREATE_DD, } from '../../../globals';

class NodeRow extends Component {
  renderLabel = () => {
    const { part: { values, type, }, } = this.props;

    switch (type) {
      case FORM_STRUCTURE_GROUP:
      case FORM_STRUCTURE_FIELDSET: {
        return `${values.title} (${FORM_TYPES_CREATE_DD[type].label})`;
      }
      default: {
        return `${values.translation.label} (${FORM_TYPES_CREATE_DD[type].label})`;
      }
    }
  }

  render() {
    const {
      // data
      node: { id, type, },
      children,
      index,
      parentId,
      parentPosition,
      disableStructActions,
      // methods
      onEditPart,
      onMovePart,
      onRemovePart,
    } = this.props;
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
        <StyledNodeRow
          type={type}
        >
          <NodeElement>
            {this.renderLabel()}
            <ActionButton
              type="button"
              onClick={() => onEditPart(id, index)}
              style={{ position: 'absolute', top: 0, right: 0, }}
            >
              <Pencil />
            </ActionButton>
          </NodeElement>
          { children && (
            <StyledChildrenRow type={type}>{children}</StyledChildrenRow>
          )}
        </StyledNodeRow>
        {!disableStructActions && (
          <div>
            <ActionButton
              type="button"
              onClick={() => onMovePart({
                id,
                parent: parentId,
                parentPosition,
                position: index,
                moveUp: true,
              })}
              disabled={disableStructActions}
            >
              <ArrowUp />
            </ActionButton>
            <ActionButton
              type="button"
              onClick={() => onMovePart({
                id,
                parent: parentId,
                parentPosition,
                position: index,
                moveUp: false,
              })}
              disabled={disableStructActions}
            >
              <ArrowDown />
            </ActionButton>
            <ActionButton
              type="button"
              onClick={() => onRemovePart(id, index)}
              disabled={disableStructActions}
            >
              <Cross />
            </ActionButton>
          </div>
        )}
      </div>
    );
  }
}


NodeRow.propTypes = {
  node: shape({
    id: string.isRequired,
    type: string.isRequired,
  }).isRequired,
  children: oneOfType([
    arrayOf(node),
    node,
  ]),
  part: shape({
    type: string.isRequired,
    values: object.isRequired,
  }).isRequired,
  index: number.isRequired,
  parentId: string.isRequired,
  parentPosition: number.isRequired,
  onEditPart: func.isRequired,
  onMovePart: func.isRequired,
  onRemovePart: func.isRequired,
  disableStructActions: bool.isRequired,
};

NodeRow.defaultProps = {
  children: null,
};


export default withTheme(NodeRow);
