import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { unregister, } from './serviceWorker';

import './index.css';
import 'typeface-roboto';

ReactDOM.render(<App />, document.getElementById('root'));


unregister();
