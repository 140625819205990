import React from 'react';
import {
  func, object, shape,
} from 'prop-types';

import FormRow from '../FormRow';
import Input from '../../../atoms/Input/Input';


const FieldSetPart = ({
  part: {
    form,
    values,
  },
  onChangeValue,
}) => (
  <FormRow
    label="Název"
  >
    <Input
      type="text"
      value={values.title}
      onChange={(e) => onChangeValue(form.title.name, e.target.value)}
      status={form.title.status}
    />
  </FormRow>
);


FieldSetPart.propTypes = {
  part: shape({
    values: object.isRequired,
    form: object.isRequired,
  }).isRequired,
  onChangeValue: func.isRequired,
};


export default FieldSetPart;
