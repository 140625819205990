import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION, REJECTED_COLLISON, FORM_SUCCESS,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


const parseValues = (values) => ({
  name: values.name,
});


/**
 * Refresh data - on mount
 */
export const QUEUE_CONFIG__ACTIONS_REFRESH = 'QUEUE_CONFIG__ACTIONS_REFRESH';
export const refreshActionsDetailAction = () => ({
  type: `${QUEUE_CONFIG__ACTIONS_REFRESH}`,
});


/**
 * Change Value
 */
export const QUEUE_CONFIG__ACTIONS_CHANGE_VALUE_DETAIL = 'QUEUE_CONFIG__ACTIONS_CHANGE_VALUE_DETAIL';
export const changeValueAction = (name, value, validationError) => ({
  type: `${QUEUE_CONFIG__ACTIONS_CHANGE_VALUE_DETAIL}`,
  payload: {
    name,
    value,
    validationError,
  },
});


/**
 * Validate Form
 */
export const QUEUE_CONFIG__ACTIONS_VALIDATE_FORM_DETAIL = 'QUEUE_CONFIG__ACTIONS_VALIDATE_FORM_DETAIL';
export const validateFormAction = (error) => ({
  type: `${QUEUE_CONFIG__ACTIONS_VALIDATE_FORM_DETAIL}`,
  payload: error,
});


/*
 * GET Actions Detail
 */
export const QUEUE_CONFIG__GET_ACTIONS_DETAIL = 'QUEUE_CONFIG__GET_ACTIONS_DETAIL';
export const getActionsDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${QUEUE_CONFIG__GET_ACTIONS_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/actions/detail?id=${id}`,
    });

    dispatch({
      type: `${QUEUE_CONFIG__GET_ACTIONS_DETAIL}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${QUEUE_CONFIG__GET_ACTIONS_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * CREATE Actions Detail
 */
export const QUEUE_CONFIG__CREATE_ACTIONS_DETAIL = 'QUEUE_CONFIG__CREATE_ACTIONS_DETAIL';
export const createActionsDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${QUEUE_CONFIG__CREATE_ACTIONS_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/actions`,
      data: {
        values: parseValues(item.values),
      },
    });

    dispatch({
      type: `${QUEUE_CONFIG__CREATE_ACTIONS_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Akce vytvořena',
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${QUEUE_CONFIG__CREATE_ACTIONS_DETAIL}__${REJECTED_VALIDATION}`,
        payload: parsedError,
      });
      return;
    }

    dispatch({
      type: `${QUEUE_CONFIG__CREATE_ACTIONS_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * UPDATE Actions Detail
 */
export const QUEUE_CONFIG__UPDATE_ACTIONS_DETAIL = 'QUEUE_CONFIG__UPDATE_ACTIONS_DETAIL';
export const updateActionsDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${QUEUE_CONFIG__UPDATE_ACTIONS_DETAIL}__${PENDING}`,
    });

    const values = parseValues(item.values);
    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/actions`,
      data: {
        id: item.id,
        values,
      },
    });

    dispatch({
      type: `${QUEUE_CONFIG__UPDATE_ACTIONS_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Akce upravena',
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${QUEUE_CONFIG__UPDATE_ACTIONS_DETAIL}__${REJECTED_VALIDATION}`,
        payload: {
          ...parsedError,
          message: {
            code: '',
            params: {},
          },
        },
      });
      return;
    }

    dispatch({
      type: `${QUEUE_CONFIG__UPDATE_ACTIONS_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * DELETE Actions Detail
 */
export const QUEUE_CONFIG__DELETE_ACTIONS_DETAIL = 'QUEUE_CONFIG__DELETE_ACTIONS_DETAIL';
export const deleteActionsDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${QUEUE_CONFIG__DELETE_ACTIONS_DETAIL}__${PENDING}`,
      payload: {
        id,
      },
    });

    const response = await api({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/actions?id=${id}`,
    });

    dispatch({
      type: `${QUEUE_CONFIG__DELETE_ACTIONS_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        id,
        notif: {
          type: FORM_SUCCESS,
          title: 'Akce smazána',
        },
      },
    });
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 428) {
      dispatch({
        type: `${QUEUE_CONFIG__DELETE_ACTIONS_DETAIL}__${REJECTED_COLLISON}`,
        payload: {
          ...parsedError,
          message: {
            code: 'CANT_DELETE',
            params: {},
          },
          id,
        },
      });
      return;
    }

    dispatch({
      type: `${QUEUE_CONFIG__DELETE_ACTIONS_DETAIL}__${REJECTED}`,
      payload: {
        ...parsedError,
        id,
      },
    });
  }
};


/*
 * Toggle Actions delete Modal
 */
export const QUEUE_CONFIG__TOGGLE_ACTIONS_DELETE_MODAL = 'QUEUE_CONFIG__TOGGLE_ACTIONS_DELETE_MODAL';
export const toggleActionsDeleteModalAction = (isOpen, id = null) => ({
  type: `${QUEUE_CONFIG__TOGGLE_ACTIONS_DELETE_MODAL}`,
  payload: {
    id,
    isOpen,
  },
});
