import React, { Component, } from 'react';
import {
  func, object, shape,
} from 'prop-types';

import {
  changePartValueLogic,
  changePartValidationValueLogic,
  changePartTranspaltionValueLogic,
} from '../../logic/editableGenForm/methods';
import StructNode from './StructNode';
import EditableGenFormView from './EditableGenFormView';
import { FORM_ROOT, } from '../../globals';
import PartModal from './PartModal';


class EditableGenForm extends Component {
  componentDidMount = () => {
    const {
      commonActions: {
        onGetRegexes,
        onGetOptions,
        onGetGroups,
        onGetGroupInputs,
      },
    } = this.props;

    onGetRegexes();
    onGetOptions();
    onGetGroups();
    onGetGroupInputs();
  }


  handleChangeValue = (input, newVal) => {
    const {
      editableGenForm: {
        modal: {
          part,
        },
      },
      genFormActions: {
        onChangePart,
      },
    } = this.props;

    onChangePart(changePartValueLogic(part, input, newVal));
  }

  handleChangeValidationValue = (input, newVal) => {
    const {
      editableGenForm: {
        modal: {
          part,
        },
      },
      genFormActions: {
        onChangePart,
      },
    } = this.props;

    onChangePart(changePartValidationValueLogic(part, input, newVal));
  }

  handleChangeTranslationValue = (input, newVal) => {
    const {
      editableGenForm: {
        modal: {
          part,
        },
      },
      genFormActions: {
        onChangePart,
      },
    } = this.props;

    onChangePart(changePartTranspaltionValueLogic(part, input, newVal));
  }

  handleChangeRootPart = (input, newVal) => {
    const {
      editableGenForm: {
        parts,
      },
      genFormActions: {
        onChangeRootPart,
      },
    } = this.props;

    onChangeRootPart(changePartValueLogic(parts[FORM_ROOT], input, newVal));
  }

  render() {
    const {
      // data
      editableGenForm,
      commonData,
      // methods
      commonActions,
      genFormActions: {
        onAddPart,
        onEditPart,
        onClosePart,
        onSavePart,
        onChangePartType,
        onRemovePart,
        onMovePart,
      },
    } = this.props;

    return (
      <EditableGenFormView
        data={editableGenForm.parts[FORM_ROOT]}
        msg={editableGenForm.msg}
        onChangeValue={this.handleChangeRootPart}
      >
        <StructNode
          {...editableGenForm}
          actions={{
            onAddPart,
            onEditPart,
            onRemovePart,
            onMovePart,
          }}
        />

        <PartModal
          modal={editableGenForm.modal}
          commonData={commonData}
          commonActions={commonActions}
          partActions={{
            onSavePart,
            onClosePart,
            onChangePartType,
            onChangeValue: this.handleChangeValue,
            onChangeValidationValue: this.handleChangeValidationValue,
            onChangeTranslationValue: this.handleChangeTranslationValue,
          }}
        />
      </EditableGenFormView>
    );
  }
}


EditableGenForm.propTypes = {
  editableGenForm: object.isRequired,
  commonData: object.isRequired,
  commonActions: object.isRequired,
  genFormActions: shape({
    onAddPart: func.isRequired,
    onEditPart: func.isRequired,
    onClosePart: func.isRequired,
    onSavePart: func.isRequired,
    onRemovePart: func.isRequired,
    onChangePart: func.isRequired,
    onChangePartType: func.isRequired,
    onMovePart: func.isRequired,
    onChangeRootPart: func.isRequired,
  }).isRequired,
};


export default EditableGenForm;
