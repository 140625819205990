import React, { Fragment, } from 'react';
import {
  shape, func, object,
} from 'prop-types';

import { withLang, } from '../../../../logic/languages/withLang';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';
import CheckBox from '../../../../atoms/CheckBox/CheckBox';
import Input from '../../../../atoms/Input/Input';

import { FORM_ERROR, FORM_SUCCESS, } from '../../../../globals';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import SegmentLoading from '../../../../components/SegmentLaoding/SegmentLaoding';
import SegmentError from '../../../../components/SegmentError/SegmentError';
import Pencil from '../../../../styles/icons/Pencil';

const StorekeeperConfigurationForm = ({
  // data
  getData,
  updateData,
  form,
  values,
  // methods
  langError,
  onChangeValue,
  onEdit,
  onGetDetail,
  isFormValid,
}) => {
  if (getData.isLoading) {
    return <SegmentLoading formGroup />;
  }
  if (getData.error) {
    return (
      <SegmentError
        formGroup
        message={getData.message}
        refresh={onGetDetail}
      />
    );
  }

  return (
    <Fragment>
      <Row formGroup>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
          Odhlásit po uplynutí (v s)
        </Col>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
          <Input
            type="number"
            value={values.inactiveLogoutTime}
            status={form.inactiveLogoutTime.status}
            onChange={(e) => onChangeValue(form.inactiveLogoutTime.name, e.target.value)}
          />
        </Col>
        <Col MD_offset={3} LG_offset={6} sidePadding>
          <InfoMsg
            status={form.inactiveLogoutTime.status}
            msg={langError(form.inactiveLogoutTime.statusMsg)}
            showIcon
          />
        </Col>
      </Row>
      <Row formGroup>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
          Zpoždění pro opakované vyvolání (v s)
        </Col>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
          <Input
            type="number"
            value={values.repeatCallDelay}
            status={form.repeatCallDelay.status}
            onChange={(e) => onChangeValue(form.repeatCallDelay.name, e.target.value)}
          />
        </Col>
        <Col MD_offset={3} LG_offset={6} sidePadding>
          <InfoMsg
            status={form.repeatCallDelay.status}
            msg={langError(form.repeatCallDelay.statusMsg)}
            showIcon
          />
        </Col>
      </Row>

      <Row formGroup>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
        <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
          <CheckBox
            id={form.usernameAndPasswordLoginPossible.name}
            text="Přihlášení pomocí jména a hesla"
            checked={values.usernameAndPasswordLoginPossible}
            onChange={() => onChangeValue(
              form.usernameAndPasswordLoginPossible.name,
              !values.usernameAndPasswordLoginPossible
            )}
          />
        </Col>
        <Col MD_offset={3} LG_offset={6} sidePadding>
          <InfoMsg
            status={form.usernameAndPasswordLoginPossible.status}
            msg={langError(form.usernameAndPasswordLoginPossible.statusMsg)}
            showIcon
          />
        </Col>
      </Row>

      <Row formGroup>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
        <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
          <CheckBox
            id={form.cardReadLoginPossible.name}
            text="Přihlášení pomocí karty"
            checked={values.cardReadLoginPossible}
            onChange={() => onChangeValue(
              form.cardReadLoginPossible.name,
              !values.cardReadLoginPossible
            )}
          />
        </Col>
        <Col MD_offset={3} LG_offset={6} sidePadding>
          <InfoMsg
            status={form.cardReadLoginPossible.status}
            msg={langError(form.cardReadLoginPossible.statusMsg)}
            showIcon
          />
        </Col>
      </Row>

      <Row formGroup>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
        <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
          <CheckBox
            id={form.pinLoginPossible.name}
            text="Přihlášení pomocí PINu"
            checked={values.pinLoginPossible}
            onChange={() => onChangeValue(
              form.pinLoginPossible.name,
              !values.pinLoginPossible
            )}
          />
        </Col>
        <Col MD_offset={3} LG_offset={6} sidePadding>
          <InfoMsg
            status={form.pinLoginPossible.status}
            msg={langError(form.pinLoginPossible.statusMsg)}
            showIcon
          />
        </Col>
      </Row>

      <Row>
        <Col textAlign="right">
          <InfoMsg
            status={updateData.error ? FORM_ERROR : FORM_SUCCESS}
            msg={langError(updateData.message)}
            showIcon
          />
        </Col>
        <Col textAlign="right">
          <ButtonLoader
            type="button"
            color="primary"
            onClick={onEdit}
            disabled={!isFormValid}
            isLoading={updateData.isLoading}
          >
            <span className="btn--icon">
              <Pencil />
            </span>
            Upravit
          </ButtonLoader>
        </Col>
      </Row>
    </Fragment>
  );
};


StorekeeperConfigurationForm.propTypes = {
  getData: object.isRequired,
  updateData: object.isRequired,
  form: shape({
    usernameAndPasswordLoginPossible: object.isRequired,
    pinLoginPossible: object.isRequired,
    cardReadLoginPossible: object.isRequired,
  }).isRequired,
  values: object.isRequired,
  onChangeValue: func.isRequired,
  onEdit: func.isRequired,
  onGetDetail: func.isRequired,
  isFormValid: func.isRequired,
  langError: func.isRequired,
};


export default withLang(StorekeeperConfigurationForm);
