import React, { Fragment, } from 'react';
import {
  object, func,
} from 'prop-types';

import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import PlatformsTable from './PlatformsTable';
import Heading from '../../../../atoms/Heading/Heading';
import Button from '../../../../atoms/Button/Button';


const Platforms = ({
  // data
  table,
  platformsData,
  // methods
  handleGetPlatforms,
  handleRestore,
  handleEdit,
  handleDelete,
  handleCreate,
  changeFilter,
}) => (
  <Fragment>

    <Heading h4>
      Rampy
    </Heading>

    <Row formGroup>
      <Col>
        <Button
          type="button"
          onClick={handleCreate}
          color="success"
          shape="outline"
        >
          Vytvořit rampu
        </Button>
      </Col>
    </Row>

    <PlatformsTable
      data={table.data}
      table={table}
      filter={table.filter}
      count={table.count}
      platformsData={platformsData}
      handleGetPlatforms={handleGetPlatforms}
      handleRestore={handleRestore}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      changeFilter={changeFilter}
    />

  </Fragment>
);


Platforms.propTypes = {
  table: object.isRequired,
  platformsData: object.isRequired,
  changeFilter: func.isRequired,
  handleGetPlatforms: func.isRequired,
  handleRestore: func.isRequired,
  handleEdit: func.isRequired,
  handleDelete: func.isRequired,
  handleCreate: func.isRequired,
};


export default Platforms;
