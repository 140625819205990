import styled from 'styled-components';

const AddPartButton = styled.button`
  position: relative;
  font-size: 0.85rem;
  font-weight: 700;
  border: 2px solid ${(p) => p.theme.grey.t800};
  background-color: white;
  padding: 5px;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.grey.t200};
  }

  &:disabled {
    cursor: default;
  }

`;

export default AddPartButton;
