export const initRestData = {
  isLoading: false,
  status: false,
  error: false,
  statusCode: null,
  messageInternal: '',
  message: {
    code: '',
    params: {},
  },
};


export const handlePending = () => ({
  isLoading: true,
  status: false,
  error: false,
  statusCode: null,
  messageInternal: 'pending',
  message: {
    code: '',
    params: {},
  },
});


export const handleFulfilled = (payload) => ({
  isLoading: false,
  status: true,
  error: false,
  statusCode: payload.statusCode,
  messageInternal: payload.messageInternal,
  message: payload.message,
});


export const handleRejected = (payload) => ({
  isLoading: false,
  status: false,
  error: true,
  statusCode: payload.statusCode,
  messageInternal: payload.messageInternal,
  message: payload.message,
});
