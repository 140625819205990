import styled from 'styled-components';

import media from '../../../styles/media';


const StyledAppSidebar = styled.div`
  width: 220px;
  z-index: 10;
  transition: width .4s ease-in-out;

  ${() => media.XS`
    height: ${(p) => p.currentHeight}px;
  `}

  ${() => media.SM`
    height: ${(p) => p.currentHeight}px;
  `}
  ${() => media.LG`
    height: 100%;
  `}
`;


export default StyledAppSidebar;
