import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION, FORM_SUCCESS, FORM_ERROR,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


const parseValues = (values) => ({
  name: values.name,
  width: values.width,
  height: values.height,
});


/**
 * Refresh data - on mount
 */
export const DEVICES__CONTROLLERS_REFRESH = 'DEVICES__CONTROLLERS_REFRESH';

export const refreshControllersDetailAction = () => ({
  type: `${DEVICES__CONTROLLERS_REFRESH}`,
});


/**
 * Change Value
 */
export const DEVICES__CONTROLLERS_CHANGE_VALUE_DETAIL = 'DEVICES__CONTROLLERS_CHANGE_VALUE_DETAIL';

export const changeValueAction = (name, value, validationError) => ({
  type: `${DEVICES__CONTROLLERS_CHANGE_VALUE_DETAIL}`,
  payload: {
    name,
    value,
    validationError,
  },
});


/**
 * Validate Form
 */
export const DEVICES__CONTROLLERS_VALIDATE_FORM_DETAIL = 'DEVICES__CONTROLLERS_VALIDATE_FORM_DETAIL';

export const validateFormAction = (error) => ({
  type: `${DEVICES__CONTROLLERS_VALIDATE_FORM_DETAIL}`,
  payload: error,
});


/*
 * GET CONTROLLERS Detail
 */
export const DEVICES__GET_CONTROLLERS_DETAIL = 'DEVICES__GET_CONTROLLERS_DETAIL';
export const getControllersDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__GET_CONTROLLERS_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/devices/controller/detail?id=${id}`,
    });

    dispatch({
      type: `${DEVICES__GET_CONTROLLERS_DETAIL}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${DEVICES__GET_CONTROLLERS_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * UPDATE CONTROLLERS Detail
 */
export const DEVICES__UPDATE_CONTROLLERS_DETAIL = 'DEVICES__UPDATE_CONTROLLERS_DETAIL';
export const updateControllersDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__UPDATE_CONTROLLERS_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/devices/controller`,
      data: {
        id: item.id,
        values: parseValues(item.values),
      },
    });

    dispatch({
      type: `${DEVICES__UPDATE_CONTROLLERS_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Disp. kontrolér aktualizován',
          showMessage: true,
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${DEVICES__UPDATE_CONTROLLERS_DETAIL}__${REJECTED_VALIDATION}`,
        payload: parsedError,
      });
      return;
    }

    dispatch({
      type: `${DEVICES__UPDATE_CONTROLLERS_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * DELETE Controllers Detail
 */
export const DEVICES__DELETE_CONTROLLERS_DETAIL = 'DEVICES__DELETE_CONTROLLERS_DETAIL';
export const deleteControllerDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__DELETE_CONTROLLERS_DETAIL}__${PENDING}`,
      payload: {
        id,
      },
    });

    const response = await api({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/devices/controller?id=${id}`,
    });
    dispatch({
      type: `${DEVICES__DELETE_CONTROLLERS_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        id,
        notif: {
          type: FORM_SUCCESS,
          title: 'Disp. kontrolér smazán',
          showMessage: true,
        },
      },
    });
  } catch (err) {
    const parsedError = handleError(err);

    dispatch({
      type: `${DEVICES__DELETE_CONTROLLERS_DETAIL}__${REJECTED}`,
      payload: {
        ...parsedError,
        id,
        notif: {
          type: FORM_ERROR,
          title: 'Nepovedlo se smazat kontrolér',
          showMessage: true,
        },
      },
    });
  }
};
