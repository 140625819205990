import {
  PENDING, FULFILLED, REJECTED, FORM_SUCCESS, REJECTED_VALIDATION,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';
import { mergeFormAndValidation, validateWholeFormValidator, } from '../../../logic/forms/validation';

/**
 * Refresh data - on mount
 */
export const TRANSPORTATION_EDIT_FORMS__REFRESH = 'TRANSPORTATION_EDIT_FORMS__REFRESH';
export const refreshFormDetailAction = () => ({
  type: `${TRANSPORTATION_EDIT_FORMS__REFRESH}`,
});


/**
 * ChangeFormValue
 */
export const TRANSPORTATION_EDIT_FORMS__CHANGE_FORM = 'TRANSPORTATION_EDIT_FORMS__CHANGE_FORM';
export const changeFormValueAction = (name, value, validationError) => ({
  type: `${TRANSPORTATION_EDIT_FORMS__CHANGE_FORM}`,
  payload: {
    name,
    value,
    validationError,
  },
});


/*
 * GET Options
 */
export const TRANSPORTATION_EDIT_FORMS__OPTIONS = 'TRANSPORTATION_EDIT_FORMS__OPTIONS';
export const getOptionsAction = () => async (dispatch) => {
  try {
    dispatch({
      type: `${TRANSPORTATION_EDIT_FORMS__OPTIONS}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/transportation_edit_forms/options`,
    });

    dispatch({
      type: `${TRANSPORTATION_EDIT_FORMS__OPTIONS}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${TRANSPORTATION_EDIT_FORMS__OPTIONS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * GET Options
 */
export const TRANSPORTATION_EDIT_FORMS__CREATE = 'TRANSPORTATION_EDIT_FORMS__CREATE';
export const TRANSPORTATION_EDIT_FORMS__VALIDATION = 'TRANSPORTATION_EDIT_FORMS__VALIDATION';
export const createAction = ({ onCompleted, }) => async (dispatch, getState) => {
  const {
    transportationEditFormsDetail: {
      detail: {
        isValid,
        form,
        values,
      },
    },
  } = getState();

  if (isValid) {
    const validation = validateWholeFormValidator(form, values);

    // Confirm
    if (Object.keys(validation).length < 1) {
      try {
        dispatch({
          type: `${TRANSPORTATION_EDIT_FORMS__CREATE}__${PENDING}`,
        });

        const response = await api({
          method: 'post',
          url: `${process.env.REACT_APP_API_URL}/transportation_edit_forms`,
          data: {
            ...values,
          },
        });

        dispatch({
          type: `${TRANSPORTATION_EDIT_FORMS__CREATE}__${FULFILLED}`,
          payload: response,
        });

        if (onCompleted) onCompleted();
      } catch (err) {
        const parsedError = handleError(err);

        if (parsedError.statusCode === 422) {
          dispatch({
            type: `${TRANSPORTATION_EDIT_FORMS__CREATE}__${REJECTED_VALIDATION}`,
            payload: parsedError,
          });
          return;
        }

        dispatch({
          type: `${TRANSPORTATION_EDIT_FORMS__CREATE}__${REJECTED}`,
          payload: parsedError,
        });
      }

    // Invalid form
    } else {
      const validatedForm = mergeFormAndValidation(form, validation);
      dispatch({
        type: TRANSPORTATION_EDIT_FORMS__VALIDATION,
        payload: validatedForm,
      });
    }
  }
};


/*
 * GET Detail
 */
export const TRANSPORTATION_EDIT_FORMS__GET_DETAIL = 'TRANSPORTATION_EDIT_FORMS__GET_DETAIL';
export const getDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${TRANSPORTATION_EDIT_FORMS__GET_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/transportation_edit_forms/detail?id=${id}`,
    });

    dispatch({
      type: `${TRANSPORTATION_EDIT_FORMS__GET_DETAIL}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${TRANSPORTATION_EDIT_FORMS__GET_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


export const TRANSPORTATION_EDIT_FORMS__EDIT = 'TRANSPORTATION_EDIT_FORMS__EDIT';
export const editAction = ({ onCompleted, }) => async (dispatch, getState) => {
  const {
    transportationEditFormsDetail: {
      detail: {
        id,
        isValid,
        form,
        values,
      },
    },
  } = getState();

  if (isValid) {
    const validation = validateWholeFormValidator(form, values);

    // Confirm
    if (Object.keys(validation).length < 1) {
      try {
        dispatch({
          type: `${TRANSPORTATION_EDIT_FORMS__EDIT}__${PENDING}`,
        });

        const response = await api({
          method: 'put',
          url: `${process.env.REACT_APP_API_URL}/transportation_edit_forms`,
          data: {
            id,
            ...values,
          },
        });

        dispatch({
          type: `${TRANSPORTATION_EDIT_FORMS__EDIT}__${FULFILLED}`,
          payload: response,
        });

        if (onCompleted) onCompleted();
      } catch (err) {
        const parsedError = handleError(err);

        if (parsedError.statusCode === 422) {
          dispatch({
            type: `${TRANSPORTATION_EDIT_FORMS__EDIT}__${REJECTED_VALIDATION}`,
            payload: parsedError,
          });
          return;
        }

        dispatch({
          type: `${TRANSPORTATION_EDIT_FORMS__EDIT}__${REJECTED}`,
          payload: parsedError,
        });
      }

    // Invalid form
    } else {
      const validatedForm = mergeFormAndValidation(form, validation);
      dispatch({
        type: TRANSPORTATION_EDIT_FORMS__VALIDATION,
        payload: validatedForm,
      });
    }
  }
}

/*
 * DELETE Detail
 */
export const TRANSPORTATION_EDIT_FORMS__DELETE_DETAIL = 'TRANSPORTATION_EDIT_FORMS__DELETE_DETAIL';
export const deleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${TRANSPORTATION_EDIT_FORMS__DELETE_DETAIL}__${PENDING}`,
      payload: {
        id,
      },
    });

    const response = await api({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/transportation_edit_forms?id=${id}`,
    });

    dispatch({
      type: `${TRANSPORTATION_EDIT_FORMS__DELETE_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        id,
        notif: {
          type: FORM_SUCCESS,
          title: 'Formulář smazán',
        },
      },
    });
  } catch (err) {
    const parsedError = handleError(err);

    dispatch({
      type: `${TRANSPORTATION_EDIT_FORMS__DELETE_DETAIL}__${REJECTED}`,
      payload: {
        ...parsedError,
        id,
        message: parsedError.statusCode === 428 ? {
          code: 'CANT_DELETE',
          params: {},
        } : parsedError.message,
      },
    });
  }
};


/*
 * Toggle delete Modal
 */
export const TRANSPORTATION_EDIT_FORMS__TOGGLE_DELETE_DETAIL_MODAL = 'TRANSPORTATION_EDIT_FORMS__TOGGLE_DELETE_DETAIL_MODAL';
export const toggleModalDeleteAction = (isOpen = false, id = null) => ({
  type: `${TRANSPORTATION_EDIT_FORMS__TOGGLE_DELETE_DETAIL_MODAL}`,
  payload: {
    id,
    isOpen,
  },
});
