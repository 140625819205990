import React, { Component, Fragment, } from 'react';
import {
  shape, bool, func, object, string,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  validateFormAction,
  changeValueAction,
  getNumericalSeriesDetailAction,
  updateNumericalSeriesDetailAction,
} from '../actions/numericalSeriesDetail';

import NumericalSeriesEdit from '../components/NumericalSeriesEdit/NumericalSeriesEdit';
import NumericalSeriesDetail from '../components/NumericalSeriesDetail/NumericalSeriesDetail';

import Modal from '../../../atoms/Modal/Modal';
import { validateInput, validateWholeFormValidator, } from '../../../logic/forms/validation';

class NumericalSeriesEditModal extends Component {
  componentDidMount() {
    this.handleGetDetail();
  }

  handleGetDetail = () => {
    const { match, getNumericalSeriesDetail, } = this.props;

    getNumericalSeriesDetail(match.params.id);
  }

  handleClose = () => {
    const { history, } = this.props;

    history.goBack();
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);

    changeValue(name, value, validationError);
  }

  handleEdit = async () => {
    const {
      updateNumericalSeriesData,
      detail,
      validateForm,
      updateNumericalSeriesDetail,
    } = this.props;

    if (updateNumericalSeriesData.isLoading || !detail.isValid) return;

    const validation = validateWholeFormValidator(
      detail.form,
      detail.values,
    );

    if (Object.keys(validation).length < 1) {
      updateNumericalSeriesDetail(detail, this.handleEditSuccess);
    } else {
      validateForm(validation);
    }
  }

  handleEditSuccess = () => {
    const { refreshParent, } = this.props;

    refreshParent();
    this.handleClose();
  }

  render() {
    const {
      // data
      detailNumericalSeriesData,
      updateNumericalSeriesData,
      detail,
      // methods
    } = this.props;

    return (
      <Fragment>
        <Modal
          isOpen
          onClose={this.handleClose}
          closeOnEsc
          size="MD"
          title="Detail číselné řady"
        >
          <NumericalSeriesEdit
            // data
            detailNumericalSeriesData={detailNumericalSeriesData}
            updateNumericalSeriesData={updateNumericalSeriesData}
            isFormValid={detail.isValid}
            // methods
            handleClose={this.handleClose}
            handleEdit={this.handleEdit}
            onGetDetail={this.handleGetDetail}
          >
            <NumericalSeriesDetail
              // data
              values={detail.values}
              form={detail.form}
              // methods
              handleClose={this.handleClose}
              handleOnChangeValue={this.handleOnChangeValue}
            />
          </NumericalSeriesEdit>
        </Modal>

      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { numericalSeriesDetail, } = state;
  return {
    detailNumericalSeriesData: numericalSeriesDetail.detailNumericalSeriesData,
    updateNumericalSeriesData: numericalSeriesDetail.updateNumericalSeriesData,
    detail: numericalSeriesDetail.detail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getNumericalSeriesDetail: bindActionCreators(getNumericalSeriesDetailAction, dispatch), // eslint-disable-line
  updateNumericalSeriesDetail: bindActionCreators(updateNumericalSeriesDetailAction, dispatch), // eslint-disable-line
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
});


NumericalSeriesEditModal.propTypes = {
  detailNumericalSeriesData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  updateNumericalSeriesData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  detail: shape({
    isValid: bool.isRequired,
    form: object.isRequired,
    values: object.isRequired,
    oldValues: object.isRequired,
  }).isRequired,
  match: shape({
    params: shape({
      id: string.isRequired,
    }).isRequired,
  }).isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
  getNumericalSeriesDetail: func.isRequired,
  refreshParent: func.isRequired,
  updateNumericalSeriesDetail: func.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(NumericalSeriesEditModal);
