import styled from 'styled-components';


const CollisionFragment = styled.div`
  margin-bottom: 0.5rem;

  .collision--title {
    font-weight: bold;
  }

  .collision--text {
    font-style: italic;
    padding-left: ${(p) => p.theme.common.padding};
  }
  
  .collision--data {
    padding-left: 0.4rem;
    font-weight: bold;
  }

  /* max-height */
  ${(p) => p.maxHeight && `
    .collision--list {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: ${p.maxHeight}px;
    }
  `}

  ${(p) => p.lvl1 && `
    padding-left: ${p.theme.common.padding};
  `}

  /* FormGroup */
  ${(p) => p.formGroup && `
    margin-bottom: ${p.theme.formGroup.marginBottom}
  `}
`;


export default CollisionFragment;
