import {
  DEVICES__GET_DISPLAYS,
  DEVICES__DISPLAYS_FILTER_CHANGED,
  DEVICES__DELETE_DISPLAYS_DETAIL,
} from '../actions/displays';
import { PENDING, FULFILLED, REJECTED, } from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';


const initialState = {
  displaysData: {
    ...initRestData,
    isLoading: true,
  },
  table: {
    data: [],
    count: 0,
    filter: {
      offset: 0,
      limit: 10,
      sortBy: 'id',
      order: 'ASC',
      params: {
        id: '',
        name: '',
        subtype: 'all',
      },
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;
  switch (type) {
    /**
     * GET Displays
     */
    case `${DEVICES__GET_DISPLAYS}__${PENDING}`: {
      return {
        ...state,
        displaysData: {
          ...state.displaysData,
          ...(handlePending()),
        },
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    case `${DEVICES__GET_DISPLAYS}__${FULFILLED}`: {
      return {
        ...state,
        displaysData: {
          ...state.displaysData,
          ...(handleFulfilled(payload)),
        },
        table: {
          data: payload.displays,
          count: payload.count,
          filter: payload.filter,
        },
      };
    }

    case `${DEVICES__GET_DISPLAYS}__${REJECTED}`: {
      return {
        ...state,
        displaysData: {
          ...state.displaysData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
     * DELETE Display Detail
     */
    case `${DEVICES__DELETE_DISPLAYS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.map((item) => {
            if (item.id === payload.id) {
              return {
                ...item,
                ...(handlePending()),
              };
            }
            return item;
          }),
        },
      };
    }

    case `${DEVICES__DELETE_DISPLAYS_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.filter((item) => item.id !== payload.id),
        },
      };
    }

    case `${DEVICES__DELETE_DISPLAYS_DETAIL}__${REJECTED}`: {
      const { id, } = payload;

      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                ...(handleRejected(payload)),
              };
            }
            return item;
          }),
        },
      };
    }


    /**
     * Change Filter
     */
    case `${DEVICES__DISPLAYS_FILTER_CHANGED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
