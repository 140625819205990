import React from 'react';
import {
  shape, func, object, oneOfType, arrayOf, bool, node,
} from 'prop-types';

import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import InputSelectWrapper from '../../../../components/wrappers/InputSelectWrapper';


const MapFormsDetail = ({
  // data
  transportationTypesData,
  value,
  disabled,
  // methods
  onGetTransporationTypes,
  onChange,
  // children
  children,
}) => (
  <Row>
    <Col LG={12} MD={18} SM={24} LG_offset={6} MD_offset={3}>
      <Row formGroup>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
          Typ přepravy
        </Col>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
          <InputSelectWrapper
            value={value}
            options={transportationTypesData.data}
            onChange={onChange}
            disabled={disabled}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isLoading={transportationTypesData.isLoading}
            error={transportationTypesData.error}
            fetchData={onGetTransporationTypes}
          />
        </Col>
      </Row>
      {children}
    </Col>
  </Row>
);


MapFormsDetail.propTypes = {
  transportationTypesData: shape({
    isLoading: bool.isRequired,
    error: bool.isRequired,
    data: object,
  }).isRequired,
  value: object,
  disabled: bool.isRequired,
  onGetTransporationTypes: func.isRequired,
  onChange: func.isRequired,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};


MapFormsDetail.defaultProps = {
  value: null,
};


export default MapFormsDetail;
