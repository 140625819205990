import React, { Component, Fragment, } from 'react';
import {
  shape, bool, arrayOf, func, string, number,
} from 'prop-types';

import RTRow from '../../../../atoms/Table/RTRow';
import RTColHeader from '../../../../atoms/Table/RTColHeader';
import RTCol from '../../../../atoms/Table/RTCol';
import RTBody from '../../../../atoms/Table/RTBody';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import ButtonGrp from '../../../../atoms/ButtonGrp/ButtonGrp';
import Detail from '../../../../styles/icons/Detail';
import Configure from '../../../../styles/icons/Configure';
import Restore from '../../../../styles/icons/Restore';
import Trash from '../../../../styles/icons/Trash';
import RTLoader from '../../../../atoms/Table/RTLoader';
import RTError from '../../../../atoms/Table/RTError';
import RTNoData from '../../../../atoms/Table/RTNoData';


class ControllersTableRow extends Component {
  renderRows = () => {
    const {
      // data
      data,
      cols,
      controllersData,
      // methods
      onGetControllers,
      onEdit,
      onRegister,
      onDispEditor,
      onDelete,
    } = this.props;

    const isLoading = controllersData.isLoading; // eslint-disable-line
    const noData = !isLoading && data.length < 1;

    if (!isLoading && controllersData.error) {
      return (
        <RTError
          data={controllersData}
          refresh={() => onGetControllers()}
        />
      );
    }

    return (
      <Fragment>
        {isLoading && (
          <RTLoader />
        )}

        {noData && (
          <RTNoData />
        )}

        {data.map((item) => (
          <RTRow key={item.id}>

            <RTColHeader>{`${cols[0].label}:`}</RTColHeader>
            <RTCol width={cols[0].width}>{item.id}</RTCol>

            <RTColHeader>{`${cols[1].label}:`}</RTColHeader>
            <RTCol width={cols[1].width}>{item.name}</RTCol>

            <RTColHeader>{`${cols[2].label}:`}</RTColHeader>
            <RTCol width={cols[2].width}>{item.deviceCode}</RTCol>


            <RTColHeader>{`${cols[3].label}:`}</RTColHeader>
            <RTCol width={cols[3].width}>
              <span className={item.synchronized ? 'app--color-success' : 'app--color-error'}>
                {item.synchronized ? 'Ano' : 'ne'}
              </span>
            </RTCol>

            <RTColHeader>{`${cols[4].label}:`}</RTColHeader>
            <RTCol width={cols[4].width}>
              <span className={item.online ? 'app--color-success' : 'app--color-error'}>
                {item.online ? 'Online' : 'Offline'}
              </span>
            </RTCol>

            <RTColHeader>{`${cols[5].label}:`}</RTColHeader>
            <RTCol width={cols[5].width}>
              {item.synchronized ? (
                <ButtonGrp>
                  <Button
                    shape="outline"
                    size="sm"
                    color="secondary"
                    onClick={() => onEdit(item.id)}
                  >
                    <span className="rt--col-icon-first"><Detail /></span>
                    Detail
                  </Button>
                  <Button
                    shape="outline"
                    size="sm"
                    color="secondary"
                    onClick={() => onDispEditor(item.id)}
                  >
                    <span className="rt--col-icon-first"><Configure /></span>
                    Konfigurace displejů
                  </Button>
                  <ButtonLoader
                    shape="outline"
                    size="sm"
                    color="error"
                    onClick={() => onDelete(item)}
                    isLoading={item.isLoading}
                  >
                    <span className="rt--col-icon-first"><Trash /></span>
                    Smazat
                  </ButtonLoader>
                </ButtonGrp>
              ) : (
                <Button
                  shape="outline"
                  size="sm"
                  color="success"
                  onClick={() => onRegister(item.id)}
                >
                  <span className="btn--icon"><Restore /></span>
                  Registrovat
                </Button>
              )}
            </RTCol>
          </RTRow>
        ))}

      </Fragment>
    );
  }

  render() {
    return (
      <RTBody>
        {this.renderRows()}
      </RTBody>
    );
  }
}


ControllersTableRow.propTypes = {
  data: arrayOf(shape({
    id: number.isRequired,
    name: string,
  })).isRequired,
  cols: arrayOf(shape({
    label: string.isRequired,
    width: number.isRequired,
  })).isRequired,
  controllersData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  onGetControllers: func.isRequired,
  onEdit: func.isRequired,
  onRegister: func.isRequired,
  onDispEditor: func.isRequired,
  onDelete: func.isRequired,
};


export default ControllersTableRow;
