import styled from 'styled-components';
import { FORM_SUCCESS, FORM_WARNING, FORM_ERROR, } from '../../../globals';


const StyledNotif = styled.div`
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  width: 300px;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: ${(p) => p.theme.common.shadow};
  padding: ${(p) => p.theme.common.padding};
  color: ${(p) => p.theme.white};
  opacity: 0.9;
  margin-bottom: 4px;

  .notif--icon {
    font-size: 1.75rem;
    padding-right: 0.5rem;
  }

  .notif--title {
    font-weight: bold;
  }

  .notif--text {
    font-size: 0.9rem;
  }

  /*
   * Succes
   */
  ${(p) => (p.type && p.type === FORM_SUCCESS) && `
    background: ${p.theme.success.t800};

    &:hover {
      background: ${p.theme.success.t900};
    }
  `}

  /*
   * Warning
   */
  ${(p) => (p.type && p.type === FORM_WARNING) && `
    background: ${p.theme.warning.t800};

    &:hover {
      background: ${p.theme.warning.t900};
    }
  `}

  /*
   * Error
   */
  ${(p) => (p.type && p.type === FORM_ERROR) && `
    background: ${p.theme.error.t800};

    &:hover {
      background: ${p.theme.error.t900};
    }
  `}
`;


export default StyledNotif;
