import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION, FORM_SUCCESS,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


const parseValues = (values) => ({
  inactiveLogoutTime: values.inactiveLogoutTime,
  repeatCallDelay: values.repeatCallDelay,
  usernameAndPasswordLoginPossible: values.usernameAndPasswordLoginPossible,
  pinLoginPossible: values.pinLoginPossible,
  cardReadLoginPossible: values.cardReadLoginPossible,
});


/**
 * Change Value
 */
export const STOREKEEPER_CONFIGURATION__CHANGE_VALUE_SETTINGS = 'STOREKEEPER_CONFIGURATION__CHANGE_VALUE_SETTINGS';
export const changeValueAction = (name, value, validationError) => ({
  type: `${STOREKEEPER_CONFIGURATION__CHANGE_VALUE_SETTINGS}`,
  payload: {
    name,
    value,
    validationError,
  },
});


/**
 * Validate Form
 */
export const STOREKEEPER_CONFIGURATION__VALIDATE_FORM_SETTINGS = 'STOREKEEPER_CONFIGURATION__VALIDATE_FORM_SETTINGS';
export const validateFormAction = (error) => ({
  type: `${STOREKEEPER_CONFIGURATION__VALIDATE_FORM_SETTINGS}`,
  payload: error,
});


/*
 * GET Storekeeper Settings
 */
export const STOREKEEPER_CONFIGURATION__GET_SETTINGS = 'STOREKEEPER_CONFIGURATION__GET_SETTINGS';
export const getStorekeeperConfigurationAction = () => async (dispatch) => {
  try {
    dispatch({
      type: `${STOREKEEPER_CONFIGURATION__GET_SETTINGS}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/devices/storekeeper/configuration`,
    });

    dispatch({
      type: `${STOREKEEPER_CONFIGURATION__GET_SETTINGS}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${STOREKEEPER_CONFIGURATION__GET_SETTINGS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * UPDATE Storekeeper Settings
 */
export const STOREKEEPER_CONFIGURATION__UPDATE_SETTINGS = 'STOREKEEPER_CONFIGURATION__UPDATE_SETTINGS';
export const updateStorekeeperConfigurationAction = (item) => async (dispatch) => {
  try {
    dispatch({
      type: `${STOREKEEPER_CONFIGURATION__UPDATE_SETTINGS}__${PENDING}`,
    });

    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/devices/storekeeper/configuration`,
      data: {
        values: parseValues(item.values),
      },
    });

    dispatch({
      type: `${STOREKEEPER_CONFIGURATION__UPDATE_SETTINGS}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Konfigurace pro aplikaci Skladník aktualizována',
        },
      },
    });
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${STOREKEEPER_CONFIGURATION__UPDATE_SETTINGS}__${REJECTED_VALIDATION}`,
        payload: parsedError,
      });
      return;
    }

    dispatch({
      type: `${STOREKEEPER_CONFIGURATION__UPDATE_SETTINGS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};
