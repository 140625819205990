import { INPUT_TYPE, } from 'sofair-form-validation';

import {
  PLATFORMS__GET_PLATFORMS_DETAIL,
  PLATFORMS__CREATE_PLATFORMS_DETAIL,
  PLATFORMS__UPDATE_PLATFORMS_DETAIL,
  PLATFORMS__VALIDATE_FORM_DETAIL,
  PLATFORMS__CHANGE_VALUE_DETAIL,
  PLATFORMS__REFRESH,
  PLATFORMS__FORCE_DELETE_PLATFORMS_DETAIL,
  PLATFORMS__FORCE_UPDATE_PLATFORMS_DETAIL,
  PLATFORMS__TOGGLE_FORCE_UPDATE_PLATFORMS_MODAL,
  PLATFORMS__TOGGLE_FORCE_DELETE_MODAL,
  PLATFORMS__DELETE_PLATFORMS_DETAIL,
} from '../actions/platformsDetail';
import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION, REJECTED_COLLISON,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';
import {
  mergeFormAndValidation, initInput, changeFormValue,
} from '../../../logic/forms/validation';
import { parseArrayFromApi, } from '../../../logic/utils/array';


const initialState = {
  detailPlatformsData: {
    ...initRestData,
    isLoading: true,
  },
  createPlatformsData: {
    ...initRestData,
  },
  updatePlatformsData: {
    ...initRestData,
  },
  forceUpdatePlatformsData: {
    ...initRestData,
  },
  forceDeleteData: {
    ...initRestData,
  },
  forceUpdateModal: {
    isOpen: false,
    reservations: {
      conflict: false,
      data: 0,
    },
    transports: {
      conflict: false,
      data: [],
    },
  },
  forceDeleteModal: {
    id: null,
    isOpen: false,
    reservations: {
      conflict: false,
      data: 0,
    },
    transports: {
      conflict: false,
      data: [],
    },
  },
  detail: {
    id: null,
    isValid: true,
    invalidInputs: 0,
    form: {
      name: {
        ...initInput,
        name: 'name',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: true,
          min: 2,
          max: 255,
        },
      },
      no: {
        ...initInput,
        name: 'no',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: true,
          min: 1,
          max: 5,
        },
      },
      capacity: {
        ...initInput,
        name: 'capacity',
        type: INPUT_TYPE.NUMBER,
        validation: {
          ...initInput.validation,
          required: true,
          min: 1,
          max: 100,
        },
      },
      transportationTypes: {
        ...initInput,
        name: 'transportationTypes',
        type: INPUT_TYPE.MULTISELECT,
      },
    },
    values: {
      name: '',
      no: '',
      capacity: '0',
      transportationTypes: [],
    },
    oldValues: {},
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;

  switch (type) {
    /**
     * Refresh Data
     */
    case `${PLATFORMS__REFRESH}`: {
      return {
        ...state,
        createPlatformsData: {
          ...initialState.createPlatformsData,
        },
        updatePlatformsData: {
          ...initialState.updatePlatformsData,
        },
        detail: {
          ...initialState.detail,
        },
      };
    }


    /**
     * Change Form
     */
    case `${PLATFORMS__CHANGE_VALUE_DETAIL}`: {
      const newFormData = changeFormValue(
        state.detail.form, state.detail.values, state.detail.invalidInputs, payload
      );

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: newFormData.invalidInputs < 1,
          invalidInputs: newFormData.invalidInputs,
          form: newFormData.form,
          values: newFormData.values,
        },
      };
    }


    /**
     * Validate form
     */
    case `${PLATFORMS__VALIDATE_FORM_DETAIL}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload);

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }


    /**
     * GET Platforms Detail
     */
    case `${PLATFORMS__GET_PLATFORMS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        detailPlatformsData: {
          ...initialState.detailPlatformsData,
          ...(handlePending()),
        },
        updatePlatformsData: {
          ...initialState.updatePlatformsData,
        },
        detail: {
          ...initialState.detail,
        },
      };
    }

    case `${PLATFORMS__GET_PLATFORMS_DETAIL}__${FULFILLED}`: {
      try {
        const newValues = {
          ...state.detail.values,
          name: payload.data.platformTranslation.name,
          no: payload.data.no,
          capacity: payload.data.capacity.toString(),
          transportationTypes: parseArrayFromApi(payload.data.transportationTypes.map((row) => ({
            id: row.id,
            name: row.transportationTypeTranslation.name,
          }))),
        };

        return {
          ...state,
          detailPlatformsData: {
            ...state.detailPlatformsData,
            ...(handleFulfilled(payload)),
          },
          detail: {
            ...state.detail,
            id: payload.data.id,
            values: newValues,
            oldValues: newValues,
          },
        };
      } catch (err) {
        return {
          ...state,
          detailPlatformsData: {
            ...state.detailPlatformsData,
            ...(handleRejected({
              statusCode: -1,
              messageInternal: err.message,
              message: {
                code: 'SOMETHING_HAPPEND',
                params: {},
              },
            })),
          },
        };
      }
    }

    case `${PLATFORMS__GET_PLATFORMS_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        detailPlatformsData: {
          ...state.detailPlatformsData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * CREATE Platforms Detail
     */
    case `${PLATFORMS__CREATE_PLATFORMS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        createPlatformsData: {
          ...state.createPlatformsData,
          ...(handlePending()),
        },
      };
    }

    case `${PLATFORMS__CREATE_PLATFORMS_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        createPlatformsData: {
          ...state.createPlatformsData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${PLATFORMS__CREATE_PLATFORMS_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data);

      return {
        ...state,
        createPlatformsData: {
          ...state.createPlatformsData,
          ...(handleRejected(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${PLATFORMS__CREATE_PLATFORMS_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        createPlatformsData: {
          ...state.createPlatformsData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * UPDATE Platforms Detail
     */
    case `${PLATFORMS__UPDATE_PLATFORMS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        updatePlatformsData: {
          ...state.updatePlatformsData,
          ...(handlePending()),
        },
      };
    }

    case `${PLATFORMS__UPDATE_PLATFORMS_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        updatePlatformsData: {
          ...state.updatePlatformsData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${PLATFORMS__UPDATE_PLATFORMS_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data);

      return {
        ...state,
        updatePlatformsData: {
          ...state.updatePlatformsData,
          ...(handleRejected(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${PLATFORMS__UPDATE_PLATFORMS_DETAIL}__${REJECTED_COLLISON}`: {
      return {
        ...state,
        updatePlatformsData: {
          ...state.updatePlatformsData,
          ...(handleRejected(payload)),
        },
        forceUpdateModal: {
          ...state.forceUpdateModal,
          isOpen: true,
          reservations: payload.data.reservations,
          transports: payload.data.transports,
        },
      };
    }

    case `${PLATFORMS__UPDATE_PLATFORMS_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        updatePlatformsData: {
          ...state.updatePlatformsData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
     * FORCE UPDATE Platforms Detail
     */
    case `${PLATFORMS__FORCE_UPDATE_PLATFORMS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        forceUpdatePlatformsData: {
          ...state.forceUpdatePlatformsData,
          ...(handlePending()),
        },
      };
    }

    case `${PLATFORMS__FORCE_UPDATE_PLATFORMS_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        updatePlatformsData: {
          ...initialState.updatePlatformsData,
        },
        forceUpdatePlatformsData: {
          ...initialState.forceUpdatePlatformsData,
        },
        forceUpdateModal: {
          ...initialState.forceUpdateModal,
        },
      };
    }

    case `${PLATFORMS__FORCE_UPDATE_PLATFORMS_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data);

      return {
        ...state,
        forceUpdatePlatformsData: {
          ...initialState.forceUpdatePlatformsData,
        },
        forceUpdateModal: {
          ...initialState.forceUpdateModal,
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${PLATFORMS__FORCE_UPDATE_PLATFORMS_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        forceUpdatePlatformsData: {
          ...state.forceUpdatePlatformsData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * FORCE DELETE Platforms Detail
     */
    case `${PLATFORMS__FORCE_DELETE_PLATFORMS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        forceDeleteData: {
          ...state.forceDeleteData,
          ...(handlePending()),
        },
      };
    }

    case `${PLATFORMS__FORCE_DELETE_PLATFORMS_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        forceDeleteData: {
          ...state.forceDeleteData,
          ...(handleFulfilled(payload)),
        },
        forceDeleteModal: {
          ...initialState.forceDeleteModal,
        },
      };
    }

    case `${PLATFORMS__FORCE_DELETE_PLATFORMS_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        forceDeleteData: {
          ...state.forceDeleteData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
     * Toggle Modal Force Update Platform
     */
    case `${PLATFORMS__TOGGLE_FORCE_UPDATE_PLATFORMS_MODAL}`: {
      return {
        ...state,
        forceUpdateModal: {
          ...initialState.forceUpdateModal,
        },
        forceUpdatePlatformsData: {
          ...initialState.forceUpdatePlatformsData,
        },
      };
    }


    /**
     * Toggle Modal Force Delete Platform
     */
    case `${PLATFORMS__TOGGLE_FORCE_DELETE_MODAL}`: {
      return {
        ...state,
        forceDeleteModal: {
          ...initialState.forceDeleteModal,
        },
        forceDeleteData: {
          ...initialState.forceDeleteData,
        },
      };
    }


    /**
     * Delete Platform
     */
    case `${PLATFORMS__DELETE_PLATFORMS_DETAIL}__${REJECTED_COLLISON}`: {
      return {
        ...state,
        forceDeleteData: {
          ...initialState.forceDeleteData,
        },
        forceDeleteModal: {
          ...initialState.forceDeleteModal,
          id: payload.id,
          isOpen: true,
          reservations: payload.data.reservations,
          transports: payload.data.transports,
        },
      };
    }


    default:
      return state;
  }
};

export default reducer;
