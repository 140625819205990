import React from 'react';
import { shape, string, } from 'prop-types';
import { Switch, Route, Redirect, } from 'react-router-dom';

import ParkingsPage from './ParkingsPage';
import KiosksLayout from './KiosksLayout';
import DisplaysLayout from './DisplaysLayout';
import StorekeeperLayout from './StorekeeperLayout';
import CamerasPage from './CamerasPage';
import NotFoundPage from '../../../templates/NotFound/NotFoundPage';
import BlankTemp from '../../../templates/Spaces/BlankTemp';


const DevicesLayout = ({ match, }) => (
  <BlankTemp>
    <Switch>
      <Redirect exact from={`${match.path}/kiosks`} to={`${match.path}/kiosks/main`} />
      <Route path={`${match.path}/parkings`} component={ParkingsPage} />
      <Route path={`${match.path}/kiosks/main`} component={KiosksLayout} />
      <Route path={`${match.path}/kiosks/configuration`} component={KiosksLayout} />
      <Redirect exact from={`${match.path}/displays`} to={`${match.path}/displays/main`} />
      <Route path={`${match.path}/displays/main`} component={DisplaysLayout} />
      <Route path={`${match.path}/displays/configuration`} component={DisplaysLayout} />
      <Route path={`${match.path}/displays/controllers`} component={DisplaysLayout} />
      <Redirect exact from={`${match.path}/storekeeper`} to={`${match.path}/storekeeper/main`} />
      <Route path={`${match.path}/storekeeper/main`} component={StorekeeperLayout} />
      <Route path={`${match.path}/storekeeper/configuration`} component={StorekeeperLayout} />
      <Route path={`${match.path}/cameras`} component={CamerasPage} />
      <Route component={NotFoundPage} />
    </Switch>
  </BlankTemp>
);


DevicesLayout.propTypes = {
  match: shape({
    path: string.isRequired,
  }).isRequired,
};


export default DevicesLayout;
