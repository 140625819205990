import { PENDING, FULFILLED, REJECTED, } from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


/*
 * GET Transportation Types
 */
export const TRANSPORTATION_TYPES__GET_TRANSPORTATION_TYPES = 'TRANSPORTATION_TYPES__GET_TRANSPORTATION_TYPES';
export const getTransportationTypesAction = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: `${TRANSPORTATION_TYPES__GET_TRANSPORTATION_TYPES}__${PENDING}`,
      payload: filter,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/transportation_types/filter`,
      data: filter,
    });

    dispatch({
      type: `${TRANSPORTATION_TYPES__GET_TRANSPORTATION_TYPES}__${FULFILLED}`,
      payload: response.data,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${TRANSPORTATION_TYPES__GET_TRANSPORTATION_TYPES}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/**
 * Filter Changed
 */
export const TRANSPORTATION_TYPES__FILTER_CHANGED = 'TRANSPORTATION_TYPES__FILTER_CHANGED';

export const changeFilterAction = (filter) => ({
  type: `${TRANSPORTATION_TYPES__FILTER_CHANGED}`,
  payload: filter,
});
