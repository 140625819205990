import React, { Fragment, } from 'react';
import {
  shape, number, bool, object, func, arrayOf,
} from 'prop-types';

import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import ControllersTable from './ControllersTable';
import Restore from '../../../../styles/icons/Restore';


const Controllers = ({
  // data
  table,
  controllersData,
  // methods
  onGetControllers,
  onEdit,
  onRegister,
  onDispEditor,
  onDelete,
  changeFilter,
}) => (
  <Fragment>

    <Row formGroup>
      <Col>
        <ButtonLoader
          onClick={() => onGetControllers()}
          color="warning"
          shape="outline"
          isLoading={controllersData.isLoading}
        >
          <span className="btn--icon"><Restore /></span>
          Synchronizovat
        </ButtonLoader>
      </Col>
    </Row>

    <ControllersTable
      data={table.data}
      table={table}
      filter={table.filter}
      count={table.count}
      controllersData={controllersData}
      onGetControllers={onGetControllers}
      onEdit={onEdit}
      onRegister={onRegister}
      onDispEditor={onDispEditor}
      onDelete={onDelete}
      changeFilter={changeFilter}
    />

  </Fragment>
);


Controllers.propTypes = {
  table: shape({
    data: arrayOf(object).isRequired,
    count: number.isRequired,
    filter: object.isRequired,
  }).isRequired,
  controllersData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  changeFilter: func.isRequired,
  onGetControllers: func.isRequired,
  onEdit: func.isRequired,
  onDispEditor: func.isRequired,
  onRegister: func.isRequired,
  onDelete: func.isRequired,
};


export default Controllers;
