import {
  DEVICES__GET_DISPLAYS_CONFIGURATION,
  DEVICES__GET_DISPLAYS_CONFIGURATION_FILTER_CHANGED,
} from '../actions/displaysConfiguration';
import { PENDING, FULFILLED, REJECTED, } from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';


const initialState = {
  displaysConfigurationData: {
    ...initRestData,
    isLoading: true,
  },
  table: {
    data: [],
    count: 0,
    filter: {
      offset: 0,
      limit: 10,
      sortBy: 'id',
      order: 'ASC',
      params: {
        id: '',
        name: '',
        type: 'all',
      },
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;
  switch (type) {
    /**
     * GET Displays Configuration
     */
    case `${DEVICES__GET_DISPLAYS_CONFIGURATION}__${PENDING}`: {
      return {
        ...state,
        displaysConfigurationData: {
          ...state.displaysConfigurationData,
          ...(handlePending()),
        },
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    case `${DEVICES__GET_DISPLAYS_CONFIGURATION}__${FULFILLED}`: {
      return {
        ...state,
        displaysConfigurationData: {
          ...state.displaysConfigurationData,
          ...(handleFulfilled(payload)),
        },
        table: {
          data: payload.configuration,
          count: payload.count,
          filter: payload.filter,
        },
      };
    }

    case `${DEVICES__GET_DISPLAYS_CONFIGURATION}__${REJECTED}`: {
      return {
        ...state,
        displaysConfigurationData: {
          ...state.displaysConfigurationData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * DELETE Kiosks configuration

    case `${DEVICES__DELETE_KIOSKS_CONFIGURATION_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.filter((item) => item.id !== payload.id),
        },
      };
    }
*/

    /**
     * Change Filter
     */
    case `${DEVICES__GET_DISPLAYS_CONFIGURATION_FILTER_CHANGED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
