/**
 * REMOVE Notification
 */
export const APP__NOTIF_REMOVE = 'APP__NOTIF_REMOVE';
export const removeNotifAction = (id) => ({
  type: `${APP__NOTIF_REMOVE}`,
  payload: {
    id,
  },
});
