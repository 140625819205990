import styled from 'styled-components';

import media from '../../styles/media';


const RTHeader = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  ${media.XS`
    display: none;
  `}
`;


export default RTHeader;
