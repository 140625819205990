import React, { Component, } from 'react';
import {
  shape, func, number, string, oneOfType,
} from 'prop-types';

import Button from '../../../../atoms/Button/Button';
import StyledDisplay from './styles/StyledDisplay';
import StyledToolTip from './styles/StyledToolTip';
import Cross from '../../../../styles/icons/Cross';
import Pencil from '../../../../styles/icons/Pencil';

class Display extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHover: false,
    };
  }

  showTooltip = () => {
    this.setState({ isHover: true, });
  }

  hideTooltip = () => {
    this.setState({ isHover: false, });
  }

  render() {
    const {
      data,
      activeId,
      posX,
      posY,
      dragMouseDown,
      removeDisplay,
      editDisplay,
    } = this.props;
    const { isHover, } = this.state;

    if (activeId === data.id) {
      return (
        <StyledDisplay
          key={data.id}
          width={data.width}
          height={data.height}
          top={posY}
          left={posX}
          isActive="true"
          onMouseDown={(e) => dragMouseDown(e, data)}
        >
          <div style={{ position: 'relative', top: '2rem', }} onMouseEnter={this.showTooltip} onMouseLeave={this.hideTooltip}>
            {data.id.toString().substring(0, 5)}
            <StyledToolTip isHover={isHover}>
              {`ID: ${data.id}`}
              <br />
              {`Horni roh: [${posX}, ${posY}]`}
              <br />
              {`Dolni roh:[${posX + data.width}, ${posY + data.height}]`}
            </StyledToolTip>
          </div>
          <div style={{ position: 'absolute', top: '1px', right: '1px', }}>
            <Button title="Upravit" size="xs" width="30px" onClick={() => editDisplay('edit', data.id)}><span style={{ left: '-2px', position: 'relative', }}><Pencil /></span></Button>
            <Button title="Smazat" size="xs" width="30px" color="error" onClick={() => removeDisplay(data.id)}><span style={{ left: '-2px', position: 'relative', }}><Cross /></span></Button>
          </div>
        </StyledDisplay>
      );
    }

    return (
      <StyledDisplay
        key={data.id}
        width={data.width}
        height={data.height}
        top={data.posY}
        left={data.posX}
        isActive={false}
        onMouseDown={(e) => dragMouseDown(e, data)}
      >
        <div style={{ position: 'relative', top: '2rem', }} onMouseEnter={this.showTooltip} onMouseLeave={this.hideTooltip}>
          {data.id.toString().substring(0, 5)}
          <StyledToolTip isHover={isHover}>
            {`ID: ${data.id}`}
            <br />
            {`Horni roh: [${data.posX}, ${data.posY}]`}
            <br />
            {`Dolni roh:[${data.posX + data.width}, ${data.posY + data.height}]`}
          </StyledToolTip>
        </div>
        <div style={{ position: 'absolute', top: '1px', right: '1px', }}>
          <Button title="Upravit" size="xs" width="30px" onClick={() => editDisplay('edit', data.id)}><span style={{ left: '-2px', position: 'relative', }}><Pencil /></span></Button>
          <Button title="Smazat" size="xs" width="30px" color="error" onClick={() => removeDisplay(data.id)}><span style={{ left: '-2px', position: 'relative', }}><Cross /></span></Button>
        </div>
      </StyledDisplay>
    );
  }
}

Display.propTypes = {
  data: shape({
    width: number.isRequired,
    height: number.isRequired,
    posX: number.isRequired,
    posY: number.isRequired,
  }).isRequired,
  activeId: oneOfType(
    [ string, number, ]
  ).isRequired,
  posX: number.isRequired,
  posY: number.isRequired,
  dragMouseDown: func.isRequired,
  removeDisplay: func.isRequired,
  editDisplay: func.isRequired,
};

export default Display;
