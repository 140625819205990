import React, { Component, Fragment, } from 'react';
import { INPUT_TYPE, } from 'sofair-form-validation';
import {
  bool, func, string, oneOfType, shape, number, object,
} from 'prop-types';
import uuidv4 from 'uuid/v4';
import { withLang, } from '../../../../logic/languages/withLang';
import Button from '../../../../atoms/Button/Button';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import ButtonGrp from '../../../../atoms/ButtonGrp/ButtonGrp';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';
import Add from '../../../../styles/icons/Add';
import Pencil from '../../../../styles/icons/Pencil';
import Trash from '../../../../styles/icons/Trash';
import Input from '../../../../atoms/Input/Input';
import Divider from '../../../../atoms/Divider/Divider';
import CheckBox from '../../../../atoms/CheckBox/CheckBox';
import {
  initInput, changeFormValue, validateInput, validateWholeFormValidator, mergeFormAndValidation,
} from '../../../../logic/forms/validation';
import { onlyNumbersInString, } from '../../../../logic/forms/utils';
import InputSelectWrapper from '../../../../components/wrappers/InputSelectWrapper';
import { POSITION_ARROWS, } from '../../../../globals';

const displayLocationStandOption = { id: 'stand', name: 'Stanoviště', };
const displayLocationPlatformsOption = { id: 'platforms', name: 'Platformy', };
const displayLocationOptionsObject = {
  stand: displayLocationStandOption,
  platforms: displayLocationPlatformsOption,
};
const displayLocationOptions = [ displayLocationStandOption, displayLocationPlatformsOption, ];

class DisplayForm extends Component {
  constructor(props) {
    super(props);
    let values = {
      name: '',
      width: '64',
      height: '64',
      multiple: false,
      number: '1',
      posX: '0',
      posY: '0',
      subtype: 'ramp',
      displayConfigurationId: null,
      displayLocation: null,
      platforms: [],
      stand: null,
    };

    if (props.type === 'edit') {
      values = {
        name: props.editedDisplay.name,
        width: props.editedDisplay.width,
        height: props.editedDisplay.height,
        posX: props.editedDisplay.posX,
        posY: props.editedDisplay.posY,
        subtype: props.editedDisplay.subtype,
        displayConfigurationId: props.editedDisplay.displayConfigurationId,
        displayConfiguration: props.editedDisplay.displayConfiguration,
        displayLocation: props.editedDisplay.displayLocation
          ? displayLocationOptionsObject[props.editedDisplay.displayLocation] : null,
        platforms: props.editedDisplay.platforms,
        stand: props.editedDisplay.stand ? {
          id: props.editedDisplay.stand.id, name: props.editedDisplay.stand.standTranslation.name,
        } : null,
      };
    }

    this.state = {
      displayDetail: {
        isValid: true,
        invalidInputs: 0,
        form: {
          name: {
            ...initInput,
            name: 'name',
            type: INPUT_TYPE.TEXT,
            validation: {
              ...initInput.validation,
              required: true,
              min: 2,
              max: 255,
            },
          },
          width: {
            ...initInput,
            name: 'width',
            type: INPUT_TYPE.NUMBER,
            validation: {
              ...initInput.validation,
              required: true,
              min: 64,
              max: 7680,
            },
          },
          height: {
            ...initInput,
            name: 'height',
            type: INPUT_TYPE.NUMBER,
            validation: {
              ...initInput.validation,
              required: true,
              min: 64,
              max: 4320,
            },
          },
          number: {
            ...initInput,
            name: 'number',
            type: INPUT_TYPE.NUMBER,
            validation: {
              ...initInput.validation,
              required: false,
              min: 1,
              max: 100,
            },
          },
          posX: {
            ...initInput,
            name: 'posX',
            type: INPUT_TYPE.NUMBER,
            validation: {
              ...initInput.validation,
              required: false,
              min: 0,
              max: 10000,
            },
          },
          posY: {
            ...initInput,
            name: 'posY',
            type: INPUT_TYPE.NUMBER,
            validation: {
              ...initInput.validation,
              required: false,
              min: 0,
              max: 10000,
            },
          },
          multiple: {
            ...initInput,
            name: 'multiple',
            type: INPUT_TYPE.CHECKBOX,
            validation: {
              ...initInput.validation,
              required: false,
              min: 0,
              max: 10000,
            },
          },
          subtype: {
            ...initInput,
            name: 'subtype',
            type: INPUT_TYPE.SELECT,
            validation: {
              ...initInput.validation,
              required: true,
            },
          },
          displayConfigurationId: {
            ...initInput,
            name: 'displayConfigurationId',
            type: INPUT_TYPE.SELECT,
            validation: {
              ...initInput.validation,
              required: true,
            },
          },
          displayLocation: {
            ...initInput,
            name: 'displayLocation',
            type: INPUT_TYPE.SELECT,
            validation: {
              ...initInput.validation,
              required: false,
            },
          },
          platforms: {
            ...initInput,
            name: 'platforms',
            type: INPUT_TYPE.MULTISELECT,
            validation: {
              ...initInput.validation,
              required: false,
            },
          },
          stand: {
            ...initInput,
            name: 'stand',
            type: INPUT_TYPE.SELECT,
            validation: {
              ...initInput.validation,
              required: false,
            },
          },
        },
      },
      values,
    };
  }

  handleOnChangeValue = (name, value) => {
    const { values, displayDetail, } = this.state;
    const { displayConfigurationSharedData, } = this.props;

    if (name === 'subtype' && values.subtype !== value) {
      values.displayConfiguration = null;
      values.displayLocation = null;
      values.stand = null;
      values.platforms = [];
    }

    values[`${name}`] = value;
    const validationError = validateInput(displayDetail.form[name], value);
    const newFormData = changeFormValue(
      displayDetail.form, values, displayDetail.invalidInputs, { name, value, validationError, },
    );
    displayDetail.form = newFormData.form;
    displayDetail.invalidInputs = newFormData.invalidInputs;
    if (newFormData.invalidInputs > 0) {
      displayDetail.isValid = false;
    } else {
      displayDetail.isValid = true;
    }
    if (name === 'displayConfigurationId') {
      values.displayConfiguration = displayConfigurationSharedData.data[value];
    }
    this.setState({ values, displayDetail, });
  }

  handleSendForm = () => {
    const {
      onCreateForm,
    } = this.props;
    const { values, displayDetail, } = this.state;
    const validation = validateWholeFormValidator(displayDetail.form, values);
    if (Object.keys(validation).length < 1) {
      onCreateForm(values);
    } else {
      displayDetail.isValid = false;
      const validatedForm = mergeFormAndValidation(displayDetail.form, validation);
      displayDetail.invalidInputs = validatedForm.invalidInputs;
      displayDetail.form = validatedForm.form;
      this.setState({ displayDetail, });
    }
  }

  handleAddPlatform = () => {
    const { values, } = this.state;
    values.platforms.push({ platform: null, position: 0, });
    this.setState({ values, });
  }

  handleRemovePlatform = (index) => {
    const { values, } = this.state;
    delete values.platforms[index];
    this.setState({ values, });
  }

  handleOnChangeInPlatform = (parameter, index, value) => {
    const { values, } = this.state;
    values.platforms[index][parameter] = value;
    this.setState({ values, });
  }

  renderPositionInputs = () => {
    const { values, displayDetail, } = this.state;
    const { langError, } = this.props;
    return (
      <Fragment>
        <Row formGroup>
          <Col SM={24} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
            Pozice X (levý horní roh)
          </Col>
          <Col SM={20} MD={16} LG={12} sidePadding>
            <Input
              name="posX"
              value={values.posX}
              status={displayDetail.form.posX.status}
              onChange={
                (e) => this.handleOnChangeValue(e.target.name, onlyNumbersInString(e.target.value))
              }
              min="0"
            />
          </Col>
          <Col LG_offset={6} sidePadding>
            <InfoMsg
              status={displayDetail.form.posX.status}
              msg={langError(displayDetail.form.posX.statusMsg)}
              showIcon
            />
          </Col>
        </Row>
        <Row formGroup>
          <Col SM={24} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
            Pozice Y (levý horní roh)
          </Col>
          <Col SM={20} MD={16} LG={12} sidePadding>
            <Input
              name="posY"
              value={values.posY}
              status={displayDetail.form.posY.status}
              onChange={
                (e) => this.handleOnChangeValue(e.target.name, onlyNumbersInString(e.target.value))
              }
              min="0"
            />
          </Col>
          <Col LG_offset={6} sidePadding>
            <InfoMsg
              status={displayDetail.form.posY.status}
              msg={langError(displayDetail.form.posY.statusMsg)}
              showIcon
            />
          </Col>
        </Row>
      </Fragment>
    );
  }

  renderNumberOfDisplaysInput = () => {
    const { values, displayDetail, } = this.state;
    const { langError, } = this.props;
    return (
      <Row formGroup>
        <Col SM={24} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
          Počet displejů pro přidání
        </Col>
        <Col SM={20} MD={16} LG={12} sidePadding>
          <Input
            name="number"
            value={values.number}
            status={displayDetail.form.number.status}
            onChange={
              (e) => this.handleOnChangeValue(e.target.name, onlyNumbersInString(e.target.value))
            }
            min="0"
          />
        </Col>
        <Col LG_offset={6} sidePadding>
          <InfoMsg
            status={displayDetail.form.number.status}
            msg={langError(displayDetail.form.number.statusMsg)}
            showIcon
          />
        </Col>
      </Row>
    );
  }

  renderCheckBox = (isMultipleAllowed) => {
    const { values, } = this.state;
    if (isMultipleAllowed) {
      return (
        <Row formGroup>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
          <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
            <CheckBox
              id="multiple"
              text="Přidat více displejů najednou?"
              checked={values.multiple}
              onChange={() => this.handleOnChangeValue('multiple', !values.multiple)}
            />
          </Col>
          <Col MD_offset={3} LG_offset={6} sidePadding />
        </Row>
      );
    }
    return ('');
  }

  renderPartForm = (isMultipleAllowed) => {
    const { values, } = this.state;
    if (isMultipleAllowed) {
      if (values.multiple) {
        return this.renderNumberOfDisplaysInput();
      }
      return this.renderPositionInputs();
    }
    return this.renderPositionInputs();
  }

  render() {
    const {
      // data
      displaySubtypesSharedData,
      displayConfigurationSharedData,
      platformsSharedData,
      standsSharedData,
      isMultipleAllowed,
      // methods
      langError,
      onClose,
      type,
      getDisplaySubtypesShared,
      getDisplayConfigurationShared,
      getStandsShared,
    } = this.props;

    const { values, displayDetail, } = this.state;

    return (
      <Fragment>
        <Row formGroup>
          <Col SM={24} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
            Jméno (označení)
          </Col>
          <Col SM={20} MD={16} LG={12} sidePadding>
            <Input
              type="text"
              name="name"
              value={values.name}
              status={displayDetail.form.name.status}
              onChange={(e) => this.handleOnChangeValue(e.target.name, e.target.value)}
            />
          </Col>
          <Col LG_offset={6} sidePadding>
            <InfoMsg
              status={displayDetail.form.name.status}
              msg={langError(displayDetail.form.name.statusMsg)}
              showIcon
            />
          </Col>
        </Row>
        <Row formGroup>
          <Col SM={24} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
            Šířka (v px)
          </Col>
          <Col SM={20} MD={16} LG={12} sidePadding>
            <Input
              name="width"
              value={values.width}
              status={displayDetail.form.width.status}
              onChange={
                (e) => this.handleOnChangeValue(e.target.name, onlyNumbersInString(e.target.value))
              }
              min="0"
            />
          </Col>
          <Col LG_offset={6} sidePadding>
            <InfoMsg
              status={displayDetail.form.width.status}
              msg={langError(displayDetail.form.width.statusMsg)}
              showIcon
            />
          </Col>
        </Row>
        <Row formGroup>
          <Col SM={24} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
            Výška (v px)
          </Col>
          <Col SM={20} MD={16} LG={12} sidePadding>
            <Input
              name="height"
              value={values.height}
              status={displayDetail.form.height.status}
              onChange={
                (e) => this.handleOnChangeValue(e.target.name, onlyNumbersInString(e.target.value))
              }
              min="0"
            />
          </Col>
          <Col LG_offset={6} sidePadding>
            <InfoMsg
              status={displayDetail.form.height.status}
              msg={langError(displayDetail.form.height.statusMsg)}
              showIcon
            />
          </Col>
        </Row>
        {this.renderCheckBox(isMultipleAllowed)}
        {this.renderPartForm(isMultipleAllowed)}
        <Row formGroup>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
            Varianta
          </Col>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
            <InputSelectWrapper
              value={
                Object.hasOwnProperty.call(
                  displaySubtypesSharedData.data, values.subtype
                ) ? displaySubtypesSharedData.data[values.subtype] : null
              }
              status={displayDetail.form.subtype.status}
              options={displaySubtypesSharedData.data}
              onChange={
                (value) => {
                  getDisplayConfigurationShared(value === null ? null : value.id);
                  this.handleOnChangeValue(
                    displayDetail.form.subtype.name, value === null ? null : value.id
                  );
                }
              }
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isLoading={displaySubtypesSharedData.isLoading}
              error={displaySubtypesSharedData.error}
              fetchData={getDisplaySubtypesShared}
            />
          </Col>
          <Col MD_offset={3} LG_offset={6} sidePadding>
            <InfoMsg
              status={displayDetail.form.subtype.status}
              msg={langError(displayDetail.form.subtype.statusMsg)}
              showIcon
            />
          </Col>
        </Row>

        <Row formGroup>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
            Konfigurace
          </Col>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
            <InputSelectWrapper
              value={values.displayConfiguration}
              status={displayDetail.form.displayConfigurationId.status}
              options={displayConfigurationSharedData.data}
              onChange={
                (value) => this.handleOnChangeValue(
                  displayDetail.form.displayConfigurationId.name, value === null ? null : value.id
                )
              }
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isLoading={displayConfigurationSharedData.isLoading}
              error={displayConfigurationSharedData.error}
              fetchData={() => getDisplayConfigurationShared(values.subtype)}
            />
          </Col>
          <Col MD_offset={3} LG_offset={6} sidePadding>
            <InfoMsg
              status={displayDetail.form.displayConfigurationId.status}
              msg={langError(displayDetail.form.displayConfigurationId.statusMsg)}
              showIcon
            />
          </Col>
        </Row>

        {values.subtype && values.subtype === 'calling'
        && (
          <Row formGroup>
            <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
              Umístění vyvolacího displaye
            </Col>
            <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
              <InputSelectWrapper
                value={values.displayLocation}
                status={displayDetail.form.displayLocation.status}
                options={displayLocationOptions}
                onChange={
                  (value) => this.handleOnChangeValue(
                    displayDetail.form.displayLocation.name, value === null ? null : value
                  )
                }
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              />
            </Col>
            <Col MD_offset={3} LG_offset={6} sidePadding>
              <InfoMsg
                status={displayDetail.form.displayLocation.status}
                msg={langError(displayDetail.form.displayLocation.statusMsg)}
                showIcon
              />
            </Col>
          </Row>
        )}

        {values.displayLocation && values.displayLocation.id === 'platforms'
          && (
            <Row formGroup>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={4} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
                Platformy
              </Col>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={18} sidePadding>
                <Divider />
                <InfoMsg
                  status={displayDetail.form.platforms.status}
                  msg={langError(displayDetail.form.platforms.statusMsg)}
                  showIcon
                />
              </Col>
            </Row>
          )
        }

        {values.displayLocation && values.displayLocation.id === 'platforms' && values.platforms.map((value, index) => (
          <Row formGroup key={value.platform === null ? uuidv4() : value.platform.id}>
            <Col MD_offset={3} MD={18} LG_offset={3} LG={8} sidePadding>
              <InputSelectWrapper
                value={value.platform}
                options={platformsSharedData.data}
                onChange={
                  (valueIn) => this.handleOnChangeInPlatform('platform', index, valueIn)
                }
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                isLoading={platformsSharedData.isLoading}
                error={platformsSharedData.error}
              />
            </Col>
            <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding>
              <InputSelectWrapper
                value={
                  Object.hasOwnProperty.call(
                    POSITION_ARROWS, value.position
                  ) ? POSITION_ARROWS[value.position] : null
                }
                options={POSITION_ARROWS}
                onChange={(valueIn) => this.handleOnChangeInPlatform('position', index, valueIn === null ? null : valueIn.id)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              />
            </Col>
            <Col MD_offset={3} MD={18} LG_offset={0} LG={4} sidePadding>
              <Button
                size="md"
                color="error"
                onClick={() => this.handleRemovePlatform(index)}
              >
                <span className="btn--icon">
                  <Trash />
                </span>
              </Button>
            </Col>
          </Row>
        ))
        }

        {values.displayLocation && values.displayLocation.id === 'platforms'
          && (
            <Row formGroup>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
                <Button onClick={this.handleAddPlatform}>
                  <span className="btn--icon">
                    <Add />
                  </span>
                </Button>
              </Col>
            </Row>)}


        {values.displayLocation && values.displayLocation.id === 'stand'
          && (
            <Row formGroup>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
                Stanoviště
              </Col>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
                <InputSelectWrapper
                  value={values.stand}
                  status={displayDetail.form.stand.status}
                  options={standsSharedData.data}
                  onChange={
                    (value) => this.handleOnChangeValue(
                      displayDetail.form.stand.name, value === null ? null : value
                    )
                  }
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isLoading={standsSharedData.isLoading}
                  error={standsSharedData.error}
                  fetchData={() => getStandsShared(true)}
                />
              </Col>
              <Col MD_offset={3} LG_offset={6} sidePadding>
                <InfoMsg
                  status={displayDetail.form.stand.status}
                  msg={langError(displayDetail.form.stand.statusMsg)}
                  showIcon
                />
              </Col>
            </Row>)}

        <Row>
          <Col textAlign="right">
            <ButtonGrp>
              <Button
                type="button"
                shape="outline"
                onClick={() => onClose()}
              >
                Zpět
              </Button>
              {type === 'add' ? (
                <Button
                  type="button"
                  color="primary"
                  disabled={!displayDetail.isValid}
                  onClick={() => this.handleSendForm()}
                >
                  <span className="btn--icon">
                    <Add />
                  </span>
                  Přidat
                </Button>
              ) : (
                <Button
                  type="button"
                  color="primary"
                  disabled={!displayDetail.isValid}
                  onClick={() => this.handleSendForm()}
                >
                  <span className="btn--icon">
                    <Pencil />
                  </span>
                  Upravit
                </Button>)
              }
            </ButtonGrp>
          </Col>
        </Row>
      </Fragment>);
  }
}

DisplayForm.propTypes = {
  type: string.isRequired,
  editedDisplay: oneOfType([shape({
    name: string.isRequired,
    width: number.isRequired,
    height: number.isRequired,
    posX: number.isRequired,
    posY: number.isRequired,
  }), bool, ]).isRequired,
  onCreateForm: func.isRequired,
  isMultipleAllowed: bool.isRequired,
  langError: func.isRequired,
  onClose: func.isRequired,
  getDisplaySubtypesShared: func.isRequired,
  getDisplayConfigurationShared: func.isRequired,
  displaySubtypesSharedData: object.isRequired,
  displayConfigurationSharedData: object.isRequired,
  platformsSharedData: object.isRequired,
  standsSharedData: object.isRequired,
  getStandsShared: func.isRequired,
};


export default withLang(DisplayForm);
