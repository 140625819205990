import {
  DEVICES__GET_KIOSKS_CONFIGURATIONS,
  DEVICES__GET_KIOSKS_CONFIGURATIONS_FILTER_CHANGED,
} from '../actions/kiosksConfigurations';
import {
  DEVICES__DELETE_KIOSKS_CONFIGURATION_DETAIL,
} from '../actions/kiosksConfigurationDetail';
import { PENDING, FULFILLED, REJECTED, } from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';


const initialState = {
  kiosksConfigurationsData: {
    ...initRestData,
    isLoading: true,
  },
  table: {
    data: [],
    count: 0,
    filter: {
      offset: 0,
      limit: 10,
      sortBy: 'id',
      order: 'ASC',
      params: {
        id: '',
        name: '',
      },
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;
  switch (type) {
    /**
     * GET Kiosk Konfigurations
     */
    case `${DEVICES__GET_KIOSKS_CONFIGURATIONS}__${PENDING}`: {
      return {
        ...state,
        kiosksConfigurationsData: {
          ...state.kiosksConfigurationsData,
          ...(handlePending()),
        },
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    case `${DEVICES__GET_KIOSKS_CONFIGURATIONS}__${FULFILLED}`: {
      return {
        ...state,
        kiosksConfigurationsData: {
          ...state.kiosksConfigurationsData,
          ...(handleFulfilled(payload)),
        },
        table: {
          data: payload.configs,
          count: payload.count,
          filter: payload.filter,
        },
      };
    }

    case `${DEVICES__GET_KIOSKS_CONFIGURATIONS}__${REJECTED}`: {
      return {
        ...state,
        kiosksConfigurationsData: {
          ...state.kiosksConfigurationsData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * DELETE Kiosks configuration
     */
    case `${DEVICES__DELETE_KIOSKS_CONFIGURATION_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.filter((item) => item.id !== payload.id),
        },
      };
    }


    /**
     * Change Filter
     */
    case `${DEVICES__GET_KIOSKS_CONFIGURATIONS_FILTER_CHANGED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
