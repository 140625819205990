import styled from 'styled-components';

import media from '../../../styles/media';


const StyledBlankTemp = styled.div`
  margin: 3rem 2rem;
  background: ${(p) => p.theme.white};
  padding: 1rem;

  ${() => media.XS`
    margin: 1rem 0rem;
  `}

  ${() => media.SM`
    margin: 2rem 1rem;
  `}
`;


export default StyledBlankTemp;
