import React from 'react';
import {
  shape, bool, func, object, number,
} from 'prop-types';

import { withLang, } from '../../../../logic/languages/withLang';
import { FORM_ERROR, FORM_SUCCESS, } from '../../../../globals';
import Modal from '../../../../atoms/Modal/Modal';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import ButtonGrp from '../../../../atoms/ButtonGrp/ButtonGrp';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';
import Trash from '../../../../styles/icons/Trash';


const LanguagesDeleteModal = ({
  modalData,
  actionData,
  onClose,
  action,
  langError,
}) => {
  if (!modalData.isOpen) return null;

  return (
    <Modal
      isOpen={modalData.isOpen}
      onClose={onClose}
      size="SM"
      closeOnEsc
      title="Smazání jazyku"
      color="error"
    >
      {(
        <Row formGroup>
          <Col>Potvrďte smazání jazyku</Col>
        </Row>
      )}

      <Row>
        <Col textAlign="right">
          <ButtonGrp>
            <Button
              type="button"
              shape="outline"
              onClick={onClose}
            >
              Zpět
            </Button>

            <ButtonLoader
              type="button"
              color="error"
              onClick={action}
              disabled={modalData.collision}
              isLoading={actionData.isLoading}
            >
              <span className="btn--icon"><Trash /></span>
              Smazat
            </ButtonLoader>
          </ButtonGrp>
        </Col>
        <Col textAlign="right">
          <InfoMsg
            status={actionData.error ? FORM_ERROR : FORM_SUCCESS}
            msg={langError(actionData.message)}
            showIcon
          />
        </Col>
      </Row>

    </Modal>
  );
};


LanguagesDeleteModal.propTypes = {
  modalData: shape({
    id: number,
    isOpen: bool.isRequired,
  }).isRequired,
  actionData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
    message: object.isRequired,
  }).isRequired,
  onClose: func.isRequired,
  action: func.isRequired,
  langError: func.isRequired,
};


export default withLang(LanguagesDeleteModal);
