import React, { Fragment, } from 'react';
import {
  arrayOf, object, bool, func, shape,
} from 'prop-types';

import { createOptionId, } from '../../actions/transportationEditFormsModalInput';
import CheckBox from '../../../../atoms/CheckBox/CheckBox';
import Button from '../../../../atoms/Button/Button';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import ButtonGrp from '../../../../atoms/ButtonGrp/ButtonGrp';
import Add from '../../../../styles/icons/Add';
import InputSelectWrapper from '../../../../components/wrappers/InputSelectWrapper';
import FormRow from './FormRow';


const InputModalView = ({
  // data
  typeOptions,
  inputForm: {
    isValid,
    form,
    values,
  },
  // methods
  onToggle,
  onChangeValue,
  onConfirm,
}) => (
  <Fragment>
    <FormRow
      label="Typ"
      status={form.type.status}
      statusMsg={form.type.statusMsg}
    >
      <InputSelectWrapper
        options={typeOptions}
        value={values.type}
        isClearable={false}
        getOptionValue={createOptionId}
        getOptionLabel={(option) => option.inputTranslation.label}
        onChange={(newVal) => onChangeValue(form.type.name, newVal)}
      />
    </FormRow>

    <FormRow
      label="Povinné?"
      status={form.required.status}
      statusMsg={form.required.statusMsg}
    >
      <CheckBox
        id={form.required.name}
        checked={values.required}
        onChange={() => onChangeValue(form.required.name, !values.required)}
        status={form.required.status}
      />
    </FormRow>

    <Row>
      <Col textAlign="right">
        <ButtonGrp>
          <Button
            type="button"
            shape="outline"
            onClick={onToggle}
          >
            Zpět
          </Button>

          <Button
            type="button"
            color="primary"
            onClick={onConfirm}
            disabled={!isValid}
          >
            Potvrdit
          </Button>
        </ButtonGrp>
      </Col>
    </Row>

  </Fragment>
);


InputModalView.propTypes = {
  // data
  typeOptions: arrayOf(object).isRequired,
  inputForm: shape({
    isValid: bool.isRequired,
    form: object.isRequired,
    values: object.isRequired,
  }).isRequired,
  // methods
  onToggle: func.isRequired,
  onChangeValue: func.isRequired,
  onConfirm: func.isRequired,
};


export default InputModalView;
