import React from 'react';

import StyledSvg from './styles/StyledSvg';


const Logo = () => (
  <StyledSvg viewBox="0 0 448 448">
    <path d="m192 56h64c4.417969 0 8 3.582031 8 8v128c0 4.417969-3.582031 8-8 8h-64c-4.417969 0-8-3.582031-8-8v-128c0-4.417969 3.582031-8 8-8zm0 0" fill="#FFFFFF" />
    <path d="m0 256h448v-248c0-4.417969-3.582031-8-8-8h-432c-4.417969 0-8 3.582031-8 8zm296-208c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v80c0 4.417969 3.582031 8 8 8h72v-88c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v160c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8v-56h-72c-13.253906 0-24-10.746094-24-24zm-128 16c0-13.253906 10.746094-24 24-24h64c13.253906 0 24 10.746094 24 24v128c0 13.253906-10.746094 24-24 24h-64c-13.253906 0-24-10.746094-24-24zm-128-16c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v80c0 4.417969 3.582031 8 8 8h72v-88c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v160c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8v-56h-72c-13.253906 0-24-10.746094-24-24zm0 0" fill="#FFFFFF" />
    <path d="m448 296v-24h-448v24c0 4.417969 3.582031 8 8 8h432c4.417969 0 8-3.582031 8-8zm0 0" fill="#FFFFFF" />
  </StyledSvg>
);

export default Logo;
