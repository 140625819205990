import React, { Component, Fragment, } from 'react';
import {
  shape, bool, arrayOf, func, string, number,
} from 'prop-types';

import RTRow from '../../../../atoms/Table/RTRow';
import RTColHeader from '../../../../atoms/Table/RTColHeader';
import RTCol from '../../../../atoms/Table/RTCol';
import RTBody from '../../../../atoms/Table/RTBody';
import Button from '../../../../atoms/Button/Button';
import ButtonGrp from '../../../../atoms/ButtonGrp/ButtonGrp';
import Detail from '../../../../styles/icons/Detail';
import Trash from '../../../../styles/icons/Trash';
import RTLoader from '../../../../atoms/Table/RTLoader';
import RTError from '../../../../atoms/Table/RTError';
import RTNoData from '../../../../atoms/Table/RTNoData';


class TableRows extends Component {
  renderRows = () => {
    const {
      // data
      data,
      cols,
      tableData,
      // methods
      onGetTableData,
      onDelete,
      onEdit,
    } = this.props;

    const isLoading = tableData.isLoading; // eslint-disable-line
    const noData = !isLoading && data.length < 1;

    if (!isLoading && tableData.error) {
      return (
        <RTError
          data={tableData}
          refresh={() => onGetTableData()}
        />
      );
    }

    return (
      <Fragment>
        {isLoading && (
          <RTLoader />
        )}

        {noData && (
          <RTNoData />
        )}

        {data.map((item) => (
          <RTRow key={item.id}>

            <RTColHeader>{`${cols[0].label}:`}</RTColHeader>
            <RTCol width={cols[0].width}>{item.id}</RTCol>

            <RTColHeader>{`${cols[1].label}:`}</RTColHeader>
            <RTCol width={cols[1].width}>{item.name}</RTCol>

            <RTColHeader>{`${cols[2].label}:`}</RTColHeader>
            <RTCol width={cols[2].width}>
              <ButtonGrp>
                <Button
                  shape="outline"
                  size="sm"
                  color="secondary"
                  onClick={() => onEdit(item.id)}
                >
                  <span className="rt--col-icon-first"><Detail /></span>
                  Detail
                </Button>
                <Button
                  shape="outline"
                  size="sm"
                  color="error"
                  onClick={() => onDelete(true, item.id)}
                >
                  <span className="rt--col-icon-first"><Trash /></span>
                  Smazat
                </Button>
              </ButtonGrp>
            </RTCol>
          </RTRow>
        ))}
      </Fragment>
    );
  }

  render() {
    return (
      <RTBody>
        {this.renderRows()}
      </RTBody>
    );
  }
}


TableRows.propTypes = {
  data: arrayOf(shape({
    id: number.isRequired,
    name: string.isRequired,
  })).isRequired,
  cols: arrayOf(shape({
    label: string.isRequired,
    width: number.isRequired,
  })).isRequired,
  tableData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  onGetTableData: func.isRequired,
  onEdit: func.isRequired,
  onDelete: func.isRequired,
};


export default TableRows;
