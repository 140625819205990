import styled from 'styled-components';

const Divider = styled.hr`
  position: relative;
  height: 1px;
  border: 0;
  border-top: 1px solid ${(p) => p.theme.grey.t800};
  margin: 1em 0;

  ${(p) => p.width && `
    width: ${p.width}%;
  `}
`;

export default Divider;
