import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION, FORM_SUCCESS,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


const parseValues = (values) => ({
  name: values.name,
  columns: values.columns.map((item) => item.id),
  logo: values.logo,
  header: values.header,
  footer: values.footer,
  barCode: values.barCode,
  numericalSeriesId: values.numericalSeriesId === null ? null : values.numericalSeriesId.id,
  transportationTypes: values.transportationTypes.map((item) => item.id),
});

/**
 * Refresh data - on mount
 */
export const TICKETS_DETAIL__REFRESH = 'TICKETS_DETAIL__REFRESH';
export const refreshTicketDetailAction = () => ({
  type: `${TICKETS_DETAIL__REFRESH}`,
});


/**
 * Change Value
 */
export const TICKETS__CHANGE_VALUE_DETAIL = 'TICKETS__CHANGE_VALUE_DETAIL';
export const changeValueAction = (name, value, validationError) => ({
  type: `${TICKETS__CHANGE_VALUE_DETAIL}`,
  payload: {
    name,
    value,
    validationError,
  },
});


/**
 * Validate Form
 */
export const TICKETS__VALIDATE_FORM_DETAIL = 'TICKETS__VALIDATE_FORM_DETAIL';
export const validateFormAction = (error) => ({
  type: `${TICKETS__VALIDATE_FORM_DETAIL}`,
  payload: error,
});


/*
 * GET Detail
 */
export const TICKETS__GET_DETAIL = 'TICKETS__GET_DETAIL';
export const getTicketDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${TICKETS__GET_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/tickets?id=${id}`,
    });

    dispatch({
      type: `${TICKETS__GET_DETAIL}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${TICKETS__GET_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * CREATE Numerical Serie Detail
 */
export const TICKETS__CREATE_DETAIL = 'TICKETS__CREATE_DETAIL';
export const createTicketDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${TICKETS__CREATE_DETAIL}__${PENDING}`,
    });
    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/tickets`,
      data: {
        values: parseValues(item.values),
      },
    });

    dispatch({
      type: `${TICKETS__CREATE_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Konfigurace lístku byl vytvořena',
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${TICKETS__CREATE_DETAIL}__${REJECTED_VALIDATION}`,
        payload: parsedError,
      });
      return;
    }

    dispatch({
      type: `${TICKETS__CREATE_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};

/*
 * Update Detail
 */
export const TICKETS__UPDATE_DETAIL = 'TICKETS__UPDATE_DETAIL';
export const updateTicketDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${TICKETS__UPDATE_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/tickets`,
      data: {
        id: item.id,
        values: parseValues(item.values),
      },
    });

    dispatch({
      type: `${TICKETS__UPDATE_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Konfigurace lístku byla upravena',
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${TICKETS__UPDATE_DETAIL}__${REJECTED_VALIDATION}`,
        payload: parsedError,
      });
      return;
    }

    dispatch({
      type: `${TICKETS__UPDATE_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};
