import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION, FORM_SUCCESS,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


/*
 * GET Display Editor settings and displays
 */
export const DEVICES__GET_DISPLAY_EDITOR = 'DEVICES__GET_DISPLAY_EDITOR';
export const getDisplayEditorAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__GET_DISPLAY_EDITOR}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/devices/controller/disp-editor?id=${id}`,
    });

    dispatch({
      type: `${DEVICES__GET_DISPLAY_EDITOR}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${DEVICES__GET_DISPLAY_EDITOR}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * UPDATE Displays in DisplayController
 */
export const DEVICES__UPDATE_DISPLAYS_IN_EDITOR = 'DEVICES__UPDATE_DISPLAYS_IN_EDITOR';
export const updateDisplaysInEditorAction = (id, values, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__UPDATE_DISPLAYS_IN_EDITOR}__${PENDING}`,
    });

    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/devices/controller/disp-editor`,
      data: {
        id,
        values,
      },
    });

    dispatch({
      type: `${DEVICES__UPDATE_DISPLAYS_IN_EDITOR}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Konfigurace displejů uložena',
          showMessage: true,
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${DEVICES__UPDATE_DISPLAYS_IN_EDITOR}__${REJECTED_VALIDATION}`,
        payload: parsedError,
      });
      return;
    }

    dispatch({
      type: `${DEVICES__UPDATE_DISPLAYS_IN_EDITOR}__${REJECTED}`,
      payload: parsedError,
    });
  }
};
