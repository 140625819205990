import { INPUT_TYPE, } from 'sofair-form-validation';

import {
  QUEUE_CONFIG__ACTIONS_REFRESH,
  QUEUE_CONFIG__ACTIONS_CHANGE_VALUE_DETAIL,
  QUEUE_CONFIG__ACTIONS_VALIDATE_FORM_DETAIL,
  QUEUE_CONFIG__GET_ACTIONS_DETAIL,
  QUEUE_CONFIG__CREATE_ACTIONS_DETAIL,
  QUEUE_CONFIG__UPDATE_ACTIONS_DETAIL,
  QUEUE_CONFIG__DELETE_ACTIONS_DETAIL,
  QUEUE_CONFIG__TOGGLE_ACTIONS_DELETE_MODAL,
} from '../actions/actionsDetail';
import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION, REJECTED_COLLISON,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';
import {
  mergeFormAndValidation, initInput, changeFormValue,
} from '../../../logic/forms/validation';


const initialState = {
  detailActionsData: {
    ...initRestData,
    isLoading: true,
  },
  createActionsData: {
    ...initRestData,
  },
  updateActionsData: {
    ...initRestData,
  },
  deleteActionsData: {
    ...initRestData,
  },
  actionsDeleteModal: {
    id: null,
    isOpen: false,
    collision: false,
    transportationTypes: {
      conflict: false,
      data: [],
    },
  },
  detail: {
    id: null,
    isValid: true,
    invalidInputs: 0,
    form: {
      name: {
        ...initInput,
        name: 'name',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: true,
          min: 2,
          max: 255,
        },
      },
    },
    values: {
      name: '',
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;

  switch (type) {
    /**
     * Refresh Data
     */
    case `${QUEUE_CONFIG__ACTIONS_REFRESH}`: {
      return {
        ...state,
        createActionsData: {
          ...initialState.createActionsData,
        },
        updateActionsData: {
          ...initialState.updateActionsData,
        },
        detail: {
          ...initialState.detail,
        },
      };
    }


    /**
     * Change Form
     */
    case `${QUEUE_CONFIG__ACTIONS_CHANGE_VALUE_DETAIL}`: {
      const newFormData = changeFormValue(
        state.detail.form, state.detail.values, state.detail.invalidInputs, payload
      );

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: newFormData.invalidInputs < 1,
          invalidInputs: newFormData.invalidInputs,
          form: newFormData.form,
          values: newFormData.values,
        },
      };
    }


    /**
     * Validate form
     */
    case `${QUEUE_CONFIG__ACTIONS_VALIDATE_FORM_DETAIL}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload);

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }


    /**
     * GET Actions Detail
     */
    case `${QUEUE_CONFIG__GET_ACTIONS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        detailActionsData: {
          ...initialState.detailActionsData,
          ...(handlePending()),
        },
        updateActionsData: {
          ...initialState.updateActionsData,
        },
        detail: {
          ...initialState.detail,
        },
      };
    }

    case `${QUEUE_CONFIG__GET_ACTIONS_DETAIL}__${FULFILLED}`: {
      try {
        const newValues = {
          ...state.detail.values,
          name: payload.data.actionTranslation.name,
        };

        return {
          ...state,
          detailActionsData: {
            ...state.detailActionsData,
            ...(handleFulfilled(payload)),
          },
          detail: {
            ...state.detail,
            id: payload.data.id,
            values: newValues,
          },
        };
      } catch (err) {
        return {
          ...state,
          detailActionsData: {
            ...state.detailActionsData,
            ...(handleRejected({
              statusCode: -1,
              messageInternal: err.message,
              message: {
                code: 'SOMETHING_HAPPEND',
                params: {},
              },
            })),
          },
        };
      }
    }

    case `${QUEUE_CONFIG__GET_ACTIONS_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        detailActionsData: {
          ...state.detailActionsData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * CREATE Actions Detail
     */
    case `${QUEUE_CONFIG__CREATE_ACTIONS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        createActionsData: {
          ...state.createActionsData,
          ...(handlePending()),
        },
      };
    }

    case `${QUEUE_CONFIG__CREATE_ACTIONS_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        createActionsData: {
          ...state.createActionsData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${QUEUE_CONFIG__CREATE_ACTIONS_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data.data);

      return {
        ...state,
        createActionsData: {
          ...state.createActionsData,
          ...(handleRejected(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${QUEUE_CONFIG__CREATE_ACTIONS_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        createActionsData: {
          ...state.createActionsData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * UPDATE Actions Detail
     */
    case `${QUEUE_CONFIG__UPDATE_ACTIONS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        updateActionsData: {
          ...state.updateActionsData,
          ...(handlePending()),
        },
      };
    }

    case `${QUEUE_CONFIG__UPDATE_ACTIONS_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        updateActionsData: {
          ...state.updateActionsData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${QUEUE_CONFIG__UPDATE_ACTIONS_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data);

      return {
        ...state,
        updateActionsData: {
          ...state.updateActionsData,
          ...(handleRejected(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${QUEUE_CONFIG__UPDATE_ACTIONS_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        updateActionsData: {
          ...state.updateActionsData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * DELETE Actions Detail
     */
    case `${QUEUE_CONFIG__DELETE_ACTIONS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        deleteActionsData: {
          ...state.deleteActionsData,
          ...(handlePending()),
        },
      };
    }

    case `${QUEUE_CONFIG__DELETE_ACTIONS_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        deleteActionsData: {
          ...state.deleteActionsData,
          ...(handleFulfilled(payload)),
        },
        actionsDeleteModal: {
          ...initialState.actionsDeleteModal,
        },
      };
    }

    case `${QUEUE_CONFIG__DELETE_ACTIONS_DETAIL}__${REJECTED_COLLISON}`: {
      return {
        ...state,
        deleteActionsData: {
          ...state.deleteActionsData,
          ...(handleRejected(payload)),
        },
        actionsDeleteModal: {
          ...state.actionsDeleteModal,
          collision: true,
          transportationTypes: {
            ...state.actionsDeleteModal.transportationTypes,
            conflict: payload.data.conflict,
            data: payload.data.transportationTypes,
          },
        },
      };
    }

    case `${QUEUE_CONFIG__DELETE_ACTIONS_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        deleteActionsData: {
          ...state.deleteActionsData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * TOGGLE Actions delete modal
     */
    case `${QUEUE_CONFIG__TOGGLE_ACTIONS_DELETE_MODAL}`: {
      return {
        ...state,
        actionsDeleteModal: {
          ...initialState.actionsDeleteModal,
          isOpen: payload.isOpen,
          id: payload.id,
        },
        deleteActionsData: initialState.deleteActionsData,
      };
    }

    default:
      return state;
  }
};

export default reducer;
