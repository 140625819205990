import { INPUT_TYPE, } from 'sofair-form-validation';

import {
  QUEUE_CONFIG__CHANGE_VALUE_SETTINGS,
  QUEUE_CONFIG__GET_SETTINGS,
  QUEUE_CONFIG__UPDATE_SETTINGS,
  QUEUE_CONFIG__VALIDATE_FORM_SETTINGS,
} from '../actions/queueConfiguration';
import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION,
} from '../../../globals';
import {
  handlePending, handleFulfilled, handleRejected, initRestData,
} from '../../../logic/restApi/utils';
import {
  mergeFormAndValidation, initInput, changeFormValue,
} from '../../../logic/forms/validation';

const initialState = {
  getData: {
    ...initRestData,
  },
  updateData: {
    ...initRestData,
  },
  detail: {
    id: null,
    isValid: true,
    invalidInputs: 0,
    form: {
      transportationInPlanTabBeforeNowInMinutes: {
        ...initInput,
        name: 'transportationInPlanTabBeforeNowInMinutes',
        type: INPUT_TYPE.NUMBER,
        validation: {
          ...initInput.validation,
          required: true,
          min: 0,
          max: 10000000,
        },
      },
      transportationInPlanTabAfterNowInMinutes: {
        ...initInput,
        name: 'transportationInPlanTabAfterNowInMinutes',
        type: INPUT_TYPE.NUMBER,
        validation: {
          ...initInput.validation,
          required: true,
          min: 0,
          max: 10000000,
        },
      },
      transportationInDelayTabBeforePlanInMinutes: {
        ...initInput,
        name: 'transportationInDelayTabBeforePlanInMinutes',
        type: INPUT_TYPE.NUMBER,
        validation: {
          ...initInput.validation,
          required: true,
          min: 0,
          max: 10000000,
        },
      },
    },
    values: {
      transportationInPlanTabBeforeNowInMinutes: '0',
      transportationInPlanTabAfterNowInMinutes: '0',
      transportationInDelayTabBeforePlanInMinutes: '0',
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;
  switch (type) {
    /**
     * Change Form
     */
    case `${QUEUE_CONFIG__CHANGE_VALUE_SETTINGS}`: {
      const newFormData = changeFormValue(
        state.detail.form, state.detail.values, state.detail.invalidInputs, payload
      );

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: newFormData.invalidInputs < 1,
          invalidInputs: newFormData.invalidInputs,
          form: newFormData.form,
          values: newFormData.values,
        },
      };
    }


    /**
     * Validate form
     */
    case `${QUEUE_CONFIG__VALIDATE_FORM_SETTINGS}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload);

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }


    /**
     * GET Languages Settings
     */
    case `${QUEUE_CONFIG__GET_SETTINGS}__${PENDING}`: {
      return {
        ...state,
        getData: {
          ...(handlePending()),
        },
        detail: {
          ...initialState.detail,
        },
      };
    }

    case `${QUEUE_CONFIG__GET_SETTINGS}__${FULFILLED}`: {
      try {
        const newValues = {
          ...state.detail.values,
          ...payload.data,
        };

        return {
          ...state,
          getData: {
            ...(handleFulfilled(payload)),
          },
          detail: {
            ...state.detail,
            id: payload.data.id,
            values: newValues,
            oldValues: newValues,
          },
        };
      } catch (err) {
        return {
          ...state,
          getData: {
            ...(handleRejected({
              statusCode: -1,
              messageInternal: err.message,
              message: {
                code: 'SOMETHING_HAPPEND',
                params: {},
              },
            })),
          },
          detail: {
            ...state.detail,
          },
        };
      }
    }

    case `${QUEUE_CONFIG__GET_SETTINGS}__${REJECTED}`: {
      return {
        ...state,
        getData: {
          ...(handleRejected(payload)),
        },
        detail: {
          ...state.detail,
        },
      };
    }


    /**
     * UPDATE Languages settings
     */
    case `${QUEUE_CONFIG__UPDATE_SETTINGS}__${PENDING}`: {
      return {
        ...state,
        updateData: {
          ...(handlePending()),
        },
        detail: {
          ...state.detail,
        },
      };
    }

    case `${QUEUE_CONFIG__UPDATE_SETTINGS}__${FULFILLED}`: {
      return {
        ...state,
        updateData: {
          ...(handleFulfilled(payload)),
        },
        detail: {
          ...state.detail,
        },
      };
    }

    case `${QUEUE_CONFIG__UPDATE_SETTINGS}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data);

      return {
        ...state,
        updateData: {
          ...(handleRejected(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${QUEUE_CONFIG__UPDATE_SETTINGS}__${REJECTED}`: {
      return {
        ...state,
        updateData: {
          ...(handleRejected(payload)),
        },
        detail: {
          ...state.detail,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
