export class ErrorLocalStorage extends Error {
  constructor(message = '') {
    super(`ErrorLocalStorage: ${message}`);
    this.name = 'ErrorLocalStorage';
    this.message = message;
  }
}


export class ErrorCompatibility extends Error {
  constructor(message = '') {
    super(`ErrorCompatibility: ${message}`);
    this.name = 'ErrorCompatibility';
    this.message = message;
  }
}
