import styled from 'styled-components';


const StyledInputItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .transportationEditForm--input-element {
    margin: 10px;
    min-width: 70%;
    border: 2px solid ${(p) => p.theme.primary.t800};
    position: relative;
    font-size: 1rem;
    padding: 10px;
  }

  button {
    font-size: 1rem;
    padding: 5px;
    background: none;
    border: none;
    color: ${(p) => p.theme.grey.t800};
  }

  button:focus {
    outline: none;
  }

  button:hover {
    cursor: pointer;
    color: ${(p) => p.theme.primary.t800};
  }

  button:disabled {
    cursor: default;
  }

  .transportationEditForm--input-btn-edit {
    position: absolute;
    top: 0;
    right: 0;
  }
`;


export default StyledInputItem;
