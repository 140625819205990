import React, { Component, } from 'react';
import {
  object, func, bool, string,
} from 'prop-types';

import { CLICK_CLICK, CLICK_CTRL, } from './consts';
import MSLListView from './MSLListView';


class MSLList extends Component {
  state = {
    prev: null,
  }

  /**
   * Double click check
   */
  isDoubleClick = (clickTime, id) => {
    const { prev, } = this.state;

    if (prev === null) return false;
    if (prev.id !== id) return false;
    if (prev.type !== CLICK_CLICK) return false;
    if (clickTime - prev.time > 250) return false;
    return true;
  }

  /**
   * Determine click type (order is important cause of double click)
   *  - Ctrl Click
   *  - Shift Click
   *  - Click
   *  - Double Click
   */
  clickHandler = (e, id) => {
    const { data, } = this.props;
    const { prev, } = this.state;

    const oldPrev = prev;
    const clickTime = new Date();

    if (oldPrev !== null && Object.prototype.hasOwnProperty.call(data, oldPrev.id)) {
      // SHIFT + Click
      if (e.shiftKey) {
        this.handleShiftClick(id, oldPrev.id);
        return;
      }

      // CTRL + Click
      if (e.ctrlKey) {
        this.handleCtrlClick(id, clickTime);
        return;
      }

      // DOUBLE Click
      if (this.isDoubleClick(clickTime, id)) {
        this.handleDoubleClick(id);
        return;
      }
    }

    // CLICK
    this.handleClick(id, clickTime);
  }

  /**
   * Click
   */
  handleClick = (id, clickTime) => {
    const { onSelect, } = this.props;

    this.setState({
      prev: {
        id,
        time: clickTime,
        type: CLICK_CLICK,
      },
    });

    onSelect({
      [id]: id,
    });
  }

  /**
   * Double Click
   */
  handleDoubleClick = (id) => {
    const { onDoubleClick, } = this.props;

    this.setState({
      prev: null,
    });

    onDoubleClick({
      [id]: id,
    });
  }

  /**
   * Shift + Click
   */
  handleShiftClick = (curId, prevId) => {
    const { onSelect, data, getOptionValue, } = this.props;

    let findPosition = 0;
    const newSelection = {};

    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      const index = getOptionValue(data[keys[i]]);

      if (index === curId || index === prevId) {
        findPosition += 1;
        newSelection[index] = index;
      } else if (findPosition === 1) {
        newSelection[index] = index;
      }
    }

    onSelect(newSelection);
  }

  /**
   * CTRL + Click
   */
  handleCtrlClick = (id, clickTime) => {
    const { onSelect, selection, } = this.props;

    this.setState({
      prev: {
        id,
        time: clickTime,
        type: CLICK_CTRL,
      },
    });

    if (Object.prototype.hasOwnProperty.call(selection, id)) {
      const newSelection = selection;
      delete newSelection[id];

      onSelect(newSelection);
    } else {
      onSelect({
        ...selection,
        [id]: id,
      });
    }
  }

  render() {
    const {
      // data
      data,
      getOptionLabel,
      getOptionValue,
      selection,
      status,
      mainList,
      errorMessage,
      getDataMessage,
      placeholder,
      isLoading,
      error,
      // actions
      handleFetchData,
    } = this.props;

    return (
      <MSLListView
        data={data}
        selection={selection}
        isLoading={isLoading}
        error={error}
        errorMessage={errorMessage}
        getDataMessage={getDataMessage}
        placeholder={placeholder}
        status={status}
        mainList={mainList}
        // actions
        handleFetchData={handleFetchData}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        clickHandler={this.clickHandler}
      />
    );
  }
}


MSLList.propTypes = {
  data: object.isRequired, // eslint-disable-line
  selection: object.isRequired,
  getOptionLabel: func.isRequired,
  getOptionValue: func.isRequired,
  onDoubleClick: func.isRequired,
  onSelect: func.isRequired,
  isLoading: bool,
  error: bool,
  errorMessage: string.isRequired,
  getDataMessage: string.isRequired,
  placeholder: string.isRequired,
  mainList: bool,
  status: string.isRequired,
  handleFetchData: func,
};


MSLList.defaultProps = {
  isLoading: false,
  error: false,
  mainList: false,
  handleFetchData: () => {},
};


export default MSLList;
