import React, { Component, Fragment, } from 'react';
import {
  shape, bool, object, func, string, number, arrayOf,
} from 'prop-types';

import { COLS, } from './utils';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Pagination from '../../../../atoms/Pagination/Pagination';
import DisplaysConfigurationTableRow from './DisplaysConfigurationTableRow';
import DisplaysConfigurationTableHeader from './DisplaysConfigurationTableHeader';


class DisplaysConfigurationTable extends Component {
  constructor(props) {
    super(props);

    this.paramsTimer = null;
  }

  componentWillUnmount() {
    if (this.paramsTimer !== null) {
      clearTimeout(this.paramsTimer);
      this.paramsTimer = null;
    }
  }

  handleOnClickSort = (id) => {
    const {
      filter, displaysConfigurationData,
    } = this.props;

    if (displaysConfigurationData.isLoading) return;

    let newOrder = 'ASC';

    if (filter.sortBy === id && filter.order === 'ASC') {
      newOrder = 'DESC';
    }

    this.handleFilterChanged({
      ...filter,
      order: newOrder,
      sortBy: id,
    });
  }

  handleOnChangeParams = (name, value) => {
    const { changeFilter, filter, } = this.props;

    if (this.paramsTimer !== null) {
      clearTimeout(this.paramsTimer);
      this.paramsTimer = null;
    }

    this.paramsTimer = setTimeout(this.paramsTimerDone, 750);

    changeFilter({
      ...filter,
      params: {
        ...filter.params,
        [name]: value,
      },
    });
  }

  handleOnChangeOffset = (newOffset) => {
    const { filter, } = this.props;

    this.handleFilterChanged({
      ...filter,
      offset: newOffset,
    });
  }

  paramsTimerDone = () => {
    const { filter, handleGetDisplaysConfiguration, } = this.props;

    handleGetDisplaysConfiguration({
      ...filter,
      offset: 0,
    });

    clearTimeout(this.paramsTimer);
    this.paramsTimer = null;
  }

  handleFilterChanged = (newFilter) => {
    const {
      handleGetDisplaysConfiguration, changeFilter,
    } = this.props;

    if (this.paramsTimer === null) {
      handleGetDisplaysConfiguration({
        ...newFilter,
      });
    } else {
      changeFilter({
        ...newFilter,
      });
    }
  }

  render() {
    const {
      // data
      data,
      filter,
      displaysConfigurationData,
      count,
      // methods
      handleGetDisplaysConfiguration,
      handleEdit,
      handleDelete,
    } = this.props;

    return (
      <Fragment>
        <Row formGroup>
          <Col>
            <DisplaysConfigurationTableHeader
              filter={filter}
              displaysConfigurationData={displaysConfigurationData}
              onClickSort={this.handleOnClickSort}
              onChangeParam={this.handleOnChangeParams}
            />

            <DisplaysConfigurationTableRow
              // data
              data={data}
              cols={COLS}
              displaysConfigurationData={displaysConfigurationData}
              // methods
              handleGetDisplaysConfiguration={handleGetDisplaysConfiguration}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
            />
          </Col>
        </Row>

        <Pagination
          isLoading={displaysConfigurationData.isLoading}
          offset={filter.offset}
          limit={filter.limit}
          count={count}
          action={this.handleOnChangeOffset}
        />
      </Fragment>
    );
  }
}


DisplaysConfigurationTable.propTypes = {
  data: arrayOf(object).isRequired,
  count: number.isRequired,
  filter: shape({
    sortBy: string.isRequired,
    order: string.isRequired,
  }).isRequired,
  displaysConfigurationData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  changeFilter: func.isRequired,
  handleGetDisplaysConfiguration: func.isRequired,
  handleEdit: func.isRequired,
  handleDelete: func.isRequired,
};


export default DisplaysConfigurationTable;
