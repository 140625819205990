import { PENDING, FULFILLED, REJECTED, } from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


/*
 * GET Controllers
 */
export const DEVICES__GET_CONTROLLERS = 'DEVICES__GET_CONTROLLERS';
export const getControllersAction = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__GET_CONTROLLERS}__${PENDING}`,
      payload: filter,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/devices/controller/filter`,
      data: filter,
    });

    dispatch({
      type: `${DEVICES__GET_CONTROLLERS}__${FULFILLED}`,
      payload: response.data,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${DEVICES__GET_CONTROLLERS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/**
 * Filter Changed
 */
export const DEVICES__CONTROLLERS_FILTER_CHANGED = 'DEVICES__CONTROLLERS_FILTER_CHANGED';

export const changeControllersFilterAction = (filter) => ({
  type: `${DEVICES__CONTROLLERS_FILTER_CHANGED}`,
  payload: filter,
});
