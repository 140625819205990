import React, { Component, Fragment, } from 'react';
import {
  shape, func, string, object, bool,
} from 'prop-types';

import { withDnD, } from '../../../../logic/dnd/withDnD';
import PlanContext from './PlanContext';
import PlanActionItem from './PlanActionItem';
import Loader from '../../../../atoms/Loader/Loader';
import Button from '../../../../atoms/Button/Button';

import StyledPlanActionList from './styles/StyledPlanActionList';


class PlanActionList extends Component {
  componentDidMount() {
    const { getActionsShared, } = this.props;

    getActionsShared();
  }

  handleOnMouseOver = (e) => {
    e.stopPropagation();
  }

  handleOnMouseMove = (e) => {
    const { dnd, root, } = this.props;

    e.preventDefault();
    e.stopPropagation();

    if (!dnd.isActive) return;

    dnd.onDragOver({ root, id: dnd.over.id, });
  }

  handleOnMouseUp = (e) => {
    e.stopPropagation();

    const { root, dnd, } = this.props;

    if (!dnd.isActive) return;

    dnd.onDrop({ root, pos: [], });
  }

  renderItems = (isDraggingOver) => {
    const {
      actionsSharedData, root, getActionsShared,
    } = this.props;

    if (actionsSharedData.isLoading) {
      return (
        <div className="trans-types--actions-container trans-types--actions-loader">
          <Loader />
        </div>
      );
    }

    if (actionsSharedData.error) {
      return (
        <div className="trans-types--actions-container trans-types--actions-error">
          <div>
            <div>Nepovedlo se načíst data</div>
            <Button
              shape="outline"
              color="error"
              size="sm"
              onClick={getActionsShared}
            >
              Refresh
            </Button>
          </div>
        </div>
      );
    }

    const keys = Object.keys(actionsSharedData.data);
    return (
      <Fragment>
        { keys.map((key, index) => {
          const item = actionsSharedData.data[key];

          return (
            <PlanActionItem
              key={item.id}
              item={item}
              root={root}
              position={[ index, ]}
              draggingOverList={isDraggingOver}
            />
          );
        })}
      </Fragment>
    );
  }

  render() {
    const { root, dnd, } = this.props;

    const isDraggingOver = root === dnd.over.root;

    return (
      <StyledPlanActionList // eslint-disable-line
        onMouseUp={this.handleOnMouseUp}
        onMouseMove={this.handleOnMouseMove}
        onMouseOver={this.handleOnMouseOver}
        isDraggingOver={isDraggingOver}
      >
        {this.renderItems(isDraggingOver)}
      </StyledPlanActionList>
    );
  }
}


PlanActionList.propTypes = {
  actionsSharedData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
    message: object.isRequired,
  }).isRequired,
  root: string.isRequired,
  dnd: shape({
    onDragOver: func.isRequired,
    onDrop: func.isRequired,
    isActive: bool.isRequired,
    over: shape({
      root: string,
      id: string,
    }).isRequired,
  }).isRequired,
  getActionsShared: func.isRequired,
};


export default withDnD(PlanContext)(PlanActionList);
