import React, { Fragment, } from 'react';
import {
  object, func,
} from 'prop-types';

import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import ActionsTable from './ActionsTable';
import Heading from '../../../../atoms/Heading/Heading';
import Button from '../../../../atoms/Button/Button';


const Actions = ({
  // data
  table,
  actionsData,
  // methods
  handleGetActions,
  handleEdit,
  handleDelete,
  handleCreate,
  changeFilter,
}) => (
  <Fragment>

    <Heading h4>
      Akce
    </Heading>

    <Row formGroup>
      <Col>
        <Button
          type="button"
          onClick={handleCreate}
          color="success"
          shape="outline"
        >
          Vytvořit akci
        </Button>
      </Col>
    </Row>

    <ActionsTable
      data={table.data}
      table={table}
      filter={table.filter}
      count={table.count}
      actionsData={actionsData}
      handleGetActions={handleGetActions}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      changeFilter={changeFilter}
    />

  </Fragment>
);


Actions.propTypes = {
  table: object.isRequired,
  actionsData: object.isRequired,
  changeFilter: func.isRequired,
  handleGetActions: func.isRequired,
  handleEdit: func.isRequired,
  handleDelete: func.isRequired,
  handleCreate: func.isRequired,
};


export default Actions;
