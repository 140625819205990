import { INPUT_TYPE, } from 'sofair-form-validation';


/**
 * Rest Api
 */
export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';
export const REJECTED_FORM = 'REJECTED_FORM';
export const REJECTED_VALIDATION = 'REJECTED_VALIDATION';
export const REJECTED_COLLISON = 'REJECTED_COLLISON';
export const LOGIN = 'LOGIN';
export const ERROR = 'ERROR';
export const NO_USER = 'NO_USER';


/**
 * Input State
 */
export const FORM_DEFAULT = 'FORM_DEFAULT';
export const FORM_ERROR = 'FORM_ERROR';
export const FORM_SUCCESS = 'FORM_SUCCESS';
export const FORM_WARNING = 'FORM_WARNING';


/**
 * Dropdowns
 */
export const KIOSKS_CONFIGURATION_DEFAULT_SEARCH = {
  truckRegistration: {
    id: 'truckRegistration',
    name: 'SPZ tahače',
  },
  no: {
    id: 'no',
    name: 'Kód přepravy',
  },
};

export const KIOSKS_CONFIGURATION_DETAIL_PARAMETERS = {
  truckRegistration: {
    id: 'truckRegistration',
    name: 'SPZ tahače',
  },
  trailerRegistration: {
    id: 'trailerRegistration',
    name: 'SPZ návěsu',
  },
  no: {
    id: 'no',
    name: 'Kód přepravy',
  },
  driver: {
    id: 'driver',
    name: 'Jméno řidiče',
  },
  driverPhone: {
    id: 'driverPhone',
    name: 'Telefon na řidiče',
  },
};

export const KIOSKS_CONFIGURATION_AUTH_METHODS = {
  select: { id: 'select', name: 'Výběrem z tabulky', },
  find: { id: 'find', name: 'Vyhledáním podle parametru', },
};

export const KIOSKS_CONFIGURATION_UNTIL = {
  1: { id: 1, name: '1 den dopředu', },
  2: { id: 2, name: '2 dny dopředu', },
  3: { id: 3, name: '3 dny dopředu', },
  4: { id: 4, name: '4 dny dopředu', },
  5: { id: 5, name: '5 dní dopředu', },
};

export const DISPLAYS_CONFIGURATION_TYPES = {
  ramp: {
    id: 'ramp',
    name: 'Rampový',
  },
  calling: {
    id: 'calling',
    name: 'Vyvolávací',
  },
};

export const DISPLAYS_COLUMN_PARAMETERS = {
  truckRegistration: {
    id: 'truckRegistration',
    name: 'SPZ tahače',
  },
  trailerRegistration: {
    id: 'trailerRegistration',
    name: 'SPZ návěsu',
  },
  no: {
    id: 'no',
    name: 'Kód přepravy',
  },
  position: {
    id: 'position',
    name: 'Směr',
  },
  platformNo: {
    id: 'platformNo',
    name: 'Označení rampy',
  },
  serialNumber: {
    id: 'serialNumber',
    name: 'Číslo lístku',
  },
};

export const POSITION_ARROWS = {
  1: { id: 1, name: 'UP', },
  2: { id: 2, name: 'UP-RIGHT', },
  3: { id: 3, name: 'RIGHT', },
  4: { id: 4, name: 'DOWN-RIGHT', },
  5: { id: 5, name: 'DOWN', },
  6: { id: 6, name: 'DOWN-LEFT', },
  7: { id: 7, name: 'LEFT', },
  8: { id: 8, name: 'UP-LEFT', },
};

/**
 * SHARED
 * type - redux action type
 * apiRoute - route on backend call
 * translation - if table has translation => name string ELSE translation = null !!!
 * id - id parameter for selectbox (from backend data may have other naming)
 * name - name parameter for selectbox (from backend data may have other naming)
 */
export const SHARED_DATA_TYPES = {
  ACTIONS: {
    type: 'actionsData',
    apiRoute: '/actions',
    translation: 'actionTranslation',
    id: 'id',
    name: 'name',
  },
  PLATFORMS: {
    type: 'platformsData',
    apiRoute: '/platforms',
    translation: null,
    id: 'id',
    name: 'name',
  },
  LANGUAGES: {
    type: 'languagesData',
    apiRoute: '/settings/languages',
    translation: null,
    id: 'id',
    name: 'name',
  },
  TRANSPORTATION_TYPES: {
    type: 'transportationTypesData',
    apiRoute: '/transportation_types',
    translation: null,
    id: 'id',
    name: 'name',
  },
  FORMS: {
    type: 'formsData',
    apiRoute: '/generic_forms/list',
    translation: null,
    id: 'id',
    name: 'name',
  },
  REGEXES: {
    type: 'regexesData',
    apiRoute: '/generic_forms/regexes',
    translation: null,
    id: 'id',
    name: 'name',
  },
  GROUPS: {
    type: 'groupData',
    apiRoute: '/generic_forms/groups',
    translation: null,
    id: 'id',
    name: 'label',
  },
  OPTIONS: {
    type: 'optionsData',
    apiRoute: '/generic_forms/options',
    translation: null,
    id: 'id',
    name: 'name',
  },
  BTN_ACTIONS: {
    type: 'btnActionsData',
    apiRoute: '/generic_forms/btn_actions',
    translation: null,
    id: 'id',
    name: 'name',
  },
};


/**
 * Form Structure Types
 */
export const FORM_STRUCTURE_INPUT = 'input';
export const FORM_STRUCTURE_FIELDSET = 'fieldset';
export const FORM_STRUCTURE_GROUP = 'group';

export const FORM_ROOT = 'formRoot';


export const FORM_TYPES = {
  TEXT: INPUT_TYPE.TEXT,
  NUMBER: INPUT_TYPE.NUMBER,
  SELECT: INPUT_TYPE.SELECT,
  MULTISELECT: INPUT_TYPE.MULTISELECT,
  CHECKBOX: INPUT_TYPE.CHECKBOX,
  RADIO: INPUT_TYPE.RADIO,
  TIME: 'time',
  DATE: 'date',
  TIMESTAMP: 'timestamp',
  FIELDSET: 'fieldset',
  GROUP: 'group',
};

export const GEN_FORM_RECURSIVE_TYPES = [
  FORM_ROOT,
  FORM_TYPES.FIELDSET,
];

export const FORM_TYPES_CREATE_DD = {
  [FORM_TYPES.TEXT]: {
    label: 'Text',
    value: FORM_TYPES.TEXT,
  },
  [FORM_TYPES.NUMBER]: {
    label: 'Číslo',
    value: FORM_TYPES.NUMBER,
  },
  [FORM_TYPES.SELECT]: {
    label: 'Select',
    value: FORM_TYPES.SELECT,
  },
  [FORM_TYPES.MULTISELECT]: {
    label: 'Multi select',
    value: FORM_TYPES.MULTISELECT,
  },
  [FORM_TYPES.CHECKBOX]: {
    label: 'Checkbox',
    value: FORM_TYPES.CHECKBOX,
  },
  [FORM_TYPES.RADIO]: {
    label: 'Radio',
    value: FORM_TYPES.RADIO,
  },
  [FORM_TYPES.TIME]: {
    label: 'Čas',
    value: FORM_TYPES.TIME,
  },
  [FORM_TYPES.DATE]: {
    label: 'Datum',
    value: FORM_TYPES.DATE,
  },
  [FORM_TYPES.TIMESTAMP]: {
    label: 'Datum a čas',
    value: FORM_TYPES.TIMESTAMP,
  },
  [FORM_TYPES.FIELDSET]: {
    label: 'Fieldset',
    value: FORM_TYPES.FIELDSET,
  },
  /*[FORM_TYPES.GROUP]: {
    label: 'Skupina',
    value: FORM_TYPES.GROUP,
  },*/
};
