import React from 'react';
import {
  func, string, number, object,
} from 'prop-types';
import { withTheme, } from 'styled-components';
import AddPartButton from '../styles/AddPartButton';
import Add from '../../../styles/icons/Add';

const AddPart = ({
  parent,
  position,
  onAddPart,
  theme,
}) => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', }}>
    <hr style={{
      width: '100%',
      marginRight: '10px',
      border: 0,
      borderTop: `1px dashed ${theme.grey.t800}`,
    }}
    />
    <AddPartButton
      type="button"
      onClick={() => onAddPart(parent, position)}
    >
      <Add />
    </AddPartButton>
  </div>
);


AddPart.propTypes = {
  parent: string.isRequired,
  position: number.isRequired,
  onAddPart: func.isRequired,
  theme: object.isRequired,
};


export default withTheme(AddPart);
