import React from 'react';
import { func, } from 'prop-types';

import Logo from '../../styles/icons/Logo';
import Cross from '../../styles/icons/Cross';


const SidebarHeader = ({ onToggleSidebar, }) => (
  <div className="sidebar--header">

    <span className="sidebar--header-logo">
      <Logo />
    </span>

    <span>
      Konfigurace
    </span>

    <span
      role="none"
      className="sidebar--header-icon-close"
      onClick={onToggleSidebar}
    >
      <Cross />
    </span>

  </div>
);


SidebarHeader.propTypes = {
  onToggleSidebar: func.isRequired,
};

export default SidebarHeader;
