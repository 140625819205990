import styled from 'styled-components';

import media from '../../../styles/media';


const StyledModal = styled.div`
  background-color: #FFFFFF;
  box-shadow: ${(p) => p.theme.common.shadow};
  
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  margin-bottom: 3rem;

  ${media.XS`
    width: 95vw;
    max-width: 540px;
  `}

  ${media.SM`
    width: 540px;
  `}

   ${(p) => (p.size === 'MD' || p.size === 'LG') && media.MD`
    width: 720px;
  `}

   ${(p) => p.size === 'LG' && media.LG`
    width: 960px;
  `}

   ${(p) => p.size === 'FHD' && media.FHD`
    width: 1600px;
  `}
`;


export default StyledModal;
