import React from 'react';
import styled from 'styled-components';

import CircleWithCross from '../../styles/icons/CircleWithCross';


const StyledRTNoData = styled.div`
  height: 7rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: ${(p) => p.theme.grey.t500};

  .rt--no-data-icon {
    font-size: 1.5rem;
    margin-right: 4px;
  }

  .rt--no-data-text {
    margin-top: 0.25rem;
  }
`;


const RTNoData = () => (
  <StyledRTNoData>
    <span className="rt--no-data-icon">
      <CircleWithCross />
    </span>
    <span className="rt--no-data-text">
      Žádná data
    </span>
  </StyledRTNoData>
);


export default RTNoData;
