import React, { Fragment, Component, } from 'react';
import {
  shape, func, object,
} from 'prop-types';

import { withLang, } from '../../../../logic/languages/withLang';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Input from '../../../../atoms/Input/Input';
import CheckBox from '../../../../atoms/CheckBox/CheckBox';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';
import MultiSelectListWrapper from '../../../../components/wrappers/MultiSelectListWrapper';
import Divider from '../../../../atoms/Divider/Divider';

class StandAppConfigurationDetail extends Component {
  componentDidMount() {
    const {
      getStandAppConfigurationColumnsShared,
    } = this.props;
    getStandAppConfigurationColumnsShared();
  }

  render() {
    const {
      form,
      values,
      standAppConfigurationColumnsSharedData,
      // methods
      handleOnChangeValue,
      langError,
    } = this.props;
    return (
      <Fragment>
        <Row formGroup>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
            Název
          </Col>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
            <Input
              type="text"
              value={values.name}
              status={form.name.status}
              onChange={(e) => handleOnChangeValue(form.name.name, e.target.value)}
            />
          </Col>
          <Col MD_offset={3} LG_offset={6} sidePadding>
            <InfoMsg
              status={form.name.status}
              msg={langError(form.name.statusMsg)}
              showIcon
            />
          </Col>
        </Row>

        <Row formGroup>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
          <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
            <CheckBox
              id={form.createButton.name}
              text="Tlačítko pro vytvoření přepravy"
              checked={values.createButton}
              onChange={() => handleOnChangeValue(form.createButton.name, !values.createButton)}
            />
          </Col>
          <Col MD_offset={3} LG_offset={6} sidePadding>
            <InfoMsg
              status={form.createButton.status}
              msg={langError(form.createButton.statusMsg)}
              showIcon
            />
          </Col>
        </Row>

        <Divider />
        <Row formGroup>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
          <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
            <CheckBox
              id={form.planTab.name}
              text="Tab pro plánované příjezdy"
              checked={values.planTab}
              onChange={() => handleOnChangeValue(form.planTab.name, !values.planTab)}
            />
          </Col>
          <Col MD_offset={3} LG_offset={6} sidePadding>
            <InfoMsg
              status={form.planTab.status}
              msg={langError(form.planTab.statusMsg)}
              showIcon
            />
          </Col>
        </Row>
        {values.planTab ? (
          <Fragment>
            <Row formGroup>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
                Sloupce pro plánované přepravy
              </Col>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
                <MultiSelectListWrapper
                  value={values.planTabColumns}
                  status={form.planTabColumns.status}
                  options={standAppConfigurationColumnsSharedData.data}
                  onChange={(value) => handleOnChangeValue(form.planTabColumns.name, value)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isLoading={standAppConfigurationColumnsSharedData.isLoading}
                  error={standAppConfigurationColumnsSharedData.error}
                />
              </Col>
              <Col MD_offset={3} LG_offset={6} sidePadding>
                <InfoMsg
                  status={form.planTabColumns.status}
                  msg={langError(form.planTabColumns.statusMsg)}
                  showIcon
                />
              </Col>
            </Row>

            <Row formGroup>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
              <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
                <CheckBox
                  id={form.isPlanTabMain.name}
                  text="Povolit akce"
                  checked={values.isPlanTabMain}
                  onChange={
                    () => handleOnChangeValue(form.isPlanTabMain.name, !values.isPlanTabMain)
                  }
                />
              </Col>
              <Col MD_offset={3} LG_offset={6} sidePadding>
                <InfoMsg
                  status={form.isPlanTabMain.status}
                  msg={langError(form.isPlanTabMain.statusMsg)}
                  showIcon
                />
              </Col>
            </Row>

            <Row formGroup>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
              <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
                <CheckBox
                  id={form.planTabCallButton.name}
                  text="Vyvolat přepravu"
                  checked={values.planTabCallButton}
                  onChange={() => handleOnChangeValue(
                    form.planTabCallButton.name, !values.planTabCallButton
                  )}
                />
              </Col>
              <Col MD_offset={3} LG_offset={6} sidePadding>
                <InfoMsg
                  status={form.planTabCallButton.status}
                  msg={langError(form.planTabCallButton.statusMsg)}
                  showIcon
                />
              </Col>
            </Row>

          </Fragment>
        )
          : ''}
        <Divider />

        <Row formGroup>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
          <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
            <CheckBox
              id={form.queueTab.name}
              text="Tab pro frontu"
              checked={values.queueTab}
              onChange={() => handleOnChangeValue(form.queueTab.name, !values.queueTab)}
            />
          </Col>
          <Col MD_offset={3} LG_offset={6} sidePadding>
            <InfoMsg
              status={form.queueTab.status}
              msg={langError(form.queueTab.statusMsg)}
              showIcon
            />
          </Col>
        </Row>

        {values.queueTab ? (
          <Fragment>
            <Row formGroup>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
                Sloupce pro frontu
              </Col>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
                <MultiSelectListWrapper
                  value={values.queueTabColumns}
                  status={form.queueTabColumns.status}
                  options={standAppConfigurationColumnsSharedData.data}
                  onChange={(value) => handleOnChangeValue(form.queueTabColumns.name, value)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isLoading={standAppConfigurationColumnsSharedData.isLoading}
                  error={standAppConfigurationColumnsSharedData.error}
                />
              </Col>
              <Col MD_offset={3} LG_offset={6} sidePadding>
                <InfoMsg
                  status={form.queueTabColumns.status}
                  msg={langError(form.queueTabColumns.statusMsg)}
                  showIcon
                />
              </Col>
            </Row>

            <Row formGroup>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
              <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
                <CheckBox
                  id={form.isQueueTabMain.name}
                  text="Povolit akce"
                  checked={values.isQueueTabMain}
                  onChange={
                    () => handleOnChangeValue(form.isQueueTabMain.name, !values.isQueueTabMain)
                  }
                />
              </Col>
              <Col MD_offset={3} LG_offset={6} sidePadding>
                <InfoMsg
                  status={form.isQueueTabMain.status}
                  msg={langError(form.isQueueTabMain.statusMsg)}
                  showIcon
                />
              </Col>
            </Row>
          </Fragment>
        )
          : ''}

        <Divider />

        <Row formGroup>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
          <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
            <CheckBox
              id={form.standTab.name}
              text="Tab pro stanoviště"
              checked={values.standTab}
              onChange={() => handleOnChangeValue(form.standTab.name, !values.standTab)}
            />
          </Col>
          <Col MD_offset={3} LG_offset={6} sidePadding>
            <InfoMsg
              status={form.standTab.status}
              msg={langError(form.standTab.statusMsg)}
              showIcon
            />
          </Col>
        </Row>

        {values.standTab ? (
          <Fragment>
            <Row formGroup>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
                Sloupce pro stanoviště
              </Col>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
                <MultiSelectListWrapper
                  value={values.standTabColumns}
                  status={form.standTabColumns.status}
                  options={standAppConfigurationColumnsSharedData.data}
                  onChange={(value) => handleOnChangeValue(form.standTabColumns.name, value)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isLoading={standAppConfigurationColumnsSharedData.isLoading}
                  error={standAppConfigurationColumnsSharedData.error}
                />
              </Col>
              <Col MD_offset={3} LG_offset={6} sidePadding>
                <InfoMsg
                  status={form.standTabColumns.status}
                  msg={langError(form.standTabColumns.statusMsg)}
                  showIcon
                />
              </Col>
            </Row>
            <Row formGroup>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
              <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
                <CheckBox
                  id={form.isStandTabMain.name}
                  text="Povolit akce"
                  checked={values.isStandTabMain}
                  onChange={
                    () => handleOnChangeValue(form.isStandTabMain.name, !values.isStandTabMain)
                  }
                />
              </Col>
              <Col MD_offset={3} LG_offset={6} sidePadding>
                <InfoMsg
                  status={form.isStandTabMain.status}
                  msg={langError(form.isStandTabMain.statusMsg)}
                  showIcon
                />
              </Col>
            </Row>
            <Row formGroup>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
              <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
                <CheckBox
                  id={form.standTabCallButton.name}
                  text="Vyvolat přepravu"
                  checked={values.standTabCallButton}
                  onChange={() => handleOnChangeValue(
                    form.standTabCallButton.name, !values.standTabCallButton
                  )}
                />
              </Col>
              <Col MD_offset={3} LG_offset={6} sidePadding>
                <InfoMsg
                  status={form.standTabCallButton.status}
                  msg={langError(form.standTabCallButton.statusMsg)}
                  showIcon
                />
              </Col>
            </Row>
          </Fragment>
        )
          : ''}

        <Divider />
        <Row formGroup>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
          <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
            <CheckBox
              id={form.delayTab.name}
              text="Tab pro zpožděné přepravy"
              checked={values.delayTab}
              onChange={() => handleOnChangeValue(form.delayTab.name, !values.delayTab)}
            />
          </Col>
          <Col MD_offset={3} LG_offset={6} sidePadding>
            <InfoMsg
              status={form.delayTab.status}
              msg={langError(form.delayTab.statusMsg)}
              showIcon
            />
          </Col>
        </Row>

        {values.delayTab ? (
          <Fragment>
            <Row formGroup>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
                Sloupce pro zpožděné přepravy
              </Col>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
                <MultiSelectListWrapper
                  value={values.delayTabColumns}
                  status={form.delayTabColumns.status}
                  options={standAppConfigurationColumnsSharedData.data}
                  onChange={(value) => handleOnChangeValue(form.delayTabColumns.name, value)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isLoading={standAppConfigurationColumnsSharedData.isLoading}
                  error={standAppConfigurationColumnsSharedData.error}
                />
              </Col>
              <Col MD_offset={3} LG_offset={6} sidePadding>
                <InfoMsg
                  status={form.delayTabColumns.status}
                  msg={langError(form.delayTabColumns.statusMsg)}
                  showIcon
                />
              </Col>
            </Row>
            <Row formGroup>
              <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
              <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
                <CheckBox
                  id={form.isDelayTabMain.name}
                  text="Povolit akce"
                  checked={values.isDelayTabMain}
                  onChange={
                    () => handleOnChangeValue(
                      form.isDelayTabMain.name,
                      !values.isDelayTabMain
                    )
                  }
                />
              </Col>
              <Col MD_offset={3} LG_offset={6} sidePadding>
                <InfoMsg
                  status={form.isDelayTabMain.status}
                  msg={langError(form.isDelayTabMain.statusMsg)}
                  showIcon
                />
              </Col>
            </Row>
          </Fragment>
        )
          : ''}

        <Divider />
        <Row formGroup>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
          <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
            <CheckBox
              id={form.visitorTab.name}
              text="Tab pro návštěvy"
              checked={values.visitorTab}
              onChange={() => handleOnChangeValue(form.visitorTab.name, !values.visitorTab)}
            />
          </Col>
          <Col MD_offset={3} LG_offset={6} sidePadding>
            <InfoMsg
              status={form.visitorTab.status}
              msg={langError(form.visitorTab.statusMsg)}
              showIcon
            />
          </Col>
        </Row>
        {values.visitorTab ? (
          <Row formGroup>
            <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
            <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
              <CheckBox
                id={form.isVisitorTabMain.name}
                text="Povolit akce"
                checked={values.isVisitorTabMain}
                onChange={
                  () => handleOnChangeValue(
                    form.isVisitorTabMain.name,
                    !values.isVisitorTabMain
                  )
                }
              />
            </Col>
            <Col MD_offset={3} LG_offset={6} sidePadding>
              <InfoMsg
                status={form.isVisitorTabMain.status}
                msg={langError(form.isVisitorTabMain.statusMsg)}
                showIcon
              />
            </Col>
          </Row>
        )
          : ''}

      </Fragment>
    );
  }
}


StandAppConfigurationDetail.propTypes = {
  form: shape({
    name: object.isRequired,
    createButton: object.isRequired,
    planTab: object.isRequired,
    queueTab: object.isRequired,
    standTab: object.isRequired,
    visitorTab: object.isRequired,
    delayTab: object.isRequired,
    planTabColumns: object.isRequired,
    queueTabColumns: object.isRequired,
    standTabColumns: object.isRequired,
    delayTabColumns: object.isRequired,
  }).isRequired,
  standAppConfigurationColumnsSharedData: object.isRequired,
  getStandAppConfigurationColumnsShared: func.isRequired,
  values: object.isRequired,
  handleOnChangeValue: func.isRequired,
  langError: func.isRequired,
};


export default withLang(StandAppConfigurationDetail);
