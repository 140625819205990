import React, { Fragment, } from 'react';
import { Switch, Route, } from 'react-router-dom';

import Heading from '../../../atoms/Heading/Heading';
import TabsLinkPanel from '../../../atoms/TabsLink/TabsLinkPanel';
import TabLink from '../../../atoms/TabsLink/TabLink';
import KiosksPage from './KiosksPage';
import KiosksConfigurationsPage from './KiosksConfigurationsPage';


const KiosksLayout = () => (
  <Fragment>
    <Heading h4>
      Kiosky
    </Heading>

    <TabsLinkPanel>
      <TabLink
        exact={false}
        to="/devices/kiosks/main"
      >
        Zařízení
      </TabLink>
      <TabLink
        exact={false}
        to="/devices/kiosks/configuration"
      >
        Konfigurace
      </TabLink>
    </TabsLinkPanel>

    <Switch>
      <Route path="/devices/kiosks/main" component={KiosksPage} />
      <Route path="/devices/kiosks/configuration" component={KiosksConfigurationsPage} />
    </Switch>
  </Fragment>
);


export default KiosksLayout;
