import React from 'react';

import StyledSvg from './styles/StyledSvg';


const ActionsLogo = () => (
  <StyledSvg viewBox="-35 0 525 525.17837">
    <path d="M 433.992188 46.992188 L 316.269531 46.992188 C 283.359375 -1.804688 217.125 -14.683594 168.335938 18.226562 C 156.992188 25.875 147.230469 35.644531 139.574219 46.992188 L 21.957031 46.992188 C 9.867188 46.992188 0.078125 56.78125 0.078125 68.871094 L 0.078125 503.117188 C 0.078125 515.207031 9.867188 525 21.957031 525 L 433.992188 525 C 446.082031 525 455.875 515.207031 455.875 503.117188 L 455.875 68.871094 C 455.875 56.78125 446.082031 46.992188 433.992188 46.992188 Z M 227.976562 21.71875 C 274.804688 21.71875 312.769531 59.683594 312.769531 106.507812 C 312.769531 153.335938 274.804688 191.300781 227.976562 191.300781 C 181.148438 191.300781 143.183594 153.335938 143.183594 106.507812 C 143.25 59.703125 181.171875 21.773438 227.976562 21.71875 Z M 433.992188 503.117188 L 21.957031 503.117188 L 21.957031 68.871094 L 128.414062 68.871094 C 107.34375 123.894531 134.859375 185.570312 189.878906 206.640625 C 244.902344 227.710938 306.574219 200.195312 327.648438 145.175781 C 337.054688 120.613281 337.054688 93.433594 327.648438 68.863281 L 434.101562 68.863281 Z M 433.992188 503.117188 " />
    <path d="M 186.949219 168.324219 C 190.199219 168.160156 193.207031 166.554688 195.152344 163.949219 L 227.101562 124.5625 L 227.976562 124.5625 C 238.007812 124.5625 246.136719 116.433594 246.136719 106.398438 C 246.136719 96.367188 238.007812 88.238281 227.976562 88.238281 C 217.941406 88.238281 209.8125 96.367188 209.8125 106.398438 C 209.824219 107.84375 210.011719 109.277344 210.359375 110.667969 L 178.414062 150.054688 C 174.605469 154.746094 175.328125 161.640625 180.023438 165.449219 C 181.980469 167.035156 184.429688 167.898438 186.949219 167.886719 C 186.949219 167.886719 186.949219 168.324219 186.949219 168.324219 Z M 186.949219 168.324219 " />
  </StyledSvg>
);

export default ActionsLogo;
