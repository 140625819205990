import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION, FORM_SUCCESS, FORM_ERROR,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


const parseValues = (values) => ({
  name: values.name,
  platforms: values.platforms.map((item) => item.id),
});


/**
 * Refresh data - on mount
 */
export const DEVICES__STOREKEEPER_REFRESH = 'DEVICES__STOREKEEPER_REFRESH';

export const refreshStorekeeperDetailAction = () => ({
  type: `${DEVICES__STOREKEEPER_REFRESH}`,
});


/**
 * Change Value
 */
export const DEVICES__STOREKEEPER_DEVICE_CHANGE_VALUE_DETAIL = 'DEVICES__STOREKEEPER_DEVICE_CHANGE_VALUE_DETAIL';

export const changeValueAction = (name, value, validationError) => ({
  type: `${DEVICES__STOREKEEPER_DEVICE_CHANGE_VALUE_DETAIL}`,
  payload: {
    name,
    value,
    validationError,
  },
});


/**
 * Validate Form
 */
export const DEVICES__STOREKEEPER_DEVICE_VALIDATE_FORM_DETAIL = 'DEVICES__STOREKEEPER_DEVICE_VALIDATE_FORM_DETAIL';

export const validateFormAction = (error) => ({
  type: `${DEVICES__STOREKEEPER_DEVICE_VALIDATE_FORM_DETAIL}`,
  payload: error,
});


/*
 * GET Storekeeper Device Detail
 */
export const DEVICES__GET_STOREKEEPER_DEVICE_DETAIL = 'DEVICES__GET_STOREKEEPER_DEVICE_DETAIL';
export const getStorekeeperDeviceDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__GET_STOREKEEPER_DEVICE_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/devices/storekeeper/detail?id=${id}`,
    });

    dispatch({
      type: `${DEVICES__GET_STOREKEEPER_DEVICE_DETAIL}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${DEVICES__GET_STOREKEEPER_DEVICE_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * UPDATE Storekeeper Detail
 */
export const DEVICES__UPDATE_STOREKEEPER_DEVICE_DETAIL = 'DEVICES__UPDATE_STOREKEEPER_DEVICE_DETAIL';
export const updateStorekeeperDeviceDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__UPDATE_STOREKEEPER_DEVICE_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/devices/storekeeper`,
      data: {
        id: item.id,
        values: parseValues(item.values),
      },
    });

    dispatch({
      type: `${DEVICES__UPDATE_STOREKEEPER_DEVICE_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Zařízení pro aplikaci skladník aktualizováno',
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${DEVICES__UPDATE_STOREKEEPER_DEVICE_DETAIL}__${REJECTED_VALIDATION}`,
        payload: parsedError,
      });
      return;
    }

    dispatch({
      type: `${DEVICES__UPDATE_STOREKEEPER_DEVICE_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * DELETE Storekeeper Detail
 */
export const DEVICES__DELETE_STOREKEEPER_DEVICE_DETAIL = 'DEVICES__DELETE_STOREKEEPER_DEVICE_DETAIL';
export const deleteStorekeeperDeviceDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__DELETE_STOREKEEPER_DEVICE_DETAIL}__${PENDING}`,
      payload: {
        id,
      },
    });

    const response = await api({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/devices/storekeeper?id=${id}`,
    });

    dispatch({
      type: `${DEVICES__DELETE_STOREKEEPER_DEVICE_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        id,
        notif: {
          type: FORM_SUCCESS,
          title: 'Zařízení pro aplikaci skladník odhlášeno',
        },
      },
    });
  } catch (err) {
    const parsedError = handleError(err);

    dispatch({
      type: `${DEVICES__DELETE_STOREKEEPER_DEVICE_DETAIL}__${REJECTED}`,
      payload: {
        ...parsedError,
        id,
        notif: {
          type: FORM_ERROR,
          title: 'Nepovedlo se odhlásit zařízení',
          showMessage: true,
        },
      },
    });
  }
};
