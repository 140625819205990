import React, { Fragment, } from 'react';
import {
  shape, func, object, bool, string,
} from 'prop-types';

import { withLang, } from '../../../../logic/languages/withLang';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Input from '../../../../atoms/Input/Input';
import CheckBox from '../../../../atoms/CheckBox/CheckBox';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';
import InputSelectWrapper from '../../../../components/wrappers/InputSelectWrapper';
import InputSwitch from '../../../../atoms/InputSwitch/InputSwitch';
import {
  KIOSKS_CONFIGURATION_DEFAULT_SEARCH,
  KIOSKS_CONFIGURATION_DETAIL_PARAMETERS,
  KIOSKS_CONFIGURATION_AUTH_METHODS,
} from '../../../../globals';

const KiosksConfigurationDetail = ({
  // data
  form,
  values,
  // methods
  handleOnChangeValue,
  langError,
}) => (
  <Fragment>
    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Název
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="text"
          value={values.name}
          status={form.name.status}
          onChange={(e) => handleOnChangeValue(form.name.name, e.target.value)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.name.status}
          msg={langError(form.name.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Metoda ověření
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <InputSelectWrapper
          value={values.type}
          status={form.type.status}
          options={KIOSKS_CONFIGURATION_AUTH_METHODS}
          onChange={(value) => handleOnChangeValue(form.type.name, value)}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.type.status}
          msg={langError(form.type.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Vyhledávací parametr
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <InputSwitch
          value={values.defaultSearch}
          left={KIOSKS_CONFIGURATION_DEFAULT_SEARCH.no.id}
          right={KIOSKS_CONFIGURATION_DEFAULT_SEARCH.truckRegistration.id}
          labelLeft={KIOSKS_CONFIGURATION_DEFAULT_SEARCH.no.name}
          labelRight={KIOSKS_CONFIGURATION_DEFAULT_SEARCH.truckRegistration.name}
          status={form.type.status}
          onChange={(value) => handleOnChangeValue(form.defaultSearch.name, value)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.defaultSearch.status}
          msg={langError(form.defaultSearch.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <CheckBox
          id={form.visitorsButtonEnabled.name}
          text="Tlačítko návštěvy"
          checked={values.visitorsButtonEnabled}
          onChange={() => handleOnChangeValue(
            form.visitorsButtonEnabled.name,
            !values.visitorsButtonEnabled
          )}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.visitorsButtonEnabled.status}
          msg={langError(form.visitorsButtonEnabled.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <CheckBox
          id={form.transportationButtonEnabled.name}
          text="Tlačítko přepravy"
          checked={values.transportationButtonEnabled}
          onChange={() => handleOnChangeValue(
            form.transportationButtonEnabled.name,
            !values.transportationButtonEnabled
          )}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.transportationButtonEnabled.status}
          msg={langError(form.transportationButtonEnabled.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <CheckBox
          id={form.printTicket.name}
          text="Tisknout lístek"
          checked={values.printTicket}
          onChange={() => handleOnChangeValue(form.printTicket.name, !values.printTicket)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.printTicket.status}
          msg={langError(form.printTicket.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <CheckBox
          id={form.automaticCameraReadSubscriptionEnabled.name}
          text="Automatické čtení z kamery"
          checked={values.automaticCameraReadSubscriptionEnabled}
          onChange={() => handleOnChangeValue(
            form.automaticCameraReadSubscriptionEnabled.name,
            !values.automaticCameraReadSubscriptionEnabled
          )}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.automaticCameraReadSubscriptionEnabled.status}
          msg={langError(form.automaticCameraReadSubscriptionEnabled.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Počet sekund pro přesměrování
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="number"
          value={values.inactiveRedirectTime}
          status={form.inactiveRedirectTime.status}
          onChange={(e) => handleOnChangeValue(form.inactiveRedirectTime.name, e.target.value)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.inactiveRedirectTime.status}
          msg={langError(form.inactiveRedirectTime.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Zobrazované informace
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <InputSelectWrapper
          value={values.detailParameters}
          status={form.detailParameters.status}
          options={KIOSKS_CONFIGURATION_DETAIL_PARAMETERS}
          isMulti
          onChange={(value) => handleOnChangeValue(form.detailParameters.name, value)}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.detailParameters.status}
          msg={langError(form.detailParameters.statusMsg)}
          showIcon
        />
      </Col>
    </Row>
  </Fragment>
);


KiosksConfigurationDetail.propTypes = {
  form: shape({
    name: object.isRequired,
    type: object.isRequired,
    transportationButtonEnabled: object.isRequired,
    visitorsButtonEnabled: object.isRequired,
    printTicket: object.isRequired,
    inactiveRedirectTime: object.isRequired,
    automaticCameraReadSubscriptionEnabled: object.isRequired,
    detailParameters: object.isRequired,
    defaultSearch: object.isRequired,
  }).isRequired,
  values: object.isRequired,
  handleOnChangeValue: func.isRequired,
  langError: func.isRequired,
};


export default withLang(KiosksConfigurationDetail);
