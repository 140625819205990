import {
  PENDING, FULFILLED, REJECTED,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


//
// Get Shared Data Action
//  - universal action for all common shared data
//
export const SHARED__GET_SHARED_DATA = 'SHARED__GET_SHARED_DATA';
export const getSharedDataAction = (dataType) => async (dispatch) => {
  try {
    dispatch({
      type: `${SHARED__GET_SHARED_DATA}__${PENDING}`,
      payload: {
        dataType,
      },
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}${dataType.apiRoute}`,
    });

    dispatch({
      type: `${SHARED__GET_SHARED_DATA}__${FULFILLED}`,
      payload: {
        response,
        dataType,
      },
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${SHARED__GET_SHARED_DATA}__${REJECTED}`,
      payload: {
        response: parsedError,
        dataType,
      },
    });
  }
};


/*
 * GET Kiosk configurations show information Shared
 */
export const SHARED__GET_KIOSK_CONFIGURATIONS_SHOW_INFORMATION = 'SHARED__GET_KIOSK_CONFIGURATIONS_SHOW_INFORMATION';
export const getKioskConfigurationsShowInformationSharedAction = () => async (dispatch) => {
  try {
    dispatch({
      type: `${SHARED__GET_KIOSK_CONFIGURATIONS_SHOW_INFORMATION}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/devices/kiosk/configurations/enums/information`,
    });

    dispatch({
      type: `${SHARED__GET_KIOSK_CONFIGURATIONS_SHOW_INFORMATION}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${SHARED__GET_KIOSK_CONFIGURATIONS_SHOW_INFORMATION}__${REJECTED}`,
      payload: parsedError,
    });
  }
};

/*
 * GET Kiosk configurations auth params Shared
 */
export const SHARED__GET_KIOSK_CONFIGURATIONS_AUTH_PARAMS = 'SHARED__GET_KIOSK_CONFIGURATIONS_AUTH_PARAMS';
export const getKioskConfigurationsAuthParamsSharedAction = () => async (dispatch) => {
  try {
    dispatch({
      type: `${SHARED__GET_KIOSK_CONFIGURATIONS_AUTH_PARAMS}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/devices/kiosk/configurations/enums/find`,
    });

    dispatch({
      type: `${SHARED__GET_KIOSK_CONFIGURATIONS_AUTH_PARAMS}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${SHARED__GET_KIOSK_CONFIGURATIONS_AUTH_PARAMS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};

/*
 * GET Kiosk configurations Shared
 */
export const SHARED__GET_KIOSK_CONFIGURATIONS = 'SHARED__GET_KIOSK_CONFIGURATIONS';
export const getKioskConfigurationsSharedAction = () => async (dispatch) => {
  try {
    dispatch({
      type: `${SHARED__GET_KIOSK_CONFIGURATIONS}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/devices/kiosk/configurations`,
    });

    dispatch({
      type: `${SHARED__GET_KIOSK_CONFIGURATIONS}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${SHARED__GET_KIOSK_CONFIGURATIONS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};

/*
 * GET Configuration Shared
 */
export const SHARED__GET_CONFIGURATION = 'SHARED__GET_CONFIGURATION';
export const getConfigurationSharedAction = () => async (dispatch) => {
  try {
    dispatch({
      type: `${SHARED__GET_CONFIGURATION}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/configurations`,
    });

    dispatch({
      type: `${SHARED__GET_CONFIGURATION}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${SHARED__GET_CONFIGURATION}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * GET platforms Shared
 */
export const SHARED__GET_PLATFORMS = 'SHARED__GET_PLATFORMS';
export const getPlatformsSharedAction = () => async (dispatch) => {
  try {
    dispatch({
      type: `${SHARED__GET_PLATFORMS}__${PENDING}`,
    });
    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/platforms`,
    });

    dispatch({
      type: `${SHARED__GET_PLATFORMS}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${SHARED__GET_PLATFORMS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * GET Actions Shared
 */
export const SHARED__GET_ACTIONS = 'SHARED__GET_ACTIONS';
export const getActionsSharedAction = () => async (dispatch) => {
  try {
    dispatch({
      type: `${SHARED__GET_ACTIONS}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/actions`,
    });

    dispatch({
      type: `${SHARED__GET_ACTIONS}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${SHARED__GET_ACTIONS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};

/*
 * GET platforms Shared
 */
export const SHARED__GET_TRANSPORTATION_TYPES = 'SHARED__GET_TRANSPORTATION_TYPES';
export const getTransportationTypesSharedAction = () => async (dispatch) => {
  try {
    dispatch({
      type: `${SHARED__GET_TRANSPORTATION_TYPES}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/transportation_types`,
    });
    dispatch({
      type: `${SHARED__GET_TRANSPORTATION_TYPES}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${SHARED__GET_TRANSPORTATION_TYPES}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * GET Stands Shared
 */
export const SHARED__GET_STANDS = 'SHARED__GET_STANDS';
export const getStandsSharedAction = (withoutClearance = false) => async (dispatch) => {
  try {
    dispatch({
      type: `${SHARED__GET_STANDS}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/stands?withoutClearance=${withoutClearance}`,
    });

    dispatch({
      type: `${SHARED__GET_STANDS}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${SHARED__GET_STANDS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * GET Devices Shared
 */
export const SHARED__GET_DEVICES = 'SHARED__GET_DEVICES';
export const getDevicesSharedAction = () => async (dispatch) => {
  try {
    dispatch({
      type: `${SHARED__GET_DEVICES}__${PENDING}`,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/devices`,
      data: {
        types: [ 'parking', 'kiosk', ],
      },
    });

    dispatch({
      type: `${SHARED__GET_DEVICES}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${SHARED__GET_DEVICES}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * GET Dispalys Shared
 */
export const SHARED__GET_DISPLAYS = 'SHARED__GET_DISPLAYS';
export const getDisplaysSharedAction = () => async (dispatch) => {
  try {
    dispatch({
      type: `${SHARED__GET_DISPLAYS}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/devices/display`,
    });

    dispatch({
      type: `${SHARED__GET_DISPLAYS}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${SHARED__GET_DISPLAYS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};

/*
 * GET Stand App configuration Shared
 */
export const SHARED__GET_STAND_APP_CONFIGURATION = 'SHARED__GET_STAND_APP_CONFIGURATION';
export const getStandAppConfigurationSharedAction = () => async (dispatch) => {
  try {
    dispatch({
      type: `${SHARED__GET_STAND_APP_CONFIGURATION}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/stands/configs`,
    });

    dispatch({
      type: `${SHARED__GET_STAND_APP_CONFIGURATION}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${SHARED__GET_STAND_APP_CONFIGURATION}__${REJECTED}`,
      payload: parsedError,
    });
  }
};

/*
 * GET Stand App configuration Columns Shared
 */
export const SHARED__GET_STAND_APP_CONFIGURATION_COLUMNS = 'SHARED__GET_STAND_APP_CONFIGURATION_COLUMNS';
export const getStandAppConfigurationColumnsSharedAction = () => async (dispatch) => {
  try {
    dispatch({
      type: `${SHARED__GET_STAND_APP_CONFIGURATION_COLUMNS}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/stands/configuration/dashboardColumns`,
    });

    dispatch({
      type: `${SHARED__GET_STAND_APP_CONFIGURATION_COLUMNS}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${SHARED__GET_STAND_APP_CONFIGURATION_COLUMNS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};

/*
 * GET Stand App configuration Columns Shared
 */
export const SHARED__GET_DISPLAY_SUBTYPES = 'SHARED__GET_DISPLAY_SUBTYPES';
export const getDisplaySubtypesSharedAction = () => async (dispatch) => {
  try {
    dispatch({
      type: `${SHARED__GET_DISPLAY_SUBTYPES}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/devices/display/display-subtypes`,
    });

    dispatch({
      type: `${SHARED__GET_DISPLAY_SUBTYPES}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${SHARED__GET_DISPLAY_SUBTYPES}__${REJECTED}`,
      payload: parsedError,
    });
  }
};

/*
 * GET Stand App configuration Columns Shared
 */
export const SHARED__GET_DISPLAY_CONFIGURATION = 'SHARED__GET_DISPLAY_CONFIGURATION';
export const getDisplayConfigurationSharedAction = (type) => async (dispatch) => {
  try {
    dispatch({
      type: `${SHARED__GET_DISPLAY_CONFIGURATION}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/devices/display/configuration?type=${type}`,
    });

    dispatch({
      type: `${SHARED__GET_DISPLAY_CONFIGURATION}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${SHARED__GET_DISPLAY_CONFIGURATION}__${REJECTED}`,
      payload: parsedError,
    });
  }
};

/*
 * GET Numerical Series dropdown
 */
export const SHARED__GET_NUMERICAL_SERIES = 'SHARED__GET_NUMERICAL_SERIES';
export const getNumericalSeriesSharedAction = () => async (dispatch) => {
  try {
    dispatch({
      type: `${SHARED__GET_NUMERICAL_SERIES}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/numerical_series/dropDown`,
    });

    dispatch({
      type: `${SHARED__GET_NUMERICAL_SERIES}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${SHARED__GET_NUMERICAL_SERIES}__${REJECTED}`,
      payload: parsedError,
    });
  }
};

/*
 * GET Cameras dropdown
 */
export const SHARED__GET_CAMERAS = 'SHARED__GET_CAMERAS';
export const getCamerasSharedAction = () => async (dispatch) => {
  try {
    dispatch({
      type: `${SHARED__GET_CAMERAS}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/devices/cameras/dropDown`,
    });

    dispatch({
      type: `${SHARED__GET_CAMERAS}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${SHARED__GET_CAMERAS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};

/*
 * GET Tickets dropdown
 */
export const SHARED__GET_TICKETS = 'SHARED__GET_TICKETS';
export const getTicketsSharedAction = () => async (dispatch) => {
  try {
    dispatch({
      type: `${SHARED__GET_TICKETS}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/tickets/dropDown`,
    });

    dispatch({
      type: `${SHARED__GET_TICKETS}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${SHARED__GET_TICKETS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};
