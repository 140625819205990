import React from 'react';

import Button from './Button';
import Loader from '../Loader/Loader';


const ButtonLoader = ({
  isLoading, disabled, children, ...props
}) => (
  <Button
    disabled={disabled || isLoading}
    {...props}
  >
    {isLoading
      ? <Loader />
      : children
    }
  </Button>
);


export default ButtonLoader;
