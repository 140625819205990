import React, { Fragment, } from 'react';
import {
  shape, func, object, string,
} from 'prop-types';

import { withLang, } from '../../../../logic/languages/withLang';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Input from '../../../../atoms/Input/Input';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';
import InputList from './InputList';


const TransportationEditFormsDetail = ({
  // data
  form,
  values,
  // methods
  onChangeValue,
  langError,
  toggleModalInput,
}) => (
  <Fragment>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Název
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="text"
          value={values.name}
          status={form.name.status}
          onChange={(e) => onChangeValue(form.name.name, e.target.value)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.name.status}
          msg={langError(form.name.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col>
        <InputList
          // data
          list={values.transportationEditFormInputs}
          // methods
          onChangeValue={onChangeValue}
          toggleModalInput={toggleModalInput}
        />
      </Col>
    </Row>

  </Fragment>
);


TransportationEditFormsDetail.propTypes = {
  form: shape({
    name: shape({
      name: string.isRequired,
      type: string.isRequired,
      validation: object.isRequired,
      status: string.isRequired,
      info: string.isRequired,
      statusMsg: object.isRequired,
    }),
  }).isRequired,
  values: shape({
    name: string,
  }).isRequired,
  onChangeValue: func.isRequired,
  langError: func.isRequired,
  toggleModalInput: func.isRequired,
};


export default withLang(TransportationEditFormsDetail);
