import React, { Fragment, } from 'react';
import {
  shape, number, bool, object, func, arrayOf,
} from 'prop-types';

import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import KiosksTable from './KiosksTable';
import Restore from '../../../../styles/icons/Restore';


const Kiosks = ({
  // data
  table,
  kiosksData,
  // methods
  onGetKiosks,
  onEdit,
  onRegister,
  onDelete,
  changeFilter,
}) => (
  <Fragment>

    <Row formGroup>
      <Col>
        <ButtonLoader
          onClick={() => onGetKiosks()}
          color="warning"
          shape="outline"
          isLoading={kiosksData.isLoading}
        >
          <span className="btn--icon"><Restore /></span>
          Synchronizovat
        </ButtonLoader>
      </Col>
    </Row>

    <KiosksTable
      data={table.data}
      table={table}
      filter={table.filter}
      count={table.count}
      kiosksData={kiosksData}
      onGetKiosks={onGetKiosks}
      onEdit={onEdit}
      onRegister={onRegister}
      onDelete={onDelete}
      changeFilter={changeFilter}
    />

  </Fragment>
);


Kiosks.propTypes = {
  table: shape({
    data: arrayOf(object).isRequired,
    count: number.isRequired,
    filter: object.isRequired,
  }).isRequired,
  kiosksData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  changeFilter: func.isRequired,
  onGetKiosks: func.isRequired,
  onEdit: func.isRequired,
  onRegister: func.isRequired,
  onDelete: func.isRequired,
};


export default Kiosks;
