import styled from 'styled-components';

import media from '../../styles/media';


const RTColHeader = styled.div`
  display: none;

  font-weight: 600;
  font-style: italic;

  ${media.XS`
    display: block;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  `}
`;


export default RTColHeader;
