import {
  QUEUE_CONFIG__GET_STANDS,
  QUEUE_CONFIG__STANDS_FILTER_CHANGED,
} from '../actions/stands';
import {
  QUEUE_CONFIG__DELETE_STANDS_DETAIL,
} from '../actions/standsDetail';
import { PENDING, FULFILLED, REJECTED, } from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';


const initialState = {
  standsData: {
    ...initRestData,
    isLoading: true,
  },
  table: {
    data: [],
    count: 0,
    filter: {
      offset: 0,
      limit: 10,
      sortBy: 'id',
      order: 'ASC',
      params: {
        id: '',
        name: '',
      },
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;
  switch (type) {
    /**
     * GET Stands
     */
    case `${QUEUE_CONFIG__GET_STANDS}__${PENDING}`: {
      return {
        ...state,
        standsData: {
          ...state.standsData,
          ...(handlePending()),
        },
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    case `${QUEUE_CONFIG__GET_STANDS}__${FULFILLED}`: {
      return {
        ...state,
        standsData: {
          ...state.standsData,
          ...(handleFulfilled(payload)),
        },
        table: {
          data: payload.stands.map((row) => ({
            ...row,
            name: row.standTranslation.name,
          })),
          count: payload.count,
          filter: payload.filter,
        },
      };
    }

    case `${QUEUE_CONFIG__GET_STANDS}__${REJECTED}`: {
      return {
        ...state,
        standsData: {
          ...state.standsData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * DELETE Stand
     */
    case `${QUEUE_CONFIG__DELETE_STANDS_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.filter((item) => item.id !== payload.id),
        },
      };
    }


    /**
     * Change Filter
     */
    case `${QUEUE_CONFIG__STANDS_FILTER_CHANGED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
