import React, { Fragment, } from 'react';
import {
  shape, bool, func, object, number, arrayOf,
} from 'prop-types';

import { withLang, } from '../../../../logic/languages/withLang';
import { FORM_ERROR, FORM_SUCCESS, } from '../../../../globals';
import Modal from '../../../../atoms/Modal/Modal';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import ButtonGrp from '../../../../atoms/ButtonGrp/ButtonGrp';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';
import CollisionReservation from '../../../../components/Collisions/CollisionReservation';
import CollisionTransports from '../../../../components/Collisions/CollisionTransports';
import Pencil from '../../../../styles/icons/Pencil';
import CheckBox from '../../../../atoms/CheckBox/CheckBox';
import CollisionFragment from '../../../../components/Collisions/CollisionFragment';


const TransportationTypesCollisionUpdate = ({
  modalData,
  actionData,
  onClose,
  action,
  langError,
  onChangeValue,
}) => {
  if (!modalData.isOpen) return null;

  const collPlat = modalData.transports.conflict || modalData.reservations.conflict;
  const collPlan = modalData.plans.conflict;

  return (
    <Modal
      isOpen={modalData.isOpen}
      onClose={onClose}
      closeOnEsc
      size="SM"
      color="error"
      title="Nastal konflikt"
    >
      {collPlat && (
        <Fragment>
          <CollisionFragment>
            Úpravou
            {' '}
            <i>Platforem</i>
            {' '}
            vzniknou následující konflikty:
          </CollisionFragment>

          <CollisionFragment lvl1 formGroup>
            <CollisionTransports
              collision={modalData.transports}
              maxHeight={250}
            />

            <CollisionReservation
              collision={modalData.reservations}
            />

            <CheckBox
              id="applyPlat"
              text="Aplikovat změny"
              checked={modalData.values.applyPlat}
              onChange={() => onChangeValue('applyPlat', !modalData.values.applyPlat)}
            />
          </CollisionFragment>
        </Fragment>
      )}

      {collPlan && (
        <Fragment>
          <CollisionFragment>
            Úpravou
            {' '}
            <i>Akcí</i>
            {' '}
            vzniknou následující konflikty:
          </CollisionFragment>

          <CollisionFragment lvl1 formGroup>
            <CollisionTransports
              collision={modalData.transports}
              maxHeight={250}
            />
            <div>
              Změny se aplikují na nové přepravy
            </div>
            <CheckBox
              id="applyPlan"
              text="Použít i na aktuální"
              checked={modalData.values.applyPlan}
              onChange={() => onChangeValue('applyPlan', !modalData.values.applyPlan)}
            />
          </CollisionFragment>
        </Fragment>
      )}


      <Row>
        <Col textAlign="right">
          <InfoMsg
            status={actionData.error ? FORM_ERROR : FORM_SUCCESS}
            msg={langError(actionData.message)}
          />
        </Col>
        <Col textAlign="right">
          <ButtonGrp>
            <Button
              type="button"
              shape="outline"
              onClick={onClose}
            >
              Zpět
            </Button>

            <ButtonLoader
              type="button"
              color="error"
              onClick={action}
              isLoading={actionData.isLoading}
            >
              <span className="btn--icon">
                <Pencil />
              </span>
              Aplikovat
            </ButtonLoader>
          </ButtonGrp>
        </Col>
      </Row>

    </Modal>
  );
};


TransportationTypesCollisionUpdate.propTypes = {
  modalData: shape({
    id: number,
    isOpen: bool.isRequired,
    transports: shape({
      conflict: bool.isRequired,
      data: arrayOf(object).isRequired,
    }).isRequired,
    reservations: shape({
      conflict: bool.isRequired,
      data: number.isRequired,
    }).isRequired,
    plans: shape({
      conflict: bool.isRequired,
      data: arrayOf(object).isRequired,
    }).isRequired,
  }).isRequired,
  actionData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
    message: object.isRequired,
  }).isRequired,
  onClose: func.isRequired,
  action: func.isRequired,
  langError: func.isRequired,
  onChangeValue: func.isRequired,
};


export default withLang(TransportationTypesCollisionUpdate);
