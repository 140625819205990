import {
  EDITABLE_GEN_FORM__ADD_PART,
  EDITABLE_GEN_FORM__EDIT_PART,
  EDITABLE_GEN_FORM__CLOSE_PART,
  EDITABLE_GEN_FORM__SAVE_PART,
  EDITABLE_GEN_FORM__REMOVE_PART,
  EDITABLE_GEN_FORM__CHANGE_PART,
  EDITABLE_GEN_FORM__CHANGE_PART_TYPE,
  EDITABLE_GEN_FORM__MOVE_PART,
  EDITABLE_GEN_FORM__GET_GROUP_INPUTS,
  EDITABLE_GEN_FORM__CHANGE_ROOT_PART,
} from '../actions/editableGenForm';
import {
  GENERIC_FORMS__GET_GENERIC_FORMS_DETAIL,
  GENERIC_FORMS__CREATE_GEN_FORM,
  GENERIC_FORMS__UPDATE_GEN_FORM,
  GENERIC_FORMS__VALIDATE_WHOLE_FORM,
  GENERIC_FORMS__REFRESH,
} from '../actions/genericFormsDetail';
import { PENDING, FULFILLED, REJECTED, } from '../../../globals';
import {
  initialState,
  caseAddPart,
  caseRemovePart,
  caseChangePartType,
  caseChangeValue,
  caseMovePart,
  caseChangeGroupPending,
  caseChangeGroupFulfilled,
  caseChangeGroupRejected,
  caseGetGenFormFulfilled,
  caseEditPart,
  caseClosePart,
  caseSavePart,
  caseValidateWhole,
  caseChangeRootPart,
} from '../../../logic/editableGenForm/reducerMethods';


const reducer = (state = initialState, action) => {
  const { type, payload, } = action;
  switch (type) {
    // resert state
    case GENERIC_FORMS__REFRESH:
    case `${GENERIC_FORMS__GET_GENERIC_FORMS_DETAIL}__${PENDING}`:
    case `${GENERIC_FORMS__UPDATE_GEN_FORM}__${FULFILLED}`:
    case `${GENERIC_FORMS__CREATE_GEN_FORM}__${FULFILLED}`: {
      return { ...initialState, };
    }

    // Add Part
    case EDITABLE_GEN_FORM__ADD_PART: {
      return caseAddPart(state, payload);
    }

    // Edit Part
    case EDITABLE_GEN_FORM__EDIT_PART: {
      return caseEditPart(state, payload);
    }

    // Add Part
    case EDITABLE_GEN_FORM__CLOSE_PART: {
      return caseClosePart(state);
    }

    // Save Part
    case EDITABLE_GEN_FORM__SAVE_PART: {
      return caseSavePart(state);
    }

    // Remove Part
    case EDITABLE_GEN_FORM__REMOVE_PART: {
      return caseRemovePart(state, payload);
    }

    // Change Part
    case EDITABLE_GEN_FORM__CHANGE_PART: {
      return caseChangeValue(state, payload);
    }

    // Change Part Type
    case EDITABLE_GEN_FORM__CHANGE_PART_TYPE: {
      return caseChangePartType(state, payload);
    }

    // Change Root part
    case EDITABLE_GEN_FORM__CHANGE_ROOT_PART: {
      return caseChangeRootPart(state, payload);
    }

    // Move Part
    case EDITABLE_GEN_FORM__MOVE_PART: {
      return caseMovePart(state, payload);
    }

    // GET gen form
    case `${GENERIC_FORMS__GET_GENERIC_FORMS_DETAIL}__${FULFILLED}`: {
      return caseGetGenFormFulfilled(state, payload);
    }

    // Validate Whole
    case `${GENERIC_FORMS__VALIDATE_WHOLE_FORM}`: {
      return caseValidateWhole(state, payload);
    }


    /**
     * GET Groups
     */
    case `${EDITABLE_GEN_FORM__GET_GROUP_INPUTS}__${PENDING}`: {
      return caseChangeGroupPending(state, payload);
    }

    case `${EDITABLE_GEN_FORM__GET_GROUP_INPUTS}__${FULFILLED}`: {
      return caseChangeGroupFulfilled(state, payload);
    }

    case `${EDITABLE_GEN_FORM__GET_GROUP_INPUTS}__${REJECTED}`: {
      return caseChangeGroupRejected(state, payload);
    }

    default:
      return state;
  }
};

export default reducer;
