import React from 'react';

import StyledSvg from './styles/StyledSvg';


const Storekeeper = () => (
  <StyledSvg viewBox="0 -26 511.98999 511">
    <path d="m333.460938 269.785156-1.359376-1.917968-20.203124 60.132812c-.539063 1.609375-.890626 3.277344-1.035157 4.964844l-9.082031 104.015625c-1.085938 12.460937 8.714844 23.289062 21.367188 23.289062 10.992187 0 20.347656-8.410156 21.320312-19.5625l8.859375-101.46875 19.460937-57.875c-14.074218 6.0625-30.675781 1.300781-39.328124-11.578125zm0 0" />
    <path d="m481.476562 172.21875c5.808594-13.152344-.144531-28.523438-13.296874-34.332031l-52.304688-23.082031c-1.761719 9.863281-13.09375 73.109374-13.09375 73.109374l24.035156-35.429687-19.046875 51.421875-56.351562 33.164062c-8.15625 4.800782-10.878907 15.308594-6.074219 23.464844 4.773438 8.117188 15.265625 10.898438 23.460938 6.078125l61.609374-36.261719c3.402344-2 6.007813-5.117187 7.378907-8.816406l21.167969-57.144531-7.105469 62.351563c-1.324219 11.628906-11.746094 14.839843-13.832031 16.535156l-52.800782 31.078125-3.429687 70.035156c-.171875 3.46875.5 6.929687 1.960937 10.082031l43.300782 93.386719c4.984374 10.753906 17.734374 15.394531 28.449218 10.425781 10.734375-4.980468 15.402344-17.714844 10.421875-28.449218l-41.074219-88.589844 2.339844-46.980469zm0 0" />
    <path d="m511.988281 83.84375c0 21.28125-17.253906 38.535156-38.535156 38.535156-21.285156 0-38.539063-17.253906-38.539063-38.535156 0-21.285156 17.253907-38.539062 38.539063-38.539062 21.28125 0 38.535156 17.253906 38.535156 38.539062zm0 0" />
    <path d="m285.433594 247.414062 42.707031 7.667969c-1.214844-12.097656 4.507813-24.367187 15.671875-30.9375l43.292969-25.480469 14.953125-83.289062c1.355468-7.558594-3.671875-14.785156-11.230469-16.140625-114.355469-20.449219-108.210937-19.625-110.5625-19.625-6.601563 0-12.460937 4.71875-13.667969 11.449219l-24.152344 134.515625c-1.355468 7.558593 3.671876 14.785156 11.230469 16.140625l22.757813 4.085937c-15.070313 0-118.773438 0-135.476563 0-7.078125 0-12.816406 5.734375-12.816406 12.816407v126.917968c0 5.917969 4.019531 10.886719 9.476563 12.359375h-35.84375v-383.992187c0-7.402344-6.003907-13.40625-13.410157-13.40625s-13.40625 6.003906-13.40625 13.40625v334.988281h-29.101562v-11.34375c0-8.863281-7.1875-16.050781-16.050781-16.050781h-29.804688v81.605468h29.804688c8.863281 0 16.050781-7.183593 16.050781-16.050781v-11.339843h29.101562v35.59375c0 7.40625 6.003907 13.40625 13.40625 13.40625h185.867188c7.40625 0 13.410156-6 13.410156-13.40625 0-6.226563-4.25-11.445313-10-12.953126h1.570313c7.078124 0 12.816406-5.738281 12.816406-12.816406 0-15.8125 0-106.722656 0-126.917968 0-4.820313-2.664063-9.011719-6.59375-11.203126zm0 0" />
  </StyledSvg>
);

export default Storekeeper;
