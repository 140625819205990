import React, { Fragment, } from 'react';
import {
  shape, func, object, string,
} from 'prop-types';

import { withLang, } from '../../../../logic/languages/withLang';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Input from '../../../../atoms/Input/Input';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';


const LanguagesDetail = ({
  // data
  form,
  values,
  // methods
  handleOnChangeValue,
  langError,
}) => (
  <Fragment>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Název
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="text"
          value={values.name}
          status={form.name.status}
          onChange={(e) => handleOnChangeValue(form.name.name, e.target.value)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.name.status}
          msg={langError(form.name.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Kód
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="text"
          value={values.code}
          status={form.code.status}
          onChange={(e) => handleOnChangeValue(form.code.name, e.target.value)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.code.status}
          msg={langError(form.code.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

  </Fragment>
);


LanguagesDetail.propTypes = {
  form: shape({
    name: object.isRequired,
    code: object.isRequired,
  }).isRequired,
  values: shape({
    name: string,
    code: string,
  }).isRequired,
  handleOnChangeValue: func.isRequired,
  langError: func.isRequired,
};


export default withLang(LanguagesDetail);
