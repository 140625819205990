import {
  LANGUAGES__GET_LANGUAGES,
  LANGUAGES__FILTER_CHANGED,
} from '../actions/languages';
import {
  LANGUAGES__DELETE_LANGUAGES_DETAIL,
} from '../actions/languagesDetail';
import {
  PENDING, FULFILLED, REJECTED,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';


const initialState = {
  languagesData: {
    ...initRestData,
    isLoading: true,
  },
  table: {
    data: [],
    count: 0,
    filter: {
      offset: 0,
      limit: 10,
      sortBy: 'id',
      order: 'ASC',
      params: {
        id: '',
        name: '',
      },
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;
  switch (type) {
    /**
     * GET Languages
     */
    case `${LANGUAGES__GET_LANGUAGES}__${PENDING}`: {
      return {
        ...state,
        languagesData: {
          ...state.languagesData,
          ...(handlePending()),
        },
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    case `${LANGUAGES__GET_LANGUAGES}__${FULFILLED}`: {
      return {
        ...state,
        languagesData: {
          ...state.languagesData,
          ...(handleFulfilled(payload)),
        },
        table: {
          data: payload.languages,
          count: payload.count,
          filter: payload.filter,
        },
      };
    }

    case `${LANGUAGES__GET_LANGUAGES}__${REJECTED}`: {
      return {
        ...state,
        languagesData: {
          ...state.languagesData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * DELETE Languages Detail
     */
    case `${LANGUAGES__DELETE_LANGUAGES_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.filter((item) => (
            item.id !== payload.id
          )),
        },
      };
    }


    /**
     * Change Filter
     */
    case `${LANGUAGES__FILTER_CHANGED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
