import React, { Fragment, } from 'react';
import {
  func, object, shape,
} from 'prop-types';

import FormRow from '../FormRow';
import Input from '../../../atoms/Input/Input';
import CheckBox from '../../../atoms/CheckBox/CheckBox';
import InputSelectWrapper from '../../wrappers/InputSelectWrapper';
import { onlyNumbersInString, fromNumberWithNullToInput, fromInputToNumberWithNull, } from '../../../logic/forms/utils';
import Heading from '../../../atoms/Heading/Heading';


const TextPart = ({
  // data
  part,
  regexesData,
  // methods
  onGetRegexes,
  onChangeValue,
  onChangeValidationValue,
  onChangeTranslationValue,
}) => {
  const { values, form, } = part;
  return (
    <Fragment>
      <FormRow
        label="Identifikátor"
        status={form.name.status}
        statusMsg={form.name.statusMsg}
      >
        <Input
          type="text"
          value={values.name}
          onChange={(e) => onChangeValue(form.name.name, e.target.value)}
          status={form.name.status}
        />
      </FormRow>

      <FormRow
        label="Výchozí hodnota"
        status={form.defaultValue.status}
        statusMsg={form.defaultValue.statusMsg}
      >
        <Input
          type="text"
          value={values.defaultValue}
          onChange={(e) => onChangeValue(form.defaultValue.name, e.target.value)}
          status={form.defaultValue.status}
        />
      </FormRow>

      <FormRow
        label="Zákaz editace?"
        isLabel={false}
        status={form.disabled.status}
        statusMsg={form.disabled.statusMsg}
      >
        <CheckBox
          id={form.disabled.name}
          checked={values.disabled}
          onChange={() => onChangeValue(form.disabled.name, !values.disabled)}
          status={form.disabled.status}
        />
      </FormRow>

      <div>
        <Heading h6>Validace</Heading>
      </div>

      <FormRow
        label="Povinné?"
        isLabel={false}
        status={form.required.status}
        statusMsg={form.required.statusMsg}
      >
        <CheckBox
          id={form.required.name}
          checked={values.validation.required}
          onChange={
            () => onChangeValidationValue(form.required.name, !values.validation.required)
          }
          status={form.required.status}
        />
      </FormRow>

      <FormRow
        label="Minimální délka"
        status={form.min.status}
        statusMsg={form.min.statusMsg}
      >
        <Input
          type="text"
          value={fromNumberWithNullToInput(values.validation.min)}
          onChange={
            (e) => onChangeValidationValue(
              form.min.name, fromInputToNumberWithNull(onlyNumbersInString(e.target.value))
            )
          }
          status={form.min.status}
        />
      </FormRow>

      <FormRow
        label="Maximální délka"
        status={form.max.status}
        statusMsg={form.max.statusMsg}
      >
        <Input
          type="text"
          value={fromNumberWithNullToInput(values.validation.max)}
          onChange={
            (e) => onChangeValidationValue(
              form.max.name, fromInputToNumberWithNull(onlyNumbersInString(e.target.value))
            )
          }
          status={form.max.status}
        />
      </FormRow>

      <FormRow
        label="Regulární výraz"
        status={form.regex.status}
        statusMsg={form.regex.statusMsg}
      >
        <InputSelectWrapper
          value={values.validation.regex}
          status={form.regex.status}
          options={regexesData.data}
          onChange={
            (value) => onChangeValidationValue(form.regex.name, value)
          }
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
          isLoading={regexesData.isLoading}
          error={regexesData.error}
          fetchData={onGetRegexes}
        />
      </FormRow>

      <div>
        <Heading h6>Nastavení popisků</Heading>
      </div>

      <FormRow
        label="Popisek"
        status={form.label.status}
        statusMsg={form.label.statusMsg}
      >
        <Input
          type="text"
          value={values.translation.label}
          onChange={(e) => onChangeTranslationValue(form.label.name, e.target.value)}
          status={form.label.status}
        />
      </FormRow>

      <FormRow
        label="Placeholder"
        status={form.placeholder.status}
        statusMsg={form.placeholder.statusMsg}
      >
        <Input
          type="text"
          value={values.translation.placeholder}
          onChange={(e) => onChangeTranslationValue(form.placeholder.name, e.target.value)}
          status={form.placeholder.status}
        />
      </FormRow>

      <FormRow
        label="Nápověda"
        status={form.help.status}
        statusMsg={form.help.statusMsg}
      >
        <Input
          type="text"
          value={values.translation.help}
          onChange={(e) => onChangeTranslationValue(form.help.name, e.target.value)}
          status={form.help.status}
        />
      </FormRow>
    </Fragment>
  );
};


TextPart.propTypes = {
  regexesData: object.isRequired,
  part: shape({
    values: object.isRequired,
    form: object.isRequired,
  }).isRequired,
  onChangeValue: func.isRequired,
  onChangeValidationValue: func.isRequired,
  onChangeTranslationValue: func.isRequired,
  onGetRegexes: func.isRequired,
};


export default TextPart;
