import React, { Component, } from 'react';
import {
  shape, func, string, arrayOf, bool, number, object,
} from 'prop-types';

import { withDnD, } from '../../../../logic/dnd/withDnD';
import PlanContext from './PlanContext';
import DropZone from './DropZone';
import PlanValueItem from './PlanValueItem';

import StyledPlanValueList from './styles/StyledPlanValueList';


class PlanValueList extends Component {
  state = {
    dropTop: false,
    dropBottom: false,
  }

  handleOnMouseOver = (e) => {
    e.stopPropagation();
  }

  handleOnDragOver = (e) => {
    const { dnd, root, } = this.props;

    e.preventDefault();
    e.stopPropagation();

    if (!dnd.isActive) return;

    const target = e.currentTarget;
    const viewportOffset = target.getBoundingClientRect();

    const topPos = e.clientY - viewportOffset.top;

    // TOP position
    if (topPos < 60) {
      this.setState({
        dropTop: true,
        dropBottom: false,
      });

    // BOTTOM position
    } else {
      this.setState({
        dropTop: false,
        dropBottom: true,
      });
    }

    dnd.onDragOver({ root, id: null, });
  }

  handleOnDragOverItems = (e) => {
    const { dnd, root, } = this.props;

    e.preventDefault();
    e.stopPropagation();

    if (!dnd.isActive) return;

    dnd.onDragOver({ root, id: dnd.over.id, });
  }

  handleOnMouseUp = (e) => {
    e.stopPropagation();

    const {
      root, dnd, position, value,
    } = this.props;

    if (!dnd.isActive) return;

    const target = e.currentTarget;
    const viewportOffset = target.getBoundingClientRect();

    const topPos = e.clientY - viewportOffset.top;

    // TOP position
    if (topPos < 60) {
      dnd.onDrop({ root, pos: [ ...position, 0, ], });

    // BOTTOM position
    } else {
      dnd.onDrop({ root, pos: [ ...position, value.length, ], });
    }
  }

  render() {
    const { dropTop, dropBottom, } = this.state;
    const { root, dnd, value, } = this.props;

    const isDraggingOver = root === dnd.over.root;
    const isDraggingOverListOnly = isDraggingOver && dnd.over.id === null;

    const showDropTop = dropTop
      && isDraggingOverListOnly
      && !(dnd.item.root === root && dnd.item.pos[dnd.item.pos.length - 1] === 0);
    const showDropBottom = dropBottom
      && isDraggingOverListOnly
      && !(dnd.item.root === root && dnd.item.pos[dnd.item.pos.length - 1] === (value.length - 1));
    const showPlaceholder = value.length < 1;

    return (
      <StyledPlanValueList // eslint-disable-line
        onMouseUp={this.handleOnMouseUp}
        onMouseMove={this.handleOnDragMove}
        onMouseOver={this.handleOnDragOver}
        isDraggingOver={isDraggingOver}
        itemsCount={value.length}
      >

        { showDropTop && (
          <DropZone />
        )}

        {showPlaceholder && (
          <div className="trans-types--value-container-placeholder">
            <span>Vyberte</span>
          </div>
        )}

        <ul // eslint-disable-line
          className="trans-types--value-list-ul"
          onMouseOver={this.handleOnDragOverItems}
          style={{
            display: 'block',
            margin: 0,
            padding: 0,
          }}
        >
          {value.map((item, index) => (
            <PlanValueItem
              key={item.id}
              item={item}
              root={root}
              position={[ index, ]}
              draggingOverList={isDraggingOver}
            />
          ))}
        </ul>

        { showDropBottom && (
          <DropZone />
        )}
      </StyledPlanValueList>
    );
  }
}


PlanValueList.propTypes = {
  root: string.isRequired,
  position: arrayOf(number).isRequired,
  value: arrayOf(object).isRequired,
  dnd: shape({
    onDragOver: func.isRequired,
    onDrop: func.isRequired,
    isActive: bool.isRequired,
    over: shape({
      root: string,
      id: string,
    }).isRequired,
    item: shape({
      root: string,
      pos: arrayOf(number),
    }).isRequired,
  }).isRequired,
};


export default withDnD(PlanContext)(PlanValueList);
