import React, { Component, Fragment, } from 'react';
import {
  shape, number, bool, func, object, string, arrayOf,
} from 'prop-types';
import { Route, } from 'react-router-dom';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  changeFilterAction,
  getNumericalSeriesAction,
  deleteNumericalSeriesDetailAction,
} from '../actions/numericalSeries';
import TableView from '../components/NumericalSeriesTable/TableView';
import NumericalSeriesEditModal from './NumericalSeriesEditModal';
import NumericalSeriesCreateModal from './NumericalSeriesCreateModal';

class NumericalSeriesPage extends Component {
  componentDidMount() {
    const { table, } = this.props;

    this.handleGetNumericalSeries(table.filter);
  }

  handleGetNumericalSeries = (newFilter) => {
    const { getNumericalSeries, table, } = this.props;

    getNumericalSeries(newFilter === undefined ? table.filter : newFilter);
  }

  handleEdit = (id) => {
    const { match, history, } = this.props;

    history.push(`${match.path}/edit/${id}`);
  }

  handleCreate = () => {
    const { match, history, } = this.props;

    history.push(`${match.path}/create`);
  }


  handleDelete = (item) => {
    const { deleteNumericalSeriesDetail, } = this.props;

    if (!item.isLoading) {
      deleteNumericalSeriesDetail(item.id);
    }
  }


  render() {
    const {
      table,
      numericalSeriesData,
      changeFilter,
      match,
    } = this.props;

    return (
      <Fragment>
        <TableView
          table={table}
          tableData={numericalSeriesData}
          onGetTableData={this.handleGetNumericalSeries}
          onEdit={this.handleEdit}
          onDelete={this.handleDelete}
          onCreate={this.handleCreate}
          onChangeFilter={changeFilter}
        />
        <Route
          exact
          path={`${match.path}/create`}
          render={
            (props) => <NumericalSeriesCreateModal {...props} refreshParent={this.handleGetNumericalSeries} /> // eslint-disable-line
          }
        />
        <Route
          exact
          path={`${match.path}/edit/:id`}
          render={
            (props) => <NumericalSeriesEditModal {...props} refreshParent={this.handleGetNumericalSeries} /> // eslint-disable-line
          }
        />
      </Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  const { numericalSeries, } = state;
  return {
    numericalSeriesData: numericalSeries.numericalSeriesData,
    table: numericalSeries.table,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getNumericalSeries: bindActionCreators(getNumericalSeriesAction, dispatch),
  changeFilter: bindActionCreators(changeFilterAction, dispatch),
  deleteNumericalSeriesDetail: bindActionCreators(deleteNumericalSeriesDetailAction, dispatch),
});


NumericalSeriesPage.propTypes = {
  table: shape({
    data: arrayOf(object).isRequired,
    count: number.isRequired,
    filter: object.isRequired,
  }).isRequired,
  numericalSeriesData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  match: shape({
    path: string.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  changeFilter: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(NumericalSeriesPage);
