import React from 'react';
import styled from 'styled-components';


const StyledNotFoundPage = styled.div`
  width: 100%;
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .notfound--404 {
    font-size: 5rem;
    color: ${(p) => p.theme.error.t700}
  }

  .notfound--text {
    font-size: 1.25rem;
  }
`;


const NotFoundPage = () => (
  <StyledNotFoundPage>
    <div className="notfound--404 noselect">
      404
    </div>
    <div className="notfound--text noselect">
      oops.. stránka neexistuje
    </div>
  </StyledNotFoundPage>
);


export default NotFoundPage;
