import React, { Component, Fragment, } from 'react';
import {
  shape, bool, func, object, string,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  validateFormAction,
  changeValueAction,
  getTicketDetailAction,
  updateTicketDetailAction,
} from '../actions/ticketsDetail';
import { getNumericalSeriesSharedAction, getTransportationTypesSharedAction, } from '../../shared/actions/shared';

import TicketsDetail from '../components/TicketsDetail/TicketsDetail';
import TicketsEdit from '../components/TicketsEdit/TicketsEdit';

import Modal from '../../../atoms/Modal/Modal';
import { validateInput, validateWholeFormValidator, } from '../../../logic/forms/validation';

class TicketsEditModal extends Component {
  componentDidMount() {
    this.handleGetDetail();
  }

  handleGetDetail = () => {
    const { match, getTicketDetail, } = this.props;

    getTicketDetail(match.params.id);
  }

  handleClose = () => {
    const { history, } = this.props;

    history.goBack();
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);

    changeValue(name, value, validationError);
  }

  handleEdit = async () => {
    const {
      updateTicketsData,
      detail,
      validateForm,
      updateTicketDetail,
    } = this.props;

    if (updateTicketsData.isLoading || !detail.isValid) return;

    const validation = validateWholeFormValidator(
      detail.form,
      detail.values,
    );

    if (Object.keys(validation).length < 1) {
      updateTicketDetail(detail, this.handleEditSuccess);
    } else {
      validateForm(validation);
    }
  }

  handleEditSuccess = () => {
    const { refreshParent, } = this.props;

    refreshParent();
    this.handleClose();
  }

  render() {
    const {
      detailTicketsData,
      updateTicketsData,
      detail,
      numericalSeriesSharedData,
      getNumericalSeriesShared,
      getTransportationTypesShared,
      transportationTypesData,
    } = this.props;

    return (
      <Fragment>
        <Modal
          isOpen
          onClose={this.handleClose}
          closeOnEsc
          size="LG"
          title="Detail lístku"
        >
          <TicketsEdit
            // data
            detailTicketsData={detailTicketsData}
            updateTicketsData={updateTicketsData}
            isFormValid={detail.isValid}
            // methods
            handleClose={this.handleClose}
            handleEdit={this.handleEdit}
            onGetDetail={this.handleGetDetail}
          >
            <TicketsDetail
              // data
              values={detail.values}
              form={detail.form}
              // methods
              handleClose={this.handleClose}
              handleOnChangeValue={this.handleOnChangeValue}
              getNumericalSeriesShared={getNumericalSeriesShared}
              numericalSeriesSharedData={numericalSeriesSharedData}
              getTransportationTypesShared={getTransportationTypesShared}
              transportationTypesData={transportationTypesData}
            />
          </TicketsEdit>
        </Modal>

      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { ticketsDetail, shared, } = state;
  return {
    detailTicketsData: ticketsDetail.detailTicketsData,
    updateTicketsData: ticketsDetail.updateTicketsData,
    detail: ticketsDetail.detail,
    numericalSeriesSharedData: shared.numericalSeriesData,
    transportationTypesData: shared.transportationTypesData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTicketDetail: bindActionCreators(getTicketDetailAction, dispatch), // eslint-disable-line
  updateTicketDetail: bindActionCreators(updateTicketDetailAction, dispatch), // eslint-disable-line
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
  getNumericalSeriesShared: bindActionCreators(getNumericalSeriesSharedAction, dispatch),
  getTransportationTypesShared: bindActionCreators(getTransportationTypesSharedAction, dispatch),
});


TicketsEditModal.propTypes = {
  detailTicketsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  updateTicketsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  detail: shape({
    isValid: bool.isRequired,
    form: object.isRequired,
    values: object.isRequired,
  }).isRequired,
  match: shape({
    params: shape({
      id: string.isRequired,
    }).isRequired,
  }).isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
  getTicketDetail: func.isRequired,
  refreshParent: func.isRequired,
  updateTicketDetail: func.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
  getNumericalSeriesShared: func.isRequired,
  numericalSeriesSharedData: object.isRequired,
  transportationTypesData: object.isRequired,
  getTransportationTypesShared: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(TicketsEditModal);
