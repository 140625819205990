import React, { Fragment, } from 'react';
import {
  object, func,
} from 'prop-types';

import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import StandsTable from './StandsTable';
import Heading from '../../../../atoms/Heading/Heading';
import Button from '../../../../atoms/Button/Button';


const Stands = ({
  // data
  table,
  standsData,
  // methods
  handleGetStands,
  handleEdit,
  handleDelete,
  handleCreate,
  changeFilter,
}) => (
  <Fragment>

    <Heading h4>
      Stanoviště
    </Heading>

    <Row formGroup>
      <Col>
        <Button
          type="button"
          onClick={handleCreate}
          color="success"
          shape="outline"
        >
          Vytvořit stanoviště
        </Button>
      </Col>
    </Row>

    <StandsTable
      data={table.data}
      table={table}
      filter={table.filter}
      count={table.count}
      standsData={standsData}
      handleGetStands={handleGetStands}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      changeFilter={changeFilter}
    />

  </Fragment>
);


Stands.propTypes = {
  table: object.isRequired,
  standsData: object.isRequired,
  changeFilter: func.isRequired,
  handleGetStands: func.isRequired,
  handleEdit: func.isRequired,
  handleDelete: func.isRequired,
  handleCreate: func.isRequired,
};


export default Stands;
