import React from 'react';
import { shape, string, } from 'prop-types';
import { Switch, Route, Redirect, } from 'react-router-dom';

import BlankTemp from '../../../templates/Spaces/BlankTemp';
import Heading from '../../../atoms/Heading/Heading';
import TabsLinkPanel from '../../../atoms/TabsLink/TabsLinkPanel';
import TabLink from '../../../atoms/TabsLink/TabLink';

import GenericFormsPage from './GenericFormsPage';
import TransportationEditFormsPage from './TransportationEditFormsPage';

const GenericFormsLayout = ({ match, }) => (
  <BlankTemp>
    <Heading h4>
      Formuláře
    </Heading>

    <TabsLinkPanel>
      <TabLink
        exact={false}
        to={`${match.path}/list`}
      >
        Generické formuláře
      </TabLink>
      <TabLink
        exact={false}
        to={`${match.path}/transportation-edit-forms`}
      >
        Formuláře s údaji z přepravy
      </TabLink>
    </TabsLinkPanel>

    <Switch>
      <Redirect exact from={`${match.path}`} to={`${match.path}/list`} />
      <Route path={`${match.path}/list`} component={GenericFormsPage} />
      <Route path={`${match.path}/transportation-edit-forms`} component={TransportationEditFormsPage} />
    </Switch>
  </BlankTemp>
);

GenericFormsLayout.propTypes = {
  match: shape({
    path: string.isRequired,
  }).isRequired,
};

export default GenericFormsLayout;
