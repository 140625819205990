import styled from 'styled-components';


const StyledApp = styled.div`
  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  }

  .app--color-success {
    color: ${(p) => p.theme.success.t700}
  }

  .app--color-error {
    color: ${(p) => p.theme.error.t700}
  }
`;


export default StyledApp;
