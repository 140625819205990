export const COLS = [
  {
    id: 'id',
    width: 15,
    label: '#',
  },
  {
    id: 'name',
    width: 25,
    label: 'Název',
  },
  {
    id: 'type',
    width: 25,
    label: 'Typ',
  },
  {
    id: 'actions',
    width: 35,
    label: 'Akce',
  },
];

export const subTypeOptions = {
  calling: {
    value: 'calling',
    label: 'Vyvolávací',
  },
  ramp: {
    value: 'ramp',
    label: 'Rampový',
  },
  all: {
    value: 'all',
    label: 'Vše',
  },
};
