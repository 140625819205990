import React, { Fragment, Component, } from 'react';
import {
  shape, func, arrayOf, bool, string, number, object,
} from 'prop-types';

import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';
import InputSelectWrapper from '../../../../components/wrappers/InputSelectWrapper';
import SegmentLaoding from '../../../../components/SegmentLaoding/SegmentLaoding';
import SegmentError from '../../../../components/SegmentError/SegmentError';
import SegmentInfo from '../../../../components/SegmentInfo/SegmentInfo';
import Heading from '../../../../atoms/Heading/Heading';
import Pencil from '../../../../styles/icons/Pencil';
import Info from '../../../../styles/icons/Info';
import { FORM_ERROR, } from '../../../../globals';
import { withLang, } from '../../../../logic/languages/withLang';
import Divider from '../../../../atoms/Divider/Divider';

class ActionsToForms extends Component {
  componentDidMount() {
  }

  render() {
    const {
      // data
      mapForms,
      formsData,
      // methods
      onRefresh,
      onGetForms,
      onChangeForm,
      onUpdate,
      langError,
      getTransportationEditFormData,
    } = this.props;

    const {
      mapped,
      selected,
      mappedFormsData: {
        isLoading,
        error,
        message,
      },
      updateData,
      transportationEditFormData,
    } = mapForms;

    if (selected === null) {
      return (
        <SegmentInfo text="Nejprve vyberte typ přepravy" icon={<Info />} />
      );
    }

    if (isLoading) {
      return <SegmentLaoding formGroup />;
    }

    if (error) {
      return (
        <SegmentError
          formGroup
          message={message}
          refresh={() => onRefresh(mapForms.selected)}
        />
      );
    }

    return (
      <Fragment>

        <Row formGroup>
          <Col textAlign="center">
            <span>Vyberte ke každé akci formulář</span>
          </Col>
          <Divider width="100" />
        </Row>

        <ul style={{ paddingLeft: 0, listStyle: 'none', }}>
          {mapped.map((item) => (
            <li key={item.action.id}>
              <Heading
                h6
                style={{ textAlign: 'center', }}
              >
                {item.action.name}
              </Heading>
              <Row formGroup>
                <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
                  Informace o přepravě
                </Col>
                <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
                  <InputSelectWrapper
                    value={item.transportationEditForm}
                    options={transportationEditFormData.data}
                    fetchData={getTransportationEditFormData}
                    onChange={(newValue) => onChangeForm({
                      actionId: item.action.id,
                      name: 'transportationEditForm',
                      value: newValue,
                    })}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    isLoading={transportationEditFormData.isLoading}
                    error={transportationEditFormData.error}
                  />
                </Col>
              </Row>
              <Row formGroup>
                <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
                  Generický formulář
                </Col>
                <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
                  <InputSelectWrapper
                    value={item.form}
                    options={formsData.data}
                    onChange={(newValue) => onChangeForm({
                      actionId: item.action.id,
                      name: 'form',
                      value: newValue,
                    })}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    isLoading={formsData.isLoading}
                    error={formsData.error}
                    fetchData={onGetForms}
                  />
                </Col>
              </Row>
            </li>
          ))}
        </ul>

        <Row>
          <Col textAlign="center">
            <InfoMsg
              status={FORM_ERROR}
              msg={langError(updateData.message)}
              showIcon
            />
          </Col>
          <Col textAlign="center">
            <ButtonLoader
              type="button"
              color="success"
              onClick={onUpdate}
              isLoading={updateData.isLoading}
            >
              <span className="btn--icon">
                <Pencil />
              </span>
              Upravit
            </ButtonLoader>
          </Col>
        </Row>

      </Fragment>
    );
  }
}


ActionsToForms.propTypes = {
  mapForms: shape({
    selected: shape({
      id: number.isRequired,
    }),
    mappedFormsData: shape({
      message: object.isRequired,
      error: bool.isRequired,
    }).isRequired,
    mapped: arrayOf(
      shape({
        action: shape({
          id: number.isRequired,
          name: string.isRequired,
        }),
      }),
    ).isRequired,
    updateData: shape({
      isLoading: bool.isRequired,
      error: bool.isRequired,
    }).isRequired,
  }).isRequired,
  formsData: shape({
    isLoading: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  onRefresh: func.isRequired,
  onGetForms: func.isRequired,
  onChangeForm: func.isRequired,
  onUpdate: func.isRequired,
  langError: func.isRequired,
  getTransportationEditFormData: func.isRequired,
};


export default withLang(ActionsToForms);
