import React, { Component, Fragment, } from 'react';
import {
  object, string, number, bool,
} from 'prop-types';

import AddPart from './parts/AddPart';
import NodeRow from './parts/NodeRow';
import RowError from './styles/RowError';

import {
  FORM_ROOT, GEN_FORM_RECURSIVE_TYPES, FORM_STRUCTURE_GROUP,
} from '../../globals';


class StructNode extends Component {
  renderChilds = (childNodeId, position) => {
    const {
      // data
      structure,
      parts,
      // methods
      actions,
    } = this.props;

    const actualStructure = structure[childNodeId];
    if (GEN_FORM_RECURSIVE_TYPES.includes(actualStructure.type)
      || actualStructure.type === FORM_STRUCTURE_GROUP) {
      return (
        <div
          style={{
            paddingLeft: 10,
          }}
        >
          <StructNode
            nodeId={childNodeId}
            structure={structure}
            parts={parts}
            nodePosition={position}
            disableStructActions={structure[childNodeId].type === FORM_STRUCTURE_GROUP}
            actions={actions}
          />
        </div>
      );
    }

    return null;
  }

  render() {
    const {
      // data
      structure,
      parts,
      nodeId,
      nodePosition,
      disableStructActions,
      // methods
      actions,
    } = this.props;

    const node = structure[nodeId];

    return (
      <ul
        style={{
          listStyle: 'none',
          paddingInlineStart: 0,
        }}
      >
        { !disableStructActions && (
          <li>
            <AddPart
              parent={nodeId}
              position={0}
              onAddPart={actions.onAddPart}
            />
          </li>
        )}


        {node.childs.map((actualId, index) => {
          const actualNode = parts[actualId];
          const { invalidInputs, } = actualNode;

          return (
            <Fragment
              key={actualNode.id}
            >

              <li>
                {(invalidInputs > 0) && (
                  <RowError>
                    {`Počet chyb v nastavení inputu: ${invalidInputs}`}
                  </RowError>
                )}
                <NodeRow
                  part={parts[actualId]}
                  node={actualNode}
                  index={index}
                  parentId={nodeId}
                  parentPosition={nodePosition}
                  onEditPart={actions.onEditPart}
                  onMovePart={actions.onMovePart}
                  onRemovePart={actions.onRemovePart}
                  disableStructActions={disableStructActions}
                >
                  {this.renderChilds(actualId, index)}
                </NodeRow>
              </li>

              { !disableStructActions && (
                <li>
                  <AddPart
                    parent={nodeId}
                    position={index + 1}
                    onAddPart={actions.onAddPart}
                  />
                </li>
              )}

            </Fragment>
          );
        })}
      </ul>
    );
  }
}


StructNode.propTypes = {
  nodeId: string,
  structure: object.isRequired,
  nodePosition: number,
  parts: object.isRequired,
  actions: object.isRequired,
  disableStructActions: bool,
};


StructNode.defaultProps = {
  nodeId: FORM_ROOT,
  nodePosition: 0,
  disableStructActions: false,
};


export default StructNode;
