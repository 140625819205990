import styled from 'styled-components';


const StyledPlanActionList = styled.div`
  position: relative;
  min-height: 200px;
  padding-top: 8px;
  padding-bottom: 1.5rem;
  background: ${(p) => p.theme.white};
  color: ${(p) => p.theme.black};
  border-radius: ${(p) => p.theme.input.borderRadius};
  border-color: ${(p) => p.theme.grey.t500};
  border-top-width: ${(p) => p.theme.input.borderWidthTopBottom};
  border-bottom-width: ${(p) => p.theme.input.borderWidthTopBottom};
  border-right-width: ${(p) => p.theme.input.borderWidthLeftRight};
  border-left-width: ${(p) => p.theme.input.borderWidthLeftRight};
  border-style: ${(p) => p.theme.input.borderStyle};

  ${(p) => p.isDraggingOver && `
    border-color: ${p.theme.primary.t500};
    box-shadow: 0 0 0 1px ${p.theme.primary.t500};
    border-style: ${p.theme.input.borderStyle};
  `}

  .trans-types--value-list-ul {
    padding-left: 0;
    list-style: none;
  }

  .trans-types--value-container-placeholder {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
    width: 100%;
    border-radius: ${(p) => p.theme.input.borderRadius};
    color: ${(p) => p.theme.grey.t400};
  }
`;


export default StyledPlanActionList;
