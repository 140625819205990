import { PENDING, FULFILLED, REJECTED, } from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


/*
 * GET Stands
 */
export const DEVICES__GET_KIOSKS_CONFIGURATIONS = 'DEVICES__GET_KIOSKS_CONFIGURATIONS';
export const getKiosksConfigurationsAction = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__GET_KIOSKS_CONFIGURATIONS}__${PENDING}`,
      payload: filter,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/devices/kiosk/configurations/filter`,
      data: filter,
    });
    dispatch({
      type: `${DEVICES__GET_KIOSKS_CONFIGURATIONS}__${FULFILLED}`,
      payload: response.data,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${DEVICES__GET_KIOSKS_CONFIGURATIONS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/**
 * Filter Changed
 */
export const DEVICES__GET_KIOSKS_CONFIGURATIONS_FILTER_CHANGED = 'DEVICES__GET_KIOSKS_CONFIGURATIONS_FILTER_CHANGED';

export const changeFilterAction = (filter) => ({
  type: `${DEVICES__GET_KIOSKS_CONFIGURATIONS_FILTER_CHANGED}`,
  payload: filter,
});
