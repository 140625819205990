import React from 'react';
import { string, bool, } from 'prop-types';

import StyledInfoMsg from './styled/StyledInfoMsg';
import { FORM_DEFAULT, } from '../../globals';
import Error from '../../styles/icons/Error';


const InfoMsg = ({ status, msg, showIcon, }) => {
  if (msg === '') return null;

  return (
    <StyledInfoMsg
      status={status}
    >
      { showIcon && (
        <span className="info-msg--icon">
          <Error />
        </span>
      )}
      {msg}
    </StyledInfoMsg>
  );
};


InfoMsg.propTypes = {
  status: string,
  msg: string,
  showIcon: bool,
};

InfoMsg.defaultProps = {
  status: FORM_DEFAULT,
  msg: '',
  showIcon: false,
};

export default InfoMsg;
