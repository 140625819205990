import React from 'react';
import { BrowserRouter as Router, Route, } from 'react-router-dom';
import { ThemeProvider, } from 'styled-components';
import { Provider, } from 'react-redux';

import configureStore from './logic/store/configureStore';
import ModalProvider from './atoms/Modal/ModalProvider';
import MainLayout from './modules/MainLayout/MainLayout';

import theme from './styles/theme';
import LangProvider from './logic/languages/LangProvider';

const store = configureStore();

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <LangProvider>
        <ModalProvider>
          <Router>
            <Route component={MainLayout} />
          </Router>
        </ModalProvider>
      </LangProvider>
    </ThemeProvider>
  </Provider>
);

export default App;
