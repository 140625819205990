import React, { Component, Fragment, } from 'react';
import {
  shape, number, bool, func, object, string, arrayOf,
} from 'prop-types';
import { Route, } from 'react-router-dom';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  deleteAction,
  toggleModalDeleteAction,
} from '../actions/genericFormsDetail';
import {
  getGenericFormsAction,
  changeFilterAction,
} from '../actions/genericForms';
import TableView from '../components/GenericFormsTable/TableView';
import GenericFormsEditModal from './GenericFormsEditModal';
import GenericFormsCreateModal from './GenericFormsCreateModal';
import GenericFormsDeleteModal from '../components/GenericFormsDeleteModal/GenericFormsDeleteModal';


class GenericFormsPage extends Component {
  componentDidMount() {
    const { table, } = this.props;
    this.handleGetGenericForms(table.filter);
  }

  handleGetGenericForms = (newFilter) => {
    const { getGenericForms, table, } = this.props;
    getGenericForms(newFilter === undefined ? table.filter : newFilter);
  }

  handleEdit = (id) => {
    const { match, history, } = this.props;
    history.push(`${match.path}/edit/${id}`);
  }

  handleCreate = () => {
    const { match, history, } = this.props;
    history.push(`${match.path}/create`);
  }

  handleDelete = () => {
    const {
      deleteData,
      modalDelete,
      onDelete,
    } = this.props;
    if (!deleteData.isLoading) {
      onDelete(modalDelete.id);
    }
  }

  render() {
    const {
      // data
      match,
      table,
      genericFormsData,
      modalDelete,
      deleteData,
      // methods
      changeFilter,
      toggleModalDelete,
    } = this.props;

    return (
      <Fragment>
        <TableView
          table={table}
          tableData={genericFormsData}
          onGetTableData={this.handleGetGenericForms}
          onRestore={this.handleRestore}
          onEdit={this.handleEdit}
          onDelete={toggleModalDelete}
          onCreate={this.handleCreate}
          onChangeFilter={changeFilter}
        />
        <Route
          exact
          path={`${match.path}/create`}
          render={
            (props) => (
              <GenericFormsCreateModal
                {...props}
                refreshParent={this.handleGetGenericForms}
              />
            )
          }
        />
        <Route
          exact
          path={`${match.path}/edit/:id`}
          render={
            (props) => (
              <GenericFormsEditModal
                {...props}
                refreshParent={this.handleGetGenericForms}
              />
            )
          }
        />

        <GenericFormsDeleteModal
          modalData={modalDelete}
          actionData={deleteData}
          action={this.handleDelete}
          onClose={toggleModalDelete}
        />

      </Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  const { genericForms, genericFormsDetail, } = state;
  return {
    genericFormsData: genericForms.genericFormsData,
    table: genericForms.table,
    deleteData: genericFormsDetail.deleteData,
    modalDelete: genericFormsDetail.modalDelete,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getGenericForms: bindActionCreators(getGenericFormsAction, dispatch),
  onDelete: bindActionCreators(deleteAction, dispatch),
  changeFilter: bindActionCreators(changeFilterAction, dispatch),
  toggleModalDelete: bindActionCreators(toggleModalDeleteAction, dispatch),
});


GenericFormsPage.propTypes = {
  table: shape({
    data: arrayOf(object).isRequired,
    count: number.isRequired,
    filter: object.isRequired,
  }).isRequired,
  genericFormsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  match: shape({
    path: string.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  modalDelete: object.isRequired,
  deleteData: object.isRequired,
  changeFilter: func.isRequired,
  getGenericForms: func.isRequired,
  toggleModalDelete: func.isRequired,
  onDelete: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(GenericFormsPage);
