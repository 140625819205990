import React, { Component, } from 'react';
import { TransitionGroup, CSSTransition, } from 'react-transition-group';
import ReactResizeDetector from 'react-resize-detector';
import {
  oneOfType, arrayOf, node, bool, func,
} from 'prop-types';

import StyledAppTemplate from './styles/StyledAppTemplate';
import StyledAppSidebar from './styles/StyledAppSidebar';
import StyledAppContent from './styles/StyledAppContent';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header/Header';


class AppTemplate extends Component {
  state = {
    sideBarHeight: 300,
    contentHeight: 300,
    currentHeight: 300,
  }

  handleSideBarHeight = (w, h) => {
    const { contentHeight, } = this.state;

    this.setState({
      sideBarHeight: h,
      currentHeight: (h > contentHeight) ? h : contentHeight,
    });
  }

  handleContentHeight = (w, h) => {
    const { sideBarHeight, } = this.state;

    this.setState({
      contentHeight: h,
      currentHeight: (h > sideBarHeight) ? h : sideBarHeight,
    });
  }

  render() {
    const {
      isOpenSidebar, children, onToggleSidebar,
    } = this.props;
    const { currentHeight, contentHeight, } = this.state;

    return (
      <StyledAppTemplate>

        <TransitionGroup className="app-temp--sidebar-wrapper">
          {isOpenSidebar && (
            <CSSTransition
              classNames="slide-sidebar"
              timeout={350}
            >
              <StyledAppSidebar currentHeight={currentHeight}>
                <div>
                  <ReactResizeDetector handleHeight onResize={this.handleSideBarHeight}>

                    <Sidebar
                      onToggleSidebar={onToggleSidebar}
                    />

                  </ReactResizeDetector>
                </div>
              </StyledAppSidebar>
            </CSSTransition>
          )}
        </TransitionGroup>

        <StyledAppContent currentHeight={isOpenSidebar ? currentHeight : contentHeight}>
          <div>
            <ReactResizeDetector handleHeight onResize={this.handleContentHeight}>

              <Header
                onToggleSidebar={onToggleSidebar}
                isOpenSidebar={isOpenSidebar}
              />
              {children}

            </ReactResizeDetector>
          </div>
        </StyledAppContent>

      </StyledAppTemplate>
    );
  }
}


AppTemplate.propTypes = {
  isOpenSidebar: bool.isRequired,
  onToggleSidebar: func.isRequired,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};

export default AppTemplate;
