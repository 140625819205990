import styled from 'styled-components';

import media from '../../../styles/media';


const StyledSidebar = styled.div`
  width: 100%;

  ul {
    padding-left: 0;
    list-style: none;
  }


  /*
   * Header
   */
  .sidebar--header {
    font-weight: 500;
    font-size: 1.15rem;
    position: relative;
    height: 4rem;
    padding: 0 1.25rem;
    display: flex;
    align-items: center;
  }

  .sidebar--header-logo {
    padding-right: 0.375rem;
    font-size: 1.5rem;
    max-width: 1.5rem;
    vertical-align: text-bottom;
  }

  .sidebar--header-icon-close {
    top: 1.5rem;
    right: 1.25rem;
    font-size: 0.75rem;
    line-height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    cursor: pointer;
  }

  .sidebar--header-icon-close:hover {
    color: ${(p) => p.theme.secondary.t700};
    background: ${(p) => p.theme.white};
  }


  /*
   * Subtitle
   */
  .sidebar--subtitle {
    padding: .375rem 1.25rem;
    font-size: .75rem;
    margin-top: 0.5rem;
  }


  /*
   * Divider
   */
  .sidebar--divider {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  
  /*
   * Item - Collapse, Link
   */

  /* Item */
  .sidebar--item {
    display: flex;
    align-items: center;
    color: inherit;
    transition: background-color .1s ease-in-out,color .1s ease-in-out;
    position: relative;
    text-decoration: none;
    cursor: pointer;
  }

  .sidebar--item-active, .sidebar--item:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  .sidebar--item-level-0 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .sidebar--item-level-1 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 2.5rem;
    padding-right: 1.25rem;
  }

  .sidebar--item-icon {
    padding-right: 0.75rem;
    fill: currentColor;
    font-size: 1.5rem;
  }

  /* Collapse */
  .sidebar--item-collapse-0 {
    background: rgba(255, 255, 255, 0.13);
  }

  .sidebar--item-collapse-icon {
    margin-left: auto;
    width: 10px;
    text-align: center;
    font-size: 0.65rem;
    transition: all .2s ease-out;
  }

  .sidebar--item-collapse-icon-open {
    transform: rotate(0deg);
  }

  .sidebar--item-collapse-icon-close {
    transform: rotate(-180deg);
  }


  /*
   * Media sizes
   */
  ${() => media.LG`
    .sidebar--header-icon-close {
      display: none;
    }
  `}
`;


export default StyledSidebar;
