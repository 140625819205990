import React from 'react';

import StyledSvg from './styles/StyledSvg';


const Save = () => (
  <StyledSvg viewBox="0 0 80 80">
    <path d="M65.861,0H10C4.5,0,0,4.5,0,10v60c0,5.5,4.5,10,10,10h60c5.5,0,10-4.5,10-10V15.638L65.861,0z
      M60,30c0,2.75-2.25,5-5,5H25c-2.75,0-5-2.25-5-5V5h40V30z M55,10H45v20h10V10z"
    />
  </StyledSvg>
);

export default Save;
