import React from 'react';
import {
  shape, bool, func, object, number, arrayOf,
} from 'prop-types';

import { withLang, } from '../../../../logic/languages/withLang';
import { FORM_ERROR, FORM_SUCCESS, } from '../../../../globals';
import Modal from '../../../../atoms/Modal/Modal';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import ButtonGrp from '../../../../atoms/ButtonGrp/ButtonGrp';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';
import CollisionReservation from '../../../../components/Collisions/CollisionReservation';
import CollisionTransports from '../../../../components/Collisions/CollisionTransports';
import Trash from '../../../../styles/icons/Trash';
import CollisionFragment from '../../../../components/Collisions/CollisionFragment';


const TransportationTypesDeleteCollisionModal = ({
  modalData,
  actionData,
  onClose,
  action,
  langError,
}) => {
  if (!modalData.isOpen) return null;

  return (
    <Modal
      isOpen={modalData.isOpen}
      onClose={onClose}
      closeOnEsc
      size="SM"
      color="error"
      title="Nastal konflikt"
    >

      <CollisionFragment>
        Nastaly následující kolize:
      </CollisionFragment>

      <CollisionFragment lvl1 formGroup>
        <CollisionReservation
          collision={modalData.reservations}
        />

        <CollisionTransports
          collision={modalData.transports}
        />
      </CollisionFragment>

      <Row>
        <Col textAlign="right">
          <InfoMsg
            status={actionData.error ? FORM_ERROR : FORM_SUCCESS}
            msg={langError(actionData.message)}
          />
        </Col>
        <Col textAlign="right">
          <ButtonGrp>
            <Button
              type="button"
              shape="outline"
              onClick={onClose}
            >
              Zpět
            </Button>

            <ButtonLoader
              type="button"
              color="error"
              onClick={action}
              isLoading={actionData.isLoading}
            >
              <span className="btn--icon">
                <Trash />
              </span>
              Přesto smazat
            </ButtonLoader>
          </ButtonGrp>
        </Col>
      </Row>

    </Modal>
  );
};


TransportationTypesDeleteCollisionModal.propTypes = {
  modalData: shape({
    id: number,
    isOpen: bool.isRequired,
    transports: shape({
      conflict: bool.isRequired,
      data: arrayOf(object).isRequired,
    }).isRequired,
    reservations: shape({
      conflict: bool.isRequired,
      data: number.isRequired,
    }).isRequired,
  }).isRequired,
  actionData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
    message: object.isRequired,
  }).isRequired,
  onClose: func.isRequired,
  action: func.isRequired,
  langError: func.isRequired,
};


export default withLang(TransportationTypesDeleteCollisionModal);
