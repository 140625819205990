import React from 'react';
import styled from 'styled-components';
import {
  string, node,
} from 'prop-types';

import Info from '../../styles/icons/Info';


const StyledSegmentInfo = styled.div`
  height: 7rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.grey.t800};
  background-color: ${(p) => p.theme.grey.t100};
  
  .segment-info {
    text-align: center;
  }

  .segment-info--icon {
    font-size: 1.5rem;
    padding-right: 0.5rem;
  }

  .segment-info--text {
    margin-top: 0.25rem;
  }

  /* FormGroup */
  ${(p) => p.formGroup && `
    margin-bottom: ${p.theme.formGroup.marginBottom}
  `}
`;


const SegmentInfo = ({
  text, icon,
}) => (
  <StyledSegmentInfo>
    <div className="segment-info">
      <span className="segment-info--icon">
        {icon}
      </span>
      <span className="segment-info--text">
        {text}
      </span>
    </div>
  </StyledSegmentInfo>
);


SegmentInfo.propTypes = {
  text: string,
  icon: node,
};

SegmentInfo.defaultProps = {
  text: '',
  icon: <Info />,
};


export default SegmentInfo;
