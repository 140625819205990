import { INPUT_TYPE, } from 'sofair-form-validation';

import {
  DEVICES__GET_CONTROLLERS_DETAIL,
  DEVICES__UPDATE_CONTROLLERS_DETAIL,
  DEVICES__CONTROLLERS_VALIDATE_FORM_DETAIL,
  DEVICES__CONTROLLERS_CHANGE_VALUE_DETAIL,
  DEVICES__CONTROLLERS_REFRESH,
} from '../actions/controllersDetail';
import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';
import {
  mergeFormAndValidation, initInput, changeFormValue,
} from '../../../logic/forms/validation';
import { parseStringFromApi, } from '../../../logic/utils/string';


const initialState = {
  detailControllersData: {
    ...initRestData,
    isLoading: true,
  },
  updateControllersData: {
    ...initRestData,
  },
  detail: {
    id: null,
    isValid: true,
    invalidInputs: 0,
    form: {
      name: {
        ...initInput,
        name: 'name',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: true,
          min: 2,
          max: 255,
        },
      },
      width: {
        ...initInput,
        name: 'width',
        type: INPUT_TYPE.NUMBER,
        validation: {
          ...initInput.validation,
          required: true,
          min: 1,
          max: 7680,
        },
      },
      height: {
        ...initInput,
        name: 'height',
        type: INPUT_TYPE.NUMBER,
        validation: {
          ...initInput.validation,
          required: true,
          min: 1,
          max: 4320,
        },
      },
    },
    values: {
      name: '',
      width: '1',
      height: '1',
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;

  switch (type) {
    /**
     * Refresh Data
     */
    case `${DEVICES__CONTROLLERS_REFRESH}`: {
      return {
        ...state,
        detail: {
          ...initialState.detail,
        },
      };
    }


    /**
     * Change Form
     */
    case `${DEVICES__CONTROLLERS_CHANGE_VALUE_DETAIL}`: {
      const newFormData = changeFormValue(
        state.detail.form, state.detail.values, state.detail.invalidInputs, payload
      );

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: newFormData.invalidInputs < 1,
          invalidInputs: newFormData.invalidInputs,
          form: newFormData.form,
          values: newFormData.values,
        },
      };
    }


    /**
     * Validate form
     */
    case `${DEVICES__CONTROLLERS_VALIDATE_FORM_DETAIL}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload);

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }


    /**
     * GET CONTROLLERS Detail
     */
    case `${DEVICES__GET_CONTROLLERS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        detailControllersData: {
          ...state.detailControllersData,
          ...(handlePending()),
        },
        detail: {
          ...initialState.detail,
        },
      };
    }

    case `${DEVICES__GET_CONTROLLERS_DETAIL}__${FULFILLED}`: {
      try {
        const newValues = {
          ...state.detail.values,
          name: parseStringFromApi(payload.data.name),
          width: payload.data.width ? payload.data.width.toString() : '',
          height: payload.data.height ? payload.data.height.toString() : '',
          deviceCode: parseStringFromApi(payload.data.deviceCode),
          online: payload.data.online,
          synchronized: payload.data.synchronized,
        };
        return {
          ...state,
          detailControllersData: {
            ...state.detailControllersData,
            ...(handleFulfilled(payload)),
          },
          detail: {
            ...state.detail,
            id: payload.data.id,
            values: newValues,
          },
        };
      } catch (err) {
        return {
          ...state,
          detailControllersData: {
            ...state.detailControllersData,
            ...(handleRejected({
              statusCode: -1,
              messageInternal: err.message,
              message: {
                code: 'SOMETHING_HAPPEND',
                params: {},
              },
            })),
          },
        };
      }
    }

    case `${DEVICES__GET_CONTROLLERS_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        detailControllersData: {
          ...state.detailControllersData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * UPDATE CONTROLLERS Detail
     */
    case `${DEVICES__UPDATE_CONTROLLERS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        updateControllersData: {
          ...state.updateControllersData,
          ...(handlePending()),
        },
      };
    }

    case `${DEVICES__UPDATE_CONTROLLERS_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        updateControllersData: {
          ...state.updateControllersData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${DEVICES__UPDATE_CONTROLLERS_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data.data);

      return {
        ...state,
        updateControllersData: {
          ...state.updateControllersData,
          ...(handleFulfilled(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${DEVICES__UPDATE_CONTROLLERS_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        updateControllersData: {
          ...state.updateControllersData,
          ...(handleRejected(payload)),
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
