import styled from 'styled-components';

const StyledDisplay = styled.div`
    position: absolute;
    background: ${(p) => (p.isActive ? p.theme.primary.t800 : p.theme.primary.t500)};
    box-shadow: inset 0px 0px 0px 1px ${(p) => p.theme.white};
    width: ${(p) => p.width}px;
    height: ${(p) => p.height}px;
    top: ${(p) => p.top}px;
    left: ${(p) => p.left}px;
    z-index: ${(p) => (p.isActive ? 10000 : 9999)};
    pointer-events: 'none';
    cursor: ${(p) => (p.isActive ? 'grabbing' : 'grab')};
`;

export default StyledDisplay;
