import React, { Component, } from 'react';
import FocusTrap from 'focus-trap-react';
import {
  oneOfType, arrayOf, node, string, func, bool,
} from 'prop-types';

import { withModalManager, } from './withModalManager';

import StyledModalOverlay from './styles/StyledModalOverlay';
import StyledModal from './styles/StyledModal';
import StyledModalPanel from './styles/StyledModalPanel';
import Cross from '../../styles/icons/Cross';
import StyledModalBody from './styles/StyledModalBody';


class ModalContent extends Component {
  constructor(props) {
    super(props);
    this.refModal = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown);
  }

  handleKeydown = (e) => {
    const { isTopModal, onClose, closeOnEsc, } = this.props;

    if (e.keyCode !== 27 || !isTopModal || !closeOnEsc) {
      return;
    }

    onClose();
  }

  handleClickAway = (e) => {
    const { disableClickOutside, onClose, } = this.props;

    e.stopPropagation();

    if (disableClickOutside) {
      return;
    }

    if (this.refModal.current && this.refModal.current.contains(e.target)) {
      return;
    }

    onClose();
  }

  render() {
    const {
      children, onClose, size, color, title, disablePadding, isTopModal,
    } = this.props;

    return (
      <FocusTrap
        tag="aside"
        active={isTopModal}
      >
        <StyledModalOverlay
          aria-modal="true"
          tabIndex="-1"
          role="dialog"
          onClick={this.handleClickAway}
        >
          <StyledModal
            ref={this.refModal}
            size={size}
          >

            <StyledModalPanel
              color={color}
            >
              <span className="modal-content--title">{title}</span>
              <button
                type="button"
                className="modal-content--exit"
                onClick={onClose}
              >
                <Cross />
              </button>
            </StyledModalPanel>

            <StyledModalBody
              disablePadding={disablePadding}
            >
              {children}
            </StyledModalBody>

          </StyledModal>
        </StyledModalOverlay>
      </FocusTrap>
    );
  }
}


ModalContent.propTypes = {
  disableClickOutside: bool.isRequired,
  isTopModal: bool.isRequired,
  disablePadding: bool.isRequired,
  closeOnEsc: bool.isRequired,
  size: string.isRequired,
  title: string.isRequired,
  color: string.isRequired,
  onClose: func.isRequired,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};


export default withModalManager(ModalContent);
