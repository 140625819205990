import { INPUT_TYPE, } from 'sofair-form-validation';

import {
  LANGUAGES__CHANGE_VALUE_SETTINGS,
  LANGUAGES__VALIDATE_FORM_SETTINGS,
  LANGUAGES__GET_LANGUAGES_SETTINGS,
  LANGUAGES__UPDATE_LANGUAGES_SETTINGS,
} from '../actions/languagesSettings';
import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION,
} from '../../../globals';
import {
  handlePending, handleFulfilled, handleRejected, initRestData,
} from '../../../logic/restApi/utils';
import {
  mergeFormAndValidation, initInput, changeFormValue,
} from '../../../logic/forms/validation';

const initialState = {
  getData: {
    ...initRestData,
  },
  updateData: {
    ...initRestData,
  },
  detail: {
    id: null,
    isValid: true,
    invalidInputs: 0,
    form: {
      defaultLang: {
        ...initInput,
        name: 'defaultLang',
        type: INPUT_TYPE.SELECT,
        validation: {
          ...initInput.validation,
          required: true,
        },
      },
      kioskDefaultLang: {
        ...initInput,
        name: 'kioskDefaultLang',
        type: INPUT_TYPE.SELECT,
        validation: {
          ...initInput.validation,
          required: true,
        },
      },
      kioskLangs: {
        ...initInput,
        name: 'kioskLangs',
        type: INPUT_TYPE.MULTISELECT,
        validation: {
          ...initInput.validation,
          required: true,
        },
      },
      centralAppDefaultLang: {
        ...initInput,
        name: 'centralAppDefaultLang',
        type: INPUT_TYPE.SELECT,
        validation: {
          ...initInput.validation,
          required: true,
        },
      },
      centralAppLangs: {
        ...initInput,
        name: 'centralAppLangs',
        type: INPUT_TYPE.MULTISELECT,
        validation: {
          ...initInput.validation,
          required: true,
        },
      },
      storekeeperAppDefaultLang: {
        ...initInput,
        name: 'storekeeperAppDefaultLang',
        type: INPUT_TYPE.SELECT,
        validation: {
          ...initInput.validation,
          required: true,
        },
      },
      storekeeperAppLangs: {
        ...initInput,
        name: 'storekeeperAppLangs',
        type: INPUT_TYPE.MULTISELECT,
        validation: {
          ...initInput.validation,
          required: true,
        },
      },
    },
    values: {
      defaultLang: null,
      kioskDefaultLang: null,
      kioskLangs: [],
      centralAppDefaultLang: null,
      centralAppLangs: [],
      storekeeperAppDefaultLang: null,
      storekeeperAppLangs: [],
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;
  switch (type) {
    /**
     * Change Form
     */
    case `${LANGUAGES__CHANGE_VALUE_SETTINGS}`: {
      const newFormData = changeFormValue(
        state.detail.form, state.detail.values, state.detail.invalidInputs, payload
      );

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: newFormData.invalidInputs < 1,
          invalidInputs: newFormData.invalidInputs,
          form: newFormData.form,
          values: newFormData.values,
        },
      };
    }


    /**
     * Validate form
     */
    case `${LANGUAGES__VALIDATE_FORM_SETTINGS}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload);

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }


    /**
     * GET Languages Settings
     */
    case `${LANGUAGES__GET_LANGUAGES_SETTINGS}__${PENDING}`: {
      return {
        ...state,
        getData: {
          ...(handlePending()),
        },
        detail: {
          ...initialState.detail,
        },
      };
    }

    case `${LANGUAGES__GET_LANGUAGES_SETTINGS}__${FULFILLED}`: {
      try {
        const newValues = {
          ...state.detail.values,
          ...payload.data,
        };

        return {
          ...state,
          getData: {
            ...(handleFulfilled(payload)),
          },
          detail: {
            ...state.detail,
            id: payload.data.id,
            values: newValues,
            oldValues: newValues,
          },
        };
      } catch (err) {
        return {
          ...state,
          getData: {
            ...(handleRejected({
              statusCode: -1,
              messageInternal: err.message,
              message: {
                code: 'SOMETHING_HAPPEND',
                params: {},
              },
            })),
          },
          detail: {
            ...state.detail,
          },
        };
      }
    }

    case `${LANGUAGES__GET_LANGUAGES_SETTINGS}__${REJECTED}`: {
      return {
        ...state,
        getData: {
          ...(handleRejected(payload)),
        },
        detail: {
          ...state.detail,
        },
      };
    }


    /**
     * UPDATE Languages settings
     */
    case `${LANGUAGES__UPDATE_LANGUAGES_SETTINGS}__${PENDING}`: {
      return {
        ...state,
        updateData: {
          ...(handlePending()),
        },
        detail: {
          ...state.detail,
        },
      };
    }

    case `${LANGUAGES__UPDATE_LANGUAGES_SETTINGS}__${FULFILLED}`: {
      return {
        ...state,
        updateData: {
          ...(handleFulfilled(payload)),
        },
        detail: {
          ...state.detail,
        },
      };
    }

    case `${LANGUAGES__UPDATE_LANGUAGES_SETTINGS}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data);

      return {
        ...state,
        updateData: {
          ...(handleRejected(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${LANGUAGES__UPDATE_LANGUAGES_SETTINGS}__${REJECTED}`: {
      return {
        ...state,
        updateData: {
          ...(handleRejected(payload)),
        },
        detail: {
          ...state.detail,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
