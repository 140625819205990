import React, { Component, } from 'react';
import {
  oneOfType, arrayOf, object, node, func,
} from 'prop-types';

import DnDDragging from './DnDDragging';


const initialState = {
  isActive: false,
  item: {
    id: null,
    pos: [],
    root: null,
    payload: null,
  },
  over: {
    root: null,
    id: null,
  },
  mouseDownPos: {
    x: 0,
    y: 0,
  },
};


class DnDProvider extends Component {
  state = initialState;

  componentDidMount() {
    window.addEventListener('mouseup', this.handleOnStopDrag);
    window.addEventListener('mouseover', this.handleOnDragout);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.handleOnStopDrag);
    window.removeEventListener('mouseover', this.handleOnDragout);
  }

  handleOnDragStart = ({
    e, id, root, pos, payload,
  }) => {
    const { isActive, } = this.state;

    if (isActive) return;

    document.querySelector('body').style.cursor = 'grabbing';

    this.setState({
      isActive: true,
      item: {
        id,
        root,
        pos,
        payload,
      },
      over: {
        ...initialState.over,
        root,
        id,
      },
      mouseDownPos: {
        x: e.pageX,
        y: e.pageY,
      },
    });
  }

  handleOnDragOver = ({ root, id, }) => {
    const { isActive, over, } = this.state;

    if (!isActive || (root === over.root && id === over.id)) return;

    this.setState({
      over: {
        root,
        id,
      },
    });
  }

  handleOnDrop = ({ root, pos, }) => {
    const { isActive, item, } = this.state;
    const { onDrop, } = this.props;

    if (!isActive) return;

    document.querySelector('body').style.cursor = 'default';

    this.setState({
      ...initialState,
    });

    onDrop(
      item,
      {
        pos,
        root,
      },
    );
  }

  handleOnStopDrag = (e) => {
    const { isActive, } = this.state;

    if (!isActive) return;

    e.preventDefault();
    e.stopPropagation();

    this.setState({
      ...initialState,
    });
  }

  handleOnDragout = (e) => {
    const { isActive, } = this.state;

    if (!isActive) return;

    e.preventDefault();
    e.stopPropagation();

    this.setState({
      over: initialState.over,
    });
  }

  render() {
    const {
      isActive, mouseDownPos, item, over,
    } = this.state;
    const { Context, children, renderDragging, } = this.props;

    return (
      <Context.Provider
        value={{
          isActive,
          item,
          over,
          onDragStart: this.handleOnDragStart,
          onDragOver: this.handleOnDragOver,
          onDrop: this.handleOnDrop,
        }}
      >

        { children }

        { isActive && (
          <DnDDragging
            mouseDownPos={mouseDownPos}
          >
            {renderDragging(item.payload)}
          </DnDDragging>
        )}

      </Context.Provider>
    );
  }
}


DnDProvider.propTypes = {
  Context: object.isRequired,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
  onDrop: func.isRequired,
  renderDragging: func.isRequired,
};


export default DnDProvider;
