/**
 * Change Login Form
 */
export const AUTH__LOGIN__CHANGE_FORM = 'AUTH_LOGIN';
export const changeLoginFormAction = ({ name, value, errorCode, }) => ({
  type: AUTH__LOGIN__CHANGE_FORM,
  payload: {
    name,
    value,
    errorCode,
  },
});
