import React from 'react';

import StyledSvg from './styles/StyledSvg';


const Logo = () => (
  <StyledSvg viewBox="-21 -69 682.66564 682">
    <path d="m499.773438 123.480469c-6.367188-3.960938-14.179688-3.632813-20.078126.183593l-165.476562 102.007813c-40.632812 3.136719-78.058594 35.460937-78.058594 83.183594 0 46.285156 37.558594 83.429687 83.6875 83.429687 47.890625 0 80.257813-37.214844 83.433594-77.667968l102.53125-165.292969c5.464844-8.808594 2.761719-20.375-6.039062-25.84375zm0 0" fill="#FFFFFF" />
    <path d="m301.25-2.832031c-70.25 4.054687-136.945312 30.851562-190.429688 76.644531l134.726563 134.5c16.664063-11.347656 35.726563-18.722656 55.703125-21.574219zm0 0" fill="#FFFFFF" />
    <path d="m83.773438 99.828125c-47.597657 51.425781-77.011719 116.804687-83.578126 186.796875-1.171874 12.480469 2.996094 24.964844 11.445313 34.253906 8.484375 9.324219 20.585937 14.675782 33.207031 14.675782h100.308594c22.039062 0 41.101562-16.429688 44.34375-38.21875 3.488281-23.445313 13.355469-45.433594 28.332031-63.675782zm0 0" fill="#FFFFFF" />
    <path d="m515.167969 466.625h-390.335938c-10.355469 0-18.75-8.402344-18.75-18.769531 0-10.367188 8.394531-18.769531 18.75-18.769531h390.335938c10.351562 0 18.75 8.402343 18.75 18.769531 0 10.367187-8.398438 18.769531-18.75 18.769531zm0 0" fill="#FFFFFF" />
    <path d="m417.507812 540.917969h-195.320312c-10.351562 0-18.75-8.40625-18.75-18.769531 0-10.367188 8.398438-18.769532 18.75-18.769532h195.320312c10.355469 0 18.75 8.402344 18.75 18.769532 0 10.363281-8.394531 18.769531-18.75 18.769531zm0 0" fill="#FFFFFF" />
    <path d="m338.75-2.832031v178.320312l178.875-111.121093c-51.390625-40.023438-113.597656-63.429688-178.875-67.199219zm0 0" fill="#FFFFFF" />
    <path d="m639.804688 286.628906c-6.285157-67.027344-33.527344-129.828125-77.632813-180.195312l-111.660156 190.953125c3.257812 21.761719 22.304687 38.167969 44.332031 38.167969h100.3125c12.621094 0 24.71875-5.355469 33.210938-14.675782 8.4375-9.285156 12.613281-21.773437 11.4375-34.25zm0 0" fill="#FFFFFF" />
  </StyledSvg>
);

export default Logo;
