import React, { Component, Fragment, } from 'react';
import {
  shape, number, bool, func, object, string, arrayOf,
} from 'prop-types';
import { Route, } from 'react-router-dom';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  deleteKioskDetailAction,
} from '../actions/kiosksDetail';
import { getKiosksAction, changeKiosksFilterAction, } from '../actions/kiosks';
import Kiosks from '../components/Kiosks/Kiosks';
import KiosksEditModal from './KiosksEditModal';
import KiosksRegisterModal from './KiosksRegisterModal';


class KiosksPage extends Component {
  componentDidMount() {
    const { table, } = this.props;

    this.handleGetKiosks(table.filter);
  }

  handleGetKiosks = (newFilter) => {
    const { getKiosks, table, } = this.props;

    getKiosks(newFilter === undefined ? table.filter : newFilter);
  }

  handleEdit = (id) => {
    const { match, history, } = this.props;

    history.push(`${match.path}/edit/${id}`);
  }

  handleRegister = (id) => {
    const { match, history, } = this.props;

    history.push(`${match.path}/register/${id}`);
  }

  handleDelete = (item) => {
    const { deleteKioskDetail, } = this.props;

    if (!item.isLoading) {
      deleteKioskDetail(item.id);
    }
  }

  render() {
    const {
      match,
      table,
      kiosksData,
      changeFilter,
    } = this.props;

    return (
      <Fragment>
        <Kiosks
          table={table}
          kiosksData={kiosksData}
          onGetKiosks={this.handleGetKiosks}
          onEdit={this.handleEdit}
          onRegister={this.handleRegister}
          onDelete={this.handleDelete}
          changeFilter={changeFilter}
        />
        <Route
          exact
          path={`${match.path}/edit/:id`}
          component={
            (props) => <KiosksEditModal {...props} refreshParent={this.handleGetKiosks} />
          }
        />
        <Route
          exact
          path={`${match.path}/register/:id`}
          component={
            (props) => <KiosksRegisterModal {...props} refreshParent={this.handleGetKiosks} />
          }
        />
      </Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  const { kiosks, } = state;
  return {
    kiosksData: kiosks.kiosksData,
    table: kiosks.table,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getKiosks: bindActionCreators(getKiosksAction, dispatch),
  changeFilter: bindActionCreators(changeKiosksFilterAction, dispatch),
  deleteKioskDetail: bindActionCreators(deleteKioskDetailAction, dispatch),
});


KiosksPage.propTypes = {
  table: shape({
    data: arrayOf(object).isRequired,
    count: number.isRequired,
    filter: object.isRequired,
  }).isRequired,
  kiosksData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  changeFilter: func.isRequired,
  getKiosks: func.isRequired,
  match: shape({
    path: string.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  deleteKioskDetail: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(KiosksPage);
