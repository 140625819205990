import {
  validateWholeFormValidator,
  mergeFormAndValidation,
} from '../../../logic/forms/validation';


export const createOptionId = (option) => `${option.configuration.table}__${option.configuration.parameter}`;

const filterOptions = ({
  isOpen,
  input,
  allInputs,
  allOptions,
}) => {
  const ret = [];
  const inputId = input
    ? createOptionId(input)
    : null;

  if (!isOpen) return ret;

  const usedOptions = {};
  for (let i = 0; i < allInputs.length; i++) {
    const option = allInputs[i];
    const id = createOptionId(option);

    if (id !== inputId) {
      usedOptions[id] = id;
    }
  }

  for (let j = 0; j < allOptions.length; j++) {
    const option = allOptions[j];
    const id = createOptionId(option);

    if (!Object.prototype.hasOwnProperty.call(usedOptions, id)) {
      ret.push({ ...option, });
    }
  }

  return ret;
};


/**
 * TOGGLE
 */
export const TRANSPORTATION_EDIT_FORMS__TOGGLE_MODAL_INPUT = 'TRANSPORTATION_EDIT_FORMS__TOGGLE_MODAL_INPUT';
export const toggleModalInputAction = (
  { isOpen = false, isEdit = false, position = null, }
) => async (dispatch, getState) => {
  const { transportationEditFormsDetail, } = getState();
  const allInputs = transportationEditFormsDetail.detail.values.transportationEditFormInputs;
  const allOptions = transportationEditFormsDetail.optionsData.options;

  const input = isEdit
    ? allInputs[position]
    : null;
  const typeOptions = filterOptions({
    isOpen,
    input,
    allInputs,
    allOptions,
  });

  dispatch({
    type: `${TRANSPORTATION_EDIT_FORMS__TOGGLE_MODAL_INPUT}`,
    payload: {
      isOpen,
      isEdit,
      position,
      input,
      typeOptions,
    },
  });
};


/**
 * Change Form Value
 */
export const TRANSPORTATION_EDIT_FORMS__MODAL_INPUT_CHANGE_FORM = 'TRANSPORTATION_EDIT_FORMS__MODAL_INPUT_CHANGE_FORM';
export const changeFormValueAction = (name, value, validationError) => ({
  type: `${TRANSPORTATION_EDIT_FORMS__MODAL_INPUT_CHANGE_FORM}`,
  payload: {
    name,
    value,
    validationError,
  },
});


/**
 * Confirm
 */
export const TRANSPORTATION_EDIT_FORMS__MODAL_INPUT_VALIDATION = 'TRANSPORTATION_EDIT_FORMS__MODAL_INPUT_VALIDATION';
export const TRANSPORTATION_EDIT_FORMS__MODAL_INPUT_CONFIRM = 'TRANSPORTATION_EDIT_FORMS__MODAL_INPUT_CONFIRM';
export const confirmAction = () => async (dispatch, getState) => {
  const {
    transportationEditFormsModalInput: {
      modalData: {
        position,
        isEdit,
      },
      inputForm: {
        isValid,
        form,
        values,
      },
    },
  } = getState();

  if (isValid) {
    const validation = validateWholeFormValidator(form, values);

    // Confirm
    if (Object.keys(validation).length < 1) {
      const newInput = { ...values.type, };
      newInput.configuration.validation.required = !!values.required;

      dispatch({
        type: TRANSPORTATION_EDIT_FORMS__MODAL_INPUT_CONFIRM,
        payload: {
          position,
          isEdit,
          newInput,
        },
      });

    // Invalid form
    } else {
      const validatedForm = mergeFormAndValidation(form, validation);
      dispatch({
        type: TRANSPORTATION_EDIT_FORMS__MODAL_INPUT_VALIDATION,
        payload: validatedForm,
      });
    }
  }
};
