import {
  DEVICES__GET_CONTROLLERS,
  DEVICES__CONTROLLERS_FILTER_CHANGED,
} from '../actions/controllers';
import {
  DEVICES__DELETE_CONTROLLERS_DETAIL,
} from '../actions/controllersDetail';
import { PENDING, FULFILLED, REJECTED, } from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';


const parseTableData = (data) => (
  data.map((item) => ({
    ...item,
    ...initRestData,
  }))
);


const initialState = {
  controllersData: {
    ...initRestData,
    isLoading: true,
  },
  table: {
    data: [],
    count: 0,
    filter: {
      offset: 0,
      limit: 10,
      sortBy: 'id',
      order: 'ASC',
      params: {
        id: '',
        name: '',
        deviceCode: '',
        synchronized: 'all',
        online: 'all',
      },
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;
  switch (type) {
    /**
     * GET Controllers
     */
    case `${DEVICES__GET_CONTROLLERS}__${PENDING}`: {
      return {
        ...state,
        controllersData: {
          ...state.controllersData,
          ...(handlePending()),
        },
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    case `${DEVICES__GET_CONTROLLERS}__${FULFILLED}`: {
      return {
        ...state,
        controllersData: {
          ...state.controllersData,
          ...(handleFulfilled(payload)),
        },
        table: {
          data: parseTableData(payload.controllers),
          count: payload.count,
          filter: payload.filter,
        },
      };
    }

    case `${DEVICES__GET_CONTROLLERS}__${REJECTED}`: {
      return {
        ...state,
        controllersData: {
          ...state.controllersData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * DELETE Controller Detail
     */
    case `${DEVICES__DELETE_CONTROLLERS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.map((item) => {
            if (item.id === payload.id) {
              return {
                ...item,
                ...(handlePending()),
              };
            }
            return item;
          }),
        },
      };
    }

    case `${DEVICES__DELETE_CONTROLLERS_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.filter((item) => item.id !== payload.id),
        },
      };
    }

    case `${DEVICES__DELETE_CONTROLLERS_DETAIL}__${REJECTED}`: {
      const { id, } = payload;

      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                ...(handleRejected(payload)),
              };
            }
            return item;
          }),
        },
      };
    }

    /**
     * Change Filter
     */
    case `${DEVICES__CONTROLLERS_FILTER_CHANGED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
