import React from 'react';
import { Switch, Route, } from 'react-router-dom';

import Heading from '../../../atoms/Heading/Heading';
import TabsLinkPanel from '../../../atoms/TabsLink/TabsLinkPanel';
import TabLink from '../../../atoms/TabsLink/TabLink';
import LanguagesPage from './LanguagesPage';
import LanguagesSettingsPage from './LanguagesSettingsPage';
import BlankTemp from '../../../templates/Spaces/BlankTemp';


const LanguagesLayout = () => (
  <BlankTemp>
    <Heading h4>
      Jazyky
    </Heading>

    <TabsLinkPanel>
      <TabLink
        exact={false}
        to="/languages/list"
      >
        Seznam
      </TabLink>
      <TabLink
        exact={false}
        to="/languages/settings"
      >
        Nastavení
      </TabLink>
    </TabsLinkPanel>

    <Switch>
      <Route path="/languages/list" component={LanguagesPage} />
      <Route path="/languages/settings" component={LanguagesSettingsPage} />
    </Switch>
  </BlankTemp>
);


export default LanguagesLayout;
