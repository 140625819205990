import React, { Fragment, } from 'react';
import {
  shape, func, string, bool, object,
} from 'prop-types';

import { withLang, } from '../../../../logic/languages/withLang';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Input from '../../../../atoms/Input/Input';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';
import MultiSelectListWrapper from '../../../../components/wrappers/MultiSelectListWrapper';

const StorekeeperDetail = ({
  // data
  form,
  values,
  platformsSharedData,
  // methods
  handleOnChangeValue,
  langError,
  getPlatformsShared,
}) => (
  <Fragment>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Název
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="text"
          value={values.name}
          status={form.name.status}
          onChange={(e) => handleOnChangeValue(form.name.name, e.target.value)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.name.status}
          msg={langError(form.name.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Platformy
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <MultiSelectListWrapper
          onChange={(value) => handleOnChangeValue(form.platforms.name, value)}
          options={platformsSharedData.data}
          value={values.platforms}
          isLoading={platformsSharedData.isLoading}
          error={platformsSharedData.error}
          fetchData={getPlatformsShared}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.platforms.status}
          msg={langError(form.platforms.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Kód zařízení
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} formValue sidePadding>
        {values.deviceCode}
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Synchronizováno
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} formValue sidePadding>
        <span className={values.synchronized ? 'app--color-success' : 'app--color-error'}>
          {values.synchronized ? 'Ano' : 'ne'}
        </span>
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Dostupnost
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} formValue sidePadding>
        <span className={values.online ? 'app--color-success' : 'app--color-error'}>
          {values.online ? 'Online' : 'Offline'}
        </span>
      </Col>
    </Row>

  </Fragment>
);


StorekeeperDetail.propTypes = {
  form: shape({
    name: shape({
      name: string.isRequired,
    }),
  }).isRequired,
  values: shape({
    name: string,
    deviceCode: string,
    online: bool,
    synchronized: bool,
  }).isRequired,
  platformsSharedData: object.isRequired,
  getPlatformsShared: func.isRequired,
  handleOnChangeValue: func.isRequired,
  langError: func.isRequired,
};


export default withLang(StorekeeperDetail);
