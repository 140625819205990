import React from 'react';
import {
  oneOfType, arrayOf, node,
} from 'prop-types';
import Container from '../../atoms/Container/Container';

import StyledBlankTemp from './styles/StyledBlankTemp';


const BlankTemp = ({ children, }) => (
  <Container className="temp-container">
    <StyledBlankTemp>
      {children}
    </StyledBlankTemp>
  </Container>
);


BlankTemp.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};


export default BlankTemp;
