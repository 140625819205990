import React, { Component, Fragment, } from 'react';
import {
  shape, bool, func, object, string,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import { SHARED_DATA_TYPES, } from '../../../globals';
import {
  getGenericFormsDetailAction,
  updateAction,
} from '../actions/genericFormsDetail';
import {
  addPartAction,
  editPartAction,
  closePartAction,
  savePartAction,
  removePartAction,
  changePartAction,
  changePartTypeAction,
  movePartAction,
  getGroupInputsAction,
  changeRootPartAction,
} from '../actions/editableGenForm';
import { getSharedDataAction, } from '../../shared/actions/shared';
import Modal from '../../../atoms/Modal/Modal';
import GenericFormsEdit from '../components/GenericFormsEdit/GenericFormsEdit';
import GenericFormsDetail from '../components/GenericFormsDetail/GenericFormsDetail';


class GenericFormsEditModal extends Component {
  componentDidMount() {
    this.handleGetDetail();
  }

  handleGetDetail = () => {
    const { match, getGenericFormsDetail, } = this.props;
    getGenericFormsDetail(match.params.id);
  }

  handleClose = () => {
    const { history, } = this.props;
    history.goBack();
  }

  handleEdit = async () => {
    const { onUpdate, } = this.props;
    onUpdate(this.handleEditSuccess);
  }

  handleEditSuccess = () => {
    const { refreshParent, } = this.props;

    refreshParent();
    this.handleClose();
  }

  render() {
    const {
      // data
      getData,
      updateData,
      editableGenForm,
      regexesData,
      groupData,
      optionsData,
      btnActionsData,
      // methods
      getSharedData,
      onAddPart,
      onEditPart,
      onClosePart,
      onSavePart,
      onRemovePart,
      onChangePart,
      onChangePartType,
      onChangeRootPart,
      onMovePart,
      onGetGroupInputs,
    } = this.props;

    const {
      commonValidation,
      finalValidation,
    } = editableGenForm;

    return (
      <Fragment>
        <Modal
          isOpen
          onClose={this.handleClose}
          closeOnEsc
          size="MD"
          title="Detail formuláře"
        >
          <GenericFormsEdit
            // data
            getDetailData={getData}
            updateData={updateData}
            isFormValid={commonValidation.isValid && finalValidation.isValid}
            // methods
            onClose={this.handleClose}
            onEdit={this.handleEdit}
            onGetDetail={this.handleGetDetail}
          >
            <GenericFormsDetail
              editableGenForm={editableGenForm}
              commonData={{
                regexesData,
                groupData,
                optionsData,
                btnActionsData,
              }}
              genFormActions={{
                onAddPart,
                onEditPart,
                onClosePart,
                onSavePart,
                onChangePart,
                onChangePartType,
                onChangeRootPart,
                onRemovePart,
                onMovePart,
              }}
              commonActions={{
                onGetRegexes: () => getSharedData(SHARED_DATA_TYPES.REGEXES),
                onGetGroups: () => getSharedData(SHARED_DATA_TYPES.GROUPS),
                onGetGroupInputs,
                onGetOptions: () => getSharedData(SHARED_DATA_TYPES.OPTIONS),
                onGetBtnActions: () => getSharedData(SHARED_DATA_TYPES.BTN_ACTIONS),
              }}
            />
          </GenericFormsEdit>
        </Modal>

      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { genericFormsDetail, editableGenForm, shared, } = state;
  return {
    getData: genericFormsDetail.getData,
    updateData: genericFormsDetail.updateData,
    // generic forms
    editableGenForm,
    regexesData: shared.regexesData,
    groupData: shared.groupData,
    optionsData: shared.optionsData,
    btnActionsData: shared.btnActionsData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSharedData: bindActionCreators(getSharedDataAction, dispatch),
  getGenericFormsDetail: bindActionCreators(getGenericFormsDetailAction, dispatch),
  onUpdate: bindActionCreators(updateAction, dispatch),
  onAddPart: bindActionCreators(addPartAction, dispatch),
  onEditPart: bindActionCreators(editPartAction, dispatch),
  onClosePart: bindActionCreators(closePartAction, dispatch),
  onSavePart: bindActionCreators(savePartAction, dispatch),
  onRemovePart: bindActionCreators(removePartAction, dispatch),
  onChangePart: bindActionCreators(changePartAction, dispatch),
  onChangePartType: bindActionCreators(changePartTypeAction, dispatch),
  onMovePart: bindActionCreators(movePartAction, dispatch),
  onGetGroupInputs: bindActionCreators(getGroupInputsAction, dispatch),
  onChangeRootPart: bindActionCreators(changeRootPartAction, dispatch),
});


GenericFormsEditModal.propTypes = {
  getData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  updateData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  match: shape({
    params: shape({
      id: string.isRequired,
    }).isRequired,
  }).isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
  getGenericFormsDetail: func.isRequired,
  refreshParent: func.isRequired,
  getSharedData: func.isRequired,
  onUpdate: func.isRequired,

  editableGenForm: shape({
    commonValidation: shape({
      isValid: bool.isReqired,
    }).isRequired,
    finalValidation: shape({
      isValid: bool.isReqired,
    }).isRequired,
  }).isRequired,
  regexesData: object.isRequired,
  groupData: object.isRequired,
  optionsData: object.isRequired,
  btnActionsData: object.isRequired,

  onEditPart: func.isRequired,
  onClosePart: func.isRequired,
  onSavePart: func.isRequired,
  onChangeRootPart: func.isRequired,
  onAddPart: func.isRequired,
  onRemovePart: func.isRequired,
  onChangePart: func.isRequired,
  onChangePartType: func.isRequired,
  onMovePart: func.isRequired,
  onGetGroupInputs: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(GenericFormsEditModal);
