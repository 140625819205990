import { combineReducers, } from 'redux';

import user from '../../modules/Auth/reducers/user';
import auth from '../../modules/Auth/reducers/auth';
import langs from '../languages/langs';
import notifs from '../notifications/notifs';
import login from '../../modules/Auth/reducers/login';
import shared from '../../modules/shared/reducers/shared';
import transportationTypes from '../../modules/TransportationTypes/reducers/transportationTypes';
import transportationTypesDetail from '../../modules/TransportationTypes/reducers/transportationTypesDetail';
import mapForms from '../../modules/TransportationTypes/reducers/mapForms';
import numericalSeries from '../../modules/TransportationTypes/reducers/numericalSeries';
import numericalSeriesDetail from '../../modules/TransportationTypes/reducers/numericalSeriesDetail';
import tickets from '../../modules/TransportationTypes/reducers/tickets';
import ticketsDetail from '../../modules/TransportationTypes/reducers/ticketsDetail';
import platforms from '../../modules/Platforms/reducers/platforms';
import platformsDetail from '../../modules/Platforms/reducers/platformsDetail';
import parkings from '../../modules/Devices/reducers/parkings';
import parkingsDetail from '../../modules/Devices/reducers/parkingsDetail';
import kiosks from '../../modules/Devices/reducers/kiosks';
import kiosksDetail from '../../modules/Devices/reducers/kiosksDetail';
import displays from '../../modules/Devices/reducers/displays';
import displaysDetail from '../../modules/Devices/reducers/displaysDetail';
import displaysConfiguration from '../../modules/Devices/reducers/displaysConfiguration';
import displaysConfigurationDetail from '../../modules/Devices/reducers/displaysConfigurationDetail';
import storekeeper from '../../modules/Devices/reducers/storekeeper';
import storekeeperDetail from '../../modules/Devices/reducers/storekeeperDetail';
import storekeeperConfiguration from '../../modules/Devices/reducers/storekeeperConfiguration';
import languages from '../../modules/Languages/reducers/languages';
import languagesDetail from '../../modules/Languages/reducers/languagesDetail';
import languagesSettings from '../../modules/Languages/reducers/languagesSettings';
import stands from '../../modules/QueueConfig/reducers/stands';
import standsDetail from '../../modules/QueueConfig/reducers/standsDetail';
import actions from '../../modules/QueueConfig/reducers/actions';
import actionsDetail from '../../modules/QueueConfig/reducers/actionsDetail';
import kiosksConfigurations from '../../modules/Devices/reducers/kiosksConfigurations';
import kiosksConfigurationDetail from '../../modules/Devices/reducers/kiosksConfigurationDetail';
import genericForms from '../../modules/GenericForms/reducers/genericForms';
import genericFormsDetail from '../../modules/GenericForms/reducers/genericFormsDetail';
import editableGenForm from '../../modules/GenericForms/reducers/editableGenForm';
import transportationEditForms from '../../modules/GenericForms/reducers/transportationEditForms';
import transportationEditFormsDetail from '../../modules/GenericForms/reducers/transportationEditFormsDetail';
import transportationEditFormsModalInput from '../../modules/GenericForms/reducers/transportationEditFormsModalInput';
import controllers from '../../modules/Devices/reducers/controllers';
import controllersDetail from '../../modules/Devices/reducers/controllersDetail';
import displayEditor from '../../modules/Devices/reducers/displayEditor';
import standAppConfiguration from '../../modules/QueueConfig/reducers/standAppConfiguration';
import standAppConfigurationDetail from '../../modules/QueueConfig/reducers/standAppConfigurationDetail';
import cameras from '../../modules/Devices/reducers/cameras';
import camerasDetail from '../../modules/Devices/reducers/camerasDetail';
import queueConfiguration from '../../modules/QueueConfig/reducers/queueConfiguration';

export default combineReducers({
  // main
  user,
  langs,
  notifs,
  // auth
  auth,
  login,
  // shared
  shared,
  // Transportation Types
  transportationTypes,
  transportationTypesDetail,
  mapForms,
  numericalSeries,
  numericalSeriesDetail,
  tickets,
  ticketsDetail,
  // Platforms
  platforms,
  platformsDetail,
  // Devices
  parkings,
  parkingsDetail,
  kiosksConfigurations,
  kiosksConfigurationDetail,
  kiosks,
  kiosksDetail,
  displays,
  displaysDetail,
  displaysConfiguration,
  displaysConfigurationDetail,
  controllers,
  controllersDetail,
  displayEditor,
  storekeeper,
  storekeeperDetail,
  storekeeperConfiguration,
  cameras,
  camerasDetail,
  // Languages
  languages,
  languagesDetail,
  languagesSettings,
  // Queue Config
  stands,
  standsDetail,
  standAppConfiguration,
  standAppConfigurationDetail,
  actions,
  actionsDetail,
  queueConfiguration,
  // Generic Forms
  genericForms,
  genericFormsDetail,
  editableGenForm,
  // Transportation Edit Forms
  transportationEditForms,
  transportationEditFormsDetail,
  transportationEditFormsModalInput,
});
