import React, { Component, Fragment, } from 'react';
import {
  shape, bool, func, object, string,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  getActionsDetailAction,
  updateActionsDetailAction,
  validateFormAction,
  changeValueAction,
} from '../actions/actionsDetail';
import { validateInput, validateWholeFormValidator, } from '../../../logic/forms/validation';
import ActionsEdit from '../components/ActionsEdit/ActionsEdit';
import Modal from '../../../atoms/Modal/Modal';
import ActionsDetail from '../components/ActionsDetail/ActionsDetail';


class ActionsEditModal extends Component {
  componentDidMount() {
    this.handleGetDetail();
  }

  handleGetDetail = () => {
    const { match, getActionsDetail, } = this.props;

    getActionsDetail(match.params.id);
  }

  handleClose = () => {
    const { history, } = this.props;

    history.goBack();
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);

    changeValue(name, value, validationError);
  }

  handleEdit = () => {
    const {
      updateActionsData,
      detail,
      validateForm,
      updateActionsDetail,
    } = this.props;

    if (updateActionsData.isLoading || !detail.isValid) return;

    const validation = validateWholeFormValidator(detail.form, detail.values);
    if (Object.keys(validation).length < 1) {
      updateActionsDetail(detail, this.handleEditSuccess);
    } else {
      validateForm(validation);
    }
  }

  handleEditSuccess = () => {
    const { refreshParent, } = this.props;

    refreshParent();
    this.handleClose();
  }

  render() {
    const {
      detailActionsData,
      updateActionsData,
      detail,
    } = this.props;

    return (
      <Fragment>

        <Modal
          isOpen
          onClose={this.handleClose}
          closeOnEsc
          size="MD"
          title="Detail akce"
        >
          <ActionsEdit
            // data
            actionsDetailData={detailActionsData}
            updateActionsData={updateActionsData}
            isFormValid={detail.isValid}
            // methods
            handleClose={this.handleClose}
            handleEdit={this.handleEdit}
            onGetDetail={this.handleGetDetail}
          >
            <ActionsDetail
              // data
              values={detail.values}
              form={detail.form}
              // methods
              handleClose={this.handleClose}
              handleOnChangeValue={this.handleOnChangeValue}
            />
          </ActionsEdit>
        </Modal>

      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { actionsDetail, } = state;
  return {
    detailActionsData: actionsDetail.detailActionsData,
    updateActionsData: actionsDetail.updateActionsData,
    detail: actionsDetail.detail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getActionsDetail: bindActionCreators(getActionsDetailAction, dispatch),
  updateActionsDetail: bindActionCreators(updateActionsDetailAction, dispatch),
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
});


ActionsEditModal.propTypes = {
  detailActionsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  updateActionsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  detail: shape({
    isValid: bool.isRequired,
    form: object.isRequired,
    values: object.isRequired,
  }).isRequired,
  match: shape({
    params: shape({
      id: string.isRequired,
    }).isRequired,
  }).isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
  getActionsDetail: func.isRequired,
  refreshParent: func.isRequired,
  updateActionsDetail: func.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(ActionsEditModal);
