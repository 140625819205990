import {
  PENDING, FULFILLED, REJECTED, FORM_SUCCESS, FORM_ERROR,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


/*
 * GET Numerical series
 */
export const NUMERICAL_SERIES__GET_NUMERICAL_SERIES = 'NUMERICAL_SERIES__GET_NUMERICAL_SERIES';
export const getNumericalSeriesAction = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: `${NUMERICAL_SERIES__GET_NUMERICAL_SERIES}__${PENDING}`,
      payload: filter,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/numerical_series/filter`,
      data: filter,
    });

    dispatch({
      type: `${NUMERICAL_SERIES__GET_NUMERICAL_SERIES}__${FULFILLED}`,
      payload: response.data,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${NUMERICAL_SERIES__GET_NUMERICAL_SERIES}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/**
 * Filter Changed
 */
export const NUMERICAL_SERIES__FILTER_CHANGED = 'NUMERICAL_SERIES__FILTER_CHANGED';

export const changeFilterAction = (filter) => ({
  type: `${NUMERICAL_SERIES__FILTER_CHANGED}`,
  payload: filter,
});


/*
 * DELETE
 */
export const NUMERICAL_SERIES__DELETE_DETAIL = 'NUMERICAL_SERIES__DELETE_DETAIL';
export const deleteNumericalSeriesDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${NUMERICAL_SERIES__DELETE_DETAIL}__${PENDING}`,
      payload: {
        id,
      },
    });

    const response = await api({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/numerical_series?id=${id}`,
    });

    dispatch({
      type: `${NUMERICAL_SERIES__DELETE_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        id,
        notif: {
          type: FORM_SUCCESS,
          title: 'Číselná řada smazána',
        },
      },
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${NUMERICAL_SERIES__DELETE_DETAIL}__${REJECTED}`,
      payload: {
        ...parsedError,
        id,
        notif: {
          type: FORM_ERROR,
          title: 'Nepovedlo se smazat číselnou řadu',
          showMessage: true,
        },
      },
    });
  }
};
