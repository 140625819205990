import React, { Component, } from 'react';
import {
  shape, bool, func, object, string,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  getKiosksDetailAction,
  updateKiosksDetailAction,
  validateFormAction,
  changeValueAction,
} from '../actions/kiosksDetail';
import {
  validateInput, validateWholeFormValidator,
} from '../../../logic/forms/validation';
import KiosksRegister from '../components/KiosksRegister/KiosksRegister';
import Modal from '../../../atoms/Modal/Modal';
import KiosksDetail from '../components/KiosksDetail/KiosksDetail';

import { getKioskConfigurationsSharedAction, getStandsSharedAction, getCamerasSharedAction, } from '../../shared/actions/shared';

class KiosksRegisterModal extends Component {
  componentDidMount() {
    this.handleGetDetail();
  }

  handleGetDetail = () => {
    const { match, getKiosksDetail, } = this.props;

    getKiosksDetail(match.params.id);
  }

  handleClose = () => {
    const { history, } = this.props;

    history.goBack();
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);

    changeValue(name, value, validationError);
  }

  handleEdit = () => {
    const {
      updateKiosksData,
      detail,
      validateForm,
      updateKiosksDetail,
    } = this.props;

    if (updateKiosksData.isLoading || !detail.isValid) return;

    const validation = validateWholeFormValidator(detail.form, detail.values);
    if (Object.keys(validation).length < 1) {
      updateKiosksDetail(detail, this.handleEditSuccess);
    } else {
      validateForm(validation);
    }
  }

  handleEditSuccess = () => {
    const { refreshParent, } = this.props;

    refreshParent();
    this.handleClose();
  }

  render() {
    const {
      // data
      detailKiosksData,
      updateKiosksData,
      detail,
      kioskConfigurationsSharedData,
      standsSharedData,
      camerasSharedData,
      // methods
      getKioskConfigurationsShared,
      getStandsShared,
      getCamerasShared,
    } = this.props;

    return (
      <Modal
        isOpen
        onClose={this.handleClose}
        closeOnEsc
        size="MD"
        title="Registrace kiosku"
      >
        <KiosksRegister
          // data
          kiosksDetailData={detailKiosksData}
          updateKiosksData={updateKiosksData}
          isFormValid={detail.isValid}
          // methods
          handleClose={this.handleClose}
          handleEdit={this.handleEdit}
          onGetDetail={this.handleGetDetail}
        >
          <KiosksDetail
            // data
            values={detail.values}
            form={detail.form}
            // methods
            handleClose={this.handleClose}
            handleOnChangeValue={this.handleOnChangeValue}
            kioskConfigurationsSharedData={kioskConfigurationsSharedData}
            getKioskConfigurationsShared={getKioskConfigurationsShared}
            standsSharedData={standsSharedData}
            getStandsShared={getStandsShared}
            camerasSharedData={camerasSharedData}
            getCamerasShared={getCamerasShared}
          />
        </KiosksRegister>

      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { kiosksDetail, shared, } = state;
  return {
    detailKiosksData: kiosksDetail.detailKiosksData,
    updateKiosksData: kiosksDetail.updateKiosksData,
    detail: kiosksDetail.detail,
    kioskConfigurationsSharedData: shared.kioskConfigurationsData,
    standsSharedData: shared.standsData,
    camerasSharedData: shared.camerasData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getKiosksDetail: bindActionCreators(getKiosksDetailAction, dispatch), // eslint-disable-line
  updateKiosksDetail: bindActionCreators(updateKiosksDetailAction, dispatch), // eslint-disable-line
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
  getKioskConfigurationsShared: bindActionCreators(getKioskConfigurationsSharedAction, dispatch),
  getStandsShared: bindActionCreators(getStandsSharedAction, dispatch),
  getCamerasShared: bindActionCreators(getCamerasSharedAction, dispatch),
});


KiosksRegisterModal.propTypes = {
  getKiosksDetail: func.isRequired,
  refreshParent: func.isRequired,
  updateKiosksDetail: func.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
  detailKiosksData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  updateKiosksData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  detail: shape({
    isValid: bool.isRequired,
    form: object.isRequired,
    values: object.isRequired,
  }).isRequired,
  match: shape({
    params: shape({
      id: string.isRequired,
    }).isRequired,
  }).isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
  kioskConfigurationsSharedData: object.isRequired,
  getKioskConfigurationsShared: func.isRequired,
  getStandsShared: func.isRequired,
  standsSharedData: object.isRequired,
  camerasSharedData: object.isRequired,
  getCamerasShared: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(KiosksRegisterModal);
