import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION, FORM_SUCCESS,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


const parseValues = (values) => ({
  name: values.name,
  subtype: values.subtype,
  displayConfigurationId: values.displayConfigurationId,
});

/**
 * Refresh data - on mount
 */
export const DEVICES__DISPLAYS_REFRESH = 'DEVICES__DISPLAYS_REFRESH';

export const refreshDisplaysDetailAction = () => ({
  type: `${DEVICES__DISPLAYS_REFRESH}`,
});


/**
 * Change Value
 */
export const DEVICES__DISPLAYS_CHANGE_VALUE_DETAIL = 'DEVICES__DISPLAYS_CHANGE_VALUE_DETAIL';

export const changeValueAction = (name, value, validationError) => ({
  type: `${DEVICES__DISPLAYS_CHANGE_VALUE_DETAIL}`,
  payload: {
    name,
    value,
    validationError,
  },
});


/**
 * Validate Form
 */
export const DEVICES__DISPLAYS_VALIDATE_FORM_DETAIL = 'DEVICES__DISPLAYS_VALIDATE_FORM_DETAIL';

export const validateFormAction = (error) => ({
  type: `${DEVICES__DISPLAYS_VALIDATE_FORM_DETAIL}`,
  payload: error,
});


/*
 * GET Displays Detail
 */
export const DEVICES__GET_DISPLAYS_DETAIL = 'DEVICES__GET_DISPLAYS_DETAIL';
export const getDisplaysDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__GET_DISPLAYS_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/devices/display/detail?id=${id}`,
    });

    dispatch({
      type: `${DEVICES__GET_DISPLAYS_DETAIL}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${DEVICES__GET_DISPLAYS_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * UPDATE Displays Detail
 */
export const DEVICES__UPDATE_DISPLAYS_DETAIL = 'DEVICES__UPDATE_DISPLAYS_DETAIL';
export const updateDisplaysDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__UPDATE_DISPLAYS_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/devices/display`,
      data: {
        id: item.id,
        values: parseValues(item.values),
      },
    });

    dispatch({
      type: `${DEVICES__UPDATE_DISPLAYS_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Displej úspěšně aktualizován',
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${DEVICES__UPDATE_DISPLAYS_DETAIL}__${REJECTED_VALIDATION}`,
        payload: parsedError,
      });
      return;
    }

    dispatch({
      type: `${DEVICES__UPDATE_DISPLAYS_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};
