import React, { Component, } from 'react';
import {
  shape, func, object, bool,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import { SHARED_DATA_TYPES, } from '../../../globals';
import {
  refreshGenericFormsDetailAction,
  createAction,
} from '../actions/genericFormsDetail';
import {
  addPartAction,
  editPartAction,
  closePartAction,
  savePartAction,
  removePartAction,
  changePartAction,
  changePartTypeAction,
  movePartAction,
  getGroupInputsAction,
  changeRootPartAction,
} from '../actions/editableGenForm';
import { getSharedDataAction, } from '../../shared/actions/shared';
import Modal from '../../../atoms/Modal/Modal';
import GenericFormsDetail from '../components/GenericFormsDetail/GenericFormsDetail';
import GenericFormsCreate from '../components/GenericFormsCreate/GenericFormsCreate';


class GenericFormsCreateModal extends Component {
  componentDidMount() {
    const {
      refreshGenericFormsDetail,
    } = this.props;

    refreshGenericFormsDetail();
  }

  handleClose = () => {
    const { history, } = this.props;

    history.goBack();
  }

  handleCreate = async () => {
    const {
      onCreate,
    } = this.props;

    onCreate(this.handleCreateSuccess);
  }


  handleCreateSuccess = () => {
    const { refreshParent, } = this.props;

    refreshParent();
    this.handleClose();
  }

  render() {
    const {
      // data
      createData,
      editableGenForm,
      regexesData,
      groupData,
      optionsData,
      btnActionsData,
      // methods
      getSharedData,
      onAddPart,
      onEditPart,
      onClosePart,
      onSavePart,
      onRemovePart,
      onChangePart,
      onChangePartType,
      onChangeRootPart,
      onMovePart,
      onGetGroupInputs,
    } = this.props;

    const {
      commonValidation,
      finalValidation,
    } = editableGenForm;

    return (
      <Modal
        isOpen
        onClose={this.handleClose}
        closeOnEsc
        size="MD"
        title="Vytvoření formuláře"
      >
        <GenericFormsCreate
          // data
          createData={createData}
          isFormValid={commonValidation.isValid && finalValidation.isValid}
          // methods
          onClose={this.handleClose}
          onCreate={this.handleCreate}
        >
          <GenericFormsDetail
            editableGenForm={editableGenForm}
            commonData={{
              regexesData,
              groupData,
              optionsData,
              btnActionsData,
            }}
            genFormActions={{
              onAddPart,
              onEditPart,
              onClosePart,
              onSavePart,
              onChangePart,
              onChangePartType,
              onChangeRootPart,
              onRemovePart,
              onMovePart,
            }}
            commonActions={{
              onGetRegexes: () => getSharedData(SHARED_DATA_TYPES.REGEXES),
              onGetGroups: () => getSharedData(SHARED_DATA_TYPES.GROUPS),
              onGetGroupInputs,
              onGetOptions: () => getSharedData(SHARED_DATA_TYPES.OPTIONS),
              onGetBtnActions: () => getSharedData(SHARED_DATA_TYPES.BTN_ACTIONS),
            }}
          />
        </GenericFormsCreate>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { genericFormsDetail, shared, editableGenForm, } = state;
  return {
    createData: genericFormsDetail.createData,
    // generic forms
    editableGenForm,
    regexesData: shared.regexesData,
    groupData: shared.groupData,
    optionsData: shared.optionsData,
    btnActionsData: shared.btnActionsData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSharedData: bindActionCreators(getSharedDataAction, dispatch),
  refreshGenericFormsDetail: bindActionCreators(refreshGenericFormsDetailAction, dispatch),
  onCreate: bindActionCreators(createAction, dispatch),
  onAddPart: bindActionCreators(addPartAction, dispatch),
  onEditPart: bindActionCreators(editPartAction, dispatch),
  onClosePart: bindActionCreators(closePartAction, dispatch),
  onSavePart: bindActionCreators(savePartAction, dispatch),
  onRemovePart: bindActionCreators(removePartAction, dispatch),
  onChangePart: bindActionCreators(changePartAction, dispatch),
  onChangePartType: bindActionCreators(changePartTypeAction, dispatch),
  onMovePart: bindActionCreators(movePartAction, dispatch),
  onGetGroupInputs: bindActionCreators(getGroupInputsAction, dispatch),
  onChangeRootPart: bindActionCreators(changeRootPartAction, dispatch),
});


GenericFormsCreateModal.propTypes = {
  createData: object.isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
  refreshGenericFormsDetail: func.isRequired,
  refreshParent: func.isRequired,
  getSharedData: func.isRequired,
  onCreate: func.isRequired,

  editableGenForm: shape({
    commonValidation: shape({
      isValid: bool.isReqired,
    }).isRequired,
    finalValidation: shape({
      isValid: bool.isReqired,
    }).isRequired,
  }).isRequired,
  regexesData: object.isRequired,
  groupData: object.isRequired,
  optionsData: object.isRequired,
  btnActionsData: object.isRequired,

  onEditPart: func.isRequired,
  onClosePart: func.isRequired,
  onSavePart: func.isRequired,
  onChangeRootPart: func.isRequired,
  onAddPart: func.isRequired,
  onRemovePart: func.isRequired,
  onChangePart: func.isRequired,
  onChangePartType: func.isRequired,
  onMovePart: func.isRequired,
  onGetGroupInputs: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(GenericFormsCreateModal);
