import React, { Fragment, } from 'react';
import {
  shape, func, string, bool, object,
} from 'prop-types';

import { withLang, } from '../../../../logic/languages/withLang';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Input from '../../../../atoms/Input/Input';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';
import InputSelectWrapper from '../../../../components/wrappers/InputSelectWrapper';

const KiosksDetail = ({
  // data
  form,
  values,
  kioskConfigurationsSharedData,
  standsSharedData,
  camerasSharedData,
  // methods
  handleOnChangeValue,
  langError,
  getKioskConfigurationsShared,
  getStandsShared,
  getCamerasShared,
}) => (
  <Fragment>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Název
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="text"
          value={values.name}
          status={form.name.status}
          onChange={(e) => handleOnChangeValue(form.name.name, e.target.value)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.name.status}
          msg={langError(form.name.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Konfigurace
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <InputSelectWrapper
          value={values.kioskConfiguration}
          status={form.kioskConfiguration.status}
          options={kioskConfigurationsSharedData.data}
          onChange={(value) => handleOnChangeValue(form.kioskConfiguration.name, value)}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          isLoading={kioskConfigurationsSharedData.isLoading}
          error={kioskConfigurationsSharedData.error}
          fetchData={getKioskConfigurationsShared}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.kioskConfiguration.status}
          msg={langError(form.kioskConfiguration.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Stanoviště
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <InputSelectWrapper
          value={values.standId}
          status={form.standId.status}
          options={standsSharedData.data}
          onChange={(value) => handleOnChangeValue(form.standId.name, value)}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          isLoading={standsSharedData.isLoading}
          error={standsSharedData.error}
          fetchData={getStandsShared}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.standId.status}
          msg={langError(form.standId.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Kamery
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <InputSelectWrapper
          value={values.cameraIds}
          status={form.cameraIds.status}
          options={camerasSharedData.data}
          onChange={(value) => handleOnChangeValue(form.cameraIds.name, value)}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          isLoading={camerasSharedData.isLoading}
          error={camerasSharedData.error}
          fetchData={getCamerasShared}
          isMulti
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.cameraIds.status}
          msg={langError(form.cameraIds.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Kód zařízení
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} formValue sidePadding>
        {values.deviceCode}
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Synchronizováno
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} formValue sidePadding>
        <span className={values.synchronized ? 'app--color-success' : 'app--color-error'}>
          {values.synchronized ? 'Ano' : 'ne'}
        </span>
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Dostupnost
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} formValue sidePadding>
        <span className={values.online ? 'app--color-success' : 'app--color-error'}>
          {values.online ? 'Online' : 'Offline'}
        </span>
      </Col>
    </Row>

  </Fragment>
);


KiosksDetail.propTypes = {
  form: shape({
    name: shape({
      name: string.isRequired,
    }),
  }).isRequired,
  values: shape({
    name: string,
    deviceCode: string,
    online: bool,
    synchronized: bool,
  }).isRequired,
  kioskConfigurationsSharedData: object.isRequired,
  getKioskConfigurationsShared: func.isRequired,
  handleOnChangeValue: func.isRequired,
  getStandsShared: func.isRequired,
  standsSharedData: object.isRequired,
  langError: func.isRequired,
  camerasSharedData: object.isRequired,
  getCamerasShared: func.isRequired,
};


export default withLang(KiosksDetail);
