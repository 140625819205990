import React, { Component, } from 'react';
import {
  shape, func, object,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import { getSharedDataAction, } from '../../shared/actions/shared';
import {
  getMappedFormsAction,
  changeMappedFormAction,
  updateMappedFormsAction,
  getTransportationEditFormDataAction,
} from '../actions/mapForms';
import MapFormsDetail from '../components/MapFormsDetail/MapFormsDetail';
import { SHARED_DATA_TYPES, } from '../../../globals';
import ActionsToForms from '../components/MapFormsDetail/ActionsToForms';


class MapFormsPage extends Component {
  componentDidMount = () => {
  }

  handleUpdate = () => {
    const { mapForms, updateMappedForms, } = this.props;
    if (mapForms.updateData.isLoading) {
      return;
    }

    updateMappedForms();
  }

  render() {
    const {
      // data
      transportationTypesData,
      formsData,
      mapForms,
      // methods
      getSharedData,
      getMappedForms,
      changeMappedForm,
      getTransportationEditFormData,
    } = this.props;

    return (
      <MapFormsDetail
        // data
        value={mapForms.selected}
        transportationTypesData={transportationTypesData}
        disabled={mapForms.mappedFormsData.isLoading}
        // methods
        onChange={getMappedForms}
        onGetTransporationTypes={() => getSharedData(SHARED_DATA_TYPES.TRANSPORTATION_TYPES)}
      >
        <ActionsToForms
          mapForms={mapForms}
          formsData={formsData}
          onUpdate={this.handleUpdate}
          onRefresh={getMappedForms}
          onChangeForm={changeMappedForm}
          onGetForms={() => getSharedData(SHARED_DATA_TYPES.FORMS)}
          getTransportationEditFormData={getTransportationEditFormData}
        />
      </MapFormsDetail>
    );
  }
}


const mapStateToProps = (state) => {
  const { shared, mapForms, } = state;
  return {
    transportationTypesData: shared.transportationTypesData,
    formsData: shared.formsData,
    mapForms,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSharedData: bindActionCreators(getSharedDataAction, dispatch),
  getMappedForms: bindActionCreators(getMappedFormsAction, dispatch),
  changeMappedForm: bindActionCreators(changeMappedFormAction, dispatch),
  updateMappedForms: bindActionCreators(updateMappedFormsAction, dispatch),
  getTransportationEditFormData: bindActionCreators(getTransportationEditFormDataAction, dispatch),
});


MapFormsPage.propTypes = {
  // data
  transportationTypesData: object.isRequired,
  formsData: object.isRequired,
  mapForms: shape({
    selected: object,
  }).isRequired,
  // methods
  getSharedData: func.isRequired,
  getMappedForms: func.isRequired,
  changeMappedForm: func.isRequired,
  updateMappedForms: func.isRequired,
  getTransportationEditFormData: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(MapFormsPage);
