import React, { Fragment, } from 'react';
import {
  object, func,
} from 'prop-types';

import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Table from './Table';
import Button from '../../../../atoms/Button/Button';


const TableView = ({
  // data
  table,
  tableData,
  // methods
  onGetTableData,
  onEdit,
  onDelete,
  onCreate,
  onChangeFilter,
}) => (
  <Fragment>

    <Row formGroup>
      <Col>
        <Button
          type="button"
          onClick={onCreate}
          color="success"
          shape="outline"
        >
          Vytvořit jazyk
        </Button>
      </Col>
    </Row>

    <Table
      data={table.data}
      table={table}
      filter={table.filter}
      count={table.count}
      tableData={tableData}
      onGetTableData={onGetTableData}
      onEdit={onEdit}
      onDelete={onDelete}
      onChangeFilter={onChangeFilter}
    />

  </Fragment>
);


TableView.propTypes = {
  table: object.isRequired,
  tableData: object.isRequired,
  onGetTableData: func.isRequired,
  onChangeFilter: func.isRequired,
  onEdit: func.isRequired,
  onDelete: func.isRequired,
  onCreate: func.isRequired,
};


export default TableView;
