import { FORM_ROOT, FORM_TYPES, } from '../../globals';
import { remapMapValue, } from './utils';


const makeMapIdToName = (parts) => {
  const ret = {
    FORM_ROOT,
  };

  const keys = Object.keys(parts);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const tmpType = parts[key].type;

    switch (tmpType) {
      case FORM_TYPES.NUMBER:
      case FORM_TYPES.TEXT:
      case FORM_TYPES.CHECKBOX:
      case FORM_TYPES.RADIO:
      case FORM_TYPES.SELECT:
      case FORM_TYPES.MULTISELECT:
      case FORM_TYPES.TIME:
      case FORM_TYPES.DATE:
      case FORM_TYPES.TIMESTAMP:
        ret[key] = parts[key].values.name;
        break;

      default:
        ret[key] = key;
        break;
    }
  }

  return ret;
};


const filterChilds = (childs, parts, mapIdToName) => {
  const ret = [];

  for (let i = 0; i < childs.length; i++) {
    const id = childs[i];
    if (parts[id].type !== null) {
      ret.push(mapIdToName[id]);
    }
  }

  return ret;
};


const parseInput = (part, mapIdToName) => ({
  ...part.values,
  type: part.type,
  map: remapMapValue(part.values.map, mapIdToName),
});


const parseFieldsetTexts = (part) => ({
  [part.id]: {
    title: part.values.title,
  },
});


const parsePart = (part, mapIdToName) => {
  const ret = {
    parsedPart: null,
    parsedTexts: {},
  };

  switch (part.type) {
    case FORM_TYPES.NUMBER:
    case FORM_TYPES.TEXT:
    case FORM_TYPES.CHECKBOX:
    case FORM_TYPES.RADIO:
    case FORM_TYPES.SELECT:
    case FORM_TYPES.MULTISELECT:
    case FORM_TYPES.TIME:
    case FORM_TYPES.DATE:
    case FORM_TYPES.TIMESTAMP:
      return {
        ...ret,
        parsedPart: parseInput(part, mapIdToName),
      };

    case FORM_TYPES.FIELDSET:
      return {
        ...ret,
        parsedTexts: {
          ...parseFieldsetTexts(part),
        },
      };

    case FORM_TYPES.GROUP:
      return {
        ...ret,
      };

    default:
      return ret;
  }
};


//
// Parse Gen Form to Api
//
export const parseToApi = (state) => {
  const {
    id, lang, structure, parts,
  } = state;

  const newStructure = {};
  const newIpnuts = [];
  const newTranslation = {
    texts: {},
  };

  // remap uuid to name
  const mapIdToName = makeMapIdToName(parts);

  // start with root childs. because root doesn't have part
  const iterateIds = [ ...structure[FORM_ROOT].childs, ];

  // ROOT structure
  newStructure[FORM_ROOT] = {
    ...structure[FORM_ROOT],
    childs: structure[FORM_ROOT].childs.map((item) => mapIdToName[item]),
  };

  while (iterateIds.length > 0) {
    const tmpId = iterateIds.pop();
    const tmpName = mapIdToName[tmpId];
    const tmpStruct = structure[tmpId];

    // skip empty parts
    if (parts[tmpId].type !== null) {
      iterateIds.push(...tmpStruct.childs);

      // parse structure, iterate childs to skip empty parts
      newStructure[tmpName] = {
        ...tmpStruct,
        id: tmpName,
        childs: filterChilds(tmpStruct.childs, parts, mapIdToName),
      };

      // parse part => get inputs, translation
      const tmpParsed = parsePart(parts[tmpId], mapIdToName);

      if (tmpParsed.parsedPart !== null) {
        newIpnuts.push(tmpParsed.parsedPart);
      }
      newTranslation.texts = {
        ...newTranslation.texts,
        ...tmpParsed.parsedTexts,
      };
    }
  }

  // root form values
  const rootNode = parts[FORM_ROOT];

  return {
    id,
    name: rootNode.values.title,
    lang,
    structure: newStructure,
    inputs: newIpnuts,
    translation: newTranslation,
  };
};
