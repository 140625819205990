import React from 'react';
import InputSelect from '../../atoms/InputSelect/InputSelect';


const InputSelectWrapper = (props) => (
  <InputSelect
    {...props}
    loadingMessage="Načítám"
    noOptionsMessage="Žádná data"
    errorMessage="Nepovedlo se načíst data"
    placeholder="Vyberte"
  />
);


export default InputSelectWrapper;
