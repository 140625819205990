import React, { Component, Fragment, } from 'react';
import {
  shape, number, bool, func, object, string, arrayOf,
} from 'prop-types';
// import { Route, } from 'react-router-dom';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  restoreParkingsDetailAction,
  deleteParkingsDetailAction,
} from '../actions/parkingsDetail';
import { getParkingsAction, changeParkingsFilterAction, } from '../actions/parkings';
import Parkings from '../components/Parkings/Parkings';
// import ParkingsCreateModal from './ParkingsCreateModal';
// import ParkingsEditModal from './ParkingsEditModal';


class ParkingsPage extends Component {
  componentDidMount() {
    const { table, } = this.props;

    this.handleGetParkings(table.filter);
  }

  handleGetParkings = (newFilter) => {
    const { getParkings, table, } = this.props;

    getParkings(newFilter === undefined ? table.filter : newFilter);
  }

  handleEdit = (id) => {
    const { match, history, } = this.props;

    history.push(`${match.path}/edit/${id}`);
  }

  handleCreate = () => {
    const { match, history, } = this.props;

    history.push(`${match.path}/create`);
  }

  handleRestore = (item) => {
    const { restoreParkingsDetail, } = this.props;

    if (!item.isLoading) {
      restoreParkingsDetail(item.id);
    }
  }

  handleDelete = (item) => {
    const { deleteParkingsDetail, } = this.props;

    if (!item.isLoading) {
      deleteParkingsDetail(item.id);
    }
  }

  render() {
    const {
      // match,
      table,
      parkingsData,
      changeFilter,
    } = this.props;

    return (
      <Fragment>
        <Parkings
          table={table}
          parkingsData={parkingsData}
          handleGetParkings={this.handleGetParkings}
          handleRestore={this.handleRestore}
          handleEdit={this.handleEdit}
          handleDelete={this.handleDelete}
          handleCreate={this.handleCreate}
          changeFilter={changeFilter}
        />
        {/*
        <Route
          exact
          path={`${match.path}/create`}
          render={
            (props) => <ParkingsCreateModal {...props} refreshParent={this.handleGetParkings} />
          }
        />
        <Route
          exact
          path={`${match.path}/edit/:id`}
          component={
            (props) => <ParkingsEditModal {...props} refreshParent={this.handleGetParkings} />
          }
        />
        */}
      </Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  const { parkings, } = state;
  return {
    parkingsData: parkings.parkingsData,
    table: parkings.table,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getParkings: bindActionCreators(getParkingsAction, dispatch),
  restoreParkingsDetail: bindActionCreators(restoreParkingsDetailAction, dispatch), // eslint-disable-line
  deleteParkingsDetail: bindActionCreators(deleteParkingsDetailAction, dispatch), // eslint-disable-line
  changeFilter: bindActionCreators(changeParkingsFilterAction, dispatch),
});


ParkingsPage.propTypes = {
  table: shape({
    data: arrayOf(object).isRequired,
    count: number.isRequired,
    filter: object.isRequired,
  }).isRequired,
  parkingsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  changeFilter: func.isRequired,
  getParkings: func.isRequired,
  restoreParkingsDetail: func.isRequired,
  deleteParkingsDetail: func.isRequired,
  match: shape({
    path: string.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(ParkingsPage);
