import React from 'react';
import { func, bool, } from 'prop-types';

import Container from '../../atoms/Container/Container';
import Menu from '../../styles/icons/Menu';
import MenuClose from '../../styles/icons/MenuClose';
import HeaderItemRound from './HeaderItemRound';

import StyledHeader from './styles/StyledHeader';


const Header = ({ onToggleSidebar, isOpenSidebar, }) => (
  <StyledHeader>
    <Container>
      <div className="header--container">

        <ul>
          <li>
            <HeaderItemRound
              onClick={onToggleSidebar}
            >
              {isOpenSidebar ? <MenuClose /> : <Menu />}
            </HeaderItemRound>
          </li>
        </ul>

        {/* <ul className="header--container-right">
          <li>

          </li>
        </ul> */}

      </div>
    </Container>
  </StyledHeader>
);


Header.propTypes = {
  onToggleSidebar: func.isRequired,
  isOpenSidebar: bool.isRequired,
};

export default Header;
