import React from 'react';
import { bool, string, func, } from 'prop-types';

import StyledCheckBox from './styles/StyledCheckBox';


const CheckBox = ({
  text, checked, disabled, onChange, id,
}) => (
  <StyledCheckBox>
    <label
      className={`checkbox--container${(disabled ? ' checkbox--container-disabled' : '')}`}
      htmlFor={id}
    >
      {text}
      <input
        id={id}
        disabled={disabled}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <span className="checkbox--checkmark" />
    </label>
  </StyledCheckBox>
);


CheckBox.propTypes = {
  id: string.isRequired,
  checked: bool.isRequired,
  disabled: bool,
  text: string,
  onChange: func.isRequired,
};

CheckBox.defaultProps = {
  disabled: false,
  text: '',
};


export default CheckBox;
