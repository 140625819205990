import React, { Component, Fragment, } from 'react';
import {
  shape, bool, func, object, string,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  getPlatformsDetailAction,
  updatePlatformsDetailAction,
  validateFormAction,
  changeValueAction,
  forceUpdatePlatformsDetailAction,
  toggleForceUpdateModalAction,
} from '../actions/platformsDetail';
import {
  getTransportationTypesSharedAction,
} from '../../shared/actions/shared';
import {
  validateInput, validateWholeFormValidator,
} from '../../../logic/forms/validation';
import PlatformsEdit from '../components/PlatformsEdit/PlatformsEdit';
import Modal from '../../../atoms/Modal/Modal';
import PlatformsDetail from '../components/PlatformsDetail/PlatformsDetail';
import PlatformsEditCollisionModal from '../components/PlatformsEditCollisionModal/PlatformsEditCollisionModal';


class PlatformsEditModal extends Component {
  componentDidMount() {
    this.handleGetDetail();
  }

  handleGetDetail = () => {
    const { match, getPlatformsDetail, } = this.props;

    getPlatformsDetail(match.params.id);
  }

  handleClose = () => {
    const { history, } = this.props;

    history.goBack();
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);

    changeValue(name, value, validationError);
  }

  handleEdit = () => {
    const {
      updatePlatformsData,
      detail,
      validateForm,
      updatePlatformsDetail,
    } = this.props;

    if (updatePlatformsData.isLoading || !detail.isValid) return;

    const validation = validateWholeFormValidator(detail.form, detail.values);
    if (Object.keys(validation).length < 1) {
      updatePlatformsDetail(detail, this.handleEditSuccess);
    } else {
      validateForm(validation);
    }
  }

  handleForceEdit = () => {
    const {
      forceUpdatePlatformsDetail, detail, forceUpdatePlatformsData, validateForm,
    } = this.props;

    if (forceUpdatePlatformsData.isLoading || !detail.isValid) return;

    const validation = validateWholeFormValidator(detail.form, detail.values);
    if (Object.keys(validation).length < 1) {
      forceUpdatePlatformsDetail(detail, this.handleEditSuccess);
    } else {
      validateForm(validation);
    }
  }

  handleEditSuccess = () => {
    const { refreshParent, } = this.props;

    this.handleClose();
    refreshParent();
  }

  render() {
    const {
      detailPlatformsData,
      updatePlatformsData,
      transportationTypesSharedData,
      forceUpdateModal,
      detail,
      forceUpdatePlatformsData,
      getTransportationTypesShared,
      toggleForceUpdateModal,
    } = this.props;

    return (
      <Fragment>

        <Modal
          isOpen
          onClose={this.handleClose}
          closeOnEsc
          size="MD"
          title="Detail typu rampy"
        >
          <PlatformsEdit
            // data
            platformsDetailData={detailPlatformsData}
            updatePlatformsData={updatePlatformsData}
            isFormValid={detail.isValid}
            // methods
            handleClose={this.handleClose}
            handleEdit={this.handleEdit}
            onGetDetail={this.handleGetDetail}
          >
            <PlatformsDetail
              // data
              values={detail.values}
              form={detail.form}
              transportationTypesSharedData={transportationTypesSharedData}
              // methods
              handleClose={this.handleClose}
              handleOnChangeValue={this.handleOnChangeValue}
              getTransportationTypesShared={getTransportationTypesShared}
            />
          </PlatformsEdit>
        </Modal>

        <PlatformsEditCollisionModal
          modalData={forceUpdateModal}
          actionData={forceUpdatePlatformsData}
          onClose={() => toggleForceUpdateModal(false)}
          action={this.handleForceEdit}
        />

      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { platformsDetail, shared, } = state;
  return {
    detailPlatformsData: platformsDetail.detailPlatformsData,
    updatePlatformsData: platformsDetail.updatePlatformsData,
    forceUpdateModal: platformsDetail.forceUpdateModal,
    detail: platformsDetail.detail,
    forceUpdatePlatformsData: platformsDetail.forceUpdatePlatformsData,
    // dropdowns
    transportationTypesSharedData: shared.transportationTypesData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPlatformsDetail: bindActionCreators(getPlatformsDetailAction, dispatch),
  updatePlatformsDetail: bindActionCreators(updatePlatformsDetailAction, dispatch),
  getTransportationTypesShared: bindActionCreators(getTransportationTypesSharedAction, dispatch),
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
  toggleForceUpdateModal: bindActionCreators(toggleForceUpdateModalAction, dispatch),
  forceUpdatePlatformsDetail: bindActionCreators(forceUpdatePlatformsDetailAction, dispatch),
});


PlatformsEditModal.propTypes = {
  forceUpdatePlatformsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  forceUpdateModal: shape({
    isOpen: bool.isRequired,
    reservations: object.isRequired,
    transports: object.isRequired,
  }).isRequired,
  detailPlatformsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  updatePlatformsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  transportationTypesSharedData: object.isRequired,
  detail: shape({
    isValid: bool.isRequired,
    form: object.isRequired,
    values: object.isRequired,
    oldValues: object.isRequired,
  }).isRequired,
  match: shape({
    params: shape({
      id: string.isRequired,
    }).isRequired,
  }).isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
  getPlatformsDetail: func.isRequired,
  getTransportationTypesShared: func.isRequired,
  refreshParent: func.isRequired,
  updatePlatformsDetail: func.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
  toggleForceUpdateModal: func.isRequired,
  forceUpdatePlatformsDetail: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(PlatformsEditModal);
