import {
  TRANSPORTATION_TYPES__GET_TRANSPORTATION_TYPES,
  TRANSPORTATION_TYPES__FILTER_CHANGED,
} from '../actions/transportationTypes';
import {
  TRANSPORTATION_TYPES__DELETE_TRANSPORTATION_TYPES_DETAIL,
  TRANSPORTATION_TYPES__RESTORE_TRANSPORTATION_TYPES_DETAIL,
  TRANSPORTATION_TYPES__FORCE_DELETE_TRANSPORTATION_TYPES_DETAIL,
} from '../actions/transportationTypesDetail';
import {
  PENDING, FULFILLED, REJECTED, REJECTED_COLLISON,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';


const parseTableData = (data) => (
  data.map((item) => ({
    ...item,
    name: item.transportationTypeTranslation.name,
    ...initRestData,
  }))
);


const initialState = {
  transportationTypesData: {
    ...initRestData,
    isLoading: true,
  },
  table: {
    data: [],
    count: 0,
    filter: {
      offset: 0,
      limit: 10,
      sortBy: 'id',
      order: 'ASC',
      params: {
        id: '',
        name: '',
        deleted: 'no',
      },
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;
  switch (type) {
    /**
     * GET Transportation Types
     */
    case `${TRANSPORTATION_TYPES__GET_TRANSPORTATION_TYPES}__${PENDING}`: {
      return {
        ...state,
        transportationTypesData: {
          ...state.transportationTypesData,
          ...(handlePending()),
        },
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    case `${TRANSPORTATION_TYPES__GET_TRANSPORTATION_TYPES}__${FULFILLED}`: {
      return {
        ...state,
        transportationTypesData: {
          ...state.transportationTypesData,
          ...(handleFulfilled(payload)),
        },
        table: {
          data: parseTableData(payload.transportationTypes),
          count: payload.count,
          filter: payload.filter,
        },
      };
    }

    case `${TRANSPORTATION_TYPES__GET_TRANSPORTATION_TYPES}__${REJECTED}`: {
      return {
        ...state,
        transportationTypesData: {
          ...state.transportationTypesData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * DELETE, RESTORE Transportation Types Detail
     */
    case `${TRANSPORTATION_TYPES__DELETE_TRANSPORTATION_TYPES_DETAIL}__${PENDING}`:
    case `${TRANSPORTATION_TYPES__RESTORE_TRANSPORTATION_TYPES_DETAIL}__${PENDING}`: {
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.map((item) => {
            if (item.id === payload.id) {
              return {
                ...item,
                ...(handlePending()),
              };
            }
            return item;
          }),
        },
      };
    }

    case `${TRANSPORTATION_TYPES__DELETE_TRANSPORTATION_TYPES_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.map((item) => {
            if (item.id === payload.id) {
              const now = new Date();

              return {
                ...item,
                deletedAt: now.toUTCString(),
                ...initRestData,
              };
            }
            return item;
          }),
        },
      };
    }

    case `${TRANSPORTATION_TYPES__RESTORE_TRANSPORTATION_TYPES_DETAIL}__${FULFILLED}`: {
      const { id, } = payload;

      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.map((item) => {
            if (item.id === id) {
              return {
                ...payload.data,
                ...initRestData,
              };
            }
            return item;
          }),
        },
      };
    }

    case `${TRANSPORTATION_TYPES__DELETE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED}`:
    case `${TRANSPORTATION_TYPES__RESTORE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED}`: {
      const { id, } = payload;

      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                ...(handleRejected(payload)),
              };
            }
            return item;
          }),
        },
      };
    }

    case `${TRANSPORTATION_TYPES__DELETE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED_COLLISON}`: {
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.map((item) => {
            if (item.id === payload.id) {
              return {
                ...item,
                ...(handleRejected(payload)),
              };
            }
            return item;
          }),
        },
      };
    }


    /**
     * FORCE DELETE Platfgorm Detail
     */
    case `${TRANSPORTATION_TYPES__FORCE_DELETE_TRANSPORTATION_TYPES_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.map((item) => {
            if (item.id === payload.id) {
              const now = new Date();

              return {
                ...item,
                deletedAt: now.toUTCString(),
                ...initRestData,
              };
            }
            return item;
          }),
        },
      };
    }


    /**
     * Change Filter
     */
    case `${TRANSPORTATION_TYPES__FILTER_CHANGED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
