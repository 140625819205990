import React, { Fragment, } from 'react';
import {
  func, object, shape,
} from 'prop-types';

import FormRow from '../FormRow';
import Input from '../../../atoms/Input/Input';
import CheckBox from '../../../atoms/CheckBox/CheckBox';
import InputSelectWrapper from '../../wrappers/InputSelectWrapper';
import Heading from '../../../atoms/Heading/Heading';

const SelectPart = ({
  // data
  part,
  optionsData,
  // methods,
  onGetOptions,
  onChangeValue,
  onChangeValidationValue,
  onChangeTranslationValue,
}) => {
  const { values, form, } = part;
  return (
    <Fragment>
      <FormRow
        label="Identifikátor"
        status={form.name.status}
        statusMsg={form.name.statusMsg}
      >
        <Input
          type="text"
          value={values.name}
          onChange={(e) => onChangeValue(form.name.name, e.target.value)}
          status={form.name.status}
        />
      </FormRow>

      <FormRow
        label="Nelze editovat?"
        isLabel={false}
        status={form.disabled.status}
        statusMsg={form.disabled.statusMsg}
      >
        <CheckBox
          id={form.disabled.name}
          checked={values.disabled}
          onChange={() => onChangeValue(form.disabled.name, !values.disabled)}
          status={form.disabled.status}
        />
      </FormRow>

      <div>
        <Heading h6>Validace</Heading>
      </div>

      <FormRow
        label="Povinné?"
        isLabel={false}
        status={form.required.status}
        statusMsg={form.required.statusMsg}
      >
        <CheckBox
          id={form.required.name}
          checked={values.validation.required}
          onChange={
            () => onChangeValidationValue(form.required.name, !values.validation.required)
          }
          status={form.required.status}
        />
      </FormRow>

      <div>
        <Heading h6>Nastavení popisků</Heading>
      </div>

      <FormRow
        label="Popisek"
        status={form.label.status}
        statusMsg={form.label.statusMsg}
      >
        <Input
          type="text"
          value={values.translation.label}
          onChange={(e) => onChangeTranslationValue(form.label.name, e.target.value)}
          status={form.label.status}
        />
      </FormRow>

      <FormRow
        label="Placeholder"
        status={form.placeholder.status}
        statusMsg={form.placeholder.statusMsg}
      >
        <Input
          type="text"
          value={values.translation.placeholder}
          onChange={(e) => onChangeTranslationValue(form.placeholder.name, e.target.value)}
          status={form.placeholder.status}
        />
      </FormRow>

      <FormRow
        label="Nápověda"
        status={form.help.status}
        statusMsg={form.help.statusMsg}
      >
        <Input
          type="text"
          value={values.translation.help}
          onChange={(e) => onChangeTranslationValue(form.help.name, e.target.value)}
          status={form.help.status}
        />
      </FormRow>

      <FormRow
        label="Možnosti"
        status={form.genericFormDialId.status}
        statusMsg={form.genericFormDialId.statusMsg}
      >
        <InputSelectWrapper
          value={(values.genericFormDialId === null)
            ? null
            : optionsData.data[values.genericFormDialId]
          }
          status={form.genericFormDialId.status}
          options={optionsData.data}
          onChange={(value) => onChangeValue(
            form.genericFormDialId.name, value === null ? null : value.id
          )}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          isLoading={optionsData.isLoading}
          error={optionsData.error}
          fetchData={onGetOptions}
        />
      </FormRow>
    </Fragment>
  );
};


SelectPart.propTypes = {
  optionsData: object.isRequired,
  onGetOptions: func.isRequired,
  part: shape({
    values: object.isRequired,
    form: object.isRequired,
  }).isRequired,
  onChangeValue: func.isRequired,
  onChangeValidationValue: func.isRequired,
  onChangeTranslationValue: func.isRequired,
};


export default SelectPart;
