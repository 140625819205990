import styled from 'styled-components';

import media from '../../styles/media';


const RTCol = styled.div`
  position: relative;
  width: 100%;
  min-height: 1px;
  box-sizing: border-box;

  -webkit-box-flex: 0;
  -ms-flex: 0 0 ${(p) => p.width}%;
  flex: 0 0 ${(p) => p.width}%;
  max-width: ${(p) => p.width}%;

  display: flex;
  padding: 0.75rem;
  align-items: center;
  word-break: break-all;
  
  .rt--col-icon {
    margin-left: 8px;
    font-size: 0.75rem;
  }

  .rt--col-icon-first {
    margin-right: 4px;
  }

  .rt--col-icon-disabled {
    color: ${(p) => p.theme.grey.t400}
  }

  ${(p) => p.header && `
    background: ${p.theme.grey.t800};
    color: ${p.theme.white};
    font-weight: 500;
    -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  `}

  ${(p) => p.clickable && `
    cursor: pointer;
    
    &:hover {
      color: ${p.theme.grey.t400};
    }
  `}

  ${media.XS`
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;

    padding: 0;
  `}
`;


export default RTCol;
