import { INPUT_TYPE, } from 'sofair-form-validation';

import {
  QUEUE_CONFIG__STANDS_REFRESH,
  QUEUE_CONFIG__STANDS_CHANGE_VALUE_DETAIL,
  QUEUE_CONFIG__STANDS_VALIDATE_FORM_DETAIL,
  QUEUE_CONFIG__GET_STANDS_DETAIL,
  QUEUE_CONFIG__CREATE_STANDS_DETAIL,
  QUEUE_CONFIG__UPDATE_STANDS_DETAIL,
  QUEUE_CONFIG__DELETE_STANDS_DETAIL,
  QUEUE_CONFIG__TOGGLE_STANDS_DELETE_MODAL,
} from '../actions/standsDetail';
import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION, REJECTED_COLLISON,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';
import {
  mergeFormAndValidation, initInput, changeFormValue,
} from '../../../logic/forms/validation';
import { parseArrayFromApi, } from '../../../logic/utils/array';


const initialState = {
  detailStandsData: {
    ...initRestData,
    isLoading: true,
  },
  createStandsData: {
    ...initRestData,
  },
  updateStandsData: {
    ...initRestData,
  },
  deleteStandsData: {
    ...initRestData,
  },
  standsDeleteModal: {
    id: null,
    isOpen: false,
    collision: false,
    transports: {
      conflict: false,
      data: [],
    },
  },
  detail: {
    id: null,
    isValid: true,
    invalidInputs: 0,
    form: {
      name: {
        ...initInput,
        name: 'name',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: true,
          min: 2,
          max: 255,
        },
      },
      actions: {
        ...initInput,
        name: 'actions',
        type: INPUT_TYPE.MULTISELECT,
        validation: {
          ...initInput.validation,
          required: true,
        },
      },
      standAppConfiguration: {
        ...initInput,
        name: 'standAppConfiguration',
        type: INPUT_TYPE.SELECT,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
      capacity: {
        ...initInput,
        name: 'capacity',
        type: INPUT_TYPE.NUMBER,
        validation: {
          ...initInput.validation,
          required: true,
          min: 1,
          max: 10000,
        },
      },
    },
    values: {
      name: '',
      actions: [],
      standAppConfiguration: null,
      capacity: '0',
    },
    oldValues: {},
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;

  switch (type) {
    /**
     * Refresh Data
     */
    case `${QUEUE_CONFIG__STANDS_REFRESH}`: {
      return {
        ...state,
        createStandsData: {
          ...initialState.createStandsData,
        },
        updateStandsData: {
          ...initialState.updateStandsData,
        },
        detail: {
          ...initialState.detail,
        },
      };
    }


    /**
     * Change Form
     */
    case `${QUEUE_CONFIG__STANDS_CHANGE_VALUE_DETAIL}`: {
      const newFormData = changeFormValue(
        state.detail.form, state.detail.values, state.detail.invalidInputs, payload
      );

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: newFormData.invalidInputs < 1,
          invalidInputs: newFormData.invalidInputs,
          form: newFormData.form,
          values: newFormData.values,
        },
      };
    }


    /**
     * Validate form
     */
    case `${QUEUE_CONFIG__STANDS_VALIDATE_FORM_DETAIL}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload);

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }


    /**
     * GET Stands Detail
     */
    case `${QUEUE_CONFIG__GET_STANDS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        detailStandsData: {
          ...initialState.detailStandsData,
          ...(handlePending()),
        },
        updateStandsData: {
          ...initialState.updateStandsData,
        },
        detail: {
          ...initialState.detail,
        },
      };
    }

    case `${QUEUE_CONFIG__GET_STANDS_DETAIL}__${FULFILLED}`: {
      try {
        const newValues = {
          ...state.detail.values,
          name: payload.data.standTranslation.name,
          actions: parseArrayFromApi(payload.data.actions.map((row) => ({
            ...row,
            name: row.actionTranslation.name,
          }))),
          standAppConfiguration: payload.data.standAppConfiguration,
          capacity: payload.data.capacity.toString(),
        };

        return {
          ...state,
          detailStandsData: {
            ...state.detailStandsData,
            ...(handleFulfilled(payload)),
          },
          detail: {
            ...state.detail,
            id: payload.data.id,
            values: newValues,
            oldValues: newValues,
          },
        };
      } catch (err) {
        return {
          ...state,
          detailStandsData: {
            ...state.detailStandsData,
            ...(handleRejected({
              statusCode: -1,
              messageInternal: err.message,
              message: {
                code: 'SOMETHING_HAPPEND',
                params: {},
              },
            })),
          },
        };
      }
    }

    case `${QUEUE_CONFIG__GET_STANDS_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        detailStandsData: {
          ...state.detailStandsData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * CREATE Stands Detail
     */
    case `${QUEUE_CONFIG__CREATE_STANDS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        createStandsData: {
          ...state.createStandsData,
          ...(handlePending()),
        },
      };
    }

    case `${QUEUE_CONFIG__CREATE_STANDS_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        createStandsData: {
          ...state.createStandsData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${QUEUE_CONFIG__CREATE_STANDS_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data.data);

      return {
        ...state,
        createStandsData: {
          ...state.createStandsData,
          ...(handleRejected(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${QUEUE_CONFIG__CREATE_STANDS_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        createStandsData: {
          ...state.createStandsData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * UPDATE Stands Detail
     */
    case `${QUEUE_CONFIG__UPDATE_STANDS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        updateStandsData: {
          ...state.updateStandsData,
          ...(handlePending()),
        },
      };
    }

    case `${QUEUE_CONFIG__UPDATE_STANDS_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        updateStandsData: {
          ...state.updateStandsData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${QUEUE_CONFIG__UPDATE_STANDS_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data);

      return {
        ...state,
        updateStandsData: {
          ...state.updateStandsData,
          ...(handleRejected(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${QUEUE_CONFIG__UPDATE_STANDS_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        updateStandsData: {
          ...state.updateStandsData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * DELETE Stands Detail
     */
    case `${QUEUE_CONFIG__DELETE_STANDS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        deleteStandsData: {
          ...state.deleteStandsData,
          ...(handlePending()),
        },
      };
    }

    case `${QUEUE_CONFIG__DELETE_STANDS_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        deleteStandsData: {
          ...state.deleteStandsData,
          ...(handleFulfilled(payload)),
        },
        standsDeleteModal: {
          ...initialState.standsDeleteModal,
        },
      };
    }

    case `${QUEUE_CONFIG__DELETE_STANDS_DETAIL}__${REJECTED_COLLISON}`: {
      return {
        ...state,
        deleteStandsData: {
          ...state.deleteStandsData,
          ...(handleRejected(payload)),
        },
        standsDeleteModal: {
          ...state.standsDeleteModal,
          collision: true,
          transports: {
            ...state.standsDeleteModal.transports,
            conflict: payload.data.conflict,
            data: payload.data.transports,
          },
        },
      };
    }

    case `${QUEUE_CONFIG__DELETE_STANDS_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        deleteStandsData: {
          ...state.deleteStandsData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
     * TOGGLE Stands delete modal
     */
    case `${QUEUE_CONFIG__TOGGLE_STANDS_DELETE_MODAL}`: {
      return {
        ...state,
        standsDeleteModal: {
          ...initialState.standsDeleteModal,
          isOpen: payload.isOpen,
          id: payload.id,
        },
        deleteStandsData: initialState.deleteStandsData,
      };
    }

    default:
      return state;
  }
};

export default reducer;
