import React, { Component, Fragment, } from 'react';
import {
  shape, number, bool, func, object, string, arrayOf,
} from 'prop-types';
import { Route, } from 'react-router-dom';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  deleteAction,
  toggleModalDeleteAction,
} from '../actions/transportationEditFormsDetail';
import {
  getFormsAction,
  changeFilterAction,
} from '../actions/transportationEditForms';
import TableView from '../components/TransportationEditFormsTable/TableView';
import TransportationEditFormsDeleteModal from '../components/TransportationEditFormsDeleteModal/TransportationEditFormsDeleteModal';
import TransportationEditFormsCreateModal from './TransportationEditFormsCreateModal';
import TransportationEditFormsEditModal from './TransportationEditFormsEditModal';

class TransportationEditFormsPage extends Component {
  componentDidMount() {
    const { table, } = this.props;
    this.handleGetForms(table.filter);
  }

  handleGetForms = (newFilter) => {
    const { getForms, table, } = this.props;
    getForms(newFilter === undefined ? table.filter : newFilter);
  }

  handleEdit = (id) => {
    const { match, history, } = this.props;
    history.push(`${match.path}/edit/${id}`);
  }

  handleCreate = () => {
    const { match, history, } = this.props;
    history.push(`${match.path}/create`);
  }

  handleDelete = () => {
    const {
      deleteData,
      modalDelete,
      onDelete,
    } = this.props;
    if (!deleteData.isLoading) {
      onDelete(modalDelete.id);
    }
  }

  render() {
    const {
      // data
      match,
      table,
      formsData,
      modalDelete,
      deleteData,
      // methods
      changeFilter,
      toggleModalDelete,
    } = this.props;

    return (
      <Fragment>
        <TableView
          table={table}
          tableData={formsData}
          onGetTableData={this.handleGetForms}
          onRestore={this.handleRestore}
          onEdit={this.handleEdit}
          onDelete={toggleModalDelete}
          onCreate={this.handleCreate}
          onChangeFilter={changeFilter}
        />
        <Route
          exact
          path={`${match.path}/create`}
          render={
            (props) => (
              <TransportationEditFormsCreateModal
                {...props}
                refreshParent={this.handleGetForms}
              />
            )
          }
        />
        <Route
          exact
          path={`${match.path}/edit/:id`}
          render={
            (props) => (
              <TransportationEditFormsEditModal
                {...props}
                refreshParent={this.handleGetForms}
              />
            )
          }
        />

        <TransportationEditFormsDeleteModal
          modalData={modalDelete}
          actionData={deleteData}
          action={this.handleDelete}
          onClose={toggleModalDelete}
        />

      </Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  const { transportationEditForms, transportationEditFormsDetail, } = state;
  return {
    formsData: transportationEditForms.formsData,
    table: transportationEditForms.table,
    deleteData: transportationEditFormsDetail.deleteData,
    modalDelete: transportationEditFormsDetail.modalDelete,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getForms: bindActionCreators(getFormsAction, dispatch),
  changeFilter: bindActionCreators(changeFilterAction, dispatch),
  onDelete: bindActionCreators(deleteAction, dispatch),
  toggleModalDelete: bindActionCreators(toggleModalDeleteAction, dispatch),
});


TransportationEditFormsPage.propTypes = {
  table: shape({
    data: arrayOf(object).isRequired,
    count: number.isRequired,
    filter: object.isRequired,
  }).isRequired,
  formsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  match: shape({
    path: string.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  modalDelete: object.isRequired,
  deleteData: object.isRequired,
  changeFilter: func.isRequired,
  getForms: func.isRequired,
  toggleModalDelete: func.isRequired,
  onDelete: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(TransportationEditFormsPage);
