import React, { Fragment, } from 'react';
import {
  object,
} from 'prop-types';

import EditableGenForm from '../../../../components/EditableGenForm/EditableGenForm';


const LanguagesDetail = ({
  // data
  editableGenForm,
  commonData,
  // methods
  commonActions,
  genFormActions,
}) => (
  <Fragment>

    <EditableGenForm
      // data
      editableGenForm={editableGenForm}
      commonData={commonData}
      // methods
      genFormActions={genFormActions}
      commonActions={commonActions}
    />

  </Fragment>
);


LanguagesDetail.propTypes = {
  editableGenForm: object.isRequired,
  commonData: object.isRequired,
  genFormActions: object.isRequired,
  commonActions: object.isRequired,
};


export default LanguagesDetail;
