import React, { Component, Fragment, } from 'react';
import {
  func, arrayOf, object,
} from 'prop-types';
import { v4, } from 'uuid';

import { withLang, } from '../../../../logic/languages/withLang';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import ButtonGrp from '../../../../atoms/ButtonGrp/ButtonGrp';
import StyledDisplayEditor from './styles/StyledDispEditor';
import StyledDisplayEditorWrapper from './styles/StyledDisplayEditorWrapper';
import Display from './Display';
import Modal from '../../../../atoms/Modal/Modal';
import DisplayForm from './DisplayForm';
import Add from '../../../../styles/icons/Add';
import Save from '../../../../styles/icons/Save';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';

class DisplayEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
      item: null,
      id: 0,
      posX: 0,
      posY: 0,
      mousePosX: 0,
      mousePosY: 0,
      displays: props.displays,
      deleted: [],
      editedId: 0,
      editedDisplay: false,
      displayModal: false,
      title: '',
    };
  }

  componentDidMount() {
    const { getPlatformsShared, } = this.props;
    window.addEventListener('mouseup', this.closeDragElement);
    window.addEventListener('mousemove', this.elementDrag);
    getPlatformsShared();
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.closeDragElement);
    window.removeEventListener('mousemove', this.elementDrag);
  }

  renderDisplays = () => {
    const {
      displays,
      posX,
      posY,
      id,
    } = this.state;
    return (
      <Fragment>
        {displays.map((display) => (
          <Display
            key={display.id}
            activeId={id}
            data={display}
            posX={posX}
            posY={posY}
            dragMouseDown={this.dragMouseDown}
            removeDisplay={this.handleRemoveDisplay}
            editDisplay={this.showDisplayForm}
          />
        ))}
      </Fragment>
    );
  }

  dragMouseDown = (e, display) => {
    const { isActive, } = this.state;
    if (isActive) {
      return;
    }
    e.preventDefault();
    this.setState({
      isActive: true,
      item: display,
      id: display.id,
      mousePosX: e.pageX,
      mousePosY: e.pageY,
      posX: display.posX,
      posY: display.posY,
    });
  }

  elementDrag = (e) => {
    e.preventDefault();
    const {
      isActive,
      mousePosX,
      mousePosY,
      posX,
      posY,
      item,
    } = this.state;
    const {
      editorSettings,
    } = this.props;
    if (!isActive) {
      return;
    }
    const pos1 = (mousePosX - e.pageX);
    const pos2 = (mousePosY - e.pageY);

    let newPosY = (posY - pos2);
    let newPosX = (posX - pos1);
    const newMousePosX = e.pageX;
    const newMousePosY = e.pageY;

    if (newPosY < 0) {
      newPosY = 0;
    }
    if (newPosX < 0) {
      newPosX = 0;
    }
    if (newPosY > (editorSettings.height - item.height)) {
      newPosY = (editorSettings.height - item.height);
    }
    if (newPosX > (editorSettings.width - item.width)) {
      newPosX = (editorSettings.width - item.width);
    }

    this.setState({
      posY: newPosY,
      posX: newPosX,
      mousePosX: newMousePosX,
      mousePosY: newMousePosY,
    });
  }

  closeDragElement = (e) => {
    e.preventDefault();
    const {
      isActive,
      posX,
      posY,
      displays,
      id,
    } = this.state;

    if (isActive) {
      const isCollision = this.checkCollisions(id, posX, posY);
      if (!isCollision) {
        displays.map((display) => {
          const newDisp = display;
          if (display.id === id) {
            newDisp.posX = posX;
            newDisp.posY = posY;
          }
          return newDisp;
        });
      }
      this.setState({ isActive: false, id: 0, });
    }
  }

  checkCollisions = (id, posX, posY) => {
    const { displays, item, } = this.state;
    for (let i = 0; i < displays.length; i++) {
      if (displays[i].id !== id) {
        const originOffsetBottom = posY + item.height;
        const originOffsetRight = posX + item.width;
        const testedOffsetBottom = displays[i].posY + displays[i].height;
        const testedOffsetRight = displays[i].posX + displays[i].width;
        if (!((originOffsetBottom <= displays[i].posY) || (posY >= testedOffsetBottom) || (originOffsetRight <= displays[i].posX) || (posX >= testedOffsetRight))) { // eslint-disable-line
          return true;
        }
      }
    }
    return false;
  }

  handleCreateForm = (values) => {
    const { displays, } = this.state;
    const {
      editorSettings,
    } = this.props;

    if (values.multiple) {
      let count = parseInt(values.number, 10);
      const wCount = Math.floor(editorSettings.width / parseInt(values.width, 10));
      const hCount = Math.floor(editorSettings.height / parseInt(values.height, 10));
      const maxCount = wCount * hCount;

      if (maxCount < count) {
        count = maxCount;
        // když jich chci přidat víc, přidám pouze maximum možné (zbytek vyfuckuju)
      }
      const width = parseInt(values.width, 10);
      const height = parseInt(values.height, 10);
      let posX = 0;
      let posY = 0;
      let countInRow = 0;

      for (let i = 0; i < count; i++) {
        const disp = {
          name: `${values.name}${i}`,
          id: v4(),
          width,
          height,
          posX,
          posY,
          isNew: true,
          subtype: values.subtype,
          displayConfigurationId: values.displayConfigurationId,
          displayConfiguration: values.displayConfiguration,
          displayLocation: values.displayLocation,
          platforms: values.platforms,
          stand: values.stand,
        };

        if (countInRow < wCount - 1) {
          posX += width;
          countInRow++;
        } else {
          posX = 0;
          countInRow = 0;
          posY += height;
        }

        displays.push(disp);
      }
    } else {
      const newDisp = {
        name: values.name,
        id: v4(),
        width: parseInt(values.width, 10),
        height: parseInt(values.height, 10),
        posX: parseInt(values.posX, 10),
        posY: parseInt(values.posY, 10),
        isNew: true,
        subtype: values.subtype,
        displayConfigurationId: values.displayConfigurationId,
        displayConfiguration: values.displayConfiguration,
        displayLocation: values.displayLocation,
        platforms: values.platforms,
        stand: values.stand,
      };
      displays.push(newDisp);
    }

    this.setState({ displays, displayModal: false, });
  }

  handleRemoveDisplay = (id) => {
    const { displays, deleted, } = this.state;
    let index = -1;
    for (let i = 0; i < displays.length; i++) {
      if (displays[i].id === id) {
        index = i;
        break;
      }
    }
    if (index !== -1) {
      if (!displays[index].isNew) {
        deleted.push(displays[index].id);
      }
      displays.splice(index, 1);
    }
    this.setState({ displays, });
  }

  handleEditForm = (values) => {
    const { displays, editedId, } = this.state;
    for (let i = 0; i < displays.length; i++) {
      if (displays[i].id === editedId) {
        displays[i].name = values.name;
        displays[i].width = parseInt(values.width, 10);
        displays[i].height = parseInt(values.height, 10);
        displays[i].posX = parseInt(values.posX, 10);
        displays[i].posY = parseInt(values.posY, 10);
        displays[i].subtype = values.subtype;
        displays[i].displayConfigurationId = values.displayConfigurationId;
        displays[i].displayConfiguration = values.displayConfiguration;
        displays[i].displayLocation = values.displayLocation;
        displays[i].platforms = values.platforms;
        displays[i].stand = values.stand;
        break;
      }
    }
    this.setState({ displays, displayModal: false, editedId: 0, });
  }

  showAddDisplay = () => {
    this.setState({ type: 'add', displayModal: true, title: 'Přidání displeje', });
  }

  showEditDisplay= (id) => {
    const { displays, } = this.state;
    let editedDisplay = {};
    for (let i = 0; i < displays.length; i++) {
      if (displays[i].id === id) {
        editedDisplay = {
          name: displays[i].name,
          width: displays[i].width,
          height: displays[i].height,
          posX: displays[i].posX,
          posY: displays[i].posY,
          subtype: displays[i].subtype,
          displayConfigurationId: displays[i].displayConfigurationId,
          displayConfiguration: displays[i].displayConfiguration,
          displayLocation: displays[i].displayLocation,
          platforms: displays[i].platforms,
          stand: displays[i].stand,
        };
        break;
      }
    }

    this.setState({
      type: 'edit',
      editedId: id,
      editedDisplay,
      displayModal: true,
      title: 'Editace displeje',
    });
  }

  showDisplayForm = (type, id = 0) => {
    if (type === 'add') {
      this.showAddDisplay();
    } else {
      this.showEditDisplay(id);
    }
  }

  handleSubmitForm = (values) => {
    const { type, } = this.state;
    if (type === 'add') {
      this.handleCreateForm(values);
    } else {
      this.handleEditForm(values);
    }
  }

  handleClose = () => {
    this.setState({ displayModal: false, type: '', editedDisplay: false, });
  }


  render() {
    const {
      // data
      editorSettings,
      platformsSharedData,
      displaySubtypesSharedData,
      displayConfigurationSharedData,
      standsSharedData,
      // methods
      getStandsShared,
      updateDisplayEditorData,
      handleEdit,
      onClose,
      getDisplaySubtypesShared,
      getDisplayConfigurationShared,
    } = this.props;

    const {
      type,
      displays,
      deleted,
      editedDisplay,
      displayModal,
      title,
    } = this.state;
    return (
      <Fragment>
        <Row>
          <Col textAlign="right">
            <Button
              type="button"
              color="primary"
              onClick={() => this.showDisplayForm('add')}
            >
              <span className="btn--icon">
                <Add />
              </span>
              Přidat displej
            </Button>
          </Col>
        </Row>
        <Row>
          <Col textAlign="center">
            <StyledDisplayEditorWrapper>
              <StyledDisplayEditor
                width={editorSettings.width}
                height={editorSettings.height}
              >
                {this.renderDisplays()}
              </StyledDisplayEditor>
            </StyledDisplayEditorWrapper>
          </Col>
        </Row>
        <Row>
          <Col textAlign="right">
            <ButtonGrp>
              <Button
                type="button"
                shape="outline"
                onClick={() => onClose()}
              >
                Zpět
              </Button>
              <ButtonLoader
                type="button"
                color="success"
                onClick={() => handleEdit(displays, deleted)}
                isLoading={updateDisplayEditorData.isLoading}
              >
                <span className="btn--icon" style={{ top: '-2px', position: 'relative', }}>
                  <Save />
                </span>
                Uložit konfiguraci
              </ButtonLoader>
            </ButtonGrp>
          </Col>
        </Row>
        <Modal
          isOpen={displayModal}
          onClose={this.handleClose}
          closeOnEsc
          size="MD"
          title={title}
        >

          <DisplayForm
            onCreateForm={this.handleSubmitForm}
            isMultipleAllowed={displays.length === 0}
            onClose={this.handleClose}
            type={type}
            standsSharedData={standsSharedData}
            getStandsShared={getStandsShared}
            editedDisplay={editedDisplay}
            getDisplaySubtypesShared={getDisplaySubtypesShared}
            getDisplayConfigurationShared={getDisplayConfigurationShared}
            displaySubtypesSharedData={displaySubtypesSharedData}
            displayConfigurationSharedData={displayConfigurationSharedData}
            platformsSharedData={platformsSharedData}
          />

        </Modal>
      </Fragment>
    );
  }
}


DisplayEditor.propTypes = {
  displays: arrayOf(object).isRequired,
  editorSettings: object.isRequired,
  handleEdit: func.isRequired,
  onClose: func.isRequired,
  getDisplaySubtypesShared: func.isRequired,
  getDisplayConfigurationShared: func.isRequired,
  displaySubtypesSharedData: object.isRequired,
  displayConfigurationSharedData: object.isRequired,
  getPlatformsShared: func.isRequired,
  platformsSharedData: object.isRequired,
  updateDisplayEditorData: object.isRequired,
  standsSharedData: object.isRequired,
  getStandsShared: func.isRequired,
};


export default withLang(DisplayEditor);
