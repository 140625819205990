import axios from 'axios';
import {
  getRefreshToken,
  setAuth,
  clearLS,
  isTokenValid,
  getAccessToken,
} from '../localStorage/auth';
import { FULFILLED, REJECTED, ERROR, } from '../../globals';
import { ErrorLocalStorage, } from '../errors/errors';


/**
 * Call rest api
 * @param {*} object - method, url, data, headers
 */
export const api = async ({
  method = 'get', url, data, headers = {},
}) => {
  try {
    const response = await axios({
      method,
      url,
      data,
      headers,
    });

    return Promise.resolve({
      statusCode: response.status,
      messageInternal: response.statusText,
      message: response.data.message === undefined || response.data.message === ''
        ? {
          code: '',
          params: {},
        }
        : response.data.message,
      data: response.data.data,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};


export const AUTH__REFRESH = 'AUTH__REFRESH';
/**
 * Refresh token
 * @param {*} dispatch store dispatch
 */
export const apiRefresh = async (dispatch) => {
  try {
    const refreshToken = getRefreshToken();
    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/auth/refresh`,
      data: {
        refreshToken,
      },
    });

    setAuth({
      access: response.data.accessToken,
      refresh: response.data.refreshToken,
      expires: response.data.expires,
      authority: response.data.user,
    });

    dispatch({
      type: `${AUTH__REFRESH}__${FULFILLED}`,
      payload: {
        authority: response.data.user,
      },
    });
    return Promise.resolve();
  } catch (err) {
    // check error type
    switch (err.constructor) {
      // LocalStorage error - log out user
      case ErrorLocalStorage: {
        clearLS();
        dispatch({
          type: `${AUTH__REFRESH}__${ERROR}`,
        });
        break;
      }
      // other errors
      //  - 401 - logout user
      //  - other - sent to parent
      default: {
        // 401 - logout user
        if (err.response && err.response.status && err.response.status === 401) {
          dispatch({
            type: `${AUTH__REFRESH}__${REJECTED}`,
          });
          clearLS();
        }
        break;
      }
    }
    return Promise.reject(err);
  }
};


export const AUTH__API = 'AUTH__API';
/**
 * Call rest api with beaerer
 * @param {*} object - method, url, data, headers, dispatch
 */
export const apiAuth = async ({
  method = 'get', url, data, headers = {}, dispatch,
}) => {
  try {
    if (!isTokenValid()) {
      await apiRefresh(dispatch);
    }

    const accessToken = getAccessToken();
    const response = await axios({
      method,
      url,
      data,
      headers: {
        ...headers,
        Authorization: `bearer ${accessToken}`,
      },
    });
    return Promise.resolve({
      statusCode: response.status,
      messageInternal: response.statusText,
      message: response.data.message === undefined || response.data.message === ''
        ? {
          code: '',
          params: {},
        }
        : response.data.message,
      data: response.data.data,
    });
  } catch (err) {
    switch (err.constructor) {
      // LocalStorage error - log out user
      case ErrorLocalStorage: {
        clearLS();
        dispatch({
          type: `${AUTH__API}__${ERROR}`,
        });
        break;
      }
      default:
        break;
    }
    return Promise.reject(err);
  }
};


/**
 * Handle api error
 * @param {*} err error
 */
export const handleError = (err) => {
  // The request was made and the server responded with error
  if (err.response) {
    return {
      statusCode: err.response.status,
      messageInternal: err.response.statusText,
      message: err.response.data.message === undefined || err.response.data.message === ''
        ? {
          code: 'SOMETHING_HAPPEND',
          params: {},
        }
        : err.response.data.message,
      data: err.response.data.data,
    };
  }

  // The request was made but no response was received
  if (err.request) {
    return {
      statusCode: 0,
      messageInternal: err.message,
      message: {
        code: 'CANNOT_CONNECT',
        params: {},
      },
      data: {},
    };
  }

  // Something happened in setting up the request that triggered an Error
  return {
    statusCode: 0,
    messageInternal: err.message,
    message: {
      code: 'SOMETHING_HAPPEND',
      params: {},
    },
    data: {},
  };
};


export const makeFakeFulfilledData = (data) => ({
  data,
  message: { code: '', params: {}, },
  messageInternal: '',
  statusCode: 200,
});
