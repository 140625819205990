import React, { Component, Fragment, } from 'react';
import {
  shape, number, bool, func, object, string, arrayOf,
} from 'prop-types';
import { Route, } from 'react-router-dom';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  restoreTransportationTypesDetailAction,
  deleteTransportationTypesDetailAction,
  forceDeleteTransportationTypesDetailAction,
  toggleForceDeleteModalAction,
} from '../actions/transportationTypesDetail';
import {
  getTransportationTypesAction,
  changeFilterAction,
} from '../actions/transportationTypes';
import TableView from '../components/TransportationTypesTable/TableView';
import TransportationTypesEditModal from './TransportationTypesEditModal';
import TransportationTypesCreateModal from './TransportationTypesCreateModal';
import TransportationTypesDeleteCollisionModal from '../components/TransportationTypesDeleteCollisionModal/TransportationTypesDeleteCollisionModal';


class TransportationTypesPage extends Component {
  componentDidMount() {
    const { table, } = this.props;

    this.handleGetTransportationTypes(table.filter);
  }

  handleGetTransportationTypes = (newFilter) => {
    const { getTransportationTypes, table, } = this.props;

    getTransportationTypes(newFilter === undefined ? table.filter : newFilter);
  }

  handleEdit = (id) => {
    const { match, history, } = this.props;

    history.push(`${match.path}/edit/${id}`);
  }

  handleCreate = () => {
    const { match, history, } = this.props;

    history.push(`${match.path}/create`);
  }

  handleRestore = (item) => {
    const { restoreTransportationTypesDetail, } = this.props;

    if (!item.isLoading) {
      restoreTransportationTypesDetail(item.id);
    }
  }

  handleDelete = (item) => {
    const { deleteTransportationTypesDetail, } = this.props;

    if (!item.isLoading) {
      deleteTransportationTypesDetail(item.id);
    }
  }

  handleForceDelete = () => {
    const { forceDeleteTransportationTypesDetail, forceDeleteModal, forceDeleteData, } = this.props;

    if (!forceDeleteData.isLoading) {
      forceDeleteTransportationTypesDetail(forceDeleteModal.id);
    }
  }

  render() {
    const {
      match,
      table,
      transportationTypesData,
      forceDeleteModal,
      forceDeleteData,
      toggleForceDeleteModal,
      changeFilter,
    } = this.props;

    return (
      <Fragment>

        <TableView
          table={table}
          tableData={transportationTypesData}
          onGetTableData={this.handleGetTransportationTypes}
          onRestore={this.handleRestore}
          onEdit={this.handleEdit}
          onDelete={this.handleDelete}
          onCreate={this.handleCreate}
          onChangeFilter={changeFilter}
        />
        <Route
          exact
          path={`${match.path}/create`}
          render={
            (props) => <TransportationTypesCreateModal {...props} refreshParent={this.handleGetTransportationTypes} /> // eslint-disable-line
          }
        />
        <Route
          exact
          path={`${match.path}/edit/:id`}
          render={
            (props) => <TransportationTypesEditModal {...props} refreshParent={this.handleGetTransportationTypes} /> // eslint-disable-line
          }
        />
        <TransportationTypesDeleteCollisionModal
          modalData={forceDeleteModal}
          actionData={forceDeleteData}
          onClose={toggleForceDeleteModal}
          action={this.handleForceDelete}
        />

      </Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  const { transportationTypes, transportationTypesDetail, } = state;
  return {
    transportationTypesData: transportationTypes.transportationTypesData,
    table: transportationTypes.table,
    forceDeleteModal: transportationTypesDetail.forceDeleteModal,
    forceDeleteData: transportationTypesDetail.forceDeleteData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTransportationTypes: bindActionCreators(getTransportationTypesAction, dispatch),
  restoreTransportationTypesDetail: bindActionCreators(restoreTransportationTypesDetailAction, dispatch), // eslint-disable-line
  deleteTransportationTypesDetail: bindActionCreators(deleteTransportationTypesDetailAction, dispatch), // eslint-disable-line
  changeFilter: bindActionCreators(changeFilterAction, dispatch),
  forceDeleteTransportationTypesDetail: bindActionCreators(forceDeleteTransportationTypesDetailAction, dispatch), // eslint-disable-line
  toggleForceDeleteModal: bindActionCreators(toggleForceDeleteModalAction, dispatch),
});


TransportationTypesPage.propTypes = {
  table: shape({
    data: arrayOf(object).isRequired,
    count: number.isRequired,
    filter: object.isRequired,
  }).isRequired,
  transportationTypesData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  forceDeleteModal: shape({
    id: number,
    isOpen: bool.isRequired,
    reservations: object.isRequired,
    transports: object.isRequired,
  }).isRequired,
  forceDeleteData: object.isRequired,
  match: shape({
    path: string.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  changeFilter: func.isRequired,
  getTransportationTypes: func.isRequired,
  restoreTransportationTypesDetail: func.isRequired,
  deleteTransportationTypesDetail: func.isRequired,
  toggleForceDeleteModal: func.isRequired,
  forceDeleteTransportationTypesDetail: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(TransportationTypesPage);
