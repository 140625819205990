import styled from 'styled-components';


const StyledAddItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  button {
    position: relative;
    font-size: 0.85rem;
    font-weight: 700;
    border: 2px solid ${(p) => p.theme.grey.t800};
    background-color: white;
    padding: 5px;
  }

  button:focus {
    outline: none;
  }

  button:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.grey.t200};
  }

  button:disabled {
    cursor: default;
  }


  hr {
    width: 100%;
    margin-right: 10px;
    border: 0;
    border-top: 1px dashed ${(p) => p.theme.grey.t800};
  }
`;

export default StyledAddItem;
