import React, { Component, Fragment, } from 'react';
import {
  shape, bool, func, object, string,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  getDisplaysConfigurationDetailAction,
  updateDisplaysConfigurationDetailAction,
  validateFormAction,
  changeValueAction,
  changeValueInColumnAction,
  addColumnAction,
  removeColumnAction,
} from '../actions/displaysConfigurationDetail';
import { validateInput, validateWholeFormValidator, } from '../../../logic/forms/validation';
import DisplaysConfigurationEdit from '../components/DisplaysConfigurationEdit/DisplaysConfigurationEdit';
import Modal from '../../../atoms/Modal/Modal';
import DisplaysConfigurationDetail from '../components/DisplaysConfigurationDetail/DisplaysConfigurationDetail';


class DisplaysConfigurationEditModal extends Component {
  componentDidMount() {
    this.handleGetDetail();
  }

  handleGetDetail = () => {
    const { match, getDisplaysConfigurationDetail, } = this.props;
    getDisplaysConfigurationDetail(match.params.id);
  }

  handleClose = () => {
    const { history, } = this.props;
    history.goBack();
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);
    changeValue(name, value, validationError);
  }

  handleOnChangeValueInColumns = (parameter, value, index) => {
    const { changeValueInColumn, } = this.props;
    if (parameter === 'width') {
      changeValueInColumn(parameter, parseInt(value, 10), index);
    } else {
      changeValueInColumn(parameter, value, index);
    }
  }

  handleAddColumn = () => {
    const { addColumn, } = this.props;

    addColumn();
  }

  handleRemoveColumn = (index) => {
    const { removeColumn, } = this.props;

    removeColumn(index);
  }

  handleEdit = () => {
    const {
      updateDisplaysConfigurationData,
      detail,
      validateForm,
      updateDisplaysConfigurationDetail,
    } = this.props;

    if (updateDisplaysConfigurationData.isLoading || !detail.isValid) return;

    const validation = validateWholeFormValidator(detail.form, detail.values);
    if (Object.keys(validation).length < 1) {
      updateDisplaysConfigurationDetail(detail, this.handleEditSuccess);
    } else {
      validateForm(validation);
    }
  }

  handleEditSuccess = () => {
    const { refreshParent, } = this.props;
    refreshParent();
    this.handleClose();
  }

  render() {
    const {
      detailDisplaysConfigurationData,
      updateDisplaysConfigurationData,
      detail,
    } = this.props;

    return (
      <Fragment>

        <Modal
          isOpen
          onClose={this.handleClose}
          closeOnEsc
          size="MD"
          title="Detail konfigurace displejů"
        >
          <DisplaysConfigurationEdit
            // data
            detailDisplaysConfigurationData={detailDisplaysConfigurationData}
            updateDisplaysConfigurationData={updateDisplaysConfigurationData}
            isFormValid={detail.isValid}
            // methods
            handleClose={this.handleClose}
            handleEdit={this.handleEdit}
            onGetDetail={this.handleGetDetail}
          >
            <DisplaysConfigurationDetail
              // data
              values={detail.values}
              form={detail.form}
              // methods
              handleClose={this.handleClose}
              handleOnChangeValue={this.handleOnChangeValue}
              handleAddColumn={this.handleAddColumn}
              handleRemoveColumn={this.handleRemoveColumn}
              handleOnChangeValueInColumns={this.handleOnChangeValueInColumns}
            />
          </DisplaysConfigurationEdit>
        </Modal>

      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { displaysConfigurationDetail, } = state;
  return {
    detailDisplaysConfigurationData: displaysConfigurationDetail.detailDisplaysConfigurationData,
    updateDisplaysConfigurationData: displaysConfigurationDetail.updateDisplaysConfigurationData,
    detail: displaysConfigurationDetail.detail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDisplaysConfigurationDetail: bindActionCreators(getDisplaysConfigurationDetailAction, dispatch), // eslint-disable-line
  updateDisplaysConfigurationDetail: bindActionCreators(updateDisplaysConfigurationDetailAction, dispatch), // eslint-disable-line
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
  changeValueInColumn: bindActionCreators(changeValueInColumnAction, dispatch),
  addColumn: bindActionCreators(addColumnAction, dispatch),
  removeColumn: bindActionCreators(removeColumnAction, dispatch),
});


DisplaysConfigurationEditModal.propTypes = {
  detailDisplaysConfigurationData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  updateDisplaysConfigurationData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  detail: shape({
    isValid: bool.isRequired,
    form: object.isRequired,
    values: object.isRequired,
  }).isRequired,
  match: shape({
    params: shape({
      id: string.isRequired,
    }).isRequired,
  }).isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
  getDisplaysConfigurationDetail: func.isRequired,
  updateDisplaysConfigurationDetail: func.isRequired,
  refreshParent: func.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
  changeValueInColumn: func.isRequired,
  addColumn: func.isRequired,
  removeColumn: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(DisplaysConfigurationEditModal);
