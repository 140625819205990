import React, { Component, Fragment, } from 'react';
import { bool, func, number, } from 'prop-types';

import Button from '../Button/Button';


class Pagination extends Component {
  getCurrentPage = () => {
    const { offset, limit, } = this.props;

    return Math.trunc(offset / limit) + 1;
  }

  getLastPage = () => {
    const { count, limit, } = this.props;
    const offsetLast = count - 1;

    return Math.trunc(offsetLast / limit) + 1;
  }

  handleOnClickPage = (page) => {
    const { action, limit, } = this.props;

    action((page - 1) * limit);
  }

  render() {
    const { isLoading, } = this.props;
    const currentPage = this.getCurrentPage();
    const lastPage = this.getLastPage();
    const isDisabled = isLoading;

    return (
      <Fragment>
        {currentPage > 3 && (
          <Button
            shape="text"
            size="sm"
            noMinWidth
            disabled={isDisabled}
            onClick={() => this.handleOnClickPage(1)}
          >
            1
          </Button>
        )}

        {currentPage > 4 && (
          <span>...</span>
        )}

        {currentPage > 2 && (
          <Button
            shape="text"
            size="sm"
            noMinWidth
            disabled={isDisabled}
            onClick={() => this.handleOnClickPage(currentPage - 2)}
          >
            {currentPage - 2}
          </Button>
        )}

        {currentPage > 1 && (
          <Button
            shape="text"
            size="sm"
            noMinWidth
            disabled={isDisabled}
            onClick={() => this.handleOnClickPage(currentPage - 1)}
          >
            {currentPage - 1}
          </Button>
        )}

        <Button
          size="sm"
          noMinWidth
          onClick={() => {}}
          disabled={isDisabled}
        >
          {currentPage}
        </Button>

        {(lastPage > currentPage) && (
          <Button
            shape="text"
            size="sm"
            noMinWidth
            disabled={isDisabled}
            onClick={() => this.handleOnClickPage(currentPage + 1)}
          >
            {currentPage + 1}
          </Button>
        )}

        {(lastPage > currentPage + 1) && (
          <Button
            shape="text"
            size="sm"
            noMinWidth
            disabled={isDisabled}
            onClick={() => this.handleOnClickPage(currentPage + 2)}
          >
            {currentPage + 2}
          </Button>
        )}

        {(lastPage > currentPage + 3) && (
          <span>...</span>
        )}

        {(lastPage > currentPage + 2) && (
          <Button
            shape="text"
            size="sm"
            noMinWidth
            disabled={isDisabled}
            onClick={() => this.handleOnClickPage(lastPage)}
          >
            {lastPage}
          </Button>
        )}

      </Fragment>
    );
  }
}


Pagination.propTypes = {
  offset: number.isRequired,
  limit: number.isRequired,
  count: number.isRequired,
  isLoading: bool.isRequired,
  action: func.isRequired,
};


export default Pagination;
