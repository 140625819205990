import React from 'react';

import StyledSvg from './styles/StyledSvg';


const SortDesc = () => (
  <StyledSvg viewBox="0 0 401.998 401.998">
    <path
      d="M328.905,237.549H73.092c-4.952,0-9.233,1.808-12.85,5.421c-3.617,3.617-5.424,7.898-5.424,12.847
      c0,4.949,1.807,9.233,5.424,12.848L188.149,396.57c3.621,3.617,7.902,5.428,12.85,5.428s9.233-1.811,12.847-5.428l127.907-127.906
      c3.613-3.614,5.427-7.898,5.427-12.848c0-4.948-1.813-9.229-5.427-12.847C338.139,239.353,333.854,237.549,328.905,237.549z"
    />
  </StyledSvg>
);

export default SortDesc;
