import React, { Component, } from 'react';
import {
  shape, bool, func, object,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  createDisplaysConfigurationDetailAction,
  validateFormAction,
  changeValueAction,
  refreshDisplaysConfigurationDetailAction,
  addColumnAction,
  removeColumnAction,
  changeValueInColumnAction,
} from '../actions/displaysConfigurationDetail';
import {
  validateInput, validateWholeFormValidator,
} from '../../../logic/forms/validation';
import Modal from '../../../atoms/Modal/Modal';
import DisplaysConfigurationDetail from '../components/DisplaysConfigurationDetail/DisplaysConfigurationDetail';
import DisplaysConfigurationCreate from '../components/DisplaysConfigurationCreate/DisplaysConfigurationCreate';


class DisplaysConfigurationCreateModal extends Component {
  componentDidMount() {
    const {
      refreshDisplaysConfigurationDetail,
    } = this.props;

    refreshDisplaysConfigurationDetail();
  }

  handleClose = () => {
    const { history, } = this.props;

    history.goBack();
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);

    changeValue(name, value, validationError);
  }

  handleOnChangeValueInColumns = (parameter, value, index) => {
    const { changeValueInColumn, } = this.props;
    if (parameter === 'width') {
      changeValueInColumn(parameter, parseInt(value, 10), index);
    } else {
      changeValueInColumn(parameter, value, index);
    }
  }

  handleCreate = () => {
    const {
      createDisplaysConfigurationData,
      detail,
      validateForm,
      createDisplaysConfigurationDetail,
    } = this.props;

    if (createDisplaysConfigurationData.isLoading || !detail.isValid) return;

    const validationForm = validateWholeFormValidator(
      detail.form,
      detail.values,
    );

    if (Object.keys(validationForm).length < 1) {
      createDisplaysConfigurationDetail(detail, this.handleCreateSuccess);
    } else {
      validateForm(validationForm);
    }
  }

  handleCreateSuccess = () => {
    const { refreshParent, } = this.props;

    refreshParent();
    this.handleClose();
  }

  handleAddColumn = () => {
    const { addColumn, } = this.props;

    addColumn();
  }

  handleRemoveColumn = (index) => {
    const { removeColumn, } = this.props;

    removeColumn(index);
  }

  render() {
    const {
      createDisplaysConfigurationData,
      detail,
    } = this.props;

    return (
      <Modal
        isOpen
        onClose={this.handleClose}
        title="Vytvoření konfigurace displejů"
      >
        <DisplaysConfigurationCreate
          // data
          createDisplaysConfigurationData={createDisplaysConfigurationData}
          isFormValid={detail.isValid}
          // methods
          handleClose={this.handleClose}
          handleCreate={this.handleCreate}
        >
          <DisplaysConfigurationDetail
            // data
            values={detail.values}
            form={detail.form}
            // methods
            handleClose={this.handleClose}
            handleOnChangeValue={this.handleOnChangeValue}
            handleAddColumn={this.handleAddColumn}
            handleRemoveColumn={this.handleRemoveColumn}
            handleOnChangeValueInColumns={this.handleOnChangeValueInColumns}
          />
        </DisplaysConfigurationCreate>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { displaysConfigurationDetail, } = state;
  return {
    createDisplaysConfigurationData: displaysConfigurationDetail.createDisplaysConfigurationData,
    detail: displaysConfigurationDetail.detail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createDisplaysConfigurationDetail: bindActionCreators(createDisplaysConfigurationDetailAction, dispatch), // eslint-disable-line
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
  refreshDisplaysConfigurationDetail: bindActionCreators(
    refreshDisplaysConfigurationDetailAction,
    dispatch,
  ),
  addColumn: bindActionCreators(addColumnAction, dispatch),
  removeColumn: bindActionCreators(removeColumnAction, dispatch),
  changeValueInColumn: bindActionCreators(changeValueInColumnAction, dispatch),
});


DisplaysConfigurationCreateModal.propTypes = {
  createDisplaysConfigurationDetail: func.isRequired,
  refreshDisplaysConfigurationDetail: func.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
  refreshParent: func.isRequired,
  addColumn: func.isRequired,
  removeColumn: func.isRequired,
  changeValueInColumn: func.isRequired,
  createDisplaysConfigurationData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  detail: shape({
    isValid: bool.isRequired,
    form: object.isRequired,
    values: object.isRequired,
  }).isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(DisplaysConfigurationCreateModal);
