import React, { Component, } from 'react';
import {
  oneOfType, arrayOf, node, string, shape,
} from 'prop-types';
import { connect, } from 'react-redux';

import { PARAM_PREFIX, PARAM_POSTFIX, } from './langs';
import LangContext from './LangContext';


class LangProvider extends Component {
  langError = ({ code, params, }) => {
    const { langs, } = this.props;

    if (!code || code === '') {
      return '';
    }

    if (code === undefined || !Object.prototype.hasOwnProperty.call(langs.error, code)) {
      return langs.error.UNKNOWN;
    }

    return this.buildMessage(langs.error[code], params);
  }

  buildMessage = (message, params) => {
    const { langs, } = this.props;

    try {
      let errorMessage = message;

      const keys = Object.keys(params);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        errorMessage = errorMessage.split(`${PARAM_PREFIX}${key}${PARAM_POSTFIX}`).join(params[key]);
      }
      return errorMessage;
    } catch (err) {
      return langs.error.UNKNOWN;
    }
  }

  render() {
    const { children, } = this.props;

    return (
      <LangContext.Provider
        value={{
          langError: this.langError,
        }}
      >
        {children}
      </LangContext.Provider>
    );
  }
}


const mapStateToProps = (state) => {
  const { langs, } = state;
  return {
    langs,
  };
};


LangProvider.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
  langs: shape({
    error: shape({
      UNKNOWN: string.isRequired,
    }).isRequired,
  }).isRequired,
};


export default connect(mapStateToProps)(LangProvider);
