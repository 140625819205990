import React, { Fragment, } from 'react';
import { Switch, Route, } from 'react-router-dom';

import Heading from '../../../atoms/Heading/Heading';
import TabsLinkPanel from '../../../atoms/TabsLink/TabsLinkPanel';
import TabLink from '../../../atoms/TabsLink/TabLink';
import StandsPage from './StandsPage';
import StandAppConfigurationPage from './StandAppConfigurationPage';


const StandsLayout = () => (
  <Fragment>
    <Heading h4>
      Nastavení stanovišť
    </Heading>

    <TabsLinkPanel>
      <TabLink
        exact={false}
        to="/queue-config/stands/main"
      >
        Stanoviště
      </TabLink>
      <TabLink
        exact={false}
        to="/queue-config/stands/configuration"
      >
        Konfigurace aplikace
      </TabLink>
    </TabsLinkPanel>

    <Switch>
      <Route path="/queue-config/stands/main" component={StandsPage} />
      <Route path="/queue-config/stands/configuration" component={StandAppConfigurationPage} />
    </Switch>
  </Fragment>
);


export default StandsLayout;
