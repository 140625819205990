import React, { Component, Fragment, } from 'react';
import {
  func, object,
} from 'prop-types';

import FormRow from '../FormRow';
import InputSelectWrapper from '../../wrappers/InputSelectWrapper';


class GroupPart extends Component {
  renderInputsData = () => {
    const { part: { inputsData, }, } = this.props;

    if (inputsData.isLoading) {
      return <div>Loading</div>;
    }

    if (inputsData.error) {
      return <div>Error</div>;
    }

    return null;
  }

  render() {
    const {
      part,
      groupData,
      onGetGroups,
      onGetGroupInputs,
    } = this.props;
    const { form, values, } = part;


    return (
      <Fragment>
        <FormRow
          label="Formulář"
        >
          <InputSelectWrapper
            value={values.group}
            status={form.group.status}
            options={groupData.data}
            onChange={(value) => onGetGroupInputs(value)}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.id}
            isLoading={groupData.isLoading}
            error={groupData.error}
            fetchData={onGetGroups}
          />
        </FormRow>

        {this.renderInputsData()}
      </Fragment>
    );
  }
}


GroupPart.propTypes = {
  part: object.isRequired,
  groupData: object.isRequired,
  onGetGroups: func.isRequired,
  onGetGroupInputs: func.isRequired,
};


export default GroupPart;
