import styled from 'styled-components';

const StyledToolTip = styled.div`
  visibility: ${(p) => (p.isHover ? 'visible' : 'hidden')};
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  width: 180px;
  top: 100%;
  left: 50%; 
  margin-left: -90px;
`;

export default StyledToolTip;
