import { INPUT_TYPE, } from 'sofair-form-validation';

import {
  TRANSPORTATION_EDIT_FORMS__TOGGLE_MODAL_INPUT,
  TRANSPORTATION_EDIT_FORMS__MODAL_INPUT_CHANGE_FORM,
  TRANSPORTATION_EDIT_FORMS__MODAL_INPUT_VALIDATION,
  TRANSPORTATION_EDIT_FORMS__MODAL_INPUT_CONFIRM,
} from '../actions/transportationEditFormsModalInput';
import {
  initInput,
  changeFormValue,
} from '../../../logic/forms/validation';


const initialState = {
  modalData: {
    isOpen: false,
    position: null,
    isEdit: false,
  },
  typeOptions: [],
  inputForm: {
    isValid: true,
    invalidInputs: 0,
    form: {
      type: {
        ...initInput,
        type: INPUT_TYPE.SELECT,
        name: 'type',
        validation: {
          required: true,
          min: null,
          max: null,
          regex: null,
        },
      },
      required: {
        ...initInput,
        type: INPUT_TYPE.CHECKBOX,
        name: 'required',
      },
    },
    values: {
      type: null,
      required: false,
    },
  },
};


const reducer = (state = initialState, action) => {
  const { type, payload, } = action;

  switch (type) {
    /**
     * Toggle Modal Input
     */
    case TRANSPORTATION_EDIT_FORMS__TOGGLE_MODAL_INPUT: {
      return {
        ...state,
        modalData: {
          ...state.modalData,
          isOpen: payload.isOpen,
          isEdit: payload.isEdit,
          position: payload.position,
        },
        typeOptions: payload.typeOptions,
        inputForm: {
          ...state.inputForm,
          values: {
            ...state.inputForm.values,
            type: payload.isEdit
              ? payload.input
              : null,
            required: payload.isEdit
              ? payload.input.configuration.validation.required
              : false,
          },
        },
      };
    }


    /**
     * Change Form
     */
    case `${TRANSPORTATION_EDIT_FORMS__MODAL_INPUT_CHANGE_FORM}`: {
      const newFormData = changeFormValue(
        state.inputForm.form, state.inputForm.values, state.inputForm.invalidInputs, payload
      );

      return {
        ...state,
        inputForm: {
          ...state.inputForm,
          isValid: newFormData.invalidInputs < 1,
          ...newFormData,
        },
      };
    }


    /**
     * Validation
     */
    case TRANSPORTATION_EDIT_FORMS__MODAL_INPUT_VALIDATION: {
      return ({
        ...state,
        inputForm: {
          ...state.inputForm,
          isValid: false,
          invalidInputs: payload.invalidInputs,
          form: payload.form,
        },
      });
    }


    /**
     * Confirm
     */
    case TRANSPORTATION_EDIT_FORMS__MODAL_INPUT_CONFIRM: {
      return { ...initialState, };
    }

    default:
      return state;
  }
};

export default reducer;
