import styled from 'styled-components';

import media from '../../styles/media';


const css = {
  alignItems: {
    start: `
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    `,
    end: `
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    `,
    center: `
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    `,
    baseline: `
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    `,
    stretch: `
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    `,
  },
  justifyContent: {
    start: `
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    `,
    end: `
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    `,
    center: `
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    `,
    between: `
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    `,
    around: `
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    `,
  },
};


/**
 * Row
 *  - AlignItems - all / XS - UHD
 *  - JustifyContent - all / XS - UHD
 */
const Row = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -${(p) => p.theme.common.paddingHalf};
  margin-left: -${(p) => p.theme.common.paddingHalf};
  box-sizing: border-box;

  /* No Gutters */
  ${(p) => p.noGutters && `
    margin-right: 0;
    margin-left: 0;
  `}

  /* FormGroup */
  ${(p) => p.formGroup && `
    margin-bottom: ${p.theme.formGroup.marginBottom}
  `}
  
  /* All */
  ${(p) => p.alignItems && css.alignItems[p.alignItems]}
  ${(p) => p.justifyContent && css.justifyContent[p.justifyContent]}

  /* XS */
  ${(p) => p.XS_alignItems && media.XS`${css.alignItems[p.XS_alignItems]}`}
  ${(p) => p.XS_justifyContent && media.XS`${css.justifyContent[p.XS_justifyContent]}`}

  /* SM */
  ${(p) => p.SM_alignItems && media.SM`${css.alignItems[p.SM_alignItems]}`}
  ${(p) => p.SM_justifyContent && media.SM`${css.justifyContent[p.SM_justifyContent]}`}

  /* MD */
  ${(p) => p.MD_alignItems && media.MD`${css.alignItems[p.MD_alignItems]}`}
  ${(p) => p.MD_justifyContent && media.MD`${css.justifyContent[p.MD_justifyContent]}`}

  /* LG */
  ${(p) => p.LG_alignItems && media.LG`${css.alignItems[p.LG_alignItems]}`}
  ${(p) => p.LG_justifyContent && media.LG`${css.justifyContent[p.LG_justifyContent]}`}

  /* HD */
  ${(p) => p.HD_alignItems && media.HD`${css.alignItems[p.HD_alignItems]}`}
  ${(p) => p.HD_justifyContent && media.HD`${css.justifyContent[p.HD_justifyContent]}`}

  /* HDplus */
  ${(p) => p.HDplus_alignItems && media.HDplus`${css.alignItems[p.HDplus_alignItems]}`}
  ${(p) => p.HDplus_justifyContent && media.HDplus`${css.justifyContent[p.HDplus_justifyContent]}`}

  /* FHD */
  ${(p) => p.FHD_alignItems && media.FHD`${css.alignItems[p.FHD_alignItems]}`}
  ${(p) => p.FHD_justifyContent && media.FHD`${css.justifyContent[p.FHD_justifyContent]}`}

  /* QHD */
  ${(p) => p.QHD_alignItems && media.QHD`${css.alignItems[p.QHD_alignItems]}`}
  ${(p) => p.QHD_justifyContent && media.QHD`${css.justifyContent[p.QHD_justifyContent]}`}

  /* QHDplus */
  ${(p) => p.QHDplus_alignItems && media.QHDplus`${css.alignItems[p.QHDplus_alignItems]}`}
  ${(p) => p.QHDplus_justifyContent && media.QHDplus`${css.justifyContent[p.QHDplus_justifyContent]}`}

  /* UHD */
  ${(p) => p.UHD_alignItems && media.UHD`${css.alignItems[p.UHD_alignItems]}`}
  ${(p) => p.UHD_justifyContent && media.UHD`${css.justifyContent[p.UHD_justifyContent]}`}

`;

export default Row;
