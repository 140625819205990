import uuidv4 from 'uuid/v4';

import { APP__NOTIF_REMOVE, } from './actions';
import {
  TRANSPORTATION_TYPES__CREATE_TRANSPORTATION_TYPES_DETAIL,
  TRANSPORTATION_TYPES__UPDATE_TRANSPORTATION_TYPES_DETAIL,
  TRANSPORTATION_TYPES__FORCE_UPDATE_TRANSPORTATION_TYPES_DETAIL,
  TRANSPORTATION_TYPES__DELETE_TRANSPORTATION_TYPES_DETAIL,
  TRANSPORTATION_TYPES__FORCE_DELETE_TRANSPORTATION_TYPES_DETAIL,
  TRANSPORTATION_TYPES__RESTORE_TRANSPORTATION_TYPES_DETAIL,
} from '../../modules/TransportationTypes/actions/transportationTypesDetail';
import {
  NUMERICAL_SERIES__CREATE_DETAIL,
  NUMERICAL_SERIES__UPDATE_DETAIL,
} from '../../modules/TransportationTypes/actions/numericalSeriesDetail';
import {
  NUMERICAL_SERIES__DELETE_DETAIL,
} from '../../modules/TransportationTypes/actions/numericalSeries';
import {
  PLATFORMS__DELETE_PLATFORMS_DETAIL,
  PLATFORMS__FORCE_DELETE_PLATFORMS_DETAIL,
  PLATFORMS__RESTORE_PLATFORMS_DETAIL,
  PLATFORMS__CREATE_PLATFORMS_DETAIL,
  PLATFORMS__UPDATE_PLATFORMS_DETAIL,
  PLATFORMS__FORCE_UPDATE_PLATFORMS_DETAIL,
} from '../../modules/Platforms/actions/platformsDetail';
import {
  QUEUE_CONFIG__CREATE_ACTIONS_DETAIL,
  QUEUE_CONFIG__UPDATE_ACTIONS_DETAIL,
  QUEUE_CONFIG__DELETE_ACTIONS_DETAIL,
} from '../../modules/QueueConfig/actions/actionsDetail';
import {
  DEVICES__CREATE_KIOSKS_CONFIGURATION_DETAIL,
  DEVICES__UPDATE_KIOSKS_CONFIGURATION_DETAIL,
  DEVICES__DELETE_KIOSKS_CONFIGURATION_DETAIL,
} from '../../modules/Devices/actions/kiosksConfigurationDetail';
import {
  QUEUE_CONFIG__CREATE_STANDS_DETAIL,
  QUEUE_CONFIG__UPDATE_STANDS_DETAIL,
  QUEUE_CONFIG__DELETE_STANDS_DETAIL,
} from '../../modules/QueueConfig/actions/standsDetail';
import {
  QUEUE_CONFIG__CREATE_STAND_APP_CONFIGURATION_DETAIL,
  QUEUE_CONFIG__UPDATE_STAND_APP_CONFIGURATION_DETAIL,
  QUEUE_CONFIG__DELETE_STAND_APP_CONFIGURATION_DETAIL,
} from '../../modules/QueueConfig/actions/standAppConfigurationDetail';
import {
  DEVICES__UPDATE_KIOSKS_DETAIL,
  DEVICES__DELETE_KIOSKS_DETAIL,
} from '../../modules/Devices/actions/kiosksDetail';
import {
  LANGUAGES__CREATE_LANGUAGES_DETAIL,
  LANGUAGES__UPDATE_LANGUAGES_DETAIL,
  LANGUAGES__DELETE_LANGUAGES_DETAIL,
} from '../../modules/Languages/actions/languagesDetail';
import {
  LANGUAGES__UPDATE_LANGUAGES_SETTINGS,
} from '../../modules/Languages/actions/languagesSettings';
import {
  GENERIC_FORMS__CREATE_GEN_FORM,
  GENERIC_FORMS__UPDATE_GEN_FORM,
  GENERIC_FORMS__DELETE_GENERIC_FORMS_DETAIL,
} from '../../modules/GenericForms/actions/genericFormsDetail';
import { TRANSPORTATION_TYPES__UPDATE_MAPPED_FORMS, } from '../../modules/TransportationTypes/actions/mapForms';
import {
  DEVICES__UPDATE_CONTROLLERS_DETAIL,
  DEVICES__DELETE_CONTROLLERS_DETAIL,
} from '../../modules/Devices/actions/controllersDetail';
import {
  DEVICES__UPDATE_DISPLAYS_IN_EDITOR,
} from '../../modules/Devices/actions/displayEditor';
import {
  DEVICES__UPDATE_DISPLAYS_DETAIL,
} from '../../modules/Devices/actions/displaysDetail';
import {
  DEVICES__DELETE_DISPLAYS_DETAIL,
} from '../../modules/Devices/actions/displays';
import {
  DEVICES__CREATE_DISPLAYS_CONFIGURATION_DETAIL,
  DEVICES__DELETE_DISPLAYS_CONFIGURATION_DETAIL,
  DEVICES__UPDATE_DISPLAYS_CONFIGURATION_DETAIL,
} from '../../modules/Devices/actions/displaysConfigurationDetail';
import {
  DEVICES__UPDATE_STOREKEEPER_DEVICE_DETAIL,
} from '../../modules/Devices/actions/storekeeperDetail';
import {
  STOREKEEPER_CONFIGURATION__UPDATE_SETTINGS,
} from '../../modules/Devices/actions/storekeeperConfiguration';
import {
  FULFILLED, REJECTED,
} from '../../globals';
import { initNotif, } from './utils';


const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;
  switch (type) {
    /**
     * Add Notification
     */
    case `${DEVICES__UPDATE_KIOSKS_DETAIL}__${FULFILLED}`:
    case `${DEVICES__DELETE_KIOSKS_DETAIL}__${FULFILLED}`:
    case `${DEVICES__DELETE_KIOSKS_DETAIL}__${REJECTED}`:
    case `${DEVICES__UPDATE_CONTROLLERS_DETAIL}__${FULFILLED}`:
    case `${DEVICES__DELETE_CONTROLLERS_DETAIL}__${FULFILLED}`:
    case `${DEVICES__DELETE_CONTROLLERS_DETAIL}__${REJECTED}`:
    case `${DEVICES__UPDATE_DISPLAYS_IN_EDITOR}__${FULFILLED}`:
    case `${PLATFORMS__CREATE_PLATFORMS_DETAIL}__${FULFILLED}`:
    case `${PLATFORMS__UPDATE_PLATFORMS_DETAIL}__${FULFILLED}`:
    case `${PLATFORMS__FORCE_UPDATE_PLATFORMS_DETAIL}__${FULFILLED}`:
    case `${PLATFORMS__DELETE_PLATFORMS_DETAIL}__${FULFILLED}`:
    case `${PLATFORMS__DELETE_PLATFORMS_DETAIL}__${REJECTED}`:
    case `${PLATFORMS__FORCE_DELETE_PLATFORMS_DETAIL}__${FULFILLED}`:
    case `${PLATFORMS__RESTORE_PLATFORMS_DETAIL}__${FULFILLED}`:
    case `${PLATFORMS__RESTORE_PLATFORMS_DETAIL}__${REJECTED}`:
    case `${QUEUE_CONFIG__DELETE_ACTIONS_DETAIL}__${FULFILLED}`:
    case `${QUEUE_CONFIG__CREATE_ACTIONS_DETAIL}__${FULFILLED}`:
    case `${QUEUE_CONFIG__UPDATE_ACTIONS_DETAIL}__${FULFILLED}`:
    case `${QUEUE_CONFIG__DELETE_STANDS_DETAIL}__${FULFILLED}`:
    case `${QUEUE_CONFIG__CREATE_STANDS_DETAIL}__${FULFILLED}`:
    case `${QUEUE_CONFIG__UPDATE_STANDS_DETAIL}__${FULFILLED}`:
    case `${DEVICES__CREATE_KIOSKS_CONFIGURATION_DETAIL}__${FULFILLED}`:
    case `${DEVICES__UPDATE_KIOSKS_CONFIGURATION_DETAIL}__${FULFILLED}`:
    case `${DEVICES__DELETE_KIOSKS_CONFIGURATION_DETAIL}__${FULFILLED}`:
    case `${DEVICES__UPDATE_STOREKEEPER_DEVICE_DETAIL}__${FULFILLED}`:
    case `${STOREKEEPER_CONFIGURATION__UPDATE_SETTINGS}__${FULFILLED}`:
    case `${DEVICES__UPDATE_DISPLAYS_DETAIL}__${FULFILLED}`:
    case `${DEVICES__DELETE_DISPLAYS_DETAIL}__${FULFILLED}`:
    case `${DEVICES__CREATE_DISPLAYS_CONFIGURATION_DETAIL}__${FULFILLED}`:
    case `${DEVICES__DELETE_DISPLAYS_CONFIGURATION_DETAIL}__${FULFILLED}`:
    case `${DEVICES__UPDATE_DISPLAYS_CONFIGURATION_DETAIL}__${FULFILLED}`:
    case `${DEVICES__CREATE_DISPLAYS_CONFIGURATION_DETAIL}__${REJECTED}`:
    case `${DEVICES__DELETE_DISPLAYS_CONFIGURATION_DETAIL}__${REJECTED}`:
    case `${DEVICES__UPDATE_DISPLAYS_CONFIGURATION_DETAIL}__${REJECTED}`:
    case `${LANGUAGES__CREATE_LANGUAGES_DETAIL}__${FULFILLED}`:
    case `${LANGUAGES__UPDATE_LANGUAGES_DETAIL}__${FULFILLED}`:
    case `${LANGUAGES__DELETE_LANGUAGES_DETAIL}__${FULFILLED}`:
    case `${LANGUAGES__UPDATE_LANGUAGES_SETTINGS}__${FULFILLED}`:
    case `${LANGUAGES__UPDATE_LANGUAGES_SETTINGS}__${REJECTED}`:
    case `${TRANSPORTATION_TYPES__CREATE_TRANSPORTATION_TYPES_DETAIL}__${FULFILLED}`:
    case `${TRANSPORTATION_TYPES__UPDATE_TRANSPORTATION_TYPES_DETAIL}__${FULFILLED}`:
    case `${TRANSPORTATION_TYPES__FORCE_UPDATE_TRANSPORTATION_TYPES_DETAIL}__${FULFILLED}`:
    case `${TRANSPORTATION_TYPES__DELETE_TRANSPORTATION_TYPES_DETAIL}__${FULFILLED}`:
    case `${TRANSPORTATION_TYPES__DELETE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED}`:
    case `${TRANSPORTATION_TYPES__FORCE_DELETE_TRANSPORTATION_TYPES_DETAIL}__${FULFILLED}`:
    case `${TRANSPORTATION_TYPES__RESTORE_TRANSPORTATION_TYPES_DETAIL}__${FULFILLED}`:
    case `${TRANSPORTATION_TYPES__RESTORE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED}`:
    case `${TRANSPORTATION_TYPES__UPDATE_MAPPED_FORMS}__${FULFILLED}`:
    case `${NUMERICAL_SERIES__CREATE_DETAIL}__${FULFILLED}`:
    case `${NUMERICAL_SERIES__UPDATE_DETAIL}__${FULFILLED}`:
    case `${NUMERICAL_SERIES__DELETE_DETAIL}__${FULFILLED}`:
    case `${NUMERICAL_SERIES__DELETE_DETAIL}__${REJECTED}`:
    case `${GENERIC_FORMS__CREATE_GEN_FORM}__${FULFILLED}`:
    case `${GENERIC_FORMS__UPDATE_GEN_FORM}__${FULFILLED}`:
    case `${QUEUE_CONFIG__CREATE_STAND_APP_CONFIGURATION_DETAIL}__${FULFILLED}`:
    case `${QUEUE_CONFIG__UPDATE_STAND_APP_CONFIGURATION_DETAIL}__${FULFILLED}`:
    case `${QUEUE_CONFIG__DELETE_STAND_APP_CONFIGURATION_DETAIL}__${FULFILLED}`:
    case `${QUEUE_CONFIG__DELETE_STAND_APP_CONFIGURATION_DETAIL}__${REJECTED}`:
    case `${GENERIC_FORMS__DELETE_GENERIC_FORMS_DETAIL}__${FULFILLED}`: {
      const newData = {
        ...initNotif,
        ...payload.notif,
      };

      if (newData.showMessage) {
        newData.message = payload.message;
      }

      return {
        ...state,
        data: [
          ...state.data,
          {
            id: uuidv4(),
            ...newData,
          },
        ],
      };
    }

    /**
     * REMOVE Notification
     */
    case `${APP__NOTIF_REMOVE}`: {
      return {
        ...state,
        data: state.data.filter((item) => item.id !== payload.id),
      };
    }

    default:
      return state;
  }
};

export default reducer;
