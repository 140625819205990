import React from 'react';
import {
  object, func, bool, string,
} from 'prop-types';

import MSLList from './MSLList';
import { BOX_VAL, BOX_OPT, } from './consts';
import StyledMSL from './styles/StyledMSL';
import { FORM_DEFAULT, } from '../../globals';

import Button from '../Button/Button';

const MSLView = ({
  leftValue,
  rightValue,
  selectLeft,
  selectRight,
  isLoading,
  error,
  errorMessage,
  getDataMessage,
  placeholder,
  status,
  // actions
  handleFetchData,
  getOptionLabel,
  getOptionValue,
  handleSelect,
  handleRemove,
  handleAdd,
}) => (
  <StyledMSL>
    <MSLList
      data={leftValue}
      selection={selectLeft}
      status={status}
      mainList
      errorMessage={errorMessage}
      getDataMessage={getDataMessage}
      placeholder={placeholder}
      // actions
      onSelect={(newVals) => handleSelect(newVals, BOX_VAL)}
      onDoubleClick={handleRemove}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
    />

    <div className="msl--center-panel">
      <Button
        style={{ padding: '0.375rem 0', }}
        width="1.5rem"
        type="button"
        shape="outline"
        onClick={() => handleAdd(selectRight)}
        disabled={isLoading || error}
      >
        {'<'}
      </Button>

      <Button
        style={{ padding: '0.375rem 0', }}
        width="1.5rem"
        type="button"
        shape="outline"
        onClick={() => handleRemove(selectLeft)}
        disabled={isLoading || error}
      >
        {'>'}
      </Button>

      <div />
    </div>

    <MSLList
      data={rightValue}
      selection={selectRight}
      isLoading={isLoading}
      error={error}
      status={FORM_DEFAULT}
      errorMessage={errorMessage}
      getDataMessage={getDataMessage}
      placeholder={placeholder}
      // actions
      handleFetchData={handleFetchData}
      onSelect={(newVals) => handleSelect(newVals, BOX_OPT)}
      onDoubleClick={handleAdd}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
    />
  </StyledMSL>
);


MSLView.propTypes = {
  getOptionLabel: func.isRequired,
  getOptionValue: func.isRequired,
  leftValue: object.isRequired,
  rightValue: object.isRequired,
  selectLeft: object.isRequired,
  selectRight: object.isRequired,
  handleSelect: func.isRequired,
  handleRemove: func.isRequired,
  handleAdd: func.isRequired,
  isLoading: bool.isRequired,
  error: bool.isRequired,
  errorMessage: string.isRequired,
  getDataMessage: string.isRequired,
  placeholder: string.isRequired,
  status: string.isRequired,
  handleFetchData: func.isRequired,
};


export default MSLView;
