import React from 'react';

import StyledSvg from './styles/StyledSvg';


const MenuClose = () => (
  <StyledSvg viewBox="0 0 95 95">
    <path d="M60,23V2c0-1.105-0.896-2-2-2H37c-1.104,0-2,0.895-2,2v21c0,1.105,0.896,2,2,2h21C59.104,25,60,24.105,60,23z M60,58V37c0-1.105-0.896-2-2-2H37c-1.104,0-2,0.895-2,2v21c0,1.105,0.896,2,2,2h21C59.104,60,60,59.105,60,58z M60,93V72c0-1.105-0.896-2-2-2H37c-1.104,0-2,0.895-2,2v21c0,1.105,0.896,2,2,2h21C59.104,95,60,94.105,60,93z" />
  </StyledSvg>
);

export default MenuClose;
