import uuidv4 from 'uuid/v4';

import {
  changePartTypeLogic, makeNewStructure, addPartLogic, remapMapValue,
} from './utils';
import { initialState, } from './reducerMethods';
import { FORM_ROOT, FORM_TYPES, } from '../../globals';
import { groupCommonProps, fieldsetCommonProps, } from './inputStructure';


const parseInput = (input, id, mapNames) => {
  const { type, index, ...reducedInput } = input;

  return changePartTypeLogic(
    id,
    input.type,
    {
      ...reducedInput,
      map: remapMapValue(input.map, mapNames),
    },
  );
};


const parseInputToPart = (data, name, mapNameToIds) => {
  const {
    inputs,
    structure,
    translation,
  } = data;

  const { type, } = structure[name];
  const newId = mapNameToIds[name];

  switch (type) {
    case FORM_TYPES.NUMBER:
    case FORM_TYPES.TEXT:
    case FORM_TYPES.CHECKBOX:
    case FORM_TYPES.RADIO:
    case FORM_TYPES.SELECT:
    case FORM_TYPES.MULTISELECT:
    case FORM_TYPES.TIME:
    case FORM_TYPES.DATE:
    case FORM_TYPES.TIMESTAMP:
      return parseInput(inputs[name], newId, mapNameToIds);

    case FORM_TYPES.GROUP:
      return {
        ...addPartLogic(newId),
        type,
        ...groupCommonProps,
        values: {
          ...groupCommonProps.values,
          group: structure[name].data,
        },
      };

    case FORM_TYPES.FIELDSET:
      return {
        ...addPartLogic(newId),
        type,
        ...fieldsetCommonProps,
        values: {
          ...fieldsetCommonProps.values,
          ...translation.texts[name],
        },
      };

    default:
      return addPartLogic(newId);
  }
};


const convertArrayToObject = (array, id = 'id') => {
  const newObject = {};

  for (let i = 0; i < array.length; i++) {
    newObject[array[i][id]] = {
      ...array[i],
    };
  }

  return newObject;
};


//
// Parse Gen Form
//
export const parsefromApi = (data) => {
  const {
    inputs,
    structure,
    translation,
  } = data;

  const inputsObj = convertArrayToObject(inputs, 'name');

  const newParts = {};
  const newStructure = {};
  const mapNameToIds = {};

  // 1 - map name to ids object
  mapNameToIds[FORM_ROOT] = FORM_ROOT;

  const iterateToMap = [ ...structure[FORM_ROOT].childs, ];
  while (iterateToMap.length > 0) {
    const tmpNameIterate = iterateToMap.pop();

    iterateToMap.push(...structure[tmpNameIterate].childs);
    mapNameToIds[tmpNameIterate] = uuidv4();
  }

  // 2 - iterate parts
  //   - first insert Root to structure and parts
  //   - start with root childs. because root doesn't have part
  const iterateIds = [ ...structure[FORM_ROOT].childs, ];

  // ROOT - structure
  newStructure[FORM_ROOT] = {
    ...structure[FORM_ROOT],
    childs: structure[FORM_ROOT].childs.map((item) => mapNameToIds[item]),
  };

  // ROOT - parts
  newParts[FORM_ROOT] = {
    ...initialState.parts[FORM_ROOT],
    values: {
      ...initialState.parts[FORM_ROOT].values,
      title: data.name,
    },
  };

  // iterate other parts and structure
  while (iterateIds.length > 0) {
    const tmpName = iterateIds.pop();
    const tmpId = mapNameToIds[tmpName];

    iterateIds.push(...structure[tmpName].childs);

    // parts
    newParts[tmpId] = parseInputToPart(
      {
        inputs: inputsObj,
        structure,
        translation,
      },
      tmpName,
      mapNameToIds
    );

    // structure
    newStructure[tmpId] = makeNewStructure({
      ...structure[tmpName],
      id: tmpId,
      // FIX
      parent: mapNameToIds[structure[tmpName].parent],
      childs: structure[tmpName].childs.map((item) => mapNameToIds[item]),
    });
  }

  // 3 - return
  return {
    ...initialState,
    id: data.id,
    lang: data.lang,
    parts: newParts,
    structure: newStructure,
  };
};


//
// Parse Group Inputs From Api
//
export const parseGroupInputsFromApi = (inputs, parent) => {
  const childs = [];
  const newParts = {};
  const mapNames = {};
  const newStructure = {};

  // craete mapNames object to map new ids to names
  for (let i = 0; i < inputs.length; i++) {
    const tmpName = inputs[i].name;
    const newId = uuidv4();

    childs.push(newId);
    mapNames[tmpName] = newId;
  }

  // make parts
  for (let j = 0; j < inputs.length; j++) {
    const tmpNewPartId = mapNames[inputs[j].name];
    newParts[tmpNewPartId] = parseInput(inputs[j], tmpNewPartId, mapNames);
    newStructure[tmpNewPartId] = makeNewStructure({
      id: tmpNewPartId,
      parent,
      type: inputs[j].type,
    });
  }

  return {
    childs,
    newParts,
    newStructure,
  };
};
