import { SyncValidator, INPUT_TYPE, } from 'sofair-form-validation';
import { FORM_ERROR, FORM_DEFAULT, } from '../../globals';


const defaultValidation = {
  required: null,
  min: null,
  max: null,
  regex: null,
};


/**
 * Basic Form Structure
 */
export const initInput = {
  name: 'default',
  type: INPUT_TYPE.TEXT,
  validation: {
    ...defaultValidation,
  },
  status: FORM_DEFAULT,
  info: '',
  statusMsg: {
    code: '',
    params: {},
  },
};


/**
 * Validate Whole Form
 * @param {*} form
 * @param {*} values
 */
export const validateWholeFormValidator = (form, values) => (
  SyncValidator.validateForm(form, values)
);


//
// validate Whole Form + logic
//
export const validateWholeForm = (data) => {
  const { form, values, } = data;
  const newForm = { ...form, };
  let newInvalidInputs = 0;

  const validationErr = validateWholeFormValidator(form, values);
  const validationErrKeys = Object.keys(validationErr);

  for (let i = 0; i < validationErrKeys.length; i++) {
    const key = validationErrKeys[i];

    if (Object.prototype.hasOwnProperty.call(form, key)) {
      newInvalidInputs++;
      newForm[key] = {
        ...newForm[key],
        status: FORM_ERROR,
        statusMsg: validationErr[key],
      };
    }
  }

  return {
    ...data,
    isValid: newInvalidInputs < 1,
    invalidInputs: newInvalidInputs,
    form: newForm,
  };
};


/**
 * Validate input
 * @param {Object} input object of input
 * @param {*} value value of input
 */
export const validateInput = (input, value) => {
  const valErr = SyncValidator.validateInput(input, value);

  if (valErr === undefined) {
    return {};
  }
  return {
    [valErr.name]: {
      params: valErr.params,
      code: valErr.code,
    },
  };
};


/**
 * Change form and apply validation
 * @param {Object} form
 * @param {Object} values
 * @param {Number} invalidInputs
 * @param {Object} payload - value, name, validationError
 */
export const changeFormValue = (form, values, invalidInputs, payload) => {
  const isValid = !Object.prototype.hasOwnProperty.call(payload.validationError, payload.name);
  const newStatus = isValid ? FORM_DEFAULT : FORM_ERROR;
  const newStatusMsg = isValid
    ? initInput.statusMsg
    : { ...payload.validationError[payload.name], };

  let newInvalidInputs = invalidInputs;

  if (newStatus !== form[payload.name].status) {
    if (newStatus === FORM_ERROR) newInvalidInputs++;
    else newInvalidInputs--;
  }

  return {
    isValid: newInvalidInputs < 1,
    invalidInputs: newInvalidInputs,
    form: {
      ...form,
      [payload.name]: {
        ...form[payload.name],
        status: newStatus,
        statusMsg: newStatusMsg,
      },
    },
    values: {
      ...values,
      [payload.name]: payload.value,
    },
  };
};


/**
 * Merge validation object into form
 * @param {Object} form redux form
 * @param {Object} validation error from server
 */
export const mergeFormAndValidation = (form, validation) => {
  const validationKeys = Object.keys(validation);
  const data = { ...form, };
  let invalidInputs = 0;

  for (let i = 0; i < validationKeys.length; i++) {
    const key = validationKeys[i];

    if (Object.prototype.hasOwnProperty.call(form, key)) {
      invalidInputs++;
      data[key] = {
        ...data[key],
        status: FORM_ERROR,
        statusMsg: validation[key],
      };
    }
  }

  return {
    form: data,
    invalidInputs,
  };
};
