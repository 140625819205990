import { ConfigValidator, ValidationError, } from 'sofair-config-validations';
import { validateWholeFormValidator, } from '../../../logic/forms/validation';


// validate whole detail and apply action validation
// - detail: object
export const validateTransTypesDetail = (detail) => (
  new Promise(async (resolve) => {
    const validationAction = {};

    // validate actions
    try {
      const parsedActions = detail.values.plan.map((item) => item.payload);

      await ConfigValidator.validate(parsedActions);
    } catch (err) {
      if (err instanceof ValidationError) {
        validationAction.plan = { ...err, };
      } else {
        validationAction.plan = {
          code: 'something',
          params: {},
        };
      }
    }

    // validate form inputs
    const validation = validateWholeFormValidator(detail.form, detail.values);
    resolve({
      ...validationAction,
      ...validation,
    });
  })
);
