import styled from 'styled-components';
import { FORM_STRUCTURE_GROUP, } from '../../../globals';


const styleActive = (type, theme) => `
  border: 2px solid ${theme.primary.t800};
`;

const styleGroup = (type, theme) => `
  border: 2px solid ${theme.grey.t800};
`;


const styleType = (type, theme) => {
  switch (type) {
    case FORM_STRUCTURE_GROUP:
      return styleGroup(type, theme);
    default:
      return styleActive(type, theme);
  }
};

const StyledNodeRow = styled.div`
  margin: 10px;
  min-width: 70%;
  ${(p) => p.type && styleType(p.type, p.theme)}
`;

export default StyledNodeRow;
