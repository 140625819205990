import {
  PENDING, FULFILLED, REJECTED, FORM_ERROR, FORM_SUCCESS,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


/*
 * GET Cameras
 */
export const DEVICES__GET_CAMERAS = 'DEVICES__GET_CAMERAS';
export const getCamerasAction = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__GET_CAMERAS}__${PENDING}`,
      payload: filter,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/devices/cameras/filter`,
      data: filter,
    });

    dispatch({
      type: `${DEVICES__GET_CAMERAS}__${FULFILLED}`,
      payload: response.data,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${DEVICES__GET_CAMERAS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * DELETE Camera
 */
export const DEVICES__DELETE_CAMERA_DETAIL = 'DEVICES__DELETE_CAMERA_DETAIL';
export const deleteCameraAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__DELETE_CAMERA_DETAIL}__${PENDING}`,
      payload: {
        id,
      },
    });

    const response = await api({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/devices/cameras?id=${id}`,
    });

    dispatch({
      type: `${DEVICES__DELETE_CAMERA_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        id,
        notif: {
          type: FORM_SUCCESS,
          title: 'Kamera úspěšně smazána',
        },
      },
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${DEVICES__DELETE_CAMERA_DETAIL}__${REJECTED}`,
      payload: {
        ...parsedError,
        id,
        notif: {
          type: FORM_ERROR,
          title: 'Nepovedlo se smazat kameru',
          showMessage: true,
        },
      },
    });
  }
};

/**
 * Filter Changed
 */
export const DEVICES__CAMERAS_FILTER_CHANGED = 'DEVICES__CAMERAS_FILTER_CHANGED';

export const changeCamerasFilterAction = (filter) => ({
  type: `${DEVICES__CAMERAS_FILTER_CHANGED}`,
  payload: filter,
});
