import { INPUT_TYPE, } from 'sofair-form-validation';

import {
  DEVICES__GET_DISPLAYS_DETAIL,
  DEVICES__UPDATE_DISPLAYS_DETAIL,
  DEVICES__DISPLAYS_VALIDATE_FORM_DETAIL,
  DEVICES__DISPLAYS_CHANGE_VALUE_DETAIL,
  DEVICES__DISPLAYS_REFRESH,
} from '../actions/displaysDetail';
import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';
import {
  mergeFormAndValidation, initInput, changeFormValue,
} from '../../../logic/forms/validation';


const initialState = {
  detailDisplaysData: {
    ...initRestData,
    isLoading: true,
  },
  updateDisplaysData: {
    ...initRestData,
  },
  deleteDisplaysData: {
    ...initRestData,
  },
  detail: {
    id: null,
    isValid: true,
    invalidInputs: 0,
    form: {
      name: {
        ...initInput,
        name: 'name',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: true,
          min: 2,
          max: 255,
        },
      },
      subtype: {
        ...initInput,
        name: 'subtype',
        type: INPUT_TYPE.SELECT,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
      displayConfigurationId: {
        ...initInput,
        name: 'displayConfigurationId',
        type: INPUT_TYPE.SELECT,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
    },
    values: {
      name: '',
      type: '',
      subtype: '',
      width: 0,
      height: 0,
      posX: 0,
      posY: 0,
      displayConfigurationId: null,
      displayControllerName: '',
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;

  switch (type) {
    /**
     * Refresh Data
     */
    case `${DEVICES__DISPLAYS_REFRESH}`: {
      return {
        ...state,
        detail: {
          ...initialState.detail,
        },
      };
    }


    /**
     * Change Form
     */
    case `${DEVICES__DISPLAYS_CHANGE_VALUE_DETAIL}`: {
      const newFormData = changeFormValue(
        state.detail.form, state.detail.values, state.detail.invalidInputs, payload
      );

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: newFormData.invalidInputs < 1,
          invalidInputs: newFormData.invalidInputs,
          form: newFormData.form,
          values: newFormData.values,
        },
      };
    }


    /**
     * Validate form
     */
    case `${DEVICES__DISPLAYS_VALIDATE_FORM_DETAIL}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload);

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }


    /**
     * GET Displays Detail
     */
    case `${DEVICES__GET_DISPLAYS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        detailDisplaysData: {
          ...state.detailDisplaysData,
          ...(handlePending()),
        },
        detail: {
          ...initialState.detail,
        },
      };
    }

    case `${DEVICES__GET_DISPLAYS_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        detailDisplaysData: {
          ...state.detailDisplaysData,
          ...(handleFulfilled(payload)),
        },
        detail: {
          ...state.detail,
          id: payload.data.id,
          values: {
            ...state.detail.values,
            name: payload.data.name,
            type: payload.data.type,
            subtype: payload.data.subtype,
            width: payload.data.width,
            height: payload.data.height,
            posX: payload.data.posX,
            posY: payload.data.posY,
            displayConfigurationId: payload.data.displayConfigurationId,
            displayControllerName: payload.data.displayController.name,
          },
        },
      };
    }

    case `${DEVICES__GET_DISPLAYS_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        detailDisplaysData: {
          ...state.detailDisplaysData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * UPDATE Displays Detail
     */
    case `${DEVICES__UPDATE_DISPLAYS_DETAIL}__${PENDING}`: {
      return {
        ...state,
        updateDisplaysData: {
          ...state.updateDisplaysData,
          ...(handlePending()),
        },
      };
    }

    case `${DEVICES__UPDATE_DISPLAYS_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        updateDisplaysData: {
          ...state.updateDisplaysData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${DEVICES__UPDATE_DISPLAYS_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data.data);

      return {
        ...state,
        updateDisplaysData: {
          ...state.updateDisplaysData,
          ...(handleFulfilled(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${DEVICES__UPDATE_DISPLAYS_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        updateDisplaysData: {
          ...state.updateDisplaysData,
          ...(handleRejected(payload)),
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
