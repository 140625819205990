import React, { Fragment, } from 'react';
import {
  shape, func, object,
} from 'prop-types';

import { withLang, } from '../../../../logic/languages/withLang';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';
import InputSelectWrapper from '../../../../components/wrappers/InputSelectWrapper';

import { FORM_ERROR, FORM_SUCCESS, } from '../../../../globals';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import SegmentLoading from '../../../../components/SegmentLaoding/SegmentLaoding';
import SegmentError from '../../../../components/SegmentError/SegmentError';
import Pencil from '../../../../styles/icons/Pencil';

const LanguagesSettingsForm = ({
  // data
  getData,
  updateData,
  form,
  values,
  languagesData,
  // methods
  onChangeValue,
  langError,
  onEdit,
  onGetDetail,
  isFormValid,
  getLanguagesShared,
}) => {
  if (getData.isLoading) {
    return <SegmentLoading formGroup />;
  }
  if (getData.error) {
    return (
      <SegmentError
        formGroup
        message={getData.message}
        refresh={onGetDetail}
      />
    );
  }

  return (
    <Fragment>
      <Row formGroup>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
          Výchozí jazyk systému
        </Col>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
          <InputSelectWrapper
            value={values.defaultLang}
            status={form.defaultLang.status}
            // options={KIOSKS_CONFIGURATION_UNTIL} todo shared
            onChange={(value) => onChangeValue(form.defaultLang.name, value)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            options={languagesData.data}
            isLoading={languagesData.isLoading}
            error={languagesData.error}
            fetchData={getLanguagesShared}
          />
        </Col>
        <Col MD_offset={3} LG_offset={6} sidePadding>
          <InfoMsg
            status={form.defaultLang.status}
            msg={langError(form.defaultLang.statusMsg)}
            showIcon
          />
        </Col>
      </Row>

      <Row formGroup>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
          Výchozí jazyk kiosků
        </Col>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
          <InputSelectWrapper
            value={values.kioskDefaultLang}
            status={form.kioskDefaultLang.status}
            // options={KIOSKS_CONFIGURATION_UNTIL} todo shared
            onChange={(value) => onChangeValue(form.kioskDefaultLang.name, value)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            options={languagesData.data}
            isLoading={languagesData.isLoading}
            error={languagesData.error}
            fetchData={getLanguagesShared}
          />
        </Col>
        <Col MD_offset={3} LG_offset={6} sidePadding>
          <InfoMsg
            status={form.kioskDefaultLang.status}
            msg={langError(form.kioskDefaultLang.statusMsg)}
            showIcon
          />
        </Col>
      </Row>

      <Row formGroup>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
          Dostupné jazyky kiosků
        </Col>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
          <InputSelectWrapper
            value={values.kioskLangs}
            status={form.kioskLangs.status}
            // options={KIOSKS_CONFIGURATION_UNTIL} todo shared
            isMulti
            onChange={(value) => onChangeValue(form.kioskLangs.name, value)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            options={languagesData.data}
            isLoading={languagesData.isLoading}
            error={languagesData.error}
            fetchData={getLanguagesShared}
          />
        </Col>
        <Col MD_offset={3} LG_offset={6} sidePadding>
          <InfoMsg
            status={form.kioskLangs.status}
            msg={langError(form.kioskLangs.statusMsg)}
            showIcon
          />
        </Col>
      </Row>

      <Row formGroup>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
          Výchozí jazyk centrální aplikace
        </Col>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
          <InputSelectWrapper
            value={values.centralAppDefaultLang}
            status={form.centralAppDefaultLang.status}
            // options={KIOSKS_CONFIGURATION_UNTIL} todo shared
            onChange={(value) => onChangeValue(form.centralAppDefaultLang.name, value)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            options={languagesData.data}
            isLoading={languagesData.isLoading}
            error={languagesData.error}
            fetchData={getLanguagesShared}
          />
        </Col>
        <Col MD_offset={3} LG_offset={6} sidePadding>
          <InfoMsg
            status={form.centralAppDefaultLang.status}
            msg={langError(form.centralAppDefaultLang.statusMsg)}
            showIcon
          />
        </Col>
      </Row>

      <Row formGroup>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
          Dostupné jazyky centrální aplikace
        </Col>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
          <InputSelectWrapper
            value={values.centralAppLangs}
            status={form.centralAppLangs.status}
            // options={KIOSKS_CONFIGURATION_UNTIL} todo shared
            isMulti
            onChange={(value) => onChangeValue(form.centralAppLangs.name, value)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            options={languagesData.data}
            isLoading={languagesData.isLoading}
            error={languagesData.error}
            fetchData={getLanguagesShared}
          />
        </Col>
        <Col MD_offset={3} LG_offset={6} sidePadding>
          <InfoMsg
            status={form.centralAppLangs.status}
            msg={langError(form.centralAppLangs.statusMsg)}
            showIcon
          />
        </Col>
      </Row>

      <Row formGroup>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
          Výchozí jazyk aplikace skladník
        </Col>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
          <InputSelectWrapper
            value={values.storekeeperAppDefaultLang}
            status={form.storekeeperAppDefaultLang.status}
            // options={KIOSKS_CONFIGURATION_UNTIL} todo shared
            onChange={(value) => onChangeValue(form.storekeeperAppDefaultLang.name, value)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            options={languagesData.data}
            isLoading={languagesData.isLoading}
            error={languagesData.error}
            fetchData={getLanguagesShared}
          />
        </Col>
        <Col MD_offset={3} LG_offset={6} sidePadding>
          <InfoMsg
            status={form.storekeeperAppDefaultLang.status}
            msg={langError(form.storekeeperAppDefaultLang.statusMsg)}
            showIcon
          />
        </Col>
      </Row>

      <Row formGroup>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
          Dostupné jazyky aplikace skladník
        </Col>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
          <InputSelectWrapper
            value={values.storekeeperAppLangs}
            status={form.storekeeperAppLangs.status}
            // options={KIOSKS_CONFIGURATION_UNTIL} todo shared
            isMulti
            onChange={(value) => onChangeValue(form.storekeeperAppLangs.name, value)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            options={languagesData.data}
            isLoading={languagesData.isLoading}
            error={languagesData.error}
            fetchData={getLanguagesShared}
          />
        </Col>
        <Col MD_offset={3} LG_offset={6} sidePadding>
          <InfoMsg
            status={form.storekeeperAppLangs.status}
            msg={langError(form.storekeeperAppLangs.statusMsg)}
            showIcon
          />
        </Col>
      </Row>

      <Row>
        <Col textAlign="right">
          <InfoMsg
            status={updateData.error ? FORM_ERROR : FORM_SUCCESS}
            msg={langError(updateData.message)}
            showIcon
          />
        </Col>
        <Col textAlign="right">
          <ButtonLoader
            type="button"
            color="primary"
            onClick={onEdit}
            disabled={!isFormValid}
            isLoading={updateData.isLoading}
          >
            <span className="btn--icon">
              <Pencil />
            </span>
            Upravit
          </ButtonLoader>
        </Col>
      </Row>
    </Fragment>
  );
};


LanguagesSettingsForm.propTypes = {
  getData: object.isRequired,
  updateData: object.isRequired,
  form: shape({
    defaultLang: object.isRequired,
    kioskDefaultLang: object.isRequired,
    kioskLangs: object.isRequired,
    centralAppDefaultLang: object.isRequired,
    centralAppLangs: object.isRequired,
    storekeeperAppDefaultLang: object.isRequired,
    storekeeperAppLangs: object.isRequired,
  }).isRequired,
  values: object.isRequired,
  languagesData: object.isRequired,
  onChangeValue: func.isRequired,
  onEdit: func.isRequired,
  onGetDetail: func.isRequired,
  isFormValid: func.isRequired,
  langError: func.isRequired,
  getLanguagesShared: func.isRequired,
};


export default withLang(LanguagesSettingsForm);
