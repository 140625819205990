import React, { Component, Fragment, } from 'react';
import {
  shape, bool, func, object,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import StorekeeperConfigurationForm from '../components/StorekeeperConfigurationForm/StorekeeperConfigurationForm';
import {
  changeValueAction,
  validateFormAction,
  getStorekeeperConfigurationAction,
  updateStorekeeperConfigurationAction,
} from '../actions/storekeeperConfiguration';
import { validateInput, validateWholeFormValidator, } from '../../../logic/forms/validation';


class StorekeeperConfigurationPage extends Component {
  componentDidMount() {
    const {
      getStorekeeperConfiguration,
    } = this.props;
    getStorekeeperConfiguration();
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);
    changeValue(name, value, validationError);
  }

  handleEdit = () => {
    const {
      updateStorekeeperConfiguration,
      detail,
      updateData,
      validateForm,
    } = this.props;

    if (updateData.isLoading || !detail.isValid) return;

    const validation = validateWholeFormValidator(detail.form, detail.values);
    if (Object.keys(validation).length < 1) {
      updateStorekeeperConfiguration(detail, this.handleEditSuccess);
    } else {
      validateForm(validation);
    }
  }

  render() {
    const {
      // data
      detail,
      getData,
      updateData,
      // methods
      validateForm,
    } = this.props;

    return (
      <Fragment>
        <StorekeeperConfigurationForm
          form={detail.form}
          getData={getData}
          updateData={updateData}
          values={detail.values}
          onChangeValue={this.handleOnChangeValue}
          onEdit={this.handleEdit}
          isFormValid={validateForm}
        />
      </Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  const { storekeeperConfiguration, } = state;
  return {
    getData: storekeeperConfiguration.getData,
    updateData: storekeeperConfiguration.updateData,
    detail: storekeeperConfiguration.detail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
  getStorekeeperConfiguration: bindActionCreators(getStorekeeperConfigurationAction, dispatch),
  updateStorekeeperConfiguration: bindActionCreators(
    updateStorekeeperConfigurationAction,
    dispatch
  ),
});


StorekeeperConfigurationPage.propTypes = {
  getData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  updateData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  detail: object.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
  getStorekeeperConfiguration: func.isRequired,
  updateStorekeeperConfiguration: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(StorekeeperConfigurationPage);
