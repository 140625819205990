import React, { Component, } from 'react';
import {
  shape, bool, func, object, string,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  getControllersDetailAction,
  updateControllersDetailAction,
  validateFormAction,
  changeValueAction,
} from '../actions/controllersDetail';

import {
  validateInput, validateWholeFormValidator,
} from '../../../logic/forms/validation';
import ControllersRegister from '../components/ControllersRegister/ControllersRegister';
import Modal from '../../../atoms/Modal/Modal';
import ControllersDetail from '../components/ControllersDetail/ControllersDetail';


class ControllersRegisterModal extends Component {
  componentDidMount() {
    this.handleGetDetail();
  }

  handleGetDetail = () => {
    const { match, getControllersDetail, } = this.props;

    getControllersDetail(match.params.id);
  }

  handleClose = () => {
    const { history, } = this.props;

    history.goBack();
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);

    changeValue(name, value, validationError);
  }

  handleEdit = () => {
    const {
      updateControllersData,
      detail,
      validateForm,
      updateControllersDetail,
    } = this.props;

    if (updateControllersData.isLoading || !detail.isValid) return;

    const validation = validateWholeFormValidator(detail.form, detail.values);
    if (Object.keys(validation).length < 1) {
      updateControllersDetail(detail, this.handleEditSuccess);
    } else {
      validateForm(validation);
    }
  }

  handleEditSuccess = () => {
    const { refreshParent, } = this.props;

    refreshParent();
    this.handleClose();
  }

  render() {
    const {
      // data
      detailControllersData,
      updateControllersData,
      detail,
      // methods
    } = this.props;
    return (
      <Modal
        isOpen
        onClose={this.handleClose}
        closeOnEsc
        size="MD"
        title="Registrace Disp. kontroléru"
      >
        <ControllersRegister
          // data
          detailControllersData={detailControllersData}
          updateControllersData={updateControllersData}
          isFormValid={detail.isValid}
          // methods
          handleClose={this.handleClose}
          handleEdit={this.handleEdit}
          onGetDetail={this.handleGetDetail}
        >
          <ControllersDetail
            // data
            values={detail.values}
            form={detail.form}
            // methods
            handleClose={this.handleClose}
            handleOnChangeValue={this.handleOnChangeValue}
          />
        </ControllersRegister>

      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { controllersDetail, } = state;
  return {
    detailControllersData: controllersDetail.detailControllersData,
    updateControllersData: controllersDetail.updateControllersData,
    detail: controllersDetail.detail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getControllersDetail: bindActionCreators(getControllersDetailAction, dispatch), // eslint-disable-line
  updateControllersDetail: bindActionCreators(updateControllersDetailAction, dispatch), // eslint-disable-line
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
});


ControllersRegisterModal.propTypes = {
  getControllersDetail: func.isRequired,
  refreshParent: func.isRequired,
  updateControllersDetail: func.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
  detailControllersData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  updateControllersData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  detail: shape({
    isValid: bool.isRequired,
    form: object.isRequired,
    values: object.isRequired,
  }).isRequired,
  match: shape({
    params: shape({
      id: string.isRequired,
    }).isRequired,
  }).isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(ControllersRegisterModal);
