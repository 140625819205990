import { PENDING, FULFILLED, REJECTED, } from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


/*
 * GET Platforms
 */
export const PLATFORMS__GET_PLATFORMS = 'PLATFORMS__GET_PLATFORMS';
export const getPlatformsAction = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: `${PLATFORMS__GET_PLATFORMS}__${PENDING}`,
      payload: filter,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/platforms/filter`,
      data: filter,
    });

    dispatch({
      type: `${PLATFORMS__GET_PLATFORMS}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${PLATFORMS__GET_PLATFORMS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/**
 * Filter Changed
 */
export const PLATFORMS__FILTER_CHANGED = 'PLATFORMS__FILTER_CHANGED';
export const changeFilterAction = (filter) => ({
  type: `${PLATFORMS__FILTER_CHANGED}`,
  payload: filter,
});
