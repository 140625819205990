import styled from 'styled-components';


const RTBody = styled.ul`
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 7rem;

  li {
    border-bottom: 1px solid ${(p) => p.theme.grey.t300};
  }

  li:first-child {
    border-top: 1px solid ${(p) => p.theme.grey.t300};
  }

  li:nth-child(odd) {
    background: ${(p) => p.theme.grey.t100};
  }

  li:hover {
    background: ${(p) => p.theme.grey.t200};
  }
`;


export default RTBody;
