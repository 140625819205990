import {
  QUEUE_CONFIG__GET_ACTIONS,
  QUEUE_CONFIG__ACTIONS_FILTER_CHANGED,
} from '../actions/actions';
import {
  QUEUE_CONFIG__DELETE_ACTIONS_DETAIL,
} from '../actions/actionsDetail';
import { PENDING, FULFILLED, REJECTED, } from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';


const initialState = {
  actionsData: {
    ...initRestData,
    isLoading: true,
  },
  table: {
    data: [],
    count: 0,
    filter: {
      offset: 0,
      limit: 10,
      sortBy: 'id',
      order: 'ASC',
      params: {
        id: '',
        name: '',
      },
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;
  switch (type) {
    /**
     * GET Actions
     */
    case `${QUEUE_CONFIG__GET_ACTIONS}__${PENDING}`: {
      return {
        ...state,
        actionsData: {
          ...state.actionsData,
          ...(handlePending()),
        },
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    case `${QUEUE_CONFIG__GET_ACTIONS}__${FULFILLED}`: {
      return {
        ...state,
        actionsData: {
          ...state.actionsData,
          ...(handleFulfilled(payload)),
        },
        table: {
          data: payload.actions.map((row) => ({
            ...row,
            name: row.actionTranslation.name,
          })),
          count: payload.count,
          filter: payload.filter,
        },
      };
    }

    case `${QUEUE_CONFIG__GET_ACTIONS}__${REJECTED}`: {
      return {
        ...state,
        actionsData: {
          ...state.actionsData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * DELETE Action
     */
    case `${QUEUE_CONFIG__DELETE_ACTIONS_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.filter((item) => item.id !== payload.id),
        },
      };
    }


    /**
     * Change Filter
     */
    case `${QUEUE_CONFIG__ACTIONS_FILTER_CHANGED}`: {
      return {
        ...state,
        table: {
          ...state.table,
          filter: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
