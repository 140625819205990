import React, { Fragment, } from 'react';
import {
  shape, func, object,
} from 'prop-types';

import { withLang, } from '../../../../logic/languages/withLang';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Input from '../../../../atoms/Input/Input';
import Button from '../../../../atoms/Button/Button';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';
import InputSelectWrapper from '../../../../components/wrappers/InputSelectWrapper';
import InputColor from '../../../../atoms/InputColor/InputColor';
import CheckBox from '../../../../atoms/CheckBox/CheckBox';
import {
  DISPLAYS_CONFIGURATION_TYPES,
  DISPLAYS_COLUMN_PARAMETERS,
} from '../../../../globals';

import Add from '../../../../styles/icons/Add';
import Trash from '../../../../styles/icons/Trash';
import Divider from '../../../../atoms/Divider/Divider';

const DISABLE_ALPHA = true;

const DisplaysConfigurationDetail = ({
  // data
  values,
  form,
  // methods
  handleOnChangeValue,
  handleAddColumn,
  handleOnChangeValueInColumns,
  handleRemoveColumn,
  langError,
}) => (
  <Fragment>
    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Název
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="text"
          value={values.name}
          status={form.name.status}
          onChange={(e) => handleOnChangeValue(form.name.name, e.target.value)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.name.status}
          msg={langError(form.name.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Typ
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <InputSelectWrapper
          value={
            Object.hasOwnProperty.call(
              DISPLAYS_CONFIGURATION_TYPES, values.type
            ) ? DISPLAYS_CONFIGURATION_TYPES[values.type] : null
          }
          status={form.type.status}
          options={DISPLAYS_CONFIGURATION_TYPES}
          onChange={(value) => handleOnChangeValue(
            form.type.name, value === null ? null : value.id
          )}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.type.status}
          msg={langError(form.type.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Počet sekund pro řádek
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="number"
          value={values.hideTimeout}
          status={form.hideTimeout.status}
          onChange={(e) => handleOnChangeValue(form.hideTimeout.name, e.target.value)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.hideTimeout.status}
          msg={langError(form.hideTimeout.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <CheckBox
          id={form.hideTransportationAfterTimeout.name}
          text="Schovávat řádky po timeoutu"
          checked={values.hideTransportationAfterTimeout}
          onChange={() => handleOnChangeValue(
            form.hideTransportationAfterTimeout.name,
            !values.hideTransportationAfterTimeout
          )}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.hideTransportationAfterTimeout.status}
          msg={langError(form.hideTransportationAfterTimeout.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Velikost písma
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="number"
          value={values.fontSize}
          status={form.fontSize.status}
          onChange={(e) => handleOnChangeValue(form.fontSize.name, e.target.value)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.fontSize.status}
          msg={langError(form.fontSize.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Počet řádků
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <Input
          type="number"
          value={values.noRows}
          status={form.noRows.status}
          onChange={(e) => handleOnChangeValue(form.noRows.name, e.target.value)}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.noRows.status}
          msg={langError(form.noRows.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} sidePadding SM_textAlign="left" LG_textAlign="right" />
      <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
        <CheckBox
          id={form.lineDivider.name}
          text="Oddělovač řádků"
          checked={values.lineDivider}
          onChange={() => handleOnChangeValue(
            form.lineDivider.name,
            !values.lineDivider
          )}
        />
      </Col>
      <Col MD_offset={3} LG_offset={6} sidePadding>
        <InfoMsg
          status={form.lineDivider.status}
          msg={langError(form.lineDivider.statusMsg)}
          showIcon
        />
      </Col>
    </Row>

    { values.lineDivider ? (
      <Fragment>
        <Row formGroup>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
            Tloušťka oddělovače
          </Col>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
            <Input
              type="number"
              value={values.dividerHeight}
              status={form.dividerHeight.status}
              onChange={(e) => handleOnChangeValue(form.dividerHeight.name, e.target.value)}
            />
          </Col>
          <Col MD_offset={3} LG_offset={6} sidePadding>
            <InfoMsg
              status={form.dividerHeight.status}
              msg={langError(form.dividerHeight.statusMsg)}
              showIcon
            />
          </Col>
        </Row>

        <Row formGroup>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
            Barva oddělovače
          </Col>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
            <InputColor
              type="text"
              formName={form.dividerColor.name}
              value={values.dividerColor}
              status={form.dividerColor.status}
              disableAlpha={DISABLE_ALPHA}
              onChange={handleOnChangeValue}
            />
          </Col>
          <Col MD_offset={3} LG_offset={6} sidePadding>
            <InfoMsg
              status={form.dividerColor.status}
              msg={langError(form.dividerColor.statusMsg)}
              showIcon
            />
          </Col>
        </Row>
      </Fragment>
    ) : '' }

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={4} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Sloupečky
      </Col>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={18} sidePadding>
        <Divider />
        <InfoMsg
          status={form.columns.status}
          msg={langError(form.columns.statusMsg)}
          showIcon
        />
      </Col>
    </Row>
    { values.columns.map((value, index) => (
      <Fragment key={`id_${index + 1}`}>
        <Row formGroup>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
            {`Sloupec: (${index + 1})`}
          </Col>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding LG_textAlign="right">
            <Button
              size="sm"
              color="error"
              onClick={() => handleRemoveColumn(index)}
            >
              <span className="btn--icon">
                <Trash />
              </span>
            </Button>
          </Col>
        </Row>
        <Row formGroup>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
            Parametr sloupce
          </Col>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
            <InputSelectWrapper
              value={
                Object.hasOwnProperty.call(
                  DISPLAYS_COLUMN_PARAMETERS, value.parameter
                ) ? DISPLAYS_COLUMN_PARAMETERS[value.parameter] : null
              }
              status={form.columns.status}
              options={DISPLAYS_COLUMN_PARAMETERS}
              onChange={(valuex) => handleOnChangeValueInColumns(
                'parameter', valuex === null ? null : valuex.id, index,
              )}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
          </Col>
        </Row>
        <Row formGroup>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
            Barva sloupce
          </Col>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
            <InputColor
              type="text"
              formName={form.columns.name}
              value={value.color}
              status={form.columns.status}
              disableAlpha={DISABLE_ALPHA}
              onChange={(name, newValue) => handleOnChangeValueInColumns('color', newValue, index)}
            />
          </Col>
        </Row>
        <Row formGroup>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
            Šířka sloupce
          </Col>
          <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
            <Input
              type="number"
              formName={form.columns.name}
              value={value.width}
              status={form.columns.status}
              onChange={(e) => handleOnChangeValueInColumns('width', e.target.value, index)}
            />
          </Col>
        </Row>
        <Row formGroup>
          <Col MD_offset={3} MD={18} LG_offset={2} LG={20} sidePadding>
            <Divider />
          </Col>
        </Row>
      </Fragment>
    ))}

    <Row formGroup>
      <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        <Button onClick={handleAddColumn}>
          <span className="btn--icon">
            <Add />
          </span>
        </Button>
      </Col>
    </Row>


  </Fragment>
);


DisplaysConfigurationDetail.propTypes = {
  form: shape({
    name: object.isRequired,
    type: object.isRequired,
  }).isRequired,
  values: object.isRequired,
  handleOnChangeValue: func.isRequired,
  handleAddColumn: func.isRequired,
  handleOnChangeValueInColumns: func.isRequired,
  handleRemoveColumn: func.isRequired,
  langError: func.isRequired,
};


export default withLang(DisplaysConfigurationDetail);
