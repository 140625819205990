import { INPUT_TYPE, } from 'sofair-form-validation';

import {
  TRANSPORTATION_EDIT_FORMS__TOGGLE_DELETE_DETAIL_MODAL,
  TRANSPORTATION_EDIT_FORMS__DELETE_DETAIL,
  TRANSPORTATION_EDIT_FORMS__GET_DETAIL,
  TRANSPORTATION_EDIT_FORMS__REFRESH,
  TRANSPORTATION_EDIT_FORMS__CHANGE_FORM,
  TRANSPORTATION_EDIT_FORMS__OPTIONS,
  TRANSPORTATION_EDIT_FORMS__VALIDATION,
  TRANSPORTATION_EDIT_FORMS__CREATE,
  TRANSPORTATION_EDIT_FORMS__EDIT,
} from '../actions/transportationEditFormsDetail';
import {
  TRANSPORTATION_EDIT_FORMS__MODAL_INPUT_CONFIRM,
} from '../actions/transportationEditFormsModalInput';
import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';
import {
  initInput,
  changeFormValue,
  mergeFormAndValidation,
  validateInput,
} from '../../../logic/forms/validation';


const initialState = {
  optionsData: {
    ...initRestData,
    isLoading: true,
    options: [],
  },
  detailData: {
    ...initRestData,
    isLoading: true,
  },
  createData: {
    ...initRestData,
  },
  editData: {
    ...initRestData,
  },
  deleteData: {
    ...initRestData,
  },
  modalDelete: {
    id: null,
    isOpen: false,
  },
  detail: {
    id: null,
    isValid: true,
    invalidInputs: 0,
    form: {
      name: {
        ...initInput,
        name: 'name',
        validation: {
          ...initInput.validation,
          required: true,
        },
      },
      transportationEditFormInputs: {
        ...initInput,
        name: 'transportationEditFormInputs',
        type: INPUT_TYPE.MULTISELECT,
        validation: {
          ...initInput.validation,
          required: true,
        },
      },
    },
    values: {
      name: '',
      transportationEditFormInputs: [],
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;

  switch (type) {
    /**
     * Refresh Data
     */
    case `${TRANSPORTATION_EDIT_FORMS__REFRESH}`: {
      return {
        ...initialState,
      };
    }


    /**
     * Change Form
     */
    case `${TRANSPORTATION_EDIT_FORMS__CHANGE_FORM}`: {
      const newFormData = changeFormValue(
        state.detail.form, state.detail.values, state.detail.invalidInputs, payload
      );

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: newFormData.invalidInputs < 1,
          ...newFormData,
        },
      };
    }


    /**
     * Invalid Form
     */
    case TRANSPORTATION_EDIT_FORMS__VALIDATION: {
      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: payload.invalidInputs,
          form: payload.form,
        },
      };
    }


    /**
     * Change Input From Input Modal
     */
    case TRANSPORTATION_EDIT_FORMS__MODAL_INPUT_CONFIRM: {
      const {
        position,
        isEdit,
        newInput,
      } = payload;

      const newValue = [
        ...state.detail.values.transportationEditFormInputs.slice(0, position),
        newInput,
        ...state.detail.values.transportationEditFormInputs.slice(
          isEdit ? (position + 1) : position
        ),
      ];
      const { name, } = state.detail.form.transportationEditFormInputs;
      const validationError = validateInput(
        name,
        newValue,
      );

      const newFormData = changeFormValue(
        state.detail.form,
        state.detail.values,
        state.detail.invalidInputs,
        {
          name,
          value: newValue,
          validationError,
        },
      );

      return {
        ...state,
        detail: {
          ...state.detail,
          ...newFormData,
        },
      };
    }


    /**
     * Get Options
     */
    case `${TRANSPORTATION_EDIT_FORMS__OPTIONS}__${PENDING}`: {
      return {
        ...state,
        optionsData: {
          ...initialState.optionsData,
          ...(handlePending()),
        },
      };
    }

    case `${TRANSPORTATION_EDIT_FORMS__OPTIONS}__${FULFILLED}`: {
      return {
        ...state,
        optionsData: {
          ...initialState.optionsData,
          ...(handleFulfilled(payload)),
          options: payload.data,
          filteredOptions: [],
        },
      };
    }

    case `${TRANSPORTATION_EDIT_FORMS__OPTIONS}__${REJECTED}`: {
      return {
        ...state,
        optionsData: {
          ...state.optionsData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * CREATE
     */
    case `${TRANSPORTATION_EDIT_FORMS__CREATE}__${PENDING}`: {
      return {
        ...state,
        createData: {
          ...state.createData,
          ...(handlePending()),
        },
      };
    }

    case `${TRANSPORTATION_EDIT_FORMS__CREATE}__${FULFILLED}`: {
      return {
        ...state,
        createData: {
          ...state.createData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${TRANSPORTATION_EDIT_FORMS__CREATE}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data);

      return {
        ...state,
        createData: {
          ...state.createData,
          ...(handleRejected(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${TRANSPORTATION_EDIT_FORMS__CREATE}__${REJECTED}`: {
      return {
        ...state,
        createData: {
          ...state.createData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * Edit
     */
    case `${TRANSPORTATION_EDIT_FORMS__EDIT}__${PENDING}`: {
      return {
        ...state,
        editData: {
          ...state.editData,
          ...(handlePending()),
        },
      };
    }

    case `${TRANSPORTATION_EDIT_FORMS__EDIT}__${FULFILLED}`: {
      return {
        ...state,
        editData: {
          ...state.editData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${TRANSPORTATION_EDIT_FORMS__EDIT}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data);

      return {
        ...state,
        editData: {
          ...state.editData,
          ...(handleRejected(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${TRANSPORTATION_EDIT_FORMS__EDIT}__${REJECTED}`: {
      return {
        ...state,
        editData: {
          ...state.editData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
     * GET Detail
     */
    case `${TRANSPORTATION_EDIT_FORMS__GET_DETAIL}__${PENDING}`: {
      return {
        ...state,
        detailData: {
          ...initialState.detailData,
          ...(handlePending()),
        },
      };
    }

    case `${TRANSPORTATION_EDIT_FORMS__GET_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        detailData: {
          ...state.detailData,
          ...(handleFulfilled(payload)),
        },
        detail: {
          ...state.detail,
          id: payload.data.id,
          values: {
            ...state.detail.values,
            transportationEditFormInputs: payload.data.transportationEditFormInputs,
            name: payload.data.name,
          },
        },
      };
    }

    case `${TRANSPORTATION_EDIT_FORMS__GET_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        detailData: {
          ...state.detailData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
     * DELETE Detail
     */
    case `${TRANSPORTATION_EDIT_FORMS__DELETE_DETAIL}__${PENDING}`: {
      return {
        ...state,
        deleteData: {
          ...state.deleteData,
          ...(handlePending()),
        },
      };
    }

    case `${TRANSPORTATION_EDIT_FORMS__DELETE_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        deleteData: {
          ...state.deleteData,
          ...(handleFulfilled(payload)),
        },
        modalDelete: {
          ...initialState.modalDelete,
        },
      };
    }

    case `${TRANSPORTATION_EDIT_FORMS__DELETE_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        deleteData: {
          ...state.deleteData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
     * TOGGLE delete modal
     */
    case `${TRANSPORTATION_EDIT_FORMS__TOGGLE_DELETE_DETAIL_MODAL}`: {
      return {
        ...state,
        modalDelete: {
          ...initialState.modalDelete,
          isOpen: payload.isOpen,
          id: payload.id,
        },
        deleteData: initialState.deleteData,
      };
    }

    default:
      return state;
  }
};

export default reducer;
