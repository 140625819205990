import { isValidDate, } from '../date/date';
import { ErrorLocalStorage, } from '../errors/errors';


const AUTH_ACCESS = 'AUTH_ACCESS';
const AUTH_REFRESH = 'AUTH_REFRESH';
const AUTH_EXPIRES = 'AUTH_EXPIRES';
const AUTH_AUTHORITY = 'AUTH_AUTHORITY';


/**
 * Save user and tokens to LocalStorage
 * @param {*} object access, refresh, valid, user
 */
export const setAuth = ({
  access, refresh, expires, authority,
}) => {
  let validUntil = new Date();
  validUntil = new Date(validUntil.getTime() + (1000 * expires) - (1000 * 60));

  if (!isValidDate(validUntil) || validUntil < new Date()) {
    throw new ErrorLocalStorage('Failed parsing expires token');
  }

  localStorage.setItem(AUTH_ACCESS, access);
  localStorage.setItem(AUTH_REFRESH, refresh);
  localStorage.setItem(AUTH_EXPIRES, validUntil);
  localStorage.setItem(AUTH_AUTHORITY, JSON.stringify(authority));
};


/**
 * Clear LocalStorage
 */
export const clearLS = () => {
  localStorage.removeItem(AUTH_ACCESS);
  localStorage.removeItem(AUTH_REFRESH);
  localStorage.removeItem(AUTH_EXPIRES);
  localStorage.removeItem(AUTH_AUTHORITY);
};


/**
 * Check user and get user authority
 */
export const checkUserAndGetAuthority = () => {
  const access = localStorage.getItem(AUTH_ACCESS);
  const refresh = localStorage.getItem(AUTH_REFRESH);
  const validUntil = localStorage.getItem(AUTH_EXPIRES);
  const authority = localStorage.getItem(AUTH_AUTHORITY);

  if (access === null || refresh === null || validUntil === null || authority === null) {
    return null;
  }
  const dateValidUntil = new Date(validUntil);
  if (!isValidDate(dateValidUntil)) {
    throw new ErrorLocalStorage('Failed parsing expires token');
  }
  return authority;
};


/**
 * Check if Token is still valid
 */
export const isTokenValid = () => {
  const validUntil = localStorage.getItem(AUTH_EXPIRES);

  const dateValidUntil = new Date(validUntil);
  if (!isValidDate(dateValidUntil)) {
    throw new ErrorLocalStorage('Failed parsing expires token');
  }
  const now = new Date();

  return dateValidUntil > now;
};


/**
 * Get access token
 */
export const getAccessToken = () => {
  const access = localStorage.getItem(AUTH_ACCESS);

  if (access === null) {
    throw new ErrorLocalStorage('No access token');
  }
  return access;
};


/**
 * Get refresh token
 */
export const getRefreshToken = () => {
  const refresh = localStorage.getItem(AUTH_REFRESH);

  if (refresh === null) {
    throw new ErrorLocalStorage('No refresh token');
  }
  return refresh;
};
