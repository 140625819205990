import { INPUT_TYPE, } from 'sofair-form-validation';

import {
  DEVICES__KIOSKS_CONFIGURATION_REFRESH,
  DEVICES__KIOSKS_CONFIGURATION_CHANGE_VALUE_DETAIL,
  DEVICES__KIOSKS_CONFIGURATION_VALIDATE_FORM_DETAIL,
  DEVICES__GET_KIOSKS_CONFIGURATION_DETAIL,
  DEVICES__CREATE_KIOSKS_CONFIGURATION_DETAIL,
  DEVICES__UPDATE_KIOSKS_CONFIGURATION_DETAIL,
  DEVICES__DELETE_KIOSKS_CONFIGURATION_DETAIL,
  DEVICES__TOGGLE_KIOSKS_CONFIGURATION_DELETE_MODAL,
} from '../actions/kiosksConfigurationDetail';
import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION,
  KIOSKS_CONFIGURATION_AUTH_METHODS,
  KIOSKS_CONFIGURATION_DETAIL_PARAMETERS,
  KIOSKS_CONFIGURATION_DEFAULT_SEARCH,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';
import {
  mergeFormAndValidation, initInput, changeFormValue,
} from '../../../logic/forms/validation';
import { parseArrayFromApi, } from '../../../logic/utils/array';
import { findKeyValuePairForValue, } from '../../../logic/utils/object';
import { parseStringFromApi, } from '../../../logic/utils/string';

const initialState = {
  detailKiosksConfigurationData: {
    ...initRestData,
    isLoading: true,
  },
  createKiosksConfigurationData: {
    ...initRestData,
  },
  updateKiosksConfigurationData: {
    ...initRestData,
  },
  deleteKiosksConfigurationData: {
    ...initRestData,
  },
  kiosksConfigurationDeleteModal: {
    id: null,
    isOpen: false,
    collision: false,
    transports: {
      conflict: false,
      data: [],
    },
  },
  detail: {
    id: null,
    isValid: true,
    invalidInputs: 0,
    form: {
      name: {
        ...initInput,
        name: 'name',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: true,
          min: 2,
          max: 255,
        },
      },
      type: {
        ...initInput,
        name: 'type',
        type: INPUT_TYPE.SELECT,
        validation: {
          ...initInput.validation,
          required: true,
        },
      },
      visitorsButtonEnabled: {
        ...initInput,
        name: 'visitorsButtonEnabled',
        type: INPUT_TYPE.CHECKBOX,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
      transportationButtonEnabled: {
        ...initInput,
        name: 'transportationButtonEnabled',
        type: INPUT_TYPE.CHECKBOX,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
      printTicket: {
        ...initInput,
        name: 'printTicket',
        type: INPUT_TYPE.CHECKBOX,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
      automaticCameraReadSubscriptionEnabled: {
        ...initInput,
        name: 'automaticCameraReadSubscriptionEnabled',
        type: INPUT_TYPE.CHECKBOX,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
      detailParameters: {
        ...initInput,
        name: 'detailParameters',
        type: INPUT_TYPE.MULTISELECT,
        validation: {
          ...initInput.validation,
          required: true,
        },
      },
      inactiveRedirectTime: {
        ...initInput,
        name: 'inactiveRedirectTime',
        type: INPUT_TYPE.NUMBER,
        validation: {
          ...initInput.validation,
          required: true,
          min: 1,
          max: 5000,
        },
      },
      defaultSearch: {
        ...initInput,
        name: 'defaultSearch',
        type: INPUT_TYPE.SELECT,
        validation: {
          ...initInput.validation,
          required: true,
        },
      },
    },
    values: {
      name: '',
      type: null,
      transportationButtonEnabled: false,
      visitorsButtonEnabled: false,
      printTicket: false,
      inactiveRedirectTime: 1,
      automaticCameraReadSubscriptionEnabled: false,
      detailParameters: [],
      defaultSearch: KIOSKS_CONFIGURATION_DEFAULT_SEARCH.no.id,
    },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;

  switch (type) {
    /**
     * Refresh Data
     */
    case `${DEVICES__KIOSKS_CONFIGURATION_REFRESH}`: {
      return {
        ...state,
        createKiosksConfigurationData: {
          ...initialState.createKiosksConfigurationData,
        },
        updateKiosksConfigurationData: {
          ...initialState.updateKiosksConfigurationData,
        },
        detailKiosksConfigurationData: {
          ...initialState.detailKiosksConfigurationData,
        },
        detail: {
          ...initialState.detail,
        },
      };
    }


    /**
     * Change Form
     */
    case `${DEVICES__KIOSKS_CONFIGURATION_CHANGE_VALUE_DETAIL}`: {
      const newFormData = changeFormValue(
        state.detail.form, state.detail.values, state.detail.invalidInputs, payload
      );

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: newFormData.invalidInputs < 1,
          invalidInputs: newFormData.invalidInputs,
          form: newFormData.form,
          values: newFormData.values,
        },
      };
    }


    /**
     * Validate form
     */
    case `${DEVICES__KIOSKS_CONFIGURATION_VALIDATE_FORM_DETAIL}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload);

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }


    /**
     * GET Kiosks configuration Detail
     */
    case `${DEVICES__GET_KIOSKS_CONFIGURATION_DETAIL}__${PENDING}`: {
      return {
        ...state,
        detailKiosksConfigurationData: {
          ...initialState.detailKiosksConfigurationData,
          ...(handlePending()),
        },
        createKiosksConfigurationData: {
          ...initialState.createKiosksConfigurationData,
        },
        detail: {
          ...initialState.detail,
        },
      };
    }

    case `${DEVICES__GET_KIOSKS_CONFIGURATION_DETAIL}__${FULFILLED}`: {
      try {
        const newValues = {
          ...state.detail.values,
          name: parseStringFromApi(payload.data.name),
          transportationButtonEnabled: payload.data.transportationButtonEnabled,
          visitorsButtonEnabled: payload.data.visitorsButtonEnabled,
          printTicket: payload.data.printTicket,
          inactiveRedirectTime: payload.data.inactiveRedirectTime,
          automaticCameraReadSubscriptionEnabled: (
            payload.data.automaticCameraReadSubscriptionEnabled
          ),
          type: findKeyValuePairForValue(
            KIOSKS_CONFIGURATION_AUTH_METHODS, payload.data.type
          ),
          defaultSearch: payload.data.defaultSearch,
          detailParameters: parseArrayFromApi(payload.data.detailParameters).map(
            (item) => (KIOSKS_CONFIGURATION_DETAIL_PARAMETERS[item])
          ),
        };

        return {
          ...state,
          detailKiosksConfigurationData: {
            ...state.detailKiosksConfigurationData,
            ...(handleFulfilled(payload)),
          },
          detail: {
            ...state.detail,
            id: payload.data.id,
            values: newValues,
            oldValues: newValues,
          },
        };
      } catch (err) {
        return {
          ...state,
          detailKiosksConfigurationData: {
            ...state.detailKiosksConfigurationData,
            ...(handleRejected({
              statusCode: -1,
              messageInternal: err.message,
              message: {
                code: 'SOMETHING_HAPPEND',
                params: {},
              },
            })),
          },
        };
      }
    }

    case `${DEVICES__GET_KIOSKS_CONFIGURATION_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        detailKiosksConfigurationData: {
          ...state.detailKiosksConfigurationData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * CREATE Kiosks configuration Detail
     */
    case `${DEVICES__CREATE_KIOSKS_CONFIGURATION_DETAIL}__${PENDING}`: {
      return {
        ...state,
        createKiosksConfigurationData: {
          ...state.createKiosksConfigurationData,
          ...(handlePending()),
        },
      };
    }

    case `${DEVICES__CREATE_KIOSKS_CONFIGURATION_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        createKiosksConfigurationData: {
          ...state.createKiosksConfigurationData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${DEVICES__CREATE_KIOSKS_CONFIGURATION_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data.data);

      return {
        ...state,
        createKiosksConfigurationData: {
          ...state.createKiosksConfigurationData,
          ...(handleRejected(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${DEVICES__CREATE_KIOSKS_CONFIGURATION_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        createKiosksConfigurationData: {
          ...state.createKiosksConfigurationData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * UPDATE Kiosks configuration Detail
     */
    case `${DEVICES__UPDATE_KIOSKS_CONFIGURATION_DETAIL}__${PENDING}`: {
      return {
        ...state,
        updateKiosksConfigurationData: {
          ...state.updateKiosksConfigurationData,
          ...(handlePending()),
        },
      };
    }

    case `${DEVICES__UPDATE_KIOSKS_CONFIGURATION_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        updateKiosksConfigurationData: {
          ...state.updateKiosksConfigurationData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${DEVICES__UPDATE_KIOSKS_CONFIGURATION_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data);

      return {
        ...state,
        updateKiosksConfigurationData: {
          ...state.updateKiosksConfigurationData,
          ...(handleRejected(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${DEVICES__UPDATE_KIOSKS_CONFIGURATION_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        updateKiosksConfigurationData: {
          ...state.updateKiosksConfigurationData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * DELETE Kiosks configuration Detail
     */
    case `${DEVICES__DELETE_KIOSKS_CONFIGURATION_DETAIL}__${PENDING}`: {
      return {
        ...state,
        deleteKiosksConfigurationData: {
          ...state.deleteKiosksConfigurationData,
          ...(handlePending()),
        },
      };
    }

    case `${DEVICES__DELETE_KIOSKS_CONFIGURATION_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        deleteKiosksConfigurationData: {
          ...state.deleteKiosksConfigurationData,
          ...(handleFulfilled(payload)),
        },
        kiosksConfigurationDeleteModal: {
          ...initialState.kiosksConfigurationDeleteModal,
        },
      };
    }

    case `${DEVICES__DELETE_KIOSKS_CONFIGURATION_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        deleteKiosksConfigurationData: {
          ...state.deleteKiosksConfigurationData,
          ...(handleRejected(payload)),
        },
      };
    }

    /**
     * TOGGLE Kiosks configuration delete modal
     */
    case `${DEVICES__TOGGLE_KIOSKS_CONFIGURATION_DELETE_MODAL}`: {
      return {
        ...state,
        kiosksConfigurationDeleteModal: {
          ...initialState.kiosksConfigurationDeleteModal,
          isOpen: payload.isOpen,
          id: payload.id,
        },
        deleteKiosksConfigurationData: initialState.deleteKiosksConfigurationData,
      };
    }

    default:
      return state;
  }
};

export default reducer;
