import React, { Component, Fragment, } from 'react';
import {
  shape, number, bool, func, object, string, arrayOf,
} from 'prop-types';
import { Route, } from 'react-router-dom';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import { getDisplaysConfigurationAction, changeFilterAction, deleteDisplayConfigurationAction, } from '../actions/displaysConfiguration';
import DisplaysConfiguration from '../components/DisplaysConfiguration/DisplaysConfiguration';
import DisplaysConfigurationEditModal from './DisplaysConfigurationEditModal';
import DisplaysConfigurationCreateModal from './DisplaysConfigurationCreateModal';

class DisplaysConfigurationPage extends Component {
  componentDidMount() {
    const { table, } = this.props;

    this.handleGetDisplaysConfiguration(table.filter);
  }

  handleGetDisplaysConfiguration = (newFilter) => {
    const { getDisplaysConfiguration, table, } = this.props;

    getDisplaysConfiguration(newFilter === undefined ? table.filter : newFilter);
  }

  handleEdit = (id) => {
    const { match, history, } = this.props;

    history.push(`${match.path}/edit/${id}`);
  }

  handleDelete = (id) => {
    const { deleteDisplayConfiguration, table, } = this.props;
    this.handleGetDisplaysConfiguration(table.filter);
    deleteDisplayConfiguration(id);
  }

  handleCreate = () => {
    const { match, history, } = this.props;

    history.push(`${match.path}/create`);
  }

  render() {
    const {
      match,
      table,
      displaysConfigurationData,
      changeFilter,
    } = this.props;

    return (
      <Fragment>
        <DisplaysConfiguration
          table={table}
          displaysConfigurationData={displaysConfigurationData}
          handleGetDisplaysConfiguration={this.handleGetDisplaysConfiguration}
          handleEdit={this.handleEdit}
          handleDelete={this.handleDelete}
          changeFilter={changeFilter}
          handleCreate={this.handleCreate}
        />
        <Route
          exact
          path={`${match.path}/create`}
          render={(props) => (
            <DisplaysConfigurationCreateModal
              {...props}
              refreshParent={this.handleGetDisplaysConfiguration}
            />
          )}
        />

        <Route
          exact
          path={`${match.path}/edit/:id`}
          component={
            (props) => (
              <DisplaysConfigurationEditModal
                {...props}
                refreshParent={this.handleGetDisplaysConfiguration}
              />
            )
          }
        />

      </Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  const { displaysConfiguration, } = state;
  return {
    displaysConfigurationData: displaysConfiguration.displaysConfigurationData,
    table: displaysConfiguration.table,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDisplaysConfiguration: bindActionCreators(getDisplaysConfigurationAction, dispatch),
  changeFilter: bindActionCreators(changeFilterAction, dispatch),
  deleteDisplayConfiguration: bindActionCreators(deleteDisplayConfigurationAction, dispatch),
});


DisplaysConfigurationPage.propTypes = {
  table: shape({
    data: arrayOf(object).isRequired,
    count: number.isRequired,
    filter: object.isRequired,
  }).isRequired,
  displaysConfigurationData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  changeFilter: func.isRequired,
  getDisplaysConfiguration: func.isRequired,
  match: shape({
    path: string.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  deleteDisplayConfiguration: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(DisplaysConfigurationPage);
