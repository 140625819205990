import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION, REJECTED_COLLISON, FORM_SUCCESS, FORM_ERROR,
} from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';
import { isSomethingRemovedFromArray, } from '../../../logic/utils/array';


const parseValues = (values) => ({
  name: values.name,
  no: values.no,
  capacity: values.capacity,
  transportationTypes: values.transportationTypes.map((item) => item.id),
});


/**
 * Refresh data - on mount
 */
export const PLATFORMS__REFRESH = 'PLATFORMS__REFRESH';
export const refreshPlatformsDetailAction = () => ({
  type: `${PLATFORMS__REFRESH}`,
});


/**
 * Change Value
 */
export const PLATFORMS__CHANGE_VALUE_DETAIL = 'PLATFORMS__CHANGE_VALUE_DETAIL';
export const changeValueAction = (name, value, validationError) => ({
  type: `${PLATFORMS__CHANGE_VALUE_DETAIL}`,
  payload: {
    name,
    value,
    validationError,
  },
});


/**
 * Validate Form
 */
export const PLATFORMS__VALIDATE_FORM_DETAIL = 'PLATFORMS__VALIDATE_FORM_DETAIL';
export const validateFormAction = (error) => ({
  type: `${PLATFORMS__VALIDATE_FORM_DETAIL}`,
  payload: error,
});


/*
 * GET Platforms Detail
 */
export const PLATFORMS__GET_PLATFORMS_DETAIL = 'PLATFORMS__GET_PLATFORMS_DETAIL';
export const getPlatformsDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${PLATFORMS__GET_PLATFORMS_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/platforms/detail?id=${id}`,
    });

    dispatch({
      type: `${PLATFORMS__GET_PLATFORMS_DETAIL}__${FULFILLED}`,
      payload: response,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${PLATFORMS__GET_PLATFORMS_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * CREATE Platforms Detail
 */
export const PLATFORMS__CREATE_PLATFORMS_DETAIL = 'PLATFORMS__CREATE_PLATFORMS_DETAIL';
export const createPlatformsDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${PLATFORMS__CREATE_PLATFORMS_DETAIL}__${PENDING}`,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/platforms`,
      data: {
        values: parseValues(item.values),
      },
    });

    dispatch({
      type: `${PLATFORMS__CREATE_PLATFORMS_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Rampa vytvořena',
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${PLATFORMS__CREATE_PLATFORMS_DETAIL}__${REJECTED_VALIDATION}`,
        payload: parsedError,
      });
      return;
    }

    dispatch({
      type: `${PLATFORMS__CREATE_PLATFORMS_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * UPDATE Platforms Detail
 */
export const PLATFORMS__UPDATE_PLATFORMS_DETAIL = 'PLATFORMS__UPDATE_PLATFORMS_DETAIL';
export const updatePlatformsDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${PLATFORMS__UPDATE_PLATFORMS_DETAIL}__${PENDING}`,
    });

    const values = parseValues(item.values);
    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/platforms`,
      data: {
        id: item.id,
        values,
        changed: parseInt(item.oldValues.capacity, 10) > parseInt(item.values.capacity, 10)
          || isSomethingRemovedFromArray(values.transportationTypes, item.oldValues.transportationTypes.map((trenstype) => trenstype.id), 'id'),
        force: false,
      },
    });

    dispatch({
      type: `${PLATFORMS__UPDATE_PLATFORMS_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Rampa upravena',
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${PLATFORMS__UPDATE_PLATFORMS_DETAIL}__${REJECTED_VALIDATION}`,
        payload: {
          ...parsedError,
          message: {
            code: '',
            params: {},
          },
        },
      });
      return;
    }

    if (parsedError.statusCode === 428) {
      dispatch({
        type: `${PLATFORMS__UPDATE_PLATFORMS_DETAIL}__${REJECTED_COLLISON}`,
        payload: {
          ...parsedError,
          message: {
            code: '',
            params: {},
          },
        },
      });
      return;
    }

    dispatch({
      type: `${PLATFORMS__UPDATE_PLATFORMS_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * FORCE UPDATE Platforms Detail
 */
export const PLATFORMS__FORCE_UPDATE_PLATFORMS_DETAIL = 'PLATFORMS__FORCE_UPDATE_PLATFORMS_DETAIL';
export const forceUpdatePlatformsDetailAction = (item, redirectBack) => async (dispatch) => {
  try {
    dispatch({
      type: `${PLATFORMS__FORCE_UPDATE_PLATFORMS_DETAIL}__${PENDING}`,
    });

    const values = parseValues(item.values);
    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/platforms`,
      data: {
        id: item.id,
        values,
        changed: parseInt(item.oldValues.capacity, 10) > parseInt(item.values.capacity, 10)
          || isSomethingRemovedFromArray(values.transportationTypes, item.oldValues.transportationTypes.map((trenstype) => trenstype.id), 'id'),
        force: true,
      },
    });

    dispatch({
      type: `${PLATFORMS__FORCE_UPDATE_PLATFORMS_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        notif: {
          type: FORM_SUCCESS,
          title: 'Rampa upravena',
        },
      },
    });

    redirectBack();
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 422) {
      dispatch({
        type: `${PLATFORMS__FORCE_UPDATE_PLATFORMS_DETAIL}__${REJECTED_VALIDATION}`,
        payload: parsedError,
      });
      return;
    }

    dispatch({
      type: `${PLATFORMS__FORCE_UPDATE_PLATFORMS_DETAIL}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * DELETE Platforms Detail
 */
export const PLATFORMS__DELETE_PLATFORMS_DETAIL = 'PLATFORMS__DELETE_PLATFORMS_DETAIL';
export const deletePlatformsDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${PLATFORMS__DELETE_PLATFORMS_DETAIL}__${PENDING}`,
      payload: {
        id,
      },
    });

    const response = await api({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/platforms?id=${id}&force=${false}`,
    });

    dispatch({
      type: `${PLATFORMS__DELETE_PLATFORMS_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        id,
        notif: {
          type: FORM_SUCCESS,
          title: 'Rampa smazána',
        },
      },
    });
  } catch (err) {
    const parsedError = handleError(err);

    if (parsedError.statusCode === 428) {
      dispatch({
        type: `${PLATFORMS__DELETE_PLATFORMS_DETAIL}__${REJECTED_COLLISON}`,
        payload: {
          ...parsedError,
          id,
          message: {
            code: 'isUsed',
            params: {},
          },
        },
      });
      return;
    }

    dispatch({
      type: `${PLATFORMS__DELETE_PLATFORMS_DETAIL}__${REJECTED}`,
      payload: {
        ...parsedError,
        id,
        notif: {
          type: FORM_ERROR,
          title: 'Nepovedlo se smazat Rampu',
          showMessage: true,
        },
      },
    });
  }
};


/*
 * FORCE DELETE Platforms Detail
 */
export const PLATFORMS__FORCE_DELETE_PLATFORMS_DETAIL = 'PLATFORMS__FORCE_DELETE_PLATFORMS_DETAIL';
export const forceDeletePlatformsDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${PLATFORMS__FORCE_DELETE_PLATFORMS_DETAIL}__${PENDING}`,
      payload: {
        id,
      },
    });

    const response = await api({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/platforms?id=${id}&force=${true}`,
    });

    dispatch({
      type: `${PLATFORMS__FORCE_DELETE_PLATFORMS_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        id,
        notif: {
          type: FORM_SUCCESS,
          title: 'Rampa smazána',
        },
      },
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${PLATFORMS__FORCE_DELETE_PLATFORMS_DETAIL}__${REJECTED}`,
      payload: {
        ...parsedError,
        id,
      },
    });
  }
};


/*
 * RESTORE Platforms Detail
 */
export const PLATFORMS__RESTORE_PLATFORMS_DETAIL = 'PLATFORMS__RESTORE_PLATFORMS_DETAIL';
export const restorePlatformssDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: `${PLATFORMS__RESTORE_PLATFORMS_DETAIL}__${PENDING}`,
      payload: {
        id,
      },
    });

    const response = await api({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/platforms/restore`,
      data: {
        id,
      },
    });

    dispatch({
      type: `${PLATFORMS__RESTORE_PLATFORMS_DETAIL}__${FULFILLED}`,
      payload: {
        ...response,
        id,
        notif: {
          type: FORM_SUCCESS,
          title: 'Rampa obnovena',
        },
      },
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${PLATFORMS__RESTORE_PLATFORMS_DETAIL}__${REJECTED}`,
      payload: {
        ...parsedError,
        id,
        notif: {
          type: FORM_ERROR,
          title: 'Nepovedlo se obnovit Rampu',
          showMessage: true,
        },
      },
    });
  }
};


/**
 * Toggle Modal Force Update Platform
 */
export const PLATFORMS__TOGGLE_FORCE_UPDATE_PLATFORMS_MODAL = 'PLATFORMS__TOGGLE_FORCE_UPDATE_PLATFORMS_MODAL';
export const toggleForceUpdateModalAction = () => ({
  type: `${PLATFORMS__TOGGLE_FORCE_UPDATE_PLATFORMS_MODAL}`,
});


/**
 * Toggle Modal Force Delete Platform
 */
export const PLATFORMS__TOGGLE_FORCE_DELETE_MODAL = 'PLATFORMS__TOGGLE_FORCE_DELETE_MODAL';
export const toggleForceDeleteModalAction = () => ({
  type: `${PLATFORMS__TOGGLE_FORCE_DELETE_MODAL}`,
});
