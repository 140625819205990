import React, { Component, Fragment, } from 'react';
import { arrayOf, object, func, } from 'prop-types';

import AddItem from './AddItem';
import InputItem from './InputItem';


class InputList extends Component {
  handleDelete = (position) => {
    const {
      list,
      onChangeValue,
    } = this.props;

    onChangeValue(
      'transportationEditFormInputs',
      [
        ...list.slice(0, position),
        ...list.slice(position + 1),
      ],
    );
  }


  handleMoveUp = (position) => {
    const {
      list,
      onChangeValue,
    } = this.props;

    if (position < 1) return;

    onChangeValue(
      'transportationEditFormInputs',
      [
        ...list.slice(0, position - 1),
        list[position],
        list[position - 1],
        ...list.slice(position + 1),
      ],
    );
  }


  handleMoveDown = (position) => {
    const {
      list,
      onChangeValue,
    } = this.props;

    if (position > list.length - 2) return;

    onChangeValue(
      'transportationEditFormInputs',
      [
        ...list.slice(0, position),
        list[position + 1],
        list[position],
        ...list.slice(position + 2),
      ],
    );
  }


  handleDetail = (position) => {
    const { toggleModalInput, } = this.props;

    toggleModalInput({
      isOpen: true,
      isEdit: true,
      position,
    });
  }


  handleAdd = (position) => {
    const { toggleModalInput, } = this.props;

    toggleModalInput({
      isOpen: true,
      isEdit: false,
      position,
    });
  }


  render() {
    const {
      list,
    } = this.props;

    return (
      <ul
        style={{
          listStyle: 'none',
          paddingInlineStart: 0,
        }}
      >
        <li>
          <AddItem
            position={0}
            onAdd={this.handleAdd}
          />
        </li>
        {list.map((item, index) => (
          <Fragment key={`${item.configuration.table}__${item.configuration.parameter}`}>
            <li>
              <InputItem
                position={index}
                data={item}
                onDetail={this.handleDetail}
                onDelete={this.handleDelete}
                onMoveUp={this.handleMoveUp}
                onMoveDown={this.handleMoveDown}
              />
            </li>
            <li>
              <AddItem
                position={index + 1}
                onAdd={this.handleAdd}
              />
            </li>
          </Fragment>
        ))}
      </ul>
    );
  }
}


InputList.propTypes = {
  // data
  list: arrayOf(object).isRequired,
  // methods
  onChangeValue: func.isRequired,
  toggleModalInput: func.isRequired,
};


export default InputList;
