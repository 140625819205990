import {
  PENDING, FULFILLED, REJECTED,
  LOGIN, ERROR, NO_USER,
} from '../../../globals';
import { api, apiRefresh, handleError, } from '../../../logic/restApi/restApi';
import {
  setAuth, checkUserAndGetAuthority, isTokenValid, clearLS,
} from '../../../logic/localStorage/auth';
import { isSupportedLS, } from '../../../logic/localStorage/support';
import { ErrorCompatibility, } from '../../../logic/errors/errors';


/**
 * Check User - after start or reload
 */
export const AUTH__INIT_USER = 'AUTH__INIT_USER';
export const initUser = () => async (dispatch) => {
  try {
    // 1. Check LocalStorage
    if (!isSupportedLS) {
      throw new ErrorCompatibility('Not supported LocalStorage!');
    }

    // 2. Check User
    const userAuthority = checkUserAndGetAuthority();

    if (userAuthority === null) {
      dispatch({
        type: `${AUTH__INIT_USER}__${NO_USER}`,
      });
      return;
    }

    // 3. Check token validity
    if (isTokenValid()) {
      dispatch({
        type: `${AUTH__INIT_USER}__${LOGIN}`,
        payload: {
          authority: userAuthority,
        },
      });
      return;
    }

    // 4. refresh token
    await apiRefresh(dispatch);
    dispatch({
      type: `${AUTH__INIT_USER}__${FULFILLED}`,
    });
  } catch (err) {
    switch (err.constructor) {
      case ErrorCompatibility:
        dispatch({
          type: `${AUTH__INIT_USER}__${ERROR}`,
          payload: {
            statusCode: 0,
            messageCode: 'errorCompability',
            errorMessage: err.message,
          },
        });
        return;

      default:
        break;
    }

    const parsedError = handleError(err);
    clearLS();
    dispatch({
      type: `${AUTH__INIT_USER}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/*
 * Login
 */
export const AUTH__LOGIN = 'AUTH__LOGIN';
export const loginAction = ({ username, password, }) => async (dispatch) => {
  try {
    dispatch({
      type: `${AUTH__LOGIN}__${PENDING}`,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/auth/token`,
      data: {
        username,
        password,
      },
    });

    setAuth({
      access: response.data.accessToken,
      refresh: response.data.refreshToken,
      expires: response.data.expires,
      authority: response.data.user,
    });

    dispatch({
      type: `${AUTH__LOGIN}__${FULFILLED}`,
      payload: {
        authority: response.data.user,
      },
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${AUTH__LOGIN}__${REJECTED}`,
      payload: parsedError,
    });
  }
};
