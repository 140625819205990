import styled from 'styled-components';

const StyledDisplayEditor = styled.div`
    position: relative;
    width: ${(p) => p.width}px;
    height: ${(p) => p.height}px;
    background-color: #272727;
`;

export default StyledDisplayEditor;
