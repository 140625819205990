import styled from 'styled-components';

import media from '../../styles/media';


const RTRow = styled.li`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;

  ${media.XS`
    padding: 0.5rem;
  `}
`;


export default RTRow;
