import { INPUT_TYPE, } from 'sofair-form-validation';

import {
  TRANSPORTATION_TYPES__GET_TRANSPORTATION_TYPES_DETAIL,
  TRANSPORTATION_TYPES__CREATE_TRANSPORTATION_TYPES_DETAIL,
  TRANSPORTATION_TYPES__UPDATE_TRANSPORTATION_TYPES_DETAIL,
  TRANSPORTATION_TYPES__VALIDATE_FORM_DETAIL,
  TRANSPORTATION_TYPES__CHANGE_VALUE_DETAIL,
  TRANSPORTATION_TYPES__REFRESH,
  TRANSPORTATION_TYPES__FORCE_UPDATE_TRANSPORTATION_TYPES_DETAIL,
  TRANSPORTATION_TYPES__FORCE_DELETE_TRANSPORTATION_TYPES_DETAIL,
  TRANSPORTATION_TYPES__TOGGLE_FORCE_UPDATE_TRANSPORTATION_TYPES_MODAL,
  TRANSPORTATION_TYPES__CHANGE_VALUE_FORCE_UPDATE_TRANSPORTATION_TYPES_MODAL,
  TRANSPORTATION_TYPES__TOGGLE_FORCE_DELETE_TRANSPORTATION_TYPES_MODAL,
  TRANSPORTATION_TYPES__DELETE_TRANSPORTATION_TYPES_DETAIL,
} from '../actions/transportationTypesDetail';
import {
  PENDING, FULFILLED, REJECTED, REJECTED_VALIDATION, REJECTED_COLLISON,
} from '../../../globals';
import {
  initRestData, handlePending, handleFulfilled, handleRejected,
} from '../../../logic/restApi/utils';
import {
  mergeFormAndValidation, initInput, changeFormValue,
} from '../../../logic/forms/validation';
import { parseArrayFromApi, parseArrayItemToPayloadAndUUID, } from '../../../logic/utils/array';


const initialState = {
  detailTransportationTypesData: {
    ...initRestData,
    isLoading: true,
  },
  createTransportationTypesData: {
    ...initRestData,
  },
  updateTransportationTypesData: {
    ...initRestData,
  },
  forceUpdateTransportationTypesData: {
    ...initRestData,
  },
  forceDeleteData: {
    ...initRestData,
  },
  forceUpdateModal: {
    isOpen: false,
    reservations: {
      conflict: false,
      data: 0,
    },
    transports: {
      conflict: true,
      data: [],
    },
    plans: {
      conflict: true,
      data: [],
    },
    values: {
      applyPlat: true,
      applyPlan: true,
    },
  },
  forceDeleteModal: {
    id: null,
    isOpen: false,
    reservations: {
      conflict: false,
      data: 0,
    },
    transports: {
      conflict: false,
      data: [],
    },
  },
  detail: {
    id: null,
    isValid: true,
    invalidInputs: 0,
    form: {
      name: {
        ...initInput,
        name: 'name',
        type: INPUT_TYPE.TEXT,
        validation: {
          ...initInput.validation,
          required: true,
          min: 2,
          max: 255,
        },
      },
      platforms: {
        ...initInput,
        name: 'platforms',
        type: INPUT_TYPE.MULTISELECT,
      },
      plan: {
        ...initInput,
        name: 'plan',
        type: INPUT_TYPE.MULTISELECT,
        validation: {
          ...initInput.validation,
          required: true,
        },
      },
      ticketId: {
        ...initInput,
        name: 'ticketId',
        type: INPUT_TYPE.SELECT,
        validation: {
          ...initInput.validation,
          required: false,
        },
      },
    },
    values: {
      name: '',
      platforms: [],
      plan: [],
      ticketId: null,
    },
    oldValues: {},
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, } = action;

  switch (type) {
    /**
     * Refresh Data
     */
    case `${TRANSPORTATION_TYPES__REFRESH}`: {
      return {
        ...state,
        createTransportationTypesData: {
          ...initialState.createTransportationTypesData,
        },
        updateTransportationTypesData: {
          ...initialState.updateTransportationTypesData,
        },
        detail: {
          ...initialState.detail,
        },
      };
    }


    /**
     * Change Form
     */
    case `${TRANSPORTATION_TYPES__CHANGE_VALUE_DETAIL}`: {
      const newFormData = changeFormValue(
        state.detail.form, state.detail.values, state.detail.invalidInputs, payload
      );

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: newFormData.invalidInputs < 1,
          invalidInputs: newFormData.invalidInputs,
          form: newFormData.form,
          values: newFormData.values,
        },
      };
    }


    /**
     * Validate form
     */
    case `${TRANSPORTATION_TYPES__VALIDATE_FORM_DETAIL}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload);

      return {
        ...state,
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }


    /**
     * GET Transportation Types Detail
     */
    case `${TRANSPORTATION_TYPES__GET_TRANSPORTATION_TYPES_DETAIL}__${PENDING}`: {
      return {
        ...state,
        detailTransportationTypesData: {
          ...initialState.detailTransportationTypesData,
          ...(handlePending()),
        },
        updateTransportationTypesData: {
          ...initialState.updateTransportationTypesData,
        },
        detail: {
          ...initialState.detail,
        },
      };
    }

    case `${TRANSPORTATION_TYPES__GET_TRANSPORTATION_TYPES_DETAIL}__${FULFILLED}`: {
      try {
        const newValues = {
          ...state.detail.values,
          name: payload.data.transportationTypeTranslation.name,
          platforms: parseArrayFromApi(payload.data.platforms.map((row) => ({
            ...row,
            name: row.platformTranslation.name,
          }))),
          plan: parseArrayFromApi(payload.data.plan.map((row) => ({
            ...row,
            name: row.actionTranslation.name,
          }))),
          ticketId: payload.data.tickets.length ? payload.data.tickets[0] : null,
        };
        return {
          ...state,
          detailTransportationTypesData: {
            ...state.detailTransportationTypesData,
            ...(handleFulfilled(payload)),
          },
          detail: {
            ...state.detail,
            id: payload.data.id,
            values: {
              ...newValues,
              plan: parseArrayItemToPayloadAndUUID(newValues.plan),
            },
            oldValues: newValues,
          },
        };
      } catch (err) {
        return {
          ...state,
          detailTransportationTypesData: {
            ...state.detailTransportationTypesData,
            ...(handleRejected({
              statusCode: -1,
              messageInternal: err.message,
              message: {
                code: 'SOMETHING_HAPPEND',
                params: {},
              },
            })),
          },
        };
      }
    }

    case `${TRANSPORTATION_TYPES__GET_TRANSPORTATION_TYPES_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        detailTransportationTypesData: {
          ...state.detailTransportationTypesData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * CREATE Transportation Types Detail
     */
    case `${TRANSPORTATION_TYPES__CREATE_TRANSPORTATION_TYPES_DETAIL}__${PENDING}`: {
      return {
        ...state,
        createTransportationTypesData: {
          ...state.createTransportationTypesData,
          ...(handlePending()),
        },
      };
    }

    case `${TRANSPORTATION_TYPES__CREATE_TRANSPORTATION_TYPES_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        createTransportationTypesData: {
          ...state.createTransportationTypesData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${TRANSPORTATION_TYPES__CREATE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data.data);

      return {
        ...state,
        createTransportationTypesData: {
          ...state.createTransportationTypesData,
          ...(handleRejected(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${TRANSPORTATION_TYPES__CREATE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        createTransportationTypesData: {
          ...state.createTransportationTypesData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * UPDATE Transportation Types Detail
     */
    case `${TRANSPORTATION_TYPES__UPDATE_TRANSPORTATION_TYPES_DETAIL}__${PENDING}`: {
      return {
        ...state,
        updateTransportationTypesData: {
          ...state.updateTransportationTypesData,
          ...(handlePending()),
        },
      };
    }

    case `${TRANSPORTATION_TYPES__UPDATE_TRANSPORTATION_TYPES_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        updateTransportationTypesData: {
          ...state.updateTransportationTypesData,
          ...(handleFulfilled(payload)),
        },
      };
    }

    case `${TRANSPORTATION_TYPES__UPDATE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data.data);

      return {
        ...state,
        updateTransportationTypesData: {
          ...state.updateTransportationTypesData,
          ...(handleRejected(payload)),
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${TRANSPORTATION_TYPES__UPDATE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED_COLLISON}`: {
      return {
        ...state,
        updateTransportationTypesData: {
          ...state.updateTransportationTypesData,
          ...(handleRejected(payload)),
        },
        forceUpdateModal: {
          ...state.forceUpdateModal,
          isOpen: true,
          reservations: payload.data.reservations,
          transports: payload.data.transports,
          plans: payload.data.plans,
        },
      };
    }

    case `${TRANSPORTATION_TYPES__UPDATE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        updateTransportationTypesData: {
          ...state.updateTransportationTypesData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * FORCE UPDATE Transportation Types Detail
     */
    case `${TRANSPORTATION_TYPES__FORCE_UPDATE_TRANSPORTATION_TYPES_DETAIL}__${PENDING}`: {
      return {
        ...state,
        forceUpdateTransportationTypesData: {
          ...state.forceUpdateTransportationTypesData,
          ...(handlePending()),
        },
      };
    }

    case `${TRANSPORTATION_TYPES__FORCE_UPDATE_TRANSPORTATION_TYPES_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        updateTransportationTypesData: {
          ...initialState.updateTransportationTypesData,
        },
        forceUpdateTransportationTypesData: {
          ...initialState.forceUpdateTransportationTypesData,
        },
        forceUpdateModal: {
          ...initialState.forceUpdateModal,
        },
      };
    }

    case `${TRANSPORTATION_TYPES__FORCE_UPDATE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED_VALIDATION}`: {
      const validatedForm = mergeFormAndValidation(state.detail.form, payload.data);

      return {
        ...state,
        forceUpdateTransportationTypesData: {
          ...state.forceUpdateTransportationTypesData,
          ...(handleRejected(payload)),
        },
        forceUpdateModal: {
          ...initialState.forceUpdateModal,
        },
        detail: {
          ...state.detail,
          isValid: false,
          invalidInputs: validatedForm.invalidInputs,
          form: validatedForm.form,
        },
      };
    }

    case `${TRANSPORTATION_TYPES__FORCE_UPDATE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        forceUpdateTransportationTypesData: {
          ...state.forceUpdateTransportationTypesData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * FORCE DELETE Transportation Types Detail
     */
    case `${TRANSPORTATION_TYPES__FORCE_DELETE_TRANSPORTATION_TYPES_DETAIL}__${PENDING}`: {
      return {
        ...state,
        forceDeleteData: {
          ...state.forceDeleteData,
          ...(handlePending()),
        },
      };
    }

    case `${TRANSPORTATION_TYPES__FORCE_DELETE_TRANSPORTATION_TYPES_DETAIL}__${FULFILLED}`: {
      return {
        ...state,
        forceDeleteData: {
          ...state.forceDeleteData,
          ...(handleFulfilled(payload)),
        },
        forceDeleteModal: {
          ...initialState.forceDeleteModal,
        },
      };
    }

    case `${TRANSPORTATION_TYPES__FORCE_DELETE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED}`: {
      return {
        ...state,
        forceDeleteData: {
          ...state.forceDeleteData,
          ...(handleRejected(payload)),
        },
      };
    }


    /**
     * Toggle Modal Force Update Transportation Types
     */
    case `${TRANSPORTATION_TYPES__TOGGLE_FORCE_UPDATE_TRANSPORTATION_TYPES_MODAL}`: {
      return {
        ...state,
        forceUpdateModal: {
          ...initialState.forceUpdateModal,
        },
      };
    }


    /**
     * Change Value Modal Force Update Transportation Types
     */
    case `${TRANSPORTATION_TYPES__CHANGE_VALUE_FORCE_UPDATE_TRANSPORTATION_TYPES_MODAL}`: {
      return {
        ...state,
        forceUpdateModal: {
          ...state.forceUpdateModal,
          values: {
            ...state.forceUpdateModal.values,
            [payload.name]: payload.value,
          },
        },
      };
    }


    /**
     * Toggle Modal Force Delete Transportation Types
     */
    case `${TRANSPORTATION_TYPES__TOGGLE_FORCE_DELETE_TRANSPORTATION_TYPES_MODAL}`: {
      return {
        ...state,
        forceDeleteModal: {
          ...initialState.forceDeleteModal,
        },
      };
    }


    /**
     * Delete Transportation Types
     */
    case `${TRANSPORTATION_TYPES__DELETE_TRANSPORTATION_TYPES_DETAIL}__${REJECTED_COLLISON}`: {
      return {
        ...state,
        forceDeleteData: {
          ...initialState.forceDeleteData,
        },
        forceDeleteModal: {
          ...initialState.forceDeleteModal,
          id: payload.id,
          isOpen: true,
          reservations: payload.data.reservations,
          transports: payload.data.transports,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
