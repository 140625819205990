import React from 'react';
import {
  shape, bool, object, arrayOf,
} from 'prop-types';

import Row from '../../atoms/Row/Row';
import Col from '../../atoms/Col/Col';
import CollisionFragment from './CollisionFragment';


const CollisionTransTypes = ({ collision, ...fragProps }) => {
  if (!collision.conflict) return null;

  return (
    <CollisionFragment {...fragProps}>
      <Row>
        <Col sidePadding className="collision--title">
          Transportační typy
        </Col>
        <Col sidePadding>
          <ul className="collision--list">
            {collision.data.map((item) => (
              <li key={item.id} className="collision--text">
                {`#${item.id} - ${item.name}`}
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </CollisionFragment>
  );
};


CollisionTransTypes.propTypes = {
  collision: shape({
    conflict: bool.isRequired,
    data: arrayOf(object).isRequired,
  }).isRequired,
};


export default CollisionTransTypes;
