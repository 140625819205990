import React, { Component, } from 'react';
import {
  shape, func, object, arrayOf, bool, string,
} from 'prop-types';

import DnDProvider from '../../../../logic/dnd/DnDProvider';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import PlanContext from './PlanContext';
import PlanActionList from './PlanActionList';
import PlanValueList from './PlanValueList';
import DraggingItem from './DraggingItem';


const ACTIONS = 'actions';
const VALUE = 'value';


class Plan extends Component {
  handleOnDrop = (item, drop) => {
    const fromPos = item.pos[item.pos.length - 1];
    const toPos = drop.pos[drop.pos.length - 1];

    if (item.root === ACTIONS) {
      if (drop.root === ACTIONS) {
        return;
      }
      this.add(item, toPos);
      return;
    }
    if (drop.root === ACTIONS) {
      this.remove(fromPos);
      return;
    }
    this.swap(item, fromPos, toPos);
  }

  add = (item, toPos) => {
    const { value, onChange, } = this.props;

    onChange([
      ...value.slice(0, toPos),
      {
        id: item.id,
        payload: item.payload,
      },
      ...value.slice(toPos),
    ]);
  }

  remove = (fromPos) => {
    const { value, onChange, } = this.props;

    onChange([
      ...value.slice(0, fromPos),
      ...value.slice(fromPos + 1),
    ]);
  }

  swap = (item, fromPos, toPos) => {
    const { value, onChange, } = this.props;

    const newValue = [
      ...value.slice(0, toPos),
      {
        id: item.id,
        payload: item.payload,
      },
      ...value.slice(toPos),
    ];

    let removePos;
    if (fromPos <= toPos) {
      removePos = fromPos;
    } else {
      removePos = fromPos + 1;
    }
    newValue.splice(removePos, 1);
    onChange(newValue);
  }

  renderDraggingItem = (data) => (
    <DraggingItem>
      {data.name}
    </DraggingItem>
  )

  render() {
    const {
      // data
      value,
      actionsSharedData,
      // methods
      getActionsShared,
    } = this.props;

    return (
      <DnDProvider
        Context={PlanContext}
        onDrop={this.handleOnDrop}
        renderDragging={this.renderDraggingItem}
      >
        <Row>
          <Col SM={12}>
            <PlanValueList
              root={VALUE}
              position={[]}
              value={value}
            />
          </Col>

          <Col SM={12}>
            <PlanActionList
              root={ACTIONS}
              position={[]}
              actionsSharedData={actionsSharedData}
              getActionsShared={getActionsShared}
            />
          </Col>
        </Row>
      </DnDProvider>
    );
  }
}


Plan.propTypes = {
  value: arrayOf(shape({
    id: string.isRequired,
    payload: object.isRequired,
  })).isRequired,
  actionsSharedData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
    data: object.isRequired,
  }).isRequired,
  onChange: func.isRequired,
  getActionsShared: func.isRequired,
};


export default Plan;
