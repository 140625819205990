import React, { Component, } from 'react';
import {
  shape, bool, func, object,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  createTicketDetailAction,
  validateFormAction,
  changeValueAction,
  refreshTicketDetailAction,
} from '../actions/ticketsDetail';
import { validateInput, validateWholeFormValidator, } from '../../../logic/forms/validation';
import { getNumericalSeriesSharedAction, getTransportationTypesSharedAction, } from '../../shared/actions/shared';
import Modal from '../../../atoms/Modal/Modal';
import TicketsDetail from '../components/TicketsDetail/TicketsDetail';
import TicketsCreate from '../components/TicketsCreate/TicketsCreate';

class TicketsCreateModal extends Component {
  componentDidMount() {
    const {
      refreshTicketDetail,
    } = this.props;

    refreshTicketDetail();
  }

  handleClose = () => {
    const { history, } = this.props;

    history.goBack();
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);

    changeValue(name, value, validationError);
  }

  handleCreate = async () => {
    const {
      createTicketsData,
      detail,
      validateForm,
      createTicketDetail,
    } = this.props;

    if (createTicketsData.isLoading || !detail.isValid) return;

    const validation = validateWholeFormValidator(
      detail.form,
      detail.values,
    );

    if (Object.keys(validation).length < 1) {
      createTicketDetail(detail, this.handleCreateSuccess);
    } else {
      validateForm(validation);
    }
  }


  handleCreateSuccess = () => {
    const { refreshParent, } = this.props;

    refreshParent();
    this.handleClose();
  }

  render() {
    const {
      createTicketsData,
      detail,
      numericalSeriesSharedData,
      getNumericalSeriesShared,
      getTransportationTypesShared,
      transportationTypesData,
    } = this.props;

    return (
      <Modal
        isOpen
        onClose={this.handleClose}
        closeOnEsc
        size="LG"
        title="Vytvoření konfigurace lístku"
      >
        <TicketsCreate
          // data
          createTicketsData={createTicketsData}
          isFormValid={detail.isValid}
          // methods
          handleClose={this.handleClose}
          handleCreate={this.handleCreate}
        >
          <TicketsDetail
            // data
            values={detail.values}
            form={detail.form}
            // methods
            handleClose={this.handleClose}
            handleOnChangeValue={this.handleOnChangeValue}
            getNumericalSeriesShared={getNumericalSeriesShared}
            numericalSeriesSharedData={numericalSeriesSharedData}
            getTransportationTypesShared={getTransportationTypesShared}
            transportationTypesData={transportationTypesData}
          />
        </TicketsCreate>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { ticketsDetail, shared, } = state;
  return {
    createTicketsData: ticketsDetail.createTicketsData,
    detail: ticketsDetail.detail,
    numericalSeriesSharedData: shared.numericalSeriesData,
    transportationTypesData: shared.transportationTypesData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createTicketDetail: bindActionCreators(createTicketDetailAction, dispatch), // eslint-disable-line
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
  refreshTicketDetail: bindActionCreators(refreshTicketDetailAction, dispatch),
  getNumericalSeriesShared: bindActionCreators(getNumericalSeriesSharedAction, dispatch),
  getTransportationTypesShared: bindActionCreators(getTransportationTypesSharedAction, dispatch),
});

TicketsCreateModal.propTypes = {
  createTicketsData: object.isRequired,
  detail: shape({
    isValid: bool.isRequired,
    form: object.isRequired,
    values: object.isRequired,
  }).isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
  createTicketDetail: func.isRequired,
  refreshTicketDetail: func.isRequired,
  getNumericalSeriesShared: func.isRequired,
  numericalSeriesSharedData: object.isRequired,
  transportationTypesData: object.isRequired,
  getTransportationTypesShared: func.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
  refreshParent: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(TicketsCreateModal);
