import React, { Component, } from 'react';
import {
  shape, bool, func, object,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  createCamerasDetailAction,
  validateFormAction,
  changeValueAction,
  refreshCamerasDetailAction,
} from '../actions/camerasDetail';
import { validateInput, validateWholeFormValidator, } from '../../../logic/forms/validation';
import Modal from '../../../atoms/Modal/Modal';
import CamerasDetail from '../components/CamerasDetail/CamerasDetail';
import CamerasCreate from '../components/CamerasCreate/CamerasCreate';


class CamerasCreateModal extends Component {
  componentDidMount() {
    const {
      refreshCamerasDetail,
    } = this.props;

    refreshCamerasDetail();
  }

  handleClose = () => {
    const { history, } = this.props;

    history.goBack();
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);

    changeValue(name, value, validationError);
  }

  handleCreate = () => {
    const {
      createCamerasData,
      detail,
      validateForm,
      createCamerasDetail,
    } = this.props;

    if (createCamerasData.isLoading || !detail.isValid) return;

    const validation = validateWholeFormValidator(detail.form, detail.values);
    if (Object.keys(validation).length < 1) {
      createCamerasDetail(detail, this.handleCreateSuccess);
    } else {
      validateForm(validation);
    }
  }

  handleCreateSuccess = () => {
    const { refreshParent, } = this.props;

    refreshParent();
    this.handleClose();
  }

  render() {
    const {
      createCamerasData,
      detail,
    } = this.props;

    return (
      <Modal
        isOpen
        onClose={this.handleClose}
        closeOnEsc
        size="MD"
        title="Přidání kamery"
      >
        <CamerasCreate
          // data
          createCamerasData={createCamerasData}
          isFormValid={detail.isValid}
          // methods
          handleClose={this.handleClose}
          handleCreate={this.handleCreate}
        >
          <CamerasDetail
            // data
            values={detail.values}
            form={detail.form}
            // methods
            handleClose={this.handleClose}
            handleOnChangeValue={this.handleOnChangeValue}
          />
        </CamerasCreate>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { camerasDetail, } = state;
  return {
    createCamerasData: camerasDetail.createCamerasData,
    detail: camerasDetail.detail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createCamerasDetail: bindActionCreators(createCamerasDetailAction, dispatch),
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
  refreshCamerasDetail: bindActionCreators(refreshCamerasDetailAction, dispatch),
});


CamerasCreateModal.propTypes = {
  createCamerasData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  detail: shape({
    isValid: bool.isRequired,
    form: object.isRequired,
    values: object.isRequired,
  }).isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
  createCamerasDetail: func.isRequired,
  refreshCamerasDetail: func.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
  refreshParent: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(CamerasCreateModal);
