import { PENDING, FULFILLED, REJECTED, } from '../../../globals';
import { api, handleError, } from '../../../logic/restApi/restApi';


/*
 * GET Kiosks
 */
export const DEVICES__GET_KIOSKS = 'DEVICES__GET_KIOSKS';
export const getKiosksAction = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: `${DEVICES__GET_KIOSKS}__${PENDING}`,
      payload: filter,
    });

    const response = await api({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/devices/kiosk/filter`,
      data: filter,
    });

    dispatch({
      type: `${DEVICES__GET_KIOSKS}__${FULFILLED}`,
      payload: response.data,
    });
  } catch (err) {
    const parsedError = handleError(err);
    dispatch({
      type: `${DEVICES__GET_KIOSKS}__${REJECTED}`,
      payload: parsedError,
    });
  }
};


/**
 * Filter Changed
 */
export const DEVICES__KIOSKS_FILTER_CHANGED = 'DEVICES__KIOSKS_FILTER_CHANGED';

export const changeKiosksFilterAction = (filter) => ({
  type: `${DEVICES__KIOSKS_FILTER_CHANGED}`,
  payload: filter,
});
