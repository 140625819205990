import React, { Fragment, } from 'react';
import {
  shape, func, object,
} from 'prop-types';

import { withLang, } from '../../../../logic/languages/withLang';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import InfoMsg from '../../../../atoms/InfoMsg/InfoMsg';
import Input from '../../../../atoms/Input/Input';
import Heading from '../../../../atoms/Heading/Heading'

import { FORM_ERROR, FORM_SUCCESS, } from '../../../../globals';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import SegmentLoading from '../../../../components/SegmentLaoding/SegmentLaoding';
import SegmentError from '../../../../components/SegmentError/SegmentError';
import Pencil from '../../../../styles/icons/Pencil';

const QueueConfigurationForm = ({
  // data
  getData,
  updateData,
  form,
  values,
  // methods
  onChangeValue,
  langError,
  onEdit,
  onGetDetail,
  isFormValid,
}) => {
  if (getData.isLoading) {
    return <SegmentLoading formGroup />;
  }
  if (getData.error) {
    return (
      <SegmentError
        formGroup
        message={getData.message}
        refresh={onGetDetail}
      />
    );
  }

  return (
    <Fragment>
      <Heading h4>
        Nastavení fronty
      </Heading>
      <Row formGroup>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
          Počet hodin od teď do minulosti, kdy se přeprava zobrazí v plánovaných
        </Col>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
          <Input
            type="number"
            value={values.transportationInPlanTabBeforeNowInMinutes}
            status={form.transportationInPlanTabBeforeNowInMinutes.status}
            onChange={(e) => onChangeValue(form.transportationInPlanTabBeforeNowInMinutes.name, e.target.value)}
          />
        </Col>
        <Col MD_offset={3} LG_offset={6} sidePadding>
          <InfoMsg
            status={form.transportationInPlanTabBeforeNowInMinutes.status}
            msg={langError(form.transportationInPlanTabBeforeNowInMinutes.statusMsg)}
            showIcon
          />
        </Col>
      </Row>

      <Row formGroup>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
        Počet hodin od teď do budoucnosti, kdy se zobrazí přeprava v plánovaných
        </Col>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
          <Input
            type="number"
            value={values.transportationInPlanTabAfterNowInMinutes}
            status={form.transportationInPlanTabAfterNowInMinutes.status}
            onChange={(e) => onChangeValue(form.transportationInPlanTabAfterNowInMinutes.name, e.target.value)}
          />
        </Col>
        <Col MD_offset={3} LG_offset={6} sidePadding>
          <InfoMsg
            status={form.transportationInPlanTabAfterNowInMinutes.status}
            msg={langError(form.transportationInPlanTabAfterNowInMinutes.statusMsg)}
            showIcon
          />
        </Col>
      </Row>

      <Row formGroup>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={6} formLabel sidePadding SM_textAlign="left" LG_textAlign="right">
          Kdy je přeprava zpožděná v hodinách
        </Col>
        <Col MD_offset={3} MD={18} LG_offset={0} LG={12} sidePadding>
          <Input
            type="number"
            value={values.transportationInDelayTabBeforePlanInMinutes}
            status={form.transportationInDelayTabBeforePlanInMinutes.status}
            onChange={(e) => onChangeValue(form.transportationInDelayTabBeforePlanInMinutes.name, e.target.value)}
          />
        </Col>
        <Col MD_offset={3} LG_offset={6} sidePadding>
          <InfoMsg
            status={form.transportationInDelayTabBeforePlanInMinutes.status}
            msg={langError(form.transportationInDelayTabBeforePlanInMinutes.statusMsg)}
            showIcon
          />
        </Col>
      </Row>

      <Row>
        <Col textAlign="right">
          <InfoMsg
            status={updateData.error ? FORM_ERROR : FORM_SUCCESS}
            msg={langError(updateData.message)}
            showIcon
          />
        </Col>
        <Col textAlign="right">
          <ButtonLoader
            type="button"
            color="primary"
            onClick={onEdit}
            disabled={!isFormValid}
            isLoading={updateData.isLoading}
          >
            <span className="btn--icon">
              <Pencil />
            </span>
            Upravit
          </ButtonLoader>
        </Col>
      </Row>
    </Fragment>
  );
};


QueueConfigurationForm.propTypes = {
  getData: object.isRequired,
  updateData: object.isRequired,
  form: shape({
    transportationInDelayTabBeforePlanInMinutes: object.isRequired,
    transportationInPlanTabAfterNowInMinutes: object.isRequired,
    transportationInPlanTabBeforeNowInMinutes: object.isRequired,
  }).isRequired,
  values: object.isRequired,
  onChangeValue: func.isRequired,
  onEdit: func.isRequired,
  onGetDetail: func.isRequired,
  isFormValid: func.isRequired,
  langError: func.isRequired,
};


export default withLang(QueueConfigurationForm);
