import styled from 'styled-components';

const StyledDisplayEditorWrapper = styled.div`
    position: relative;
    margin-top: 1rem;
    display: inline-block;
    max-width: 100%;
    height: 800px;
    overflow: scroll;  
    border: 1px solid black;
`;

export default StyledDisplayEditorWrapper;
