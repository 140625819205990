import React, { Component, Fragment, } from 'react';
import {
  shape, bool, func, object, string,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  getStandAppConfigurationDetailAction,
  updateStandAppConfigurationDetailAction,
  validateFormAction,
  changeValueAction,
} from '../actions/standAppConfigurationDetail';
import {
  getStandAppConfigurationColumnsSharedAction,
} from '../../shared/actions/shared';
import { validateInput, validateWholeFormValidator, } from '../../../logic/forms/validation';
import StandAppConfigurationEdit from '../components/StandAppConfigurationEdit/StandAppConfigurationEdit';
import Modal from '../../../atoms/Modal/Modal';
import StandAppConfigurationDetail from '../components/StandAppConfigurationDetail/StandAppConfigurationDetail';


class StandAppConfigurationEditModal extends Component {
  componentDidMount() {
    this.handleGetDetail();
  }

  handleGetDetail = () => {
    const { match, getStandAppConfigurationDetail, } = this.props;
    getStandAppConfigurationDetail(match.params.id);
  }

  handleClose = () => {
    const { history, } = this.props;
    history.goBack();
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);
    changeValue(name, value, validationError);
  }

  handleEdit = () => {
    const {
      updateStandAppConfigurationData,
      detail,
      validateForm,
      updateStandAppConfigurationDetail,
    } = this.props;

    if (updateStandAppConfigurationData.isLoading || !detail.isValid) return;

    const validation = validateWholeFormValidator(detail.form, detail.values);
    if (Object.keys(validation).length < 1) {
      updateStandAppConfigurationDetail(detail, this.handleEditSuccess);
    } else {
      validateForm(validation);
    }
  }

  handleEditSuccess = () => {
    const { refreshParent, } = this.props;
    refreshParent();
    this.handleClose();
  }

  render() {
    const {
      detailStandAppConfigurationData,
      updateStandAppConfigurationData,
      standAppConfigurationColumnsSharedData,
      getStandAppConfigurationColumnsShared,
      detail,
    } = this.props;

    return (
      <Fragment>

        <Modal
          isOpen
          onClose={this.handleClose}
          closeOnEsc
          size="LG"
          title="Detail konfigurace pro aplikaci stanoviště"
        >
          <StandAppConfigurationEdit
            // data
            detailStandAppConfigurationData={detailStandAppConfigurationData}
            updateStandAppConfigurationData={updateStandAppConfigurationData}
            isFormValid={detail.isValid}
            // methods
            handleClose={this.handleClose}
            handleEdit={this.handleEdit}
            onGetDetail={this.handleGetDetail}
          >
            <StandAppConfigurationDetail
              // data
              values={detail.values}
              form={detail.form}
              // methods
              handleClose={this.handleClose}
              handleOnChangeValue={this.handleOnChangeValue}
              standAppConfigurationColumnsSharedData={standAppConfigurationColumnsSharedData}
              getStandAppConfigurationColumnsShared={getStandAppConfigurationColumnsShared}
            />
          </StandAppConfigurationEdit>
        </Modal>

      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { standAppConfigurationDetail, shared, } = state;
  return {
    detailStandAppConfigurationData: standAppConfigurationDetail.detailStandAppConfigurationData,
    updateStandAppConfigurationData: standAppConfigurationDetail.updateStandAppConfigurationData,
    detail: standAppConfigurationDetail.detail,
    standAppConfigurationColumnsSharedData: shared.standAppConfigurationColumnsData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStandAppConfigurationDetail: bindActionCreators(getStandAppConfigurationDetailAction, dispatch), // eslint-disable-line
  updateStandAppConfigurationDetail: bindActionCreators(updateStandAppConfigurationDetailAction, dispatch), // eslint-disable-line
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
  getStandAppConfigurationColumnsShared: bindActionCreators(getStandAppConfigurationColumnsSharedAction, dispatch), // eslint-disable-line
});


StandAppConfigurationEditModal.propTypes = {
  detailStandAppConfigurationData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  updateStandAppConfigurationData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  detail: shape({
    isValid: bool.isRequired,
    form: object.isRequired,
    values: object.isRequired,
  }).isRequired,
  match: shape({
    params: shape({
      id: string.isRequired,
    }).isRequired,
  }).isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
  standAppConfigurationColumnsSharedData: object.isRequired,
  getStandAppConfigurationDetail: func.isRequired,
  updateStandAppConfigurationDetail: func.isRequired,
  getStandAppConfigurationColumnsShared: func.isRequired,
  refreshParent: func.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(StandAppConfigurationEditModal);
