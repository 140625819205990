import React, { Component, } from 'react';
import { Route, Switch, Redirect, } from 'react-router-dom';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';
import {
  shape, bool, func,
} from 'prop-types';

import { initUser, } from '../Auth/actions/auth';
import AppTemplate from '../../components/AppTemplate/AppTemplate';
import HomePage from '../Home/pages/HomePage';
import LoginPage from '../Auth/pages/LoginPage';
import TransportationTypesLayout from '../TransportationTypes/pages/TransportationTypesLayout';
import PlatformsLayout from '../Platforms/pages/PlatformsLayout';
import DevicesLayout from '../Devices/pages/DevicesLayout';
import LanguagesLayout from '../Languages/pages/LanguagesLayout';
import GenericFormsLayout from '../GenericForms/pages/GenericFormsLayout';
import QueueConfigLayout from '../QueueConfig/pages/QueueConfigLayout';
import NotFoundPage from '../../templates/NotFound/NotFoundPage';
import NotifManager from '../../logic/notifications/NotifManager';

import StyledApp from '../../styles/StyledApp';


class MainLayout extends Component {
  state = {
    isOpenSidebar: true,
  }

  componentDidMount() {
    const { initUserAction, } = this.props;
    initUserAction();
  }

  handleToggleSidebar = (e) => {
    const { isOpenSidebar, } = this.state;

    e.preventDefault();
    this.setState({
      isOpenSidebar: !isOpenSidebar,
    });
  }

  render() {
    const { isOpenSidebar, } = this.state;
    const { /* user, */ initUserData, } = this.props;

    // TODO - stranka s nacitanim dat usera, pripadne nacitam i konfig
    if (initUserData.isLoading) {
      return <div>isLaoding</div>;
    }

    // TODO - nepovedlo se nacist, skoc na login
    if (initUserData.error) {
      return <div>Error</div>;
    }

    // TODO - musi se naroutovat /login bez layout

    return (
      <StyledApp>
        <AppTemplate
          isOpenSidebar={isOpenSidebar}
          onToggleSidebar={this.handleToggleSidebar}
        >

          <Switch>
            <Redirect exact from="/languages" to="/languages/list" />
            <Redirect exact from="/transportation-types" to="/transportation-types/list" />
            <Route exact path="/" component={HomePage} />
            <Route exact path="/login" component={LoginPage} />
            <Route path="/transportation-types" component={TransportationTypesLayout} />
            <Route path="/platforms" component={PlatformsLayout} />
            <Route path="/devices" component={DevicesLayout} />
            <Route path="/languages" component={LanguagesLayout} />
            <Route path="/queue-config" component={QueueConfigLayout} />
            <Route path="/generic-forms" component={GenericFormsLayout} />
            <Route component={NotFoundPage} />
          </Switch>

          <NotifManager />

        </AppTemplate>
      </StyledApp>
    );
  }
}


const mapStateToProps = (state) => {
  const { user, auth, } = state;
  return {
    user,
    initUserData: auth.initUserData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  initUserAction: bindActionCreators(initUser, dispatch),
});


MainLayout.propTypes = {
  initUserData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  // user: shape({
  //   isLoggedIn: bool.isRequired,
  // }).isRequired,
  initUserAction: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
