import React, { Component, } from 'react';
import {
  shape, func, string, arrayOf, bool, number,
} from 'prop-types';
import uuidv4 from 'uuid/v4';

import { withDnD, } from '../../../../logic/dnd/withDnD';
import PlanContext from './PlanContext';

import StyledPlanItem from './styles/StyledPlanItem';


class PlanActionItem extends Component {
  handleOnMouseDown = (e) => {
    const {
      dnd, item, root, position,
    } = this.props;

    e.preventDefault();
    e.stopPropagation();

    if (dnd.isActive) return;

    dnd.onDragStart({
      e,
      id: uuidv4(),
      root,
      pos: position,
      payload: item,
    });
  }

  render() {
    const { item, draggingOverList, } = this.props;

    return (
      <li>
        <StyledPlanItem
          role="none"
          onMouseDown={this.handleOnMouseDown}
          draggingOverList={draggingOverList}
          isLocked={false}
        >
          {item.name}
        </StyledPlanItem>
      </li>
    );
  }
}


PlanActionItem.propTypes = {
  item: shape({
    name: string.isRequired,
  }).isRequired,
  root: string.isRequired,
  draggingOverList: bool.isRequired,
  position: arrayOf(number).isRequired,
  dnd: shape({
    onDragStart: func.isRequired,
    isActive: bool.isRequired,
    item: shape({
      id: string,
    }).isRequired,
  }).isRequired,
};


export default withDnD(PlanContext)(PlanActionItem);
