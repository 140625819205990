import React, { Component, Fragment, } from 'react';
import {
  shape, bool, func, object, string,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  getLanguagesDetailAction,
  updateLanguagesDetailAction,
  validateFormAction,
  changeValueAction,
} from '../actions/languagesDetail';
import { validateInput, validateWholeFormValidator, } from '../../../logic/forms/validation';
import LanguagesEdit from '../components/LanguagesEdit/LanguagesEdit';
import Modal from '../../../atoms/Modal/Modal';
import LanguagesDetail from '../components/LanguagesDetail/LanguagesDetail';

class LanguagesEditModal extends Component {
  componentDidMount() {
    this.handleGetDetail();
  }

  handleGetDetail = () => {
    const { match, getLanguagesDetail, } = this.props;
    getLanguagesDetail(match.params.id);
  }

  handleClose = () => {
    const { history, } = this.props;
    history.goBack();
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);
    changeValue(name, value, validationError);
  }

  handleEdit = async () => {
    const {
      updateLanguagesData,
      detail,
      validateForm,
      updateLanguagesDetail,
    } = this.props;

    if (updateLanguagesData.isLoading || !detail.isValid) return;

    const validation = await validateWholeFormValidator(detail.form, detail.values);
    if (Object.keys(validation).length < 1) {
      updateLanguagesDetail(detail, this.handleEditSuccess);
    } else {
      validateForm(validation);
    }
  }

  handleEditSuccess = () => {
    const { refreshParent, } = this.props;

    refreshParent();
    this.handleClose();
  }

  render() {
    const {
      // data
      detailLanguagesData,
      updateLanguagesData,
      detail,
      // methods
    } = this.props;

    return (
      <Fragment>
        <Modal
          isOpen
          onClose={this.handleClose}
          closeOnEsc
          size="MD"
          title="Detail jazyku"
        >
          <LanguagesEdit
            // data
            detailLanguagesData={detailLanguagesData}
            updateLanguagesData={updateLanguagesData}
            isFormValid={detail.isValid}
            // methods
            handleClose={this.handleClose}
            handleEdit={this.handleEdit}
            onGetDetail={this.handleGetDetail}
          >
            <LanguagesDetail
              // data
              values={detail.values}
              form={detail.form}
              // methods
              handleClose={this.handleClose}
              handleOnChangeValue={this.handleOnChangeValue}
            />
          </LanguagesEdit>
        </Modal>

      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { languagesDetail, } = state;
  return {
    detailLanguagesData: languagesDetail.detailLanguagesData,
    updateLanguagesData: languagesDetail.updateLanguagesData,
    detail: languagesDetail.detail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getLanguagesDetail: bindActionCreators(getLanguagesDetailAction, dispatch), // eslint-disable-line
  updateLanguagesDetail: bindActionCreators(updateLanguagesDetailAction, dispatch), // eslint-disable-line
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
});


LanguagesEditModal.propTypes = {
  detailLanguagesData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  updateLanguagesData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  detail: shape({
    isValid: bool.isRequired,
    form: object.isRequired,
    values: object.isRequired,
    oldValues: object.isRequired,
  }).isRequired,
  match: shape({
    params: shape({
      id: string.isRequired,
    }).isRequired,
  }).isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
  getLanguagesDetail: func.isRequired,
  refreshParent: func.isRequired,
  updateLanguagesDetail: func.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(LanguagesEditModal);
