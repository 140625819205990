import styled from 'styled-components';


const DropZone = styled.div`
  margin: 0 1rem;
  border: 1px solid red;
  height: 1.5em;
`;


export default DropZone;
