import React from 'react';
import { Route, Switch, } from 'react-router-dom';

import BlankTemp from '../../../templates/Spaces/BlankTemp';
import Heading from '../../../atoms/Heading/Heading';
import TabsLinkPanel from '../../../atoms/TabsLink/TabsLinkPanel';
import TabLink from '../../../atoms/TabsLink/TabLink';
import TransportationTypesPage from './TransportationTypesPage';
import MapFormsPage from './MapFormsPage';
import NumericalSeriesPage from './NumericalSeriesPage';
import TicketsPage from './TicketsPage';

const TransportationTypesLayout = () => (
  <BlankTemp>
    <Heading h4>
      Typy přeprav
    </Heading>

    <TabsLinkPanel>
      <TabLink
        exact={false}
        to="/transportation-types/list"
      >
        Seznam
      </TabLink>
      <TabLink
        exact={false}
        to="/transportation-types/map-forms"
      >
        Mapování formulářů
      </TabLink>
      <TabLink
        exact={false}
        to="/transportation-types/numerical-series"
      >
        Číselné řady
      </TabLink>
      <TabLink
        exact={false}
        to="/transportation-types/tickets"
      >
        Lístky
      </TabLink>
    </TabsLinkPanel>

    <Switch>
      <Route path="/transportation-types/list" component={TransportationTypesPage} />
      <Route path="/transportation-types/map-forms" component={MapFormsPage} />
      <Route path="/transportation-types/numerical-series" component={NumericalSeriesPage} />
      <Route path="/transportation-types/tickets" component={TicketsPage} />
    </Switch>
  </BlankTemp>
);


export default TransportationTypesLayout;
