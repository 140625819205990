import React, { Component, Fragment, } from 'react';
import {
  shape, bool, func, object, string,
} from 'prop-types';
import { connect, } from 'react-redux';
import { bindActionCreators, } from 'redux';

import {
  getStandsDetailAction,
  updateStandsDetailAction,
  validateFormAction,
  changeValueAction,
} from '../actions/standsDetail';
import {
  getActionsSharedAction,
  getStandAppConfigurationSharedAction,
} from '../../shared/actions/shared';
import { validateInput, validateWholeFormValidator, } from '../../../logic/forms/validation';
import StandsEdit from '../components/StandsEdit/StandsEdit';
import Modal from '../../../atoms/Modal/Modal';
import StandsDetail from '../components/StandsDetail/StandsDetail';


class StandsEditModal extends Component {
  componentDidMount() {
    this.handleGetDetail();
  }

  handleGetDetail = () => {
    const { match, getStandsDetail, } = this.props;

    getStandsDetail(match.params.id);
  }

  handleClose = () => {
    const { history, } = this.props;

    history.goBack();
  }

  disableOptionsForClearance = (option) => {
    const { detail: { values, }, } = this.props;
    // pokud není vybraná žádná hodnota => vše je viditelné
    if (values.actions.length < 1) {
      return false;
    }
    // pokud je prvni vybrana hodnota odbaveni, ostatní zesedivy
    if (values.actions[0].type === 'clearance') {
      return true;
    }
    // pokud je prvni vybrana hodnota jina nez odbaveni => odbaveni zesedivy
    if (option.type === 'clearance') {
      return true;
    }
    // aby nepicoval lint tak vracím false, i kdyz to sem nidky nemuze dojit
    return false;
  }

  handleOnChangeValue = (name, value) => {
    const { detail, changeValue, } = this.props;
    const validationError = validateInput(detail.form[name], value);

    changeValue(name, value, validationError);
  }

  handleEdit = () => {
    const {
      updateStandsData,
      detail,
      validateForm,
      updateStandsDetail,
    } = this.props;

    if (updateStandsData.isLoading || !detail.isValid) return;

    const validation = validateWholeFormValidator(detail.form, detail.values);
    if (Object.keys(validation).length < 1) {
      updateStandsDetail(detail, this.handleEditSuccess);
    } else {
      validateForm(validation);
    }
  }

  handleEditSuccess = () => {
    const { refreshParent, } = this.props;

    refreshParent();
    this.handleClose();
  }

  render() {
    const {
      detailStandsData,
      updateStandsData,
      detail,
      actionsSharedData,
      standAppConfigurationSharedData,
      getActionsShared,
      getStandAppConfigurationShared,
    } = this.props;

    return (
      <Fragment>

        <Modal
          isOpen
          onClose={this.handleClose}
          closeOnEsc
          size="MD"
          title="Detail stanoviště"
        >
          <StandsEdit
            // data
            standsDetailData={detailStandsData}
            updateStandsData={updateStandsData}
            isFormValid={detail.isValid}
            // methods
            handleClose={this.handleClose}
            handleEdit={this.handleEdit}
            onGetDetail={this.handleGetDetail}
          >
            <StandsDetail
              // data
              values={detail.values}
              form={detail.form}
              actionsSharedData={actionsSharedData}
              standAppConfigurationSharedData={standAppConfigurationSharedData}
              // methods
              handleClose={this.handleClose}
              handleOnChangeValue={this.handleOnChangeValue}
              getActionsShared={getActionsShared}
              getStandAppConfigurationShared={getStandAppConfigurationShared}
              disableOptionsForClearance={this.disableOptionsForClearance}
            />
          </StandsEdit>
        </Modal>

      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { standsDetail, shared, } = state;
  return {
    detailStandsData: standsDetail.detailStandsData,
    updateStandsData: standsDetail.updateStandsData,
    detail: standsDetail.detail,
    // dropdowns
    actionsSharedData: shared.actionsData,
    standAppConfigurationSharedData: shared.standAppConfigurationData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStandsDetail: bindActionCreators(getStandsDetailAction, dispatch), // eslint-disable-line
  updateStandsDetail: bindActionCreators(updateStandsDetailAction, dispatch), // eslint-disable-line
  changeValue: bindActionCreators(changeValueAction, dispatch),
  validateForm: bindActionCreators(validateFormAction, dispatch),
  // dropdowns
  getActionsShared: bindActionCreators(getActionsSharedAction, dispatch),
  getStandAppConfigurationShared: bindActionCreators(getStandAppConfigurationSharedAction, dispatch), // eslint-disable-line
});


StandsEditModal.propTypes = {
  detailStandsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  updateStandsData: shape({
    isLoading: bool.isRequired,
    status: bool.isRequired,
    error: bool.isRequired,
  }).isRequired,
  detail: shape({
    isValid: bool.isRequired,
    form: object.isRequired,
    values: object.isRequired,
  }).isRequired,
  match: shape({
    params: shape({
      id: string.isRequired,
    }).isRequired,
  }).isRequired,
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
  actionsSharedData: object.isRequired,
  standAppConfigurationSharedData: object.isRequired,
  getActionsShared: func.isRequired,
  getStandAppConfigurationShared: func.isRequired,
  getStandsDetail: func.isRequired,
  refreshParent: func.isRequired,
  updateStandsDetail: func.isRequired,
  changeValue: func.isRequired,
  validateForm: func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(StandsEditModal);
