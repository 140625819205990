import styled from 'styled-components';


const StyledMSBox = styled.ul`
  width: calc(50% - 1rem);
  height: 200px;
  overflow-y: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 0;
  list-style: none;
  background: ${(p) => p.theme.white};
  color: ${(p) => p.theme.black};
  border-radius: ${(p) => p.theme.input.borderRadius};
  border-color: ${(p) => p.theme.grey.t500};
  border-top-width: ${(p) => p.theme.input.borderWidthTopBottom};
  border-bottom-width: ${(p) => p.theme.input.borderWidthTopBottom};
  border-right-width: ${(p) => p.theme.input.borderWidthLeftRight};
  border-left-width: ${(p) => p.theme.input.borderWidthLeftRight};
  border-style: ${(p) => (p.mainList ? p.theme.input.borderStyle : 'dashed')};

  &:focus {
    outline: none;
  }

  ${(p) => p.isFocused && `
    border-color: ${p.theme.primary.t500};
    box-shadow: 0 0 0 1px ${p.theme.primary.t500};
    border-style: ${p.theme.input.borderStyle};
  `}

  
  .msl--info-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
    margin-right: 8px;
    margin-left: 8px;
    border-radius: ${(p) => p.theme.input.borderRadius};
  }

  .msl--loader {
    background: ${(p) => p.theme.grey.t200};
    color: ${(p) => p.theme.grey.t500};
    font-size: 2rem;
  }

  .msl--error {
    color: ${(p) => p.theme.error.t800};
    background: ${(p) => p.theme.error.t50};
  }

  .msl--placeholder {
    color: ${(p) => p.theme.grey.t400};
  }
`;

export default StyledMSBox;
